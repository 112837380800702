import React from 'react'
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Box, Button, ButtonProps, Grid } from '@mui/material'
import { NIL as NIL_UUID } from 'uuid'
import { PanelContent, PanelFooter } from 'components/panel'
import _ from 'core/utils/deepdash'
import { useFormik } from 'formik'
import { colors } from 'utils/colors'
import { useNewsCardsContext } from 'modules/news-cards/context/news-card-context'
import { INewsCard } from 'modules/news-cards/models/news-card'
import { NewsCardForm } from 'modules/news-cards/view/news-card-modal/news-card-form'
import { NewsCardSchema } from 'modules/news-cards/validation/news-card-schema'
import { ModalWrapper } from 'components/modal-wrapper'
import { convertNewsCardToRequestObject } from 'modules/news-cards/utils'
import { ConfirmationDialog } from 'components/confirmation-dialog'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
    },
    panelHeader: {
      display: 'flex',
      width: '100%',
      flexShrink: 0,
    },
    panelTitle: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      padding: '0 0 0 1.25rem',
    },
    navigationContainer: {
      display: 'flex',
      flex: 2,
      flexGrow: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    rightHeaderSection: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      padding: '0 1.25rem 0 0',
      flexDirection: 'row-reverse',
      '& .MuiButton-contained': {
        marginRight: 20,
      },
    },
    footerContent: {
      display: 'flex',
      flex: 1,
      justifyContent: 'space-between',
    },
    cancelBtn: {
      marginRight: '1.25rem',
    },
    wrapper: {
      borderLeft: `1px solid ${colors.rgba_0_0_0_12}`,
    },
    form: {
      height: '600px',
      overflow: 'auto',
    },
  }),
)

const buttonProps: ButtonProps = {
  variant: 'contained',
  size: 'large',
}

export const NewsCardModal: React.FC = () => {
  const classes = useStyles()
  const {
    currentNewsCard,
    updateNewsCard,
    createNewsCard,
    clearCurrentNewsCard,
    deleteNewsCard
  } = useNewsCardsContext()
  const isUpdating = currentNewsCard?.id !== NIL_UUID
  const { eventId, EventId, variant, Variant } = currentNewsCard?.parameters || {}

  const form = useFormik({
    enableReinitialize: isUpdating,
    validationSchema: NewsCardSchema,
    initialValues: {
      id: currentNewsCard?.id ?? '',
      title: currentNewsCard?.title || '',
      variant: currentNewsCard?.variant || variant || Variant,
      media: currentNewsCard?.media || {},
      parameters: currentNewsCard?.parameters || {},
      promotedEventId: currentNewsCard?.promotedEventId || eventId || EventId || '',
    },
    onSubmit: (value: INewsCard) => {
      const renewsCard = isUpdating ? updateNewsCard : createNewsCard
      renewsCard(convertNewsCardToRequestObject(value))
    },
  })

  return (
    <ModalWrapper
      panelSize={8}
      closeModal={clearCurrentNewsCard}
      title={isUpdating ? `Edit ${currentNewsCard?.title}` : 'Create News Card'}
      open={!!currentNewsCard}
    >
      <>
        <PanelContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <NewsCardForm
                newsCard={form.values}
                handleChange={form.handleChange}
                setFieldTouched={form.setFieldTouched}
                setFieldValue={form.setFieldValue}
                errors={form.errors}
                isUpdating={isUpdating}
              />
            </Grid>
          </Grid>
        </PanelContent>
        <PanelFooter>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box className={classes.footerContent}>
                <Box>
                  <ConfirmationDialog
                    popoverId={`delete-newsCard-${currentNewsCard?.id}`}
                    onConfirmation={(): void => deleteNewsCard()}
                    confirmationText={`Are you sure you want to delete this card?`}
                    buttonLabel="Delete"
                    buttonProps={buttonProps}
                  />
                </Box>
                <Box>
                  <Button
                    size="large"
                    className={classes.cancelBtn}
                    onClick={clearCurrentNewsCard}
                    variant="contained"
                    color="secondary"
                  >
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    size="large"
                    onClick={form.submitForm}
                    variant="contained"
                    disabled={form.dirty && !form.isValid}
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </PanelFooter>
      </>
    </ModalWrapper>
  )
}
