import { IAlertsState } from 'types/common/alerts/models/state/alerts-state'
import { AppState } from 'types/redux/types/app-state'
import _ from 'core/utils/deepdash'
import { MODULE_NAME } from '../constants'

const getState = (state: AppState): IAlertsState =>
  _.cloneDeep(state[MODULE_NAME])

export const getAlerts = (state: AppState): IAlertsState['queue'] =>
  getState(state).queue
