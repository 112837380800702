import paths from 'core/api/endpoints/leaderboards/paths'
import params from 'core/api/endpoints/leaderboards/params'
import { RequestMethod } from 'types/common/api/enums/request-method'
import { EndpointRoute } from 'types/common/api/models/entities/endpoint-route'

const LeaderboardEndpoints: { [key: string]: EndpointRoute } = {
  getLeaderboardPositionRewards: {
    method: RequestMethod.GET,
    path: [paths.leaderboards, params.leaderboardId, paths.rewards],
  },
  createLeaderboardPositionReward: {
    method: RequestMethod.POST,
    path: [paths.leaderboards, params.leaderboardId, paths.rewards],
  },
  updateLeaderboardPositionReward: {
    method: RequestMethod.PUT,
    path: [
      paths.leaderboards,
      params.leaderboardId,
      paths.rewards,
      params.positionId,
    ],
  },
}

export default LeaderboardEndpoints
