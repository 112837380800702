import { MODULE_NAME } from 'modules/post-groups/constants'

const ActionTypes = {
  GET_POST_GROUP_POSTS: `${MODULE_NAME}/GET_POST_GROUP_POSTS`,
  CLEAR_POST_GROUP_POSTS: `${MODULE_NAME}/CLEAR_POST_GROUP_POSTS`,
  SET_CURRENT_POST_GROUP_ID: `${MODULE_NAME}/SET_CURRENT_POST_GROUP_ID`,
  GET_POST_GROUPS: `${MODULE_NAME}/GET_POST_GROUPS`,
  GET_POST_GROUP_BY_ID: `${MODULE_NAME}/GET_POST_GROUP_BY_ID`,
  CREATE_POST_GROUP: `${MODULE_NAME}/CREATE_POST_GROUP`,
  CREATE_POST_GROUP_DRAFT: `${MODULE_NAME}/CREATE_POST_GROUP_DRAFT`,
  UPDATE_POST_GROUP: `${MODULE_NAME}/UPDATE_POST_GROUP`,
  SETTLE_POST_GROUP: `${MODULE_NAME}/SETTLE_POST_GROUP`,
  DELETE_POST_GROUP_BY_ID: `${MODULE_NAME}/DELETE_POST_GROUP_BY_ID`,
}

export default ActionTypes
