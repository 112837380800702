import { Box, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { StarIcon, CoinIcon } from 'components/icons'
import { PreviewContainer } from 'components/preview'
import { QuestionImage } from 'modules/app/views/components/previews/question-image'
import { QuestionOption } from 'modules/app/views/components/previews/question-option'
import React from 'react'
import PostType from 'types/modules/posts/enums/post-type'
import { IPost } from 'types/modules/posts/models/entities/post'
import { IPostQuestion } from 'types/modules/posts/models/entities/post-question'
import { sortQuestionOptionsByOrder } from 'utils/common'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    questionScreen: {
      position: 'relative',
      display: 'flex',
      width: '100%',
      height: '100%',
      flexDirection: 'column',
      overflow: 'hidden',
      background: '#ff787d',
    },
    coverFeedHeader: {
      display: 'flex',
      position: 'absolute',
      width: '100%',
      padding: '10px 20px 10px 20px',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    currencyBar: {
      display: 'flex',
      flexDirection: 'row',
    },
    currencyItem: {
      position: 'relative',
      width: 60,
      background: 'rgba(255,255,255,0.2)',
      border: '1px solid #ffffff',
      borderRadius: 5,
      display: 'flex',
      height: '100%',
      justifyContent: 'flex-end',
      marginLeft: 40,
      paddingRight: 7,
    },
    currencyIcon: {
      position: 'absolute',
      width: 30,
      height: 30,
      top: '-6px',
      left: '-18px',
    },
    currencyBalance: {
      fontSize: '0.8rem',
      color: '#ffffff',
    },
    mainContentWrapper: {
      display: 'flex',
      width: '100%',
      height: '55%',
      padding: 20,
      position: 'absolute',
      bottom: 0,
      background: '#FFFFFF',
      borderRadius: '15px 15px 0 0',
      flexDirection: 'column',
    },
    questionContainer: {
      display: 'flex',
      position: 'relative',
      flex: 1,
      width: '100%',
      height: '100%',
      overflow: 'auto',
      background: 'rgb(243, 240, 240)',
      borderRadius: 20,
      padding: 15,
      flexDirection: 'column',
    },
    timer: {
      position: 'absolute',
      display: 'flex',
      top: '-85px',
      height: 100,
      width: 100,
      backgroundColor: '#ffffff',
      justifyContent: 'center',
      alignItems: 'center',
      alignSelf: 'center',
      borderRadius: 50,
      border: '6px solid rgba(243, 103, 108, 1)',
      boxShadow: '1px 2px 3px 2px rgba(0,0,0,0.3)',
    },
    timerSeconds: {
      fontSize: 28,
      color: '#000000',
      lineHeight: 32,
      marginTop: 5,
    },
    questionNumberWrapper: {
      display: 'flex',
      marginBottom: 5,
    },
    questionNumber: {
      color: '#939393',
      fontSize: 13,
    },
    questionTitleContainer: {
      display: 'flex',
      width: '100%',
      marginBottom: 15,
    },
    questionTitle: {
      fontSize: 20,
      fontWeight: 600,
      lineHeight: 1,
      color: '#000000',
    },
    optionsContainer: {
      display: 'flex',
      flex: 1,
      background: '#F2F1EF',
      overflow: 'auto',
      flexDirection: 'column',
    },
    fakeButton: {
      display: 'flex',
      borderRadius: 20,
      width: '100%',
      height: 60,
      justifyContent: 'center',
      alignItems: 'center',
      background: 'rgb(255, 151, 57)',
      marginTop: 20,
    },
    buttonText: {
      fontSize: 16,
      textAlign: 'center',
      color: 'rgba(255,255,255,0.4)',
    },
  }),
)

export interface IQuestionScreenGameProps {
  post: IPost
  question: IPostQuestion
  index: number
}

export const QuestionScreenGamePreview: React.FC<IQuestionScreenGameProps> = ({
  post,
  question,
  index,
}: IQuestionScreenGameProps) => {
  const classes = useStyles()

  return (
    <PreviewContainer withDevice={true}>
      <Box className={classes.questionScreen}>
        <Box className={classes.coverFeedHeader}>
          <Box className={classes.currencyBar}>
            <Box className={classes.currencyItem}>
              <Box className={classes.currencyIcon}>
                <StarIcon />
              </Box>
              <Typography className={classes.currencyBalance}>0</Typography>
            </Box>
            <Box className={classes.currencyItem}>
              <Box className={classes.currencyIcon}>
                <CoinIcon />
              </Box>
              <Typography className={classes.currencyBalance}>0</Typography>
            </Box>
          </Box>
        </Box>
        <QuestionImage questionId={question.id} />
        <Box className={classes.mainContentWrapper}>
          {post.type === PostType.Game &&
            post.timed &&
            question.timerSeconds && (
              <Box className={classes.timer}>
                <Typography className={classes.timerSeconds}>
                  {question.timerSeconds}
                </Typography>
              </Box>
            )}
          <Box className={classes.questionContainer}>
            <Box className={classes.questionNumberWrapper}>
              <Typography className={classes.questionNumber}>
                {`Question ${index + 1}`}
              </Typography>
            </Box>
            <Box className={classes.questionTitleContainer}>
              <Typography className={classes.questionTitle}>
                {question.text}
              </Typography>
            </Box>
            {/* <Box className={classes.tags}>
            </Box> */}
            <Box className={classes.optionsContainer}>
              {sortQuestionOptionsByOrder(question.options).map((option, index) => {
                return (
                  <QuestionOption
                    key={`option-${option.id}`}
                    option={option}
                    index={index}
                  />
                )
              })}
            </Box>
          </Box>
          <Box className={classes.fakeButton}>
            <Typography className={classes.buttonText}>
              Selection Needed
            </Typography>
          </Box>
        </Box>
      </Box>
    </PreviewContainer>
  )
}
