import { IKeyValueStringCollection } from 'types/common/interfaces/IKeyValueStringCollection'

const params = {
  alertId: '{{alertId}}',
  answerId: '{{answerId}}',
  matchId: '{{matchId}}',
  optionId: '{{optionId}}',
  postId: '{{postId}}',
  postStatus: '{{postStatus}}',
  questionId: '{{questionId}}',
  resultId: '{{resultId}}',
  extension: '{{extension}}',
}

export default params
