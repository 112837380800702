import React from 'react'
import { Theme, TableCell, TableRow } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { IQuest } from 'modules/quests/models/quest'


export const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }),
)(TableCell);

export const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.secondary.light,
      }
    },
  }),
)(TableRow);

interface ICardProps {
  item: IQuest
  onClick: () => void
}

export const QuestCard: React.FC<ICardProps> = ({ item: quest, onClick }: ICardProps) => {
  return <StyledTableRow key={quest.id} onClick={onClick}>
    <StyledTableCell component="th" scope="row">
      {quest.media?.title}
    </StyledTableCell>
    <StyledTableCell>{quest.schedule}</StyledTableCell>
    <StyledTableCell align="center">{quest.active ? 'Yes' : 'No'}</StyledTableCell>
    <StyledTableCell align="right">{quest.set}</StyledTableCell>
  </StyledTableRow>
}
