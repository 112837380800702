import { Box, Button, ButtonBase, ButtonGroup, InputLabel, MenuItem, Select, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { SimpleImageDisplay } from 'components/simple-image-display'
import { useMediaItemsContext } from 'modules/media-items/media-item-context'
import React, { useRef } from 'react'
import { AspectRatio } from 'types/common/images/enum/aspect-ratio'
import { MediaOrientation } from 'types/common/images/enum/media-orientation'

interface IPreviewAndControlsProps {
  disabled?: boolean
  aspectRatio: string
  orientation: string
  imageUrl?: string
  onChangeAspectRatio: (value: string) => void
  onChangeOrientation: (value: string) => void
  onFileChange: (value: FileList | null) => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentPanel: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      padding: '1.25rem',
      overflow: 'auto',
    },
    sidebarPanel: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      borderRight: '1px solid rgba(0,0,0,0.12)',
      '&:last-child': {
        border: 'none',
      },
    },
    imageUploadContainer: {
      display: 'flex',
      width: '100%',
      height: '100%',
    },
    videoUploadContainer: {
      height: 100,
    },
    inputLabel: {
      display: 'flex',
      marginBottom: '0.625rem',
      marginTop: '0.625rem',
    },
    imageUploaderButton: {
      display: 'flex',
      flex: 1,
      width: '100%',
    },
    hidden: {
      display: 'none',
    },
    select: {
      marginBottom: '1.25rem',
    },
    videoButton: {
      width: '100%',
      height: '100%',
      textAlign: 'center'
    }
  }),
)

const aspectRatioOptions = Object.keys(AspectRatio).map((name) => ({
  // @ts-expect-error ignore this
  value: AspectRatio[name],
  name: name,
}))

export const PreviewAndControls: React.FC<IPreviewAndControlsProps> = ({
  onFileChange,
  disabled = false,
  aspectRatio,
  orientation,
  imageUrl,
  onChangeAspectRatio,
  onChangeOrientation,
}): JSX.Element => {
  const classes = useStyles()
  const fileInput = useRef<HTMLInputElement>(null)
  const onClickUploader = (): void => {
    if (disabled) return
    fileInput?.current?.click()
  }
  const { accept, loading, isVideo, removeCrop, uploadMediaLabel } = useMediaItemsContext()

  return (
    <Box className={classes.sidebarPanel}>
      <Box
        className={classes.contentPanel}
        style={{
          borderBottom: '1px solid rgba(0,0,0,0.12)',
        }}
      >
        <Box className={isVideo || removeCrop ? classes.videoUploadContainer : classes.imageUploadContainer}>
          <input
            type="file"
            onChange={({ target: { files } }): void => {
              onFileChange(files)
            }}
            accept={accept}
            ref={fileInput}
            disabled={loading || disabled}
            className={classes.hidden}
          />
          {isVideo || removeCrop
            ? <Button
              variant="contained"
              color="primary"
              onClick={onClickUploader}
            >
              {uploadMediaLabel}
            </Button>
          : <ButtonBase
              className={classes.imageUploaderButton}
              onClick={onClickUploader}
            >
            <SimpleImageDisplay
              width="100%"
              height="100%"
              imageUrl={imageUrl}
              backgroundSize="contain"
              noImageLabel="Click to select image"
            />
          </ButtonBase>}
        </Box>
      </Box>
      {!isVideo && !removeCrop && <Box className={classes.contentPanel}>
        <InputLabel className={classes.inputLabel}>Aspect Ratio</InputLabel>
        <ButtonGroup fullWidth>
          {aspectRatioOptions.map((option) => <Button
            key={option.value}
            {...(aspectRatio === option.value
              ? { variant: 'contained', color: 'primary' }
              : { variant: 'outlined' })}
            onClick={(): void => {
              onChangeAspectRatio(option.value)
            }}
          >
            {option.name}
          </Button>)}
        </ButtonGroup>
        <InputLabel className={classes.inputLabel}>Orientation</InputLabel>
        <ButtonGroup fullWidth>
          <Button
            {...(orientation === MediaOrientation.Portrait &&
            aspectRatio !== AspectRatio.Square
              ? { variant: 'contained', color: 'primary' }
              : { variant: 'outlined' })}
            onClick={(): void => {
              onChangeOrientation(MediaOrientation.Portrait)
            }}
            disabled={aspectRatio === AspectRatio.Square}
          >
            Portrait
          </Button>
          <Button
            {...(orientation === MediaOrientation.Landscape &&
            aspectRatio !== AspectRatio.Square
              ? { variant: 'contained', color: 'primary' }
              : { variant: 'outlined' })}
            onClick={(): void => {
              onChangeOrientation(MediaOrientation.Landscape)
            }}
            disabled={aspectRatio === AspectRatio.Square}
          >
            Landscape
          </Button>
        </ButtonGroup>
      </Box>}
    </Box>
  )
}
