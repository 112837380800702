import { Box, ButtonBase, SvgIcon, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx'
import { useGameTasksContext } from 'modules/quests/context/game-task-context'
import React, { useMemo } from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    navItemButton: {
      display: 'flex',
      padding: 16,
    },
    stepNavigationItem: {
      display: 'flex',
      width: 40,
      height: 40,
      borderRadius: 90,
      alignItems: 'center',
      justifyContent: 'center',
      background: 'rgba(0,0,0,0.03)',
      color: theme.palette.text.primary,
      '&.active': {
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      },
    },
    navItemText: {
      fontSize: '0.8rem',
    },
    navItemIcon: {
      fontSize: 16,
    },
    disabled: {
      color: '#efefef'
    }
  }),
)

interface IGameTaskStepSwitchProps {
  gameTaskStepId?: string
  active: boolean
  onClick: (gameTaskStepId?: string) => void
  disabled?: boolean,
  Icon?: JSX.Element
}

export const GameTaskStepSwitch: React.FC<IGameTaskStepSwitchProps> = (
  {
    gameTaskStepId,
    disabled,
    active,
    onClick,
    Icon
  }: IGameTaskStepSwitchProps,
) => {
  const classes = useStyles()

  const { gameTaskSteps } = useGameTasksContext()
  const gameTaskStep = useMemo(
    () => gameTaskSteps.find((step) => step.id === gameTaskStepId),
    [gameTaskSteps.length]
  )
  return (
    <ButtonBase
      disabled={disabled}
      className={clsx(classes.navItemButton, active && 'active')}
      onClick={(): void => onClick(gameTaskStepId)}
    >
      <Box
        className={clsx(classes.stepNavigationItem, active && 'active')}
      >
        {
          !Icon
            ? <Typography className={`${classes.navItemText} ${disabled && classes.disabled}`}>
              L{gameTaskStep?.stepNumber}
            </Typography>
            : <SvgIcon color={disabled ? 'disabled' : 'inherit'}>
              {Icon}
            </SvgIcon>
        }
      </Box>
    </ButtonBase>
  )
}
