export enum OptionField {
  ID = 'id',
  QUESTION_ID = 'questionId',
  RESULT_ID = 'resultId',
  TEXT = 'text',
  ORDER = 'order',
  CORRECT = 'correct',
  PROBABILITY = 'probability',
  ANSWERS = 'answers',
  VOLUME = 'volume',
  TEMPORARY_ID = 'temporaryId',
}
