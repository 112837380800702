import { RootSagaReturnType } from 'core/redux/rootSaga'
import { ActionTypes } from 'modules/comments'
import { GetTagsSaga } from 'modules/tags/sagas/get-tags-saga'
import { all, AllEffect, ForkEffect, takeEvery } from 'redux-saga/effects'

const enabledAsyncActions = [
  ActionTypes.GET_POST_COMMENTS,
  ActionTypes.CREATE_POST_COMMENT,
  ActionTypes.UPDATE_POST_COMMENT,
  ActionTypes.DELETE_POST_COMMENT,
]

export const RootSaga = function* (): RootSagaReturnType {
  yield all([yield takeEvery(enabledAsyncActions, GetTagsSaga)])
}
