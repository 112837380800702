import React from 'react'
import { Box, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { ICalendarGridCellProps } from 'components/calendar/props/calendar-grid-cell-props'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { usePopover } from 'components/popover/use-popover'
import { IUserPrize } from 'types/modules/shipping/models/entities/user-prize'
import { TimeGridUserPrize } from 'modules/shipping/views/components/dashboard/user-prize-calendar-time-grid-cell/user-prize'
import { TimeGridUserPrizeMultiview } from 'modules/shipping/views/components/dashboard/user-prize-calendar-time-grid-cell/multiview'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    userPrizeGridCellContainer: {
      display: 'flex',
      flex: 1,
      '&:hover': {
        background: 'rgba(0,0,0,0.03)',
        cursor: 'pointer',
      },
    },
    emptyCell: {
      display: 'flex',
      flex: 1,
    },
  }),
)

export const UserPrizeCalendarTimeGridCell: React.FC<
  ICalendarGridCellProps<IUserPrize>
> = (props: ICalendarGridCellProps<IUserPrize>) => {
  const { items, dateTime, sortBy } = props

  const classes = useStyles()

  const contextMenu = usePopover({
    id: `context-menu-${dateTime}`,
    anchorReference: 'anchorPosition',
  })

  const renderViewSelector = (): JSX.Element | null => {
    if (IsNullOrUndefined(items) || items.length === 0) {
      return <Box className={classes.emptyCell} />
    }

    switch (items.length) {
      case 1:
        return (
          <TimeGridUserPrize
            userPrize={items[0]}
            index={0}
            dateField={sortBy}
          />
        )
      default:
        return (
          <TimeGridUserPrizeMultiview
            userPrizes={items}
            dateTime={dateTime}
            dateField={sortBy}
          />
        )
    }
  }

  return (
    <Box className={classes.userPrizeGridCellContainer}>
      {renderViewSelector()}
    </Box>
  )
}
