import { Box, Grid, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import _ from 'core/utils/deepdash'
import { FormGen, FormTypes } from 'components/simple-form-gen'
import React from 'react'
import { IForm } from 'types/common/interfaces/IForm'
import { GameTask, GameTaskSchedule, GameTaskType } from 'fe-shared-resources'
import { createOptionsFromEnum } from 'utils/common';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      width: '100%',
      height: '600px',
      '& .MuiGrid-item': {
        display: 'flex',
        flexDirection: 'column',
      },
    },
    questsWrapper: {
      borderLeft: '1px solid #f4f4f4',
      paddingLeft: 8,
      height: '100%',
    },
    container: {
      height: '100%'
    },
    questsError: {
      marginTop: -16
    }
  }),
)

interface IGameTaskFormProps extends IForm {
  gameTask: GameTask
  isUpdating: boolean
}

export const GameTaskForm: React.FC<IGameTaskFormProps> = ({
  gameTask,
  handleChange,
  setFieldValue,
  errors,
  isUpdating
}): JSX.Element => {
  const classes = useStyles()

  const onChangeIsVisible = (e: any): void => {
    setFieldValue('isVisible', !!e.target.value)
  }

  const onChangeIsEnabled = (e: any): void => {
    setFieldValue('isEnabled', !!e.target.value)
  }

  const gameTaskTypeOptions = createOptionsFromEnum(Object.values(GameTaskType))
  const gameTaskScheduleOptions = createOptionsFromEnum(Object.values(GameTaskSchedule))
  const elements = [
    {
      name: 'name',
      label: 'Name',
      value: gameTask?.name || '',
      type: FormTypes.TEXT_INPUT,
      otherProps: {
        error: !!errors?.name,
        helperText: errors?.name
      }
    },
    {
      name: 'description',
      label: 'Value description',
      value: gameTask?.description || '',
      type: FormTypes.TEXT_INPUT,
      otherProps: {
        error: !!errors?.description,
        helperText: errors?.description
      }
    },
    {
      name: 'type',
      label: 'Type',
      value: gameTask?.type || '',
      type: FormTypes.SELECT,
      hideInitialOption: true,
      options: gameTaskTypeOptions,
      otherProps: {
        error: !!errors?.type,
        helperText: errors?.type
      }
    },
    {
      name: 'schedule',
      label: 'Schedule',
      value: gameTask?.schedule || '',
      type: FormTypes.SELECT,
      hideInitialOption: true,
      options: gameTaskScheduleOptions,
      otherProps: {
        error: !!errors?.schedule,
        helperText: errors?.schedule
      }
    },
    {
      value: gameTask?.isVisible,
      type: FormTypes.CHECKBOX,
      label: 'Visible',
      name: 'isVisible',
      handleChange: onChangeIsVisible
    },
    {
      value: gameTask?.isEnabled,
      type: FormTypes.CHECKBOX,
      label: 'Enabled',
      name: 'isEnabled',
      handleChange: onChangeIsEnabled,
      disabled: !isUpdating
    },
  ]

  return (
    <Box className={classes.form}>
      <Grid container spacing={2} className={classes.container}>
        <Grid item xs={5}>
          <Grid container spacing={2}>
              {elements.map((element) => (
                <Grid
                  item xs={12}
                  key={element.name}
                >
                  <FormGen
                    handleChange={handleChange}
                    {...element}
                  />
                </Grid>
              ))}

            </Grid>
        </Grid>
        <Grid item xs={7}></Grid>
      </Grid>
    </Box>
  )
}
