export enum TagField {
  ID = 'id',
  CODE = 'code',
  LEVEL = 'level',
  NAME = 'name',
  MEDIA = 'media',
  ICON = 'icon',
  TAG_GROUPS = 'tagGroups',
  ACTIVE_POSTS = 'activePosts',
  BACKGROUND_COLOR = 'backgroundColor',
  IMAGE_URL = 'imageUrl',
  MEDIA_ITEM_ID = 'mediaItemId',
}
