import React from 'react'
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

interface ITabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    panelContainer: {
      overflow: 'auto',
      height: '100%'
    },
  }),
)

export const TabPanel = (props: ITabPanelProps): JSX.Element => {
  const { children, value, index, ...other } = props
  const classes = useStyles()
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      className={classes.panelContainer}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Box>
  )
}
