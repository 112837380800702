import React, { useState } from 'react'
import { Actions } from 'modules/posts'
import { Actions as PostGroupActions } from 'modules/post-groups'
import { IsNullUndefinedOrEmpty } from 'core/utils/isNullOrUndefined'
import { IPost } from 'types/modules/posts/models/entities/post'
import { IPostResult } from 'types/modules/posts/models/entities/post-result'
import { IPostQuestion } from 'types/modules/posts/models/entities/post-question'
import { useDispatch } from 'react-redux'
import { IPostActionParams } from 'modules/posts/actions'
import { Tag } from 'types/modules/tags/models/entities/tag'
import _ from 'core/utils/deepdash'
import { MediaUploadModel } from 'types/common/images/models/entities/image-upload-model'
import { mapMimeType } from 'core/utils/mime-mapper'
import { IPostResultDraft } from 'types/modules/posts/models/entities/post-result-draft'
import { IPostQuestionDraft } from 'types/modules/posts/models/entities/post-question-draft'
import { MediaItem } from 'types/modules/media-items/models/entities/media-item'
import { usePostListContext } from 'modules/posts/context/post-list'
import { sortBy } from 'lodash-es'
import { useRouter } from 'core/routing/hooks/use-router'


export interface IPostManagerContext {
  addPostQuestion: (postId: string, order: number) => Promise<IPostQuestion>
  addPostResult: (postId: string) => Promise<IPostResult>
  updatePost: (post: IPost, withTags: boolean) => void
  updatePostTags: (postId: string, tags: Tag[]) => void
  uploadPostImage: (postId: string, fileParams: MediaUploadModel) => void
  duplicatePost: (postId: string) => void
  updatePostMedia: (mediaItem: MediaItem) => void
  updatingPost: boolean
  toggleFeaturePost: (post: IPost) => void
  createPost: (post: Partial<IPost>) => void
  isOrderRepeated: boolean
  reorderQuestions: () => void
  updatePostGroup: (response: any) => void
}

export const PostManagerContext = React.createContext<IPostManagerContext>(
  {} as IPostManagerContext,
)

export const PostManagerProvider: React.FC = (props) => {
  const { setCurrentPost, currentPost } = usePostListContext()

  const [updatingPost, setUpdatingPost] = useState(false)

  const dispatch = useDispatch()

  const { routeParams } = useRouter()

  const toggleFeaturePost = (post: IPost): void => {
    const newPost = _.clone(post)
    newPost.featured = !newPost.featured
    dispatch(
      Actions.updatePost({
        postId: post.id,
        item: newPost,
      }),
    )
  }

  const updatePostGroup = (response: any) => {
    if (routeParams.postGroupId) {
      dispatch(
        PostGroupActions.getPostGroupPosts({
          postGroupId: routeParams.postGroupId,
        })
      )
    }
  }

  const createPost = (post: Partial<IPost>): void => {
    new Promise<IPost>((resolve, reject) => {
      dispatch(
        Actions.createPost({
          item: post,
          promise: {
            onResolve: (response) => {
              updatePostGroup(response)
              resolve(response)
            },
            onReject: reject,
          },
          queryParams: !IsNullUndefinedOrEmpty(post.tagIds)
            ? {
                updateTags: true,
                // eslint-disable-next-line id-blacklist
              }
            : undefined,
        }),
      )
    }).then((response: IPost) => {
      setCurrentPost(response.id, response.type)
    })
  }

  const updatePost = (post: IPost, withTags: boolean = false): void => {
    setUpdatingPost(true)
    const params = {
      postId: post.id,
      item: {
        ...post,
        media: {
          ...currentPost?.media,
          newsLink: post.media?.newsLink,
          description: post.media?.description,
        },
      },
    } as IPostActionParams

    if (withTags) {
      params.queryParams = {
        updateTags: true,
      }
    }

    dispatch(
      Actions.updatePost({
        ...params,
        promise: {
          onResolve: (response): void => {
            updatePostGroup(response)
            setUpdatingPost(false)
          },
          onReject: (): void => setUpdatingPost(false),
        },
      }),
    )
  }

  const updatePostMedia = (mediaItem: MediaItem): void => {
    setUpdatingPost(true)
    const params = {
      postId: currentPost?.id,
      item: {
        ...currentPost,
        media: {
          ...currentPost?.media,
          ...(mediaItem.media ?? {}),
          mediaItemId: mediaItem.id,
        },
      },
    } as IPostActionParams

    dispatch(
      Actions.updatePost({
        ...params,
        promise: {
          onResolve: (response): void => {
            updatePostGroup(response)
            setUpdatingPost(false)
          },
          onReject: (): void => setUpdatingPost(false),
        },
      }),
    )
  }

  const addPostQuestion = (
    postId: string,
    order: number,
  ): Promise<IPostQuestion> => {
    return new Promise((resolve, reject) => {
      dispatch(
        Actions.addQuestion({
          postId,
          item: {
            postId,
            text: '',
            order,
          } as IPostQuestionDraft,
          promise: {
            onResolve: (response) => {
              updatePostGroup(response)
              resolve(response)
            },
            onReject: reject,
          },
        }),
      )
    })
  }

  const addPostResult = (postId: string): Promise<IPostResult> => {
    return new Promise((resolve, reject) => {
      dispatch(
        Actions.addResult({
          postId,
          item: {
            postId,
            text: '',
          } as IPostResultDraft,
          promise: {
            onResolve: (response) => {
              updatePostGroup(response)
              resolve(response)
            },
            onReject: reject,
          },
        }),
      )
    })
  }

  const updatePostTags = (postId: string, tags: Tag[]): void => {
    dispatch(
      Actions.updatePostTags({
        postId: postId,
        items: [...tags.map((x) => x.id!)],
        promise: {
          onResolve: (response) => {
            updatePostGroup(response)
          }
        },
      }),
    )
  }

  const duplicatePost = (postId: string): void => {
    dispatch(
      Actions.duplicatePost({
        postId,
        callbackFn: (newPost: IPost) => {
          setCurrentPost(newPost.id, newPost.type)
        },
      }),
    )
  }

  const uploadPostImage = (
    postId: string,
    fileParams: MediaUploadModel,
  ): void => {
    dispatch(
      Actions.uploadPostImage({
        postId,
        item: fileParams.binaryData,
        extension: `image.${fileParams.fileExtension}`,
        overrideHeaders: {
          'content-type': mapMimeType(fileParams.fileExtension!),
          accept: '*/*',
        },
        promise: {
          onResolve: (response) => {
            updatePostGroup(response)
          },
        },
      }),
    )
  }

  const questionsOrder: { [key: number]: string } = {}
  const isOrderRepeated = (currentPost?.questions || []).some((question: IPostQuestion) => {
    if (questionsOrder[question.order]) {
      return true
    }
    questionsOrder[question.order] = question.id
    return false
  })

  const reorderQuestions = async (): Promise<void> => {
    setUpdatingPost(true)
    const reorderingRequest: Promise<void>[] = [];

    sortBy(currentPost?.questions || [], 'order').forEach((question: IPostQuestion, index: number) => {
      reorderingRequest.push(
        new Promise((onResolve, onReject) => {
          dispatch(
            Actions.updateQuestion({
              alertsEnabled: index === (currentPost?.questions || []).length - 1,
              questionId: question.id,
              postId: currentPost?.id,
              item: {
                ...question,
                order: index
              },
              promise: {
                onResolve: (response) => {
                  updatePostGroup(response)
                  onResolve(response)
                },
                onReject,
              },
            }),
          )
        })
      )
    })

    await Promise.all(reorderingRequest)
    setUpdatingPost(false)
  }

  const context: IPostManagerContext = {
    addPostQuestion,
    addPostResult,
    updatePost,
    updatePostTags,
    uploadPostImage,
    duplicatePost,
    updatePostMedia,
    updatingPost,
    createPost,
    toggleFeaturePost,
    isOrderRepeated,
    reorderQuestions,
    updatePostGroup
  }

  return (
    <PostManagerContext.Provider value={context}>
      {props.children}
    </PostManagerContext.Provider>
  )
}

export const usePostManagerContext = (): IPostManagerContext =>
  React.useContext(PostManagerContext)
