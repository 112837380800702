export enum InfoCardField {
  ID = 'id',
  TITLE = 'title',
  BODY = 'body',
  STATUS = 'status',
  MEDIA = 'media',
  IMAGE_URL = 'imageUrl',
  FOOTER_TEXT = 'footerText',
  CREATED_AT = 'createdAt',
  CREATED_BY = 'createdBy',
  UPDATED_AT = 'updatedAt',
  UPDATED_BY = 'updatedBy',
}
