import { IKeyValueStringCollection } from 'types/common/interfaces/IKeyValueStringCollection'

const filters: IKeyValueStringCollection = {
  status: '(status)',
  visibility: '(visibility)',
  name: '(name)',
  type: '(type)',
  start: '(start)',
  close: '(close)',
}

export default filters
