import { useRouter } from 'core/routing/hooks/use-router'
import React, { PropsWithChildren, useMemo } from 'react'
import { Actions } from 'modules/posts'
import { AnyNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { IPostResult } from 'types/modules/posts/models/entities/post-result'
import { useDispatch } from 'react-redux'
import _ from 'core/utils/deepdash'
import { MediaUploadModel } from 'types/common/images/models/entities/image-upload-model'
import { mapMimeType } from 'core/utils/mime-mapper'
import { IEvent } from 'types/modules/events/models/entities/event'
import { usePostListContext } from 'modules/posts/context/post-list'

export interface IPostResultsManager {
  currentResult: IPostResult | null
  updatePostResult: (result: IPostResult) => void
  uploadResultImage: (
    postId: string,
    resultId: string,
    fileParams: MediaUploadModel,
  ) => void
  deleteResult: (postId: string, resultId: string) => void
  outcomeTotal: number
}

export const PostResultsManager = React.createContext<IPostResultsManager>(
  {} as IPostResultsManager,
)

interface IPostResultsManagerProps {
  event?: IEvent
}

export const PostResultsManagerProvider: React.FC<IPostResultsManagerProps> = (
  props: PropsWithChildren<IPostResultsManagerProps>,
) => {
  const { routeParams } = useRouter()

  const { resultId } = routeParams

  const { currentPost } = usePostListContext()

  const currentResult = !AnyNullOrUndefined(currentPost, resultId)
    ? (currentPost?.results || []).find((r) => r.id === resultId) ?? null
    : null

  const dispatch = useDispatch()

  const updatePostResult = (result: IPostResult): void => {
    dispatch(
      Actions.updateResult({
        postId: result.postId,
        resultId: result.id,
        item: result,
      }),
    )
  }

  const deleteResult = (postId: string, resultId: string): void => {
    dispatch(
      Actions.deleteResult({
        postId,
        resultId,
      }),
    )
  }

  const uploadResultImage = (
    postId: string,
    resultId: string,
    fileParams: MediaUploadModel,
  ): void => {
    dispatch(
      Actions.uploadResultImage({
        postId,
        resultId,
        item: fileParams.binaryData,
        extension: `image.${fileParams.fileExtension}`,
        overrideHeaders: {
          'content-type': mapMimeType(fileParams.fileExtension!),
          accept: '*/*',
        },
      }),
    )
  }

  const outcomeTotal = useMemo(() => {
    return (currentPost?.results || []).reduce(
      (acc, curr) => acc + (curr.totalOutcomes || 0),
      0,
    )
  }, [currentPost?.results])

  const context: IPostResultsManager = {
    currentResult,
    updatePostResult,
    uploadResultImage,
    outcomeTotal,
    deleteResult,
  }

  return (
    <PostResultsManager.Provider value={context}>
      {props.children}
    </PostResultsManager.Provider>
  )
}

export const usePostResultsManager = (): IPostResultsManager =>
  React.useContext(PostResultsManager)
