import { Box, ButtonBase, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx'
import { CommentIcon, MobileIcon } from 'components/icons'
import React from 'react'
import { PostDynamicViewMode } from 'types/modules/posts/enums/post-dynamic-view-mode'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentNavigation: {
      position: 'absolute',
      right: 0,
      top: 'calc(50% - 56px)',
      marginRight: 16,
      display: 'flex',
      flexDirection: 'column',
      '&.disabled': {
        opacity: 0.3,
      },
    },
    navItemButton: {
      display: 'flex',
      marginBottom: 16,
      borderRadius: 90,
    },
    contentNavigationNavItem: {
      display: 'flex',
      width: 40,
      height: 40,
      borderRadius: 90,
      alignItems: 'center',
      justifyContent: 'center',
      background: 'rgba(0,0,0,0.03)',
      color: theme.palette.text.primary,
      '&.active': {
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      },
    },
    navItemIcon: {
      fontSize: 16,
    },
  }),
)

interface IContentNavigationProps {
  activeMode: PostDynamicViewMode
  onChange: (mode: PostDynamicViewMode) => void
}

export const ContentNavigation: React.FC<IContentNavigationProps> = (
  props: IContentNavigationProps,
) => {
  const { activeMode, onChange } = props

  const classes = useStyles()

  const renderIcon = (mode: PostDynamicViewMode): JSX.Element => {
    switch (mode) {
      case PostDynamicViewMode.Preview:
        return <MobileIcon />
      case PostDynamicViewMode.Comments:
        return <CommentIcon />
    }
  }

  const renderNavigationButton = (mode: PostDynamicViewMode): JSX.Element => {
    const active = activeMode === mode

    return (
      <ButtonBase
        key={`nav-btn-${mode}`}
        className={clsx(classes.navItemButton, active && 'active')}
        onClick={(): void => onChange(mode)}
      >
        <Box
          className={clsx(classes.contentNavigationNavItem, active && 'active')}
        >
          <Box className={classes.navItemIcon}>{renderIcon(mode)}</Box>
        </Box>
      </ButtonBase>
    )
  }

  return (
    <Box className={classes.contentNavigation}>
      {Object.values(PostDynamicViewMode).map((mode) =>
        renderNavigationButton(mode),
      )}
    </Box>
  )
}
