import { createAction } from 'core/redux/utils'
import { IFilter } from 'types/common/filtering/models/entities/IFilter'
import { ISimpleAction } from 'types/redux/interfaces/IAction'
import Actions from './action-types'

export const applyFilter = (filter: IFilter): ISimpleAction =>
  createAction(Actions.APPLY_FILTER, filter)

export const removeFilter = (payload: { field: string }): ISimpleAction =>
  createAction(Actions.REMOVE_FILTER, payload)
