import React, {
  useMemo,
  useState,
  createContext,
  PropsWithChildren,
  useContext,
  useCallback,
} from 'react'
import {
  createEditor,
  Descendant,
  Transforms,
  Element,
  Editor,
  Text,
  Node,
} from 'slate'
import { CustomEditor } from 'components/goss-editor/types/custom-editor'
import { CustomElement } from 'components/goss-editor/types/custom-element'
import { ElementType } from 'components/goss-editor/types/element-type'
import { MarkType } from 'components/goss-editor/types/mark-type'
import { withHistory } from 'slate-history'
import { useSlate, withReact } from 'slate-react'
import {
  EditableProps,
  RenderElementProps,
  RenderLeafProps,
} from 'slate-react/dist/components/editable'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { Typography } from '@mui/material'
import { isHotkey } from 'is-hotkey'

export interface IGossEditorContext {
  editor: CustomEditor
  editableProps: EditableProps
  value: Descendant[]
  setValue: (value: Descendant[]) => void
  isMarkActive: (type: MarkType, editor: CustomEditor) => boolean
  isBlockActive: (type: ElementType, editor: CustomEditor) => boolean
  toggleMark: (type: MarkType, editor: CustomEditor) => void
  toggleBlock: (type: ElementType, editor: CustomEditor) => void
  renderElement: ({
    attributes,
    children,
    element,
  }: RenderElementProps) => JSX.Element
  renderLeaf: ({ attributes, children, leaf }: RenderLeafProps) => JSX.Element
  onKeyDown: (event: React.KeyboardEvent<HTMLDivElement>) => void
  resetEditor: () => void
  submit: () => void
  isDirty: () => boolean
}

export const GossEditorContext = createContext<IGossEditorContext>(
  {} as IGossEditorContext,
)

export interface IEditorSubmitResult {
  value: Descendant[]
  callbackFn: () => void
}

export interface IGossEditorProviderProps {
  initialValue?: Descendant[]
  editableProps: EditableProps
  onSubmit: (result: IEditorSubmitResult) => void
}

const HOTKEYS: { [key: string]: MarkType } = {
  'mod+b': MarkType.Bold,
  'mod+i': MarkType.Italic,
  'mod+u': MarkType.Underline,
  'mod+`': MarkType.Code,
}

enum ListTypes {
  NumberedList = 'numbered-list',
  BulletedList = 'bulleted-list',
  CheckList = 'check-list',
}

export const GossEditorProvider: React.FC<IGossEditorProviderProps> = (
  props: PropsWithChildren<IGossEditorProviderProps>,
) => {
  const { editableProps, children } = props

  const editor = useMemo(() => withHistory(withReact(createEditor())), [])

  const initialValue = !IsNullOrUndefined(props.initialValue)
    ? props.initialValue!
    : [
        {
          type: ElementType.Paragraph,
          children: [
            {
              text: '',
            },
          ],
        },
      ]

  const [value, setValue] = useState<Descendant[]>(initialValue)

  const isMarkActive = (type: MarkType, editor: CustomEditor): boolean => {
    const marks = Editor.marks(editor)
    return marks ? marks[type] === true : false
  }

  const isBlockActive = (type: ElementType, editor: CustomEditor): boolean => {
    const { selection } = editor
    if (!selection) return false

    const [match] = Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) =>
        !Editor.isEditor(n) && Element.isElement(n) && n.type === type,
    })

    return !!match
  }

  const toggleMark = (type: MarkType, editor: CustomEditor): void => {
    const isActive = isMarkActive(type, editor)

    if (isActive) {
      editor.removeMark(type)
    } else {
      editor.addMark(type, true)
    }
  }

  const toggleBlock = (type: ElementType, editor: CustomEditor): void => {
    const isActive = isBlockActive(type, editor)

    const isList = Object.values(ListTypes)
      .map((t) => t.toString())
      .includes(type)

    Transforms.unwrapNodes(editor, {
      match: (node) =>
        !Editor.isEditor(node) &&
        Element.isElement(node) &&
        Object.values(ListTypes)
          .map((t) => t.toString())
          .includes(type),
      split: true,
    })

    const newProperties: Partial<CustomElement> = {
      type: isActive
        ? ElementType.Paragraph
        : isList
        ? ElementType.ListItem
        : type,
    }

    Transforms.setNodes(editor, newProperties)

    if (!isActive && isList) {
      const block = { type, children: [] }
      Transforms.wrapNodes(editor, block)
    }
  }

  const renderElement = useCallback(
    ({ attributes, children, element }: RenderElementProps): JSX.Element => {
      switch (element.type) {
        case ElementType.BlockQuote:
          return (
            <Typography variant="caption" {...attributes}>
              {children}
            </Typography>
          )
        case ElementType.BulletedList:
          return <ul {...attributes}>{children}</ul>
        case ElementType.HeadingOne:
          return (
            <Typography variant={'h5'} {...attributes}>
              {children}
            </Typography>
          )
        case ElementType.HeadingTwo:
          return (
            <Typography variant={'h6'} {...attributes}>
              {children}
            </Typography>
          )
        case ElementType.ListItem:
          return <li {...attributes}>{children}</li>
        case ElementType.NumberedList:
          return <ol {...attributes}>{children}</ol>
        default:
          return (
            <Typography variant={'body2'} {...attributes}>
              {children}
            </Typography>
          )
      }
    },
    [],
  )

  const renderLeaf = useCallback(
    ({ attributes, children, leaf }: RenderLeafProps) => {
      if (leaf.bold) {
        children = <strong>{children}</strong>
      }

      if (leaf.code) {
        children = <code>{children}</code>
      }

      if (leaf.italic) {
        children = <em>{children}</em>
      }

      if (leaf.underline) {
        children = <u>{children}</u>
      }

      return <span {...attributes}>{children}</span>
    },
    [],
  )

  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    for (const hotkey in HOTKEYS) {
      if (isHotkey(hotkey, event as any)) {
        event.preventDefault()
        const markType = HOTKEYS[hotkey]
        toggleMark(markType, editor)
      }
    }
  }

  const resetEditor = (): void => {
    const point = {
      path: [0, 0],
      offset: 0,
    }
    editor.selection = { anchor: point, focus: point } // clean up selection
    editor.history = { redos: [], undos: [] } // clean up history
    setValue(initialValue)
    editor.children = initialValue
  }

  const submit = (): void => {
    props.onSubmit({
      value,
      callbackFn: resetEditor,
    })
  }

  const isDirty = (): boolean => {
    return value !== initialValue
  }

  const context: IGossEditorContext = {
    editor,
    editableProps,
    value,
    setValue,
    isMarkActive,
    isBlockActive,
    toggleMark,
    toggleBlock,
    renderElement,
    renderLeaf,
    onKeyDown,
    resetEditor,
    submit,
    isDirty,
  }

  return (
    <GossEditorContext.Provider value={context}>
      {children}
    </GossEditorContext.Provider>
  )
}

export const useGossEditor = (): IGossEditorContext =>
  useContext(GossEditorContext)
