import { Box, Grid } from '@mui/material';
import { LiveOpsAction, GAME_TASK_STEP_PARAM_CONFIG } from 'fe-shared-resources';
import { GameTaskStepParamsElement } from 'modules/quests/view/game-tasks/game-task-modal/game-task-step-params-element';
import React, { useEffect } from 'react'
import { IForm } from 'types/common/interfaces/IForm';

interface GameTaskStepParamsFormProps extends IForm {
 value: { [key: string]: string }
 paramType: LiveOpsAction
 prefix: string
 onSetDefault: (currentVariant: string, isDefault: boolean) => void
 currentVariant: string
 stepParamsErrors: { [key: string]: string }
}

export const GameTaskStepParamsForm = ({
  value,
  setFieldValue,
  handleChange,
  paramType,
  prefix,
  onSetDefault,
  currentVariant,
  stepParamsErrors
}: GameTaskStepParamsFormProps): JSX.Element | null => {
  const onChangeIsDefault = (e: any): void => {
    setFieldValue(`${prefix}.isDefault`, !!e.target.value)
    onSetDefault(currentVariant, !!e.target.value)
    handleChange({
      target: {
        name: `${prefix}.isDefault`,
        // @ts-expect-error ignore for now
        value: !!e.target.value
      }
    })
  }

  useEffect(() => {
    const config = GAME_TASK_STEP_PARAM_CONFIG[paramType] || {}
    const initialValue = Object.keys(config).reduce((acc, key) => ({
      ...acc,
      [key]: value[key] || GAME_TASK_STEP_PARAM_CONFIG[paramType][key]?.defaultValue
    }), {})
    setFieldValue(prefix, {
      ...initialValue,
    })
  }, [paramType])

  if (!GAME_TASK_STEP_PARAM_CONFIG[paramType]) {
    return null
  }

  const formElementMap = GAME_TASK_STEP_PARAM_CONFIG[paramType]

  return (
    <Box>
      <Grid container spacing={2}>
        {
          Object.keys(formElementMap).map((key) => {
            const elementKey = key
            const elementData = formElementMap[elementKey]!
            return <Grid item xs={elementData.gridSize || 12} key={key}>
              <GameTaskStepParamsElement
                key={key}
                name={key}
                value={value[key]}
                label={elementData.label || key.toLocaleUpperCase()}
                error={stepParamsErrors[`${currentVariant}.${key}`]}
                elementType={elementData.type}
                handleChange={elementKey === 'isDefault' ? onChangeIsDefault : handleChange}
                options={elementData.options}
                prefix={prefix}
                setFieldValue={setFieldValue}
              />
            </Grid>
          })
        }
      </Grid>
    </Box>
  );
}
