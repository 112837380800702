import { Box, Button, ButtonBase, Divider, SvgIcon, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { EditIcon, ImageIcon } from 'components/icons'
import { useRouter } from 'core/routing/hooks/use-router'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { useEventsContext } from 'modules/events/context'
import { getStatusLabel } from 'modules/events/utils/get-status-label'
import { CloseEventButton } from 'modules/events/views/components/action-buttons/close'
import { EventDetailsModal } from 'modules/events/views/components/event-details-panel/modal'
import { EventPosts } from 'modules/events/views/components/single-event-dashboard/event-posts'
import { EventRewards } from 'modules/events/views/components/single-event-dashboard/event-rewards'
import { EventSchedules } from 'modules/events/views/components/single-event-dashboard/event-schedule'
import moment from 'moment-timezone'
import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { EventPage } from 'types/modules/events/enums/event-page'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    eventDashboard: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      height: '100%',
    },
    dashboardHeader: {
      display: 'flex',
      flexShrink: 0,
      width: '100%',
      padding: '0.625rem',
    },
    coverImageContainer: {
      display: 'flex',
      width: 90,
      height: 90,
      background: 'rgba(0,0,0,0.03)',
      borderRadius: 10,
      overflow: 'hidden',
      marginRight: '0.625rem',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
    },
    editButton: {
      color: theme.palette.text.primary,
      backgroundColor: '#ecf0f1',
      '&:hover': {
        backgroundColor: '#f2f5f5',
      },
    },
    eventNavigationContainer: {
      display: 'flex',
      marginLeft: 'auto',
    },
    eventNavButton: {
      display: 'flex',
      width: 90,
      height: 90,
      marginRight: '0.625rem',
      background: 'rgba(0,0,0,0.03)',
      '&:last-child': {
        marginRight: 0,
      },
    },
    eventNavButtonLink: {
      display: 'flex',
      width: '100%',
      height: '100%',
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      '&.active': {
        '&::after': {
          display: 'block',
          position: 'absolute',
          width: '100%',
          height: '0.3125rem',
          content: '""',
          bottom: 0,
          background: theme.palette.primary.main,
        },
      },
    },
    eventNavButtonLabel: {
      color: 'rgba(0,0,0,0.54)',
    },
    eventDetails: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    eventActionsMetadata: {
      display: 'flex',
      flexDirection: 'row',
      '& .MuiButton-root': {
        marginRight: '0.625rem',
      },
    },
    comingSoonContainer: {
      display: 'flex',
      flex: 1,
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
)

export const SingleEventDashboard: React.FC<{}> = () => {
  const { currentEvent: event } = useEventsContext()
  const { location, routeParams, history } = useRouter()

  const [editModalOpen, setEditModelOpen] = useState<boolean>(false)

  const { eventPage } = routeParams

  const classes = useStyles()

  const hasCoverImage = !IsNullOrUndefined(event?.media?.imageUrl)
  const eventCoverContainerStyles: { [key: string]: string | number } = {}

  if (hasCoverImage) {
    eventCoverContainerStyles.backgroundImage = `url(${event?.media!
      .imageUrl!})`
  } else {
    eventCoverContainerStyles.background = 'rgba(0,0,0,0.03)'
  }

  const renderEventPage = (): JSX.Element | null => {
    if (IsNullOrUndefined(event)) return null

    switch (eventPage) {
      case EventPage.Posts:
        return <EventPosts event={event!} />

      case EventPage.Schedule:
        return <EventSchedules event={event!} />

      case EventPage.Rewards:
        return <EventRewards event={event!} />

      default:
        return (
          <Box className={classes.comingSoonContainer}>
            <Typography variant="subtitle1" color="textPrimary">
              Coming Soon...
            </Typography>
          </Box>
        )
    }
  }

  const eventNavigationConfig = [
    {
      route: `/events/${event?.id}/posts/list`,
      label: 'Posts',
    },
    {
      route: `/events/${event?.id}/rewards`,
      label: 'Rewards',
    },
    {
      route: `/events/${event?.id}/quests`,
      label: 'Quests',
    },
    {
      route: `/events/${event?.id}/schedule/list`,
      label: 'Schedule',
    },
  ]

  const renderDates = (): string => {
    const dateFormat = 'D MMMM YYYY HH:mm'
    let result = moment(event?.start).format(dateFormat)
    if (!IsNullOrUndefined(event?.end)) {
      result += ` - ${moment(event?.end).format(dateFormat)}`
    }
    return result
  }

  return (
    <React.Fragment>
      <Box className={classes.eventDashboard}>
        <Box className={classes.dashboardHeader}>
          <Box
            className={classes.coverImageContainer}
            style={eventCoverContainerStyles}
          >
            {!hasCoverImage && (
              <SvgIcon>
                <ImageIcon />
              </SvgIcon>
            )}
          </Box>
          <Box className={classes.eventDetails}>
            <Typography
              variant="subtitle1"
              color="textPrimary"
              component="span"
            >
              {`${event?.media?.icon ? event?.media?.icon + ' ' : ''}${
                event?.name ?? ''
              }${
                event?.media?.description ? ': ' + event.media.description : ''
              }`}
            </Typography>
            <Typography variant="body2" color="textPrimary" component="span">
              {renderDates()}
            </Typography>
            <Box className={classes.eventActionsMetadata}>
              {event && (
                <React.Fragment>
                  <Button
                    variant="contained"
                    startIcon={<EditIcon />}
                    className={classes.editButton}
                    size="small"
                    onClick={(): void => setEditModelOpen(true)}
                  >
                    Edit
                  </Button>
                  <CloseEventButton event={event} />
                </React.Fragment>
              )}
              <Typography
                variant="button"
                color="primary"
                component="span"
                style={{
                  position: 'relative',
                  top: 5,
                }}
              >
                {getStatusLabel(event?.status!)}
              </Typography>
            </Box>
          </Box>
          <Box className={classes.eventNavigationContainer}>
            {eventNavigationConfig.map((config) => (
              <ButtonBase key={config.label} className={classes.eventNavButton}>
                <NavLink
                  to={config.route}
                  className={classes.eventNavButtonLink}
                  activeClassName="active"
                >
                  <Typography
                    variant="button"
                    className={classes.eventNavButtonLabel}
                  >
                    {config.label}
                  </Typography>
                </NavLink>
              </ButtonBase>
            ))}
          </Box>
        </Box>
        <Divider />
        {renderEventPage()}
      </Box>
      {editModalOpen && (
        <EventDetailsModal
          currentEvent={event}
          open={editModalOpen}
          closeModal={(): void => {
            setEditModelOpen(false)
          }}
        />
      )}
    </React.Fragment>
  )
}
