/* Types */
import { EndpointRoute } from 'types/common/api/models/entities/endpoint-route'
import { RequestMethod } from 'types/common/api/enums/request-method'

/* Module */
import filters from './filters'
import params from './params'
import paths from './paths'

const InfoCardEndpoints: { [key: string]: EndpointRoute } = {
  getInfoCardStatuses: {
    method: RequestMethod.GET,
    path: [paths.infoCards, paths.statuses],
  },
  getInfoCards: {
    method: RequestMethod.GET,
    path: [paths.infoCards],
    filters,
  },
  getInfoCardById: {
    method: RequestMethod.GET,
    path: [paths.infoCards, params.infoCardId],
  },
  createInfoCard: {
    method: RequestMethod.POST,
    path: [paths.infoCards],
  },
  updateInfoCard: {
    method: RequestMethod.PUT,
    path: [paths.infoCards, params.infoCardId],
  },
  updateInfoCardStatus: {
    method: RequestMethod.PUT,
    path: [
      paths.infoCards,
      params.infoCardId,
      paths.updateStatus,
      params.infoCardStatus,
    ],
  },
  uploadInfoCardImage: {
    method: RequestMethod.PUT,
    path: [paths.infoCards, params.infoCardId, paths.images, paths.upload],
  },
}

export default InfoCardEndpoints
