import { FilterType } from 'types/common/filtering/enums/FilterType'
import { FilterOperator } from 'types/common/filtering/enums/FilterOperator'
import { Actions as PrizeActions } from 'modules/prizes'
import { PrizeField } from 'types/modules/prizes/enums/prize-field'
import PrizeStatus from 'types/modules/prizes/enums/prize-status'
import { IPrizeActionParams } from 'modules/prizes/actions'
import { IAsyncAction } from 'types/redux/interfaces/IAction'
import { PrizeType } from 'fe-shared-resources'

export const searchPrizes = (params: IPrizeActionParams): IAsyncAction => {
  const filters = [
    {
      field: PrizeField.STATUS,
      type: FilterType.Select,
      operator: FilterOperator.NotEquals,
      value: [
        ...Object.values(PrizeStatus).filter((status) =>
          [PrizeStatus.Created, PrizeStatus.Trashed].includes(status),
        ),
      ],
    },
    {
      field: PrizeField.NAME,
      type: FilterType.Search,
      operator: FilterOperator.ContainsCaseInsensitive,
      value: params.search!.value,
    },

      {
        field: PrizeField.TYPE,
        type: FilterType.Select,
        operator: FilterOperator.Equals,
        value: [PrizeType.Post, PrizeType.Instant],
      },
  ]

  return PrizeActions.autocompletePrizeSearch({
    filters,
  })
}
