import { ButtonProps, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx'
import { ConfirmationDialog } from 'components/confirmation-dialog'
import { CloseIcon } from 'components/icons'
import { usePrizeStatusManagerContext } from 'modules/prizes/context/prize-status-manager'
import React from 'react'
import PrizeStatus from 'types/modules/prizes/enums/prize-status'
import { IPrizeActionButtonProps } from 'types/modules/prizes/models/props/prize-action-button-props'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.text.primary,
      backgroundColor: '#ecf0f1',
      '&:hover': {
        backgroundColor: '#f2f5f5',
      },
    },
  }),
)

export const ClosePrizeButton: React.FC<IPrizeActionButtonProps> = (
  props: IPrizeActionButtonProps,
) => {
  const { closePrize } = usePrizeStatusManagerContext()

  const classes = useStyles()

  const isActive = props.prize.status === PrizeStatus.Open

  const buttonProps: ButtonProps = {
    variant: 'contained',
    startIcon: <CloseIcon />,
    className: clsx(classes.root),
    size: 'small',
    disabled: props.disabled,
  }

  const popoverButtonProps = {
    ...buttonProps,
    disabled: props.disabled,
  }

  const buttonLabel = 'close'

  return isActive ? (
    <ConfirmationDialog
      popoverId={`${buttonLabel}-prize-${props.prize.id}`}
      buttonProps={popoverButtonProps}
      onConfirmation={(): void => closePrize(props.prize.id)}
      confirmationText={`Are you sure you want to ${buttonLabel} this prize?`}
      buttonLabel={buttonLabel}
      onPopoverClose={props.onPopoverClose}
    />
  ) : null
}
