import { Typography } from '@mui/material'
import { IsNullUndefinedOrEmpty } from 'core/utils/isNullOrUndefined'
import React from 'react'
import { IUserPrize } from 'types/modules/shipping/models/entities/user-prize'
import { getUserInfoForShipping, getUserInfoForShippingSource } from 'utils/shipping'

interface IProps {
  userPrize: IUserPrize | null
}

type TextColor = 'textPrimary' | 'textSecondary'

export const UserInfo: React.FC<IProps> = ({ userPrize }) => {
  const userInfo = getUserInfoForShipping({
    shippingInfo: userPrize?.shippingDetails,
    userInfo: userPrize?.user
  })

  const userInfoSource = getUserInfoForShippingSource({
    shippingInfo: userPrize?.shippingDetails,
    userInfo: userPrize?.user
  })

  return (
    <>
      {IsNullUndefinedOrEmpty(userInfo.name) ? null : (
        <Typography
          variant="subtitle2"
          color={userInfoSource.name as TextColor}
        >
          {userInfo.name}
        </Typography>
      )}
      {!IsNullUndefinedOrEmpty(userInfo.username) && (
        <Typography
          variant="subtitle2"
          color={userInfoSource.username as TextColor}
        >
          {userInfo.username}
        </Typography>
      )}
      {!IsNullUndefinedOrEmpty(userInfo.email) && (
        <Typography
          variant="subtitle2"
          color={userInfoSource.email as TextColor}
        >
          {userInfo.email}
        </Typography>
      )}
      {!IsNullUndefinedOrEmpty(userInfo.phone) && (
        <Typography
          variant="subtitle2"
          color={userInfoSource.phone as TextColor}
        >
          {userInfo.phone}
        </Typography>
      )}
    </>
  )
}
