import React from 'react'
import { Box, ButtonBase, Popover, SvgIcon, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { ICalendarGridCellProps } from 'components/calendar/props/calendar-grid-cell-props'
import { IPost } from 'types/modules/posts/models/entities/post'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { TimeGridPost } from 'modules/posts/views/components/dashboard/post-calender-time-grid-cell/post'
import { TimeGridPostMultiview } from 'modules/posts/views/components/dashboard/post-calender-time-grid-cell/multiview'
import { usePopover } from 'components/popover/use-popover'
import { PostTimeGridContextMenu } from 'modules/posts/views/components/dashboard/post-calender-time-grid-cell/context-menu'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    postGridCellContainer: {
      display: 'flex',
      flex: 1,
      '&:hover': {
        background: 'rgba(0,0,0,0.03)',
        cursor: 'pointer',
      },
    },
    emptyCell: {
      display: 'flex',
      flex: 1,
    },
  }),
)

export const PostCalendarTimeGridCell: React.FC<
  ICalendarGridCellProps<IPost>
> = (props: ICalendarGridCellProps<IPost>) => {
  const { items, dateTime, sortBy } = props

  const classes = useStyles()

  const contextMenu = usePopover({
    id: `context-menu-${dateTime}`,
    anchorReference: 'anchorPosition',
  })

  const renderEmptyView = (): JSX.Element | null => {
    return (
      <React.Fragment>
        <Box
          className={classes.emptyCell}
          onContextMenu={(e: React.MouseEvent<HTMLDivElement>): void => {
            e.preventDefault()
            contextMenu.onClick(e)
          }}
        />
        <PostTimeGridContextMenu
          dateTime={dateTime}
          dateField={sortBy}
          popoverProps={contextMenu.popoverProps}
        />
      </React.Fragment>
    )
  }

  const renderViewSelector = (): JSX.Element | null => {
    if (IsNullOrUndefined(items) || items.length === 0) {
      return renderEmptyView()
    }

    switch (items.length) {
      case 1:
        return <TimeGridPost post={items[0]} index={0} dateField={sortBy} />
      default:
        return (
          <TimeGridPostMultiview
            posts={items}
            dateTime={dateTime}
            dateField={sortBy}
          />
        )
    }
  }

  return (
    <Box className={classes.postGridCellContainer}>{renderViewSelector()}</Box>
  )
}
