import {
  Typography,
} from '@mui/material'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { getSourceLabel } from 'modules/shipping/utils/get-source-label'
import React from 'react'
import { IUserPrize } from 'types/modules/shipping/models/entities/user-prize'

interface IProps {
  userPrize: IUserPrize | null
}


export const SourceInfo: React.FC<IProps> = ({ userPrize }) => {
  if (IsNullOrUndefined(userPrize?.source)) {
    return null
  }

  return (
    <Typography
      variant="subtitle2"
      color="textPrimary"
    >
      {getSourceLabel(userPrize!)}
    </Typography>
  )
}
