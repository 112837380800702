import { Box, Grid, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import _ from 'core/utils/deepdash'
import { FormGen, FormTypes } from 'components/simple-form-gen'
import React from 'react'
import { IForm } from 'types/common/interfaces/IForm'
import { IEvergreenQuest } from 'modules/quests/models/evergreen-quest'
import { QuestTasksForm } from 'modules/quests/view/quests/quest-modal/quest-tasks-form'
import { IQuestTask } from 'modules/quests/models/quest'
import { ActionCode } from 'fe-shared-resources'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      width: '100%',
      height: '600px',
      '& .MuiGrid-item': {
        display: 'flex',
        flexDirection: 'column',
      },
    },
    questsWrapper: {
      borderLeft: '1px solid #f4f4f4',
      paddingLeft: 8,
      height: '100%',
    },
    container: {
      height: '100%'
    },
    questsError: {
      marginTop: -16
    }
  }),
)

interface IEvergreenQuestFormProps extends IForm {
  evergreenQuest: IEvergreenQuest
}

export const EvergreenQuestForm: React.FC<IEvergreenQuestFormProps> = ({
  evergreenQuest,
  handleChange,
  setFieldValue,
  errors
}): JSX.Element => {
  const classes = useStyles()

  const onChangeIsVisible = (e: any): void => {
    setFieldValue('isVisible', !!e.target.value)
  }

  const actionCodesOptions = Object.values(ActionCode).map((code) => ({ value: code, name: code}))
  const elements = [
    {
      name: 'name',
      label: 'Name',
      value: evergreenQuest?.name || '',
      type: FormTypes.TEXT_INPUT,
      otherProps: {
        error: !!errors?.name,
        helperText: errors?.name
      }
    },
    {
      name: 'description',
      label: 'Value description',
      value: evergreenQuest?.description || '',
      type: FormTypes.TEXT_INPUT,
      otherProps: {
        error: !!errors?.description,
        helperText: errors?.description
      }
    },
    {
      name: 'actionCode',
      label: 'Action code',
      value: evergreenQuest?.actionCode || '',
      type: FormTypes.SELECT,
      options: actionCodesOptions,
      otherProps: {
        error: !!errors?.actionCode,
        helperText: errors?.actionCode
      }
    },
    {
      name: 'maxLevelMessage',
      label: 'Max level copy',
      value: evergreenQuest?.maxLevelMessage || '',
      type: FormTypes.TEXT_INPUT,
    },
    {
      value: evergreenQuest?.isVisible,
      type: FormTypes.CHECKBOX,
      label: 'Is visible',
      name: 'isVisible',
      handleChange: onChangeIsVisible
    },
  ]

  const taskItem: IQuestTask = {
    id: 'taskCode',
    quantity: 1,
    code: evergreenQuest.taskCode,
    parameters: evergreenQuest.taskParameters
  }

  const onChangeTaskCode = (value: any, fieldName: any): void => {
    if (fieldName === 'code') {
      setFieldValue('taskCode', value)
    } else if (fieldName === 'parameters') {
      setFieldValue('taskParameters', value)
    }
  }

  return (
    <Box className={classes.form}>
      <Grid container spacing={2} className={classes.container}>
        <Grid item xs={5}>
          <Grid container spacing={2}>
              {elements.map((element) => (
                <Grid
                  item xs={12}
                  key={element.name}
                >
                  <FormGen
                    handleChange={handleChange}
                    {...element}
                  />
                </Grid>
              ))}

            </Grid>
        </Grid>
        <Grid
          item xs={7}
        >
          <Grid container spacing={2}>
            <QuestTasksForm setValue={onChangeTaskCode} item={taskItem} errors={errors?.taskCode} hideQuantity />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}
