import { ISelectOption } from 'components/local-list-wrapper/types'
import { get } from 'lodash-es'
import { useMemo, useState } from 'react'

interface IReturn<Type, FilterSettingType> {
  filteredList: Type[],
  fullList: Type[],
  pageNumber: number,
  searchValue: string,
  sortValue: string,
  setPageNumber: (num: number) => void
  setSearchValue: (val: string) => void
  setSortValue: (val: string) => void
  loadMore: () => void
  onChangeCustomFilterSettings: (value: FilterSettingType) => void
}

interface IParams<Type, FilterSettingType> {
  list: Type[],
  searchKey: string,
  customFilterFcn?: (list: Type[], setting: FilterSettingType) => Type[],
  sortOptions?: ISelectOption[]
}

function defaultFilterFcn<Type>(list: Type[], searchKey: string, searchValue: string): Type[] {
  return list.filter((item) => {
    return !!(get(item, searchKey) as string || '').toUpperCase().match(searchValue.toUpperCase())
  })
}

function defaultSortFcn<Type>(list: Type[], sortValue: string, selectedSortOption: ISelectOption): Type[] {
  if (!sortValue) return list
  return list.sort((a, b) => {
    const first = (get(a, sortValue) as string || '').toUpperCase()
    const second = (get(b, sortValue) as string || '').toUpperCase()
    if (first > second) return selectedSortOption.descending ? -1 : 1
    if (first < second) return selectedSortOption.descending ? 1 : -1
    return 0
  })
}

export function useLocalListManager<Type, FilterSettingType>({
  list,
  searchKey,
  customFilterFcn,
  sortOptions = []
}: IParams<Type, FilterSettingType>): IReturn<Type, FilterSettingType> {
  const [pageNumber, setPageNumber] = useState(1)
  const [searchValue, setSearchValue] = useState('')
  const [sortValue, setSortValue] = useState(sortOptions[0] ? sortOptions[0].value : '')
  const [customFilterSettings, setCustomerFilterSettings] = useState<FilterSettingType>()
  const selectedSortOption = useMemo(() => {
    return sortOptions.find(({ value }) => value === sortValue)
  }, [sortValue])

  const loadMore = (): void => {
    setPageNumber(pageNumber + 1)
  }

  const onChangeCustomFilterSettings = (value: FilterSettingType): void => {
    setCustomerFilterSettings(value)
  }

  return {
    filteredList: defaultSortFcn(customFilterFcn
      ? customFilterFcn(list, customFilterSettings as FilterSettingType)
      : defaultFilterFcn<Type>(list, searchKey, searchValue)
    , sortValue, selectedSortOption as ISelectOption),
    pageNumber,
    searchValue,
    fullList: list,
    sortValue,
    setPageNumber: (num: number): void => {
      setPageNumber(num)
    },
    setSearchValue: (val: string): void => {
      setSearchValue(val)
    },
    setSortValue: (val: string): void => {
      setSortValue(val)
    },
    loadMore,
    onChangeCustomFilterSettings,
  }
}