import { Box, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react'
import { ILiveSchedule } from 'types/modules/live-schedules/models/entities/live-schedule'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import moment from 'moment-timezone'
import { LiveScheduleActionButtons } from 'modules/live-schedules/views/components/action-buttons'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    liveScheduleListCard: {
      display: 'flex',
      width: '100%',
      marginBottom: '0.625rem',
      flexDirection: 'row',
      boxShadow: 'none',
      border: '1px solid rgba(0,0,0,0.12)',
      borderRadius: 4,
      '&:last-child': {
        marginBottom: 'calc(48px + 0.625rem)',
      },
    },
    liveScheduleContent: {
      padding: '0.625rem',
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
    },
    liveScheduleDetails: {
      display: 'flex',
      flexGrow: 1,
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    actionsAndMetadata: {
      display: 'flex',
      flexShrink: 1,
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    actions: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      '& .MuiButton-root': {
        marginRight: '0.625rem',
        '&:last-child': {
          marginRight: 0,
        },
      },
    },
    metadata: {
      display: 'flex',
      flexDirection: 'row-reverse',
    },
    metadataItem: {
      marginLeft: '1.25rem',
    },
  }),
)

export const LiveScheduleListItem: React.FC<ILiveSchedule> = (
  liveSchedule: ILiveSchedule,
) => {
  const classes = useStyles()

  const renderDates = (): string => {
    const dateFormat = 'D MMMM YYYY HH:mm'
    let result = moment(liveSchedule.start).format(dateFormat)
    if (!IsNullOrUndefined(liveSchedule.end)) {
      result += ` - ${moment(liveSchedule.end).format(dateFormat)}`
    }
    return result
  }

  const renderMetadata = (): JSX.Element => {
    return (
      <React.Fragment>
        <Box className={classes.metadataItem}>
          <Typography variant="body2" color="textPrimary" component="span">
            XP Multiplier =
          </Typography>
          <Typography variant="h6" color="textPrimary" component="span">
            {' ' + liveSchedule.xpMultiplier}
          </Typography>
        </Box>
      </React.Fragment>
    )
  }

  return (
    <Box className={classes.liveScheduleListCard}>
      <Box className={classes.liveScheduleContent}>
        <Box className={classes.liveScheduleDetails}>
          <Typography variant="subtitle1" color="textPrimary" component="span">
            {`${liveSchedule.name}${
              liveSchedule.description ? ': ' + liveSchedule.description : ''
            }`}
          </Typography>
          <Typography variant="body2" color="textPrimary" component="span">
            {renderDates()}
          </Typography>
        </Box>
        <Box className={classes.actionsAndMetadata}>
          <Box className={classes.actions}>
            <LiveScheduleActionButtons liveSchedule={liveSchedule} />
          </Box>
          <Box className={classes.metadata}>{renderMetadata()}</Box>
        </Box>
      </Box>
    </Box>
  )
}
