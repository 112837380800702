import { InputLabel } from '@mui/material'
import DownshiftMultiselect from 'components/controls/inputs/downshift/multiselect'
import { useSimpleFormStyles } from 'components/simple-form-gen'
import { useTypedSelector } from 'core/redux/utils'
import { QuestActions, Selectors } from 'modules/quests'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Tag } from 'types/modules/tags/models/entities/tag'

interface IQuestSelectProps {
  handleChange: (selectedTags: Tag[]) => void
  initialSelectedIds: string[]
}

export const QuestsSelect = ({ handleChange, initialSelectedIds }: IQuestSelectProps): JSX.Element | null => {
  const [counter, setCounter] = useState(0)
  const dispatch = useDispatch()
  const getQuest = useTypedSelector((state) => Selectors.getQuestGetter(state))
  const initiallySelectedOptions = initialSelectedIds.map((id) => getQuest(id))
  const quests = useTypedSelector((state) => Selectors.getQuests(state))
  const simpleFormClasses = useSimpleFormStyles()

  useEffect(() => {
    initialSelectedIds?.forEach((id) => {
      if (!getQuest(id)) {
        dispatch(QuestActions.getQuestById({
          questId: id,
          promise: {
            onResolve: (): void => setCounter(counter + 1)
          }
        }))
      }
    })
  }, [])

  if (initiallySelectedOptions.some(option => !option?.id)) return null

  return  <>
    <InputLabel className={simpleFormClasses.inputLabel}>
      Quests
    </InputLabel>
    <DownshiftMultiselect
      onSelectedItemsChange={(items): void => {
        handleChange(items)
      }}
      availableItems={
        quests.map((quest) => ({
          name: quest.media?.title || '',
          value: quest.id,
          questId: quest.id,
          id: '',
          questGroupId: ''
        }))
      }
      initialSelectedItems={
        initiallySelectedOptions.map((quest) => ({
          name: quest.media?.title || '',
          value: quest.id,
          questId: quest.id,
          id: '',
          questGroupId: ''
        }))
      }
      variant="filled"
    />
  </>
}