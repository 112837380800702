import { Box, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useGossEditor } from 'components/goss-editor/context'
import React from 'react'
import { Editable, Slate } from 'slate-react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    richTextContainer: {},
  }),
)

export const RichTextDisplay: React.FC<{}> = () => {
  const classes = useStyles()

  const {
    editor,
    editableProps,
    value,
    setValue,
    renderElement,
    renderLeaf,
    onKeyDown,
  } = useGossEditor()

  return (
    <Box className={classes.richTextContainer}>
      <Slate editor={editor} value={value} onChange={setValue}>
        <Editable
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          onKeyDown={onKeyDown}
          {...editableProps}
        />
      </Slate>
    </Box>
  )
}
