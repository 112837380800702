import { useAuth } from 'core/authentication/hooks/use-auth'
import { PortalPermissions } from 'core/authorization/rules'
import { AccessDenied, ROUTES } from 'core/routing'
import React from 'react'
import { Redirect } from 'react-router-dom'

export const RedirectController: React.FC<{}> = () => {
  const { currentUser } = useAuth()

  if (currentUser.can(PortalPermissions.DASHBOARD_VIEW)) {
    return <Redirect to={ROUTES.POST_LIST} />
  }

  if (currentUser.can(PortalPermissions.WATCHLIST_VIEW)) {
    return <Redirect to={ROUTES.WATCHLIST} />
  }

  return null
}
