import { actionTypeSuccess, cacheItem, cacheItems } from 'core/redux/utils'
import ActionTypes from 'modules/events/action-types'
import { Reducer } from 'redux'
import { IEventsState } from 'types/modules/events/models/state/IEventsState'
import { ISuccessAction, ISimpleAction } from 'types/redux/interfaces/IAction'
import _ from 'core/utils/deepdash'
import IPaginatedItems from 'types/common/pagination/models/entities/paginated-items'
import { IEvent } from 'types/modules/events/models/entities/event'
import { EventStatus } from 'types/modules/events/enums/event-status'
import { IEventActionParams } from 'modules/events/actions'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { mergeArrays } from 'core/utils/mergeArrays'

const initialState = (): IEventsState => {
  const state: IEventsState = {
    items: [],
    cache: {},
    statuses: {},
  }

  Object.values(EventStatus).forEach((value) => {
    state.statuses[value] = {
      count: 0,
      hasNotifications: false,
    }
  })

  return state
}

type AllowedActionTypes = ISuccessAction | ISimpleAction

const EventsReducer: Reducer<IEventsState, AllowedActionTypes> = (
  state = initialState(),
  action: AllowedActionTypes,
) => {
  const simpleAction = action as ISimpleAction
  const successAction = action as ISuccessAction

  const currentEvent = !IsNullOrUndefined(state.currentEventId)
    ? state.items.find((post) => post.id === state.currentEventId) ?? null
    : null

  switch (action.type) {
    case ActionTypes.SET_CURRENT_EVENT_ID: {
      const newState = _.cloneDeep(state)
      const { eventId } = simpleAction.payload as IEventActionParams

      if (IsNullOrUndefined(eventId)) {
        if (Object.hasOwnProperty.call(newState, 'currentEventId')) {
          const page = (newState as any).page ?? 0
          const pageSize = (newState as any).pageSize ?? 0
          if (page === 1 && newState.items.length > pageSize) {
            const currentPageIndex = newState.items.findIndex(
              (event) => event.id === currentEvent?.id,
            )

            _.pullAt(newState.items, currentPageIndex)
          }
          delete newState.currentEventId
        }
      } else {
        newState.currentEventId = eventId
      }

      return newState
    }
    case ActionTypes.CLEAR_EVENTS: {
      const newState = _.cloneDeep(state)
      if (IsNullOrUndefined(newState.currentEventId)) {
        newState.items = []
      } else {
        newState.items = newState.items.filter(
          (post) => post.id !== newState.currentEventId,
        )
      }

      return newState
    }

    case actionTypeSuccess(ActionTypes.GET_EVENT_STATUSES): {
      const newState = _.cloneDeep(state)
      const statuses = successAction.data as IEventsState['statuses']

      _.forEach(state.statuses, (value, status) => {
        const responseStatus = statuses[status.toLowerCase()]

        if (!IsNullOrUndefined(responseStatus)) {
          newState.statuses[status] = responseStatus
        } else {
          newState.statuses[status] = {
            count: 0,
            hasNotifications: false,
          }
        }
      })

      return newState
    }

    case actionTypeSuccess(ActionTypes.GET_EVENTS): {
      let newState = _.cloneDeep(state)
      const response = successAction.data as IPaginatedItems<IEvent>
      const page = successAction.payload!.page

      const pageIsFirstOrNull = IsNullOrUndefined(page) || page === 1

      const currentPostIsNullOrResponseContainsCurrentPost =
        IsNullOrUndefined(currentEvent) ||
        response.items.some((event) => event.id === currentEvent!.id)

      if (pageIsFirstOrNull && currentPostIsNullOrResponseContainsCurrentPost) {
        newState = {
          ...newState,
          ...response,
          pageSize: response.pageSize ?? response.count,
          cache: cacheItems(state.cache, response.items),
        }
      } else {
        newState = {
          ...newState,
          ...response,
          items: mergeArrays(newState.items, response.items),
          cache: cacheItems(state.cache, response.items),
        }
      }
      return newState
    }

    case actionTypeSuccess(ActionTypes.GET_EVENT_BY_ID): {
      const newState = _.cloneDeep(state)
      const response = successAction.data as IEvent
      const eventIndex = state.items.findIndex((x) => x.id === response.id)

      if (eventIndex < 0) {
        newState.items = [...newState.items, response]
      } else {
        newState.items[eventIndex] = response
      }

      newState.cache = cacheItem(state.cache, response)

      return newState
    }

    case actionTypeSuccess(ActionTypes.UPLOAD_EVENT_IMAGE):
    case actionTypeSuccess(ActionTypes.UPLOAD_EVENT_PASS_IMAGE):
    case actionTypeSuccess(ActionTypes.UPLOAD_DEFAULT_LIVE_SCHEDULE_IMAGE):
    case actionTypeSuccess(ActionTypes.UPDATE_EVENT_STATUS):
      case actionTypeSuccess(ActionTypes.PROMOTE_EVENT):
    case actionTypeSuccess(ActionTypes.UPDATE_EVENT): {
      const newState = _.cloneDeep(state)
      const response = successAction.data as IEvent
      const eventIndex = state.items.findIndex(
        (event) => event.id === response.id,
      )

      if (eventIndex >= 0) {
        newState.items[eventIndex] = {
          ...newState.items[eventIndex],
          ...(successAction.payload as IEvent),
          ...response,
        }
      }

      newState.cache = cacheItem(state.cache, response)

      return newState
    }

    case actionTypeSuccess(ActionTypes.AUTOCOMPLETE_EVENT_SEARCH): {
      const newState = _.cloneDeep(state)
      const response = successAction.data as IPaginatedItems<IEvent>

      newState.cache = cacheItems(state.cache, response.items)

      return newState
    }

    default:
      return state
  }
}

export default EventsReducer
