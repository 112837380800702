import { AppState } from 'types/redux/types/app-state'
import {
  MODULE_NAME,
} from 'modules/news-cards/constants'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { NIL as NIL_UUID } from 'uuid'
import {
  INewsCardState,
} from 'modules/news-cards/models/state'
import { INewsCard } from 'modules/news-cards/models/news-card'


const getNewsCardState = (state: AppState): INewsCardState => state[MODULE_NAME]


export const getCurrentNewsCard = (
  state: AppState,
): INewsCard | null => {
  const { currentNewsCardId, cache } = getNewsCardState(state)
  if (IsNullOrUndefined(currentNewsCardId)) return null
  if (currentNewsCardId === NIL_UUID) {
    return {
      id: NIL_UUID,
      title: '',
      parameters: {},
      variant: ''
    }
  }
  const currentNewsCard = cache[currentNewsCardId!]
  return currentNewsCard ?? null
}

export const getNewsCards = (state: AppState): INewsCard[] =>
  getNewsCardState(state).items

export const getNewsCardsCount = (state: AppState): number =>
  getNewsCardState(state).count
