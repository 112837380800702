import React from 'react'
import { Theme, Box, SvgIcon } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { ImageIcon } from 'components/icons'
import { colors } from 'utils/colors'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    coverImageContainer: {
      display: 'flex',
      background: colors.rgba_0_0_0_03,
      flexDirection: 'column',
      borderRadius: 10,
      overflow: 'hidden',
      marginRight: '0.625rem',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
    contain: {
      backgroundSize: 'contain',
    },
    cover: {
      backgroundSize: 'cover',
    },
    noImageLabel: {
      marginTop: 8,
    },
  }),
)

interface ICardProps {
  imageUrl?: string | null
  height?: number | string
  width?: number | string
  backgroundSize?: 'cover' | 'contain'
  noImageLabel?: string
}

export const SimpleImageDisplay: React.FC<ICardProps> = (props: ICardProps) => {
  const {
    imageUrl,
    height = 100,
    width = 130,
    backgroundSize = 'cover',
    noImageLabel,
  } = props
  const classes = useStyles()
  const hasCoverImage = !IsNullOrUndefined(imageUrl)
  const eventCoverContainerStyles: { [key: string]: string | number } = {}

  if (hasCoverImage) {
    eventCoverContainerStyles.backgroundImage = `url(${imageUrl!})`
  } else {
    eventCoverContainerStyles.background = colors.rgba_0_0_0_03
  }

  return (
    <Box
      className={`${classes.coverImageContainer} ${
        classes[backgroundSize] || ''
      }`}
      style={{
        ...eventCoverContainerStyles,
        width,
        height,
      }}
    >
      {!hasCoverImage && (
        <>
          <SvgIcon>
            <ImageIcon />
          </SvgIcon>
          {noImageLabel && (
            <Box className={classes.noImageLabel}>{noImageLabel}</Box>
          )}
        </>
      )}
    </Box>
  )
}
