import { PrizeType } from 'fe-shared-resources'
import { PrizeVisibility } from 'types/modules/prizes/enums/prize-visibility'
import { UserPrizeSource } from 'types/modules/shipping/enums/user-prize-source'
import { IUserPrize } from 'types/modules/shipping/models/entities/user-prize'

export const getSourceLabel = (userPrize: IUserPrize): string => {
  switch (userPrize.source) {
    case UserPrizeSource.Default: {
      if (userPrize.prize.type === PrizeType.Instant) {
        return userPrize.prize.visibility === PrizeVisibility.NewUser
          ? 'Carrot Prize'
          : 'Instant Purchase'
      } else return 'Sweepstake'
    }

    case UserPrizeSource.LevelUp:
      return 'Level Up Reward'

    case UserPrizeSource.UserBoxPrize:
      return 'Chest Reward'

    case UserPrizeSource.UserQuest:
      return 'Quest Reward'

    case UserPrizeSource.UserPositionReward:
      return 'Event Reward'

    case UserPrizeSource.UserPostPrize:
      return 'Multibet Reward'
  }
}
