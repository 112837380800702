import moment from 'moment-timezone'
import _ from 'core/utils/deepdash'

export const hoursInDay = (): string[] => {
  const hours: string[] = []

  new Array(24).fill(null).forEach((value, index) => {
    hours.push(moment({ hour: index }).toISOString())
  })

  return hours
}

export const getDaysOfWeek = (): string[] => {
  const startOfWeek = moment().startOf('isoWeek')

  const currentDate = startOfWeek

  const result: string[] = []

  new Array(7).fill(null).forEach(() => {
    result.push(currentDate.format('dddd'))
    currentDate.add(1, 'day')
  })

  return result
}

export const getWeekDates = (inputDate: string): string[] => {
  const date = moment(inputDate).startOf('day')

  const startOfWeek = date.startOf('isoWeek')

  const currentDate = startOfWeek

  const result: string[] = []

  new Array(7).fill(null).forEach(() => {
    result.push(currentDate.toISOString())
    currentDate.add(1, 'day')
  })

  return result
}

export const getMonthDates = (inputDate: string): string[] => {
  const date = moment(inputDate).startOf('month').startOf('day')

  const startOfWeek = date.startOf('isoWeek')

  const currentDate = startOfWeek

  const result: string[] = []

  new Array(7 * 5).fill(null).forEach(() => {
    result.push(currentDate.toISOString())
    currentDate.add(1, 'day')
  })

  return result
}
