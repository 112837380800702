import { Box, Chip, Grid, InputLabel, MenuItem, Select, SvgIcon, TextField, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { ImageIcon } from 'components/icons'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { useMobileAppUsersContext } from 'modules/mobile-app-users/context'
import { MobileAppUserFieldName } from 'modules/mobile-app-users/enums/mobile-app-user-field'
import {
  EditableMobileAppUser,
  MobileAppUser,
} from 'modules/mobile-app-users/models/entities/mobile-app-user'
import moment from 'moment-timezone'
import React from 'react'
import { IForm } from 'types/common/interfaces/IForm'
import { REGIONS_OPTIONS } from 'utils/constants'
import { DateTimePicker } from '@mui/x-date-pickers';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    eventDetailsForm: {
      width: '100%',
      height: 'auto',
      '& .MuiGrid-item': {
        display: 'flex',
        flexDirection: 'column',
      },
    },
    coverImageContainer: {
      display: 'flex',
      width: '90%',
      height: 150,
      background: 'rgba(0,0,0,0.03)',
      borderRadius: 10,
      overflow: 'hidden',
      marginRight: '0.625rem',
      marginTop: 10,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
    },
    inputLabel: {
      display: 'flex',
      marginTop: 16,
      marginBottom: 4,
    },
    disabledChip: {
      marginTop: 24,
    },
  }),
)

interface IUserEditFormProps extends IForm {
  mobileAppUser: EditableMobileAppUser
}

export const UserEditForm: React.FC<IUserEditFormProps> = ({
  mobileAppUser,
  handleChange,
  setFieldValue,
}): JSX.Element => {
  const classes = useStyles()
  const { currentMobileAppUser } = useMobileAppUsersContext()
  const hasCoverImage = !IsNullOrUndefined(
    currentMobileAppUser?.media?.backgroundImageUrl,
  )
  const eventCoverContainerStyles: { [key: string]: string | number } = {}
  if (hasCoverImage) {
    eventCoverContainerStyles.backgroundImage = `url(${currentMobileAppUser?.media!
      .backgroundImageUrl})`
  } else {
    eventCoverContainerStyles.background = 'rgba(0,0,0,0.03)'
  }
  const isDisabled = currentMobileAppUser?.username?.includes('DELETED')

  const elements = [
    {
      name: MobileAppUserFieldName.PREFERRED_USERNAME,
      label: 'Preferred username',
      value: mobileAppUser[MobileAppUserFieldName.PREFERRED_USERNAME],
    },
    {
      name: MobileAppUserFieldName.GIVEN_NAME,
      label: 'Given name',
      value: mobileAppUser[MobileAppUserFieldName.GIVEN_NAME],
    },
    {
      name: MobileAppUserFieldName.FAMILY_NAME,
      label: 'Family name',
      value: mobileAppUser[MobileAppUserFieldName.FAMILY_NAME],
    },
    {
      name: MobileAppUserFieldName.EMAIL,
      label: 'Email',
      value: mobileAppUser[MobileAppUserFieldName.EMAIL],
    },
    {
      name: MobileAppUserFieldName.PHONE_NUMBER,
      label: 'Phone number',
      value: mobileAppUser[MobileAppUserFieldName.PHONE_NUMBER],
    },
  ]

  return (
    <Box className={classes.eventDetailsForm}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Box
                className={classes.coverImageContainer}
                style={eventCoverContainerStyles}
              >
                {!hasCoverImage && (
                  <SvgIcon>
                    <ImageIcon />
                  </SvgIcon>
                )}
              </Box>
              {isDisabled && (
                <Box className={classes.disabledChip}>
                  <Chip color="secondary" label="Disabled" />
                </Box>
              )}
            </Grid>
            <Grid item xs={8}>
              {elements.map(({ name, label, value }) => (
                <Grid item xs={12} key={name}>
                  <InputLabel htmlFor={name} className={classes.inputLabel}>
                    {label}
                  </InputLabel>
                  <TextField
                    id={name}
                    name={name}
                    onChange={handleChange as React.ChangeEventHandler<HTMLInputElement>}
                    value={value}
                    variant="filled"
                    fullWidth
                  />
                </Grid>
              ))}
              <Grid item xs={12}>
                <InputLabel className={classes.inputLabel}>Region</InputLabel>
                <Select
                  id={MobileAppUserFieldName.LOCALE}
                  name={MobileAppUserFieldName.LOCALE}
                  value={mobileAppUser[MobileAppUserFieldName.LOCALE]}
                  onChange={handleChange}
                  variant="filled"
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    // getContentAnchorEl: null,
                  }}
                  fullWidth
                >
                  <MenuItem value="">Not set</MenuItem>
                  {REGIONS_OPTIONS.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12}>
                <InputLabel
                  htmlFor={MobileAppUserFieldName.BIRTHDAY}
                  className={classes.inputLabel}
                >
                  Birthday
                </InputLabel>
                <DateTimePicker
                  value={moment(mobileAppUser[MobileAppUserFieldName.BIRTHDAY])}
                  onChange={(date): void => {
                    const newValue = moment(
                      date?.utc().toISOString(),
                    ).toISOString()
                    setFieldValue(MobileAppUserFieldName.BIRTHDAY, newValue)
                  }}
                  format={'YYYY-MM-DD HH:mm'}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}
