import React from 'react'
import { Theme, TableCell, TableRow } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { IQuestGroup } from 'modules/quests/models/quest-group'

const IMAGE_HEIGHT = '100px'



interface ICardProps {
  item: IQuestGroup
  onClick: () => void
}

export const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }),
)(TableCell)

export const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.secondary.light,
      },
    },
  }),
)(TableRow)

export const QuestGroupCard: React.FC<ICardProps> = ({
  item: questGroup,
  onClick,
}: ICardProps) => {
  return (
    <StyledTableRow key={questGroup.id} onClick={onClick}>
      <StyledTableCell component="th" scope="row">
        {questGroup.name}
      </StyledTableCell>
      <StyledTableCell>{questGroup.variant}</StyledTableCell>
      <StyledTableCell>{questGroup.test}</StyledTableCell>
    </StyledTableRow>
  )
}
