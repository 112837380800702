import { all, AllEffect, ForkEffect, takeEvery } from 'redux-saga/effects'
import CampaignActionTypes from 'modules/campaigns/action-types/campaign-action-types'
import { GetCampaignsSaga } from './campaign-saga'

type RootSagaReturnType = Generator<
  ForkEffect<never> | AllEffect<any>,
  void,
  any
>

export const RootSaga = function* (): RootSagaReturnType {
  yield all([

    yield takeEvery(
      CampaignActionTypes.GET_CAMPAIGNS,
      GetCampaignsSaga,
    ),
    yield takeEvery(
      CampaignActionTypes.GET_CAMPAIGN_BY_ID,
      GetCampaignsSaga,
    ),
    yield takeEvery(
      CampaignActionTypes.CREATE_CAMPAIGN,
      GetCampaignsSaga,
    ),
    yield takeEvery(
      CampaignActionTypes.UPDATE_CAMPAIGN,
      GetCampaignsSaga,
    ),
    yield takeEvery(
      CampaignActionTypes.UPLOAD_CAMPAIGN_IMAGE,
      GetCampaignsSaga,
    ),
    yield takeEvery(
      CampaignActionTypes.DELETE_CAMPAIGN,
      GetCampaignsSaga,
    ),
  ])
}
