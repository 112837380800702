import { Box, ButtonBase, IconButton, SvgIcon, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { ArrowLeftIcon, ArrowRightIcon, CrossIcon } from 'components/icons'
import { PreviewContainer } from 'components/preview'
import { useAuth } from 'core/authentication/hooks/use-auth'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { usePostListContext } from 'modules/posts/context/post-list'
import { IPostNavigationConfigItem } from 'modules/posts/views/components/manage-post-panel/types/post-navigation-config-item'
import { WatchlistOptionSelector } from 'modules/watchlist/views/components/post-modal/option-selector'
import React from 'react'
import { IPost } from 'types/modules/posts/models/entities/post'
import { IPostQuestion } from 'types/modules/posts/models/entities/post-question'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    questionContainer: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      height: '100%',
      position: 'relative',
    },
    headerToolbar: {
      display: 'flex',
      width: '100%',
      background: theme.palette.primary.main,
      color: 'white !important',
      justifyContent: 'center',
      alignItems: 'center',
      height: 66,
      position: 'relative',
    },
    closeQuestionButton: {
      position: 'absolute',
      right: '1.25rem',
      width: 40,
      height: 40,
      color: theme.palette.primary.contrastText,
    },
    previousQuestionButton: {
      width: 40,
      height: 40,
      marginRight: '1.25rem',
      color: theme.palette.primary.contrastText,
    },
    nextQuestionButton: {
      width: 40,
      height: 40,
      marginLeft: '1.25rem',
      color: theme.palette.primary.contrastText,
    },
    questionImage: {
      display: 'flex',
      width: '100%',
      height: 170,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      [theme.breakpoints.down('sm')]: {
        height: '54vw',
      },
    },
    questionContent: {
      display: 'flex',
      width: '100%',
      height: 'calc(100% - 226px)',
      position: 'absolute',
      bottom: 0,
      background: '#FFFFFF',
      borderRadius: '12px 12px 0 0',
      flexDirection: 'column',
      [theme.breakpoints.down('sm')]: {
        height: 'calc(100% - 65vw)',
      },
    },
    questionTitleContainer: {
      display: 'flex',
      width: '100%',
      height: 'auto',
      padding: '1rem',
    },
    questionTitle: {
      fontSize: '0.9rem',
      lineHeight: 1.4,
      fontWeight: 600,
      [theme.breakpoints.down('sm')]: {
        fontSize: '4.585987261146498VW',
      },
    },
    optionsContainer: {
      display: 'flex',
      flex: 1,
      background: '#F2F1EF',
      overflow: 'auto',
      padding: '1rem',
      flexDirection: 'column',
      [theme.breakpoints.down('sm')]: {
        padding: '5.095541401273886VW',
      },
    },
    option: {
      display: 'flex',
      width: '100%',
      height: 40,
      background: '#FFFFFF',
      flexDirection: 'row',
      borderRadius: 9,
      marginBottom: '1rem',
      '&:last-ot-type': {
        marginBottom: 0,
      },
      [theme.breakpoints.down('sm')]: {
        height: '12.738853503184714VW',
        borderRadius: '2.8662420382165608VW',
      },
    },
    optionNumberTab: {
      display: 'flex',
      height: '100%',
      width: 40,
      borderRadius: '9px 0 0 9px',
      background: theme.palette.primary.main,
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        width: '12.738853503184714VW',
        borderRadius: '2.8662420382165608VW 0 0 2.8662420382165608VW',
      },
    },
    optionNumber: {
      color: theme.palette.primary.contrastText,
    },
    optionContentTab: {
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },
    optionTextContainer: {
      display: 'flex',
      width: 165,
      [theme.breakpoints.down('sm')]: {
        width: '52.547770700636946VW',
      },
    },
    optionText: {
      lineHeight: '0.8rem',
      fontSize: '0.75rem',
      fontWeight: 600,
      textAlign: 'center',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        lineHeight: '4.076433121019109VW',
        fontSize: '3.821656050955414VW',
      },
    },
  }),
)

interface IWatchlistQuestionPreviewProps {
  question: IPostQuestion
  post: IPost
  isMobile: boolean
  currentPage: number
  nextPage: () => void
  previousPage: () => void
  navigationConfig: IPostNavigationConfigItem[]
}

export const WatchlistQuestionPreview: React.FC<
  IWatchlistQuestionPreviewProps
> = (props: IWatchlistQuestionPreviewProps) => {
  const {
    question,
    post,
    isMobile,
    currentPage,
    nextPage,
    previousPage,
    navigationConfig,
  } = props
  const { currentUser } = useAuth()
  const classes = useStyles()
  const { clearCurrentPost } = usePostListContext()

  const hasImage = !IsNullOrUndefined(question?.media?.imageUrl)
  const questionImageStyles: { [key: string]: string | number } = {}

  if (hasImage) {
    questionImageStyles.backgroundImage = `url(${question.media!.imageUrl!})`
  } else {
    questionImageStyles.background = 'rgba(0,0,0,0.03)'
  }

  const portalQuestionAnswers = question?.answers.filter(
    (answer) =>
      !IsNullOrUndefined(answer.updatedBy) && answer.updatedBy?.fromPortal,
  )

  const userCurrentAnswerIndex = portalQuestionAnswers?.findIndex(
    (answer) => answer.updatedBy?.name === currentUser.username,
  )

  const userHasAnswered = userCurrentAnswerIndex >= 0

  return (
    <PreviewContainer withDevice={!isMobile}>
      <Box className={classes.questionContainer}>
        <Box className={classes.headerToolbar}>
          {isMobile && (
            <IconButton
              onClick={clearCurrentPost}
              className={classes.closeQuestionButton}
              size="large">
              <SvgIcon>
                <CrossIcon />
              </SvgIcon>
            </IconButton>
          )}
          {isMobile && (
            <IconButton
              onClick={previousPage}
              className={classes.previousQuestionButton}
              disabled={currentPage === 1}
              size="large">
              <SvgIcon>
                <ArrowLeftIcon />
              </SvgIcon>
            </IconButton>
          )}
          <Typography className={classes.questionTitle}>
            {`Question ${currentPage}`}
          </Typography>
          {isMobile && (
            <IconButton
              onClick={nextPage}
              className={classes.nextQuestionButton}
              disabled={currentPage === navigationConfig.length}
              size="large">
              <SvgIcon>
                <ArrowRightIcon />
              </SvgIcon>
            </IconButton>
          )}
        </Box>
        <Box className={classes.questionImage} style={questionImageStyles} />
        <Box className={classes.questionContent}>
          <Box className={classes.questionTitleContainer}>
            <Typography className={classes.questionTitle}>
              {question.text}
            </Typography>
          </Box>
          <Box className={classes.optionsContainer}>
            {question.options.map((option, index) => {
              return (
                <WatchlistOptionSelector
                  key={`option-${index + 1}`}
                  // eslint-disable-next-line id-blacklist
                  userPreviousAnswerId={
                    userHasAnswered
                      ? question.answers[userCurrentAnswerIndex].id
                      : undefined
                  }
                  option={option}
                  index={index}
                  question={question}
                  post={post}
                />
              )
            })}
          </Box>
        </Box>
      </Box>
    </PreviewContainer>
  );
}
