import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react'
import { ChromePicker } from 'react-color'

const useStyles = makeStyles(() =>
  createStyles({
    container: { position: 'relative' },
    picker: { position: 'absolute', zIndex: 2 },
    innerPicker: {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    },
  }),
)

interface IPickerDialogProps {
  onChange: (color: any) => void
  onClick: () => void
  value?: string
}

export const PickerDialog: React.FC<IPickerDialogProps> = (props) => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <div className={classes.picker}>
        <div className={classes.innerPicker} onClick={props.onClick} />
        <ChromePicker
          color={props.value}
          onChange={props.onChange}
          disableAlpha
        />
      </div>
    </div>
  )
}
