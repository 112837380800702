import { getStatusFilterOptions } from 'modules/posts/utils/get-status-filter-options'
import { ISelectOption } from 'types/common/filtering/models/entities/ISelectOption'
import PostStatus from 'types/modules/posts/enums/post-status'
import { IPostsState } from 'types/modules/posts/models/state/IPostsState'

export const buildPostStatusConfig = (
  eventPostStatuses: { type: string; count: number }[],
): ISelectOption[] => {
  const statuses: IPostsState['statuses'] = {}

  Object.values(PostStatus).forEach((status) => {
    const eventPostStatus = eventPostStatuses.find((postStatus) => {
      return postStatus.type === status
    })

    statuses[status] = {
      count: eventPostStatus?.count ?? 0,
      hasNotifications: false,
    }
  })

  return getStatusFilterOptions(statuses)
}
