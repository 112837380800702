import { Theme, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import {
  GossEditorProvider,
  IEditorSubmitResult,
} from 'components/goss-editor/context'
import { GossEditor } from 'components/goss-editor/views'
import { useRequestContext } from 'core/api/context'
import { useCommentsContext } from 'modules/comments/context'
import { CommentFeed } from 'modules/comments/views/components/comment-feed'
import { usePostListContext } from 'modules/posts/context/post-list'
import { PostCommentItem } from 'modules/posts/views/components/manage-post-panel/post-comment-item'
import React, { useEffect } from 'react'
import { SortDirection } from 'types/common/enums/sort-direction'
import { CommentField } from 'types/modules/comments/enums/comment-field'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    postComments: {
      display: 'flex',
      flex: 1,
      marginRight: 52,
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
  }),
)

export const PostComments: React.FC<{}> = () => {
  const classes = useStyles()

  const { currentPost } = usePostListContext()

  const { currentPostComments, createPostComment } = useCommentsContext()

  const rc = useRequestContext()

  useEffect(() => {
    rc?.setPage(1)
    rc?.setPageSize(10)
    rc?.setSortValue({
      sortBy: CommentField.CREATED_AT,
      sortDirection: SortDirection.Descending,
    })
    rc?.setActive(true)
  }, [])

  return (
    <Box className={classes.postComments}>
      <CommentFeed
        commentComponent={PostCommentItem}
        data={currentPostComments}
        onCreate={({ value, callbackFn }: IEditorSubmitResult): void => {
          createPostComment(JSON.stringify(value), currentPost?.id, callbackFn)
        }}
        onUpdate={({ value, callbackFn }: IEditorSubmitResult): void => {
          return
        }}
      />
    </Box>
  )
}
