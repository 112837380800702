import { Box, Grid, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import _ from 'core/utils/deepdash'
import { FormGen, FormTypes } from 'components/simple-form-gen'
import React from 'react'
import { IForm } from 'types/common/interfaces/IForm'
import {
  IIapProduct,
  ProductStatus,
  ProductType,
} from 'modules/iap-products/models/iap-product'
import { Currencies } from 'modules/iap-products/view/iap-product-modal/currencies'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iapProductsWrapper: {
      borderLeft: '1px solid #f4f4f4',
      paddingLeft: 8,
      height: '100%',
    },
    container: {
      height: '100%',
    },
    iapProductsError: {
      marginTop: -16,
    },
    inputLabel: {
      display: 'flex',
      marginBottom: '0.625rem',
    },
    currenciesContainer: {
      backgroundColor: '#f8f6f6',
      borderRadius: 8,
    },
  }),
)

interface IIapProductFormProps extends IForm {
  iapProduct: IIapProduct
  isUpdating?: boolean
}

const statusOptions = Object.values(ProductStatus).map((code) => ({
  value: code,
  name: code,
}))
const typeOptions = Object.values(ProductType).map((code) => ({
  value: code,
  name: code,
}))

export const IapProductForm: React.FC<IIapProductFormProps> = ({
  iapProduct,
  handleChange,
  setFieldValue,
  errors,
  isUpdating
}): JSX.Element => {
  const classes = useStyles()
  const title = {
    name: 'id',
    label: 'Product ID',
    value: iapProduct?.id || '',
    type: FormTypes.TEXT_INPUT,
    disabled: isUpdating,
    otherProps: {
      error: !!errors?.id,
      helperText: errors?.id,
    },
  }
  const description = {
    name: 'description',
    label: 'Description',
    value: iapProduct?.description || '',
    type: FormTypes.TEXT_INPUT,
    otherProps: {
      error: !!errors?.description,
      helperText: errors?.description,
    },
  }

  const status = {
    name: 'status',
    label: 'Status',
    value: iapProduct?.status,
    type: FormTypes.SELECT,
    options: statusOptions,
    otherProps: {
      error: !!errors?.status,
      helperText: errors?.status,
    },
  }

  const type = {
    name: 'type',
    label: 'Consumable type',
    value: iapProduct?.type,
    type: FormTypes.SELECT,
    options: typeOptions,
    otherProps: {
      error: !!errors?.type,
      helperText: errors?.type,
    },
  }

  const secondsFromUserCreationToStart = {
    name: 'secondsFromUserCreationToStart',
    label: 'Seconds from user creation to start',
    value: iapProduct?.secondsFromUserCreationToStart,
    type: FormTypes.TEXT_INPUT,
    otherProps: {
      error: !!errors?.secondsFromUserCreationToStart,
      helperText: errors?.secondsFromUserCreationToStart,
    },
  }

  const secondsFromUserCreationToEnd = {
    name: 'secondsFromUserCreationToEnd',
    label: 'Seconds from user creation to end',
    value: iapProduct?.secondsFromUserCreationToEnd,
    type: FormTypes.TEXT_INPUT,
    otherProps: {
      error: !!errors?.secondsFromUserCreationToEnd,
      helperText: errors?.secondsFromUserCreationToEnd,
    },
  }


  return (
      <Grid container spacing={2} className={classes.container}>
        <Grid item xs={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormGen handleChange={handleChange} {...title} />
            </Grid>
            <Grid item xs={12}>
              <FormGen handleChange={handleChange} {...description} />
            </Grid>
            <Grid item xs={12}>
              <FormGen handleChange={handleChange} {...status} />
            </Grid>
            <Grid item xs={12}>
              <FormGen handleChange={handleChange} {...type} />
            </Grid>
            <Grid item xs={6}>
              <FormGen handleChange={handleChange} {...secondsFromUserCreationToStart} />
            </Grid>
            <Grid item xs={6}>
              <FormGen handleChange={handleChange} {...secondsFromUserCreationToEnd} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} className={classes.currenciesContainer}>
          <Currencies
            value={iapProduct?.metaData?.productConfig?.currencies || []}
            setFieldValue={setFieldValue}
            handleChange={handleChange}
            errors={errors?.metaData?.productConfig?.currencies || []}
          />
        </Grid>
      </Grid>
  )
}
