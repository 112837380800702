import { Box, ButtonBase, Popover, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { ICalendarGridCellProps } from 'components/calendar/props/calendar-grid-cell-props'
import { useLiveScheduleContext } from 'modules/live-schedules/context'
import React from 'react'
import { ILiveSchedule } from 'types/modules/live-schedules/models/entities/live-schedule'
import moment from 'moment-timezone'
import clsx from 'clsx'
import {
  IsNullOrUndefined,
  IsNullUndefinedOrEmpty,
} from 'core/utils/isNullOrUndefined'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    eventGridCellContainer: {
      display: 'flex',
      flex: 1,
      '&:hover': {
        background: 'rgba(0,0,0,0.03)',
        cursor: 'pointer',
      },
    },
    event: {
      display: 'flex',
      flex: 1,
      boxSizing: 'border-box',
      flexDirection: 'column',
      height: 79,
      background: '#ff787d',
      border: '1px solid #ff787d',
      '&.multiple': {
        background: '#02599D',
        border: '1px solid #02599D',
      },
    },
    eventHeader: {
      display: 'flex',
      height: 15,
      width: '100%',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingLeft: '0.3rem',
      paddingRight: '0.3rem',
    },
    headerText: {
      fontSize: '0.7rem',
      color: theme.palette.primary.contrastText,
      '&.textPrimary': {
        color: theme.palette.text.primary,
      },
    },
    eventContent: {
      display: 'flex',
      flex: 1,
      width: '100%',
      height: 'calc(100% - 15px)',
      background: 'rgba(255,255,255,0.60)',
      padding: '0.3rem',
      '&.darker': {
        background: 'rgba(255,255,255,0.30)',
      },
    },
    eventDetailsContainer: {
      display: 'flex',
      flex: 1,
    },
    eventTitle: {
      fontSize: '0.7rem',
      color: theme.palette.text.primary,
      overflow: 'hidden',
      textAlign: 'left',
    },
    multieventPopover: {
      display: 'flex',
      flexDirection: 'column',
      width: 230,
    },
    multieventCount: {
      color: theme.palette.primary.contrastText,
      textAlign: 'center',
      margin: 'auto',
    },
  }),
)

export const LiveScheduleCalendarTimeGridCell: React.FC<
  ICalendarGridCellProps<ILiveSchedule>
> = (props: ICalendarGridCellProps<ILiveSchedule>) => {
  const { items, dateTime, sortBy } = props

  const { setCurrentLiveSchedule } = useLiveScheduleContext()

  const classes = useStyles()

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handleMultiLiveSchedulePopoverClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleMultiLiveSchedulePopoverClose = (): void => {
    setAnchorEl(null)
  }

  const renderEvent = (liveSchedule: ILiveSchedule): JSX.Element => {
    return (
      <ButtonBase
        key={liveSchedule.id}
        className={clsx(classes.event)}
        onClick={(): void => {
          handleMultiLiveSchedulePopoverClose()
          setCurrentLiveSchedule(liveSchedule.id)
        }}
      >
        <Box className={classes.eventHeader}>
          <Typography
            component="span"
            variant="button"
            className={clsx(classes.headerText)}
          >
            {moment((liveSchedule as { [key: string]: any })[sortBy]).format(
              'h:mm A',
            )}
          </Typography>
        </Box>
        <Box className={classes.eventContent}>
          <Box className={classes.eventDetailsContainer}>
            <Typography
              component="span"
              variant="body2"
              className={clsx(classes.eventTitle)}
            >
              {liveSchedule.name}
            </Typography>
            {!IsNullUndefinedOrEmpty(liveSchedule.description) && (
              <Typography
                component="span"
                variant="body2"
                className={clsx(classes.eventTitle)}
              >
                {`: ${liveSchedule.description}`}
              </Typography>
            )}
          </Box>
        </Box>
      </ButtonBase>
    )
  }

  const renderMultipleEvents = (): JSX.Element => {
    const open = Boolean(anchorEl)
    // eslint-disable-next-line id-blacklist
    const id = open ? `multiple-schedules-${dateTime}` : undefined

    return (
      <React.Fragment>
        <ButtonBase
          aria-describedby={id}
          className={clsx(classes.event, 'multiple')}
          onClick={handleMultiLiveSchedulePopoverClick}
        >
          <Box className={classes.eventHeader}>
            <Typography
              component="span"
              variant="button"
              className={clsx(classes.headerText)}
            >
              {moment(dateTime).format('h:mm A')}
            </Typography>
            <Typography
              component="span"
              variant="button"
              className={clsx(classes.headerText)}
            >
              {`.`}
            </Typography>
          </Box>
          <Box className={clsx(classes.eventContent, 'darker')}>
            <Typography
              component="span"
              variant="body2"
              className={clsx(classes.multieventCount)}
            >
              {`${items.length} Schedules`}
            </Typography>
          </Box>
        </ButtonBase>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleMultiLiveSchedulePopoverClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Box className={classes.multieventPopover}>
            {items.map((item) => renderEvent(item))}
          </Box>
        </Popover>
      </React.Fragment>
    )
  }

  const renderEmptyView = (): JSX.Element | null => {
    return null
  }

  const renderViewSelector = (): JSX.Element | null => {
    if (IsNullOrUndefined(items) || items.length === 0) {
      return renderEmptyView()
    }

    switch (items.length) {
      case 1:
        return renderEvent(items[0])
      default:
        return renderMultipleEvents()
    }
  }

  return (
    <Box className={classes.eventGridCellContainer}>{renderViewSelector()}</Box>
  )
}
