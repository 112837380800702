import { Box, Button, ButtonProps, Popover, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, { ComponentType, ReactElement } from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    confirmationPopover: {
      padding: '1.25rem',
    },
    confirmationTextWrapper: {
      justifyContent: 'center',
      textAlign: 'center',
      marginBottom: '1.25rem',
    },
    confirmationText: {},
    confirmationButtons: {
      display: 'flex',
      justifyContent: 'center',
    },
    confirmButton: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
      marginRight: '0.625rem',
    },
    cancelButton: {
      color: theme.palette.text.primary,
      backgroundColor: '#ecf0f1',
      marginLeft: '0.625rem',
      '&:hover': {
        backgroundColor: '#f2f5f5',
      },
    },
  }),
)

interface IConfirmationDialogProps {
  popoverId: string
  confirmationText: string
  onConfirmation: () => void
  buttonProps: ButtonProps
  buttonLabel: string
  onPopoverClose?: () => void
}

export const ConfirmationDialog: React.FC<IConfirmationDialogProps> = (
  props: IConfirmationDialogProps,
) => {
  const classes = useStyles()

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handlePopoverClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ): void => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = (): void => {
    setAnchorEl(null)
    props.onPopoverClose && props.onPopoverClose()
  }

  const open = Boolean(anchorEl)
  // eslint-disable-next-line id-blacklist
  const id = open ? props.popoverId : undefined

  return (
    <React.Fragment>
      <Button {...props.buttonProps} onClick={handlePopoverClick}>
        {props.buttonLabel}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={(): void => {
          handlePopoverClose()
          props.onPopoverClose && props.onPopoverClose()
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box className={classes.confirmationPopover}>
          <Box className={classes.confirmationTextWrapper}>
            <Typography className={classes.confirmationText}>
              {props.confirmationText}
            </Typography>
          </Box>
          <Box className={classes.confirmationButtons}>
            <Button
              variant="contained"
              className={classes.confirmButton}
              size="small"
              onClick={(): void => {
                props.onConfirmation()
                handlePopoverClose()
              }}
            >
              Confirm
            </Button>
            <Button
              variant="contained"
              className={classes.cancelButton}
              size="small"
              onClick={handlePopoverClose}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Popover>
    </React.Fragment>
  )
}
