import { AppState } from 'types/redux/types/app-state'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { IPostsState } from 'types/modules/posts/models/state/IPostsState'
import { IPost, IPostAutoCompleteFilter } from 'types/modules/posts/models/entities/post'
import _ from 'core/utils/deepdash'
import PostStatus from 'types/modules/posts/enums/post-status'
import moment from 'moment-timezone'
import { NIL } from 'uuid'
import { createPostDraft } from 'modules/posts/utils/create-post-draft'
import { ICreatePostDraftParams } from 'types/modules/posts/models/entities/post-draft'
import { MODULE_NAME } from '../constants'

const getState = (state: AppState): IPostsState =>
  _.cloneDeep(state[MODULE_NAME])


export const getPosts = (state: AppState): IPostsState => getState(state)

export const getPostsByStatus = (
  state: AppState,
  statuses: PostStatus[] | null,
): IPostsState => {
  const posts = getPosts(state)

  if (!IsNullOrUndefined(statuses)) {
    posts.items = posts.items.filter((post) => statuses?.includes(post.status))
  }

  return posts
}

export const getAutocompleteSearchPosts = (
  state: AppState,
  filter: IPostAutoCompleteFilter = {}
): IPost[] => {
  const posts = state.posts.items.reduce((acc, curr) => {
    let shouldAdd = true
    if (filter.type && !filter.type.includes(curr.type)) {
      shouldAdd = false
    }
    else if (filter.status && !filter.status.includes(curr.status)) {
      shouldAdd = false
    }
    else if (!filter.includePostsInPostGroup && curr.groups.length) {
      shouldAdd = false
    }

    if (shouldAdd) {
      return [...acc, curr]
    }
    return acc
  }, [] as IPost[])

  return posts
}

export const getPostsByDate = (
  state: AppState,
  date: string,
  field: string,
): IPost[] => {
  const dateParam = moment(date)

  const posts = getPosts(state)

  return posts.items.filter((post) => {
    const postDate = moment((post as { [key: string]: any })[field])

    return dateParam.isSame(postDate, 'day')
  })
}

export const getCurrentPost = (
  state: AppState,
  params?: ICreatePostDraftParams,
): Partial<IPost> | null => {
  const posts = getState(state)

  if (IsNullOrUndefined(posts.currentPostId)) return null

  if (posts.currentPostId === NIL && params && params.postType) {
    const draft = createPostDraft(params)
    return {
      id: NIL,
      ...draft,
    }
  }

  const currentPost = posts.items.find(
    (post) => post.id === posts.currentPostId,
  )

  return currentPost ?? null
}

export const getPostById = (state: AppState, postId: string): IPost | null => {
  return !IsNullOrUndefined(postId)
    ? getState(state).items.find((x) => x.id === postId) ?? null
    : null
}

export const getStatuses = (state: AppState): IPostsState['statuses'] =>
  getState(state).statuses

export const getPostGetter = (state: AppState): (postId: string) => IPost => {
  return (postId: string): IPost => getState(state).items.find((item) => item?.id === postId)!
}
