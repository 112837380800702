/* Libs */
import {
  all,
  AllEffect,
  ForkEffect,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects'

/* Module */
import { ActionTypes } from '..'
import { GetTagsSaga } from './get-tags-saga'

type RootSagaReturnType = Generator<
  ForkEffect<never> | AllEffect<any>,
  void,
  any
>

export const RootSaga = function* (): RootSagaReturnType {
  yield all([
    yield takeEvery(ActionTypes.GET_TAGS, GetTagsSaga),
    yield takeEvery(ActionTypes.GET_TAG_BY_ID, GetTagsSaga),
    yield takeEvery(ActionTypes.CREATE_TAG, GetTagsSaga),
    yield takeEvery(ActionTypes.UPDATE_TAG, GetTagsSaga),
    yield takeEvery(ActionTypes.DELETE_TAG_BY_ID, GetTagsSaga),
    yield takeEvery(ActionTypes.GET_AUTOCOMPLETE_SEARCH_TAGS, GetTagsSaga),
    yield takeEvery(ActionTypes.GET_TAG_GROUPS, GetTagsSaga),
    yield takeEvery(ActionTypes.GET_TAG_GROUP_BY_ID, GetTagsSaga),
    yield takeEvery(ActionTypes.CREATE_TAG_GROUP, GetTagsSaga),
    yield takeEvery(ActionTypes.UPDATE_TAG_GROUP, GetTagsSaga),
    yield takeEvery(
      ActionTypes.GET_AUTOCOMPLETE_SEARCH_TAG_GROUPS,
      GetTagsSaga,
    ),
    yield takeEvery(ActionTypes.GET_TAG_PROMOTION, GetTagsSaga),
    yield takeEvery(ActionTypes.UPDATE_TAG_PROMOTION, GetTagsSaga),
    yield takeEvery(ActionTypes.GET_TAG_SUGGESTION, GetTagsSaga),
    yield takeEvery(ActionTypes.UPDATE_TAG_SUGGESTION, GetTagsSaga),
    yield takeEvery(ActionTypes.UPLOAD_TAG_IMAGE, GetTagsSaga),
  ])
}
