import React, {
  PropsWithChildren,
  PropsWithRef,
  ReactElement,
  useEffect,
  useState,
} from 'react'

import {
  useCombobox,
  useMultipleSelection,
  UseComboboxStateChange,
} from 'downshift'

import { TextField } from '@mui/material'

import {
  Item,
  RequiredItemProps,
} from 'components/controls/inputs/downshift/item'
import { IAutoCompleteListProps } from 'components/controls/inputs/downshift/autocomplete/autocomplete-list-props'
import { ISelectedChipDisplayProps } from 'components/controls/inputs/downshift/multiselect/selected-chip-display-props'
import { IMultiselectProps } from 'components/controls/inputs/downshift/multiselect/multiselect-props'
import { useStyles } from 'components/controls/inputs/downshift/styles'

import AutocompleteList from 'components/controls/inputs/downshift/autocomplete/autocomplete-list'
import SelectedChipDisplay from 'components/controls/inputs/downshift/multiselect/selected-chip-display'
import MultiselectStateReducer from 'components/controls/inputs/downshift/multiselect/multiselect-state-reducer'
import { NIL as NIL_UUID } from 'uuid'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { get } from 'lodash-es'

const DownshiftMultiselect = <TItem,>(
  props: PropsWithRef<IMultiselectProps<Item<TItem>>>,
): JSX.Element => {
  const [inputValue, setInputValue] = useState('')

  const {
    getSelectedItemProps,
    getDropdownProps,
    addSelectedItem,
    removeSelectedItem,
    selectedItems,
    activeIndex,
    reset: resetComboboxMultiselect,
  } = useMultipleSelection({
    initialSelectedItems: props.initialSelectedItems,
  })

  useEffect(() => {
    props.onInputValueChanged && props.onInputValueChanged(inputValue)
  }, [inputValue])

  const availableItemsFilter = (item: Item<TItem>): boolean =>
    selectedItems.findIndex(
      (x) =>
        get(x, props.searchKey || 'name') ===
        get(item, props.searchKey || 'name'),
    ) < 0 &&
    get(item, props.searchKey || 'name')
      .toLowerCase()
      .includes(inputValue.toLowerCase())

  const availableItems = props.availableItems.filter((item) =>
    availableItemsFilter(item),
  )

  const newItemOption =
    props.options?.createOptions?.onCreate &&
    inputValue.length > 0
      ? [props.options!.createOptions!.newItemPlaceholder(inputValue)]
      : []

  const inputItems = [...availableItems, ...newItemOption]

  const comboboxProps = {
    inputValue: inputValue,
    defaultHighlightedIndex: 0,
    items: inputItems,
    stateReducer: MultiselectStateReducer,
    onStateChange: (changes: UseComboboxStateChange<Item<TItem>>): void => {
      const { inputValue, type, selectedItem } = changes

      switch (type) {
        case useCombobox.stateChangeTypes.InputChange:
          setInputValue(inputValue ?? '')
          break

        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur:
          if (selectedItem) {
            if (
              !IsNullOrUndefined(props.options?.createOptions) &&
              (selectedItem as any).id === NIL_UUID
            ) {
              props.options?.createOptions?.onCreate(
                selectedItem,
                addSelectedItem,
              )
            } else {
              addSelectedItem(selectedItem)
            }

            setInputValue('')
          }
          break

        default:
          break
      }
    },
  }

  useEffect(() => {
    props.onSelectedItemsChange(selectedItems)
  }, [selectedItems])

  const {
    isOpen,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    getItemProps,
    highlightedIndex,
  } = useCombobox<TItem>(comboboxProps)

  const autoCompleteListProps: IAutoCompleteListProps<TItem> = {
    isOpen,
    getItemProps,
    getMenuProps,
    inputItems,
    highlightedIndex,
    autocompleteSearchResultContent:
      props.options?.autocompleteSearchResultContent,
    fieldName: props.fieldName
  }

  const selectedChipDisplayProps: ISelectedChipDisplayProps<TItem> = {
    selectedItems: selectedItems,
    getSelectedItemProps: getSelectedItemProps,
    removeSelectedItem: removeSelectedItem,
    selectedItemsChipProps: props.options?.selectedItemsChipProps,
    fieldName: props.fieldName
  }

  // useEffect(() => {
  //   if (
  //     inputItems.length === 0 &&
  //     activeIndex === -1 &&
  //     inputValue.length > 0
  //   ) {
  //     setIsCreating(true);
  //     // setInputItems(true);
  //     setHighlightedIndex(0);
  //   }
  // }, [inputItems, setIsCreating, setHighlightedIndex, inputValue, activeIndex]);

  const classes = useStyles()

  return (
    <div className={classes.multiselectContainer}>
      <React.Fragment>
        <div {...getComboboxProps()}>
          <TextField
            fullWidth
            disabled={props.disabled}
            InputProps={{
              ...getInputProps(
                getDropdownProps({
                  refKey: 'inputRef',
                  onKeyDown: (event: any) => {
                    if (event.keyCode === 8) {
                      // Backspace
                      event.nativeEvent.preventDownshiftDefault = true
                    }
                  },
                }),
              ),
            }}
            label={props.label ?? null}
            variant={props.variant ?? 'outlined'}
          />
        </div>
      </React.Fragment>
      <AutocompleteList {...autoCompleteListProps} />
      <SelectedChipDisplay {...selectedChipDisplayProps} />
    </div>
  )
}

export default DownshiftMultiselect
