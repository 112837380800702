import QuestGroupEndpoints from 'core/api/endpoints/quest-groups'
import ActionTypes from 'modules/quests/action-types/quest-group-action-types'
import { createAction, createAsyncAction } from 'core/redux/utils'
import { IAsyncAction, ISimpleAction } from 'types/redux/interfaces/IAction'
import { IAsyncActionParams } from 'types/common/api/models/entities/async-action-params'
import { IQuestGroup } from 'modules/quests/models/quest-group'


export interface IQuestGroupActionParams extends IAsyncActionParams<IQuestGroup> {
  questGroupId?: string
}

export const setCurrentQuestGroupId: (params: IQuestGroupActionParams) => ISimpleAction = (
  params,
) => createAction(ActionTypes.SET_CURRENT_QUEST_GROUP_ID, params)

export const getQuestGroups: (params: IQuestGroupActionParams) => IAsyncAction = (params) =>
  createAsyncAction({ type: ActionTypes.GET_QUEST_GROUPS, endpoint: QuestGroupEndpoints.getQuestGroups, payload: params})

export const getQuestGroupById: (params: IQuestGroupActionParams) => IAsyncAction = (
  params,
) =>
  createAsyncAction({
    type: ActionTypes.GET_QUEST_GROUP_BY_ID,
    endpoint: QuestGroupEndpoints.getQuestGroupById,
    payload: params,
  })

export const createQuestGroup: (params: IQuestGroupActionParams) => IAsyncAction = (params) =>
  createAsyncAction({ type: ActionTypes.CREATE_QUEST_GROUP, endpoint: QuestGroupEndpoints.createQuestGroup, payload: params})

export const createQuestGroupDraft: (params: IQuestGroupActionParams) => ISimpleAction = (
  params,
) => createAction(ActionTypes.CREATE_QUEST_GROUP_DRAFT, params)

export const updateQuestGroup: (params: IQuestGroupActionParams) => IAsyncAction = (params) =>
  createAsyncAction({ type: ActionTypes.UPDATE_QUEST_GROUP, endpoint: QuestGroupEndpoints.updateQuestGroup, payload: params})
