import EventsEndpoints from 'core/api/endpoints/events'
import { createAction, createAsyncAction } from 'core/redux/utils'
import ActionTypes from 'modules/events/action-types'
import { IAsyncActionParams } from 'types/common/api/models/entities/async-action-params'
import { EventStatus } from 'types/modules/events/enums/event-status'
import { IEvent } from 'types/modules/events/models/entities/event'
import { IEventDraft } from 'types/modules/events/models/entities/event-draft'
import { IAsyncAction, ISimpleAction } from 'types/redux/interfaces/IAction'

export interface IEventActionParams
  extends IAsyncActionParams<IEvent | IEventDraft> {
  eventId?: string
  eventStatus?: EventStatus
}

export const setCurrentEventId: (
  params: IEventActionParams,
) => ISimpleAction = (params) =>
  createAction(ActionTypes.SET_CURRENT_EVENT_ID, params)

export const getEventStatuses: () => IAsyncAction = () =>
  createAsyncAction({
    type: ActionTypes.GET_EVENT_STATUSES,
    endpoint: EventsEndpoints.getEventStatuses,
    payload: null,
  })

export const getEvents: (params: IEventActionParams) => IAsyncAction = (
  params,
) =>
  createAsyncAction({ type: ActionTypes.GET_EVENTS, endpoint: EventsEndpoints.getEvents, payload: params })

export const clearEvents: () => ISimpleAction = () =>
  createAction(ActionTypes.CLEAR_EVENTS, null)

export const getEventById: (params: IEventActionParams) => IAsyncAction = (
  params,
) =>
  createAsyncAction({
    type: ActionTypes.GET_EVENT_BY_ID,
    endpoint: EventsEndpoints.getEventById,
    payload: params,
  })

export const createEvent: (params: IEventActionParams) => IAsyncAction = (
  params,
) =>
  createAsyncAction({
    type: ActionTypes.CREATE_EVENT,
    endpoint: EventsEndpoints.createEvent,
    payload: params,
  })

export const updateEvent: (params: IEventActionParams) => IAsyncAction = (
  params,
) =>
  createAsyncAction({
    type: ActionTypes.UPDATE_EVENT,
    endpoint: EventsEndpoints.updateEvent,
    payload: params,
  })

export const autocompleteEventSearch: (
  params: IEventActionParams,
) => IAsyncAction = (params) =>
  createAsyncAction({
    type: ActionTypes.AUTOCOMPLETE_EVENT_SEARCH,
    endpoint: EventsEndpoints.getEvents,
    payload: params,
  })

export const updateEventStatus: (params: IEventActionParams) => IAsyncAction = (
  params,
) =>
  createAsyncAction({
    type: ActionTypes.UPDATE_EVENT_STATUS,
    endpoint: EventsEndpoints.updateEventStatus,
    payload: params,
  })

export const uploadEventImage: (params: IEventActionParams) => IAsyncAction = (
  params,
) =>
  createAsyncAction({
    type: ActionTypes.UPLOAD_EVENT_IMAGE,
    endpoint: EventsEndpoints.uploadEventImage,
    payload: params,
  })

export const uploadEventPassImage: (
  params: IEventActionParams,
) => IAsyncAction = (params) =>
  createAsyncAction({
    type: ActionTypes.UPLOAD_EVENT_PASS_IMAGE,
    endpoint: EventsEndpoints.uploadEventPassImage,
    payload: params,
  })

export const uploadDefaultLiveScheduleImage: (
  params: IEventActionParams,
) => IAsyncAction = (params) =>
  createAsyncAction({
    type: ActionTypes.UPLOAD_DEFAULT_LIVE_SCHEDULE_IMAGE,
    endpoint: EventsEndpoints.uploadDefaultLiveScheduleImage,
    payload: params,
  })

export const promoteEvent: (
  params: IEventActionParams,
) => IAsyncAction = (params) =>
  createAsyncAction({
    type: ActionTypes.PROMOTE_EVENT,
    endpoint: EventsEndpoints.promoteEvent,
    payload: params,
  })
