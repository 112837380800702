import React from 'react'
import { PanelContainer, Panel } from 'components/panel'
import { RequestContextProvider } from 'core/api/context'
import { ShippingProvider } from 'modules/shipping/context'
import { UserPrizesDashboard } from 'modules/shipping/views/components/dashboard'
import { InfoCardProvider } from 'modules/info-cards/context'
import { InfoCardsDashboard } from 'modules/info-cards/views/components/dashboard'

export const InfoCardsDashboardPage: React.FC<{}> = () => {
  return (
    <RequestContextProvider>
      <InfoCardProvider>
        <PanelContainer>
          <Panel container xs={12}>
            <InfoCardsDashboard />
          </Panel>
        </PanelContainer>
      </InfoCardProvider>
    </RequestContextProvider>
  )
}
