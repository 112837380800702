import { all, AllEffect, ForkEffect, takeEvery } from 'redux-saga/effects'
import QuestActionTypes from 'modules/quests/action-types/quest-action-types'
import QuestGroupActionTypes from 'modules/quests/action-types/quest-group-action-types'
import EvergreenQuestActionTypes from 'modules/quests/action-types/evergreen-quest-action-types'
import GameTaskActionTypes from 'modules/quests/action-types/game-task-action-types'
import { GetQuestsSaga } from './get-quests-saga'

type RootSagaReturnType = Generator<
  ForkEffect<never> | AllEffect<any>,
  void,
  any
>

export const RootSaga = function* (): RootSagaReturnType {
  yield all([
    yield takeEvery(QuestActionTypes.GET_QUESTS, GetQuestsSaga),
    yield takeEvery(QuestActionTypes.GET_QUEST_BY_ID, GetQuestsSaga),
    yield takeEvery(QuestActionTypes.CREATE_QUEST, GetQuestsSaga),
    yield takeEvery(QuestActionTypes.UPDATE_QUEST, GetQuestsSaga),
    yield takeEvery(QuestActionTypes.ACTIVATE_QUEST, GetQuestsSaga),
    yield takeEvery(QuestActionTypes.DEACTIVATE_QUEST, GetQuestsSaga),

    yield takeEvery(QuestGroupActionTypes.GET_QUEST_GROUPS, GetQuestsSaga),
    yield takeEvery(QuestGroupActionTypes.GET_QUEST_GROUP_BY_ID, GetQuestsSaga),
    yield takeEvery(QuestGroupActionTypes.CREATE_QUEST_GROUP, GetQuestsSaga),
    yield takeEvery(QuestGroupActionTypes.UPDATE_QUEST_GROUP, GetQuestsSaga),

    yield takeEvery(
      EvergreenQuestActionTypes.GET_EVERGREEN_QUESTS,
      GetQuestsSaga,
    ),
    yield takeEvery(
      EvergreenQuestActionTypes.GET_EVERGREEN_QUEST_BY_ID,
      GetQuestsSaga,
    ),
    yield takeEvery(
      EvergreenQuestActionTypes.CREATE_EVERGREEN_QUEST,
      GetQuestsSaga,
    ),
    yield takeEvery(
      EvergreenQuestActionTypes.UPDATE_EVERGREEN_QUEST,
      GetQuestsSaga,
    ),
    yield takeEvery(
      EvergreenQuestActionTypes.CREATE_EVERGREEN_QUEST_LEVEL,
      GetQuestsSaga,
    ),
    yield takeEvery(
      EvergreenQuestActionTypes.UPDATE_EVERGREEN_QUEST_LEVEL,
      GetQuestsSaga,
    ),

    yield takeEvery(
      EvergreenQuestActionTypes.UPLOAD_EVERGREEN_QUEST_IMAGE,
      GetQuestsSaga,
    ),

    yield takeEvery(
      GameTaskActionTypes.GET_GAME_TASKS,
      GetQuestsSaga,
    ),
    yield takeEvery(
      GameTaskActionTypes.GET_GAME_TASK_STEPS,
      GetQuestsSaga,
    ),
    yield takeEvery(
      GameTaskActionTypes.GET_GAME_TASK_BY_ID,
      GetQuestsSaga,
    ),
    yield takeEvery(
      GameTaskActionTypes.CREATE_GAME_TASK,
      GetQuestsSaga,
    ),
    yield takeEvery(
      GameTaskActionTypes.UPDATE_GAME_TASK,
      GetQuestsSaga,
    ),
    yield takeEvery(
      GameTaskActionTypes.CREATE_GAME_TASK_STEP,
      GetQuestsSaga,
    ),
    yield takeEvery(
      GameTaskActionTypes.UPDATE_GAME_TASK_STEP,
      GetQuestsSaga,
    ),

    yield takeEvery(
      EvergreenQuestActionTypes.UPLOAD_EVERGREEN_QUEST_IMAGE,
      GetQuestsSaga,
    ),
  ])
}
