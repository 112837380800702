import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import queryString from 'query-string'
import { IKeyValueStringCollection } from 'types/common/interfaces/IKeyValueStringCollection'
import IApiRequest from 'types/common/api/models/entities/api-request'
import { ApiRequestParams } from 'types/common/api/models/entities/api-request-params'
import { ApiResponse } from 'types/common/api/models/entities/api-response'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'

const getDefaultHeaders: () => IKeyValueStringCollection = () => {
  const headers = {
    'Content-Type': 'application/json',
    Accept: '*/*',
  }
  return headers
}

const makeRequest = async (
  params: AxiosRequestConfig,
): Promise<ApiResponse> => {
  if (IsNullOrUndefined(params.headers)) {
    params.headers = getDefaultHeaders()
  }

  try {
    const response: AxiosResponse = await axios(params)

    return {
      response: {
        ok: true,
      },
      data: response.data,
    } as ApiResponse
  } catch (error) {
    return {
      response: {
        ok: false,
      },
      error: {
        // @ts-expect-error will write problem later
        data: error.response?.data,
        // @ts-expect-error will write problem later
        message: error.response?.data ? error.response.data : error.message,
        // @ts-expect-error will write problem later
        status: error.status || error.response?.status,
        // @ts-expect-error will write problem later
        statusText: error.statusText || error.response?.statusText,
      },
    } as ApiResponse
  }
}

export const request: IApiRequest = async (
  params: ApiRequestParams,
): Promise<ApiResponse> => {
  const { url, query } = queryString.parseUrl(params.url)
  const newQueryString = encodeURI(
    queryString.stringify({ ...query, ...params.params }),
  )
  const newURL = newQueryString ? `${url}?${newQueryString}` : url
  return makeRequest({
    url: decodeURI(newURL),
    method: params.method,
    headers: params.headers,
    data: params.body
      ? IsNullOrUndefined(params.headers)
        ? JSON.stringify(params.body)
        : params.body
      : null,
  })
}
