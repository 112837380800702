import UserPrizeEndpoints from 'core/api/endpoints/user-prizes'
import { createAction, createAsyncAction } from 'core/redux/utils'
import { ActionTypes } from 'modules/shipping'
import { IAsyncActionParams } from 'types/common/api/models/entities/async-action-params'
import { IUserPrizeHistoryDraft } from 'types/modules/shipping/models/entities/user-prize-history-draft'
import { IAsyncAction, ISimpleAction } from 'types/redux/interfaces/IAction'

export interface IShippingActionParams
  extends IAsyncActionParams<string | IUserPrizeHistoryDraft> {
  userPrizeId?: string
  userPrizeStatus?: string
}

export const clearUserPrizes: () => ISimpleAction = () =>
  createAction(ActionTypes.CLEAR_USER_PRIZES, null)

export const setCurrentUserPrizeId: (
  params: IShippingActionParams,
) => ISimpleAction = (params) =>
  createAction(ActionTypes.SET_CURRENT_USER_PRIZE_ID, params)

export const getUserPrizes: (params: IShippingActionParams) => IAsyncAction = (
  params,
) =>
  createAsyncAction({
    type: ActionTypes.GET_USER_PRIZES,
    endpoint: UserPrizeEndpoints.getUserPrizes,
     payload: params,
  })

export const getUserPrizeStatuses: () => IAsyncAction = () =>
  createAsyncAction({
    type: ActionTypes.GET_USER_PRIZE_STATUSES,
    endpoint: UserPrizeEndpoints.getUserPrizeStatuses,
    payload: null,
  })

export const updateUserPrizeStatus: (
  params: IShippingActionParams,
) => IAsyncAction = (params) =>
  createAsyncAction({
    type: ActionTypes.UPDATE_USER_PRIZE_STATUS,
    endpoint: UserPrizeEndpoints.updateUserPrizeStatus,
     payload: params,
  })

export const createUserPrizeNote: (
  params: IShippingActionParams,
) => IAsyncAction = (params) =>
  createAsyncAction({
    type: ActionTypes.CREATE_USER_PRIZE_NOTE,
    endpoint: UserPrizeEndpoints.createUserPrizeNote,
     payload: params,
  })
