import React, { PropsWithChildren } from 'react'
import { Grid, Theme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

interface IPanelContainerProps {
  noMargin?: boolean
  noHeight?: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    panelContainer: {
      flex: 1,
      flexWrap: 'initial',
    },
    height: {
      height: 100,
    },
  }),
)

const PanelContainer: React.FC<PropsWithChildren<IPanelContainerProps>> = (
  props: PropsWithChildren<IPanelContainerProps>,
) => {
  const classes = useStyles()

  return (
    <Grid
      container
      className={`${classes.panelContainer} ${
        props.noHeight ? '' : classes.height
      }`}
      spacing={props.noMargin ? 0 : 3}
    >
      {props.children ?? null}
    </Grid>
  )
}

export default PanelContainer
