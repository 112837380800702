import { Box, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, { PropsWithChildren } from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iphoneX: {
      zoom: '0.7',
      // height: 612,
      height: 926,
      // width: 314,
      width: 428,
      display: 'flex',
      // border: '12px solid rgba(0,0,0,0.01)',
      background: '#FFFFFF',
      borderRadius: 15,
      boxShadow: '0 10px 18px rgb(0 0 0 / 20%), 0 10px 12px rgb(0 0 0 / 5%)',
      position: 'relative',
      overflow: 'hidden',
      paddingTop: 26,
      '&::before': {
        position: 'absolute',
        top: 0,
        width: '100%',
        height: 26,
        content: '""',
        borderRadius: '15px 15px 0 0',
        background: '#ff787d',
        zIndex: 1,
      },
    },
  }),
)

export const MobileDevice: React.FC<PropsWithChildren<{}>> = (
  props: PropsWithChildren<{}>,
) => {
  const classes = useStyles()
  return <Box className={classes.iphoneX}>{props.children}</Box>
}
