import { Box, Button, ButtonBase, Popover, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx'
import { PlusIcon } from 'components/icons'
import { usePostManagerContext } from 'modules/posts/context/post-manager'
import React from 'react'
import PostType from 'types/modules/posts/enums/post-type'
import { IPost } from 'types/modules/posts/models/entities/post'
import { IPostQuestion } from 'types/modules/posts/models/entities/post-question'
import { IPostResult } from 'types/modules/posts/models/entities/post-result'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    navItemButton: {
      display: 'flex',
      padding: 16,
    },
    postNavigationItem: {
      display: 'flex',
      width: 40,
      height: 40,
      borderRadius: 90,
      alignItems: 'center',
      justifyContent: 'center',
      background: 'rgba(0,0,0,0.03)',
      color: theme.palette.text.primary,
      '&.active': {
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      },
    },
    navItemIcon: {
      fontSize: 16,
    },
    popover: {
      padding: '0.625rem',
      flexDirection: 'column',
    },
    popoverButton: {
      display: 'flex',
      width: '100%',
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
      marginBottom: '0.625rem',
      '&:last-of-type': {
        margin: 0,
      },
    },
  }),
)

interface IPostNavigationAddButtonProps {
  post: IPost
  navigateToResultOrQuestion: (itemId: string) => void
}

export const PostNavigationAddButton: React.FC<
  IPostNavigationAddButtonProps
> = (props: IPostNavigationAddButtonProps) => {
  const { post, navigateToResultOrQuestion } = props

  const classes = useStyles()

  const { addPostQuestion, addPostResult } = usePostManagerContext()

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handlePopoverClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ): void => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = (): void => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  // eslint-disable-next-line id-blacklist
  const id = open ? 'add-post-content' : undefined

  return (
    <React.Fragment>
      <ButtonBase
        className={classes.navItemButton}
        onClick={handlePopoverClick}
      >
        <Box className={clsx(classes.postNavigationItem)}>
          <Box className={classes.navItemIcon}>
            <PlusIcon />
          </Box>
        </Box>
      </ButtonBase>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box className={classes.popover}>
          {post.type === PostType.Test && (
            <Button
              variant="contained"
              size="small"
              className={classes.popoverButton}
              onClick={(): void => {
                addPostResult(post.id).then((result: IPostResult) => {
                  navigateToResultOrQuestion(result.id)
                })
                handlePopoverClose()
              }}
            >
              Result
            </Button>
          )}
          <Button
            variant="contained"
            size="small"
            className={classes.popoverButton}
            onClick={(): void => {
              addPostQuestion(post.id, post.questions.length).then(
                (question: IPostQuestion) => {
                  navigateToResultOrQuestion(question.id)
                },
              )
              handlePopoverClose()
            }}
          >
            Question
          </Button>
        </Box>
      </Popover>
    </React.Fragment>
  )
}
