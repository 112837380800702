import { Box, Button, Grid, IconButton, SvgIcon, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { CrossIcon, PlusIcon } from 'components/icons'
import { FormGen, FormTypes } from 'components/simple-form-gen'
import { Currency } from 'fe-shared-resources'
import { IIapProductCurrencyAndAmount } from 'modules/iap-products/models/iap-product'
import React from 'react'

const useStyles = makeStyles(() =>
  createStyles({
    iconButton: {
      width: 30,
      height: 30,
    },
    crossButton: {
      marginTop: 35,
    },
  }),
)

const defaultCurrencyItemValue: IIapProductCurrencyAndAmount = {
  currency: Currency.Coin,
  amount: 0,
}

const currency = {
  label: 'Currency',
  type: FormTypes.SELECT,
  options: Object.values(Currency).map((code) => ({ value: code, name: code })),
}

const amount = {
  label: 'Amount',
  type: FormTypes.TEXT_INPUT,
}

interface ICurrenciesProps {
  value: IIapProductCurrencyAndAmount[]
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  handleChange: (e: React.ChangeEvent<any>) => void
  errors: any[]
}

export const Currencies = ({
  value,
  setFieldValue,
  handleChange,
  errors,
}: ICurrenciesProps): JSX.Element => {
  const addCurrencyItem = (): void => {
    setFieldValue('metaData.productConfig.currencies', [
      ...value,
      { ...defaultCurrencyItemValue, id: Date.now() },
    ])
  }
  const removeCurrencyItem = (currencyItemId: number): void => {
    setFieldValue(
      'metaData.productConfig.currencies',
      value.filter((item) => item.id !== currencyItemId),
    )
  }
  const classes = useStyles()

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={10}>
          <Typography variant="h6">Currencies</Typography>
        </Grid>
        <Grid item xs={2}>
          <IconButton
            size="small"
            onClick={(): void => addCurrencyItem()}
            className={classes.iconButton}
          >
            <PlusIcon />
          </IconButton>
        </Grid>
        {value.map((currencyItem, index) => (
          <Grid container spacing={2} key={currencyItem.id}>
            <Grid item xs={1}>
              <IconButton
                className={`${classes.crossButton} ${classes.iconButton}`}
                onClick={(): void => removeCurrencyItem(currencyItem.id!)}
                size="large">
                <SvgIcon>
                  <CrossIcon />
                </SvgIcon>
              </IconButton>
            </Grid>
            <Grid item xs={6}>
              <FormGen
                {...currency}
                name={`metaData.productConfig.currencies[${index}].currency`}
                handleChange={handleChange}
                value={currencyItem.currency}
                otherProps={{
                  error: !!errors[index]?.currency,
                  helperText: errors[index]?.currency,
                }}
              />
            </Grid>
            <Grid item xs={5}>
              <FormGen
                {...amount}
                name={`metaData.productConfig.currencies[${index}].amount`}
                handleChange={handleChange}
                value={currencyItem.amount}
                otherProps={{
                  error: !!errors[index]?.amount,
                  helperText: errors[index]?.amount,
                  type: 'number',
                }}
              />
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
