import { Theme, Popover, PopoverProps, Box } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { IPopoverApi, IPopoverProps } from 'components/popover/use-popover'
import PostActionButtons from 'modules/posts/views/components/action-buttons'
import { CreatePostDialogButton } from 'modules/posts/views/components/create-post-dialog-button'
import React from 'react'
import { PostActionButtonType } from 'types/modules/posts/enums/post-action-button-type'
import { IPost } from 'types/modules/posts/models/entities/post'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contextMenu: {
      display: 'flex',
      flexDirection: 'column',
      padding: '0.625rem',
      '& .MuiButton-contained': {
        marginBottom: '0.625rem',
        '&:last-child': {
          marginBottom: 0,
        },
      },
    },
  }),
)

interface IPostTimeGridContextMenuProps {
  post?: IPost
  dateTime: string
  dateField: string
  popoverProps: IPopoverProps
}

export const PostTimeGridContextMenu: React.FC<
  IPostTimeGridContextMenuProps
> = (props: IPostTimeGridContextMenuProps) => {
  const { post, dateTime, dateField, popoverProps } = props

  const classes = useStyles()

  return (
    <Popover {...popoverProps}>
      <Box className={classes.contextMenu}>
        <CreatePostDialogButton
          initialData={{
            [dateField]: dateTime,
          }}
          buttonProps={{
            variant: 'contained',
            color: 'primary',
            size: 'small',
          }}
          onPopoverClose={(): void => {
            popoverProps.onClose()
          }}
        />
        {post && (
          <PostActionButtons
            post={post}
            exclude={[
              PostActionButtonType.Duplicate,
              PostActionButtonType.Approve,
              PostActionButtonType.Reject,
            ]}
            onPopoverClose={(): void => {
              popoverProps.onClose()
            }}
          />
        )}
      </Box>
    </Popover>
  )
}
