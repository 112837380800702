import { Box, Grid, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import _ from 'core/utils/deepdash'
import { FormGen, FormTypes } from 'components/simple-form-gen'
import React from 'react'
import { IForm } from 'types/common/interfaces/IForm'
import { IMilestone } from 'modules/milestones/models/milestone'
import { LabelValue } from 'components/label-value';
import moment from 'moment-timezone';
import { Currency } from 'fe-shared-resources';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      width: '100%',
      height: '600px',
      '& .MuiGrid-item': {
        display: 'flex',
        flexDirection: 'column',
      },
    },
    milestonesWrapper: {
      borderLeft: '1px solid #f4f4f4',
      paddingLeft: 8,
      height: '100%',
    },
    container: {
      height: '100%',
    },
    milestonesError: {
      marginTop: -16,
    },

    inputLabel: {
      display: 'flex',
      marginBottom: '0.625rem',
    },

    imageHint: {
      marginTop: 8,
      color: 'red',
    },
    imageHintText: {
      marginLeft: 8,
    },
    imageContainer: {
      height: 200,
      width: '100%',
    },
  }),
)

interface IMilestoneFormProps extends IForm {
  milestone: IMilestone
  isUpdating?: boolean
}

const currencyOptions = Object.values(Currency).map((code) => ({
  value: code,
  name: code,
}))

export const MilestoneForm: React.FC<IMilestoneFormProps> = ({
  milestone,
  handleChange,
  setFieldValue,
  errors,
  isUpdating,
}): JSX.Element => {
  const classes = useStyles()

  const name = {
    name: 'name',
    label: 'Name',
    value: milestone?.name || '',
    type: FormTypes.TEXT_INPUT,
    otherProps: {
      error: !!errors?.name,
      helperText: errors?.name,
    },
  }

  const rewardAmount = {
    name: 'rewardAmount',
    label: 'Reward amount',
    value: milestone?.rewardAmount || '',
    type: FormTypes.TEXT_INPUT,
    otherProps: {
      error: !!errors?.rewardAmount,
      helperText: errors?.rewardAmount,
    },
  }
  const description = {
    name: 'description',
    label: 'Description',
    value: milestone?.description || '',
    type: FormTypes.TEXT_INPUT,
    otherProps: {
      error: !!errors?.description,
      helperText: errors?.description,
      multiline: true,
    },
  }

  const rewardCurrency = {
    name: 'rewardCurrency',
    label: 'Reward Currency',
    value: milestone?.rewardCurrency,
    type: FormTypes.SELECT,
    options: currencyOptions,
    otherProps: {
      error: !!errors?.rewardCurrency,
      helperText: errors?.rewardCurrency,
    },
  }

  return (
    <Box className={classes.form}>
      <Grid container spacing={2} className={classes.container}>
        <Grid item xs={7}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormGen handleChange={handleChange} {...name} />
            </Grid>
            <Grid item xs={12}>
              <FormGen handleChange={handleChange} {...rewardAmount} />
            </Grid>
            <Grid item xs={12}>
              <FormGen handleChange={handleChange} {...rewardCurrency} />
            </Grid>
            <Grid item xs={12}>
              <FormGen handleChange={handleChange} {...description} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={5}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <LabelValue label="Code" value={milestone.code} />
            </Grid>
            <Grid item xs={12}>
              <LabelValue label="Active" value={milestone.active ? 'Yes' : 'No'} />
            </Grid>
            <Grid item xs={12}>
              <LabelValue label="Internal Only" value={milestone.internalOnly ? 'Yes' : 'No'} />
            </Grid>
            <Grid item xs={12}>
              <LabelValue label="Start" value={moment(milestone?.start).format('D MMMM YYYY HH:mm:ss')} />
            </Grid>
            <Grid item xs={12}>
              <LabelValue label="Created at" value={moment(milestone?.createdAt).format('D MMMM YYYY HH:mm:ss')} />
            </Grid>
            <Grid item xs={12}>
              <LabelValue label="Updated at" value={moment(milestone?.updatedAt).format('D MMMM YYYY HH:mm:ss')} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}
