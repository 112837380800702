import { Box, TextField } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { PickerDialog } from 'components/color-picker/picker-dialog'
import React, { useCallback, useState } from 'react'

const useStyles = makeStyles(() =>
  createStyles({
    sample: {
      width: '20px',
      border: '1px solid #000',
      height: '20px',
      display: 'inline-flex',
      marginTop: '10px',
      borderRadius: '4px',
      marginLeft: '-25px',
    },
  }),
)

interface IColorPickerProps {
  onChange: (color: string) => void
  initialExpanded?: boolean
  name?: string
  value?: string
}

export const ColorPicker: React.FC<IColorPickerProps> = (props) => {
  const [expanded, setExpanded] = useState(!!props.initialExpanded)
  const classes = useStyles()
  const onClick = useCallback((): void => {
    setExpanded(false)
  }, [])
  const onClickTextField = useCallback((): void => {
    setExpanded(true)
  }, [])
  const onChangeColor = useCallback((color: any): void => {
    props.onChange(color.hex)
  }, [])

  return (
    <>
      <Box>
        <TextField
          onClick={onClickTextField}
          name={props.name}
          onChange={(event: any): void => {
            props.onChange(event.target.value)
          }}
          value={props.value || ''}
          variant="filled"
        />
        <span
          onClick={onClickTextField}
          className={classes.sample}
          style={{ backgroundColor: props.value }}
        ></span>
      </Box>
      {expanded && (
        <PickerDialog
          value={props.value}
          onClick={onClick}
          onChange={onChangeColor}
        />
      )}
    </>
  )
}
