import { Box, Button, Grid, InputLabel } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import _ from 'core/utils/deepdash'
import { FormGen, FormTypes } from 'components/simple-form-gen'
import React, { useEffect, useMemo, useState } from 'react'
import { IForm } from 'types/common/interfaces/IForm'
import { MediaItemInput } from 'modules/media-items/media-item-input'
import { MediaOrientation } from 'types/common/images/enum/media-orientation'
import { AspectRatio } from 'types/common/images/enum/aspect-ratio'
import { MediaUploadModel } from 'types/common/images/models/entities/image-upload-model'
import { LiveOpsAction, GameTaskStep, GameTaskStepCode } from 'fe-shared-resources';
import { createOptionsFromEnum } from 'utils/common';
import { GameTaskStepParamsForm } from 'modules/quests/view/game-tasks/game-task-modal/game-task-step-params-form';
import { GameTaskStepParamsVariantSelect } from 'modules/quests/view/game-tasks/game-task-modal/game-task-step-params-variant-select';

const useStyles = makeStyles(() =>
  createStyles({
    form: {
      width: '100%',
      height: '600px',
      '& .MuiGrid-item': {
        display: 'flex',
        flexDirection: 'column',
      },
    },
    container: {
      height: '100%',
    },
    inputLabel: {
      display: 'flex',
      marginBottom: '0.625rem',
    },
    deleteButtonWrapper: {
      flexDirection: 'row',
      justifyContent: 'end',
      display: 'flex',
    },
  }),
)

export interface FormGameTaskStep extends GameTaskStep {
  stepParamsObject?: {
    [key: string]: {
      [key: string]: any
    }
  }
}

interface IGameTaskStepFormProps extends IForm {
  gameTaskStep: FormGameTaskStep
  isUpdating?: boolean
  resetCounter: number
  stepParamsErrors: { [key: string]: 'string' }
}

const NEW_VALUE = 'new'
const gameTaskStepCodeOptions = createOptionsFromEnum(Object.values(GameTaskStepCode))
const gameTaskStepParamTypeOptions = createOptionsFromEnum(Object.values(LiveOpsAction))

export const GameTaskStepForm: React.FC<IGameTaskStepFormProps> = ({
  gameTaskStep,
  handleChange,
  errors,
  isUpdating,
  setFieldValue,
  resetCounter,
  stepParamsErrors,
}): JSX.Element => {
  const classes = useStyles()
  const [variant, setVariant] = useState('')

  const elements = [
    {
      name: 'stepNumber',
      label: 'Step number',
      value: gameTaskStep?.stepNumber,
      type: FormTypes.TEXT_INPUT,
      disabled: true,
      otherProps: {
        type: 'number',
      },
    },
    {
      name: 'name',
      label: 'Param type',
      value: gameTaskStep?.name,
      type: FormTypes.SELECT,
      options: gameTaskStepParamTypeOptions,
      handleChange: (e: any): void => {
        setVariant('')
        setFieldValue(`name`, e.target.value)
        setFieldValue('stepParamsObject', {})
      },
      otherProps: {
        error: !!errors?.name,
        helperText: errors?.name,
        hint: 'Changing this will clear variants',
      },
    },
    {
      name: 'taskStepCode',
      label: 'Task Step Code',
      value: gameTaskStep?.taskStepCode || '',
      type: FormTypes.SELECT,
      options: gameTaskStepCodeOptions,
      otherProps: {
        error: !!errors?.taskStepCode,
        helperText: errors?.taskStepCode,
      },
      disabled: isUpdating
    },
    {
      name: 'description',
      label: 'Description',
      value: gameTaskStep?.description,
      type: FormTypes.TEXT_INPUT,
      otherProps: {
        error: !!errors?.description,
        helperText: errors?.description,
      },
    },
    {
      name: 'pointsRequired',
      label: 'Points required',
      value: gameTaskStep?.pointsRequired,
      type: FormTypes.TEXT_INPUT,
      otherProps: {
        error: !!errors?.pointsRequired,
        helperText: errors?.pointsRequired,
        type: 'number',
      },
      disabled: isUpdating
    },
  ]

  const variantOptionsLength = Object.keys(gameTaskStep.stepParamsObject || {}).length

  useEffect(() => {
    setVariant('')
  }, [resetCounter])

  const variantOptions = useMemo(() => {
    const stepParamsObject = gameTaskStep.stepParamsObject || {}
    const options = [...Object.keys(stepParamsObject)]
    if (!stepParamsObject[NEW_VALUE]) {
      options.push(NEW_VALUE)
    }
    return options
  }, [gameTaskStep.stepParams.liveOps, variantOptionsLength])

  const switchButtonsDisabled = useMemo(() => {
    if (!gameTaskStep.name) {
      return true
    }
    const variantKeys = Object
      .keys(stepParamsErrors || {})
      .map(errorKey => errorKey.split('.')[0])

    return (!gameTaskStep.name || variantKeys && variantKeys.includes(variant))
  }, [stepParamsErrors, gameTaskStep.name])

  const onSetDefault = (changedVariant: string, isDefault: boolean) => {
    console.log(changedVariant, isDefault, gameTaskStep.stepParamsObject)
    if (!isDefault) return
    const stepParamsObject = gameTaskStep.stepParamsObject || {}
    setFieldValue(
      'stepParamsObject',
      Object.keys(stepParamsObject).reduce((acc, curr) => ({
        ...acc,
        [curr]: {
          ...stepParamsObject[curr],
          isDefault: changedVariant === curr ? isDefault : false
        }
      }), {})
    )
  }

  const onRemoveVariant = (variantToBeDeleted: string) => {
    const stepParamsObject = gameTaskStep.stepParamsObject || {}
    setFieldValue(
      'stepParamsObject',
      Object.keys(stepParamsObject).reduce((acc, curr) => {
        if (variantToBeDeleted === curr) return acc
        return { ...acc, [curr]: stepParamsObject[curr] }
      }, {})
    )
    setVariant('')
  }

  return (
    <Box className={classes.form}>
      <Grid container spacing={2} className={classes.container}>
        <Grid item xs={5}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputLabel className={classes.inputLabel}>Media</InputLabel>
              <Box>
                <MediaItemInput
                  url={gameTaskStep?.imageUrl}
                  imageLabel={'Cover Image'}
                  options={{
                    aspectRatio: AspectRatio.Standard,
                    orientation: MediaOrientation.Portrait,
                  }}
                  onComplete={(fileParams: MediaUploadModel): void => {
                    setFieldValue('imageUrl', fileParams.imageUrl)
                  }}
                  removeCrop
                  mediaSquareHeight={400}
                  doneLabel="Confirm"
                  uploadMediaLabel="Choose Media"
                  button
                  hasAttachment={!!gameTaskStep?.imageUrl}
                  buttonLabel="Upload Image"
                />
              </Box>
            </Grid>
            {elements.map((element) => (
              <Grid item xs={12} key={element.name}>
                <FormGen handleChange={handleChange} {...element} />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={7}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <GameTaskStepParamsVariantSelect
                options={variantOptions}
                disabled={switchButtonsDisabled}
                onSelect={
                  (option: string) => {
                    setVariant(option)
                  }
                }
                value={variant}
              />
            </Grid>
            {
              !!variant && variant !== NEW_VALUE && <Grid
                item
                xs={12}
              >
                <Box className={classes.deleteButtonWrapper}>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => onRemoveVariant(variant)}
                  >
                    Delete
                  </Button>
                </Box>
              </Grid>
            }
            {
              !!gameTaskStep.stepParamsObject &&
              (!!gameTaskStep.stepParamsObject[variant] || variant === NEW_VALUE) &&
              <Grid item xs={12}>
                <GameTaskStepParamsForm
                  setFieldValue={setFieldValue}
                  value={gameTaskStep.stepParamsObject[variant] || {}}
                  handleChange={handleChange}
                  paramType={gameTaskStep.name as LiveOpsAction}
                  prefix={`stepParamsObject.${variant}`}
                  onSetDefault={onSetDefault}
                  currentVariant={variant}
                  stepParamsErrors={stepParamsErrors}
                />
              </Grid>
            }
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}
