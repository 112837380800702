import {
  Rarity,
  ChanceRewardCurrencies,
  ChanceRewardType,
  ChestFieldName,
} from 'modules/chests/enums/chest-field'

export const MODULE_NAME = 'chests'

export const NEW_CHANCE_REWARD = {
  [ChestFieldName.PRIZE_RARITY]: 'NotSet',
  [ChestFieldName.PROBABILITY]: 1,
  [ChestFieldName.AMOUNT]: 1,
  [ChestFieldName.TYPE]: 'Currency',
  [ChestFieldName.CURRENCY]: 'Star',
}

export const RARITY_OPTIONS = [
  { label: 'Select rarity', value: Rarity.NOT_SET },
  { label: 'Basic', value: Rarity.BASIC },
  { label: 'Epic', value: Rarity.EPIC },
  { label: 'Rare', value: Rarity.RARE },
  { label: 'Legendary', value: Rarity.LEGENDARY },
]

export const CHANCE_REWARD_TYPE_OPTIONS = [
  { label: 'Currency', value: ChanceRewardType.CURRENCY },
  { label: 'Prize', value: ChanceRewardType.PRIZE },
]

export const CHANCE_REWARD_CURRENCY_OPTIONS = [
  { label: 'Stars', value: ChanceRewardCurrencies.STAR },
  { label: 'Coins', value: ChanceRewardCurrencies.COIN },
]

export const PAGE_SIZE = 10
