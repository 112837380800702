import { Fade, Modal, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Panel } from 'components/panel'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { PostMediaPanel } from 'modules/posts/views/components/media-modal/post-media-panel'
import React from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 40,
    },
  }),
)

interface IPostMediaModalProps {
  date: string | null
}

export const PostMediaModal: React.FC<IPostMediaModalProps> = (
  props: IPostMediaModalProps,
) => {
  const classes = useStyles()
  return (
    <Modal
      className={classes.modal}
      open={!IsNullOrUndefined(props.date)}
    >
      <Fade in={!IsNullOrUndefined(props.date)}>
        <Panel
          xs={12}
          style={{
            display: 'flex',
            flex: 1,
            height: '100%',
            width: '100%',
          }}
        >
          <PostMediaPanel date={props.date!} />
        </Panel>
      </Fade>
    </Modal>
  )
}
