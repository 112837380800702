import React from 'react'
import { Theme, TableCell, TableRow } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { INewsCard } from 'modules/news-cards/models/news-card'
import moment from 'moment-timezone'

interface ICardProps {
  item: INewsCard
  onClick: () => void
}

export const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }),
)(TableCell)

export const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.secondary.light,
      },
    },
  }),
)(TableRow)

export const NewsCard: React.FC<ICardProps> = ({
  item,
  onClick,
}: ICardProps) => {
  return (
    <StyledTableRow key={item.id} onClick={onClick}>
      <StyledTableCell component="th" scope="row">
        {item.title}
      </StyledTableCell>
      <StyledTableCell>{item?.media?.description}</StyledTableCell>
      <StyledTableCell>{moment(item?.createdAt).format('D MMMM YYYY HH:mm:ss')}</StyledTableCell>
      <StyledTableCell>{item?.variant || 'N/A'}</StyledTableCell>
    </StyledTableRow>
  )
}
