import { Box, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx'
import { InstantPurchaseIcon, SweepstakeIcon } from 'components/icons'
import React from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    prizeListLegend: {
      display: 'flex',
      padding: '0.625rem',
      borderRadius: 4,
    },
    legendItem: {
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.text.primary,
      marginRight: '1.25rem',
      '&:last-of-type': {
        marginRight: 0,
      },
    },
    icon: {
      marginRight: '0.625rem',
      '&.notification': {
        color: theme.palette.primary.main,
      },
    },
    label: {},
  }),
)

export const PrizeListLegend: React.FC<{}> = () => {
  const classes = useStyles()

  return (
    <Box className={classes.prizeListLegend}>
      <Box className={classes.legendItem}>
        <Box className={classes.icon}>
          <InstantPurchaseIcon />
        </Box>
        <Typography variant="body2" color="textPrimary">
          Instant
        </Typography>
      </Box>
      <Box className={classes.legendItem}>
        <Box className={classes.icon}>
          <SweepstakeIcon />
        </Box>
        <Typography variant="body2" color="textPrimary">
          Sweepstake
        </Typography>
      </Box>
    </Box>
  )
}
