import { IAutocompleteSearchSelectProps } from 'components/controls/inputs/downshift/autocomplete/autocomplete-search-select-props'
import {
  Item,
} from 'components/controls/inputs/downshift/item'
import DownshiftSelect from 'components/controls/inputs/downshift/select'
import { useTypedSelector } from 'core/redux/utils'
import { get } from 'lodash-es'
import React, {
  PropsWithChildren,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useDispatch } from 'react-redux'
import { IAsyncActionParams } from 'types/common/api/models/entities/async-action-params'

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function AutocompleteSearchSelect<TItem, TActionTypeParams>(
  props: PropsWithChildren<IAutocompleteSearchSelectProps<Item<TItem>>>,
): ReactElement<any, any> | null {
  const [inputValue, setInputValue] = useState('')
  const { fieldName = 'name', fieldNames = ['name'] } = props
  const timeout = useRef<any | null>(null)

  const availableItems: TItem[] = useTypedSelector((state) =>
    props.selectorMethod(state),
  )

  const handleInputValueChanged: (value: string) => void = (value) => {
    setInputValue(value)
  }


  const dispatch = useDispatch()

  useEffect(() => {
    clearTimeout(timeout.current)
    if (
      inputValue === '' ||
      inputValue.length < 3
    ) {
      return
    }

    const searchParams: IAsyncActionParams<TItem> = {
      search: {
        fields: fieldNames,
        value: inputValue,
      },
    }

    timeout.current = setTimeout(() => {
      dispatch(
        props.searchAsyncActionCreator<IAsyncActionParams<TItem>>(searchParams)
      )
    }, 1000)

    return (): void => {
      clearTimeout(timeout.current)
    }
  }, [inputValue])

  return (
    <DownshiftSelect
      label={props.label}
      variant={props.variant}
      availableItems={availableItems}
      onSelectedItemChange={props.onSelectedItemChange}
      onInputValueChanged={handleInputValueChanged}
      initialSelectedItem={props.initialSelectedItem}
      options={props.options}
      fieldName={fieldName}
      optionDisabled={props.optionDisabled}
    />
  )
}
