import { Checkbox, FormControlLabel, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { IFilterOptionComponentProps } from 'core/filtering/components/select-filter'
import React from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      display: 'flex',
      margin: '0',
    },
    checkbox: {
      padding: 0,
      marginRight: '0.625rem',
    },
  }),
)

export const CheckboxFilter: React.FC<IFilterOptionComponentProps> = (
  props: IFilterOptionComponentProps,
) => {
  const classes = useStyles()
  return (
    <FormControlLabel
      className={classes.label}
      control={
        <Checkbox
          className={classes.checkbox}
          size="small"
          checked={props.isActive}
          onClick={(): void => props.onClick(props.value, props.isActive)}
          name={props.value.toString()}
          color="primary"
        />
      }
      label={props.label}
    />
  )
}
