import { ButtonBase, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx'
import { IFilterOptionComponentProps } from 'core/filtering/components/select-filter'
import React, { MouseEventHandler } from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabFilter: {
      display: 'flex',
      flexDirection: 'column',
      width: 90,
      height: 40,
      borderRadius: 8,
      marginRight: '0.625rem',
      background: 'rgba(0,0,0,0.03)',
      '&.active': {
        '&::after': {
          display: 'block',
          position: 'absolute',
          width: '100%',
          height: '0.3125rem',
          content: '""',
          bottom: 0,
          background: theme.palette.primary.main,
        },
      },
      '&:last-child': {
        margin: 0,
      },
    },
    tabFilterCount: {
      color: 'rgba(0,0,0,0.54)',
      fontSize: 12,
    },
    tabFilterLabel: {
      color: 'rgba(0,0,0,0.54)',
      fontSize: 12,
    },
  }),
)

export const FilterTab: React.FC<IFilterOptionComponentProps> = (
  props: IFilterOptionComponentProps,
) => {
  const classes = useStyles()

  return (
    <ButtonBase
      key={props.label}
      className={clsx(classes.tabFilter, props.isActive && 'active')}
      onClick={(): void => props.onClick(props.value, props.isActive)}
    >
      {!isNaN(props.metadata?.count) && (
        <Typography
          component="span"
          variant="h5"
          color="textPrimary"
          className={classes.tabFilterCount}
          style={{ color: props.labelColor }}
        >
          {props.metadata!.count!} {props.label}
        </Typography>
      )}
    </ButtonBase>
  )
}
