import { Theme, Popover, PopoverProps, Box } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { IPopoverApi, IPopoverProps } from 'components/popover/use-popover'
import { CreatePostDialogButton } from 'modules/posts/views/components/create-post-dialog-button'
import { PrizeActionButtons } from 'modules/prizes/views/components/action-buttons'
import React from 'react'
import { PostActionButtonType } from 'types/modules/posts/enums/post-action-button-type'
import { IPrize } from 'types/modules/prizes/models/entities/prize'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contextMenu: {
      display: 'flex',
      flexDirection: 'column',
      padding: '0.625rem',
      '& .MuiButton-contained': {
        marginBottom: '0.625rem',
        '&:last-child': {
          marginBottom: 0,
        },
      },
    },
  }),
)

interface IPrizeTimeGridContextMenuProps {
  prize?: IPrize
  dateTime: string
  dateField: string
  popoverProps: IPopoverProps
}

export const PrizeTimeGridContextMenu: React.FC<
  IPrizeTimeGridContextMenuProps
> = (props: IPrizeTimeGridContextMenuProps) => {
  const { prize, dateTime, dateField, popoverProps } = props

  const classes = useStyles()

  return (
    <Popover {...popoverProps}>
      <Box className={classes.contextMenu}>
        <CreatePostDialogButton
          initialData={{
            [dateField]: dateTime,
          }}
          buttonProps={{
            variant: 'contained',
            color: 'primary',
            size: 'small',
          }}
          onPopoverClose={(): void => {
            popoverProps.onClose()
          }}
        />
        {prize && (
          <PrizeActionButtons
            prize={prize}
            exclude={[
              PostActionButtonType.Duplicate,
              PostActionButtonType.Approve,
            ]}
            onPopoverClose={(): void => {
              popoverProps.onClose()
            }}
          />
        )}
      </Box>
    </Popover>
  )
}
