import React from 'react'
import { IPortalUser } from 'types/modules/users/portal/models/entities/portal-user'

export interface IAuthContext {
  isVerifyingLogin: boolean
  error: { message: string }
  currentUser: IPortalUser
  isAuthenticated: boolean
  configureAuth: () => void
  verifyLogin: () => void
  initiateLogin: (username: string, password: string) => void
  logout: () => void
}

export const AuthContext = React.createContext<IAuthContext>({} as IAuthContext)
