/* eslint-disable no-invalid-this */
import * as yup from 'yup'
import { LiveScheduleField } from 'types/modules/live-schedules/enums/live-schedule-field'
import {
  AHEAD_OF_END_DATE_MESSAGE,
  BEFORE_START_DATE_MESSAGE,
  testDateRange,
} from 'utils/validation-helper'

export const LiveScheduleSchema = yup.object({
  [LiveScheduleField.NAME]: yup.string().required('name is required'),
  [LiveScheduleField.START]: yup
    .string()
    .required('start date is required')
    .test(
      'startDateBeforeEndDate',
      AHEAD_OF_END_DATE_MESSAGE,
      function (value) {
        return testDateRange(value, this.parent[LiveScheduleField.END])
      },
    ),
  [LiveScheduleField.END]: yup
    .string()
    .required('end date is required')
    .test({
      message: BEFORE_START_DATE_MESSAGE,
      test: function (value) {
        return testDateRange(
          this.parent[LiveScheduleField.START],
          this.parent[LiveScheduleField.END],
        )
      },
    }),
})
