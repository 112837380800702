import createStyles from '@mui/styles/createStyles';

import makeStyles from '@mui/styles/makeStyles';
import TagIcon from '../public/images/icons/tag.svg';
import TagsIcon from '../public/images/icons/tags.svg';
import PostIcon from '../public/images/icons/posts.svg';
import LoyaltyIcon from '../public/images/icons/loyalty.svg';
import BallotCheckIcon from '../public/images/icons/ballot-check.svg';
import ShippingFastIcon from '../public/images/icons/shipping-fast.svg';
import TvIcon from '../public/images/icons/tv.svg';
import NewspaperIcon from '../public/images/icons/newspaper.svg';
import TicketAltIcon from '../public/images/icons/ticket-alt.svg';
import LayerPlusIcon from '../public/images/icons/layer-plus.svg';
import PrizeIcon from '../public/images/icons/prize.svg';
import StarIcon from '../public/images/icons/star.png';
import CoinIcon from '../public/images/icons/coin.png';
import SweepsIcons from '../public/images/icons/sweeps.svg';

interface IIconScss {
  ballotCheckIcon: string
  chestIcon: string
  coinIcon: string
  layerPlusIcon: string
  newspaperIcon: string
  postsIcon: string
  prizeIcon: string
  shippingIcon: string
  starIcon: string
  svgIcon: string
  tagIcon: string
  tagsIcon: string
  ticketIcon: string
  tvIcon: string
  sweepsIcon: string
}

const commonIconStyle = {
  display: 'flex',
  width: 22,
  height: 22,
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  opacity: 0.64,
}

const useStyles = makeStyles(() => createStyles({
  ballotCheckIcon: {
    ...commonIconStyle,
    backgroundImage: `url(${BallotCheckIcon})`
  },
  chestIcon: {
    ...commonIconStyle,
    backgroundImage: `url(${LoyaltyIcon})`
  },
  coinIcon: {
    backgroundImage: `url(${CoinIcon})`,
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  starIcon: {
    backgroundImage: `url(${StarIcon})`,
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  tagIcon: {
    ...commonIconStyle,
      backgroundImage: `url(${TagIcon})`
    },
   tagsIcon: {
    ...commonIconStyle,
      backgroundImage: `url(${TagsIcon})`
    },
   ticketIcon: {
    ...commonIconStyle,
      backgroundImage: `url(${TicketAltIcon})`
    },
   tvIcon: {
    ...commonIconStyle,
      backgroundImage:`url(${TvIcon})`
    },
   shippingIcon: {
    ...commonIconStyle,
      backgroundImage: `url(${ShippingFastIcon})`
    },
   newspaperIcon: {
    ...commonIconStyle,
      backgroundImage: `url(${NewspaperIcon})`
    },
   postsIcon: {
    ...commonIconStyle,
      backgroundImage: `url(${PostIcon})`
    },
   prizeIcon: {
    ...commonIconStyle,
      backgroundImage: `url(${PrizeIcon})`
    },
    layerPlusIcon: {
      ...commonIconStyle,
      backgroundImage: `url(${LayerPlusIcon})`
    },
   sweepsIcon: {
    ...commonIconStyle,
      backgroundImage: `url(${SweepsIcons})`
    },
}));


export const useIconStyles = (): IIconScss | any => {
  return useStyles() as IIconScss
};
