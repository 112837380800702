import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroll-component'
import { CircularProgress } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import { useTypedSelector } from 'core/redux/utils'
import { SortDirection } from 'types/common/enums/sort-direction'
import { SortValue } from 'types/common/sorting/models/entities/sort-value'
import {
  PanelContainer,
  Panel,
  PanelHeader,
  PanelContent,
} from 'components/panel'
import { MobileAppUserCard } from 'modules/mobile-app-users/views/dashboard-components/mobile-app-users-card'
import { makeStyles } from '@mui/styles'
import { useMobileAppUsersContext } from 'modules/mobile-app-users/context'
import { MobileAppUserFieldName } from 'modules/mobile-app-users/enums/mobile-app-user-field'
import { FilterForm } from 'modules/mobile-app-users/views/dashboard-components/filter-form'
import { IMobileAppUsersFilter } from 'modules/mobile-app-users/models/entities/mobile-app-users-filter'
import { FilterType } from 'types/common/filtering/enums/FilterType'
import { FilterOperator } from 'types/common/filtering/enums/FilterOperator'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { MobileUserModal } from 'modules/mobile-app-users/views/mobile-user-modal'
import { Actions, Selectors } from '../..'

const useStyles = makeStyles(
  createStyles({
    infiniteScrollLoader: {
      width: 40,
      height: 40,
      display: 'flex',
      overflow: 'hidden',
      margin: 'auto',
      marginTop: 20,
      marginBottom: 20,
    },
    promoteMobileAppUserButton: {
      marginRight: 10,
    },
    gridWrapper: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr',
      gap: 24,
      overflow: 'hidden !important',
    },
  }),
)

const MobileAppUsersListView: React.FC<{}> = () => {
  const defaultSortValue: SortValue = {
    sortBy: MobileAppUserFieldName.PREFERRED_USERNAME,
    sortDirection: SortDirection.Ascending,
  }

  const classes = useStyles()

  const dispatch = useDispatch()

  const mobileAppUsers = useTypedSelector((state) =>
    Selectors.getMobileAppUsers(state),
  )

  const count = useTypedSelector((state) =>
    Selectors.getMobileAppUsersCount(state),
  )

  const {
    setCurrentMobileAppUser,
    currentMobileAppUser,
    clearCurrentMobileAppUser,
  } = useMobileAppUsersContext()

  const [sortValue] = useState(defaultSortValue)

  const [listFilter, setListFilter] = useState<IMobileAppUsersFilter>({})

  const [pageNumber, setPageNumber] = useState(1)

  const [fetching, setFetching] = useState(false)

  const pageSize = 20

  useEffect(() => {
    if (Object.values(listFilter).every((val) => !val)) {
      dispatch(Actions.setMobileAppUsersAsEmpty())
      return
    }
    const getParams: Actions.IMobileAppUserActionParams = {
      sortBy: sortValue.sortBy,
      sortDirection: sortValue.sortDirection,
      page: pageNumber,
      pageSize: pageSize,
      filters: [
        {
          value: listFilter.preferredUsername,
          field: MobileAppUserFieldName.PREFERRED_USERNAME,
        },
        {
          value: listFilter.givenName,
          field: MobileAppUserFieldName.GIVEN_NAME,
        },
        {
          value: listFilter.familyName,
          field: MobileAppUserFieldName.FAMILY_NAME,
        },
        {
          value: listFilter.phoneNumber,
          field: MobileAppUserFieldName.PHONE_NUMBER,
        },
        { value: listFilter.email, field: MobileAppUserFieldName.EMAIL },
      ]
        .filter(({ value }) => !!value)
        .map(({ value, field }) => ({
          field,
          type: FilterType.Search,
          operator: FilterOperator.ContainsCaseInsensitive,
          value: value || '',
        })),
    }

    const fetchUsers = async (): Promise<void> => {
      setFetching(true)
      dispatch(
        await Actions.getMobileAppUsers(getParams, () => {
          setFetching(false)
        }),
      )
    }
    fetchUsers()
  }, [
    dispatch,
    pageNumber,
    sortValue,
    listFilter.email,
    listFilter.familyName,
    listFilter.phoneNumber,
    listFilter.preferredUsername,
    listFilter.givenName,
  ])

  const loadMore = (): void => {
    setPageNumber(pageNumber + 1)
  }

  const setFilter = (filter: IMobileAppUsersFilter): void => {
    setPageNumber(1)
    setListFilter(filter)
  }

  const handleClose = (): void => {
    clearCurrentMobileAppUser()
  }

  const renderCards = (): JSX.Element[] => {
    return (
      mobileAppUsers?.map((mobileAppUser) => {
        return (
          <MobileAppUserCard
            key={`mobile-app-users-${mobileAppUser.id}`}
            mobileAppUser={mobileAppUser}
            onClick={(): void => {
              setCurrentMobileAppUser(mobileAppUser.id!)
            }}
          />
        )
      }) ?? null
    )
  }

  return (
    <PanelContainer>
      <Panel xs={12}>
        <PanelHeader
          title="Mobile App Users"
          toolbar={<FilterForm setFilter={setFilter} fetching={fetching} />}
        />
        <PanelContent id="MobileAppUserContent">
          <InfiniteScroll
            dataLength={mobileAppUsers?.length!}
            next={loadMore}
            hasMore={mobileAppUsers?.length! < count}
            loader={
              <CircularProgress className={classes.infiniteScrollLoader} />
            }
            scrollThreshold={'300px'}
            scrollableTarget="MobileAppUserContent"
            className={classes.gridWrapper}
          >
            {renderCards()}
          </InfiniteScroll>
        </PanelContent>
      </Panel>
      {!IsNullOrUndefined(currentMobileAppUser) && (
        <MobileUserModal open handleClose={handleClose} />
      )}
    </PanelContainer>
  )
}

export default MobileAppUsersListView
