import React from 'react'
import { RequestContextProvider } from 'core/api/context'
import { NewsCardsProvider } from 'modules/news-cards/context/news-card-context'
import { NewsCardList } from 'modules/news-cards/view/news-cards-list'

export const NewsCardDashboard: React.FC<{}> = () => {
  return (
    <RequestContextProvider>
      <NewsCardsProvider>
        <NewsCardList />
      </NewsCardsProvider>
    </RequestContextProvider>
  )
}
