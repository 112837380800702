import { useRequestContext } from 'core/api/context'
import { useTypedSelector } from 'core/redux/utils'
import { useRouter } from 'core/routing/hooks/use-router'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { Actions, Selectors } from 'modules/live-schedules'
import { ILiveScheduleActionParams } from 'modules/live-schedules/actions'
import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
} from 'react'
import { useDispatch } from 'react-redux'
import { IAsyncActionParams } from 'types/common/api/models/entities/async-action-params'
import SortDirection from 'types/common/enums/sort-direction'
import { FilterOperator } from 'types/common/filtering/enums/FilterOperator'
import { FilterType } from 'types/common/filtering/enums/FilterType'
import { IEvent } from 'types/modules/events/models/entities/event'
import { LiveScheduleField } from 'types/modules/live-schedules/enums/live-schedule-field'
import { ILiveSchedule } from 'types/modules/live-schedules/models/entities/live-schedule'
import { ILiveScheduleDraft } from 'types/modules/live-schedules/models/entities/live-schedule-draft'
import { ILiveSchedulesState } from 'types/modules/live-schedules/models/state/live-schedules-state'
import { NIL as NIL_UUID } from 'uuid'
import moment from 'moment-timezone'

export interface ILiveScheduleContext {
  liveSchedules: ILiveSchedulesState
  currentLiveSchedule: ILiveSchedule | null
  initialiseDashboard: (dasboardView: string) => void
  setCurrentLiveSchedule: (liveScheduleId: string) => void
  clearCurrentLiveSchedule: () => void
  loadLiveSchedules: (params: IAsyncActionParams<ILiveSchedule>) => void
  createLiveScheduleDraft: (liveSchedule?: ILiveScheduleDraft) => void
  createLiveSchedule: (liveSchedule: ILiveScheduleDraft) => void
  updateLiveSchedule: (liveSchedule: ILiveScheduleDraft) => void
}

export const LiveScheduleContext = createContext<ILiveScheduleContext>(
  {} as ILiveScheduleContext,
)

interface ILiveScheduleContextProps {
  event: IEvent
}

export const LiveScheduleContextProvider: React.FC<
  ILiveScheduleContextProps
> = (props: PropsWithChildren<ILiveScheduleContextProps>) => {
  const { event } = props

  const rc = useRequestContext()

  const { routeParams, pushQueryParams, removeQueryParams } = useRouter()

  const { scheduleId } = routeParams

  const liveSchedules = useTypedSelector((state) =>
    Selectors.getLiveSchedules(state),
  )

  const currentLiveSchedule = useTypedSelector((state) =>
    Selectors.getCurrentLiveSchedule(state),
  )

  const setCurrentLiveSchedule = (scheduleId: string): void => {
    pushQueryParams({
      scheduleId,
    })
  }

  const clearCurrentLiveSchedule = (): void => {
    removeQueryParams()
  }

  // Actions
  const dispatch = useDispatch()

  useEffect(() => {
    if (scheduleId?.length > 0 && scheduleId === NIL_UUID) return

    dispatch(Actions.setCurrentLiveScheduleId({ liveScheduleId: scheduleId }))

    if (!IsNullOrUndefined(scheduleId)) {
      dispatch(Actions.getLiveScheduleById({ liveScheduleId: scheduleId }))
    }
  }, [scheduleId])

  const initialiseDashboard = (dashboardView: string): void => {
    rc?.applyFilter({
      field: LiveScheduleField.EVENT_ID,
      type: FilterType.Select,
      operator: FilterOperator.Equals,
      value: [event?.id!],
    })

    rc?.setSortValue({
      sortBy: LiveScheduleField.START,
      sortDirection:
        dashboardView === 'list'
          ? SortDirection.Descending
          : SortDirection.Ascending,
    })
  }

  const loadLiveSchedules = useCallback((): void => {
    dispatch(
      Actions.getLiveSchedules({
        filters: rc?.filters,
        page: rc?.page,
        pageSize: rc?.pageSize,
        ...rc?.sortValue,
      } as ILiveScheduleActionParams),
    )
  }, [rc?.active, rc?.filters, rc?.page, rc?.pageSize, rc?.sortValue])

  useEffect(() => {
    dispatch(Actions.clearLiveSchedules())
  }, [rc?.filters, rc?.sortValue])

  useEffect(() => {
    if (!rc?.active) return
    loadLiveSchedules()
  }, [rc?.active, rc?.filters, rc?.page, rc?.pageSize, rc?.sortValue])

  const createLiveScheduleDraft = (liveSchedule?: ILiveScheduleDraft): void => {
    const newLiveSchedule = {
      id: NIL_UUID,
      [LiveScheduleField.NAME]: liveSchedule?.[LiveScheduleField.NAME] ?? '',
      [LiveScheduleField.DESCRIPTION]:
        liveSchedule?.[LiveScheduleField.DESCRIPTION] ?? '',
      [LiveScheduleField.START]:
        liveSchedule?.[LiveScheduleField.START] ?? moment().toISOString(),
      [LiveScheduleField.END]:
        liveSchedule?.[LiveScheduleField.END] ??
        moment().add(1, 'hour').toISOString(),
      [LiveScheduleField.LIVE]: liveSchedule?.[LiveScheduleField.LIVE] ?? true,
      [LiveScheduleField.EVENT_ID]:
        liveSchedule?.[LiveScheduleField.EVENT_ID] ?? event.id,
      [LiveScheduleField.XP_MULTIPLIER]:
        liveSchedule?.[LiveScheduleField.XP_MULTIPLIER] ?? 3,
      [LiveScheduleField.CREATED_AT]:
        liveSchedule?.[LiveScheduleField.CREATED_AT] ?? '',
      [LiveScheduleField.UPDATED_AT]:
        liveSchedule?.[LiveScheduleField.UPDATED_AT] ?? '',
    }

    dispatch(
      Actions.createLiveScheduleDraft({
        item: newLiveSchedule,
      }),
    )

    setCurrentLiveSchedule(NIL_UUID)
  }

  const createLiveSchedule = (liveSchedule: ILiveScheduleDraft): void => {
    new Promise<ILiveSchedule>((onResolve, onReject) => {
      dispatch(
        Actions.createLiveSchedule({
          item: liveSchedule,
          promise: {
            onResolve,
            onReject,
          },
        }),
      )
    }).then((liveSchedule: ILiveSchedule) => {
      setCurrentLiveSchedule(liveSchedule.id)
    })
  }

  const updateLiveSchedule = (liveSchedule: ILiveScheduleDraft): void => {
    dispatch(
      Actions.updateLiveSchedule({
        liveScheduleId: liveSchedule.id,
        item: liveSchedule,
      }),
    )
  }

  const context: ILiveScheduleContext = {
    liveSchedules,
    currentLiveSchedule,
    initialiseDashboard,
    setCurrentLiveSchedule,
    clearCurrentLiveSchedule,
    loadLiveSchedules,
    createLiveScheduleDraft,
    createLiveSchedule,
    updateLiveSchedule,
  }

  return (
    <LiveScheduleContext.Provider value={context}>
      {props.children}
    </LiveScheduleContext.Provider>
  )
}

export const useLiveScheduleContext = (): ILiveScheduleContext =>
  useContext(LiveScheduleContext)
