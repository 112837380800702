import { MODULE_NAME } from 'modules/news-cards/constants'

const ActionTypes = {
  SET_CURRENT_NEWS_CARD_ID: `${MODULE_NAME}/SET_CURRENT_NEWS_CARD_ID`,
  GET_NEWS_CARDS: `${MODULE_NAME}/GET_NEWS_CARDS`,
  GET_NEWS_CARD_BY_ID: `${MODULE_NAME}/GET_NEWS_CARD_BY_ID`,
  CREATE_NEWS_CARD: `${MODULE_NAME}/CREATE_NEWS_CARD`,
  CREATE_NEWS_CARD_DRAFT: `${MODULE_NAME}/CREATE_NEWS_CARD_DRAFT`,
  UPDATE_NEWS_CARD: `${MODULE_NAME}/UPDATE_NEWS_CARD`,
  DELETE_NEWS_CARD: `${MODULE_NAME}/DELETE_NEWS_CARD`,
  UPLOAD_NEWS_CARD_IMAGE: `${MODULE_NAME}/UPLOAD_NEWS_CARD_IMAGE`,
}

export default ActionTypes
