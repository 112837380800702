import { Box, Button, ButtonProps, Grid, Typography } from '@mui/material';
import { usePostGroupsContext } from 'modules/post-groups/context'
import React, { useEffect } from 'react'
import _ from 'core/utils/deepdash'
import { PostGroupDetailsModal } from 'modules/post-groups/view/components/post-group-details-modal';
import { SimpleImageDisplay } from 'components/simple-image-display';
import { PostGroupPosts } from 'modules/post-groups/view/components/post-group-dashboard/post-group-posts';
import { renderDate, renderDates } from 'utils/date-range';
import { createStyles, makeStyles } from '@mui/styles';
import { ConfirmationDialog } from 'components/confirmation-dialog';
import moment from 'moment-timezone';

const useStyles = makeStyles(() =>
  createStyles({
    headerContainer: {
      borderBottom: 'solid #efefef',
      marginBottom: 16
    },
  }),
)

const buttonProps: ButtonProps = {
  variant: 'contained',
  size: 'small',
  color: 'secondary'
}

const popoverButtonProps = {
  ...buttonProps,
}

export const PostGroupDashboard: React.FC<{}> = () => {
  const classes = useStyles()
  const {
    currentPostGroup,
    initialiseDashboard,
    displayModal,
    shouldShowModal,
    settlePostGroup
  } = usePostGroupsContext()

  useEffect(() => {
    initialiseDashboard()
  }, [])

  return (
    <React.Fragment>
      <Box marginRight={2} marginLeft={2} padding={2} className={classes.headerContainer}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="space-between">
              <Box>
                {!!currentPostGroup?.imageUrl && <SimpleImageDisplay imageUrl={currentPostGroup?.imageUrl} width={120} height={120} />}
                <Box>
                  <Box>
                    <Typography variant="h5" color="textPrimary" component="span">
                      {currentPostGroup?.name}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="body2" color="textPrimary" component="span">
                      Status: {currentPostGroup?.status}
                    </Typography>
                  </Box>
                  {!!currentPostGroup?.settleAt && <Box>
                    <Typography variant="body2" color="textPrimary" component="span">
                      Date to settle: {moment(currentPostGroup?.settleAt).format('D MMMM YYYY HH:mm')}
                    </Typography>
                  </Box>}
                  {!!currentPostGroup?.startAt && <Box>
                    <Typography variant="body2" color="textPrimary" component="span">
                      Start: {moment(currentPostGroup?.startAt).format('D MMMM YYYY HH:mm')}
                    </Typography>
                  </Box>}
                  {!!currentPostGroup?.endAt && <Box>
                    <Typography variant="body2" color="textPrimary" component="span">
                      End: {moment(currentPostGroup?.endAt).format('D MMMM YYYY HH:mm')}
                    </Typography>
                  </Box>}
                  {!!currentPostGroup?.settledAt && <Box>
                    <Typography variant="body2" color="textPrimary" component="span">
                      Settled on: {moment(currentPostGroup?.settledAt).format('D MMMM YYYY HH:mm')}
                    </Typography>
                  </Box>}
                </Box>
              </Box>
              <Box flexDirection="column" justifyContent="flex-end">
                <Button
                  variant="contained"
                  size="small"
                  onClick={displayModal}
                  >
                  Edit Group
                </Button>
                <Box marginTop={1}>
                  <ConfirmationDialog
                    popoverId={`settle-post-group-${currentPostGroup?.id}`}
                    buttonProps={popoverButtonProps}
                    onConfirmation={(): void => settlePostGroup(currentPostGroup!.id!)}
                    confirmationText="Are you sure you want to settle this group?"
                    buttonLabel="Settle"
                  />
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box marginRight={2} marginLeft={2} overflow="auto">
        <PostGroupPosts postGroup={currentPostGroup || undefined} />
      </Box>
      {shouldShowModal && (
        <PostGroupDetailsModal />
      )}
    </React.Fragment>
  )
}
