import { Button, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx'
import { EditIcon, ViewIcon } from 'components/icons'
import { useInfoCardContext } from 'modules/info-cards/context'
import React from 'react'
import { IInfoCardActionButtonProps } from 'types/modules/info-cards/models/props/info-card-action-button-props'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.text.primary,
      backgroundColor: '#ecf0f1',
      '&:hover': {
        backgroundColor: '#f2f5f5',
      },
    },
  }),
)

export const EditInfoCardButton: React.FC<IInfoCardActionButtonProps> = (
  props: IInfoCardActionButtonProps,
) => {
  const classes = useStyles()

  const { setCurrentInfoCard } = useInfoCardContext()

  return (
    <Button
      variant="contained"
      startIcon={<EditIcon />}
      className={clsx(classes.root)}
      size="small"
      onClick={(): void => setCurrentInfoCard(props.infoCard.id)}
    >
      Edit
    </Button>
  )
}
