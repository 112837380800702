export enum EventFieldName {
  ID = 'id',
  NAME = 'name',
  CURRENCY = 'currency',
  AMOUNT = 'amount',
  START = 'start',
  END = 'end',
  STATUS = 'status',
  MEDIA = 'media',
  MEDIA_ITEM_ID = 'mediaItemId',
  DESCRIPTION = 'description',
  ICON = 'icon',
  IMAGE_URL = 'imageUrl',
  PASS_IMAGE_URL = 'passImageUrl',
  PASS_MEDIA_ITEM_ID = 'passMediaItemId',
  DEFAULT_LIVE_SCHEDULE_IMAGE_URL = 'defaultLiveScheduleImageUrl',
  DEFAULT_LIVE_SCHEDULE_MEDIA_ITEM_ID = 'defaultLiveScheduleMediaItemId',
  AUTO_PARLAY = 'autoParlay',
  UPDATED_AT = 'updatedAt',
  LIVE_SCHEDULES = 'liveSchedules',
  LOCALE = 'locale',
  LEADERBOARD_ID = 'leaderboardId',
  REWARDS = 'rewards',
  HAS_ACTIVE_TICKETS = 'hasActiveTickets',
  HAS_LEADERBOARDS = 'hasLeaderboards',
  HAS_PAST_POSTS = 'hasPastPosts',
  POST_STATUS = 'postStatus',
  USERS = 'users',
  TYPE = 'type',
  COUNT = 'count',
  TAG_GROUP_ID = 'tagGroupId',
  TAG_GROUP = 'tagGroup',
  STOP_SCHEDULED_CLOSE = 'stopScheduledClose',
  HAS_TIMEZONE = 'hasTimezone',
  PROMOTED = 'promoted'
}
