import { AppState } from 'types/redux/types/app-state'
import { MODULE_NAME } from 'modules/mobile-app-users/constants'
import { IMobileAppUserState } from 'modules/mobile-app-users/models/state/IMobileAppUserState'
import { MobileAppUser } from 'modules/mobile-app-users/models/entities/mobile-app-user'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { Ticket } from 'modules/mobile-app-users/models/entities/ticket'

const getState = (state: AppState): IMobileAppUserState => state[MODULE_NAME]

export const getMobileAppUsers = (state: AppState): MobileAppUser[] =>
  getState(state).items

export const getMobileAppUsersCount = (state: AppState): number =>
  getState(state).count

export const getCache = (state: AppState): { [key: string]: MobileAppUser } =>
  getState(state).cache

export const getCachedMobileUser = (state: AppState): MobileAppUser[] =>
  Object.values(getState(state).cache)

export const getAutocompleteSearchMobileAppUsers = (
  state: AppState,
): MobileAppUser[] => {
  return Object.values(getState(state).cache)
}

export const getMobileAppUser = (
  state: AppState,
  mobileAppUserId: string,
): MobileAppUser => getCache(state)[mobileAppUserId]

export const getCurrentMobileAppUser = (
  state: AppState,
): MobileAppUser | null => {
  const mobileAppUsers = getState(state)
  if (IsNullOrUndefined(mobileAppUsers.currentMobileAppUserId)) return null
  return getCache(state)[mobileAppUsers.currentMobileAppUserId || ''] || null
}

export const getTickets = (state: AppState): Ticket[] =>
  getState(state).tickets.items

export const getTicketsCount = (state: AppState): number =>
  getState(state).tickets.count

export const getTicket = (
  state: AppState,
  ticketId?: string,
): Ticket | null => {
  if (!ticketId) return null
  return getState(state).tickets.cache[ticketId]
}
