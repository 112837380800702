import { actionTypeSuccess, cacheItem, cacheItems } from 'core/redux/utils'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { ActionTypes } from 'modules/info-cards'
import { IInfoCardActionParams } from 'modules/info-cards/actions'
import { Reducer } from 'redux'
import { InfoCardStatus } from 'types/modules/info-cards/enums/info-card-status'
import { IInfoCardState } from 'types/modules/info-cards/models/state/info-card-state'
import { ISimpleAction, ISuccessAction } from 'types/redux/interfaces/IAction'
import _ from 'core/utils/deepdash'
import IPaginatedItems from 'types/common/pagination/models/entities/paginated-items'
import { IInfoCard } from 'types/modules/info-cards/models/entities/info-card'
import { mergeArrays } from 'core/utils/mergeArrays'
import { NIL as NIL_UUID } from 'uuid'

const initialState = (): IInfoCardState => {
  const state: IInfoCardState = {
    items: [],
    statuses: {},
    cache: {},
  }

  Object.values(InfoCardStatus).forEach((value) => {
    state.statuses[value] = 0
  })

  return state
}

type AllowedActionTypes = ISuccessAction | ISimpleAction

const InfoCardsReducer: Reducer<IInfoCardState, AllowedActionTypes> = (
  state = initialState(),
  action: AllowedActionTypes,
) => {
  const simpleAction = action as ISimpleAction
  const successAction = action as ISuccessAction

  const currentInfoCard = !IsNullOrUndefined(state.currentInfoCardId)
    ? state.items.find((infoCard) => infoCard.id === state.currentInfoCardId)
    : null

  switch (action.type) {
    case ActionTypes.SET_CURRENT_INFO_CARD_ID: {
      const newState = _.cloneDeep(state)

      const { infoCardId } = simpleAction.payload as IInfoCardActionParams

      if (IsNullOrUndefined(infoCardId)) {
        if (Object.hasOwnProperty.call(newState, 'currentInfoCardId')) {
          const page = (newState as any).page ?? 0
          const pageSize = (newState as any).pageSize ?? 0
          if (page === 1 && newState.items.length > pageSize) {
            const currentPageIndex = newState.items.findIndex(
              (infoCard) => infoCard.id === currentInfoCard?.id,
            )

            _.pullAt(newState.items, currentPageIndex)
          }
          delete newState.currentInfoCardId
        }
      } else {
        newState.currentInfoCardId = infoCardId
      }

      return newState
    }

    case ActionTypes.CLEAR_INFO_CARDS: {
      const newState = _.cloneDeep(state)
      if (IsNullOrUndefined(newState.currentInfoCardId)) {
        newState.items = []
      } else {
        newState.items = newState.items.filter(
          (infoCard) => infoCard.id !== newState.currentInfoCardId,
        )
      }

      return newState
    }

    case ActionTypes.CREATE_INFO_CARD_DRAFT: {
      const newState = _.cloneDeep(state)

      const { item } = simpleAction.payload as IInfoCardActionParams

      newState.items = [item as IInfoCard, ...state.items]

      newState.currentInfoCardId = NIL_UUID

      return newState
    }

    case actionTypeSuccess(ActionTypes.GET_INFO_CARD_STATUSES): {
      const newState = _.cloneDeep(state)
      const statuses = successAction.data as {
        status: string
        count: number
      }[]

      _.forEach(state.statuses, (value, status) => {
        const responseStatus = statuses.find((res) => res.status === status)

        newState.statuses[status] = responseStatus?.count ?? 0
      })

      return newState
    }

    case actionTypeSuccess(ActionTypes.GET_INFO_CARDS): {
      let newState = _.cloneDeep(state)
      const response = successAction.data as IPaginatedItems<IInfoCard>
      const page = successAction.payload!.page

      const pageIsFirstOrNull = IsNullOrUndefined(page) || page === 1

      const currentInfoCardIsNullOrResponseContainsCurrentInfoCard =
        IsNullOrUndefined(currentInfoCard) ||
        response.items.some((infoCard) => infoCard.id === currentInfoCard!.id)

      if (
        pageIsFirstOrNull &&
        currentInfoCardIsNullOrResponseContainsCurrentInfoCard
      ) {
        newState = {
          ...newState,
          ...response,
          pageSize: response.pageSize ?? response.count,
          cache: cacheItems(state.cache, response.items),
        }
      } else {
        newState = {
          ...newState,
          ...response,
          items: mergeArrays(state.items, response.items),
          cache: cacheItems(state.cache, response.items),
        }
      }
      return newState
    }

    case actionTypeSuccess(ActionTypes.GET_INFO_CARD_BY_ID): {
      const newState = _.cloneDeep(state)
      const response = successAction.data as IInfoCard
      const infoCardIndex = state.items.findIndex((x) => x.id === response.id)

      if (infoCardIndex < 0) {
        newState.items = [...newState.items, response]
      } else {
        newState.items[infoCardIndex] = response
      }

      newState.cache = cacheItem(state.cache, response)

      return newState
    }

    case actionTypeSuccess(ActionTypes.UPDATE_INFO_CARD):
    case actionTypeSuccess(ActionTypes.UPDATE_INFO_CARD_STATUS): {
      const newState = _.cloneDeep(state)
      const response = successAction.data as IInfoCard

      const infoCardIndex = state.items.findIndex(
        (infoCard) => infoCard.id === response.id,
      )

      if (infoCardIndex >= 0) {
        newState.items[infoCardIndex] = response
      }

      newState.cache = cacheItem(state.cache, response)

      return newState
    }

    case actionTypeSuccess(ActionTypes.UPLOAD_INFO_CARD_IMAGE): {
      const newState = _.cloneDeep(state)
      const response = successAction.data as IInfoCard

      const infoCardIndex = newState.items.findIndex(
        (infoCard) => infoCard.id === response.id,
      )

      if (infoCardIndex >= 0) {
        newState.items[infoCardIndex] = response

        newState.cache = cacheItem(state.cache, response)

        return newState
      }

      return state
    }

    default:
      return state
  }
}

export default InfoCardsReducer
