import { PostDifficulty } from 'types/modules/posts/enums/post-difficulty'
import PostType from 'types/modules/posts/enums/post-type'

export const MODULE_NAME = 'posts'
export const MAX_TITLE_LENGTH = 90
export const MAX_RESULT_DESCRIPTION = 400

export const DRAFTED_BET = {
  type: PostType.Bet,
  minBet: 0,
  difficulty: PostDifficulty.Easy,
  starsWin: 1,
  multiplierMid: 2,
  multiplierMax: 3,
}

export const DRAFTED_POLL = {
  type: PostType.Poll,
  difficulty: PostDifficulty.Easy,
  minBet: 0,
  starsWin: 0,
  coinsWin: 5,
}

export const DRAFTED_TEST = {
  type: PostType.Test,
  difficulty: PostDifficulty.Easy,
  minBet: 0,
  starsWin: 0,
  coinsWin: 25,
}

export const DRAFTED_RETRO = {
  type: PostType.Game,
  difficulty: PostDifficulty.Easy,
  minBet: 5,
  coinsWin: 0,
  starsWin: 0,
}

export const initialPostDraftMap = {
  [PostType.Bet]: DRAFTED_BET,
  [PostType.Game]: DRAFTED_RETRO,
  [PostType.Poll]: DRAFTED_POLL,
  [PostType.Test]: DRAFTED_TEST,
}

export const screenLabel = {
  [PostType.Bet]: 'Bet',
  [PostType.Game]: 'Retro',
  [PostType.Poll]: 'Poll',
  [PostType.Test]: 'Personality',
}
