import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
} from 'react'
import { useRequestContext } from 'core/api/context'
import { useTypedSelector } from 'core/redux/utils'
import { useRouter } from 'core/routing/hooks/use-router'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { Actions, Selectors } from 'modules/prizes'
import { Actions as ChestActions } from 'modules/chests'
import { IPrizeActionParams } from 'modules/prizes/actions'
import { getStatusFilterOptions } from 'modules/prizes/utils/get-status-filter-options'
import { useDispatch } from 'react-redux'
import { IAsyncActionParams } from 'types/common/api/models/entities/async-action-params'
import SortDirection from 'types/common/enums/sort-direction'
import { FilterOperator } from 'types/common/filtering/enums/FilterOperator'
import { FilterType } from 'types/common/filtering/enums/FilterType'
import { ISelectFilterConfig } from 'types/common/filtering/models/entities/ISelectFilterConfig'
import PrizeStatus from 'types/modules/prizes/enums/prize-status'
import { PrizeField } from 'types/modules/prizes/enums/prize-field'
import { IPrize } from 'types/modules/prizes/models/entities/prize'
import { IPrizeState } from 'types/modules/prizes/models/state/prize-state'
import _ from 'core/utils/deepdash'
import { usePageSizeMonitor } from 'modules/common/usePageSizeMonitor'
import { NIL as NIL_UUID } from 'uuid'
import { PrizeType } from 'fe-shared-resources'

interface IPrizeListContext {
  prizes: IPrizeState
  statusFilterConfig: ISelectFilterConfig
  currentPrize: Partial<IPrize> | null
  initialiseDashboard: (dashboardView: string) => void
  setCurrentPrize: (prizeId: string, prizeType: PrizeType) => void
  createPrizeDraft: (prizeType: PrizeType) => void
  clearCurrentPrize: () => void
  loadPrizes: (params: IAsyncActionParams<IPrize>) => void
  hasTrash: boolean
  clearTrash: () => void
}

export const PrizeListContext = createContext<IPrizeListContext>(
  {} as IPrizeListContext,
)

export const PrizeListContextProvider: React.FC<PropsWithChildren<{}>> = (
  props: PropsWithChildren<{}>,
) => {
  const rc = useRequestContext()

  const dispatch = useDispatch()

  const pageSize = usePageSizeMonitor({ fetchItems: Actions.getPrizes })

  const statusFilter = rc?.getFilterByField(PrizeField.STATUS)

  const { routeParams, pushQueryParams, removeQueryParams } = useRouter()

  const { prizeId, prizeType } = routeParams

  const prizes = useTypedSelector((state) =>
    Selectors.getPrizesByStatus(state, statusFilter?.value as PrizeStatus[]),
  )

  const currentPrize = useTypedSelector((state) =>
    Selectors.getCurrentPrize(state, prizeType as PrizeType),
  )

  const hasTrash = prizes.statuses[PrizeStatus.Trashed] > 0

  const setCurrentPrize = (id: string, type: PrizeType): void => {
    pushQueryParams({
      prizeId: id,
      prizeType: type,
    })
  }

  const createPrizeDraft = (type: PrizeType): void => {
    setCurrentPrize(NIL_UUID, type)
  }

  const clearCurrentPrize = (): void => {
    removeQueryParams()
  }

  const statusFilterConfig = {
    field: PrizeField.STATUS,
    options: getStatusFilterOptions(prizes.statuses),
  }

  const initialiseDashboard = (dashboardView: string): void => {
    dispatch(Actions.getPrizeStatuses())

    rc?.applyFilter({
      field: statusFilterConfig.field,
      type: FilterType.Select,
      operator: FilterOperator.Equals,
      value:
        dashboardView === 'list'
          ? [PrizeStatus.Open]
          : [PrizeStatus.Open, PrizeStatus.Scheduled, PrizeStatus.Pending],
    })

    rc?.setSortValue({
      sortBy: PrizeField.START,
      sortDirection:
        dashboardView === 'list'
          ? SortDirection.Descending
          : SortDirection.Ascending,
    })
  }

  const loadPrizes = useCallback((): void => {
    dispatch(
      Actions.getPrizes({
        filters: rc?.filters,
        page: rc?.page,
        pageSize,
        ...rc?.sortValue,
      } as IPrizeActionParams),
    )
  }, [rc?.active, rc?.filters, rc?.page, pageSize, rc?.sortValue])

  useEffect(() => {
    dispatch(Actions.setCurrentPrizeId({ prizeId }))

    if (!IsNullOrUndefined(prizeId) && prizeId !== NIL_UUID) {
      dispatch(
        Actions.getPrizeById({
          prizeId,
        }),
      )
    }
  }, [prizeId])

  useEffect(() => {
    dispatch(Actions.clearPrizes())
  }, [rc?.filters, rc?.sortValue])

  useEffect(() => {
    if (!rc?.active) return
    loadPrizes()
  }, [rc?.active, rc?.filters, rc?.page, rc?.sortValue])

  useEffect(() => {
    if (IsNullOrUndefined(prizeId) || !currentPrize || !currentPrize.chestId)
      return
    dispatch(ChestActions.getChest({ chestId: currentPrize.chestId }))
  }, [currentPrize?.chestId])

  const clearTrash = (): void => {
    dispatch(Actions.clearTrash())
    dispatch(Actions.clearPrizes())
  }

  const context: IPrizeListContext = {
    prizes,
    statusFilterConfig,
    currentPrize,
    initialiseDashboard,
    setCurrentPrize,
    createPrizeDraft,
    clearCurrentPrize,
    loadPrizes,
    hasTrash,
    clearTrash,
  }

  return (
    <PrizeListContext.Provider value={context}>
      {props.children}
    </PrizeListContext.Provider>
  )
}

export const usePrizeListContext = (): IPrizeListContext =>
  useContext(PrizeListContext)
