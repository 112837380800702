import React from 'react'
import {
  Box,
  colors,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SvgIcon,
  TextField,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { IChanceRewardFormProps } from 'modules/chests/models/entities/chest-form-props'
import {
  ChanceRewardCurrencies,
  ChanceRewardType,
  ChestFieldName,
} from 'modules/chests/enums/chest-field'
import { CrossIcon } from 'components/icons'
import {
  CHANCE_REWARD_CURRENCY_OPTIONS,
  CHANCE_REWARD_TYPE_OPTIONS,
  RARITY_OPTIONS,
} from 'modules/chests/constants'
import { AutocompleteSearchSelect } from 'components/controls/inputs/downshift/autocomplete/autocomplete-search-select'
import { IPrize } from 'types/modules/prizes/models/entities/prize'
import { searchPrizes } from 'modules/prizes/utils/get-search-filter-option'
import { Selectors as PrizeSelectors } from 'modules/prizes'
import { useTypedSelector } from 'core/redux/utils'
import PrizeStatus from 'types/modules/prizes/enums/prize-status'

const useStyles = makeStyles(() =>
  createStyles({
    chanceRewardForm: {
      width: '100%',
      padding: 8,
      position: 'relative',
      background: colors.grey[50],
      borderRadius: 8,
      marginBottom: 8,
    },
    inputLabel: {
      display: 'flex',
      marginBottom: 8,
      marginTop: 8,
    },
    iconButton: {
      position: 'absolute',
      right: -8,
      top: -8,
    },
  }),
)

export const ChanceRewardForm: React.FC<IChanceRewardFormProps> = (
  props: IChanceRewardFormProps,
) => {
  const classes = useStyles()
  const {
    chanceReward,
    removeChanceReward,
    setChanceRewardFieldValue,
    prizeRarityError,
    probabilityError,
    prizeIdRequiredError,
    namePrefix,
    index,
  } = props

  const onChange = (event: any, fieldName: string): void => {
    const value = event.target.value as string | undefined
    setChanceRewardFieldValue(value, fieldName, chanceReward.id || '')
  }

  const selectedPrize = useTypedSelector((state) =>
    chanceReward?.prizeId
      ? PrizeSelectors.getCachedPrizeById(state, chanceReward?.prizeId)
      : null,
  )

  return (
    <Box className={classes.chanceRewardForm}>
      <IconButton
        onClick={(): void => removeChanceReward(chanceReward.id || '')}
        className={classes.iconButton}
        size="large">
        <SvgIcon>
          <CrossIcon />
        </SvgIcon>
      </IconButton>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <InputLabel
            htmlFor={`${namePrefix}[${index}].${ChestFieldName.PRIZE_RARITY}`}
            className={classes.inputLabel}
          >
            Rarity
          </InputLabel>
          <Select
            id={ChestFieldName.PRIZE_RARITY}
            name={`${namePrefix}[${index}].${ChestFieldName.PRIZE_RARITY}`}
            onChange={(event): void => {
              onChange(event, ChestFieldName.PRIZE_RARITY)
            }}
            value={chanceReward[ChestFieldName.PRIZE_RARITY]}
            variant="filled"
            fullWidth
            error={!!prizeRarityError}
          >
            {RARITY_OPTIONS.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
          {prizeRarityError && (
            <FormHelperText error>{prizeRarityError}</FormHelperText>
          )}
        </Grid>
        <Grid item xs={4}>
          <InputLabel
            htmlFor={`${namePrefix}[${index}].${ChestFieldName.PROBABILITY}`}
            className={classes.inputLabel}
          >
            Probability
          </InputLabel>
          <TextField
            id={ChestFieldName.PROBABILITY}
            name={`${namePrefix}[${index}].${ChestFieldName.PROBABILITY}`}
            onChange={(event): void => {
              onChange(event, ChestFieldName.PROBABILITY)
            }}
            value={chanceReward[ChestFieldName.PROBABILITY]}
            variant="filled"
            type="number"
            fullWidth
            error={!!probabilityError}
            helperText={probabilityError}
            InputProps={{
              inputProps: {
                step: 0.1,
              },
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <InputLabel
            htmlFor={`${namePrefix}[${index}].${ChestFieldName.TYPE}`}
            className={classes.inputLabel}
          >
            Type
          </InputLabel>
          <Select
            id={ChestFieldName.TYPE}
            name={`${namePrefix}[${index}].${ChestFieldName.TYPE}`}
            onChange={(event): void => {
              onChange(event, ChestFieldName.TYPE)
              setChanceRewardFieldValue(
                event.target.value === ChanceRewardType.CURRENCY
                  ? ChanceRewardCurrencies.STAR
                  : '',
                ChestFieldName.CURRENCY,
                chanceReward.id || '',
              )
            }}
            value={chanceReward[ChestFieldName.TYPE]}
            variant="filled"
            fullWidth
          >
            {CHANCE_REWARD_TYPE_OPTIONS.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        {chanceReward[ChestFieldName.TYPE] === ChanceRewardType.CURRENCY ? (
          <>
            <Grid item xs={4}>
              <InputLabel
                htmlFor={`${namePrefix}[${index}].${ChestFieldName.CURRENCY}`}
                className={classes.inputLabel}
              >
                Currency
              </InputLabel>
              <Select
                id={ChestFieldName.CURRENCY}
                name={`${namePrefix}[${index}].${ChestFieldName.CURRENCY}`}
                onChange={(event): void => {
                  onChange(event, ChestFieldName.CURRENCY)
                }}
                value={chanceReward[ChestFieldName.CURRENCY]}
                variant="filled"
                fullWidth
              >
                {CHANCE_REWARD_CURRENCY_OPTIONS.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={4}>
              <InputLabel
                htmlFor={`${namePrefix}[${index}].${ChestFieldName.AMOUNT}`}
                className={classes.inputLabel}
              >
                Amount
              </InputLabel>
              <TextField
                id={ChestFieldName.AMOUNT}
                name={`${namePrefix}[${index}].${ChestFieldName.AMOUNT}`}
                onChange={(event): void => {
                  onChange(event, ChestFieldName.AMOUNT)
                }}
                value={chanceReward[ChestFieldName.AMOUNT]}
                variant="filled"
                fullWidth
                type="number"
              />
            </Grid>
          </>
        ) : (
          <Grid item xs={8}>
            <InputLabel
              htmlFor={`${namePrefix}[${index}].${ChestFieldName.PRIZE_ID}`}
              className={classes.inputLabel}
            >
              Prize
            </InputLabel>
            <AutocompleteSearchSelect
              variant="filled"
              initialSelectedItem={selectedPrize}
              availableItems={[]}
              onSelectedItemChange={(selectedPrize: IPrize | null): void => {
                setChanceRewardFieldValue(
                  selectedPrize?.id,
                  ChestFieldName.PRIZE_ID,
                  chanceReward.id || '',
                )
              }}
              searchAsyncActionCreator={searchPrizes}
              selectorMethod={PrizeSelectors.getCachedInstantPrizes}
              options={{
                autocompleteSearchResultContent: (item: unknown): string => {
                  const prize = item as IPrize
                  return `${prize?.item?.name} - ${prize?.item?.details?.brand}`
                },
              }}
              optionDisabled={(prize: IPrize | null): boolean => {
                // @ts-ignore
                return [PrizeStatus.Closed, PrizeStatus.OutOfStock].includes(prize?.status)
              }}
            />
            {prizeIdRequiredError && (
              <FormHelperText error>{prizeIdRequiredError}</FormHelperText>
            )}
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
