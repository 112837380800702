import { Box, Button, CircularProgress, Grid, InputLabel, TextField, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { SearchIcon } from 'components/icons'
import { IMobileAppUsersFilter } from 'modules/mobile-app-users/models/entities/mobile-app-users-filter'
import React, { useState } from 'react'

interface IFilterFormProps {
  setFilter: (value: IMobileAppUsersFilter) => void
  fetching: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filterForm: {
      width: '100%',
    },
    inputLabel: {
      display: 'flex',
      marginBottom: '0.625rem',
      marginTop: '0.625rem',
    },
    searchButton: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    buttonWrapper: {
      display: 'flex',
      marginTop: 36,
    },
    spinner: {
      marginLeft: 8,
    },
  }),
)

export const FilterForm: React.FC<IFilterFormProps> = ({
  setFilter,
  fetching,
}) => {
  const classes = useStyles()

  const [preferredUsername, setPreferredUsername] = useState('')
  const [givenName, setGivenName] = useState('')
  const [familyName, setFamilyName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [email, setEmail] = useState('')

  const [usedFilter, setUsedFilter] = useState<IMobileAppUsersFilter>({})

  const createFilter = (): IMobileAppUsersFilter => ({
    preferredUsername,
    givenName,
    familyName,
    phoneNumber,
    email,
  })
  const onClickSearch = (): void => {
    const filter = createFilter()
    setUsedFilter(filter)
    setFilter(filter)
  }

  const handleSearchKeyDown = (event: React.KeyboardEvent): void => {
    if (event.keyCode === 13) {
      onClickSearch()
    }
  }

  const isFilterSame =
    givenName === usedFilter.givenName &&
    familyName === usedFilter.familyName &&
    preferredUsername === usedFilter.preferredUsername &&
    email === usedFilter.email &&
    phoneNumber === usedFilter.phoneNumber

  return (
    <Box className={classes.filterForm}>
      <Grid container spacing={2}>
        {[
          {
            set: setPreferredUsername,
            value: preferredUsername,
            name: 'preferredUsername',
            label: 'Username',
          },
          {
            set: setGivenName,
            value: givenName,
            name: 'givenName',
            label: 'Given name',
          },
          {
            set: setFamilyName,
            value: familyName,
            name: 'familyName',
            label: 'Family name',
          },
          {
            set: setPhoneNumber,
            value: phoneNumber,
            name: 'phoneNumber',
            label: 'Phone number',
          },
          { set: setEmail, value: email, name: 'email', label: 'Email' },
        ].map(({ set, value, name, label }) => {
          return (
            <Grid item xs={4} key={name}>
              <InputLabel htmlFor={name} className={classes.inputLabel}>
                {label}
              </InputLabel>
              <TextField
                value={value}
                name={name}
                onChange={(event): void => {
                  set(event.target.value)
                }}
                onKeyDown={handleSearchKeyDown}
                variant="filled"
                fullWidth
              />
            </Grid>
          )
        })}
        <Grid item xs={4}>
          <div className={classes.buttonWrapper}>
            <Button
              variant="contained"
              startIcon={<SearchIcon />}
              onClick={onClickSearch}
              className={classes.searchButton}
              disabled={isFilterSame}
            >
              Search {fetching && <>...</>}
            </Button>
            {fetching && <CircularProgress className={classes.spinner} />}
          </div>
        </Grid>
      </Grid>
    </Box>
  )
}
