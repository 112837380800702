import paths from 'core/api/endpoints/comments/paths'
import filters from 'core/api/endpoints/comments/filters'
import params from 'core/api/endpoints/comments/params'
import { RequestMethod } from 'types/common/api/enums/request-method'
import { EndpointRoute } from 'types/common/api/models/entities/endpoint-route'

const CommentsEndpoints: { [Key: string]: EndpointRoute } = {
  getPostComments: {
    method: RequestMethod.GET,
    path: [paths.posts, params.postId, paths.comments],
  },
  createPostComment: {
    method: RequestMethod.POST,
    path: [paths.postComments],
  },
  updatePostComment: {
    method: RequestMethod.PUT,
    path: [paths.postComments, params.commentId],
  },
  deletePostComment: {
    method: RequestMethod.DELETE,
    path: [paths.postComments, params.commentId],
  },
}

export default CommentsEndpoints
