import { Box, Grid, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import _ from 'core/utils/deepdash'
import { FormGen, FormTypes } from 'components/simple-form-gen'
import React from 'react'
import { IForm } from 'types/common/interfaces/IForm'
import { Currency, PostGroup, PostGroupType } from 'fe-shared-resources';
import { PostsSelect } from 'components/posts-select';
import { IPostAutoCompleteFilter } from 'types/modules/posts/models/entities/post';
import PostStatus from 'types/modules/posts/enums/post-status';
import PostType from 'types/modules/posts/enums/post-type';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      width: '100%',
      height: '600px',
      '& .MuiGrid-item': {
        display: 'flex',
        flexDirection: 'column',
      },
    },
    postGroupsWrapper: {
      borderLeft: '1px solid #f4f4f4',
      paddingLeft: 8,
      height: '100%',
    },
    container: {
      height: '100%',
    },
    postGroupsError: {
      marginTop: -16,
    },
    inputLabel: {
      display: 'flex',
      marginBottom: '0.625rem',
    },
    currenciesContainer: {
      backgroundColor: '#f8f6f6',
      borderRadius: 8,
    },
  }),
)

interface IPostGroupFormProps extends IForm {
  postGroup: PostGroup
  isUpdating?: boolean
}

const typeOptions = Object.values(PostGroupType).map((code) => ({
  value: code,
  name: code,
}))

const currencyOptions = Object.values(Currency).map((code) => ({
  value: code,
  name: code,
}))

const filterMap: Partial<Record<PostGroupType, IPostAutoCompleteFilter>> = {
  DailyStack: {
    status: [
      PostStatus.Active,
      PostStatus.Created,
      PostStatus.Pending,
      PostStatus.Scheduled
    ],
    type: [PostType.Bet]
  },
  Onboarding: {
    status: [
      PostStatus.Active,
      PostStatus.Created,
      PostStatus.Pending,
      PostStatus.Scheduled
    ],
  }
}

export const PostGroupForm: React.FC<IPostGroupFormProps> = ({
  postGroup,
  handleChange,
  errors,
  isUpdating
}): JSX.Element => {
  const classes = useStyles()
  const name = {
    name: 'name',
    label: 'Name',
    value: postGroup?.name || '',
    type: FormTypes.TEXT_INPUT,
    otherProps: {
      error: !!errors?.name,
      helperText: errors?.name,
    },
  }
  const description = {
    name: 'description',
    label: 'Description',
    value: postGroup?.description || '',
    type: FormTypes.TEXT_INPUT,
    otherProps: {
      error: !!errors?.description,
      helperText: errors?.description,
    },
  }
  const type = {
    name: 'type',
    label: 'Post Group Type',
    value: postGroup?.type,
    type: FormTypes.SELECT,
    options: typeOptions,
    hideInitialOption: true,
    disabled: isUpdating,
    otherProps: {
      error: !!errors?.type,
      helperText: errors?.type,
    },
  }

  const rewardAmount = {
    name: 'rewardAmount',
    label: 'Reward Amount',
    value: postGroup?.rewardAmount,
    type: FormTypes.TEXT_INPUT,
    otherProps: {
      error: !!errors?.rewardAmount,
      helperText: errors?.rewardAmount,
    },
  }

  const rewardCurrency = {
    name: 'rewardCurrency',
    label: 'Reward Currency',
    value: postGroup?.rewardCurrency,
    type: FormTypes.SELECT,
    options: currencyOptions,
    otherProps: {
      error: !!errors?.rewardCurrency,
      helperText: errors?.rewardCurrency,
    },
  }

  const startAt = {
    name: 'startAt',
    label: 'Start Time',
    value: postGroup?.startAt,
    type: FormTypes.DATE_TIME,
    otherProps: {
      error: !!errors?.startAt,
      helperText: errors?.startAt,
    },
  }

  const endAt = {
    name: 'endAt',
    label: 'End Time',
    value: postGroup?.endAt,
    type: FormTypes.DATE_TIME,
    otherProps: {
      error: !!errors?.endAt,
      helperText: errors?.endAt,
    },
  }

  const settledAt = {
    name: 'settleAt',
    label: 'Settled Time',
    value: postGroup?.settleAt,
    type: FormTypes.DATE_TIME,
    otherProps: {
      error: !!errors?.settledAt,
      helperText: errors?.settledAt,
    },
  }

  //  const image = {
  //   name: 'media.imageUrl',
  //   label: 'Image',
  //   imageUrl: currentPostGroup?.imageUrl,
  //   type: FormTypes.IMAGE,
  //   otherProps: {
  //     error: !!errors?.settledAt,
  //     helperText: errors?.settledAt,
  //   },
  //   disabled: !isUpdating,
  //   onCreate: (val: any) => {
  //     updatePostGroup({
  //       ...currentPostGroup!,
  //     })
  //   },
  // }

  return (
    <Box className={classes.form}>
      <Grid container spacing={2} className={classes.container}>
        {/* <Grid item xs={3}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box height={96}>
                <FormGen handleChange={handleChange} {...image} />
              </Box>
            </Grid>
          </Grid>
        </Grid> */}
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormGen handleChange={handleChange} {...name} />
            </Grid>
            <Grid item xs={6}>
              <FormGen handleChange={handleChange} {...type} />
            </Grid>
            <Grid item xs={12}>
              <FormGen handleChange={handleChange} {...description} />
            </Grid>
            <Grid item xs={3}>
              <FormGen handleChange={handleChange} {...rewardAmount} />
            </Grid>
            <Grid item xs={9}>
              <FormGen handleChange={handleChange} {...rewardCurrency} />
            </Grid>
            <Grid item xs={12}>
              <PostsSelect
                // @ts-expect-error to decide later
                handleChange={(val) => handleChange({ target: { name: 'postIds', value: val } })}
                initialSelectedIds={postGroup.postIds || []}
                filter={filterMap[postGroup.type]}
              />
            </Grid>
            <Grid item xs={6}>
              <FormGen handleChange={handleChange} {...startAt} />
            </Grid>
            <Grid item xs={6}>
              <FormGen handleChange={handleChange} {...endAt} />
            </Grid>
            <Grid item xs={6}>
              <FormGen handleChange={handleChange} {...settledAt} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}
