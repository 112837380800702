import { InputLabel } from '@mui/material'
import { useSimpleFormStyles } from 'components/simple-form-gen'
import { useTypedSelector } from 'core/redux/utils'
import { getIapProducts } from 'modules/iap-products/actions/iap-product-actions'
import { Selectors } from 'modules/iap-products'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { OnChange } from 'types/common/interfaces/IForm'
import DownshiftSelect from 'components/controls/inputs/downshift/select'
import DownshiftMultiselect from 'components/controls/inputs/downshift/multiselect'

interface IapProductMultiselectProps {
  handleChange: (value: OnChange) => void
  initialSelectedIds: string[] | null
  label: string
  name: string
}

export const IapProductMultiselect = ({
  handleChange,
  initialSelectedIds,
  name,
  label
}: IapProductMultiselectProps): JSX.Element | null => {
  const dispatch = useDispatch()
  const getIapProduct = useTypedSelector((state) => Selectors.getIapProductGetter(state))
  const iapProducts = useTypedSelector((state) => Selectors.getIapProducts(state))
  const initiallySelected = initialSelectedIds
    ? initialSelectedIds.map(initialSelectedId => getIapProduct(initialSelectedId))
    : null
  const simpleFormClasses = useSimpleFormStyles()
  const [hasFetched, setHasFetched] = useState(false)

  const availableIapProducts = useMemo(() => {
    return iapProducts
  }, [hasFetched, iapProducts.length])

  useEffect(() => {
    if (hasFetched) return
    const fetchIapProduct = () => {
      dispatch(getIapProducts({
        promise: {
          onResolve: () => setHasFetched(true),
          onReject: () => setHasFetched(true)
        }
      }))
    }
    fetchIapProduct()
  }, [hasFetched])

  const availableIapProductsMap:  { [key: string]: boolean } = useMemo(() => {
    return availableIapProducts.reduce((acc, curr) => ({
      ...acc,
      [curr.id!]: true
    }), {})
  }, [availableIapProducts.length])

  const hasLoadedInitiallySelectedIapProduct =
    !!initialSelectedIds &&
    initialSelectedIds.every((initialSelectedId) => !!availableIapProductsMap[initialSelectedId])

  if (initialSelectedIds && !hasLoadedInitiallySelectedIapProduct) return null

  return  <>
    <InputLabel className={simpleFormClasses.inputLabel}>
      {label || 'Iap product'}
    </InputLabel>
    <DownshiftMultiselect
      onSelectedItemsChange={(items): void => {
        handleChange({
          // @ts-expect-error ignore for now
          target: {
            name,
            value: items.map((item) => item.id!)
          }
        })
      }}
      availableItems={availableIapProducts}
      initialSelectedItems={initiallySelected || []}
      variant="filled"
      searchKey="id"
      fieldName="id"
    />
  </>
}