import CampaignEndpoints from 'core/api/endpoints/campaigns'
import CampaignActionTypes from 'modules/campaigns/action-types/campaign-action-types'
import { createAction, createAsyncAction } from 'core/redux/utils'
import { IAsyncAction, ISimpleAction } from 'types/redux/interfaces/IAction'
import { IAsyncActionParams } from 'types/common/api/models/entities/async-action-params'
import { ICampaign } from 'modules/campaigns/models/campaign'
import { Version } from 'types/common/enums/versions'

export interface ICampaignActionParams
  extends IAsyncActionParams<ICampaign> {
  campaignId?: string
  campaignLevelId?: string
}

export const setCurrentCampaignId: (
  params: ICampaignActionParams,
) => ISimpleAction = (params) =>
  createAction(CampaignActionTypes.SET_CURRENT_CAMPAIGN_ID, params)

export const getCampaigns: (
  params: ICampaignActionParams,
) => IAsyncAction = (params) =>
  createAsyncAction({
    type: CampaignActionTypes.GET_CAMPAIGNS,
    endpoint: CampaignEndpoints.getCampaigns,
    payload: params,
    version: Version.V2
  })

export const getCampaignById: (
  params: ICampaignActionParams,
) => IAsyncAction = (params) =>
  createAsyncAction({
    type: CampaignActionTypes.GET_CAMPAIGN_BY_ID,
    endpoint: CampaignEndpoints.getCampaignById,
    payload: params,
    version: Version.V2
  })

export const createCampaignDraft: (
  params: ICampaignActionParams,
) => ISimpleAction = (params) =>
  createAction(CampaignActionTypes.CREATE_CAMPAIGN_DRAFT, params)

export const createCampaign: (
  params: ICampaignActionParams,
) => IAsyncAction = (params) =>
  createAsyncAction({
    type: CampaignActionTypes.CREATE_CAMPAIGN,
    endpoint: CampaignEndpoints.createCampaign,
    payload: params,
    version: Version.V2
  })

export const updateCampaign: (
  params: ICampaignActionParams,
) => IAsyncAction = (params) =>
  createAsyncAction({
    type: CampaignActionTypes.UPDATE_CAMPAIGN,
    endpoint: CampaignEndpoints.updateCampaign,
    payload: params,
    version: Version.V2
  })

export const uploadCampaignImage: (params: ICampaignActionParams) => IAsyncAction = (
  params,
) =>
  createAsyncAction({
    type: CampaignActionTypes.UPLOAD_CAMPAIGN_IMAGE,
    endpoint: CampaignEndpoints.uploadCampaignImage,
    payload: params,
    version: Version.V2
  })

export const deleteCampaign: (
  params: ICampaignActionParams,
) => IAsyncAction = (params) =>
  createAsyncAction({
    type: CampaignActionTypes.DELETE_CAMPAIGN,
    endpoint: CampaignEndpoints.deleteCampaignById,
    payload: params,
    version: Version.V2
  })