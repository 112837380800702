import { actionTypeSuccess } from 'core/redux/utils'
import ActionTypes from 'modules/watchlist/action-types'
import { Reducer } from 'redux'
import IPaginatedItems from 'types/common/pagination/models/entities/paginated-items'
import { TagGroup } from 'types/modules/tags/models/entities/tag-group'
import { IWatchlistState } from 'types/modules/watchlist/models/state/watchlist-state'
import { ISimpleAction, ISuccessAction } from 'types/redux/interfaces/IAction'
import _ from 'core/utils/deepdash'

const initialState: IWatchlistState = {
  tagGroups: {
    items: [],
  },
}

type AllowedActionTypes = ISuccessAction | ISimpleAction

const WatchlistReducer: Reducer<IWatchlistState, AllowedActionTypes> = (
  state = initialState,
  action: AllowedActionTypes,
) => {
  const simpleAction = action as ISimpleAction
  const successAction = action as ISuccessAction

  switch (action.type) {
    case actionTypeSuccess(ActionTypes.GET_TAG_GROUPS): {
      const newState = _.cloneDeep(state)
      const response = successAction.data as IPaginatedItems<TagGroup>

      newState.tagGroups = response

      return newState
    }
  }

  return state
}

export default WatchlistReducer
