import { SvgIcon } from '@mui/material'
import React from 'react'

const GossLogo: React.FC<{}> = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 98.02 32.49">
      <path
        d="M16.3,19.5a11.07,11.07,0,0,1-6.1,2A8.17,8.17,0,0,1,2,13.3C1.8,6.9,5.8,1.5,11.7.3a7.49,7.49,0,0,1,7.4,2.3c.6-.5.1-1.7,1-1.8a36.84,36.84,0,0,1,4.7,0c.5,0,.3.6.3.9-1.2,6.9-2.3,13.9-3.8,20.8A12.47,12.47,0,0,1,6,32.1c-3.9-.9-6.2-3.9-6-7.5.1-1.1.4-1.6,1.5-1.5a5.9,5.9,0,0,0,1.4-.2c2.1-.3,2.1-.3,2.5,1.9a3.74,3.74,0,0,0,3.3,2.9,6.47,6.47,0,0,0,6.5-3.6A11.13,11.13,0,0,0,16.3,19.5Zm-2.6-14a6.36,6.36,0,0,0-6.3,6.4,4,4,0,0,0,4.3,4.3A6.51,6.51,0,0,0,18,9.7C18.1,7.2,16.3,5.5,13.7,5.5Z"
        transform="translate(0.01 0.02)"
      />
      <path
        d="M39.7,0c7.1.1,11,5.6,9,12.9a12.78,12.78,0,0,1-14.8,9.5c-4.2-.7-7.1-4.3-7.2-8.9A13.28,13.28,0,0,1,37,.3,24.77,24.77,0,0,1,39.7,0ZM36.8,17.3A7.2,7.2,0,0,0,43.6,9,4.66,4.66,0,0,0,38,5.3a7.28,7.28,0,0,0-5.8,7.5C32.4,15.6,34.1,17.3,36.8,17.3Z"
        transform="translate(0.01 0.02)"
      />
      <path
        d="M59.3,22.7c-4.6,0-7.7-2.4-8.7-6.2-.3-1.2-.1-2,1.3-2a8.75,8.75,0,0,0,1.6-.2c1.2-.3,2.1-.2,2.4,1.3s1.7,2,3.1,2.1c1.6.1,3.2-.1,4.1-1.7.7-1.1.2-1.9-1-2.2s-2.7-.4-4-.7c-2.5-.5-4.5-1.8-5.1-4.4a6.45,6.45,0,0,1,2.9-6.9c3.2-2.3,7.7-2.4,10.5-.4A7.67,7.67,0,0,1,69,5.2c.1.2.2.5.1.6-.7,1.4-2.2.9-3.4,1.4-.5.2-1,.5-1.4-.4a3.4,3.4,0,0,0-5.2-1c-.4.4-.8.8-.7,1.4a1,1,0,0,0,1.1.8c1.4.3,2.8.4,4.1.7,5.3,1.4,6.8,6.7,3.1,10.7C64.7,21.8,62,22.5,59.3,22.7Z"
        transform="translate(0.01 0.02)"
      />
      <path
        d="M82,.1c3.9,0,6.6,1.8,7.5,4.9.3,1,.2,1.5-1,1.7a11.7,11.7,0,0,0-2,.5c-.8.3-1.4.3-1.8-.8-.8-1.8-4.4-1.8-5.4-.2-.5.8-.4,1.5.6,1.7s2.3.4,3.5.6c2.5.5,4.9,1.5,5.6,4.4s-.3,5.3-2.5,7.3c-3.2,2.9-8.9,3.4-12.3,1.1a8.3,8.3,0,0,1-3.3-5.2c-.2-.9,0-1.5,1.1-1.5a4.4,4.4,0,0,0,1.6-.2c1.5-.4,2.4-.4,2.9,1.5.4,1.6,2,1.8,3.4,1.8s3-.3,3.8-1.8c.6-1.1,0-1.9-1.1-2.1-1.3-.3-2.7-.4-4-.7-2.5-.5-4.5-1.8-5-4.5A6.36,6.36,0,0,1,76.3,2,8.72,8.72,0,0,1,82,.1Z"
        transform="translate(0.01 0.02)"
      />
      <path
        d="M98,18.6a3.83,3.83,0,0,1-3.7,3.7A3.18,3.18,0,0,1,91.1,19a3.83,3.83,0,0,1,3.8-3.6A3.07,3.07,0,0,1,98,18.6Z"
        transform="translate(0.01 0.02)"
      />
    </svg>
  )
}

export default GossLogo
