import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { colors } from 'utils/colors'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    navItemButton: {
      display: 'flex',
      padding: 16,
    },
    postNavigationItem: {
      display: 'flex',
      width: 40,
      height: 40,
      borderRadius: 90,
      alignItems: 'center',
      justifyContent: 'center',
      background: colors.rgba_0_0_0_03,
      color: theme.palette.text.primary,
    },
    navItemIcon: {
      fontSize: 16,
    },
    selectedNavItem: {
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    panelHeader: {
      display: 'flex',
      width: '100%',
      flexShrink: 0,
      height: 72,
      borderBottom: `1px solid ${colors.rgba_0_0_0_12}`,
    },
    navigationContainer: {
      display: 'flex',
      flex: 2,
      flexGrow: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    panelTitle: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      padding: '0 0 0 1.25rem',
    },
    rightHeaderSection: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      padding: '0 1.25rem 0 0',
      flexDirection: 'row-reverse',
      '& .MuiButton-contained': {
        marginRight: 20,
      },
    },
    contentWrapper: {
      display: 'flex',
      flexGrow: 1,
      overflow: 'hidden',
    },
    contentPanel: {
      position: 'relative',
      display: 'flex',
      flex: 1,
      padding: '1.25rem',
      overflow: 'auto',
      borderRight: `1px solid ${colors.rgba_0_0_0_12}`,
      '&:last-child': {
        border: 'none',
      },
    },
    splitFooter: {
      display: 'flex',
      width: '100%',
      justifySelf: 'flex-end',
    },
    leftFooterSection: {
      display: 'flex',
      padding: '1.25rem',
      flex: 1,
      justifyContent: 'space-between',
      borderRight: `1px solid ${colors.rgba_0_0_0_12}`,
      '&:last-child': {
        border: 'none',
      },
    },
    rightFooterSection: {
      display: 'flex',
      padding: '1.25rem',
      flex: 1,
      justifyContent: 'center',
      '& .MuiButton-contained': {
        marginLeft: '0.625rem',
        marginRight: '0.625rem',
      },
      borderRight: `1px solid ${colors.rgba_0_0_0_12}`,
      '&:last-child': {
        border: 'none',
      },
    },
  }),
)

export const useStylesForModalsWithTabs = (): any => {
  return useStyles()
}
