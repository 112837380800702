// Lib
import {
  createStore,
  applyMiddleware,
  Store as ReduxStore,
  compose,
  Middleware,
} from 'redux'

import createSagaMiddleware, { SagaMiddleware } from 'redux-saga'

import axios from 'axios'

import LogRocket from 'logrocket'

// Core
import IStore from 'types/redux/interfaces/IStore'
import rootReducer from 'core/redux/rootReducer'
import rootSaga from 'core/redux/rootSaga'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { ENV } from 'core/environment'

if (ENV.TARGET_ENV !== 'development') {
  LogRocket.init('2seiiz/goss-content-portal')
}

const sagaMiddleware: SagaMiddleware<{}> = createSagaMiddleware()

const middleware: Middleware[] = [sagaMiddleware, LogRocket.reduxMiddleware()]

const devToolsEnhancer =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const middlewareEnhancer =
  ENV.TARGET_ENV !== 'production'
    ? devToolsEnhancer(applyMiddleware(...middleware))
    : compose(applyMiddleware(...middleware))

const store: ReduxStore<IStore> = createStore(rootReducer, middlewareEnhancer)

sagaMiddleware.run(rootSaga)

let identified = false

store.subscribe(() => {
  const user = store.getState().account.user

  if (IsNullOrUndefined(user)) {
    identified = false
    return
  }

  const { id, username: name, email, token, roles } = user!

  if (token) {
    axios.defaults.headers.common.Authorization = token
  }

  if (!identified) {
    LogRocket.identify(id, {
      name,
      email,
      roles: (roles?.toArray() ?? []).join(','),
    })

    identified = true
  }
})

export default store
