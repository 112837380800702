import React from 'react'

import { Box, Button, Grid, Theme, Tooltip } from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import { IViewModeSelectorProps } from 'components/content-dashboard/props/view-mode-selector-props'
import clsx from 'clsx'
import { CalendarIcon, ListIcon, TimelineIcon } from 'components/icons'
import { useRequestContext } from 'core/api/context'
import { useDashboardContext } from 'components/content-dashboard/context'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    viewModeTabContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginRight: '0.625rem',
    },
    iconButton: {
      border: '1px solid rgba(0, 0, 0, 0.12)',
      minWidth: 0,
      padding: 7,
      marginBottom: '0.625rem',
      boxSizing: 'border-box',
      color: 'rgba(0,0,0,0.54)',
      '& .MuiButton-label': {
        top: 0,
      },
      '&.active': {
        border: '1px solid',
        borderColor: theme.palette.primary.main,
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      },
      '&:last-child': {
        margin: 0,
      },
    },
    icon: {
      display: 'flex',
      width: 24,
      height: 24,
    },
  }),
)

export const ViewModeSelector: React.FC<IViewModeSelectorProps> = (
  props: IViewModeSelectorProps,
) => {
  const rc = useRequestContext()
  const { dashboardView } = useDashboardContext()

  const classes = useStyles()

  const modes = [
    {
      label: 'List',
      value: 'list',
      icon: <ListIcon />,
    },
    {
      label: 'Calendar',
      value: 'calendar',
      icon: <CalendarIcon />,
    },
    {
      label: 'Timeline (Coming Soon)',
      value: 'timeline',
      icon: <TimelineIcon />,
      disabled: true,
    },
  ]

  const handleViewModeClick = (modeValue: string): void => {
    if (modeValue === 'timeline' || modeValue === dashboardView) return
    rc?.setActive(false)
    props.onViewModeChange && props.onViewModeChange(modeValue)
    props.setDashboardView(modeValue)
  }

  return (
    <Box className={classes.viewModeTabContainer}>
      {modes.map((mode) => (
        <Tooltip key={mode.label} title={mode.label} placement="left">
          <Button
            variant="outlined"
            className={clsx(
              classes.iconButton,
              mode.value === props.dashboardView && 'active',
            )}
            onClick={(): void => handleViewModeClick(mode.value)}
          >
            <i className={classes.icon}>{mode.icon}</i>
          </Button>
        </Tooltip>
      ))}
    </Box>
  )
}
