import React, { useState } from 'react'
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Modal from '@mui/material/Modal'
import Fade from '@mui/material/Fade'
import { Box, Tab, Tabs } from '@mui/material'
import { Panel, PanelHeader } from 'components/panel'
import { TagGroup } from 'types/modules/tags/models/entities/tag-group'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import _ from 'core/utils/deepdash'
import { AttachTagGroups } from 'modules/tags/views/attach-tag-groups'
import { ManageTagGroups } from 'modules/tags/views/manage-tag-group'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    emoticon: {
      maxWidth: 100,
      marginRight: '1.25rem',
    },
    nameField: {
      marginBottom: '20px',
      // width: 'calc(100% - 120px)',
      width: '100%',
    },
    editFormWrapper: {
      flexFlow: 'wrap',
      minHeight: 350,
    },
    footerContent: {
      display: 'flex',
      flex: 1,
      justifyContent: 'flex-end',
    },
    cancelBtn: {
      marginRight: '1.25rem',
    },

    tabContainer: {
      paddingLeft: 20,
      paddingTop: 10,
    },

    hidden: {
      display: 'none',
    },
  }),
)

export interface IEditModalProps {
  open: boolean
  tagGroup: TagGroup | null
  handleOpen: () => void
  handleClose: () => void
}

enum Mode {
  Create = 'Create',
  Edit = 'Edit',
}

export const TagGroupEditModal: React.FC<IEditModalProps> = (
  props: IEditModalProps,
) => {
  const classes = useStyles()

  const mode = IsNullOrUndefined(props.tagGroup) ? Mode.Create : Mode.Edit
  const [tabValue, setTabValue] = useState(0)

  const handleTabChange = (event: any, val: any): void => {
    setTabValue(val)
  }

  return (
    <div>
      <Modal
        className={classes.modal}
        open={props.open}
      >
        <Fade in={props.open}>
          <Panel xs={5}>
            {mode === Mode.Edit ? (
              <PanelHeader>
                <Tabs value={tabValue} onChange={handleTabChange}>
                  <Tab label="Attach tags" />
                  <Tab label="Attach tag groups" />
                </Tabs>
              </PanelHeader>
            ) : (
              <PanelHeader title="Create Tag" />
            )}
            {mode === Mode.Create && (
              <ManageTagGroups
                handleClose={props.handleClose}
                tagGroup={props.tagGroup}
              />
            )}
            {mode === Mode.Edit && (
              <>
                <Box className={tabValue === 0 ? '' : classes.hidden}>
                  <ManageTagGroups
                    handleClose={props.handleClose}
                    tagGroup={props.tagGroup}
                  />
                </Box>
                <Box className={tabValue !== 0 ? '' : classes.hidden}>
                  <AttachTagGroups
                    tagGroupId={props.tagGroup?.id!}
                    handleClose={props.handleClose}
                  />
                </Box>
              </>
            )}
          </Panel>
        </Fade>
      </Modal>
    </div>
  )
}
