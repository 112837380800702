export enum QuestionField {
  ID = 'id',
  POST_ID = 'postId',
  TEXT = 'text',
  TIMER_SECONDS = 'timerSeconds',
  MEDIA = 'media',
  MEDIA_ITEM_ID = 'mediaItemId',
  IMAGE_URL = 'imageUrl',
  VIDEO_URL = 'videoUrl',
  OPTIONS = 'options',
  ANSWERS = 'answers',
  KEYWORDS = 'keywords',
  UPDATED_AT = 'updatedAt',
  UPDATED_BY = 'updatedBy',
}
