import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { SortDirection } from 'types/common/enums/sort-direction'

export const CaptureSorts = (
  sortBy?: string,
  sortDirection?: string,
): string | null => {
  if (
    IsNullOrUndefined(sortBy) ||
    sortBy === '' ||
    IsNullOrUndefined(sortDirection)
  )
    return null

  const prefix = sortDirection === SortDirection.Descending ? '-' : ''

  return `${prefix}${sortBy}`
}
