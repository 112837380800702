import React from 'react'
import { GameTask } from 'fe-shared-resources'
import { useTypedSelector } from 'core/redux/utils'
import { useGameTasksContext } from 'modules/quests/context/game-task-context'
import {
  GameTaskCard,
  StyledTableCell,
} from 'modules/quests/view/game-tasks/game-task-card'
import { GameTaskModal } from 'modules/quests/view/game-tasks/game-task-modal'
import { LocalListWrapper } from 'components/local-list-wrapper'
import { getGameTasks, IGameTaskActionParams } from 'modules/quests/actions/game-task-actions'
import * as Selectors from 'modules/quests/selectors'

const sortOptions = [
  { name: 'Created at', value: 'createdAt', descending: true },
  { name: 'Name', value: 'name' },
]

export const GameTaskList: React.FC<{}> = () => {
  const gameTasks = useTypedSelector((state) =>
    Selectors.getGameTasks(state),
  )
  const count = useTypedSelector((state) =>
    Selectors.getGameTasksCount(state),
  )
  const {
    currentGameTask,
    createGameTaskDraft,
    setCurrentGameTask,
  } = useGameTasksContext()

  return (
    <LocalListWrapper<
      GameTask,
      IGameTaskActionParams,
      null
    >
      list={gameTasks}
      count={count}
      searchKey="name"
      currentItem={currentGameTask}
      createDraft={createGameTaskDraft}
      setCurrentItem={setCurrentGameTask}
      CardComponent={GameTaskCard}
      ModalComponent={GameTaskModal}
      getItems={getGameTasks}
      searchLabel="Name"
      title="Game Tasks"
      sortOptions={sortOptions}
      panelXs={12}
    >
      <>
        <StyledTableCell>Name</StyledTableCell>
        <StyledTableCell>Type</StyledTableCell>
        <StyledTableCell>Schedule</StyledTableCell>
        <StyledTableCell>Max Level</StyledTableCell>
        <StyledTableCell>Enabled</StyledTableCell>
        <StyledTableCell>Visible</StyledTableCell>
      </>
    </LocalListWrapper>
  )
}
