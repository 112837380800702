import { SelectChangeEvent } from '@mui/material'
import { useTypedSelector } from 'core/redux/utils'
import { useRouter } from 'core/routing/hooks/use-router'
import {
  IsNullOrUndefined,
  IsNullUndefinedOrEmpty,
} from 'core/utils/isNullOrUndefined'
import { usePostListContext } from 'modules/posts/context/post-list'
import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useDispatch } from 'react-redux'
import { IPost } from 'types/modules/posts/models/entities/post'
import { Tag } from 'types/modules/tags/models/entities/tag'
import { TagGroup } from 'types/modules/tags/models/entities/tag-group'
import * as Actions from '../actions'
import * as Selectors from '../selectors'

export enum DisplayOptions {
  Questions = 'Questions',
  Posts = 'Posts',
}

export interface IWatchlistContext {
  groupedPosts: { [key: string]: IPost[] } | null
  tagGroups: TagGroup[]
  currentCategory: string | null
  currentCategoryPosts: IPost[] | null
  navigateToCategory: (category: string) => void
  navigateToWatchlist: () => void
  displayBy: DisplayOptions
  handleDisplayChange: (event: SelectChangeEvent<string>) => void
}

export const WatchlistContext = React.createContext<IWatchlistContext>(
  {} as IWatchlistContext,
)

export const WatchlistContextProvider: React.FC<React.ReactNode> = (
  props: PropsWithChildren<React.ReactNode>,
) => {
  const { location, routeParams, history } = useRouter()
  const { posts: postData } = usePostListContext()

  const tagGroups = useTypedSelector((state) => Selectors.getTagGroups(state))

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(Actions.getTagGroups())
  }, [])

  const [displayBy, setDisplayBy] = useState<DisplayOptions>(
    DisplayOptions.Questions,
  )

  const handleDisplayChange = (
    event: SelectChangeEvent<string>,
  ): void => {
    setDisplayBy(event.target.value as DisplayOptions)
  }

  const groupPosts = (): { [key: string]: IPost[] } | null => {
    if (
      IsNullUndefinedOrEmpty(postData.items) ||
      IsNullUndefinedOrEmpty(tagGroups.items)
    )
      return null

    let tags: Tag[] = []

    tagGroups.items.forEach((tagGroup) => {
      tags = [...tags, ...(tagGroup.tags as Tag[])]
    })

    const result: { [key: string]: IPost[] } = {}

    if (
      postData.items.some(
        (post) =>
          !(post.tags as Tag[]).some((postTag) =>
            tags.some((tag) => tag.id === postTag.id),
          ),
      )
    ) {
      result.Uncategorised = postData.items.filter(
        (post) =>
          !(post.tags as Tag[]).some((postTag) =>
            tags.some((tag) => tag.id === postTag.id),
          ),
      )
    }

    tags.forEach((tag) => {
      if (
        postData.items.some((post) =>
          (post.tags as Tag[]).some((postTag) => postTag.id === tag.id),
        )
      ) {
        result[tag.name!] = postData.items.filter((post) =>
          (post.tags as Tag[]).some((postTag) => postTag.id === tag.id),
        )
      }
    })

    return result
  }

  const groupedPosts = groupPosts()

  const currentCategory = !IsNullOrUndefined(routeParams.tagCode)
    ? decodeURI(routeParams.tagCode)
    : null

  const currentCategoryPosts =
    !IsNullOrUndefined(groupedPosts) &&
    !IsNullOrUndefined(currentCategory) &&
    Object.hasOwnProperty.call(groupedPosts, currentCategory!)
      ? groupedPosts![currentCategory!]
      : null

  const navigateToCategory = (category: string): void => {
    const categorySlug = encodeURI(category)
    history.push(`/watchlist/${categorySlug}`)
  }

  const navigateToWatchlist = (): void => {
    history.push('/watchlist')
  }

  const context = {
    groupedPosts,
    tagGroups: tagGroups.items,
    currentCategory,
    currentCategoryPosts,
    navigateToCategory,
    navigateToWatchlist,
    displayBy,
    handleDisplayChange,
  }

  return (
    <WatchlistContext.Provider value={context}>
      {props.children}
    </WatchlistContext.Provider>
  )
}

export const useWatchlistContext = (): IWatchlistContext =>
  useContext(WatchlistContext)
