import { useTypedSelector } from 'core/redux/utils'
import { Actions, Selectors } from 'modules/events'
import { IEventActionParams } from 'modules/events/actions'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import SortDirection from 'types/common/enums/sort-direction'
import { FilterOperator } from 'types/common/filtering/enums/FilterOperator'
import { FilterType } from 'types/common/filtering/enums/FilterType'
import { IFilter } from 'types/common/filtering/models/entities/IFilter'
import { EventFieldName } from 'types/modules/events/enums/event-field'
import { EventStatus } from 'types/modules/events/enums/event-status'
import { IEvent } from 'types/modules/events/models/entities/event'
import { IAsyncAction } from 'types/redux/interfaces/IAction'

interface IParams {
  eventId?: string
}

interface IReturn {
  selectedEvent: IEvent | null
  searchEvents: (params: IEventActionParams) => IAsyncAction
}

export const useEventAutoCompleteSearch = ({
  eventId
}: IParams): IReturn => {
  const selectedEvent = useTypedSelector((state) =>
    Selectors.getCachedEventById(state, eventId),
  )
  const dispatch = useDispatch()

  useEffect(() => {
    if (selectedEvent === null && eventId) {
      dispatch(Actions.getEventById({ eventId }))
    }
  }, [eventId])

  const searchEvents = (params: IEventActionParams): IAsyncAction => {
    const filters: IFilter[] = [
      {
        field: EventFieldName.STATUS,
        type: FilterType.Select,
        operator: FilterOperator.NotEquals,
        value: EventStatus.Created,
      },
      {
        field: EventFieldName.NAME,
        type: FilterType.Search,
        operator: FilterOperator.ContainsCaseInsensitive,
        value: params.search!.value,
      },
    ]

    return Actions.autocompleteEventSearch({
      filters,
      sortBy: EventFieldName.UPDATED_AT,
      sortDirection: SortDirection.Descending,
    })
  }

  return {
    selectedEvent,
    searchEvents
  }
}