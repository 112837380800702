import React from 'react'
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Box, Button } from '@mui/material'
import { PanelContent, PanelFooter } from 'components/panel'
import _ from 'core/utils/deepdash'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    editFormWrapper: {
      flexFlow: 'wrap',
      minHeight: 350,
    },
    footerContent: {
      display: 'flex',
      flex: 1,
      justifyContent: 'flex-end',
    },
    cancelBtn: {
      marginRight: '1.25rem',
    },
  }),
)

export interface ITagGroupFromContainerProps {
  handleSubmit: () => void
  handleClose: () => void
  buttonLabel: string
  children: React.ReactChild
  processing?: boolean
}

export const TagGroupFromContainer: React.FC<ITagGroupFromContainerProps> = ({
  handleSubmit,
  handleClose,
  buttonLabel,
  children,
  processing = false,
}: ITagGroupFromContainerProps) => {
  const classes = useStyles()
  return (
    <>
      <PanelContent>
        <Box className={classes.editFormWrapper}>{children}</Box>
      </PanelContent>
      <PanelFooter>
        <Box className={classes.footerContent}>
          <Button
            size="large"
            className={classes.cancelBtn}
            onClick={handleClose}
            variant="contained"
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            color="primary"
            size="large"
            onClick={handleSubmit}
            variant="contained"
            disabled={processing}
          >
            {buttonLabel}
          </Button>
        </Box>
      </PanelFooter>
    </>
  )
}
