import { AppState } from 'types/redux/types/app-state'
import { MODULE_NAME } from 'modules/app-settings/constants'
import { IAppSettingsState } from 'modules/app-settings/model/state'
import { IPortalWarning } from 'types/modules/users/portal/models/entities/portal-settings'

const getState = (state: AppState): IAppSettingsState => state[MODULE_NAME]

export const getCurrentCurrencies = (state: AppState): string[] => {
  return getState(state).currencies
}

export const getTaskCodes = (state: AppState): string[] => {
  return getState(state).taskCodes
}

export const getActionCodes = (state: AppState): string[] => {
  return getState(state).actionCodes
}

export const getQuestMediaLocations = (state: AppState): string[] => {
  return getState(state).questMediaLocations
}

export const getSchedules = (state: AppState): string[] => {
  return getState(state).schedules
}

export const getRewardOptions = (state: AppState): string[] => {
  return getState(state).rewardOptions
}

export const getEventCodes = (state: AppState): string[] => {
  return getState(state).eventCodes
}

export const getIsFetched = (state: AppState): boolean => {
  return getState(state).fetched
}

export const getIsFetching = (state: AppState): boolean => {
  return getState(state).fetching
}

export const getAppWarnings = (state: AppState): IPortalWarning[] => {
  return getState(state).appWarnings
}

export const getAppWarningCountMap = (
  state: AppState,
): { [key: string]: number } => {
  return getAppWarnings(state).reduce(
    (acc, curr): { [key: string]: number } => ({
      ...acc,
      // @ts-expect-error will write problem later
      [curr.source]: (acc[curr.source] || 0) + 1,
    }),
    {},
  )
}
