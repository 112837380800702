import PrizeEndpoints from 'core/api/endpoints/prizes'
import { createAction, createAsyncAction } from 'core/redux/utils'
import { ActionTypes } from 'modules/prizes'
import { IAsyncActionParams } from 'types/common/api/models/entities/async-action-params'
import { IPrize } from 'types/modules/prizes/models/entities/prize'
import { IPrizeDraft } from 'types/modules/prizes/models/entities/prize-draft'
import { IAsyncAction, ISimpleAction } from 'types/redux/interfaces/IAction'

export interface IPrizeActionParams
  extends IAsyncActionParams<IPrize | IPrizeDraft | string> {
  itemId?: string
  prizeId?: string
  prizeStatus?: string
}

export const setCurrentPrizeId: (
  params: IPrizeActionParams,
) => ISimpleAction = (params) =>
  createAction(ActionTypes.SET_CURRENT_PRIZE_ID, params)

export const clearPrizes: () => ISimpleAction = () =>
  createAction(ActionTypes.CLEAR_PRIZES, null)

export const getPrizeStatuses: () => IAsyncAction = () =>
  createAsyncAction({
    type: ActionTypes.GET_PRIZE_STATUSES,
    endpoint: PrizeEndpoints.getPrizeStatuses,
    payload: null,
  })

export const getPrizes: (params: IPrizeActionParams) => IAsyncAction = (
  params,
) => createAsyncAction({ type: ActionTypes.GET_PRIZES, endpoint: PrizeEndpoints.getPrizes, payload: params})

export const getPrizeById: (params: IPrizeActionParams) => IAsyncAction = (
  params,
) =>
  createAsyncAction({
    type: ActionTypes.GET_PRIZE_BY_ID,
    endpoint: PrizeEndpoints.getPrizeById,
    payload: params,
  })

export const createPrize: (params: IPrizeActionParams) => IAsyncAction = (
  params,
) =>
  createAsyncAction({
    type: ActionTypes.CREATE_PRIZE,
    endpoint: PrizeEndpoints.createPrize,
    payload: params,
  })

export const duplicatePrize: (params: IPrizeActionParams) => IAsyncAction = (
  params,
) =>
  createAsyncAction({
    type: ActionTypes.DUPLICATE_PRIZE,
    endpoint: PrizeEndpoints.duplicatePrize,
    payload: params,
  })

export const updatePrize: (params: IPrizeActionParams) => IAsyncAction = (
  params,
) =>
  createAsyncAction({
    type: ActionTypes.UPDATE_PRIZE,
    endpoint: PrizeEndpoints.updatePrize,
    payload: params,
  })

export const updatePrizeStatus: (params: IPrizeActionParams) => IAsyncAction = (
  params,
) =>
  createAsyncAction({
    type: ActionTypes.UPDATE_PRIZE_STATUS,
    endpoint: PrizeEndpoints.updatePrizeStatus,
    payload: params,
  })

export const promotePrize: (params: IPrizeActionParams) => IAsyncAction = (
  params,
) =>
  createAsyncAction({
    type: ActionTypes.PROMOTE_PRIZE,
    endpoint: PrizeEndpoints.promotePrize,
    payload: params,
  })

export const deletePrize: (params: IPrizeActionParams) => IAsyncAction = (
  params,
) =>
  createAsyncAction({
    type: ActionTypes.DELETE_PRIZE,
    endpoint: PrizeEndpoints.deletePrize,
    payload: params,
  })

export const clearTrash: () => IAsyncAction = () =>
  createAsyncAction({ type: ActionTypes.CLEAR_TRASH, endpoint: PrizeEndpoints.clearTrash, payload: null })

export const autocompletePrizeSearch: (
  params: IPrizeActionParams,
) => IAsyncAction = (params) =>
  createAsyncAction({
    type: ActionTypes.AUTOCOMPLETE_PRIZE_SEARCH,
    endpoint: PrizeEndpoints.getPrizes,
    payload: params,
  })

export const uploadPrizeImage: (params: IPrizeActionParams) => IAsyncAction = (
  params,
) =>
  createAsyncAction({
    type: ActionTypes.UPLOAD_PRIZE_IMAGE,
    endpoint: PrizeEndpoints.uploadPrizeImage,
    payload: params,
  })

export const uploadFeaturedImage: (
  params: IPrizeActionParams,
) => IAsyncAction = (params) =>
  createAsyncAction({
    type: ActionTypes.UPLOAD_FEATURED_IMAGE,
    endpoint: PrizeEndpoints.uploadFeaturedImage,
    payload: params,
  })

export const uploadLogo: (params: IPrizeActionParams) => IAsyncAction = (
  params,
) =>
  createAsyncAction({
    type: ActionTypes.UPLOAD_LOGO,
    endpoint: PrizeEndpoints.uploadLogo,
    payload: params
  })
