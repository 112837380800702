import { Box, Button, Portal, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { ConfirmationDialog } from 'components/confirmation-dialog'
import { UndoIcon, SaveIcon, DiscardIcon } from 'components/icons'
import { useAuth } from 'core/authentication/hooks/use-auth'
import { titleCase } from 'core/utils/titleCase'
import { useFormik } from 'formik'
import { QuestionView } from 'modules/posts/views/components/manage-post-panel/question-view'
import moment from 'moment-timezone'
import React, { useEffect, useRef } from 'react'
import PostStatus from 'types/modules/posts/enums/post-status'
import { IPost } from 'types/modules/posts/models/entities/post'
import { IPostQuestion } from 'types/modules/posts/models/entities/post-question'
import _ from 'core/utils/deepdash'
import PostType from 'types/modules/posts/enums/post-type'
import { QuestionScreenGamePreview } from 'modules/app/views/components/previews/question-screen-game'
import { PostFeedPreview } from 'modules/app/views/components/previews/post-feed'
import { QuestionScreenBetPreview } from 'modules/app/views/components/previews/question-screen-bet'
import { QuestionScreenMultibetPreview } from 'modules/app/views/components/previews/question-screen-multibet'
import { mergeArrays } from 'core/utils/mergeArrays'
import { QuestionSchema } from 'modules/posts/validation/question-schema'
import { QuestionField } from 'types/modules/posts/enums/question-field'
import { useCurrentQuestion } from 'hooks/use-current-question'
import { usePostQuestionsManager } from 'modules/posts/context/post-questions-manager'
import { usePostListContext } from 'modules/posts/context/post-list'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentPanel: {
      position: 'relative',
      display: 'flex',
      flex: 1,
      padding: '1.25rem',
      overflow: 'auto',
      borderRight: '1px solid rgba(0,0,0,0.12)',
      '&:last-child': {
        border: 'none',
      },
    },
    updateButton: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
      marginRight: '0.625rem',
    },
    resetButton: {
      color: theme.palette.text.primary,
      backgroundColor: '#ecf0f1',
      marginLeft: '0.625rem',
      '&:hover': {
        backgroundColor: '#f2f5f5',
      },
    },
    metadataContainer: {
      position: 'absolute',
      display: 'flex',
      flexDirection: 'column',
      bottom: '1.25rem',
      right: '1.25rem',
    },
    metadata: {
      display: 'flex',
      fontSize: '0.7rem',
      justifyContent: 'flex-end',
    },
  }),
)

interface IQuestionScreenProps {
  questionId: string
  mainContentPanelRef: HTMLDivElement | null
  dynamicContentPanelRef: HTMLDivElement | null
  formButtonFooterContainerRef: HTMLDivElement | null
  disableButtons: (condition: boolean) => void
  renderPreview: boolean
}

export const QuestionScreen: React.FC<IQuestionScreenProps> = ({
  questionId,
  mainContentPanelRef,
  dynamicContentPanelRef,
  formButtonFooterContainerRef,
  disableButtons,
  renderPreview,
}: IQuestionScreenProps) => {
  const { deleteQuestion, updatePostQuestion, updatingQuestion } =
    usePostQuestionsManager()
  const { currentPost } = usePostListContext()
  const { currentUser } = useAuth()
  const post = currentPost!! as IPost
  const previousUpdatingValue = useRef(updatingQuestion)
  const question = useCurrentQuestion(questionId)

  const postQuestionForm = useFormik({
    enableReinitialize: false,
    initialValues: {
      [QuestionField.ID]: question.id,
      [QuestionField.POST_ID]: question.postId,
      [QuestionField.TEXT]: question.text,
      [QuestionField.TIMER_SECONDS]: question.timerSeconds,
      [QuestionField.MEDIA]: question.media ?? {},
      [QuestionField.OPTIONS]: question.options,
      [QuestionField.ANSWERS]: question.answers,
      [QuestionField.KEYWORDS]: question.keywords,
      [QuestionField.UPDATED_AT]: question.updatedAt,
      [QuestionField.UPDATED_BY]: question.updatedBy,
      order: question.order,
    },
    validationSchema: QuestionSchema,
    onSubmit: (values: IPostQuestion): void => {
      const newValues = _.clone(values)
      newValues.updatedBy = currentUser.username
      newValues.text = newValues.text.trim().replace('  ', ' ')
      newValues.options = newValues.options.map((option) => {
        option.text = option.text.trim().replace('  ', ' ')
        return option
      })
      updatePostQuestion(newValues)
    },
  })

  useEffect(() => {
    disableButtons(postQuestionForm.dirty)
  }, [postQuestionForm.dirty])

  useEffect(() => {
    const values = postQuestionForm.values
    if (previousUpdatingValue.current && !updatingQuestion) {
      postQuestionForm.resetForm({
        values,
      })
    }
    previousUpdatingValue.current = updatingQuestion
  }, [updatingQuestion])

  const classes = useStyles()

  const renderFormButtons = (): JSX.Element => {
    return (
      <Portal container={formButtonFooterContainerRef}>
        <Button
          variant="contained"
          startIcon={<UndoIcon />}
          className={classes.resetButton}
          size="small"
          onClick={(): void => {
            postQuestionForm.resetForm()
          }}
          disabled={
            !postQuestionForm.dirty &&
            Object.keys(postQuestionForm.touched).length === 0 &&
            postQuestionForm.touched.constructor === Object
          }
        >
          Undo
        </Button>
        {[PostStatus.Created, PostStatus.Pending, PostStatus.Trashed].includes(
          post.status,
        ) && (
          <ConfirmationDialog
            popoverId={`delete-post-question-${question.id}`}
            buttonProps={{
              variant: 'contained',
              startIcon: <DiscardIcon />,
              className: classes.resetButton,
              size: 'small',
            }}
            onConfirmation={(): void => deleteQuestion(post.id, question.id)}
            confirmationText={`Are you sure you want to delete this question?`}
            buttonLabel={'Delete Question'}
          />
        )}
        <Button
          variant="contained"
          startIcon={<SaveIcon />}
          className={classes.updateButton}
          size="small"
          onClick={(): void => {
            postQuestionForm.submitForm()
          }}
          disabled={
            !postQuestionForm.dirty &&
            Object.keys(postQuestionForm.touched).length === 0 &&
            postQuestionForm.touched.constructor === Object
          }
        >
          Save
        </Button>
      </Portal>
    )
  }

  const liveUpdatePost: IPost = {
    ...post,
    questions: mergeArrays(post.questions, [postQuestionForm.values]),
  }

  const getPreview = (): JSX.Element | undefined => {
    switch (post.type) {
      case PostType.Poll:
        return <PostFeedPreview {...liveUpdatePost} />

      case PostType.Game:
      case PostType.Test:
        return (
          <QuestionScreenGamePreview
            post={post}
            question={postQuestionForm.values}
            index={post.questions.findIndex((q) => q.id === question.id)}
          />
        )

      case PostType.Bet:
        return post.betCard ? (
          <QuestionScreenMultibetPreview
            post={post}
            question={postQuestionForm.values}
            index={post.questions.findIndex((q) => q.id === question.id)}
          />
        ) : (
          <QuestionScreenBetPreview
            post={post}
            question={postQuestionForm.values}
            index={post.questions.findIndex((q) => q.id === question.id)}
          />
        )
    }
  }

  return (
    <React.Fragment>
      {renderFormButtons()}
      <Portal container={mainContentPanelRef}>
        <QuestionView
          {...{
            post,
            question: postQuestionForm.values,
            handleChange: postQuestionForm.handleChange,
            setFieldValue: postQuestionForm.setFieldValue,
            setFieldTouched: postQuestionForm.setFieldTouched,
            errors: postQuestionForm.errors,
          }}
        />
      </Portal>
      {renderPreview && (
        <Portal container={dynamicContentPanelRef}>
          {getPreview()}
          <Box className={classes.metadataContainer}>
            <Typography color="textPrimary" className={classes.metadata}>
              {`Created By: ${titleCase(post.createdBy ?? 'Unknown')}`}
            </Typography>
            <Typography color="textPrimary" className={classes.metadata}>
              {`Updated By: ${titleCase(question.updatedBy ?? 'Unknown')}`}
            </Typography>
            <Typography color="textPrimary" className={classes.metadata}>
              {`${moment(question.updatedAt).format('Do MMMM YYYY, h:mm A')}`}
            </Typography>
          </Box>
        </Portal>
      )}
    </React.Fragment>
  )
}
