import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';


interface Props {
  options: string[],
  onSelect: (option: string) => void
  value: string
  countMap: { [key: string]: number }
}

export default function StatusSelect({ value, onSelect, options, countMap }: Props) {

  return (
    <ButtonGroup variant="text" aria-label="Status">
      {
        options.map((option) => <Button
          key={option}
          onClick={() => onSelect(option)}
          color={value === option ? 'success' : 'primary'}
        >
          {option} ({countMap[option]})
        </Button>)
      }
    </ButtonGroup>
  )
}