import { useRequestContext } from 'core/api/context'
import FilterToolbar, {
  IFilterToolbarProps,
} from 'core/filtering/components/filter-toolbar'
import React, { useEffect } from 'react'
import SortDirection from 'types/common/enums/sort-direction'
import { PostField } from 'types/modules/posts/enums/post-field'
import PostStatus from 'types/modules/posts/enums/post-status'

export const PostListToolbar: React.FC<{}> = () => {
  const rc = useRequestContext()

  const statusFilter = rc?.getFilterByField(PostField.STATUS)

  const toolbarProps = {
    searchFilterConfig: {
      field: 'tagsOrTitle',
    },
    sortOptions: [
      { label: 'Start Date', value: 'start' },
      { label: 'End Date', value: 'close' },
      { label: 'Check on Date', value: 'checkByDate' },
      { label: 'Settle Date', value: 'shouldBeSettledBy' },
      { label: 'Last Updated', value: 'updatedAt' },
      { label: 'Show', value: 'subCategoryId' },
      {
        label: 'Volume',
        value: (statusFilter?.value as string[]).includes(PostStatus.Active)
          ? 'ActivePopularity'
          : 'volume',
      },
      { label: 'Featured', value: 'featured' },
    ],
    initialSortValue: {
      sortBy: 'start',
      sortDirection: SortDirection.Descending,
    },
  } as IFilterToolbarProps

  return <FilterToolbar {...toolbarProps} />
}
