import { Box, ButtonBase, Popover, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx'
import { usePopover } from 'components/popover/use-popover'
import { sortBy } from 'lodash-es'
import { PostTimeGridContextMenu } from 'modules/posts/views/components/dashboard/post-calender-time-grid-cell/context-menu'
import { TimeGridPost } from 'modules/posts/views/components/dashboard/post-calender-time-grid-cell/post'
import moment from 'moment-timezone'
import React from 'react'
import { IPost } from 'types/modules/posts/models/entities/post'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    postEvent: {
      display: 'flex',
      flex: 1,
      boxSizing: 'border-box',
      flexDirection: 'column',
      height: 79,
      '&.Bet': {
        background: '#ff787d',
        border: '1px solid #ff787d',
      },
      '&.Game': {
        background: '#FDD219',
        border: '1px solid #FDD219',
      },
      '&.Poll': {
        background: '#06B2BB',
        border: '1px solid #06B2BB',
      },
      '&.Test': {
        background: '#F78828',
        border: '1px solid #F78828',
      },
      '&.multiple': {
        background: '#02599D',
        border: '1px solid #02599D',
      },
    },
    eventHeader: {
      display: 'flex',
      height: 15,
      width: '100%',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingLeft: '0.3rem',
      paddingRight: '0.3rem',
    },
    headerText: {
      fontSize: '0.7rem',
      color: theme.palette.primary.contrastText,
      '&.textPrimary': {
        color: theme.palette.text.primary,
      },
    },
    eventContent: {
      display: 'flex',
      flex: 1,
      width: '100%',
      height: 'calc(100% - 15px)',
      background: 'rgba(255,255,255,0.60)',
      padding: '0.3rem',
      '&.darker': {
        background: 'rgba(255,255,255,0.30)',
      },
    },
    multipostPopover: {
      display: 'flex',
      flexDirection: 'column',
      width: 230,
    },
    multipostCount: {
      color: theme.palette.primary.contrastText,
      textAlign: 'center',
      margin: 'auto',
    },
  }),
)

interface ITimeGridPostMultiviewProps {
  posts: IPost[]
  dateTime: string
  dateField: string
}

export const TimeGridPostMultiview: React.FC<ITimeGridPostMultiviewProps> = (
  props: ITimeGridPostMultiviewProps,
) => {
  const { posts, dateTime, dateField } = props
  const classes = useStyles()

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handleMultipostPopoverClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleMultipostPopoverClose = (): void => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  // eslint-disable-next-line id-blacklist
  const id = open ? `multiple-posts-${dateTime}` : undefined

  const contextMenu = usePopover({
    id: `context-menu-${dateTime}`,
    anchorReference: 'anchorPosition',
  })

  return (
    <React.Fragment>
      <ButtonBase
        aria-describedby={id}
        className={clsx(classes.postEvent, 'multiple')}
        onClick={handleMultipostPopoverClick}
        onContextMenu={(e: React.MouseEvent<HTMLButtonElement>): void => {
          e.preventDefault()
          contextMenu.onClick(e)
        }}
      >
        <Box className={classes.eventHeader}>
          <Typography
            component="span"
            variant="button"
            className={clsx(classes.headerText)}
          >
            {moment(dateTime).format('h:mm A')}
          </Typography>
          <Typography
            component="span"
            variant="button"
            className={clsx(classes.headerText)}
          >
            {`.`}
          </Typography>
        </Box>
        <Box className={clsx(classes.eventContent, 'darker')}>
          <Typography
            component="span"
            variant="body2"
            className={clsx(classes.multipostCount)}
          >
            {`${posts.length} Posts`}
          </Typography>
        </Box>
      </ButtonBase>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleMultipostPopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box className={classes.multipostPopover}>
          {posts.map((post, index) => (
            <TimeGridPost
              key={`post-${post.id}`}
              index={index}
              post={post}
              dateField={dateField}
              onClick={(): void => handleMultipostPopoverClose()}
            />
          ))}
        </Box>
      </Popover>
      <PostTimeGridContextMenu
        dateTime={dateTime}
        dateField={dateField}
        popoverProps={contextMenu.popoverProps}
      />
    </React.Fragment>
  )
}
