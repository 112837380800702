import { Box, Grid } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { FormGen, FormTypes } from 'components/simple-form-gen'
import { IQuest } from 'modules/quests/models/quest'
import React, { useMemo, useState } from 'react'

export enum ActiveOptions {
  NONE = 'none',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

interface IQuestCustomFilterProps {
  handleChange: (settings: IFilterSetting) => void
  list: IQuest[]
  children?: JSX.Element
}

const activeOptions = [
  { name: 'Not set', value: ActiveOptions.NONE },
  { name: 'Yes', value: ActiveOptions.ACTIVE },
  { name: 'No', value: ActiveOptions.INACTIVE },
]

const useStyles = makeStyles(
  createStyles({
    filterWrapper: {
      marginBottom: 16,
    },
  }),
)

export interface IFilterSetting {
  title?: string
  active?: string
  scheduleAndSet?: string
  showNegativeSet: boolean
}

export const QuestCustomFilter = ({
  handleChange,
  list,
  children,
}: IQuestCustomFilterProps): JSX.Element => {
  const [title, setTitle] = useState('')
  const [active, setActive] = useState(ActiveOptions.NONE)
  const [scheduleAndSet, setScheduleAndSet] = useState('NONE')
  const [showNegativeSet, setShowNegativeSet] = useState<boolean>(false)
  const classes = useStyles()

  const scheduleAndSetOptions = useMemo(() => {
    const map = list.reduce(
      (acc, item) => ({
        ...acc,
        [`${item.schedule} ${item.set}`]: 1,
      }),
      {},
    )

    return [
      { name: 'Not set', value: 'NONE' },
      ...Object.keys(map)
        .sort()
        .map((val) => ({ name: val.replace(' ', ' set '), value: val })),
    ]
  }, [list.length])

  const onChangeTitle = (val: string): void => {
    setTitle(val)
    handleChange({ title: val, active, scheduleAndSet, showNegativeSet })
  }

  const onChangeActive = (val: string): void => {
    setActive(val as ActiveOptions)
    handleChange({ title, active: val, scheduleAndSet, showNegativeSet })
  }

  const onChangeScheduleAndSet = (val: string): void => {
    setScheduleAndSet(val)
    handleChange({ title, active, scheduleAndSet: val, showNegativeSet })
  }

  const onChangeShowNegativeSet = (val: boolean): void => {
    setShowNegativeSet(val)
    handleChange({ title, active, scheduleAndSet, showNegativeSet: !!val })
  }

  const elements = useMemo(() => {
    return [
      {
        value: title,
        handleChange: onChangeTitle,
        type: FormTypes.TEXT_INPUT,
        label: 'Title',
        name: 'title',
      },
      {
        value: active,
        handleChange: onChangeActive,
        type: FormTypes.SELECT,
        label: 'Active',
        name: 'active',
        options: activeOptions,
        hideInitialOption: true,
      },
      {
        value: scheduleAndSet,
        handleChange: onChangeScheduleAndSet,
        type: FormTypes.SELECT,
        label: 'Schedule and set',
        name: 'scheduleAndSet',
        options: scheduleAndSetOptions.filter((option) =>
          showNegativeSet ? true : !option.value.includes('-'),
        ),
        hideInitialOption: true,
      },
      {
        value: showNegativeSet,
        handleChange: onChangeShowNegativeSet,
        type: FormTypes.CHECKBOX,
        label: 'Show negative set',
        name: 'scheduleAndSet',
      },
    ]
  }, [showNegativeSet, active, scheduleAndSet, title, list.length])

  return (
    <Box className={classes.filterWrapper}>
      <Grid container spacing={2}>
        {elements.map((elementProps) => (
          <Grid item xs={6} key={elementProps.label}>
            <FormGen
              {...elementProps}

              handleChange={(e): void =>
                // @ts-expect-error decided later
                elementProps.handleChange(e.target.value)
              }
            />
          </Grid>
        ))}
        {children}
      </Grid>
    </Box>
  )
}
