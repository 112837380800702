import React from 'react'
import {
  Box,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SvgIcon,
  TextField,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { IChestFormProps } from 'modules/chests/models/entities/chest-form-props'
import { ChestFieldName } from 'modules/chests/enums/chest-field'
import { ChanceRewardForm } from 'modules/chests/components/manage-chest/chance-reward-form'
import { ChanceReward } from 'modules/chests/models/entities/chest'
import { ExclamationTriangle, PlusIcon } from 'components/icons'
import { createErrors } from 'utils/validation-helper'
import { RARITY_OPTIONS } from 'modules/chests/constants'
import { MediaItemInput } from 'modules/media-items/media-item-input'
import { AspectRatio } from 'types/common/images/enum/aspect-ratio'
import { MediaOrientation } from 'types/common/images/enum/media-orientation'
import { MediaUploadModel } from 'types/common/images/models/entities/image-upload-model'
import { MediaItem } from 'types/modules/media-items/models/entities/media-item'
import { useChestsContext } from 'modules/chests/context'
import _ from 'core/utils/deepdash'
import { DisplayMode } from 'types/common/enums/display-mode'
import { sumChanceRewardsProbability } from 'modules/chests/helper'
import { SaveBeforeWarning } from 'components/save-before-warning'

const useStyles = makeStyles(() =>
  createStyles({
    chestForm: {
      width: '100%',
    },
    imageWrapper: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: 250,
    },
    inputLabel: {
      display: 'flex',
      marginBottom: '0.625rem',
      marginTop: '0.625rem',
    },
    chanceRewardInputLabel: {
      display: 'flex',
      marginBottom: 0,
      marginTop: 0,
    },
    chanceRewardLabel: {
      marginTop: 20,
    },
    error: {
      color: '#f44336',
      marginTop: 3,
      fontWeight: 400,
      lineHeight: 1.66,
      letterSpacing: '0.0333em',
    },
    inputLabelAndProbability: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    totalProbabilityCount: {
      paddingTop: 15,
      fontSize: 14,
      color: 'green',
    },
    totalProbabilityCountError: {
      color: '#f44336',
    },
    imageHint: {
      marginTop: 8,
      color: 'red',
    },
    imageHintText: {
      marginLeft: 8,
    },
  }),
)

export const ChestForm: React.FC<IChestFormProps> = (
  props: IChestFormProps,
) => {
  const classes = useStyles()
  const {
    chest,
    handleChange,
    errors,
    addChanceReward,
    removeChanceReward,
    setChanceRewardFieldValue,
    displayMode,
  } = props

  const formErrors = createErrors(errors)
  const { uploadChestImage, updateChest } = useChestsContext()

  const chanceRewards =
    chest[ChestFieldName.CHEST_CONFIG][ChestFieldName.CHANCE_REWARDS]
  const totalProbabilityCount = sumChanceRewardsProbability(chanceRewards)

  return (
    <Box className={classes.chestForm}>
      <Grid container spacing={2}>
        <Grid item xs={4} className={classes.imageWrapper}>
          <InputLabel className={classes.inputLabel}>
            Open Chest Image
          </InputLabel>
          <MediaItemInput
            disabled={displayMode !== DisplayMode.Edit}
            url={chest.media?.imageUrl}
            imageLabel={'Chest Image'}
            mediaItemId={chest.media?.mediaItemId}
            options={{
              aspectRatio: AspectRatio.Square,
              orientation: MediaOrientation.Portrait,
            }}
            onComplete={(fileParams: MediaUploadModel): void => {
              uploadChestImage(chest.id!, fileParams)
            }}
            onCreate={(mediaItem: MediaItem): void => {
              const newChest = _.cloneDeep(chest)
              newChest.media = {
                ...(chest.media ?? {}),
                mediaItemId: mediaItem.id,
              }
              updateChest(newChest)
            }}
          />
          {displayMode !== DisplayMode.Edit && <SaveBeforeWarning />}
        </Grid>
        <Grid item xs={8}>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <InputLabel
                htmlFor={ChestFieldName.DESCRIPTION}
                className={classes.inputLabel}
              >
                Chest Item
              </InputLabel>
              <TextField
                id={ChestFieldName.DESCRIPTION}
                name={ChestFieldName.DESCRIPTION}
                onChange={handleChange as React.ChangeEventHandler<HTMLInputElement>}
                value={chest[ChestFieldName.DESCRIPTION]}
                variant="filled"
                fullWidth
                {...formErrors[ChestFieldName.DESCRIPTION]}
              />
            </Grid>
            <Grid item xs={4}>
              <InputLabel
                htmlFor={ChestFieldName.CHEST_RARITY}
                className={classes.inputLabel}
              >
                Rarity
              </InputLabel>
              <Select
                id={ChestFieldName.CHEST_RARITY}
                name={ChestFieldName.CHEST_RARITY}
                onChange={handleChange}
                value={chest[ChestFieldName.CHEST_RARITY]}
                variant="filled"
                fullWidth
                error={!!errors.chestRarity}
              >
                {RARITY_OPTIONS.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
              {errors.chestRarity && (
                <FormHelperText error>{errors.chestRarity}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={6}>
              <InputLabel
                htmlFor={ChestFieldName.MIN_REWARDS_IN_CHEST}
                className={classes.inputLabel}
              >
                Min reward
              </InputLabel>
              <TextField
                id={ChestFieldName.MIN_REWARDS_IN_CHEST}
                name={ChestFieldName.MIN_REWARDS_IN_CHEST}
                onChange={handleChange as React.ChangeEventHandler<HTMLInputElement>}
                value={chest[ChestFieldName.MIN_REWARDS_IN_CHEST]}
                variant="filled"
                fullWidth
                disabled
                type="number"
                {...formErrors[ChestFieldName.MIN_REWARDS_IN_CHEST]}
              />
            </Grid>
            <Grid item xs={6}>
              <InputLabel
                htmlFor={ChestFieldName.MAX_REWARDS_IN_CHEST}
                className={classes.inputLabel}
              >
                Max reward
              </InputLabel>
              <TextField
                id={ChestFieldName.MAX_REWARDS_IN_CHEST}
                name={ChestFieldName.MAX_REWARDS_IN_CHEST}
                onChange={handleChange as React.ChangeEventHandler<HTMLInputElement>}
                value={chest[ChestFieldName.MAX_REWARDS_IN_CHEST]}
                variant="filled"
                fullWidth
                disabled
                {...formErrors[ChestFieldName.MAX_REWARDS_IN_CHEST]}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className={classes.inputLabelAndProbability}>
            <InputLabel
              htmlFor={ChestFieldName.CHEST_CONFIG}
              className={classes.chanceRewardInputLabel}
            >
              <label className={classes.chanceRewardLabel}>
                Chance rewards
              </label>
              <IconButton onClick={(): void => addChanceReward()} size="large">
                <SvgIcon>
                  <PlusIcon />
                </SvgIcon>
              </IconButton>
            </InputLabel>
            <div
              className={`${classes.totalProbabilityCount} ${
                totalProbabilityCount !== 1
                  ? classes.totalProbabilityCountError
                  : ''
              }`}
            >
              Total probability {totalProbabilityCount}
            </div>
          </div>
          {!!errors?.totalProbability && (
            <div className={classes.error}>{errors.totalProbability}</div>
          )}
        </Grid>
        {chest[ChestFieldName.CHEST_CONFIG][ChestFieldName.CHANCE_REWARDS].map(
          (chanceReward: ChanceReward, index: number) => (
            <ChanceRewardForm
              chanceReward={chanceReward}
              key={chanceReward.id || index}
              removeChanceReward={removeChanceReward}
              setChanceRewardFieldValue={setChanceRewardFieldValue}
              namePrefix={`${ChestFieldName.CHEST_CONFIG}.${ChestFieldName.CHANCE_REWARDS}`}
              index={index}
              prizeRarityError={
                errors?.[ChestFieldName.CHEST_CONFIG]?.[
                  ChestFieldName.CHANCE_REWARDS
                ]?.[index]?.[ChestFieldName.PRIZE_RARITY]
              }
              prizeIdRequiredError={
                errors?.[ChestFieldName.CHEST_CONFIG]?.[
                  ChestFieldName.CHANCE_REWARDS
                ]?.[index]?.[ChestFieldName.PRIZE_ID]
              }
              probabilityError={
                errors?.[ChestFieldName.CHEST_CONFIG]?.[
                  ChestFieldName.CHANCE_REWARDS
                ]?.[index]?.[ChestFieldName.PROBABILITY]
              }
            />
          ),
        )}
      </Grid>
    </Box>
  );
}
