import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { ApprovePrizeButton } from 'modules/prizes/views/components/action-buttons/approve'
import { ClosePrizeButton } from 'modules/prizes/views/components/action-buttons/close'
import { DiscardPrizeButton } from 'modules/prizes/views/components/action-buttons/discard'
import { DuplicatePrizeButton } from 'modules/prizes/views/components/action-buttons/duplicate'
import { FeaturePrizeButton } from 'modules/prizes/views/components/action-buttons/feature'
import { PromotePrizeButton } from 'modules/prizes/views/components/action-buttons/promote'
import { ReactivatePrizeButton } from 'modules/prizes/views/components/action-buttons/reactivate'
import { RestorePrizeButton } from 'modules/prizes/views/components/action-buttons/restore'
import { ReviewPrizeButton } from 'modules/prizes/views/components/action-buttons/review'
import { SubmitPrizeButton } from 'modules/prizes/views/components/action-buttons/submit'
import { ViewPrizeButton } from 'modules/prizes/views/components/action-buttons/view'
import React, { ComponentType } from 'react'
import { PrizeActionButtonType } from 'types/modules/prizes/enums/prize-action-button-type'
import { IPrizeActionButtonProps } from 'types/modules/prizes/models/props/prize-action-button-props'
import { IPrizeActionButtonsProps } from 'types/modules/prizes/models/props/prize-action-buttons-props'

export const PrizeActionButtons: React.FC<IPrizeActionButtonsProps> = (
  props: IPrizeActionButtonsProps,
) => {
  const { prize, exclude, onPopoverClose } = props

  const renderButtons = (): JSX.Element => {
    const buttonsToRender: [
      PrizeActionButtonType,
      ComponentType<IPrizeActionButtonProps>,
    ][] = []

    Object.values(PrizeActionButtonType).forEach((buttonType) => {
      if (!IsNullOrUndefined(exclude) && exclude?.includes(buttonType)) {
        return
      }

      let currentButton: ComponentType<IPrizeActionButtonProps> | null = null

      switch (buttonType) {
        case PrizeActionButtonType.Approve: {
          currentButton = ApprovePrizeButton
          break
        }
        case PrizeActionButtonType.Close: {
          currentButton = ClosePrizeButton
          break
        }
        case PrizeActionButtonType.Discard: {
          currentButton = DiscardPrizeButton
          break
        }
        case PrizeActionButtonType.Duplicate: {
          currentButton = DuplicatePrizeButton
          break
        }
        case PrizeActionButtonType.Feature: {
          currentButton = FeaturePrizeButton
          break
        }
        case PrizeActionButtonType.Promote: {
          currentButton = PromotePrizeButton
          break
        }
        case PrizeActionButtonType.Reactivate: {
          currentButton = ReactivatePrizeButton
          break
        }
        case PrizeActionButtonType.Restore: {
          currentButton = RestorePrizeButton
          break
        }
        case PrizeActionButtonType.Review: {
          currentButton = ReviewPrizeButton
          break
        }
        case PrizeActionButtonType.Submit: {
          currentButton = SubmitPrizeButton
          break
        }
        case PrizeActionButtonType.View: {
          currentButton = ViewPrizeButton
          break
        }
      }
      if (!IsNullOrUndefined(currentButton)) {
        buttonsToRender.push([buttonType, currentButton!])
      }
    })

    return (
      <React.Fragment>
        {buttonsToRender.map(([buttonType, Component]) => (
          <Component
            key={buttonType}
            prize={prize}
            disabled={props.disabled}
            onPopoverClose={onPopoverClose}
          />
        ))}
      </React.Fragment>
    )
  }

  return !IsNullOrUndefined(prize) ? renderButtons() : null
}
