export const ControllerSegments = {
  ACCESS_DENIED: '/access-denied',
  ACCOUNT: '/account',
  POSTS: '/posts-old',
  QUESTIONS: '/question',
  RESULTS: '/result',
  MATCH: '/match',
  SHOWS: '/shows',
  LOYALTY: '/loyalty',
  TAGS: '/tags',
  POSTS_NEW: '/posts',
  PRIZES: '/prizes',
  EVENTS: '/events',
  WATCHLIST: '/watchlist',
  SHIPPING: '/shipping',
  INFO_CARDS: '/info-cards',
  CHESTS: '/chests',
  MOBILE_APP_USERS: '/mobile-app-users',
  QUESTS: '/quests',
  NEWS_CARDS: '/news-cards',
  IAP_PRODUCTS: '/iap-products',
  POST_GROUPS: '/post-groups',
  MILESTONES: '/milestones',
  CAMPAIGNS: '/campaigns',
}
