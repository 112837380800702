import React, { useState } from 'react'
import Modal from '@mui/material/Modal'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Button, Fade, Box } from '@mui/material'
import { Panel, PanelHeader, PanelContent, PanelFooter, PanelContainer } from 'components/panel'
import { TagPromoteForm } from 'modules/tags/views/tag-promote-form'
import { TagPromotionField } from 'types/modules/tags/enums/tag-promotion-fields'
import _ from 'core/utils/deepdash'
import { ITagPromotion } from 'types/modules/tags/models/entities/tag-promotion'
import { Tag } from 'types/modules/tags/models/entities/tag'
import { TagPromotionSchema } from 'modules/tags/validation/tag-promotion-schema'
import { useFormik } from 'formik'
import { useTypedSelector } from 'core/redux/utils'
import {
  getTagPromotion,
  getTagsByTagIds,
  getTagSuggestion,
} from 'modules/tags/selectors'
import { useDispatch } from 'react-redux'
import { Actions } from 'modules/tags'
import { TagSuggestionField } from 'types/modules/tags/enums/tag-suggestion-fields'
import { ITagSuggestion } from 'types/modules/tags/models/entities/tag-suggestion'
import { TagSuggestionForm } from 'modules/tags/views/tag-suggestion-form'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    footerContent: {
      display: 'flex',
      flex: 1,
      justifyContent: 'flex-end',
    },
    cancelBtn: {
      marginRight: '1.25rem',
    },
    tabContainer: {
      paddingLeft: 20,
      paddingTop: 10,
    },
  }),
)

export interface IPromoteModalProps {
  open: boolean
  handleOpen: () => void
  handleClose: () => void
}

export const TagPromoteModal: React.FC<IPromoteModalProps> = (
  props: IPromoteModalProps,
) => {
  const [tabValue, setTabValue] = useState(0)
  const classes = useStyles()
  const dispatch = useDispatch()

  const tagPromotion = useTypedSelector((state) => getTagPromotion(state))

  const tagSuggestion = useTypedSelector((state) => getTagSuggestion(state))

  const promotedTags = useTypedSelector((state) =>
    getTagsByTagIds(state, tagPromotion.tagIds),
  )

  const handleTabChange = (event: any, val: any): void => {
    setTabValue(val)
  }

  const tagPromotionForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      [TagPromotionField.PROMOTION_END_DATE]: tagPromotion.promotionEndDate,
      [TagPromotionField.TAGS]: promotedTags,
      [TagPromotionField.TAG_IDS]: tagPromotion.tagIds,
    },
    validationSchema: TagPromotionSchema,
    onSubmit: (values: ITagPromotion): void => {
      const promotion = _.cloneDeep(values)
      promotion.tagIds = (promotion.tags as Tag[]).map((tag) => tag.id!)
      const { tagIds, promotionEndDate } = promotion
      dispatch(
        Actions.updateTagPromotion({ item: { tagIds, promotionEndDate } }),
      )
      props.handleClose()
    },
  })

  const tagSuggestionForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      [TagSuggestionField.TAGS]: promotedTags,
      [TagSuggestionField.TAG_IDS]: tagPromotion.tagIds,
    },
    onSubmit: (values: ITagSuggestion): void => {
      const suggestion = _.cloneDeep(values)
      suggestion.tagIds = (suggestion.tags as Tag[]).map((tag) => tag.id!)
      const { tagIds } = suggestion
      dispatch(Actions.updateTagSuggestion({ items: tagIds }))
      props.handleClose()
    },
  })

  return (
    <div>
      <Modal
        className={classes.modal}
        open={props.open}
      >
        <Fade in={props.open}>
          <Panel item xs={5}>
            <PanelContainer>
              <Panel container xs={12}>
                <PanelHeader title="Promoted and Suggest Tags" />
                <PanelContent>
                  <Tabs value={tabValue} onChange={handleTabChange}>
                    <Tab label="Promoted tags" />
                    <Tab label="Suggested tag" />
                  </Tabs>
                  <Box className={classes.tabContainer}>
                    {tabValue === 0 ? (
                      <TagPromoteForm
                        setFieldValue={tagPromotionForm.setFieldValue}
                        tagPromotion={tagPromotionForm.values}
                        initialSelectedTags={promotedTags}
                      />
                    ) : (
                      <TagSuggestionForm
                        setFieldValue={tagSuggestionForm.setFieldValue}
                        initialSelectedTags={tagSuggestion.tags}
                        tagSuggestion={tagSuggestionForm.values}
                      />
                    )}
                  </Box>
                </PanelContent>
                <PanelFooter>
                  <Box className={classes.footerContent}>
                    <Button
                      size="large"
                      className={classes.cancelBtn}
                      onClick={props.handleClose}
                      variant="contained"
                      color="secondary"
                    >
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      disabled={tabValue === 0 && !tagPromotionForm.isValid}
                      size="large"
                      onClick={(): void => {
                        if (tabValue === 0) {
                          tagPromotionForm.submitForm()
                          return
                        }
                        tagSuggestionForm.submitForm()
                      }}
                      variant="contained"
                    >
                      Add Tag
                    </Button>
                  </Box>
                </PanelFooter>
              </Panel>
            </PanelContainer>
          </Panel>
        </Fade>
      </Modal>
    </div>
  )
}
