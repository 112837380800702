import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { RequestContextProvider } from 'core/api/context'
import { LiveScheduleContextProvider } from 'modules/live-schedules/context'
import { LiveScheduleDashboard } from 'modules/live-schedules/views/components/dashboard'
import React from 'react'
import { IEvent } from 'types/modules/events/models/entities/event'

interface IEventSchedulesProps {
  event: IEvent
}

export const EventSchedules: React.FC<IEventSchedulesProps> = (
  props: IEventSchedulesProps,
) => {
  return (
    <RequestContextProvider>
      <LiveScheduleContextProvider event={props.event}>
        <LiveScheduleDashboard />
      </LiveScheduleContextProvider>
    </RequestContextProvider>
  )
}
