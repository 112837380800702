import { useTypedSelector } from 'core/redux/utils'
import { useRouter } from 'core/routing/hooks/use-router'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { MilestoneActions, Selectors } from 'modules/milestones'
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
} from 'react'
import { useDispatch } from 'react-redux'
import { NIL as NIL_UUID } from 'uuid'
import { IMilestone } from 'modules/milestones/models/milestone'

export interface IMilestonesContext {
  currentMilestone: IMilestone | null
  setCurrentMilestone: (milestoneId: string) => void
  clearCurrentMilestone: () => void
  updateMilestone: (milestone: IMilestone) => void
}

const MilestonesContext = createContext<IMilestonesContext>(
  {} as IMilestonesContext,
)

export const MilestonesProvider: React.FC<PropsWithChildren<{}>> = (
  props: PropsWithChildren<{}>,
) => {
  const dispatch = useDispatch()

  const {
    routeParams: { milestoneId },
    pushQueryParams,
    removeQueryParams,
  } = useRouter()

  const currentMilestone = useTypedSelector((state) =>
    Selectors.getCurrentMilestone(state),
  )

  const setCurrentMilestone = (milestoneId: string): void => {
    pushQueryParams({
      milestoneId,
    })
  }

  const clearCurrentMilestone = (): void => {
    removeQueryParams('milestoneId')
  }


  const updateMilestone = (milestone: IMilestone): void => {
    dispatch(
      MilestoneActions.updateMilestone({
        item: milestone,
        milestoneId: milestone.id,
      }),
    )
  }

  useEffect(() => {
    dispatch(MilestoneActions.setCurrentMilestoneId({ milestoneId }))
    if (!IsNullOrUndefined(milestoneId) && milestoneId !== NIL_UUID) {
      dispatch(MilestoneActions.getMilestoneById({ milestoneId }))
    }
  }, [milestoneId])

  const context = {
    currentMilestone,
    setCurrentMilestone,
    clearCurrentMilestone,
    updateMilestone,
  }

  return (
    <MilestonesContext.Provider value={context}>
      {props.children}
    </MilestonesContext.Provider>
  )
}

export const useMilestonesContext = (): IMilestonesContext =>
  useContext(MilestonesContext)
