import React from 'react'

import AccountLayout from 'components/layouts/account-layout'
import DashboardLayout from 'components/layouts/dashboard-layout'

import { LoginForm } from 'modules/account/views/login-form'

import { RouteConfig } from 'types/common/routing/route-config'
import { RedirectController } from 'core/routing/components/redirect-controller'
import { PortalPermissions } from 'core/authorization/rules'
import TagAndGroupListView from 'modules/tags/views/tag+group-list-view'
import PostsDashboardPage from 'modules/posts/views/pages/dashboard-page'
import { ManagePostPage } from 'modules/posts/views/pages/manage-post-page'
import { WatchlistPage } from 'modules/watchlist/views/pages/watchlist'
import { EventsPage } from 'modules/events/views/pages/events'
import { SingleEventPage } from 'modules/events/views/pages/single-event'
import { WatchlistCategoryPage } from 'modules/watchlist/views/pages/category'
import { PrizesDashboardPage } from 'modules/prizes/views/pages/dashboard'
import { ShippingDashboardPage } from 'modules/shipping/views/pages/dashboard'
import { InfoCardsDashboardPage } from 'modules/info-cards/views/pages/dashboard'
import { MobileAppUserDashboard } from 'modules/mobile-app-users/views/mobile-app-user-dashboard'
import { QuestDashboard } from 'modules/quests/view/quest-dashboard'
import { PostGroupsPage } from 'modules/post-groups/view/pages/post-groups'
import { NewsCardDashboard } from 'modules/news-cards/view/news-card-dashboard'
import { MilestoneDashboard } from 'modules/milestones/view/milestone-dashboard'
import { IapProductDashboard } from 'modules/iap-products/view/iap-product-dashboard'
import { PostGroupPage } from 'modules/post-groups/view/pages/post-group'
import { CampaignDashboard } from 'modules/campaigns/view/campaign-dashboard'
import { RouteParams as RP } from './route-params'
import { ControllerSegments as CS } from './controller-segments'
import { ActionSegments as AS } from './action-segments'

export const POST_TYPES = {
  BET: '/bet',
  GAME: '/game',
  POLL: '/poll',
  TEST: '/test',
}

export const LOYALTY_TYPES = {
  SWEEPSTAKE: '/sweepstake',
  INSTANT: '/instant',
}

export const ROUTE_CONFIG: { [key: string]: RouteConfig } = {
  HOME: {
    path: `/`,
    component: <RedirectController />,
  },
  ACCOUNT_LOGIN: {
    path: `${CS.ACCOUNT}${AS.LOGIN}`,
    layout: AccountLayout,
    component: LoginForm,
    isPublic: true,
  },
  EVENTS_PAGE: {
    path: `${CS.EVENTS}${RP.DASHBOARD_VIEW}`,
    requiredParams: [RP.DASHBOARD_VIEW],
    layout: DashboardLayout,
    component: EventsPage,
    requiredPermissions: [PortalPermissions.DASHBOARD_VIEW],
  },
  EVENT_PAGE: {
    path: `${CS.EVENTS}${RP.EVENT_ID}${RP.EVENT_PAGE}${RP.DASHBOARD_VIEW}?`,
    requiredParams: [RP.EVENT_ID, RP.EVENT_PAGE, RP.DASHBOARD_VIEW],
    layout: DashboardLayout,
    component: SingleEventPage,
    requiredPermissions: [PortalPermissions.DASHBOARD_VIEW],
  },
  POST_GROUPS: {
    path: `${CS.POST_GROUPS}`,
    requiredParams: [RP.DASHBOARD_VIEW],
    layout: DashboardLayout,
    component: PostGroupsPage,
    requiredPermissions: [PortalPermissions.DASHBOARD_VIEW],
  },
  POST_GROUP: {
    path: `${CS.POST_GROUPS}${RP.POST_GROUP_ID}${RP.POST_GROUP_PAGE}${RP.DASHBOARD_VIEW}?`,
    requiredParams: [RP.POST_GROUP_ID],
    layout: DashboardLayout,
    component: PostGroupPage,
    requiredPermissions: [PortalPermissions.DASHBOARD_VIEW],
  },
  INFO_CARDS_DASHBOARD: {
    path: `${CS.INFO_CARDS}${RP.DASHBOARD_VIEW}?`,
    requiredParams: [RP.DASHBOARD_VIEW],
    layout: DashboardLayout,
    component: InfoCardsDashboardPage,
    requiredPermissions: [PortalPermissions.DASHBOARD_VIEW],
  },
  POSTS_DASHBOARD: {
    path: `${CS.POSTS_NEW}${RP.DASHBOARD_VIEW}`,
    requiredParams: [RP.DASHBOARD_VIEW],
    layout: DashboardLayout,
    component: PostsDashboardPage,
    requiredPermissions: [PortalPermissions.DASHBOARD_VIEW],
  },
  MANAGE_POST: {
    path: `${CS.POSTS_NEW}${RP.POST_ID}`,
    requiredParams: [RP.POST_ID],
    layout: DashboardLayout,
    component: ManagePostPage,
    requiredPermissions: [PortalPermissions.DASHBOARD_VIEW],
  },
  PRIZES_DASHBOARD: {
    path: `${CS.PRIZES}${RP.DASHBOARD_VIEW}`,
    requiredParams: [RP.DASHBOARD_VIEW],
    layout: DashboardLayout,
    component: PrizesDashboardPage,
    requiredPermissions: [PortalPermissions.DASHBOARD_VIEW],
  },
  SHIPPING_DASHBOARD: {
    path: `${CS.SHIPPING}${RP.DASHBOARD_VIEW}`,
    requiredParams: [RP.DASHBOARD_VIEW],
    layout: DashboardLayout,
    component: ShippingDashboardPage,
    requiredPermissions: [PortalPermissions.DASHBOARD_VIEW],
  },
  TAGS: {
    path: `${CS.TAGS}`,
    layout: DashboardLayout,
    component: TagAndGroupListView,
    requiredPermissions: [PortalPermissions.DASHBOARD_VIEW],
  },
  MOBILE_APP_USERS: {
    path: `${CS.MOBILE_APP_USERS}`,
    layout: DashboardLayout,
    component: MobileAppUserDashboard,
    requiredPermissions: [PortalPermissions.DASHBOARD_VIEW],
  },
  WATCHLIST: {
    path: `${CS.WATCHLIST}`,
    layout: DashboardLayout,
    component: WatchlistPage,
    requiredPermissions: [PortalPermissions.WATCHLIST_VIEW],
  },
  WATCHLIST_CATEGORY: {
    path: `${CS.WATCHLIST}${RP.TAG_CODE}`,
    layout: DashboardLayout,
    component: WatchlistCategoryPage,
    requiredPermissions: [PortalPermissions.WATCHLIST_VIEW],
  },
  QUESTS: {
    path: `${CS.QUESTS}`,
    layout: DashboardLayout,
    component: QuestDashboard,
    requiredPermissions: [PortalPermissions.DASHBOARD_VIEW],
  },

  NEWS_CARDS: {
    path: `${CS.NEWS_CARDS}`,
    layout: DashboardLayout,
    component: NewsCardDashboard,
    requiredPermissions: [PortalPermissions.DASHBOARD_VIEW],
  },

  MILESTONES: {
    path: `${CS.MILESTONES}`,
    layout: DashboardLayout,
    component: MilestoneDashboard,
    requiredPermissions: [PortalPermissions.DASHBOARD_VIEW],
  },

  CAMPAIGNS: {
    path: `${CS.CAMPAIGNS}`,
    layout: DashboardLayout,
    component: CampaignDashboard,
    requiredPermissions: [PortalPermissions.DASHBOARD_VIEW],
  },

  IAP_PRODUCTS: {
    path: `${CS.IAP_PRODUCTS}`,
    layout: DashboardLayout,
    component: IapProductDashboard,
    requiredPermissions: [PortalPermissions.DASHBOARD_VIEW],
  },
}

// Finish implementing LinkResolver component to replace this
export const ROUTES = {
  // Home
  HOME: `/`,
  POST_LIST: `/posts/list`,
  WATCHLIST: '/watchlist',
}
