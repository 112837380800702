import ContentDashboard from 'components/content-dashboard'
import { DashboardContextProvider } from 'components/content-dashboard/context'
import { IContentDashboardProps } from 'components/content-dashboard/props/content-dashboard-props'
import { useRequestContext } from 'core/api/context'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { PostCalendarDateGridCell } from 'modules/posts/views/components/dashboard/post-calendar-date-grid-cell'
import { PostCalendarTimeGridCell } from 'modules/posts/views/components/dashboard/post-calender-time-grid-cell'
import { PostListHeaderPanel } from 'modules/posts/views/components/dashboard/post-list-header-panel'
import { PostListItem } from 'modules/posts/views/components/dashboard/post-list-item'
import { PostListLegend } from 'modules/posts/views/components/dashboard/post-list-legend'
import { PostListToolbar } from 'modules/posts/views/components/dashboard/post-list-toolbar'
import React, { useCallback } from 'react'
import { PostField } from 'types/modules/posts/enums/post-field'
import { IPost } from 'types/modules/posts/models/entities/post'
import _ from 'core/utils/deepdash'
import SortDirection from 'types/common/enums/sort-direction'
import PostStatus from 'types/modules/posts/enums/post-status'
import { CustomPostFilters } from 'modules/posts/views/components/dashboard/custom-filters'
import { IFilter } from 'types/common/filtering/models/entities/IFilter'
import { SelectFilterMode } from 'types/common/filtering/enums/SelectFilterMode'
import { ImageIcon } from 'components/icons'
import { useRouter } from 'core/routing/hooks/use-router'
import { usePostListContext } from 'modules/posts/context/post-list'

export const PostList: React.FC<{}> = () => {
  const { posts, statusFilterConfig, initialiseDashboard } =
    usePostListContext()

  const { pushQueryParams } = useRouter()

  const rc = useRequestContext()

  const statusFilter = rc?.getFilterByField(PostField.STATUS)

  const onViewModeChange = React.useCallback(
    (viewMode: string) => {
      if (IsNullOrUndefined(statusFilter)) return

      // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
      const newSortValue = _.clone(rc?.sortValue!)
      const newStatusFilter = _.clone(statusFilter)

      switch (viewMode) {
        case 'list': {
          newSortValue.sortBy = PostField.START
          newSortValue.sortDirection = SortDirection.Descending

          newStatusFilter!.value = [PostStatus.Active]

          rc?.setSortValue(newSortValue)
          rc?.applyFilter(newStatusFilter!)
          break
        }

        case 'calendar': {
          newSortValue.sortBy = PostField.START
          newSortValue.sortDirection = SortDirection.Ascending

          newStatusFilter!.value = [
            PostStatus.Paused,
            PostStatus.Active,
            PostStatus.Scheduled,
            PostStatus.Pending,
          ]

          rc?.setSortValue(newSortValue)
          rc?.applyFilter(newStatusFilter!)

          break
        }

        default:
          return
      }
    },
    [statusFilter],
  )

  const onTabChange = useCallback(
    (value: IFilter['value'], mode: SelectFilterMode) => {
      const valueIsArray = Array.isArray(value)
      const valueAsArray = value as PostStatus[]

      if (IsNullOrUndefined(statusFilter) || !valueIsArray) return

      if (mode === SelectFilterMode.Multiple) {
        rc?.setActive(true)
        return
      }

      const status = valueAsArray[0]

      switch (status) {
        case PostStatus.Active:
        case PostStatus.Scheduled: {
          rc?.setSortValue({
            sortBy: PostField.START,
            sortDirection: SortDirection.Descending,
          })
          return
        }

        default: {
          rc?.setSortValue({
            sortBy: PostField.UPDATED_AT,
            sortDirection: SortDirection.Descending,
          })
          return
        }
      }
    },
    [statusFilter],
  )

  const dashboardProps: IContentDashboardProps<IPost> = {
    onDashboardMount: initialiseDashboard,
    onViewModeChange,
    onTabChange,
    tabFilterConfig: statusFilterConfig,
    customFilterComponent: <CustomPostFilters />,
    listOptions: {
      headerPanelComponent: <PostListHeaderPanel />,
      legendComponent: <PostListLegend />,
      listItemComponent: PostListItem,
      toolbarComponent: <PostListToolbar />,
    },
    calendarOptions: {
      toolbarComponent: <PostListToolbar />,
      timeGridCellComponent: PostCalendarTimeGridCell,
      dateGridCellComponent: PostCalendarDateGridCell,
      headerActionButton: {
        icon: <ImageIcon />,
        onClick: (date: string): void => {
          pushQueryParams({
            viewMedia: date,
          })
        },
      },
    },
    data: posts,
  }

  return (
    <DashboardContextProvider>
      <ContentDashboard {...dashboardProps} />
    </DashboardContextProvider>
  )
}
