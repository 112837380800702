import { Panel, PanelContainer } from 'components/panel'
import { RequestContextProvider } from 'core/api/context'
import { PostGroupsProvider } from 'modules/post-groups/context'
import React from 'react'
import { PostGroupDashboard } from 'modules/post-groups/view/components/post-group-dashboard'

export const PostGroupPage: React.FC<{}> = () => {
  return (
    <RequestContextProvider>
      <PostGroupsProvider>
        <PanelContainer>
          <Panel container xs={12}>
            <PostGroupDashboard />
          </Panel>
        </PanelContainer>
      </PostGroupsProvider>
    </RequestContextProvider>
  )
}
