import React from 'react'
import * as yup from 'yup'

import { useAuth } from 'core/authentication/hooks/use-auth'
import { Redirect } from 'react-router-dom'
import { ROUTES } from 'core/routing'
import { useFormik } from 'formik'
import { Button, Checkbox, FormControlLabel, Grid, InputLabel, TextField } from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const LoginSchema = yup.object({
  username: yup.string().required('username is required'),
  password: yup.string().required('password is required'),
})

interface ILoginCredentials {
  password?: string;
  username?: string;
  rememberMe?: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    inputLabel: {
      display: 'flex',
      marginBottom: '0.625rem',
      marginTop: '0.625rem',
      color: '#7a7878'
    },
    error: {
      color: 'red',
      fontSize: 12
    }
  })
);

export const LoginForm: React.FC = (props) => {
  const { isAuthenticated, initiateLogin, error: authError } = useAuth()
  const classes = useStyles();

  const onLogin = async (username: string, password: string): Promise<void> => {
    initiateLogin(username, password)
  }

  const form = useFormik({
    enableReinitialize: true,
    validationSchema: LoginSchema,
    initialValues: {
      username: '',
      password: '',
      rememberMe: true,
    },
    onSubmit: (values: ILoginCredentials): void => {
      onLogin(values.username || '', values.password || '')
    },
  })

  const onPressEnter = (event: React.KeyboardEvent): void => {
    if (event.keyCode === 13) {
      form.submitForm()
    }
  };

  return isAuthenticated
    ? <Redirect to={ROUTES.HOME} />
    : <Grid container spacing={2}>
      <Grid item xs={12}>
        <InputLabel htmlFor="username" className={classes.inputLabel}>
          Username
        </InputLabel>
        <TextField
          value={form.values.username}
          name="username"
          onChange={({ target: { value } }): void => {
            form.setFieldValue('username', value)
          }}
          variant="filled"
          fullWidth
          helperText={form.errors.username}
          error={!!form.errors.username}
          onKeyDown={onPressEnter}
        />
      </Grid>
      <Grid item xs={12}>
        <InputLabel htmlFor="password" className={classes.inputLabel}>
          Password
        </InputLabel>
        <TextField
          value={form.values.password}
          name="password"
          onChange={({ target: { value } }): void => {
            form.setFieldValue('password', value)
          }}
          type="password"
          variant="filled"
          fullWidth
          helperText={form.errors.password}
          error={!!form.errors.password}
          onKeyDown={onPressEnter}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          className={classes.inputLabel}
          control={
            <Checkbox
              size="small"
              checked={form.values.rememberMe}
              onClick={(): void => {
                form.setFieldValue('rememberMe', !form.values.rememberMe);
              }}
              name="rememberMe"
              color="primary"
            />
          }
          label="Remember me"
        />
      </Grid>
      {authError && <Grid item xs={12} className={classes.error}>
        {authError.message}
      </Grid>}
      <Grid item xs={12}>
      <Button
        color="secondary"
        onClick={form.submitForm}
        variant="contained"
        disabled={(!!form.errors.password || !!form.errors.username) && form.dirty}
      >
        Login
      </Button>
      </Grid>
    </Grid>
}
