import { MODULE_NAME } from 'modules/live-schedules/constants'

const ActionTypes = {
  GET_LIVE_SCHEDULES: `${MODULE_NAME}/GET_LIVE_SCHEDULES`,
  GET_LIVE_SCHEDULE_BY_ID: `${MODULE_NAME}/GET_LIVE_SCHEDULE_BY_ID`,
  CREATE_LIVE_SCHEDULE_DRAFT: `${MODULE_NAME}/CREATE_LIVE_SCHEDULE_DRAFT`,
  CREATE_LIVE_SCHEDULE: `${MODULE_NAME}/CREATE_LIVE_SCHEDULE`,
  UPDATE_LIVE_SCHEDULE: `${MODULE_NAME}/UPDATE_LIVE_SCHEDULE`,
  CLEAR_LIVE_SCHEDULES: `${MODULE_NAME}/CLEAR_LIVE_SCHEDULES`,
  SET_CURRENT_LIVE_SCHEDULE_ID: `${MODULE_NAME}/SET_CURRENT_LIVE_SCHEDULE_ID`,
}

export default ActionTypes
