import { MODULE_NAME } from 'modules/chests/constants'
import { IKeyValueStringCollection } from 'types/common/interfaces/IKeyValueStringCollection'

export const ActionTypes: IKeyValueStringCollection = {
  GET_CHEST: `${MODULE_NAME}/GET_CHEST`,
  GET_CHESTS: `${MODULE_NAME}/GET_CHESTS`,
  CREATE_CHEST: `${MODULE_NAME}/CREATE_CHEST`,
  UPDATE_CHEST: `${MODULE_NAME}/UPDATE_CHEST`,
  CREATE_CHEST_DRAFT: `${MODULE_NAME}/CREATE_CHEST_DRAFT`,
  UPLOAD_CHEST_IMAGE: `${MODULE_NAME}/UPLOAD_CHEST_IMAGE`,
  SET_CURRENT_CHEST_ID: `${MODULE_NAME}/SET_CURRENT_CHEST_ID`,
  GET_AUTOCOMPLETE_SEARCH_CHESTS: `${MODULE_NAME}/GET_AUTOCOMPLETE_SEARCH_CHESTS`,
}
