import { MODULE_NAME } from 'modules/leaderboards/constants'

const ActionTypes = {
  SET_CURRENT_LEADERBOARD_ID: `${MODULE_NAME}/SET_CURRENT_LEADERBOARD`,
  GET_LEADERBOARD_POSITION_REWARDS: `${MODULE_NAME}/GET_LEADERBOARD_POSITION_REWARDS`,
  CREATE_LEADERBOARD_POSITION_REWARD: `${MODULE_NAME}/CREATE_LEADERBOARD_POSITION_REWARD`,
  UPDATE_LEADERBOARD_POSITION_REWARD: `${MODULE_NAME}/UPDATE_LEADERBOARD_POSITION_REWARD`,
}

export default ActionTypes
