export const availableTagItems = [
  {
    tagGroups: [],
    id: '394927c2-5b19-11eb-afee-06a405c23a70',
    code: 'Zayn',
    name: 'Zayn',
    media: { icon: '👠' },
    level: 2,
  },
  {
    tagGroups: [],
    id: '3b597e54-5b19-11eb-aff5-06a405c23a70',
    code: 'Zac Efron',
    name: 'Zac Efron',
    media: { icon: '👠' },
    level: 2,
  },
  {
    tagGroups: [],
    id: '2536b4a4-ac08-11ea-92f4-06a405c23a70',
    code: 'Youtube',
    name: 'Youtube',
    level: 2,
  },
  {
    tagGroups: [
      {
        name: 'PostManGroup',
        tagGroupId: '2f9222a2-55af-11eb-a98a-06a405c23a70',
      },
      { name: 'foofar x1', tagGroupId: 'b8a5233c-54e2-11eb-a69b-06a405c23a70' },
      {
        name: 'PostManGroup Update1',
        tagGroupId: 'c017bc20-54e6-11eb-bc85-06a405c23a70',
      },
      { name: 'foofar 4', tagGroupId: 'a952ee14-54e2-11eb-a697-06a405c23a70' },
    ],
    id: '0186d768-ac09-11ea-9306-06a405c23a70',
    code: 'Yoga',
    name: 'Yoga',
    level: 2,
  },
  {
    tagGroups: [],
    id: '01957200-ac09-11ea-9308-06a405c23a70',
    code: 'Wimbledon',
    name: 'Wimbledon',
    level: 2,
  },
  {
    tagGroups: [
      { name: 'Group 1', tagGroupId: 'e4ebf6e2-54e1-11eb-a689-06a405c23a70' },
    ],
    id: '5b254c7c-e2ba-11ea-94d8-06a405c23a70',
    code: 'Wedding',
    name: 'Wedding',
    level: 2,
  },
  {
    tagGroups: [],
    id: '224c027a-0262-11eb-bc38-06a405c23a70',
    code: 'We asked 100',
    name: 'We asked 100',
    level: 2,
  },
  {
    tagGroups: [
      { name: 'Group 1', tagGroupId: 'e4ebf6e2-54e1-11eb-a689-06a405c23a70' },
    ],
    id: 'a3ff5c34-b6d3-11ea-9776-06a405c23a70',
    code: 'Tv',
    name: 'TV',
    media: { icon: '📺' },
    level: 1,
  },
  {
    tagGroups: [],
    id: '03899f22-b10d-4ed3-b506-474686ab6ecd',
    code: 'Trending',
    name: 'Trending',
    media: { icon: '🔥' },
    level: 1,
  },
  {
    tagGroups: [
      { name: 'Group 1', tagGroupId: 'e4ebf6e2-54e1-11eb-a689-06a405c23a70' },
    ],
    id: 'b74d68ac-5b16-11eb-afe1-06a405c23a70',
    code: 'Travel',
    name: 'Travel',
    media: { icon: '👠' },
    level: 2,
  },
  {
    tagGroups: [],
    id: '2528dc58-ac08-11ea-92f2-06a405c23a70',
    code: 'TikTok',
    name: 'TikTok',
    level: 2,
  },
  {
    tagGroups: [],
    id: '65bb8f56-d023-11ea-9e06-06a405c23a70',
    code: 'Retro',
    name: 'Throwback',
    media: { icon: '🕒' },
    level: 1,
  },
  {
    tagGroups: [
      { name: 'Group 3', tagGroupId: 'fe22052a-54e1-11eb-a68c-06a405c23a70' },
    ],
    id: '33a62296-5b1b-11eb-afff-06a405c23a70',
    code: 'realH',
    name: 'The real Housewives',
    media: { icon: '👠' },
    level: 2,
  },
  {
    tagGroups: [
      { name: 'Group 3', tagGroupId: 'fe22052a-54e1-11eb-a68c-06a405c23a70' },
    ],
    id: '81bd3876-5b1a-11eb-affe-06a405c23a70',
    code: 'TOWIS',
    name: 'The Only Way is Essex',
    media: { icon: '👠' },
    level: 2,
  },
  {
    tagGroups: [
      { name: 'foofar 6', tagGroupId: '1b08a70a-54e4-11eb-979d-06a405c23a70' },
      {
        name: 'PostManGroup',
        tagGroupId: '2f9222a2-55af-11eb-a98a-06a405c23a70',
      },
      { name: 'foofar 3', tagGroupId: '31973a92-54e2-11eb-a68f-06a405c23a70' },
      { name: 'foofar x1', tagGroupId: 'b8a5233c-54e2-11eb-a69b-06a405c23a70' },
      { name: 'foofar 8', tagGroupId: '603d5a5c-54e7-11eb-874e-06a405c23a70' },
      { name: 'foofar 5', tagGroupId: '735a44d2-54e3-11eb-a0e2-06a405c23a70' },
      { name: 'foofar 7', tagGroupId: '8243889a-54e4-11eb-99b4-06a405c23a70' },
      {
        name: 'PostManGroup Update1',
        tagGroupId: 'c017bc20-54e6-11eb-bc85-06a405c23a70',
      },
      { name: 'foofar 4', tagGroupId: 'a952ee14-54e2-11eb-a697-06a405c23a70' },
    ],
    id: '017fd382-ac09-11ea-9305-06a405c23a70',
    code: 'The MET Gala',
    name: 'The MET Gala',
    level: 2,
  },
  {
    tagGroups: [
      { name: 'Group 3', tagGroupId: 'fe22052a-54e1-11eb-a68c-06a405c23a70' },
    ],
    id: '36543096-5b1b-11eb-b001-06a405c23a70',
    code: 'maskedSinger',
    name: 'The Masked Singer',
    media: { icon: '👠' },
    level: 2,
  },
  {
    tagGroups: [
      { name: 'Group 3', tagGroupId: 'fe22052a-54e1-11eb-a68c-06a405c23a70' },
    ],
    id: '357b5e10-5b1b-11eb-b000-06a405c23a70',
    code: 'theC',
    name: 'The Circle',
    media: { icon: '👠' },
    level: 2,
  },
  {
    tagGroups: [
      { name: 'Group 3', tagGroupId: 'fe22052a-54e1-11eb-a68c-06a405c23a70' },
    ],
    id: '259e2e18-ac08-11ea-9303-06a405c23a70',
    code: 'The Bachelor',
    name: 'The Bachelor',
    media: {
      icon: '🌹',
      imageUrl:
        'https://goss-content-resources.s3.eu-west-2.amazonaws.com/images/app/categories/The+Bachelor.png',
    },
    level: 2,
  },
  {
    tagGroups: [],
    id: 'c1871ade-094a-11eb-bc7a-06a405c23a70',
    code: 'Test',
    name: 'Test',
    media: { icon: '🦄' },
    level: 1,
  },
  {
    tagGroups: [],
    id: '2d305460-5b19-11eb-afeb-06a405c23a70',
    code: 'Taylor Swift',
    name: 'Taylor Swift',
    media: { icon: '👠' },
    level: 2,
  },
]