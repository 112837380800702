import ActionTypes from 'modules/quests/action-types/evergreen-quest-action-types'
import { Reducer } from 'redux'
import { actionTypeSuccess, cacheItem, cacheItems } from 'core/redux/utils'
import { ISimpleAction, ISuccessAction } from 'types/redux/interfaces/IAction'
import _ from 'core/utils/deepdash'
import { mergeArrays } from 'core/utils/mergeArrays'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { IEvergreenQuestActionParams } from 'modules/quests/actions/evergreen-actions'
import { NIL as NIL_UUID } from 'uuid'
import { IEvergreenQuestState } from 'modules/quests/models/state'
import { IEvergreenQuest } from 'modules/quests/models/evergreen-quest'

const pageSize = 20

const initialState: IEvergreenQuestState = {
  count: 0,
  page: 0,
  pageSize: pageSize,
  items: [],
  cache: {},
}

export type GetEvergreenQuestsApiResponse = {
  count: number
  page: number
  pageSize: number
  items: IEvergreenQuest[]
}

type AllowedActionTypes = ISuccessAction | ISimpleAction

const handleUpdate = (state: IEvergreenQuestState,
    action: AllowedActionTypes): IEvergreenQuestState => {
  const successAction = action as ISuccessAction
    const payloadItem = successAction.data as IEvergreenQuest
    const itemIndex = state.items.findIndex((x) => x.id === payloadItem.id)

    return {
      ...state,
      items:
        itemIndex >= 0
          ? state.items.map((item, index) =>
              index === itemIndex ? payloadItem : item,
            )
          : [...state.items, payloadItem],
      cache: cacheItem(state.cache, payloadItem),
    }
}

const actionHandlers = {
  [ActionTypes.SET_CURRENT_EVERGREEN_QUEST_ID]: (
    state: IEvergreenQuestState,
    action: AllowedActionTypes,
  ): IEvergreenQuestState => {
    const simpleAction = action as ISimpleAction
    const { evergreenQuestId } =
      simpleAction.payload as IEvergreenQuestActionParams
    return {
      ...state,
      currentEvergreenQuestId: evergreenQuestId ? evergreenQuestId : undefined,
    }
  },
  [ActionTypes.CREATE_EVERGREEN_QUEST_DRAFT]: (
    state: IEvergreenQuestState,
    action: AllowedActionTypes,
  ): IEvergreenQuestState => {
    const simpleAction = action as ISimpleAction
    const { item } = simpleAction.payload as IEvergreenQuestActionParams

    return {
      ...state,
      currentEvergreenQuestId: NIL_UUID,
      items: [item as IEvergreenQuest, ...state.items],
    }
  },
  [actionTypeSuccess(ActionTypes.GET_EVERGREEN_QUESTS)]: (
    state: IEvergreenQuestState,
    action: AllowedActionTypes,
  ): IEvergreenQuestState => {
    const successAction = action as ISuccessAction
    const response = successAction.data as IEvergreenQuest[]
    return {
      ...state,
      items: mergeArrays(state.items, response),
      cache: cacheItems(state.cache, response),
    }
  },
  [actionTypeSuccess(ActionTypes.GET_EVERGREEN_QUEST_BY_ID)]: (
    state: IEvergreenQuestState,
    action: AllowedActionTypes,
  ): IEvergreenQuestState => {
    const successAction = action as ISuccessAction
    const response = successAction.data as IEvergreenQuest
    return {
      ...state,
      cache: cacheItem(state.cache, response),
    }
  },
  [actionTypeSuccess(ActionTypes.CREATE_EVERGREEN_QUEST)]: (
    state: IEvergreenQuestState,
    action: AllowedActionTypes,
  ): IEvergreenQuestState => {
    const successAction = action as ISuccessAction
    const responseItem = successAction.data as IEvergreenQuest

    const { items } = state

    return {
      ...state,
      items: [responseItem, ...items],
      cache: cacheItem(state.cache, responseItem),
    }
  },
  [actionTypeSuccess(ActionTypes.UPDATE_EVERGREEN_QUEST)]: (
    state: IEvergreenQuestState,
    action: AllowedActionTypes,
  ): IEvergreenQuestState => handleUpdate(state, action),
  [actionTypeSuccess(ActionTypes.CREATE_EVERGREEN_QUEST_LEVEL)]: (
    state: IEvergreenQuestState,
    action: AllowedActionTypes,
  ): IEvergreenQuestState => handleUpdate(state, action),
  [actionTypeSuccess(ActionTypes.UPDATE_EVERGREEN_QUEST_LEVEL)]: (
    state: IEvergreenQuestState,
    action: AllowedActionTypes,
  ): IEvergreenQuestState => handleUpdate(state, action),
  [actionTypeSuccess(ActionTypes.UPLOAD_EVERGREEN_QUEST_IMAGE)]: (
    state: IEvergreenQuestState,
    action: AllowedActionTypes,
  ): IEvergreenQuestState => handleUpdate(state, action)
}

const evergreenQuestsReducer: Reducer<IEvergreenQuestState, AllowedActionTypes> = (
  state = initialState,
  action: AllowedActionTypes,
) => {
  return actionHandlers[action.type]
    ? actionHandlers[action.type](state, action)
    : state
}

export default evergreenQuestsReducer
