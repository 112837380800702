import EvergreenQuestEndpoints from 'core/api/endpoints/evergreen-quests'
import EvergreenQuestActionTypes from 'modules/quests/action-types/evergreen-quest-action-types'
import { createAction, createAsyncAction } from 'core/redux/utils'
import { IAsyncAction, ISimpleAction } from 'types/redux/interfaces/IAction'
import { IAsyncActionParams } from 'types/common/api/models/entities/async-action-params'
import { IEvergreenQuest, IEvergreenQuestLevel } from 'modules/quests/models/evergreen-quest'

export interface IEvergreenQuestActionParams
  extends IAsyncActionParams<IEvergreenQuest | IEvergreenQuestLevel> {
  evergreenQuestId?: string
  evergreenQuestLevelId?: string
}

export const setCurrentEvergreenQuestId: (
  params: IEvergreenQuestActionParams,
) => ISimpleAction = (params) =>
  createAction(EvergreenQuestActionTypes.SET_CURRENT_EVERGREEN_QUEST_ID, params)

export const getEvergreenQuests: (
  params: IEvergreenQuestActionParams,
) => IAsyncAction = (params) =>
  createAsyncAction({
    type: EvergreenQuestActionTypes.GET_EVERGREEN_QUESTS,
    endpoint: EvergreenQuestEndpoints.getEvergreenQuests,
    payload: params,
  })

export const getEvergreenQuestById: (
  params: IEvergreenQuestActionParams,
) => IAsyncAction = (params) =>
  createAsyncAction({
    type: EvergreenQuestActionTypes.GET_EVERGREEN_QUEST_BY_ID,
    endpoint: EvergreenQuestEndpoints.getEvergreenQuestById,
    payload: params,
  })

export const createEvergreenQuestDraft: (
  params: IEvergreenQuestActionParams,
) => ISimpleAction = (params) =>
  createAction(EvergreenQuestActionTypes.CREATE_EVERGREEN_QUEST_DRAFT, params)

export const createEvergreenQuest: (
  params: IEvergreenQuestActionParams,
) => IAsyncAction = (params) =>
  createAsyncAction({
    type: EvergreenQuestActionTypes.CREATE_EVERGREEN_QUEST,
    endpoint: EvergreenQuestEndpoints.createEvergreenQuest,
    payload: params,
  })

export const updateEvergreenQuest: (
  params: IEvergreenQuestActionParams,
) => IAsyncAction = (params) =>
  createAsyncAction({
    type: EvergreenQuestActionTypes.UPDATE_EVERGREEN_QUEST,
    endpoint: EvergreenQuestEndpoints.updateEvergreenQuest,
    payload: params,
  })

export const createEvergreenQuestLevel: (
  params: IEvergreenQuestActionParams,
) => IAsyncAction = (params) =>
  createAsyncAction({
    type: EvergreenQuestActionTypes.CREATE_EVERGREEN_QUEST_LEVEL,
    endpoint: EvergreenQuestEndpoints.createEvergreenQuestLevel,
    payload: params,
  })

export const updateEvergreenQuestLevel: (
  params: IEvergreenQuestActionParams,
) => IAsyncAction = (params) =>
  createAsyncAction({
    type: EvergreenQuestActionTypes.UPDATE_EVERGREEN_QUEST_LEVEL,
    endpoint: EvergreenQuestEndpoints.updateEvergreenQuestLevel,
    payload: params,
  })

export const uploadEvergreenQuestImage: (params: IEvergreenQuestActionParams) => IAsyncAction = (
  params,
) =>
  createAsyncAction({
    type: EvergreenQuestActionTypes.UPLOAD_EVERGREEN_QUEST_IMAGE,
    endpoint: EvergreenQuestEndpoints.uploadEvergreenQuestImage,
    payload: params,
  })
