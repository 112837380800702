import React, { useState } from 'react'
import { Box, ButtonBase, List, ListItem, ListItemText, Popover, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import clsx from 'clsx'
import { useDispatch } from 'react-redux'
import { changePageSize } from 'modules/common/actions'
import { getPageSize } from 'modules/common/selectors'
import { useTypedSelector } from 'core/redux/utils'
import { PAGE_SIZE_KEY } from 'modules/common/constant'


const options = [
  { name: '5', value: 5 },
  { name: '10', value: 10 },
  { name: '20', value: 20 },
  { name: '50', value: 50 },
  { name: '100', value: 100 },
  { name: '200', value: 200 },
]

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    '@global': {
      '@keyframes openMenu': {
        '0%': {
          maxHeight: 65,
        },
        '100%': {
          boxShadow: '1px 3px 5px rgba(0, 0, 0, 0.3)',
          maxHeight: 145,
        },
      },
    },
    logo: {
      display: 'flex',
      width: '100%',
      height: '30px',
      marginTop: '7px',
      '& svg': {
        fill: theme.palette.primary.contrastText,
        height: '100%',
        fontSize: 90,
      },
    },
    userAccountMenu: {
      top: 0,
      borderLeft: '1px solid rgba(51, 51, 51, 0.2)',
      flexDirection: 'column',
      maxHeight: 65,
      overflow: 'hidden',
      transition: theme.transitions.create(['box-shadow', 'max-height'], {
        duration: theme.transitions.duration.standard,
      }),
      '&.open': {
        border: 'none',
        boxShadow: '1px 5px 10px rgba(0, 0, 0, 0.3)',
        maxHeight: 210,
        transition: theme.transitions.create(['box-shadow', 'max-height'], {
          duration: theme.transitions.duration.standard,
        }),
      },
      [theme.breakpoints.down('sm')]: {
        border: 0,
      },
    },
    headerTab: {
      display: 'flex',
      height: '100%',
      padding: '10px 20px',
      alignItems: 'center',
      cursor: 'pointer',
      [theme.breakpoints.down('sm')]: {
        padding: 10,
      },
    },
    username: {
      display: 'flex',
      flex: 1,
    },
    userDropdownLinks: {
      background: theme.palette.background.paper,
      width: '100%',
      padding: 0,
    },
    userDropdownLinkButton: {
      padding: '14px 20px',
    },
    userDropdownLinkText: {
      marginTop: 6,
      marginBottom: 2,
      color: theme.palette.text.primary,
    },
    dropdownArrow: {
      transform: 'rotate(0deg)',
      transition: theme.transitions.create(['transform'], {
        duration: theme.transitions.duration.standard,
      }),
      '&.open': {
        transform: 'rotate(180deg)',
        transition: theme.transitions.create(['transform'], {
          duration: theme.transitions.duration.standard,
        }),
      },
    },
  }),
)


export const PageSizeSelect = (): JSX.Element => {
  const initialPageSize = useTypedSelector((state) => getPageSize(state))
  const [pageSize, setPageSize] = useState(initialPageSize)
  const [menuOpen, setMenuOpen] = useState(false)
  const dispatch = useDispatch()

  const classes = useStyles()

  const dropdownClasses = clsx(classes.userAccountMenu, menuOpen && 'open')

  const dropdownMenuClasses = clsx(
    classes.userDropdownLinks,
    menuOpen && 'open',
  )

  const dropdownArrowClasses = clsx(classes.dropdownArrow, menuOpen && 'open')

  const closeMenu = (): void => setMenuOpen(false)

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const onChangePageSize = (value: number): void => {
    localStorage.setItem(PAGE_SIZE_KEY, `${value}`)
    setPageSize(value)
    dispatch(changePageSize({ pageSize: value }))
    closeMenu()
    setAnchorEl(null)
  }

  const handlePopoverClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ): void => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = (): void => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'user-account-menu' : undefined


  return <Box>
    <Box className={dropdownClasses} onMouseLeave={closeMenu}>
      <ButtonBase onClick={handlePopoverClick}>
        <Box className={classes.headerTab}>
          <>
            <Typography className={classes.username} variant="body1">
              Size - {pageSize}
            </Typography>
            <ArrowDropDownIcon className={dropdownArrowClasses} />
          </>
        </Box>
      </ButtonBase>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        marginThreshold={0}
      >
        <List className={dropdownMenuClasses}>
          {
            options.map(({ value, name }) => <ListItem
              button
              key={name}
              onClick={(): void => onChangePageSize(value)}
              className={classes.userDropdownLinkButton}
            >
              <ListItemText
                primary={name}
                className={classes.userDropdownLinkText}
              />
            </ListItem>)
          }
        </List>
      </Popover>
    </Box>
  </Box>
}