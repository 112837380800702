import { Fade, Modal, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { MediaHandler } from 'modules/media-items/media-item-input/media-handler'
import { Panel } from 'components/panel'
import React from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 40,
    },
  }),
)

export interface IMediaItemInputProps {
  open: boolean
}

export const ManageMediaItemModal: React.FC<IMediaItemInputProps> = ({
  open,
}) => {
  const classes = useStyles()
  return (
    <div>
      <Modal
        className={classes.modal}
        open={open}
        closeAfterTransition
      >
        <Fade in={open}>
          <Panel
            xs={12}
            style={{
              display: 'flex',
              flex: 1,
              height: '100%',
              maxHeight: 800,
              maxWidth: 1200,
              zIndex: 1240,
            }}
          >
            <MediaHandler />
          </Panel>
        </Fade>
      </Modal>
    </div>
  )
}
