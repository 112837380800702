export enum ParameterTypes {
  CURRENCY = 'currency',
  TAGS = 'tags'
}

export enum QuestTaskField {
  ID = 'id',
  CODE = 'code',
  QUANTITY = 'quantity',
  PARAMETERS = 'parameters'
}

export enum QuestRewardField {
  ID = 'id',
  TYPE = 'type',
  CURRENCY = 'currency',
  REWARD_TEXT = 'rewardText',
  PRIZE_ID = 'prizeId',
  AMOUNT = 'amount',
}

export enum QuestField {
  ID = 'id',
  CODE = 'code',
  SET = 'set',
  QUEST_TEXT = 'questText',
  QUEST_TASKS = 'questTasks',
  QUEST_REWARDS = 'questRewards',
  SCHEDULE = 'schedule',
  CREATED_AT = 'createdAt',
  MEDIA = 'media',
  DESCRIPTION = 'description',
  TITLE = 'title',
  ACTIVE = 'active'
}

export enum QuestMediaField {
  IMAGE_URL = 'imageUrl',
  MEDIA_ITEM_ID = 'mediaItemId',
  DESCRIPTION = 'description',
  TITLE = 'title'
}

export enum QuestFormTypes {
  MAIN,
  REWARDS,
  TASKS
}

export enum QuestRewardTypes {
  CURRENCY = 'Currency',
  PRIZE = 'Prize',
}

export enum QuestRewardCurrency {
  STAR = 'Star',
  COIN = 'Coin'
}