import { Avatar, Box, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { GossEditorProvider } from 'components/goss-editor/context'
import { ElementType } from 'components/goss-editor/types/element-type'
import { RichTextDisplay } from 'components/goss-editor/views/rich-text-display'
import { useAuth } from 'core/authentication/hooks/use-auth'
import { IsNullUndefinedOrEmpty } from 'core/utils/isNullOrUndefined'
import { titleCase } from 'core/utils/titleCase'
import { getStatusLabel } from 'modules/shipping/utils/get-status-label'
import moment from 'moment-timezone'
import React from 'react'
import { IUserPrizeHistory } from 'types/modules/shipping/models/entities/user-prize-history'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    historyFeedItem: {
      display: 'flex',
      width: '100%',
      height: 'auto',
      flexDirection: 'row',
      '&:nth-child(n+2)': {
        paddingTop: '1.25rem',
        marginTop: '1.25rem',
        borderTop: '1px solid rgba(0,0,0,0.12)',
      },
    },
    leftSection: {
      display: 'flex',
      height: '100%',
      marginRight: 16,
    },
    avatar: {
      display: 'flex',
      width: 40,
      height: 40,
      fontSize: '1.25rem',
      fontWeight: 500,
      backgroundColor: 'rgba(0,0,0,0.14)',
    },
    mainSection: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
    },
    headerSection: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      height: 40,
    },
    usernameSection: {
      flex: 1,
    },
    username: {},
    dateSection: {
      flex: 1,
    },
    metadataSection: {
      textAlign: 'right',
      flex: 1,
    },
    commentSection: {
      paddingBottom: '0.625rem',
      display: 'flex',
      width: '100%',
      height: 'auto',
    },
  }),
)

export const UserPrizeHistoryItem: React.FC<IUserPrizeHistory> = (
  historyItem: IUserPrizeHistory,
) => {
  const classes = useStyles()

  const renderDate = (date: string): string => {
    const dateFormat = 'D MMMM YYYY HH:mm'
    return moment(date).format(dateFormat)
  }

  const createdBy = !IsNullUndefinedOrEmpty(historyItem.createdBy)
    ? historyItem.createdBy
    : 'Goss'

  const createdByInitials = createdBy.substr(0, 1)

  let noteValue = null

  if (!IsNullUndefinedOrEmpty(historyItem.note)) {
    try {
      noteValue = JSON.parse(historyItem.note)
    } catch (error) {
      noteValue = [
        {
          type: ElementType.Paragraph,
          children: [
            {
              text: historyItem.note,
            },
          ],
        },
      ]
    }
  } else {
    noteValue = null
  }

  return (
    <Box className={classes.historyFeedItem}>
      <Box className={classes.leftSection}>
        <Avatar className={classes.avatar} variant={'circular'}>
          {createdByInitials.toUpperCase()}
        </Avatar>
      </Box>
      <Box className={classes.mainSection}>
        <Box className={classes.headerSection}>
          <Box className={classes.usernameSection}>
            <Typography className={classes.username} variant="body1">
              {titleCase(createdBy)}
            </Typography>
          </Box>
          <Box className={classes.dateSection}>
            <Typography className={classes.username} variant="body2">
              {renderDate(historyItem.createdAt)}
            </Typography>
          </Box>
          <Box className={classes.metadataSection}>
            <Typography variant="body2" color="primary">
              {getStatusLabel(historyItem.status)}
            </Typography>
          </Box>
        </Box>
        <Box className={classes.commentSection}>
          <GossEditorProvider
            initialValue={noteValue}
            editableProps={{
              spellCheck: true,
              autoFocus: true,
              readOnly: true,
            }}
            onSubmit={(): void => {
              return
            }}
          >
            <RichTextDisplay />
          </GossEditorProvider>
        </Box>
      </Box>
    </Box>
  )
}
