import { EVERGREEN_QUEST_MODULE_NAME } from 'modules/quests/constants'

const ActionTypes = {
  SET_CURRENT_EVERGREEN_QUEST_ID: `${EVERGREEN_QUEST_MODULE_NAME}/SET_CURRENT_EVERGREEN_QUEST_ID`,
  GET_EVERGREEN_QUESTS: `${EVERGREEN_QUEST_MODULE_NAME}/GET_EVERGREEN_QUESTS`,
  GET_EVERGREEN_QUEST_BY_ID: `${EVERGREEN_QUEST_MODULE_NAME}/GET_EVERGREEN_QUEST_BY_ID`,
  CREATE_EVERGREEN_QUEST: `${EVERGREEN_QUEST_MODULE_NAME}/CREATE_EVERGREEN_QUEST`,
  CREATE_EVERGREEN_QUEST_DRAFT: `${EVERGREEN_QUEST_MODULE_NAME}/CREATE_EVERGREEN_QUEST_DRAFT`,
  UPDATE_EVERGREEN_QUEST: `${EVERGREEN_QUEST_MODULE_NAME}/UPDATE_EVERGREEN_QUEST`,
  CREATE_EVERGREEN_QUEST_LEVEL: `${EVERGREEN_QUEST_MODULE_NAME}/CREATE_EVERGREEN_QUEST_LEVEL`,
  UPDATE_EVERGREEN_QUEST_LEVEL: `${EVERGREEN_QUEST_MODULE_NAME}/UPDATE_EVERGREEN_QUEST_LEVEL`,
  UPLOAD_EVERGREEN_QUEST_IMAGE: `${EVERGREEN_QUEST_MODULE_NAME}/UPLOAD_EVERGREEN_QUEST_IMAGE`,
}

export default ActionTypes
