import { useTypedSelector } from 'core/redux/utils'
import { useRouter } from 'core/routing/hooks/use-router'
import {
  IsNullOrUndefined,
} from 'core/utils/isNullOrUndefined'
import { Actions, Selectors } from 'modules/post-groups'
import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useDispatch } from 'react-redux'
import { NIL as NIL_UUID } from 'uuid'
import { FilterOperator } from 'types/common/filtering/enums/FilterOperator'
import { FilterType } from 'types/common/filtering/enums/FilterType'
import SortDirection from 'types/common/enums/sort-direction'
import { useRequestContext } from 'core/api/context'
import { getStatusFilterOptions } from 'modules/posts/utils/get-status-filter-options'
import { ISelectFilterConfig } from 'types/common/filtering/models/entities/ISelectFilterConfig'
import { IPostGroupState } from 'modules/post-groups/models/state'
import { IAsyncActionParams } from 'types/common/api/models/entities/async-action-params'
import { usePageSizeMonitor } from 'modules/common/usePageSizeMonitor'
import { PostGroup, PostGroupStatus } from 'fe-shared-resources'

export interface IActionParams
  extends IAsyncActionParams<PostGroup> {
  postGroupId?: string
  postGroupStatus?: PostGroupStatus
}


export interface IPostGroupsContext {
  fetchingPosts: boolean
  currentPostGroup: PostGroup | null
  postGroups: IPostGroupState
  setCurrentPostGroup: (postGroupId: string) => void
  clearCurrentPostGroup: () => void
  goToCreatePostGroup: () => void
  createPostGroup: (postGroup: PostGroup, callbackFn?: (postGroup: PostGroup) => void) => void
  createPostGroupDraft: (postGroup?: PostGroup) => void
  updatePostGroup: (postGroup: PostGroup) => void
  settlePostGroup: (postGroupId: string) => void
  initialiseDashboard: () => void
  statusFilterConfig: ISelectFilterConfig
  shouldShowModal: boolean
  displayModal: () => void
  hideModal: () => void
}

const PostGroupsContext = createContext<IPostGroupsContext>({} as IPostGroupsContext)

export const PostGroupsProvider: React.FC<PropsWithChildren<{}>> = (
  props: PropsWithChildren<{}>,
) => {
  const dispatch = useDispatch()
  const [fetchingPosts, setFetchingPosts] = useState(false)
  const [shouldShowModal, setShouldShowModal] = useState(false)
  const rc = useRequestContext()

  const pageSize = usePageSizeMonitor({ fetchItems: Actions.getPostGroups })

  const statusFilter = rc?.getFilterByField('status')
  const postGroups = useTypedSelector((state) =>
    Selectors.getPostGroupsByStatus(state, statusFilter?.value as PostGroupStatus[]),
  )
  const statusFilterConfig = {
    field: 'status',
    options: getStatusFilterOptions(postGroups.statuses),
  }

  const { routeParams: { postGroupId }, pushQueryParams, removeQueryParams  } = useRouter()

  const currentPostGroup = useTypedSelector((state) => Selectors.getCurrentPostGroup(state))

  const setCurrentPostGroup = (postGroupId: string): void => {
    setShouldShowModal(true)
  }

  const clearCurrentPostGroup = (): void => {
    removeQueryParams('postGroupId')
    Actions.setCurrentPostGroupId({ postGroupId: undefined })
  }

  useEffect(() => {
    if (!postGroupId) return
    Actions.setCurrentPostGroupId({ postGroupId: undefined })
  }, [postGroupId])

  useEffect(() => {
    dispatch(Actions.setCurrentPostGroupId({ postGroupId }))

    if (!IsNullOrUndefined(postGroupId) && postGroupId !== NIL_UUID) {
      dispatch(Actions.getPostGroupById({ postGroupId }))
    }
  }, [postGroupId])

  const createPostGroup = (postGroup: PostGroup, callbackFn?: (postGroup: PostGroup) => void): void => {
    new Promise<PostGroup>((onResolve, onReject) => {
      dispatch(
        Actions.createPostGroup({
          item: postGroup,
          promise: {
            onResolve,
            onReject,
          },
        }),
      )
    }).then((postGroup) => {
      if (!IsNullOrUndefined(callbackFn)) {
        callbackFn!(postGroup)
      }
      clearCurrentPostGroup()
    })
  }

  const createPostGroupDraft = (): void => {
    setCurrentPostGroup(NIL_UUID)
  }

  const updatePostGroup = (postGroup: PostGroup): void => {
    dispatch(
      Actions.updatePostGroup({
        item: {
          ...postGroup,
        },
        postGroupId: postGroup.id,
        promise: {
          onResolve: () => dispatch(Actions.getPostGroupPosts({ postGroupId }))
        }
      }),
    )
  }

  const settlePostGroup = (postGroupId: string): void => {
    dispatch(
      Actions.settlePostGroup({
        postGroupId: postGroupId,
      }),
    )
  }

  const goToCreatePostGroup = () => {
    pushQueryParams({
      postGroupId: NIL_UUID,
    })
    dispatch(Actions.setCurrentPostGroupId({ postGroupId: NIL_UUID }))
  }

  const loadPostGroups = useCallback((): void => {
    dispatch(
      Actions.getPostGroups({
        filters: rc?.filters,
        page: rc?.page,
        pageSize,
        ...rc?.sortValue,
        promise: {
          onResolve: () => setFetchingPosts(false),
          onReject: () => setFetchingPosts(false),
        },
      } as IActionParams),
    )
  }, [rc?.active, rc?.filters, rc?.page, pageSize, rc?.sortValue])

  useEffect(() => {
    if (rc?.active) {
      loadPostGroups()
    }
  }, [rc?.active, rc?.filters, rc?.page, rc?.sortValue])

  const initialiseDashboard = (): void => {
    rc?.applyFilter({
      field: statusFilterConfig.field,
      type: FilterType.Select,
      operator: FilterOperator.Equals,
      value: [PostGroupStatus.Active],
    })
    rc?.setSortValue({
      sortBy: 'start',
      sortDirection: SortDirection.Descending,
    })
  }

  const context = {
    currentPostGroup,
    setCurrentPostGroup,
    clearCurrentPostGroup,
    createPostGroup,
    createPostGroupDraft,
    updatePostGroup,
    goToCreatePostGroup,
    initialiseDashboard,
    statusFilterConfig,
    postGroups,
    shouldShowModal,
    displayModal: () => setShouldShowModal(true),
    hideModal: () => setShouldShowModal(false),
    settlePostGroup,
    fetchingPosts
  }

  return (
    <PostGroupsContext.Provider value={context}>
      {props.children}
    </PostGroupsContext.Provider>
  )
}

export const usePostGroupsContext = (): IPostGroupsContext => useContext(PostGroupsContext)
