import { Reducer } from 'redux'
import { ISimpleAction } from 'types/redux/interfaces/IAction'
import _ from 'core/utils/deepdash'
import CommonActionTypes from 'modules/common/actionTypes'
import { PAGE_SIZE_DEFAULT, PAGE_SIZE_KEY } from 'modules/common/constant'


export interface ICommonState {
  pageSize: number
}

const initialState: ICommonState = {
  pageSize: parseInt(localStorage.getItem(PAGE_SIZE_KEY) || `${PAGE_SIZE_DEFAULT}`, 10),
}

const actionHandlers = {
  [CommonActionTypes.SET_PAGE_SIZE]: (state: ICommonState, action: ISimpleAction): ICommonState => {
    const { pageSize } = action.payload as { pageSize: number }
    return {
      ...state,
      pageSize
    }
  },
}

const commonReducer: Reducer<ICommonState, ISimpleAction> = (
  state = initialState,
  action: ISimpleAction,
) => {
  return actionHandlers[action.type]
    ? actionHandlers[action.type](state, action)
    : state
}

export default commonReducer