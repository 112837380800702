import { Box, Chip } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { round } from 'lodash-es'
import React from 'react'

interface IVolumeInterface {
  total: number,
  volume?: number
}

const useStyles = makeStyles(() =>
  createStyles({
    inputLabel: {
      display: 'flex',
      marginBottom: '0.625rem',
      justifyContent: 'space-between',
    },
    outcomeWrapper: {
      display: 'flex',
      width: '100%',
      height: '100%',
      justifyContent: 'flex-end'
    },
    chip: {
      margin: 4
    }
  }),
)

export const Volume = ({ total, volume = 0 }: IVolumeInterface): JSX.Element => {
  const classes = useStyles()

  const portion = !volume ? 0 : (volume / total)
  const roundedUpPercentage = round(portion, 3) * 100

  return <Box className={classes.outcomeWrapper}>
    <Chip
      label={`Outcome: ${volume || 0}/${total}`}
      className={classes.chip}
      variant="outlined"
      size="small"
    />
    <Chip
      label={`Percentage: ${roundedUpPercentage.toString().substring(0, 4)}%`}
      className={classes.chip}
      variant="outlined"
      size="small"
    />
</Box>
}