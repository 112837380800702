import { useTypedSelector } from 'core/redux/utils'
import React, { PropsWithChildren } from 'react'
import { ILeaderboard } from 'types/modules/leaderboards/models/entities/leaderboard'
import ILeaderboardPositionReward from 'types/modules/leaderboards/models/entities/leaderboard-position-reward'
import { Selectors, Actions } from 'modules/leaderboards'
import { useRouter } from 'core/routing/hooks/use-router'
import { IsNullUndefinedOrEmpty } from 'core/utils/isNullOrUndefined'
import { useDispatch } from 'react-redux'
import { ILeaderboardPositionRewardDraft } from 'types/modules/leaderboards/models/entities/leaderboard-position-reward-draft'

export interface ILeaderboardContext {
  currentLeaderboard: ILeaderboard | null
  currentPositionReward: ILeaderboardPositionReward | null
  setCurrentLeaderboard: (leaderboardId: string) => void
  clearCurrentLeaderboard: () => void
  setCurrentPositionReward: (positionId: string) => void
  clearCurrentPositionReward: () => void
  updatePositionReward: (
    leaderboardId: string,
    positionReward: ILeaderboardPositionRewardDraft,
  ) => void
}

export const LeaderboardContext = React.createContext<ILeaderboardContext>(
  {} as ILeaderboardContext,
)

export const LeaderboardContextProvider: React.FC<{}> = (
  props: PropsWithChildren<{}>,
) => {
  const dispatch = useDispatch()

  const { routeParams, pushQueryParams, removeQueryParams } = useRouter()

  const { positionId } = routeParams

  const currentLeaderboard = useTypedSelector((state) =>
    Selectors.getCurrentLeaderboard(state),
  )

  const currentPositionReward = !IsNullUndefinedOrEmpty(positionId)
    ? currentLeaderboard?.rewards.find(
        (position) => position.id === positionId,
      ) ?? null
    : null

  const setCurrentLeaderboard = (leaderboardId: string): void => {
    dispatch(Actions.setCurrentLeaderboardId({ leaderboardId }))

    new Promise<ILeaderboardPositionReward[]>((onResolve, onReject) => {
      dispatch(
        Actions.getLeaderboardPositionRewards({
          leaderboardId,
          promise: {
            onReject,
            onResolve,
          },
        }),
      )
    })
  }

  const clearCurrentLeaderboard = (): void => {
    dispatch(Actions.setCurrentLeaderboardId({}))
  }

  const setCurrentPositionReward = (positionId: string): void => {
    pushQueryParams({
      positionId,
    })
  }

  const clearCurrentPositionReward = (): void => {
    removeQueryParams()
  }

  const updatePositionReward = (
    leaderboardId: string,
    positionReward: ILeaderboardPositionRewardDraft,
  ): void => {
    new Promise<ILeaderboardPositionReward>((onResolve, onReject) => {
      dispatch(
        Actions.updateLeaderboardPositionReward({
          leaderboardId,
          positionId: positionReward.id,
          item: positionReward,
          promise: {
            onResolve,
            onReject,
          },
        }),
      )
    }).then(() => {
      dispatch(
        Actions.getLeaderboardPositionRewards({
          leaderboardId,
        }),
      )
    })
  }

  const context: ILeaderboardContext = {
    currentLeaderboard,
    currentPositionReward,
    setCurrentLeaderboard,
    clearCurrentLeaderboard,
    setCurrentPositionReward,
    clearCurrentPositionReward,
    updatePositionReward,
  }

  return (
    <LeaderboardContext.Provider value={context}>
      {props.children}
    </LeaderboardContext.Provider>
  )
}

export const useLeaderboardContext = (): ILeaderboardContext =>
  React.useContext(LeaderboardContext)
