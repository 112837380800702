import { usePostListContext } from 'modules/posts/context/post-list'
import { IPostQuestion } from 'types/modules/posts/models/entities/post-question'

export const useCurrentQuestion = (questionId: string): IPostQuestion => {
  const { currentPostQuestions } = usePostListContext()
  const currentQuestion = currentPostQuestions.find((x) => {
    return questionId === x.id
  })
  return currentQuestion!
}
