import React, {
  createContext,
  PropsWithChildren,
  useState,
  useContext,
} from 'react'

export interface ILayoutContext {
  toolbarRef: HTMLDivElement | null
  setToolbarRef: (ref: HTMLDivElement | null) => void
  // displayToolbar: boolean;
}

export const LayoutContext = createContext<ILayoutContext>({} as ILayoutContext)

export const LayoutProvider: React.FC<React.ReactNode> = (
  props: PropsWithChildren<React.ReactNode>,
) => {
  const [toolbarRef, setToolbarRef] = useState<HTMLDivElement | null>(null)

  const context = {
    toolbarRef,
    setToolbarRef,
  }

  return (
    <LayoutContext.Provider value={context}>
      {props.children}
    </LayoutContext.Provider>
  )
}

export const useLayoutContext = (): ILayoutContext => useContext(LayoutContext)
