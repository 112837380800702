import { useRouter } from 'core/routing/hooks/use-router'
import React, { PropsWithChildren, useContext, useState } from 'react'

interface IDashboardContext {
  dashboardView: string
  setDashboardView: (viewMode: string) => void
}

const DashboardContext = React.createContext<IDashboardContext>(
  {} as IDashboardContext,
)

const useProvideDashboardContext = (): IDashboardContext => {
  const { location, routeParams, history } = useRouter()

  const { dashboardView } = routeParams

  const setDashboardView = (mode: string): void => {
    const newPath = location.pathname.replace(dashboardView, mode)
    history.push(newPath)
  }

  return {
    dashboardView,
    setDashboardView,
  }
}

export const DashboardContextProvider: React.FC<PropsWithChildren<{}>> = (
  props: PropsWithChildren<{}>,
) => {
  const context = useProvideDashboardContext()

  return (
    <DashboardContext.Provider value={context}>
      {props.children}
    </DashboardContext.Provider>
  )
}

export const useDashboardContext = (): IDashboardContext =>
  useContext(DashboardContext)
