export const PortalPermissions = {
  DASHBOARD_VIEW: 'dashboard:view',
  WATCHLIST_VIEW: 'watchlist:view',
  EVENTS_CLOSE: 'events:close-event',
  MANAGE_USERS: 'users:management',
}

export const Rules = {
  Admin: {
    static: [...Object.values(PortalPermissions)],
  },
  'Tech:Developer': {
    static: [...Object.values(PortalPermissions)],
  },
  'Content:Manager': {
    static: [
      PortalPermissions.DASHBOARD_VIEW,
      PortalPermissions.WATCHLIST_VIEW,
      PortalPermissions.EVENTS_CLOSE,
    ],
  },
  'Content:Supervisor': {
    static: [
      PortalPermissions.DASHBOARD_VIEW,
      PortalPermissions.WATCHLIST_VIEW,
      PortalPermissions.EVENTS_CLOSE,
    ],
  },
  'Content:Writer': {
    static: [
      PortalPermissions.DASHBOARD_VIEW,
      PortalPermissions.WATCHLIST_VIEW,
    ],
  },
  'Content:Watcher': {
    static: [PortalPermissions.WATCHLIST_VIEW],
  },
}
