import React from 'react'
import { PanelContainer, Panel } from 'components/panel'
import { RequestContextProvider } from 'core/api/context'
import { ShippingProvider } from 'modules/shipping/context'
import { UserPrizesDashboard } from 'modules/shipping/views/components/dashboard'

export const ShippingDashboardPage: React.FC<{}> = () => {
  return (
    <RequestContextProvider>
      <ShippingProvider>
        <PanelContainer>
          <Panel container xs={12}>
            <UserPrizesDashboard />
          </Panel>
        </PanelContainer>
      </ShippingProvider>
    </RequestContextProvider>
  )
}
