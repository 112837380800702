import PostGroupActionTypes from 'modules/post-groups/action-types'
import { Reducer } from 'redux'
import { actionTypeSuccess, cacheItem, cacheItems } from 'core/redux/utils'
import { ISimpleAction, ISuccessAction } from 'types/redux/interfaces/IAction'
import _ from 'core/utils/deepdash'
import { mergeArrays } from 'core/utils/mergeArrays'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { IPostGroupActionParams } from 'modules/post-groups/actions'
import { NIL as NIL_UUID } from 'uuid'
import { IPostGroupState } from 'modules/post-groups/models/state'
import { IPost } from 'types/modules/posts/models/entities/post'
import { PostGroup } from 'fe-shared-resources'

const pageSize = 20

const initialState: IPostGroupState = {
  count: 0,
  page: 0,
  pageSize: pageSize,
  items: [],
  cache: {},
  postGroups: [],
  statuses: {},
  postGroupPosts: []
}

export type GetPostGroupsApiResponse = {
  count: number
  page: number
  pageSize: number
  items: PostGroup[]
}

export type GetPostGroupPostsApiResponse = {
  items: IPost[]
}


type AllowedActionTypes = ISuccessAction | ISimpleAction

const postGroupsReducer: Reducer<IPostGroupState, AllowedActionTypes> = (
  state = initialState,
  action: AllowedActionTypes,
) => {
  const simpleAction = action as ISimpleAction
  const successAction = action as ISuccessAction

  switch (action.type) {
    case PostGroupActionTypes.SET_CURRENT_POST_GROUP_ID: {
      const newState = _.cloneDeep(state)

      const { postGroupId } = simpleAction.payload as IPostGroupActionParams

      if (IsNullOrUndefined(postGroupId) && Object.hasOwnProperty.call(newState, 'currentPostGroupId')) {
        delete newState.currentPostGroupId
      } else {
        newState.currentPostGroupId = postGroupId
      }

      return newState
    }

    case PostGroupActionTypes.CLEAR_POST_GROUP_POSTS: {
      return {
        ...state,
        postGroupPosts: []
      }
    }

    case PostGroupActionTypes.CREATE_POST_GROUP_DRAFT: {
      const draft = action.payload?.item as PostGroup
      return {
        ...state,
        postGroups: [draft, ...state.postGroups],
        currentPostGroupId: NIL_UUID
      }
    }

    case actionTypeSuccess(PostGroupActionTypes.GET_POST_GROUPS): {
      const response = successAction.data as GetPostGroupsApiResponse
      return {
        ...state,
        items: [...response.items],
        postGroups: [...response.items],
        cache: cacheItems(state.cache, response.items),
        count: response.items.length
      }
    }

    case actionTypeSuccess(PostGroupActionTypes.GET_POST_GROUP_POSTS): {
      const response = successAction.data as GetPostGroupPostsApiResponse
      return {
        ...state,
        postGroupPosts: response.items
      }
    }

    case actionTypeSuccess(PostGroupActionTypes.GET_POST_GROUP_BY_ID): {
      const newState = _.cloneDeep(state)
      const response = successAction.data as PostGroup
      const postGroupIndex = state.items.findIndex((x) => x.id === response.id)

      if (postGroupIndex < 0) {
        newState.items = [...newState.items, response]
      } else {
        newState.items[postGroupIndex] = response
      }

      newState.cache = cacheItem(state.cache, response)

      return newState
    }

    case actionTypeSuccess(PostGroupActionTypes.CREATE_POST_GROUP): {
      const responseItem = successAction.data as PostGroup
      return {
        ...state,
        items: [responseItem, ...state.postGroups],
        postGroups: [responseItem, ...state.postGroups],
        cache: cacheItem(state.cache, responseItem),
      }
    }

    case actionTypeSuccess(PostGroupActionTypes.UPDATE_POST_GROUP): {
      const payloadItem = successAction.payload!.item as PostGroup
      const index = state.postGroups.findIndex(
        (item) => item.id === payloadItem.id,
      )
      const newList = [
          ...state.postGroups.slice(0, index),
          payloadItem,
          ...state.postGroups.slice(index + 1, state.postGroups.length)
        ]
      return {
        ...state,
        postGroups: newList as PostGroup[],
        items: newList as PostGroup[],
        cache: cacheItem(state.cache, payloadItem),
      }
    }

    default:
      return state
  }
}

export default postGroupsReducer