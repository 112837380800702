import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { CloseEventButton } from 'modules/events/views/components/action-buttons/close'
import { EditEventButton } from 'modules/events/views/components/action-buttons/edit'
import { LaunchEventButton } from 'modules/events/views/components/action-buttons/launch'
import { ManageEventButton } from 'modules/events/views/components/action-buttons/manage'
import { PromoteEventButton } from 'modules/events/views/components/action-buttons/promote'
import { ScheduleEventButton } from 'modules/events/views/components/action-buttons/schedule'
import { SubmitEventButton } from 'modules/events/views/components/action-buttons/submit'
import React, { ComponentType } from 'react'
import { EventActionButtonType } from 'types/modules/events/enums/event-action-button-type'
import { IEventActionButtonProps } from 'types/modules/events/models/props/event-action-button-props'
import { IEventActionButtonsProps } from 'types/modules/events/models/props/event-action-buttons-props'


const buttons = {
  [EventActionButtonType.Close]: CloseEventButton,
  [EventActionButtonType.Launch]: LaunchEventButton,
  [EventActionButtonType.Schedule]: ScheduleEventButton,
  [EventActionButtonType.Edit]: EditEventButton,
  [EventActionButtonType.Manage]: ManageEventButton,
  [EventActionButtonType.Submit]: SubmitEventButton,
  [EventActionButtonType.Promote]: PromoteEventButton,
}

export const EventActionButtons: React.FC<IEventActionButtonsProps> = (
  props: IEventActionButtonsProps,
) => {
  const { event, exclude, disabled } = props

  const renderButtons = (): JSX.Element => {
    const buttonsToRender: [
      EventActionButtonType,
      ComponentType<IEventActionButtonProps>,
    ][] = []

    Object.values(EventActionButtonType).forEach((buttonType) => {
      if (!IsNullOrUndefined(exclude) && exclude?.includes(buttonType)) {
        return
      }
      const currentButton: ComponentType<IEventActionButtonProps> | null = buttons[buttonType] || null

      if (!IsNullOrUndefined(currentButton)) {
        buttonsToRender.push([buttonType, currentButton])
      }
    })

    return (
      <React.Fragment>
        {buttonsToRender.map(([buttonType, Component]) => (
          <Component key={buttonType} event={event} disabled={disabled} />
        ))}
      </React.Fragment>
    )
  }

  return !IsNullOrUndefined(event) ? renderButtons() : null
}
