import { Grid } from '@mui/material'
import { FormGen, FormTypes } from 'components/simple-form-gen'
import { useEnums } from 'hooks/use-enums'
import { DEFAULT_PARAM_VALUES, TASK_CODES_WITH_PARAMS } from 'modules/quests/constants'
import { QuestTaskField } from 'modules/quests/enums/quest-field'
import { IQuestTask } from 'modules/quests/models/quest'
import { Parameters } from 'modules/quests/view/quests/quest-modal/parameters'
import React, { useCallback } from 'react'

interface IQuestTaskFormProps {
  item: IQuestTask,
  setValue: (value: any, fieldName: string, id: string) => void,
  errors: any
  hideQuantity?: boolean
}

export const QuestTasksForm = ({
  setValue,
  item,
  errors,
  hideQuantity
}: IQuestTaskFormProps): JSX.Element => {

  const { taskCodes } = useEnums()
  const taskCodesOptions = taskCodes.length > 0
    ? taskCodes.map((code) => ({ value: code, name: code}))
    : [{ value: item[QuestTaskField.CODE], name: item[QuestTaskField.CODE] }]

  const setParameters = useCallback((taskCode) => {
    if (!TASK_CODES_WITH_PARAMS[taskCode]) return
    const parameterType = TASK_CODES_WITH_PARAMS[taskCode]
    setValue(DEFAULT_PARAM_VALUES[parameterType], QuestTaskField.PARAMETERS, item.id || '')
  }, [item.id])

  return <>
    <Grid item xs={8}>
      <FormGen
        handleChange={({ target: { value } }): void => {
          setValue(value, QuestTaskField.CODE, item.id || '')
          setParameters(value)
        }}
        label="Code"
        value={item[QuestTaskField.CODE]}
        type={FormTypes.SELECT}
        name={QuestTaskField.CODE}
        options={taskCodesOptions}
        hideInitialOption
        otherProps={{
          error: !!errors?.[QuestTaskField.CODE],
          helperText: errors?.[QuestTaskField.CODE]
        }}
      />
    </Grid>
    {!hideQuantity && <Grid item xs={4}>
      <FormGen
        handleChange={({ target: { value } }): void => {
          setValue(value, QuestTaskField.QUANTITY, item.id || '')
        }}
        value={item[QuestTaskField.QUANTITY]}
        type={FormTypes.TEXT_INPUT}
        name={QuestTaskField.QUANTITY}
        label="Quantity"
        otherProps={{
          type: 'number',
          error: !!errors?.[QuestTaskField.QUANTITY],
          helperText: errors?.[QuestTaskField.QUANTITY]
        }}
      />
    </Grid>}
    {!!TASK_CODES_WITH_PARAMS[item[QuestTaskField.CODE]] && <Grid item xs={12}>
        <Parameters
          setValue={setValue}
          item={item}
          errors={errors}
          paramterType={TASK_CODES_WITH_PARAMS[item[QuestTaskField.CODE]]}
        />
      </Grid>}
  </>
}