import _ from 'core/utils/deepdash'
import { IsNullUndefinedOrEmpty } from 'core/utils/isNullOrUndefined'
import { ILeaderboard } from 'types/modules/leaderboards/models/entities/leaderboard'
import { ILeaderboardState } from 'types/modules/leaderboards/models/state/leaderboard-state'
import { AppState } from 'types/redux/types/app-state'

import { MODULE_NAME } from '../constants'

const getState = (state: AppState): ILeaderboardState =>
  _.cloneDeep(state[MODULE_NAME])

export const getCurrentLeaderboard = (state: AppState): ILeaderboard | null => {
  const { currentLeaderboardId, rewards } = getState(state)

  if (!IsNullUndefinedOrEmpty(currentLeaderboardId)) {
    return {
      id: currentLeaderboardId!,
      rewards: rewards[currentLeaderboardId!] ?? [],
    }
  }

  return null
}
