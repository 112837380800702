import React from 'react'
import { useTypedSelector } from 'core/redux/utils'
import { useNewsCardsContext } from 'modules/news-cards/context/news-card-context'
import {
  NewsCard,
  StyledTableCell,
} from 'modules/news-cards/view/news-card'
import { NewsCardModal } from 'modules/news-cards/view/news-card-modal'
import { INewsCard } from 'modules/news-cards/models/news-card'
import { LocalListWrapper } from 'components/local-list-wrapper'
import { NewsCardActions, Selectors } from '..'

const sortOptions = [
  { name: 'Created at', value: 'createdAt', descending: true },
  { name: 'Title', value: 'title' },
  { name: 'Variant', value: 'variant' },
]

export const NewsCardList: React.FC<{}> = () => {
  const list = useTypedSelector((state) =>
    Selectors.getNewsCards(state),
  )
  const count = useTypedSelector((state) =>
    Selectors.getNewsCardsCount(state),
  )
  const {
    currentNewsCard,
    createNewsCardDraft,
    setCurrentNewsCard,
  } = useNewsCardsContext()

  return (
    <LocalListWrapper<
      INewsCard,
      NewsCardActions.INewsCardActionParams,
      null
    >
      list={list}
      count={count}
      searchKey="title"
      currentItem={currentNewsCard}
      createDraft={createNewsCardDraft}
      setCurrentItem={setCurrentNewsCard}
      CardComponent={NewsCard}
      ModalComponent={NewsCardModal}
      getItems={NewsCardActions.getNewsCards}
      searchLabel="Title"
      title="News Cards"
      sortOptions={sortOptions}
      panelXs={12}
    >
      <>
        <StyledTableCell>Title</StyledTableCell>
        <StyledTableCell>Description</StyledTableCell>
        <StyledTableCell>Created at</StyledTableCell>
        <StyledTableCell>Variant</StyledTableCell>
      </>
    </LocalListWrapper>
  )
}
