import React, { ReactElement, useEffect, useState } from 'react'
import { Theme, Box, Grid } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { ViewModeSelector } from 'components/content-dashboard/view-mode-selector'
import { ListView } from 'components/content-dashboard/list-view'
import { CalendarView } from 'components/content-dashboard/calendar-view'
import { CalendarContextProvider } from 'components/calendar/context/calendar-context'
import { useDashboardContext } from 'components/content-dashboard/context'
import { IContentDashboardProps } from 'components/content-dashboard/props/content-dashboard-props'
import { SelectFilter } from 'core/filtering/components/select-filter'
import { SelectFilterMode } from 'types/common/filtering/enums/SelectFilterMode'
import { FilterTab } from 'components/content-dashboard/filter-tab'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentDashboardContainer: {
      display: 'flex',
      width: '100%',
      padding: '0.625rem',
      flexGrow: 1,
      flexDirection: 'column',
      overflow: 'auto',
    },
    dashboardHeader: {
      display: 'flex',
      marginBottom: '0.625rem',
      flexShrink: 1,
    },
    tabsNToolbarContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginRight: '0.625rem',
      flexShrink: 1,
    },
    dynamicToolbar: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: 12
    },
    tabFilterContainer: {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: '0.625rem',
    },
    tabFilter: {
      display: 'flex',
      flexDirection: 'column',
      width: 90,
      height: 90,
      marginRight: '0.625rem',
      background: 'rgba(0,0,0,0.03)',
      '&.active': {
        '&::after': {
          display: 'block',
          position: 'absolute',
          width: '100%',
          height: '0.3125rem',
          content: '""',
          bottom: 0,
          background: theme.palette.primary.main,
        },
      },
      '&:last-child': {
        margin: 0,
      },
    },
    tabFilterCount: {
      color: 'rgba(0,0,0,0.54)',
    },
    tabFilterLabel: {
      color: 'rgba(0,0,0,0.54)',
      fontSize: '0.7rem',
    },
    customFiltersContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexShrink: 1,
      height: '100%'
    },
    dynamicHeaderPanel: {
      display: 'flex',
      marginLeft: 'auto',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    dashboardContent: {
      display: 'flex',
      minHeight: 0,
      flexGrow: 1,
      position: 'relative',
    },
    dynamicFilterWrappers: {
      minWidth: 370
    }
  }),
)

function ContentDashboard<TModel>(
  props: IContentDashboardProps<TModel>,
): ReactElement<any, any> {
  const {
    listOptions,
    calendarOptions,
    data,
    onViewModeChange,
    customFilterComponent,
    onTabChange,
  } = props

  const { dashboardView, setDashboardView } = useDashboardContext()

  // Portal Refs
  const [dynamicToolbarRef, setDynamicToolbarRef] =
    useState<HTMLDivElement | null>(null)

  const [dynamicHeaderPanelRef, setDynamicHeaderPanelRef] =
    useState<HTMLDivElement | null>(null)

  useEffect(() => props.onDashboardMount(dashboardView), [])

  const classes = useStyles()

  const renderTabFilters = (): JSX.Element => {
    const { tabFilterConfig } = props

    return (
      <SelectFilter
        {...tabFilterConfig!}
        mode={
          dashboardView === 'list'
            ? SelectFilterMode.Single
            : SelectFilterMode.Multiple
        }
        filterOptionComponent={FilterTab}
        onChange={onTabChange}
        optionWrapperProps={{ xs: 3, item: true }}
        OptionWrapperTag={Grid}
      />
    )
  }

  const renderDashboardView = (): JSX.Element | null => {
    const dashboardProps = {
      dynamicToolbarRef,
      dynamicHeaderPanelRef,
      resultCount: props.data?.count,
    }

    switch (dashboardView) {
      case 'list': {
        return <ListView {...listOptions} {...dashboardProps} data={data} />
      }
      case 'calendar':
        return (
          <CalendarContextProvider>
            <CalendarView
              {...calendarOptions}
              {...dashboardProps}
              data={data!}
            />
          </CalendarContextProvider>
        )
    }
    return null
  }

  return (
    <Box className={classes.contentDashboardContainer}>
      <Box className={classes.dashboardHeader}>
        <ViewModeSelector
          {...{
            dashboardView,
            setDashboardView,
            onViewModeChange,
          }}
        />
        <Grid container spacing={1}>
          <Grid item xs={5} className={classes.dynamicFilterWrappers}>
            {props.tabFilterConfig && (
              <Grid container spacing={1}>
                {renderTabFilters()}
              </Grid>
            )}
            <div
              className={classes.dynamicToolbar}
              ref={(el): void => setDynamicToolbarRef(el)}
            />
          </Grid>
            {!IsNullOrUndefined(customFilterComponent) && (
              <Grid xs={4} item  className={classes.dynamicFilterWrappers}>
                <Box className={classes.customFiltersContainer}>
                  {customFilterComponent}
                </Box>
              </Grid>
            )}
          <Grid xs={!IsNullOrUndefined(customFilterComponent) ? 3 : 7} item>
            <div
              className={classes.dynamicHeaderPanel}
              ref={(el): void => setDynamicHeaderPanelRef(el)}
            />
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.dashboardContent}>{renderDashboardView()}</Box>
    </Box>
  )
}

export default ContentDashboard
