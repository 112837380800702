import React from 'react'
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Box, Grid, InputLabel } from '@mui/material'
import { TagSuggestionField } from 'types/modules/tags/enums/tag-suggestion-fields'
import { ITagSuggestion } from 'types/modules/tags/models/entities/tag-suggestion'
import { Actions, Selectors } from 'modules/tags'
import { AutocompleteSearchMultiselect } from 'components/controls/inputs/downshift/autocomplete/autocomplete-search-multiselect'
import { Tag } from 'types/modules/tags/models/entities/tag'
import { OverrideChipProps } from 'components/controls/inputs/downshift/multiselect/multiselect-props'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputLabel: {
      display: 'flex',
      marginBottom: '0.625rem',
    },
    tagsContainer: {
      marginTop: 20,
      zIndex: 10,
      minHeight: 410,
    },
  }),
)

export interface ISuggestionFormProps {
  tagSuggestion: ITagSuggestion
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  initialSelectedTags: Tag[]
}

export const TagSuggestionForm: React.FC<ISuggestionFormProps> = (
  props: ISuggestionFormProps,
) => {
  const classes = useStyles()
  const { tagSuggestion, setFieldValue } = props

  const handleSelectedTagsChange = (selectedTags: Tag[]): void => {
    setFieldValue(TagSuggestionField.TAGS, selectedTags)
  }

  return (
    <Box>
      <Grid item xs={12} className={classes.tagsContainer}>
        <InputLabel className={classes.inputLabel}>Tags</InputLabel>
        <AutocompleteSearchMultiselect
          variant="filled"
          key={`autocomplete-suggestion}`}
          initialSelectedItems={props.initialSelectedTags}
          availableItems={[]}
          onSelectedItemsChange={handleSelectedTagsChange}
          searchAsyncActionCreator={Actions.getAutocompleteSearchTags}
          selectorMethod={Selectors.getAutocompleteSearchTags}
          options={{
            selectedItemsChipProps: (item: Tag): OverrideChipProps => {
              const props: OverrideChipProps = {
                label: item.name!,
              }
              if (!IsNullOrUndefined(item.media?.icon)) {
                props.icon = <i>{item.media!.icon!}</i>
              }
              return props
            },
            autocompleteSearchResultContent: (item: Tag): string =>
              `${item.media?.icon ?? ''} ${item.name} - Level: ${item.level}`,
          }}
        />
      </Grid>
    </Box>
  )
}
