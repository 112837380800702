enum PrizeStatus {
  Created = 'Created',
  Pending = 'Pending',
  Scheduled = 'Scheduled',
  Open = 'Open',
  Closed = 'Closed',
  Settled = 'Settled',
  OutOfStock = 'OutOfStock',
  Trashed = 'Trashed',
}

export default PrizeStatus
