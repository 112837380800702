import { Button, ButtonProps, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx'
import { ConfirmationDialog } from 'components/confirmation-dialog'
import { ReactivateIcon } from 'components/icons'
import { usePrizeStatusManagerContext } from 'modules/prizes/context/prize-status-manager'
import React from 'react'
import PrizeStatus from 'types/modules/prizes/enums/prize-status'
import { IPrizeActionButtonProps } from 'types/modules/prizes/models/props/prize-action-button-props'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.primary.contrastText,
      backgroundColor: '#2cd06e',
      '&:hover': {
        backgroundColor: '#54db8a',
      },
    },
  }),
)

export const ReactivatePrizeButton: React.FC<IPrizeActionButtonProps> = (
  props: IPrizeActionButtonProps,
) => {
  const { reactivatePrize } = usePrizeStatusManagerContext()

  const classes = useStyles()

  const isPaused = props.prize.status === PrizeStatus.OutOfStock

  const buttonProps: ButtonProps = {
    variant: 'contained',
    startIcon: <ReactivateIcon />,
    className: clsx(classes.root),
    size: 'small',
    disabled: props.disabled,
  }

  const buttonLabel = 'reactivate'

  return isPaused ? (
    <ConfirmationDialog
      popoverId={`${buttonLabel}-prize-${props.prize.id}`}
      buttonProps={buttonProps}
      onConfirmation={(): void => reactivatePrize(props.prize.id)}
      confirmationText={`Are you sure you want to ${buttonLabel} this prize?`}
      buttonLabel={buttonLabel}
      onPopoverClose={props.onPopoverClose}
    />
  ) : null
}
