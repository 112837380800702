import { IapProductMultiselect } from 'components/iap-product-multiselect'
import { IapProductSelect } from 'components/iap-product-select'
import { PrizeSelect } from 'components/prize-select'
import { FormGen, FormTypes } from 'components/simple-form-gen'
import { GameTaskStepParamConfigurationFieldType } from 'fe-shared-resources'
import React from 'react'
import { MediaUploadModel } from 'types/common/images/models/entities/image-upload-model'
import { OnChange } from 'types/common/interfaces/IForm'

interface Props {
  elementType: GameTaskStepParamConfigurationFieldType,
  value: string | string[]
  name: string
  label: string
  options?: any[]
  error?: any
  handleChange: (value: OnChange) => void | React.ChangeEventHandler<HTMLInputElement>
  prefix: string
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
}

const FORM_GEN_ELEMENTS = [
  GameTaskStepParamConfigurationFieldType.Text,
  GameTaskStepParamConfigurationFieldType.Number,
  GameTaskStepParamConfigurationFieldType.Image,
  GameTaskStepParamConfigurationFieldType.Select,
  GameTaskStepParamConfigurationFieldType.Date,
  GameTaskStepParamConfigurationFieldType.CountryMultiSelect,
  GameTaskStepParamConfigurationFieldType.CheckBox
]

export const GameTaskStepParamsElement = ({
  elementType,
  name,
  value,
  label,
  error,
  options,
  handleChange,
  prefix,
  setFieldValue
}: Props) => {
  if (FORM_GEN_ELEMENTS.includes(elementType)) {
    let element: any | null = {
      name: `${prefix}.${name}`,
      value,
      handleChange,
      label: label,
      otherProps: {
        error: !!error,
        helperText: error,
      }
    }
    switch (elementType) {
      case GameTaskStepParamConfigurationFieldType.Date:
        element = {
          ...element,
          type: FormTypes.DATE_TIME,
        }
        break;
      case GameTaskStepParamConfigurationFieldType.Select:
        element = {
          ...element,
          type: FormTypes.SELECT,
          options,
          optionLabelKey: 'label'
        }
        break;
      case GameTaskStepParamConfigurationFieldType.Text:
        element = {
          ...element,
          type: FormTypes.TEXT_INPUT,
        }
        break;
      case GameTaskStepParamConfigurationFieldType.CountryMultiSelect: {
        element = {
          ...element,
          value: value || [],
          type: FormTypes.COUNTRIES_SELECT,
        }
        break;
      }
      case GameTaskStepParamConfigurationFieldType.Number:
        element = {
          ...element,
          type: FormTypes.TEXT_INPUT,
          otherProps: {
            ...element.otherProps,
            type: 'number',
          }
        }
        break;
      case GameTaskStepParamConfigurationFieldType.CheckBox: {
        element = {
          ...element,
          type: FormTypes.CHECKBOX,
        }
        break;
      }
      case GameTaskStepParamConfigurationFieldType.Image:
        element = {
          ...element,
          type: FormTypes.IMAGE,
          imageUrl: value,
          otherProps: {
            ...element.otherProps,
            onComplete: (fileParams: MediaUploadModel): void => {
              handleChange({
                // @ts-expect-error to be decided
                target: {
                  name: `${prefix}.${name}`,
                  value: fileParams.imageUrl!
                }
              })
            },
            removeCrop: true,
            mediaSquareHeight: 400,
            doneLabel: 'Confirm',
            uploadMediaLabel: 'Choose Media',
            button: true,
            hasAttachment: !!value,
            buttonLabel: 'Upload Image'
          }
        }
        break;
      default:
        element = null
    }
    if (element) return <FormGen {...element} />
    return <></>
  }

  if (elementType === GameTaskStepParamConfigurationFieldType.PrizeSelect) {
    return <PrizeSelect
      handleChange={handleChange}
      initialSelectedId={value as string}
      name={`${prefix}.${name}`}
      label={label}
    />
  }

  if (elementType === GameTaskStepParamConfigurationFieldType.IAP) {
    return <IapProductSelect
      handleChange={handleChange}
      initialSelectedId={value as string || null}
      name={`${prefix}.${name}`}
      label={label}
    />
  }

  if (elementType === GameTaskStepParamConfigurationFieldType.IapMultiSelect) {
    return <IapProductMultiselect
      handleChange={handleChange}
      initialSelectedIds={value as string[] || null}
      name={`${prefix}.${name}`}
      label={label}
    />
  }

  return null

}