export enum UserPrizeField {
  ID = 'id',
  USER_ID = 'userId',
  PRIZE_ID = 'prizeId',
  STATUS = 'status',
  SOURCE = 'source',
  SOURCE_ID = 'sourceId',
  SHIPPING_DETAILS = 'shippingDetails',
  FREE_ENTRY = 'freeEntry',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
  UPDATED_BY = 'updatedBy',
  PRIZE = 'prize',
  USER = 'user',
  USER_PRIZE_HISTORIES = 'userPrizeHistories',
  TOKEN_NUMBER = 'tokenNumber',
}
