import React from 'react'
import { Box, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment-timezone'
import { IsNullUndefinedOrEmpty } from 'core/utils/isNullOrUndefined'
import { ImageIcon, WriterIcon } from 'components/icons'
import { IInfoCard } from 'types/modules/info-cards/models/entities/info-card'
import { InfoCardActionButtons } from 'modules/info-cards/views/components/action-buttons'
import { titleCase } from 'core/utils/titleCase'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    infoCardListCard: {
      display: 'flex',
      width: '100%',
      marginBottom: '0.625rem',
      flexDirection: 'row',
      boxShadow: 'none',
      border: '1px solid rgba(0,0,0,0.12)',
      borderRadius: 4,
      '&:last-child': {
        marginBottom: 'calc(48px + 0.625rem)',
      },
    },
    actionArea: {
      display: 'flex',
    },
    iconIndicatorContainer: {
      padding: 7,
      display: 'flex',
      width: 40,
      borderRight: '1px solid rgba(0,0,0,0.12)',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    iconIndicator: {
      display: 'flex',
      width: 24,
      height: 24,
      alignItems: 'center',
      color: 'rgba(0,0,0,0.54)',
    },
    infoCardContent: {
      padding: '0.625rem',
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
    },
    coverImageContainer: {
      display: 'flex',
      width: 80,
      height: 80,
      background: 'rgba(0,0,0,0.03)',
      borderRadius: 90,
      border: '1px solid rgba(0,0,0,0.12)',
      overflow: 'hidden',
      marginRight: '0.625rem',
      justifyContent: 'center',
      alignItems: 'center',
    },
    coverImage: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
    },
    infoCardDetails: {
      display: 'flex',
      flexGrow: 1,
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    actionsAndMetadata: {
      display: 'flex',
      flexShrink: 1,
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    actions: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      '& .MuiButton-root': {
        marginRight: '0.625rem',
        '&:last-of-type': {
          marginRight: 0,
        },
      },
    },
    metadata: {
      display: 'flex',
      flexDirection: 'row-reverse',
    },
    metadataItem: {
      marginLeft: '1.25rem',
    },
    buttonWithIcon: {
      '& .MuiButton-label': {
        position: 'relative',
        top: '.1em',
        '& .MuiButton-startIcon': {
          position: 'relative',
          top: '-.1em',
        },
      },
    },

    writer: {
      marginRight: 10,
      display: 'flex',
      paddingTop: 3,
      justifyContent: 'flex-end',
      width: '100%',
    },
  }),
)

export const InfoCardListItem: React.FC<IInfoCard> = (infoCard: IInfoCard) => {
  const classes = useStyles()

  const renderCoverImage = (): JSX.Element => {
    return infoCard.media?.imageUrl ? (
      <img src={infoCard.media!.imageUrl!} className={classes.coverImage} />
    ) : (
      <Box className={classes.coverImage}>
        <ImageIcon />
      </Box>
    )
  }

  const renderCreatedAt = (): string => {
    const dateFormat = 'D MMMM YYYY HH:mm'
    return moment(infoCard.createdAt).format(dateFormat)
  }

  return (
    <Box className={classes.infoCardListCard}>
      <Box className={classes.infoCardContent}>
        <Box className={classes.coverImageContainer}>{renderCoverImage()}</Box>
        <Box className={classes.infoCardDetails}>
          <Typography variant="subtitle2" color="textPrimary" component="span">
            {infoCard.title}
          </Typography>
          <Typography variant="body2" color="textPrimary" component="span">
            {infoCard.body}
          </Typography>
          <Typography variant="body2" color="textPrimary" component="span">
            {renderCreatedAt()}
          </Typography>
        </Box>
        <Box className={classes.actionsAndMetadata}>
          <Box className={classes.actions}>
            <InfoCardActionButtons infoCard={infoCard} />
          </Box>
          {!IsNullUndefinedOrEmpty(infoCard.createdBy) && (
            <Box className={classes.writer}>
              <Box>
                <Typography
                  variant="body2"
                  color="textPrimary"
                  component="span"
                >
                  <WriterIcon />
                  {` ${titleCase(infoCard.createdBy!)}`}
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  )
}
