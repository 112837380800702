import React from 'react'
import {
  Divider,
  Drawer,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Theme,
  useMediaQuery,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { NavLink } from 'react-router-dom'
import { useIconStyles } from 'hooks/use-icon-styles'
import { useTypedSelector } from 'core/redux/utils'
import { Selectors } from 'modules/app-settings'

const drawerWidth = 250

const config = [
  {
    label: 'Posts',
    route: '/posts/list',
    baseRoute: '/post',
    icon: 'postsIcon',
  },
  {
    label: 'Post groups',
    route: '/post-groups',
    baseRoute: '/post-groups',
    icon: 'postsIcon',
  },
  {
    label: 'Events',
    route: '/events/list',
    baseRoute: '/events',
    icon: 'ticketIcon',
  },
  {
    label: 'Info Cards',
    route: '/info-cards/list',
    baseRoute: '/info-cards',
    icon: 'newspaperIcon',
  },
  {
    label: 'Tags',
    route: '/tags',
    baseRoute: '/tags',
    icon: 'tagsIcon',
  },
  {
    label: 'Prizes',
    route: '/prizes/list',
    baseRoute: '/prizes',
    icon: 'prizeIcon',
    warningSource: ['Chest', 'Carrot', 'Box', 'Inactive user'],
  },
  {
    label: 'Shipping',
    route: '/shipping/list',
    baseRoute: '/shipping',
    icon: 'shippingIcon',
  },
  {
    label: 'Watchlist',
    route: '/watchlist',
    baseRoute: '/watchlist',
    disabled: true,
    icon: 'tvIcon',
  },
  {
    label: 'App Users',
    route: '/mobile-app-users',
    baseRoute: '/mobile-app-users',
    icon: 'sweepsIcon',
  },

  {
    label: 'Quests',
    route: '/quests',
    baseRoute: '/quests',
    icon: 'chestIcon',
  },

  {
    label: 'News Cards',
    route: '/news-cards',
    baseRoute: '/news-cards',
    icon: 'layerPlusIcon',
  },

  {
    label: 'IAP products',
    route: '/iap-products',
    baseRoute: '/iap-products',
    icon: 'coinIcon',
  },

  {
    label: 'Milestones',
    route: '/milestones',
    baseRoute: '/milestones',
    icon: 'sweepsIcon',
  },

  {
    label: 'Campaigns',
    route: '/campaigns',
    baseRoute: '/campaigns',
    icon: 'sweepsIcon',
  },
]

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    drawerModal: {
      zIndex: 1200,
    },
    drawerPaper: {
      width: drawerWidth,
      zIndex: 900,
    },
    drawerContainer: {
      marginTop: 65,
      overflow: 'auto',
    },
    navLinks: {
      padding: 0,
    },
    navLink: {
      padding: '16px 20px',
      fontSize: 12,
    },
    navIcon: {
      minWidth: 'initial',
      width: 25,
      height: 25,
      marginRight: 18,
    },
    navLinkText: {
      textDecoration: 'none',
    },
    navLinkAnchor: {
      display: 'flex',
      textDecoration: 'none',
      color: theme.palette.text.primary,
      '&:active, &:hover': {
        color: theme.palette.text.primary,
      },
    },
    activeLink: {
      background: theme.palette.secondary.main,
    },
    warningIndicator: {
      backgroundColor: '#f78c00',
      width: 12,
      height: 12,
      borderRadius: '50%',
    },
  }),
)

interface INavigationProps {
  mobileNavOpen: boolean
  setMobileNavOpen: (open: boolean) => void
}

const Navigation: React.FC<INavigationProps> = (props: INavigationProps) => {
  const classes = useStyles()
  const iconClasses = useIconStyles()
  const warnings = useTypedSelector((state) =>
    Selectors.getAppWarningCountMap(state),
  )

  const renderNavContent = (): JSX.Element => {
    return (
      <React.Fragment>
        <div className={classes.drawerContainer}>
          <List className={classes.navLinks}>
            {config.map((value, index) => (
              <NavLink
                key={value.label}
                to={value.route}
                // exact={value.exact}
                isActive={(match, location): boolean => {
                  if (match) return true
                  return location.pathname === value.baseRoute
                }}
                activeClassName={classes.activeLink}
                className={classes.navLinkAnchor}
              >
                <ListItem button className={classes.navLink}>
                  <ListItemIcon className={classes.navIcon}>
                    <i className={iconClasses[value.icon]} />
                  </ListItemIcon>
                  <ListItemText
                    primary={value.label}
                    className={classes.navLinkText}
                  />
                  {!!value.warningSource &&
                    value.warningSource.some(
                      (source) => !!warnings[source],
                    ) && <span className={classes.warningIndicator}></span>}
                </ListItem>
                <Divider />
              </NavLink>
            ))}
          </List>
        </div>
      </React.Fragment>
    )
  }

  const mobile = useMediaQuery('(max-width: 600px)')

  return (
    <nav className={classes.drawer}>
      <Hidden smUp implementation="css">
        <Drawer
          variant="temporary"
          anchor="left"
          open={mobile && props.mobileNavOpen}
          onClose={(): void => {
            props.setMobileNavOpen(false)
          }}
          classes={{
            paper: classes.drawerPaper,
          }}
          PaperProps={{ elevation: 4 }}
          ModalProps={{
            keepMounted: true,
            style: {
              zIndex: 1200,
            },
          }}
        >
          {renderNavContent()}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
          PaperProps={{ elevation: 4 }}
        >
          {renderNavContent()}
        </Drawer>
      </Hidden>
    </nav>
  );
}

export default Navigation
