import { mapMimeType } from 'core/utils/mime-mapper'
import { createImage } from 'modules/media-items/utils/create-image'
import { getRadianAngle } from 'modules/media-items/utils/get-radian-angle'
import { rotateSize } from 'modules/media-items/utils/rotate-size'
import { Area } from 'react-easy-crop/types'

export default async function getCroppedImage(
  imageSrc: string,
  pixelCrop: Area,
  rotation = 0,
  flip = { horizontal: false, vertical: false },
): Promise<string | null> {
  const image = await createImage(imageSrc)
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  if (!ctx) {
    return null
  }

  const rotRad = getRadianAngle(rotation)

  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
    image.width,
    image.height,
    rotation,
  )

  canvas.width = bBoxWidth
  canvas.height = bBoxHeight

  ctx.translate(bBoxWidth / 2, bBoxHeight / 2)
  ctx.rotate(rotRad)
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1)
  ctx.translate(-image.width / 2, -image.height / 2)

  ctx.drawImage(image, 0, 0)

  const data = ctx.getImageData(
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
  )

  canvas.width = pixelCrop.width
  canvas.height = pixelCrop.height

  ctx.putImageData(data, 0, 0)

  const fileTypeExtension = imageSrc
    ?.split(/[#?]/)[0]
    ?.split('.')
    ?.pop()
    ?.trim()

  // As Base64 string
  return fileTypeExtension
    ? canvas.toDataURL(mapMimeType(fileTypeExtension!)).split(';base64,')[1]
    : null

  // As a blob
  // return new Promise((resolve, reject) => {
  //   canvas.toBlob((file) => {
  //     resolve(URL.createObjectURL(file!));
  //   }, 'image/jpeg');
  // });
}
