import React from 'react'
import { useTypedSelector } from 'core/redux/utils'
import { useIapProductsContext } from 'modules/iap-products/context/iap-product-context'
import {
  IapProduct,
  StyledTableCell,
} from 'modules/iap-products/view/iap-product'
import { IapProductModal } from 'modules/iap-products/view/iap-product-modal'
import { IIapProduct } from 'modules/iap-products/models/iap-product'
import { LocalListWrapper } from 'components/local-list-wrapper'
import { IapProductActions, Selectors } from '..'

const sortOptions = [
  { name: 'Created at', value: 'createdAt', descending: true },
  { name: 'id', value: 'id' },
]

export const IapProductList: React.FC<{}> = () => {
  const list = useTypedSelector((state) => Selectors.getIapProducts(state))
  const count = useTypedSelector((state) =>
    Selectors.getIapProductsCount(state),
  )
  const { currentIapProduct, createIapProductDraft, setCurrentIapProduct } =
    useIapProductsContext()

  return (
    <LocalListWrapper<
      IIapProduct,
      IapProductActions.IIapProductActionParams,
      null
    >
      list={list}
      count={count}
      searchKey="id"
      currentItem={currentIapProduct}
      createDraft={createIapProductDraft}
      setCurrentItem={setCurrentIapProduct}
      CardComponent={IapProduct}
      ModalComponent={IapProductModal}
      getItems={IapProductActions.getIapProducts}
      searchLabel="Product ID"
      title="IAP Products"
      sortOptions={sortOptions}
      panelXs={12}
    >
      <>
        <StyledTableCell>Product ID</StyledTableCell>
        <StyledTableCell>Description</StyledTableCell>
        <StyledTableCell>Status</StyledTableCell>
      </>
    </LocalListWrapper>
  )
}
