import { ActionTypeSuffix } from 'core/redux/constants'
import { withoutSuffix } from 'core/redux/utils'
import _ from 'core/utils/deepdash'
import { Reducer } from 'redux'
import { IRequestAction, ISuccessAction } from 'types/redux/interfaces/IAction'

type AllowedActionTypes = IRequestAction | ISuccessAction

const RequestsReducer: Reducer<IRequestAction[], AllowedActionTypes> = (
  state = [],
  action: AllowedActionTypes,
) => {
  // let newState = _.clone(state);
  // if (action.type.endsWith(ActionTypeSuffix.REQUEST)) {

  //   const requestAction = action as IRequestAction;
  //   newState.push(requestAction);
  //   return newState;
  // }

  // if (action.type.endsWith(ActionTypeSuffix.SUCCESS)) {
  //   const successAction = action as ISuccessAction;
  //   newState = _.filter(newState, requestAction => {
  //     return !(
  //       _.isEqual(
  //         withoutSuffix(requestAction.type),
  //         withoutSuffix(successAction.type)
  //       ) &&
  //       _.isEqual(
  //         requestAction.payload,
  //         successAction.payload
  //       )
  //     );
  //   }) || [];
  //   return newState;
  // }

  return state
}

export default RequestsReducer
