import React from 'react'
import { PanelContainer, Panel } from 'components/panel'
import { RequestContextProvider } from 'core/api/context'
import { PrizesDashboard } from 'modules/prizes/views/components/dashboard'
import { ChestsContextProvider } from 'modules/chests/context'
import { PrizeListContextProvider } from 'modules/prizes/context/prize-list'
import { PrizeManagerContextProvider } from 'modules/prizes/context/prize-manager'
import { PrizeStatusManagerContextProvider } from 'modules/prizes/context/prize-status-manager'

export const PrizesDashboardPage: React.FC<{}> = () => {
  return (
    <RequestContextProvider>
      <PrizeListContextProvider>
        <PrizeManagerContextProvider>
          <PrizeStatusManagerContextProvider>
            <ChestsContextProvider>
              <PanelContainer>
                <Panel container xs={12}>
                  <PrizesDashboard />
                </Panel>
              </PanelContainer>
            </ChestsContextProvider>
          </PrizeStatusManagerContextProvider>
        </PrizeManagerContextProvider>
      </PrizeListContextProvider>
    </RequestContextProvider>
  )
}
