/* Lib */
import { TypedUseSelectorHook, useSelector } from 'react-redux'
import * as uuid from 'uuid'

/* Core */
import { ActionTypeSuffix } from 'core/redux/constants'

/* Types */
import { AppState } from 'types/redux/types/app-state'
import {
  IAsyncActionCreator,
  IFailedActionCreator,
  IRequestActionCreator,
  ISimpleActionCreator,
  ISuccessActionCreator,
} from 'types/common/api/models/entities/IActionCreator'
import moment from 'moment-timezone'
import { Version } from '../../types/common/enums/versions'

/**
 * Bootstraps AppState into a typed use selector hook.
 */
export const useTypedSelector: TypedUseSelectorHook<AppState> = useSelector

/**
 * Appends the action type 'REQUEST' suffix to an action type.
 * Used to generate action types and determine action type in reducers.
 * @param actionType - The action type of a related action
 */
export const actionTypeRequest: (actionType: string) => string = (actionType) =>
  `${actionType}${ActionTypeSuffix.REQUEST}`

/**
 * Appends the action type 'SUCCESS' suffix to an action type.
 * Used to generate action types and determine action type in reducers.
 * @param actionType - The action type of a related action
 */
export const actionTypeSuccess: (actionType: string) => string = (actionType) =>
  `${actionType.replace(ActionTypeSuffix.REQUEST, '')}${
    ActionTypeSuffix.SUCCESS
  }`

/**
 * Removes all defined suffixes from action type.
 * Primarily used for matching async action types.
 * @param actionType - The action type
 */
export const withoutSuffix: (actionType: string) => string = (actionType) => {
  Object.values(ActionTypeSuffix).forEach((suffix) => {
    actionType = actionType.replace(suffix, '')
  })

  return actionType
}

/**
 * Appends the action type 'FAILED' suffix to an action type.
 * Used to generate action types and determine action type in reducers.
 * @param actionType - The action type of a related action
 */
export const actionTypeFailed: (actionType: string) => string = (actionType) =>
  `${actionType.replace(ActionTypeSuffix.REQUEST, '')}${
    ActionTypeSuffix.FAILED
  }`

/**
 * Creates a simple action
 * @param type - The action type
 * @param payload - The action payload
 */
export const createAction: ISimpleActionCreator = (type, payload = null) => ({
  id: uuid.v4(),
  type,
  payload,
  timestamp: moment().toISOString(),
})

/**
 * Creates an async action
 * @param type - The action type
 * @param endpoint - The associated endpoint route
 * @param payload - The action paylod
 */
export const createAsyncAction: IAsyncActionCreator = ({
  type,
  endpoint,
  payload = null,
  alertsEnabled = true,
  finalCallback,
  version = Version.V1
}) => ({
  id: uuid.v4(),
  type,
  endpoint,
  payload,
  async: true,
  alertsEnabled,
  timestamp: moment().toISOString(),
  finalCallback,
  version
})

/**
 * Create a request action from an async action and appends request parameters.
 * @param asyncAction - The related async action.
 * @param requestParams - Parameters for the api request.
 */
export const createRequestAction: IRequestActionCreator = (
  asyncAction,
  requestParams,
) => ({
  ...asyncAction,
  requestParams,
  type: actionTypeRequest(asyncAction.type),
  timestamp: moment().toISOString(),
})

/**
 * Creates a success action from a request action and appends response data.
 * @param requestAction - The related request action.
 * @param data - Api response data.
 */
export const createSuccessAction: ISuccessActionCreator = (
  requestAction,
  data,
) => ({
  ...requestAction,
  data,
  type: actionTypeSuccess(requestAction.type),
  timestamp: moment().toISOString(),
})

/**
 * Creates an error action from a request action and appends error information.
 * @param requestAction - The related request action.
 * @param error - Api response error
 */
export const createFailedAction: IFailedActionCreator = (
  requestAction,
  error,
) => ({
  ...requestAction,
  error,
  type: actionTypeFailed(requestAction.type),
  timestamp: moment().toISOString(),
})

export const cacheItem = <TType>(
  cache: { [key: string]: TType },
  item: TType,
  cacheKey?: string,
): { [key: string]: TType } => {
  const newCache = { ...cache }

  newCache[(item as any)[cacheKey ?? 'id']] = item

  return newCache
}

export const cacheItems = <TType>(
  cache: { [key: string]: TType },
  items: TType[],
  cacheKey?: string,
): { [key: string]: TType } => {
  let newCache = { ...cache };

  (items || []).forEach((item: TType) => {
    newCache = cacheItem(newCache, item, cacheKey)
  })

  return newCache
}
