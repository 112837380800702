import React from 'react'
import { Box, Chip, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment-timezone'
import {
  IsNullOrUndefined,
  IsNullUndefinedOrEmpty,
} from 'core/utils/isNullOrUndefined'
import clsx from 'clsx'
import { ImageIcon } from 'components/icons'
import { IUserPrize } from 'types/modules/shipping/models/entities/user-prize'
import { UserPrizeActionButtons } from 'modules/shipping/views/components/action-buttons'
import { getSourceLabel } from 'modules/shipping/utils/get-source-label'
import { OrderInfo } from 'modules/shipping/views/components/details/order-info'
import { InfoWrapper } from 'modules/shipping/views/components/details/info-wrapper'
import { UserInfo } from 'modules/shipping/views/components/details/user-info'
import { ShippingInfo } from 'modules/shipping/views/components/details/shipping-info'
import { SourceInfo } from 'modules/shipping/views/components/details/source-info'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    userPrizeListCard: {
      display: 'flex',
      width: '100%',
      marginBottom: '0.625rem',
      flexDirection: 'row',
      boxShadow: 'none',
      border: '1px solid rgba(0,0,0,0.12)',
      borderRadius: 4,
      '&:last-child': {
        marginBottom: 'calc(48px + 0.625rem)',
      },
    },
    actionArea: {
      display: 'flex',
    },
    iconIndicatorContainer: {
      padding: 7,
      display: 'flex',
      width: 40,
      borderRight: '1px solid rgba(0,0,0,0.12)',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    iconIndicator: {
      display: 'flex',
      width: 24,
      height: 24,
      alignItems: 'center',
      color: 'rgba(0,0,0,0.54)',
    },
    userPrizeContent: {
      padding: '0.625rem',
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
    },
    coverImageContainer: {
      display: 'flex',
      width: 80,
      height: 80,
      background: 'rgba(0,0,0,0.03)',
      borderRadius: 90,
      border: '1px solid rgba(0,0,0,0.12)',
      overflow: 'hidden',
      marginRight: '0.625rem',
      justifyContent: 'center',
      alignItems: 'center',
    },
    coverImage: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
    },
    userPrizeDetails: {
      display: 'flex',
      flexGrow: 1,
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
    actionsAndMetadata: {
      display: 'flex',
      flexShrink: 1,
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    actions: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      '& .MuiButton-root': {
        marginRight: '0.625rem',
        '&:last-of-type': {
          marginRight: 0,
        },
      },
    },
    metadata: {
      display: 'flex',
      flexDirection: 'row-reverse',
    },
    metadataItem: {
      marginLeft: '1.25rem',
    },
    buttonWithIcon: {
      '& .MuiButton-label': {
        position: 'relative',
        top: '.1em',
        '& .MuiButton-startIcon': {
          position: 'relative',
          top: '-.1em',
        },
      },
    },
  }),
)

export const UserPrizeListItem: React.FC<IUserPrize> = (
  userPrize: IUserPrize,
) => {
  const classes = useStyles()

  const renderCoverImage = (): JSX.Element => {
    return userPrize.prize.item.details?.imageUrl ? (
      <img
        src={userPrize.prize.item.details!.imageUrl!}
        className={classes.coverImage}
      />
    ) : (
      <Box className={classes.coverImage}>
        <ImageIcon />
      </Box>
    )
  }

  return (
    <Box className={classes.userPrizeListCard}>
      <Box className={classes.userPrizeContent}>
        <Box className={classes.coverImageContainer}>{renderCoverImage()}</Box>
        <Box className={classes.userPrizeDetails}>
          <InfoWrapper xs={6} label="Order">
            <OrderInfo userPrize={userPrize} />
          </InfoWrapper>
        </Box>
        <Box className={classes.userPrizeDetails}>
          <InfoWrapper xs={6} label="User Info">
            <UserInfo userPrize={userPrize} />
          </InfoWrapper>
        </Box>
        <Box className={classes.userPrizeDetails}>
          <InfoWrapper xs={6} label="Shipping Address">
            <ShippingInfo userPrize={userPrize} />
          </InfoWrapper>
        </Box>
        <Box className={classes.userPrizeDetails}>
          <InfoWrapper xs={6} label="Source">
            <SourceInfo userPrize={userPrize} />
          </InfoWrapper>
        </Box>
        <Box className={classes.actionsAndMetadata}>
          <Box className={classes.actions}>
            <UserPrizeActionButtons userPrize={userPrize} />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
