import React, { PropsWithChildren, ReactElement } from 'react'
import { List, ListItem, ListItemText } from '@mui/material'

import { IAutoCompleteListProps } from 'components/controls/inputs/downshift/autocomplete/autocomplete-list-props'
import { useStyles } from 'components/controls/inputs/downshift/styles'
import {
  Item,
  RequiredItemProps,
} from 'components/controls/inputs/downshift/item'
import { get } from 'lodash-es'

const AutocompleteList = <TItem,>(
  props: PropsWithChildren<IAutoCompleteListProps<Item<TItem>>>,
): ReactElement<any, any> | null => {
  const classes = useStyles()

  const listItemContent = (item: Item<TItem>): string => {
    return props.autocompleteSearchResultContent
      ? props.autocompleteSearchResultContent(item)
      : get((item as unknown as RequiredItemProps), props.fieldName || 'name')
  }

  return props.isOpen ? (
    <List className={classes.autoCompleteList} {...props.getMenuProps()}>
      {props.inputItems.map((item: Item<TItem>, index: number) => {
        const { onClick, ...rest } = props.getItemProps({
          item: item,
          index: index,
          style: {
            backgroundColor:
              props.highlightedIndex === index ? '#dcdcdc' : '#efefef',
          },
        })
        const disabled = props?.optionDisabled && props?.optionDisabled(item)
        return (
          <ListItem
            key={`${(item as unknown as RequiredItemProps).name}-${index}`}
            {...rest}
            onClick={disabled ? (): void => {} : onClick}
            disabled={disabled}
          >
            <ListItemText primary={listItemContent(item)} />
          </ListItem>
        )
      })}
    </List>
  ) : (
    <div {...props.getMenuProps()}></div>
  )
}

export default AutocompleteList
