import React, { PropsWithChildren } from 'react'
import { Box, Theme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    panelContent: {
      display: 'flex',
      width: '100%',
      padding: '1.25rem',
      flexGrow: 1,
      flexDirection: 'column',
      overflow: 'auto',
    },
  }),
)

const PanelContent: React.FC<PropsWithChildren<{ id?: string }>> = (
  props: PropsWithChildren<{ id?: string }>,
) => {
  const classes = useStyles()

  return (
    <Box id={props.id ?? ''} className={classes.panelContent}>
      {props.children ?? null}
    </Box>
  )
}

export default PanelContent
