import ActionTypes from 'modules/milestones/action-types/milestone-action-types'
import { Reducer } from 'redux'
import { actionTypeSuccess, cacheItem, cacheItems } from 'core/redux/utils'
import { ISimpleAction, ISuccessAction } from 'types/redux/interfaces/IAction'
import _ from 'core/utils/deepdash'
import { mergeArrays } from 'core/utils/mergeArrays'
import { IMilestoneActionParams } from 'modules/milestones/actions/milestone-actions'
import { IMilestoneState } from 'modules/milestones/models/state'
import { IMilestone } from 'modules/milestones/models/milestone'

const pageSize = 20

const initialState: IMilestoneState = {
  count: 0,
  page: 0,
  pageSize: pageSize,
  items: [],
  cache: {},
}

export type GetMilestonesApiResponse = {
  count: number
  page: number
  pageSize: number
  items: IMilestone[]
}

type ListResponse = { data: { items: IMilestone[] } }
type AllowedActionTypes = ISuccessAction | ISimpleAction

const handleUpdate = (state: IMilestoneState,
    action: AllowedActionTypes): IMilestoneState => {
  const successAction = action as ISuccessAction
    const payloadItem = successAction.data as IMilestone
    const itemIndex = state.items.findIndex((x) => x.id === payloadItem.id)

    return {
      ...state,
      items:
        itemIndex >= 0
          ? state.items.map((item, index) =>
              index === itemIndex ? payloadItem : item,
            )
          : [...state.items, payloadItem],
      cache: cacheItem(state.cache, payloadItem),
    }
}

const actionHandlers = {
  [ActionTypes.SET_CURRENT_MILESTONE_ID]: (
    state: IMilestoneState,
    action: AllowedActionTypes,
  ): IMilestoneState => {
    const simpleAction = action as ISimpleAction
    const { milestoneId } =
      simpleAction.payload as IMilestoneActionParams
    return {
      ...state,
      currentMilestoneId: milestoneId ? milestoneId : undefined,
    }
  },
  [actionTypeSuccess(ActionTypes.GET_MILESTONES)]: (
    state: IMilestoneState,
    action: AllowedActionTypes,
  ): IMilestoneState => {
    const successAction = action as unknown as ListResponse
    const response = successAction.data.items as IMilestone[]
    return {
      ...state,
      items: mergeArrays(state.items, response),
      cache: cacheItems(state.cache, response),
    }
  },
  [actionTypeSuccess(ActionTypes.GET_MILESTONE_BY_ID)]: (
    state: IMilestoneState,
    action: AllowedActionTypes,
  ): IMilestoneState => {
    const successAction = action as ISuccessAction
    const response = successAction.data as IMilestone
    return {
      ...state,
      cache: cacheItem(state.cache, response),
    }
  },
  [actionTypeSuccess(ActionTypes.UPDATE_MILESTONE)]: (
    state: IMilestoneState,
    action: AllowedActionTypes,
  ): IMilestoneState => handleUpdate(state, action),
}

const milestonesReducer: Reducer<IMilestoneState, AllowedActionTypes> = (
  state = initialState,
  action: AllowedActionTypes,
) => {
  return actionHandlers[action.type]
    ? actionHandlers[action.type](state, action)
    : state
}

export default milestonesReducer
