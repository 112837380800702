import { AppState } from 'types/redux/types/app-state'
import { ITagState } from 'types/modules/tags/models/state/ITagState'
import { MODULE_NAME } from 'modules/tags/constants'
import { Tag } from 'types/modules/tags/models/entities/tag'
import { TagGroup } from 'types/modules/tags/models/entities/tag-group'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { NIL as NIL_UUID } from 'uuid'

const getState = (state: AppState): ITagState => state[MODULE_NAME]

export const getCurrentTag = (state: AppState): Tag | null => {
  const { tags } = getState(state)

  if (IsNullOrUndefined(tags.currentTagId)) return null

  if (tags.currentTagId === NIL_UUID) {
    return {
      id: NIL_UUID,
      name: '',
      code: '',
      level: 3,
      media: {
        icon: '',
      },
    }
  }

  const currentTag = tags.cache[tags.currentTagId!]

  return currentTag ?? null
}

export const getTags = (state: AppState): ITagState['tags'] =>
  getState(state).tags

export const getAutocompleteSearchTags = (state: AppState): Tag[] =>
  Object.values(getTags(state).cache)

export const getTagGroups = (state: AppState): ITagState['tagGroups'] =>
  getState(state).tagGroups

export const getAutocompleteSearchTagGroups = (state: AppState): TagGroup[] => {
  return Object.values(getTagGroups(state).cache)
}

export const getTagGroupById = (
  state: AppState,
  tagGroupId?: string,
): TagGroup | null =>
  getTagGroups(state).items?.find((tagGroup) => tagGroup.id === tagGroupId) ??
  null

export const getCachedTagGroups = (state: AppState): TagGroup[] =>
  Object.values(getTagGroups(state).cache)

export const getCachedTagGroupById = (
  state: AppState,
  tagGroupId?: string,
): TagGroup | null =>
  getCachedTagGroups(state).find((tagGroup) => tagGroup.id === tagGroupId) ??
  null

export const getTagPromotion = (state: AppState): ITagState['tagPromotion'] =>
  getState(state).tagPromotion

export const getTagSuggestion = (state: AppState): ITagState['tagSuggestion'] =>
  getState(state).tagSuggestion

export const getTagsByTagIds = (
  state: AppState,
  tagIds: string[] = [],
): Tag[] =>
  tagIds.map((tagId: string) => getTags(state).cache[tagId]).filter(Boolean)

export const getTagGetter = (state: AppState): (tagId: string) => Tag => {
  return (tagId: string): Tag => getState(state).tags.cache[tagId]
}
