/* Libs */
import { all, AllEffect, ForkEffect, takeEvery } from 'redux-saga/effects'

/* Module */
import ActionTypes from '../action-types'
import { GetAppSettingsSaga } from './get-app-settings-saga'

type RootSagaReturnType = Generator<
  ForkEffect<never> | AllEffect<any>,
  void,
  any
>

export const RootSaga = function* (): RootSagaReturnType {
  yield all([
    yield takeEvery(ActionTypes.GET_PORTAL_DEFAULTS, GetAppSettingsSaga),
    yield takeEvery(ActionTypes.GET_PORTAL_WARNINGS, GetAppSettingsSaga),
  ])
}
