import { UserPrizeStatus } from 'types/modules/shipping/enums/user-prize-status'

export const getStatusLabel = (status: UserPrizeStatus | string): string => {
  switch (status) {
    case UserPrizeStatus.ReadyToShip:
      return 'Ready to Ship'

    default:
      return status
  }
}
