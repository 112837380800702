import GameTaskEndpoints from 'core/api/endpoints/game-tasks'
import GameTaskActionTypes from 'modules/quests/action-types/game-task-action-types'
import { createAction, createAsyncAction } from 'core/redux/utils'
import { IAsyncAction, ISimpleAction } from 'types/redux/interfaces/IAction'
import { IAsyncActionParams } from 'types/common/api/models/entities/async-action-params'
import { GameTask, GameTaskStep } from 'fe-shared-resources'
import { Version } from 'types/common/enums/versions'

export interface IGameTaskActionParams
  extends IAsyncActionParams<GameTask | GameTaskStep> {
  gameTaskId?: string
  gameTaskStepId?: string
}

export const setCurrentGameTaskId: (
  params: IGameTaskActionParams,
) => ISimpleAction = (params) =>
  createAction(GameTaskActionTypes.SET_CURRENT_GAME_TASK_ID, params)

export const getGameTasks: (
  params: IGameTaskActionParams,
) => IAsyncAction = (params) =>
  createAsyncAction({
    type: GameTaskActionTypes.GET_GAME_TASKS,
    endpoint: GameTaskEndpoints.getGameTasks,
    payload: params,
    version: Version.V2
  })

export const getGameTaskSteps: (
  params: IGameTaskActionParams,
) => IAsyncAction = (params) =>
  createAsyncAction({
    type: GameTaskActionTypes.GET_GAME_TASK_STEPS,
    endpoint: GameTaskEndpoints.getGameTaskSteps,
    payload: params,
    version: Version.V2
  })

export const getGameTaskById: (
  params: IGameTaskActionParams,
) => IAsyncAction = (params) =>
  createAsyncAction({
    type: GameTaskActionTypes.GET_GAME_TASK_BY_ID,
    endpoint: GameTaskEndpoints.getGameTaskById,
    payload: params,
    version: Version.V2
  })

export const createGameTaskDraft: (
  params: IGameTaskActionParams,
) => ISimpleAction = (params) =>
  createAction(GameTaskActionTypes.CREATE_GAME_TASK_DRAFT, params)

export const createGameTask: (
  params: IGameTaskActionParams,
) => IAsyncAction = (params) =>
  createAsyncAction({
    type: GameTaskActionTypes.CREATE_GAME_TASK,
    endpoint: GameTaskEndpoints.createGameTask,
    payload: params,
    version: Version.V2
  })

export const updateGameTask: (
  params: IGameTaskActionParams,
) => IAsyncAction = (params) =>
  createAsyncAction({
    type: GameTaskActionTypes.UPDATE_GAME_TASK,
    endpoint: GameTaskEndpoints.updateGameTask,
    payload: params,
    version: Version.V2
  })

export const createGameTaskStep: (
  params: IGameTaskActionParams,
) => IAsyncAction = (params) =>
  createAsyncAction({
    type: GameTaskActionTypes.CREATE_GAME_TASK_STEP,
    endpoint: GameTaskEndpoints.createGameTaskStep,
    payload: params,
    version: Version.V2
  })

export const updateGameTaskStep: (
  params: IGameTaskActionParams,
) => IAsyncAction = (params) =>
  createAsyncAction({
    type: GameTaskActionTypes.UPDATE_GAME_TASK_STEP,
    endpoint: GameTaskEndpoints.updateGameTaskStep,
    payload: params,
    version: Version.V2
  })

export const uploadGameTaskImage: (params: IGameTaskActionParams) => IAsyncAction = (
  params,
) =>
  createAsyncAction({
    type: GameTaskActionTypes.UPLOAD_GAME_TASK_IMAGE,
    endpoint: GameTaskEndpoints.uploadGameTaskImage,
    payload: params,
    version: Version.V2
  })
