export enum LeaderboardPositionRewardField {
  ID = 'id',
  LEADERBOARD_ID = 'leaderboardId',
  START = 'start',
  END = 'end',
  TYPE = 'type',
  COINS = 'coins',
  STARS = 'stars',
  PRIZES = 'prizes',
  GOLD_BOX = 'goldBox',
  BROWN_BOX = 'brownBox',
  UPDATED_AT = 'updatedAt',
}
