import React from 'react'
import { useGossEditor } from 'components/goss-editor/context'
import { Button, SvgIcon } from '@mui/material'
import { MarkType } from 'components/goss-editor/types/mark-type'
import clsx from 'clsx'
import { ToolbarButton } from 'components/goss-editor/views/toolbar/toolbar-button'
import { useSlate } from 'slate-react'

interface IMarkButtonProps {
  format: MarkType
  icon: JSX.Element
}

const MarkButton: React.FC<IMarkButtonProps> = ({ format, icon }) => {
  const { isMarkActive, toggleMark } = useGossEditor()

  const editor = useSlate()

  return (
    <ToolbarButton
      active={isMarkActive(format, editor)}
      onClick={(event): void => {
        event.preventDefault()
        toggleMark(format, editor)
      }}
    >
      <SvgIcon style={{ fontSize: 16 }}>{icon}</SvgIcon>
    </ToolbarButton>
  )
}

export default MarkButton
