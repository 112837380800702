import React from 'react'
import { Theme, Box, Card, CardActionArea, CardContent, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Ticket } from 'modules/mobile-app-users/models/entities/ticket'
import { SimpleImageDisplay } from 'components/simple-image-display'
import { colors } from 'utils/colors'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      width: '100%',
      marginBottom: '1.25rem',
      overflow: 'initial',
      boxShadow: 'none',
    },
    notSelectedCard: {
      border: `1px solid ${colors.rgba_0_0_0_12}`,
    },
    selectedCard: {
      border: '1px solid red',
    },
    detailsWrapper: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
    },
    header: {
      display: 'flex',
      width: '100%',
    },
    detail: {
      fontSize: 12,
    },
    cardContent: {
      marginTop: '1.25rem',
      display: 'flex',
      overflow: 'hidden',
      minHeight: 36,
    },
    contentWrapper: {
      display: 'flex',
    },
    cardActionArea: {
      height: '100%',
    },
    title: {
      overflowWrap: 'anywhere',
    },
  }),
)

interface ICardProps {
  ticket: Ticket
  onClick: (ticketId: string) => void
  selected: boolean
}

export const TicketCard: React.FC<ICardProps> = (props: ICardProps) => {
  const { ticket, onClick, selected } = props
  const { post } = ticket
  const classes = useStyles()

  return (
    <Card
      className={`${classes.card} ${
        selected ? classes.selectedCard : classes.notSelectedCard
      }`}
    >
      <CardActionArea
        className={classes.cardActionArea}
        onClick={(): void => onClick(ticket.id)}
      >
        <CardContent>
          <Box className={classes.contentWrapper}>
            <SimpleImageDisplay imageUrl={post.media!.imageUrl} />
            <Box className={classes.detailsWrapper}>
              <Box>
                <Typography
                  variant="subtitle2"
                  color="textPrimary"
                  className={classes.title}
                >
                  {post.title}
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="subtitle2"
                  color="textSecondary"
                  className={classes.detail}
                >
                  Post status: {post.status}
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="subtitle2"
                  color="textSecondary"
                  className={classes.detail}
                >
                  Ticket status: {ticket.status}
                </Typography>
              </Box>
            </Box>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}
