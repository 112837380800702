import { RootSagaReturnType } from 'core/redux/rootSaga'
import ActionTypes from 'modules/events/action-types'
import { GetTagsSaga } from 'modules/tags/sagas/get-tags-saga'
import { all, takeEvery, takeLatest } from 'redux-saga/effects'

const enabledAsyncActions = [
  ActionTypes.GET_EVENT_STATUSES,
  ActionTypes.GET_EVENT_BY_ID,
  ActionTypes.CREATE_EVENT,
  ActionTypes.UPDATE_EVENT,
  ActionTypes.UPDATE_EVENT_STATUS,
  ActionTypes.UPLOAD_EVENT_IMAGE,
  ActionTypes.UPLOAD_EVENT_PASS_IMAGE,
  ActionTypes.AUTOCOMPLETE_EVENT_SEARCH,
  ActionTypes.UPLOAD_DEFAULT_LIVE_SCHEDULE_IMAGE,
  ActionTypes.PROMOTE_EVENT,
]

export const RootSaga = function* (): RootSagaReturnType {
  yield all([
    yield takeEvery(enabledAsyncActions, GetTagsSaga),
    yield takeLatest(ActionTypes.GET_EVENTS, GetTagsSaga),
  ])
}
