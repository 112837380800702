import { Box, ButtonBase, SvgIcon, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx'
import { useEvergreenQuestsContext } from 'modules/quests/context/evergreen-quest-context'
import React, { useMemo } from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    navItemButton: {
      display: 'flex',
      padding: 16,
    },
    levelNavigationItem: {
      display: 'flex',
      width: 40,
      height: 40,
      borderRadius: 90,
      alignItems: 'center',
      justifyContent: 'center',
      background: 'rgba(0,0,0,0.03)',
      color: theme.palette.text.primary,
      '&.active': {
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      },
    },
    navItemText: {
      fontSize: '0.8rem',
    },
    navItemIcon: {
      fontSize: 16,
    },
    disabled: {
      color: '#efefef'
    }
  }),
)

interface IEvergreenQuestLevelSwitchProps {
  evergreenQuestLevelId?: string
  active: boolean
  onClick: (evergreenQuestLevelId?: string) => void
  disabled?: boolean,
  Icon?: JSX.Element
}

export const EvergreenQuestLevelSwitch: React.FC<IEvergreenQuestLevelSwitchProps> = (
  {
    evergreenQuestLevelId,
    disabled,
    active,
    onClick,
    Icon
  }: IEvergreenQuestLevelSwitchProps,
) => {
  const classes = useStyles()

  const { evergreenQuestLevels } = useEvergreenQuestsContext()
  const evergreenQuestLevel = useMemo(
    () => evergreenQuestLevels.find((level) => level.id === evergreenQuestLevelId),
    [evergreenQuestLevels.length]
  )
  return (
    <ButtonBase
      disabled={disabled}
      className={clsx(classes.navItemButton, active && 'active')}
      onClick={(): void => onClick(evergreenQuestLevelId)}
    >
      <Box
        className={clsx(classes.levelNavigationItem, active && 'active')}
      >
        {
          !Icon
            ? <Typography className={`${classes.navItemText} ${disabled && classes.disabled}`}>
              L{evergreenQuestLevel?.level}
            </Typography>
            : <SvgIcon color={disabled ? 'disabled' : 'inherit'}>
              {Icon}
            </SvgIcon>
        }
      </Box>
    </ButtonBase>
  )
}
