import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import {
  ProductStatus,
  ProductType,
} from 'modules/iap-products/models/iap-product'
import * as yup from 'yup'

export const IapProductSchema = yup.object({
  id: yup.string().required('required'),
  description: yup.string().required('required'),
  status: yup
    .string()
    .required('status is required')
    .test({
      message: 'status is required',
      test: function (value) {
        return !!value
      },
    }),
  type: yup
    .string()
    .required('type is required')
    .test({
      message: 'consumable type is required',
      test: function (value) {
        return !!value && value !== ProductType.NotSet
      },
    }),
  metaData: yup.object().shape({
    productConfig: yup.object().shape({
      currencies: yup.array().of(
        yup.object().shape({
          amount: yup
            .number()
            .required('amount is required')
            .test({
              message: 'be more than zero',
              test: function (value) {
                return (value || 0) > 0
              },
            }),
          currency: yup
            .string()
            .required('currency is required')
            .test({
              message: 'currency is required',
              test: function (value) {
                return !!value
              },
            }),
        }),
      ),
    }),
  }),
  secondsFromUserCreationToStart: yup
    .string()
    .test({
      message: 'has to be before end',
      test: function (value) {
        if (IsNullOrUndefined(value) || IsNullOrUndefined(this.parent.secondsFromUserCreationToEnd)) return true
        return parseFloat(value!) <= parseFloat(this.parent.secondsFromUserCreationToEnd)
      },
    }),
  secondsFromUserCreationToEnd: yup.string().test({
    message: 'has to be ahead of start',
    test: function (value) {
      if (IsNullOrUndefined(value)) return true
      return parseFloat(value!) > parseFloat(this.parent.secondsFromUserCreationToStart)
    },
  }),
})
