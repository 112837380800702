import { Box, Chip, SvgIcon, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx'
import {
  StarIcon,
  CoinIcon,
  PlusIcon,
  HeartIcon,
} from 'components/icons'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { PostFeedWrapper } from 'modules/app/views/components/previews/post-feed-wrapper'
import { QuestionOption } from 'modules/app/views/components/previews/question-option'
import React from 'react'
import { IPost } from 'types/modules/posts/models/entities/post'
import { Tag } from 'types/modules/tags/models/entities/tag'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    postCoverContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: '100%',
      height: 550,
      borderRadius: 12,
      padding: 15,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
    },
    postCoverTopContainer: {
      display: 'flex',
      width: '100%',
      height: 'auto',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    postCoverPostType: {
      display: 'flex',
      background: '#FFFFFF',
      borderRadius: 3,
      padding: '0.2rem 0.5rem',
    },
    postCoverReward: {
      display: 'flex',
      background: '#FFFFFF',
      borderRadius: 3,
      padding: '0.2rem',
    },
    coinIcon: {
      display: 'flex',
      width: 19,
      marginRight: 2,
      '&:last-child': {
        marginRight: 0,
      },
    },
    starIcon: {
      display: 'flex',
      width: 15,
      marginRight: 2,
      '&:last-child': {
        marginRight: 0,
      },
    },
    starCount: {
      color: theme.palette.text.primary,
      fontSize: '0.8rem',
      fontWeight: 300,
      position: 'relative',
      top: 1,
      marginRight: '0.2rem',
    },
    postCoverPostTypeIcon: {
      fontSize: '0.6rem',
      position: 'relative',
      top: 4,
      marginRight: 2,
    },
    postCoverPostTypeLabel: {
      color: theme.palette.text.primary,
      fontSize: '0.8rem',
      fontWeight: 300,
      position: 'relative',
      top: 1,
    },
    postCoverDetailsContainer: {
      display: 'flex',
      width: '100%',
      height: 'auto',
      padding: 20,
      background: '#FFFFFF',
      borderRadius: 12,
      flexDirection: 'column',
    },
    postCoverDetailsTopLine: {
      display: 'flex',
      width: '100%',
      height: 'auto',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      marginBottom: 10,
    },
    postTagsAndArrowContainer: {
      display: 'flex',
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    postTitle: {
      fontSize: '1.25rem',
      lineHeight: 1.4,
      fontWeight: 600,
    },
    heartContainer: {
      display: 'flex',
      marginLeft: 10,
      fontSize: 25,
    },
    postTags: {
      marginRight: 'auto',
    },
    tag: {
      height: 'auto',
      background: '#ffffff',
      border: '1px solid',
      borderColor: 'rgb(216, 216, 216)',
      borderRadius: 8,
      marginRight: 5,
      '& .MuiChip-label': {
        paddingLeft: 8,
        paddingRight: 8,
        fontSize: '0.7rem',
        position: 'relative',
        top: 1,
      },
    },
    tagIcon: {
      fontSize: '0.6rem',
    },
    tagSubscribeIcon: {
      marginLeft: 2,
      width: 10,
      color: theme.palette.text.primary,
    },
    optionsContainer: {
      display: 'flex',
      width: '100%',
      height: 'auto',
      marginTop: 10,
      flexDirection: 'column',
    },
  }),
)

export const PostFeedPoll: React.FC<IPost> = (post: IPost) => {
  const classes = useStyles()

  const postTypeTag = (post.tags as Tag[]).find((tag) => tag.level === 0)

  const hasStarReward = !IsNullOrUndefined(post.starsWin) && post.starsWin !== 0

  const hasOptions = post.questions.some((q) => q.options.length > 0)

  return (
    <PostFeedWrapper>
      <Box className={classes.postCoverTopContainer}>
        <Box className={classes.postCoverPostType}>
          <Typography className={classes.postCoverPostTypeIcon}>
            {`${postTypeTag?.media?.icon ?? ''} `}
          </Typography>
          <Typography className={classes.postCoverPostTypeLabel}>
            {postTypeTag?.name ?? ''}
          </Typography>
        </Box>
        <Box className={classes.postCoverReward}>
          {hasStarReward ? (
            post.starsWin <= 5 ? (
              new Array(post.starsWin).fill(null).map((x, index) => (
                <i key={`star-${index}`} className={classes.starIcon}>
                  <StarIcon />
                </i>
              ))
            ) : (
              <React.Fragment>
                <Typography className={classes.starCount}>
                  {post.starsWin}
                </Typography>
                <i key={`star`} className={classes.starIcon}>
                  <StarIcon />
                </i>
              </React.Fragment>
            )
          ) : (
            <i className={classes.coinIcon}>
              <CoinIcon />
            </i>
          )}
        </Box>
      </Box>
      <Box className={classes.postCoverDetailsContainer}>
        <Box className={classes.postCoverDetailsTopLine}>
          <Typography className={classes.postTitle}>{post.title}</Typography>
          <Box className={classes.heartContainer}>
            <HeartIcon />
          </Box>
        </Box>
        <Box className={classes.postTagsAndArrowContainer}>
          <Box className={classes.postTags}>
            {post.tags &&
              (post.tags as Tag[])
                .filter((tag) => tag.level !== 0)
                .sort((a, b) => a.level! - b.level!)
                .slice(0, 2)
                .map((tag) => (
                  <Chip
                    key={`post-${post.id}-tag-${tag.id}`}
                    label={tag.name}
                    icon={
                      <i className={classes.tagIcon}>
                        {tag.media?.icon ?? null}
                      </i>
                    }
                    className={clsx(classes.tag)}
                    onDelete={(): void => {
                      return
                    }}
                    deleteIcon={
                      <SvgIcon className={classes.tagSubscribeIcon}>
                        <PlusIcon />
                      </SvgIcon>
                    }
                  />
                ))}
          </Box>
        </Box>
        {hasOptions && (
          <Box className={classes.optionsContainer}>
            {post.questions[0].options.map((o, index) => {
              return (
                <QuestionOption
                  key={`option-${o.id}`}
                  option={o}
                  index={index}
                />
              )
            })}
          </Box>
        )}
      </Box>
    </PostFeedWrapper>
  )
}
