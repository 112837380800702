import {
  Paper,
  Button,
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Divider,
  SvgIcon,
  Theme,
  Typography,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useRequestContext } from 'core/api/context'
import { useWatchlistContext } from 'modules/watchlist/context'
import ScrollContainer from 'react-indiana-drag-scroll'
import React, { useEffect } from 'react'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { ImageIcon } from 'components/icons'
import { WatchlistPostModal } from 'modules/watchlist/views/components/post-modal'
import SortDirection from 'types/common/enums/sort-direction'
import { PostField } from 'types/modules/posts/enums/post-field'
import { usePostListContext } from 'modules/posts/context/post-list'
import { usePostQuestionsManager } from 'modules/posts/context/post-questions-manager'
import { IPost } from 'types/modules/posts/models/entities/post'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    categoryContainer: {
      width: '100%',
      marginBottom: '1.25rem',
    },
    categoryHeader: {
      display: 'flex',
      padding: '0.625rem',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    categoryContentWrapper: {
      padding: '0.625rem',
      display: 'flex',
      flexWrap: 'nowrap',
      overflowX: 'auto',
      '-webkit-overflow-scrolling': 'touch',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    postCard: {
      flex: '0 0 auto',
      width: 240,
      marginRight: '0.625rem',
      '&:last-child': {
        marginRight: 0,
      },
    },
    cardActionArea: {
      display: 'flex',
      flex: 1,
      height: '100%',
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
    cardContent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: '100%',
      flex: 1,
    },
    coverImageContainer: {
      display: 'flex',
      width: '100%',
      height: 240,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      borderBottom: '1px solid rgba(0,0,0,0.12)',
      // boxShadow: '0 3px 3px rgba(0,0,0,0.12)'
    },
  }),
)

export const WatchlistDashboard: React.FC<{}> = () => {
  const classes = useStyles()
  const requestContext = useRequestContext()
  const { currentPost } = usePostListContext()
  const { setCurrentQuestion } = usePostQuestionsManager()
  const { groupedPosts, navigateToCategory } = useWatchlistContext()

  useEffect(() => {
    requestContext?.setSortValue({
      sortBy: PostField.START,
      sortDirection: SortDirection.Ascending,
    })
  }, [])

  return (
    <React.Fragment>
      {groupedPosts &&
        Object.entries(groupedPosts).map((value) => {
          const [tag, posts] = value

          return (
            <Paper
              key={tag}
              className={classes.categoryContainer}
              elevation={2}
            >
              <Box className={classes.categoryHeader}>
                <Typography variant="h5" color="textPrimary">
                  {tag}
                </Typography>
                <Button
                  onClick={(): void => {
                    navigateToCategory(tag)
                  }}
                >
                  See all
                </Button>
              </Box>
              <Divider />
              <ScrollContainer
                className={classes.categoryContentWrapper}
                nativeMobileScroll
              >
                {posts.map((post) => {
                  const hasCoverImage = !IsNullOrUndefined(post.media?.imageUrl)
                  const postCoverContainerStyles: {
                    [key: string]: string | number
                  } = {}

                  if (hasCoverImage) {
                    postCoverContainerStyles.backgroundImage = `url(${post.media!
                      .imageUrl!})`
                  } else {
                    postCoverContainerStyles.background = 'rgba(0,0,0,0.03)'
                  }

                  let questionsAnswered = 0

                  post.questions.forEach((question) => {
                    const portalAnswers = question.answers.filter(
                      (answer) =>
                        !IsNullOrUndefined(answer.updatedBy) &&
                        answer.updatedBy?.fromPortal,
                    )

                    if (portalAnswers.length > 0) {
                      questionsAnswered += 1
                    }
                  })

                  return (
                    <Card
                      key={`${tag}-post-${post.id}`}
                      className={classes.postCard}
                      elevation={2}
                    >
                      <CardActionArea
                        className={classes.cardActionArea}
                        onClick={(): void =>
                          setCurrentQuestion(post.questions[0])
                        }
                      >
                        <Box
                          className={classes.coverImageContainer}
                          style={postCoverContainerStyles}
                        >
                          {!hasCoverImage && (
                            <SvgIcon>
                              <ImageIcon />
                            </SvgIcon>
                          )}
                        </Box>
                        <CardContent className={classes.cardContent}>
                          <Typography
                            variant="subtitle2"
                            style={{
                              fontWeight: 600,
                              marginBottom: '1.25rem',
                            }}
                            color="textPrimary"
                          >
                            {post.title}
                          </Typography>
                          <Typography variant="body2" color="textPrimary">
                            {`Questions Answered: ${questionsAnswered}/${post.questions.length}`}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  )
                })}
              </ScrollContainer>
            </Paper>
          )
        })}
      {!IsNullOrUndefined(currentPost) && (
        <WatchlistPostModal post={currentPost as IPost} />
      )}
    </React.Fragment>
  )
}
