import { createTheme, adaptV4Theme } from '@mui/material/styles';
import { SportsRugbySharp } from '@mui/icons-material'
import { ENV } from 'core/environment'
import { Environment } from 'types/common/enums/environment'

const getHeaderColor = (): string => {
  switch (ENV.TARGET_ENV) {
    default:
    case Environment.Production:
      return '#ff787d'
    case Environment.Prerelease:
      return '#693234'
    case Environment.Staging:
      return '#8500ff'
    case Environment.Development:
      return '#02b3dc'
  }
}

const theme = createTheme(adaptV4Theme({
  palette: {
    primary: {
      light: '#ff9397',
      main: '#ff787d',
      dark: '#b25457',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ebebeb',
      main: '#e7e7e7',
      dark: '#a1a1a1',
      contrastText: 'rgba(51,51,51,0.87)',
    },
    text: {
      primary: 'rgba(51, 51, 51, 0.87)',
    },
  },
  typography: {
    fontFamily: [
      '"Mukta"',
      '"Avenir Book"',
      '"Helvetica Neue"',
      'Helvetica',
    ].join(','),
    h1: {
      fontFamily: '"Mukta"',
      fontWeight: 300,
      fontSize: '6rem',
      lineHeight: 1.167,
      letterSpacing: '-0.01562em',
    },
    h2: {
      fontFamily: '"Mukta"',
      fontWeight: 300,
      fontSize: '3.75rem',
      lineHeight: 1.2,
      letterSpacing: '-0.00833em',
    },
    h3: {
      fontFamily: '"Mukta"',
      fontWeight: 400,
      fontSize: '3rem',
      lineHeight: 1.167,
      letterSpacing: '0em',
    },
    h4: {
      fontFamily: '"Mukta"',
      fontWeight: 400,
      fontSize: '2.125rem',
      lineHeight: 1.235,
      letterSpacing: '0.00735em',
    },
    h5: {
      fontFamily: '"Mukta"',
      fontWeight: 500,
      fontSize: '1.5rem',
      lineHeight: 1.334,
      letterSpacing: '0em',
    },
    h6: {
      fontFamily: '"Mukta"',
      fontWeight: 400,
      fontSize: '1.25rem',
      lineHeight: 1.6,
      letterSpacing: '0.0075em',
    },
    subtitle1: {
      fontFamily: '"Mukta"',
      fontWeight: 400,
      fontSize: '1.1rem',
      lineHeight: 1.75,
      letterSpacing: '0.00938em',
    },
    subtitle2: {
      fontFamily: '"Mukta"',
      fontWeight: 500,
      fontSize: '0.875rem',
      lineHeight: 1.57,
      letterSpacing: '0.00714em',
    },
    body1: {
      fontFamily: '"Mukta"',
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: 1.5,
      letterSpacing: '0.00938em',
    },
    body2: {
      fontFamily: '"Mukta"',
      fontWeight: 400,
      fontSize: '0.875rem',
      lineHeight: 1.43,
      letterSpacing: '0.01071em',
    },
    button: {
      fontFamily: '"Mukta"',
      fontWeight: 600,
      fontSize: '0.875rem',
      lineHeight: 1.75,
      letterSpacing: '0.02857em',
      textTransform: 'uppercase',
    },
    caption: {
      fontFamily: '"Mukta"',
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: 1.66,
      letterSpacing: '0.03333em',
    },
    overline: {
      fontFamily: '"Mukta"',
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: 2.66,
      letterSpacing: '0.08333em',
      textTransform: 'uppercase',
    },
  },
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: getHeaderColor(),
      },
    },
    MuiButton: {
      contained: {
        boxShadow: 'none',
      },
      startIcon: {
        position: 'relative',
        top: '-.1em',
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.03)',
      },
      adornedEnd: {
        paddingRight: 0,
      },
      multiline: {
        padding: '10px 12px',
      },
      input: {
        padding: '10px 12px',
      },
      underline: {
        '&:hover:before': {
          borderBottom: 'rgba(0,0,0,0)',
        },
        '&::before': {
          borderBottom: 'rgba(0,0,0,0)',
        },
      },
    },
  },
}))

export default theme
