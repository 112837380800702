import { Button, ButtonProps, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx'
import { ConfirmationDialog } from 'components/confirmation-dialog'
import { FeaturedIcon } from 'components/icons'
import { usePostManagerContext } from 'modules/posts/context/post-manager'
import React from 'react'
import PostStatus from 'types/modules/posts/enums/post-status'
import { IPostActionButtonProps } from 'types/modules/posts/models/props/post-action-button-props'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.text.primary,
      backgroundColor: '#ecf0f1',
      '&:hover': {
        backgroundColor: '#f2f5f5',
      },
      '&.featured': {
        color: theme.palette.primary.contrastText,
        backgroundColor: '#2cd06e',
        '&:hover': {
          backgroundColor: '#54db8a',
        },
      },
    },
  }),
)

export const FeaturePostButton: React.FC<IPostActionButtonProps> = (
  props: IPostActionButtonProps,
) => {
  const { toggleFeaturePost } = usePostManagerContext()

  const classes = useStyles()

  const isFeatured = props.post.featured
  const isActive = props.post.status === PostStatus.Active

  const buttonProps: ButtonProps = {
    variant: 'contained',
    startIcon: <FeaturedIcon />,
    className: clsx(classes.root, isFeatured && 'featured'),
    size: 'small',
    disabled: props.disabled,
  }

  const buttonLabel = isFeatured ? 'Featured' : 'Feature'
  const popoverLabel = isFeatured ? 'unfeature' : 'feature'

  return isActive ? (
    <ConfirmationDialog
      popoverId={`${buttonLabel}-post-${props.post.id}`}
      buttonProps={buttonProps}
      onConfirmation={(): void => toggleFeaturePost(props.post)}
      confirmationText={`Are you sure you want to ${popoverLabel} this post?`}
      buttonLabel={buttonLabel}
      onPopoverClose={props.onPopoverClose}
    />
  ) : null
}
