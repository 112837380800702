import MediaItemEndpoints from 'core/api/endpoints/media-items'
import { createAsyncAction } from 'core/redux/utils'
import { IAsyncActionParams } from 'types/common/api/models/entities/async-action-params'
import { MediaItem } from 'types/modules/media-items/models/entities/media-item'
import { MediaItemTransformation } from 'types/modules/media-items/models/entities/media-item-transformation'
import { IAsyncAction } from 'types/redux/interfaces/IAction'
import { default as ActionTypes } from '../action-types'


export interface IMediaItemActionParams
  extends IAsyncActionParams<MediaItem | MediaItemTransformation | string> {
  mediaItemId?: string,
  mimeType?: string
}

export const getMediaItemById: (
  params: IMediaItemActionParams,
) => IAsyncAction = (params) =>
  createAsyncAction({
    type: ActionTypes.GET_MEDIA_ITEM_BY_ID,
    endpoint: MediaItemEndpoints.getMediaItemById,
    payload: params,
  })

export const uploadMediaItemImage: (
  params: IMediaItemActionParams,
) => IAsyncAction = (params) =>
  createAsyncAction({
    type: ActionTypes.UPLOAD_MEDIA_ITEM_IMAGE,
    endpoint: MediaItemEndpoints.uploadMediaItemImage,
    payload: params,
    alertsEnabled: false,
  })

export const updateMediaItemImage: (
  params: IMediaItemActionParams,
) => IAsyncAction = (params) =>
  createAsyncAction({
    type: ActionTypes.UPDATE_MEDIA_ITEM_IMAGE,
    endpoint: MediaItemEndpoints.updateMediaItemImage,
    payload: params,
    alertsEnabled: false,
  })

export const updateMediaItemTransformData: (
  params: IMediaItemActionParams,
) => IAsyncAction = (params) =>
  createAsyncAction({
    type: ActionTypes.UPDATE_MEDIA_ITEM_TRANSFORM_DATA,
    endpoint: MediaItemEndpoints.updateMediaItemTransformData,
    payload: params,
    alertsEnabled: false,
  })

export const createPresignedUrl: (
  params: IMediaItemActionParams,
) => IAsyncAction = (params) =>
  createAsyncAction({
    type: ActionTypes.CREATE_VIDEO_PRESIGNED_URL,
    endpoint: MediaItemEndpoints.createPresignedUrl,
    payload: params,
    alertsEnabled: false,
  })
