import { ISelectOption } from 'types/common/filtering/models/entities/ISelectOption'
import { EventStatus } from 'types/modules/events/enums/event-status'
import { IEventsState } from 'types/modules/events/models/state/IEventsState'

export const getStatusFilterOptions = (
  statuses: IEventsState['statuses'],
): ISelectOption[] => {
  return Object.keys(EventStatus).map((status) => ({
    label: status,
    value: [status],
    metadata: {
      ...statuses[status],
    },
  }))
}
