import { IEditorSubmitResult } from 'components/goss-editor/context'
import { useCommentsContext } from 'modules/comments/context'
import { CommentFeedItem } from 'modules/comments/views/components/comment-feed/comment-feed-item'
import React from 'react'
import { IComment } from 'types/modules/comments/models/entities/comment'

export const PostCommentItem: React.FC<IComment> = (comment: IComment) => {
  const { updatePostComment, deletePostComment } = useCommentsContext()

  return (
    <CommentFeedItem
      comment={comment}
      onUpdate={({ value, callbackFn }: IEditorSubmitResult): void => {
        updatePostComment(
          JSON.stringify(value),
          comment.id,
          comment.postId,
          callbackFn,
        )
      }}
      onDelete={(commentId: string): void => {
        deletePostComment(commentId, comment.postId)
      }}
    />
  )
}
