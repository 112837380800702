import { useRequestContext } from 'core/api/context'
import { useTypedSelector } from 'core/redux/utils'
import { getPageSize } from 'modules/common/selectors'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'


export interface IParams {
  fetchItems: (value: any) => void,
}

export const usePageSizeMonitor = ({ fetchItems }: IParams): number => {
  const dispatch = useDispatch()
  const pageSize = useTypedSelector((state) => getPageSize(state))
  const [firstTime, setFirstTime] = useState(true)
  const rc = useRequestContext()
  useEffect(() => {
    if (firstTime) {
      setFirstTime(false)
      return
    }
    dispatch(
      fetchItems({
        filters: rc?.filters,
        page: 1,
        pageSize,
        ...rc?.sortValue,
      }),
    )
  }, [pageSize])

  return pageSize
}
