const paths = {
  events: 'events',
  images: 'images',
  statuses: 'statuses',
  upload: 'upload',
  promote: 'promote',
  updateStatus: 'update-status',
  eventPassUpload: 'EventPassUpload',
  defaultScheduleImageUpload: 'DefaultLiveScheduleUpload',
}

export default paths
