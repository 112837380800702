import { IKeyValueStringCollection } from 'types/common/interfaces/IKeyValueStringCollection'

const filters: IKeyValueStringCollection = {
  status: '{{status}}',
  start: '{{start}}',
  end: '{{end}}',
  name: '{{name}}',
}

export default filters
