import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import FilterToolbar, {
  IFilterToolbarProps,
} from 'core/filtering/components/filter-toolbar'
import React from 'react'
import SortDirection from 'types/common/enums/sort-direction'
import { LiveScheduleField } from 'types/modules/live-schedules/enums/live-schedule-field'

export const LiveSchedulesToolbar: React.FC<{}> = () => {
  const toolbarProps = {
    searchFilterConfig: {
      field: LiveScheduleField.NAME,
    },
    sortOptions: [
      { label: 'Start Date', value: LiveScheduleField.START },
      { label: 'End Date', value: LiveScheduleField.END },
      { label: 'Last Updated', value: LiveScheduleField.UPDATED_AT },
    ],
    initialSortValue: {
      sortBy: LiveScheduleField.START,
      sortDirection: SortDirection.Descending,
    },
  } as IFilterToolbarProps

  return <FilterToolbar {...toolbarProps} />
}
