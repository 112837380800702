import { RootSagaReturnType } from 'core/redux/rootSaga'
import { ActionTypes } from 'modules/posts'
import { GetTagsSaga } from 'modules/tags/sagas/get-tags-saga'
import { all, takeEvery, takeLatest } from 'redux-saga/effects'

const enabledAsyncActions = [
  ActionTypes.GET_POST_STATUSES,
  ActionTypes.GET_POST_BY_ID,
  ActionTypes.CLEAR_TRASH,

  ActionTypes.CREATE_POST,
  ActionTypes.DUPLICATE_POST,

  ActionTypes.UPDATE_POST,
  ActionTypes.UPLOAD_POST_IMAGE,
  ActionTypes.UPDATE_POST_STATUS,
  ActionTypes.SETTLE_POST_TICKETS,
  ActionTypes.PROMOTE_POST,
  ActionTypes.SET_POST_COMPROMISED,

  ActionTypes.ADD_QUESTION,
  ActionTypes.UPLOAD_QUESTION_IMAGE,
  ActionTypes.UPDATE_QUESTION,
  ActionTypes.SAVE_QUESTION_AND_OPTIONS,
  ActionTypes.DELETE_QUESTION,

  ActionTypes.ADD_RESULT,
  ActionTypes.UPDATE_RESULT,
  ActionTypes.UPLOAD_RESULT_IMAGE,
  ActionTypes.DELETE_RESULT,

  ActionTypes.ADD_ANSWER,
  ActionTypes.UPDATE_ANSWER,
]

export const RootSaga = function* (): RootSagaReturnType {
  yield all([
    yield takeEvery(enabledAsyncActions, GetTagsSaga),
    yield takeLatest(ActionTypes.GET_POSTS, GetTagsSaga),
    yield takeLatest(ActionTypes.GET_AUTOCOMPLETE_SEARCH_POSTS, GetTagsSaga),
  ])
}
