import { PrizeType } from 'fe-shared-resources'
import { Regions } from 'types/common/enums/regions'
import { PrizeVisibility } from 'types/modules/prizes/enums/prize-visibility'

export const MODULE_NAME = 'prizes'

const countries = [Regions.GB, Regions.GB, Regions.GB, Regions.GB]

export const DRAFTED_SWEEP_PRIZE = {
  type: PrizeType.Sweepstake,
  requiredEntries: 5000,
  countries,
  item: {
    name: '',
  },
}

export const DRAFTED_INSTANT_PRIZE = {
  type: PrizeType.Instant,
  countries,
  item: {
    name: '',
  },
}

export const DRAFTED_INSTANT_LOTTERY = {
  type: PrizeType.Lottery,
  requiredEntries: 5000,
  countries,
  visibility: PrizeVisibility.Private,
  item: {
    name: '',
  },
}

export const DEFAULT_PRIZE = {
  requiredEntries: 5000,
  countries,
  item: {
    name: '',
  },
}


export const DRAFTED_PRIZE = {
  [PrizeType.Instant]: DRAFTED_INSTANT_PRIZE,
  [PrizeType.Sweepstake]: DRAFTED_SWEEP_PRIZE,
  [PrizeType.Lottery]: DRAFTED_INSTANT_LOTTERY,
}
