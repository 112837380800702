import React from 'react'
import { RequestContextProvider } from 'core/api/context'
import { CampaignsProvider } from 'modules/campaigns/context/campaign-context'
import { CampaignList } from 'modules/campaigns/view/campaign-list'

export const CampaignDashboard: React.FC<{}> = () => {
  return (
    <RequestContextProvider>
      <CampaignsProvider>
        <CampaignList />
      </CampaignsProvider>
    </RequestContextProvider>
  )
}
