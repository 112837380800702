/* eslint-disable react/display-name */
import React from 'react'
import { Button } from '@mui/material'
import { dismissAlert, enqueueAlert } from 'core/alerts/actions'
import { createAlert } from 'core/alerts/utils/create-alert'
import store from 'core/redux/store'
import { SnackbarKey } from 'notistack'
import { put, PutEffect } from 'redux-saga/effects'
import { ISimpleAction, ISuccessAction } from 'types/redux/interfaces/IAction'

type SagaReturnType = Generator<PutEffect<ISimpleAction>, void, unknown>

export function* SuccessAlertSaga(action: ISuccessAction): SagaReturnType {
  const actionType = action.type.split('/').slice(-1)[0]
  if (!action.alertsEnabled) return
  if (
    !actionType.startsWith('GET_') &&
    !actionType.startsWith('AUTOCOMPLETE_')
  ) {
    const message = actionType.split('_').join(' ')

    const alert = createAlert(message, {
      variant: 'success',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
      },
      action: (key: SnackbarKey) => (
        <Button
          onClick={(): void => {
            store.dispatch(dismissAlert(key))
          }}
        >
          Dismiss
        </Button>
      ),
    })

    yield put(enqueueAlert(alert))
  }
}
