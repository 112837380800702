import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { PanelContainer, Panel } from 'components/panel'
import { EventsContextProvider } from 'modules/events/context'
import { SingleEventDashboard } from 'modules/events/views/components/single-event-dashboard'
import { LeaderboardContextProvider } from 'modules/leaderboards/context'
import React from 'react'

const useStyles = makeStyles((theme: Theme) => createStyles({}))

export const SingleEventPage: React.FC<{}> = () => {
  const classes = useStyles()
  return (
    <EventsContextProvider>
      <LeaderboardContextProvider>
        <PanelContainer>
          <Panel container xs={12}>
            <SingleEventDashboard />
          </Panel>
        </PanelContainer>
      </LeaderboardContextProvider>
    </EventsContextProvider>
  )
}
