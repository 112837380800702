import { Box, Grid, InputLabel, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import _ from 'core/utils/deepdash'
import { FormGen, FormTypes } from 'components/simple-form-gen'
import React from 'react'
import { IForm } from 'types/common/interfaces/IForm'
import { IEvergreenQuestLevel } from 'modules/quests/models/evergreen-quest'
import { MediaItemInput } from 'modules/media-items/media-item-input'
import { MediaOrientation } from 'types/common/images/enum/media-orientation'
import { AspectRatio } from 'types/common/images/enum/aspect-ratio'
import { MediaItem } from 'types/modules/media-items/models/entities/media-item'
import { MediaUploadModel } from 'types/common/images/models/entities/image-upload-model'
import { useEvergreenQuestsContext } from 'modules/quests/context/evergreen-quest-context'
import { ExclamationTriangle } from 'components/icons'
import { SaveBeforeWarning } from 'components/save-before-warning'
import { Currency } from 'fe-shared-resources';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      width: '100%',
      height: '600px',
      '& .MuiGrid-item': {
        display: 'flex',
        flexDirection: 'column',
      },
    },
    questsWrapper: {
      borderLeft: '1px solid #f4f4f4',
      paddingLeft: 8,
      height: '100%',
    },
    container: {
      height: '100%',
    },
    questsError: {
      marginTop: -16,
    },
    inputLabel: {
      display: 'flex',
      marginBottom: '0.625rem',
    },
    imageHint: {
      marginTop: 8,
      color: 'red',
    },
    imageHintText: {
      marginLeft: 8,
    },
  }),
)

interface IEvergreenQuestLevelFormProps extends IForm {
  evergreenQuestLevel: IEvergreenQuestLevel
}

export const EvergreenQuestLevelForm: React.FC<
  IEvergreenQuestLevelFormProps
> = ({ evergreenQuestLevel, handleChange, errors }): JSX.Element => {
  const classes = useStyles()
  const { uploadEvergreenQuestImage, evergreenQuestLevels } =
    useEvergreenQuestsContext()
  const originalQuestLevel = evergreenQuestLevels.find(
    (level) => level.id === evergreenQuestLevel?.id,
  )
  const elements = [
    {
      name: 'level',
      label: 'Level',
      value: evergreenQuestLevel?.level,
      type: FormTypes.TEXT_INPUT,
      disabled: true,
      otherProps: {
        error: !!errors?.level,
        helperText: errors?.level,
        type: 'number',
      },
    },
    {
      name: 'description',
      label: 'Description',
      value: evergreenQuestLevel?.description,
      type: FormTypes.TEXT_INPUT,
      otherProps: {
        error: !!errors?.description,
        helperText: errors?.description,
      },
    },
    {
      name: 'requiredQuantity',
      label: 'Required quantity',
      value: evergreenQuestLevel?.requiredQuantity,
      type: FormTypes.TEXT_INPUT,
      otherProps: {
        error: !!errors?.requiredQuantity,
        helperText: errors?.requiredQuantity,
        type: 'number',
      },
    },
    {
      name: 'rewardAmount',
      label: 'Reward amount',
      value: evergreenQuestLevel?.rewardAmount,
      type: FormTypes.TEXT_INPUT,
      otherProps: {
        error: !!errors?.amount,
        helperText: errors?.amount,
        type: 'number',
      },
    },
    {
      name: 'rewardCurrency',
      label: 'Reward currency',
      value: evergreenQuestLevel?.rewardCurrency,
      type: FormTypes.SELECT,
      options: Object.values(Currency).map((currency) => ({
        value: currency,
        name: currency,
      })),
      otherProps: {
        error: !!errors?.amount,
        helperText: errors?.amount,
      },
    },
  ]

  return (
    <Box className={classes.form}>
      <Grid container spacing={2} className={classes.container}>
        <Grid item xs={4}>
          <Grid container spacing={2}>
            {elements.map((element) => (
              <Grid item xs={12} key={element.name}>
                <FormGen handleChange={handleChange} {...element} />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={8}>
          <InputLabel className={classes.inputLabel}>Media</InputLabel>
          {!evergreenQuestLevel?.id && <SaveBeforeWarning />}
          <MediaItemInput
            disabled={!evergreenQuestLevel?.id}
            url={originalQuestLevel?.badgeUrl}
            imageLabel={'Cover Image'}
            options={{
              aspectRatio: AspectRatio.Standard,
              orientation: MediaOrientation.Portrait,
            }}
            onComplete={(fileParams: MediaUploadModel): void => {
              uploadEvergreenQuestImage(
                evergreenQuestLevel.id!,
                fileParams.imageUrl,
              )
            }}
            removeCrop
            mediaSquareHeight={400}
          />
        </Grid>
      </Grid>
    </Box>
  )
}
