import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Panel, PanelContainer } from 'components/panel'
import { RequestContextProvider } from 'core/api/context'
import { EventsContextProvider } from 'modules/events/context'
import { EventsDashboard } from 'modules/events/views/components/events-dashboard'
import React from 'react'

const useStyles = makeStyles((theme: Theme) => createStyles({}))

export const EventsPage: React.FC<{}> = () => {
  const classes = useStyles()
  return (
    <RequestContextProvider>
      <EventsContextProvider>
        <PanelContainer>
          <Panel container xs={12}>
            <EventsDashboard />
          </Panel>
        </PanelContainer>
      </EventsContextProvider>
    </RequestContextProvider>
  )
}
