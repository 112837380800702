import posts from 'core/api/endpoints/posts'
import _ from 'core/utils/deepdash'
import { ISelectOption } from 'types/common/filtering/models/entities/ISelectOption'
import PostStatus from 'types/modules/posts/enums/post-status'
import { IPostsState } from 'types/modules/posts/models/state/IPostsState'

export const getStatusFilterOptions = (
  statuses: IPostsState['statuses'],
): ISelectOption[] => {
  const closedGroup = [
    PostStatus.Closed,
    PostStatus.Settled,
    PostStatus.Unsettled,
  ]

  const sumStatusCountForGroup = (statusGroup: PostStatus[]): number => {
    let total = 0
    statusGroup.forEach((status) => (total += statuses[status]?.count || 0))
    return total
  }

  const statusGroupHasNotifications = (statusGroup: PostStatus[]): boolean => {
    const groupStatuses = _.filter(statuses, (value, key) => {
      return statusGroup.includes(key as PostStatus)
    })

    return groupStatuses.some((status) => status.hasNotifications)
  }

  return [
    {
      label: PostStatus.Closed,
      value: closedGroup,
      metadata: {
        count: sumStatusCountForGroup(closedGroup),
        hasNotifications: statusGroupHasNotifications(closedGroup),
      },
    },
    {
      label: PostStatus.Paused,
      value: [PostStatus.Paused],
      metadata: {
        ...statuses[PostStatus.Paused],
      },
    },
    {
      label: PostStatus.Active,
      value: [PostStatus.Active],
      metadata: {
        ...statuses[PostStatus.Active],
      },
    },
    {
      label: PostStatus.Scheduled,
      value: [PostStatus.Scheduled],
      metadata: {
        ...statuses[PostStatus.Scheduled],
      },
    },
    {
      label: PostStatus.Pending,
      value: [PostStatus.Pending],
      metadata: {
        ...statuses[PostStatus.Pending],
      },
    },
    {
      label: PostStatus.Rejected,
      value: [PostStatus.Rejected],
      metadata: {
        ...statuses[PostStatus.Rejected],
      },
    },
    {
      label: 'Drafts',
      value: [PostStatus.Created],
      metadata: {
        ...statuses[PostStatus.Created],
      },
    },
    {
      label: PostStatus.Abandoned,
      value: [PostStatus.Abandoned],
      metadata: {
        ...statuses[PostStatus.Abandoned],
      },
    },
    {
      label: PostStatus.Trashed,
      value: [PostStatus.Trashed],
      metadata: {
        ...statuses[PostStatus.Trashed],
      },
    },
  ]
}
