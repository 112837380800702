import { QUEST__GROUP_MODULE_NAME } from 'modules/quests/constants'

const ActionTypes = {
  SET_CURRENT_QUEST_GROUP_ID: `${QUEST__GROUP_MODULE_NAME}/SET_CURRENT_QUEST_GROUP_ID`,
  GET_QUEST_GROUPS: `${QUEST__GROUP_MODULE_NAME}/GET_QUEST_GROUPS`,
  GET_QUEST_GROUP_BY_ID: `${QUEST__GROUP_MODULE_NAME}/GET_QUEST_GROUP_BY_ID`,
  CREATE_QUEST_GROUP: `${QUEST__GROUP_MODULE_NAME}/CREATE_QUEST_GROUP`,
  CREATE_QUEST_GROUP_DRAFT: `${QUEST__GROUP_MODULE_NAME}/CREATE_QUEST_GROUP_DRAFT`,
  UPDATE_QUEST_GROUP: `${QUEST__GROUP_MODULE_NAME}/UPDATE_QUEST_GROUP`,
}

export default ActionTypes
