import { Typography } from '@mui/material'
import { IsNullUndefinedOrEmpty } from 'core/utils/isNullOrUndefined'
import React from 'react'
import { IUserPrize } from 'types/modules/shipping/models/entities/user-prize'

interface IProps {
  userPrize: IUserPrize | null
}

export const OrderInfo: React.FC<IProps> = ({ userPrize }) => {
  return (
    <>
      {!IsNullUndefinedOrEmpty(
        userPrize?.prize.item.details?.brand,
      ) && (
        <Typography
          variant="subtitle2"
          color="textPrimary"
        >
          {userPrize?.prize.item.details!.brand}
        </Typography>
      )}
      {!IsNullUndefinedOrEmpty(userPrize?.prize.item.name) && (
        <Typography
          variant="subtitle2"
          color="textPrimary"
        >
          {userPrize?.prize.item.name}
        </Typography>
      )}
      {!IsNullUndefinedOrEmpty(
        userPrize?.prize.item.details?.description,
      ) && (
        <Typography
          variant="subtitle2"
          color="textPrimary"
        >
          {userPrize?.prize.item.details?.description}
        </Typography>
      )}
      {userPrize?.prize.item.unitCost && (
        <Typography
          variant="body2"
          color="textPrimary"
        >
          {`£${userPrize?.prize.item.unitCost.toString()}`}
        </Typography>
      )}
    </>
  )
}
