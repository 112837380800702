import { Box, SvgIcon, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useGossEditor } from 'components/goss-editor/context'
import { ElementType } from 'components/goss-editor/types/element-type'
import { MarkType } from 'components/goss-editor/types/mark-type'
import BlockButton from 'components/goss-editor/views/toolbar/block-button'
import MarkButton from 'components/goss-editor/views/toolbar/mark-button'
import { ToolbarButton } from 'components/goss-editor/views/toolbar/toolbar-button'
import {
  BoldIcon,
  CodeIcon,
  CrossIcon,
  HeadingOneIcon,
  HeadingTwoIcon,
  ItalicIcon,
  ListOlIcon,
  ListUlIcon,
  ParagraphIcon,
  QuoteIcon,
  SaveIcon,
  UnderlineIcon,
} from 'components/icons'
import React from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    editorToolbar: {
      padding: '0.312rem',
      display: 'flex',
      background: 'rgba(0,0,0,0.03)',
      borderTop: '1px solid rgba(0,0,0,0.13)',
    },
    formatButtons: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'row',
    },
    actionButtons: {
      display: 'flex',
    },
  }),
)

export interface IEditorToolbarProps {
  onCancel: () => void
}

export const EditorToolbar: React.FC<IEditorToolbarProps> = (
  props: IEditorToolbarProps,
) => {
  const classes = useStyles()

  const { onCancel } = props

  const { submit } = useGossEditor()

  return (
    <Box className={classes.editorToolbar}>
      <Box className={classes.formatButtons}>
        <BlockButton format={ElementType.Paragraph} icon={<ParagraphIcon />} />
        <MarkButton format={MarkType.Bold} icon={<BoldIcon />} />
        <MarkButton format={MarkType.Italic} icon={<ItalicIcon />} />
        <MarkButton format={MarkType.Underline} icon={<UnderlineIcon />} />
        <MarkButton format={MarkType.Code} icon={<CodeIcon />} />
        <BlockButton
          format={ElementType.HeadingOne}
          icon={<HeadingOneIcon />}
        />
        <BlockButton
          format={ElementType.HeadingTwo}
          icon={<HeadingTwoIcon />}
        />
        <BlockButton format={ElementType.BlockQuote} icon={<QuoteIcon />} />
        {/* <BlockButton format={ElementType.BulletedList} icon={<ListUlIcon />} />
        <BlockButton format={ElementType.NumberedList} icon={<ListOlIcon />} /> */}
      </Box>
      <Box className={classes.actionButtons}>
        <ToolbarButton onClick={onCancel}>
          <SvgIcon style={{ fontSize: 16 }}>
            <CrossIcon />
          </SvgIcon>
        </ToolbarButton>
        <ToolbarButton classes={['primary']} onClick={submit}>
          <SvgIcon style={{ fontSize: 16 }}>
            <SaveIcon />
          </SvgIcon>
        </ToolbarButton>
      </Box>
    </Box>
  )
}
