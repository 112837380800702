import { Box, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { useCurrentQuestion } from 'hooks/use-current-question'
import React from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    questionImage: {
      display: 'flex',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '50%',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundColor: '#ffffff',
    },
  }),
)

export interface IQuestionImageProps {
  questionId: string
}

export const QuestionImage = ({
  questionId,
}: IQuestionImageProps): JSX.Element => {
  const classes = useStyles()
  const question = useCurrentQuestion(questionId)
  const hasImage = !IsNullOrUndefined(question.media?.imageUrl)
  const questionImageStyles: { [key: string]: string | number } = {}

  if (hasImage) {
    questionImageStyles.backgroundImage = `url(${question.media!.imageUrl!})`
  } else {
    questionImageStyles.background = 'rgba(0,0,0,0.03)'
  }

  return (
    <Box className={classes.questionImage} style={questionImageStyles} />
  )
}
