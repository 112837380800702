import { Reducer } from 'redux'
import { actionTypeSuccess } from 'core/redux/utils'
import { ISimpleAction, ISuccessAction } from 'types/redux/interfaces/IAction'
import _ from 'core/utils/deepdash'
import { IAppSettingsState } from 'modules/app-settings/model/state'
import ActionTypes from 'modules/app-settings/action-types'
import { ApiResponseData } from 'types/common/api/models/entities/api-response-data'
import {
  IPortalDefaultsResponse,
  IPortalWarning,
  IPortalWarningResponse,
} from 'types/modules/users/portal/models/entities/portal-settings'

const initialState: IAppSettingsState = {
  taskCodes: [],
  currencies: [],
  schedules: [],
  eventCodes: [],
  actionCodes: [],
  questMediaLocations: [],
  rewardOptions: [],
  appWarnings: [],
  fetching: false,
  fetched: false,
}

type AllowedActionTypes = ISuccessAction | ISimpleAction

const actionHandlers = {
  [actionTypeSuccess(ActionTypes.GET_PORTAL_DEFAULTS)]: (
    state: IAppSettingsState,
    payload: ApiResponseData,
  ): IAppSettingsState => {
    const response = payload as IPortalDefaultsResponse
    return {
      ...state,
      ...response.enums,
      fetched: true,
    }
  },
  [actionTypeSuccess(ActionTypes.GET_PORTAL_WARNINGS)]: (
    state: IAppSettingsState,
    payload: ApiResponseData,
  ): IAppSettingsState => {
    const response = payload as IPortalWarningResponse
    return {
      ...state,
      appWarnings: response.value,
    }
  },
}

const AppSettingReducer: Reducer<IAppSettingsState, AllowedActionTypes> = (
  state = initialState,
  action: AllowedActionTypes,
) => {
  const successAction = action as ISuccessAction

  return actionHandlers[action.type]
    ? actionHandlers[action.type](state, successAction.data)
    : state
}

export default AppSettingReducer
