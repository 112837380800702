import { Box, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx'
import {
  CircleIcon,
  PersonalityTestIcon,
  PollsIcon,
  RiskIcon,
  TimedIcon,
  WatchlistIcon,
} from 'components/icons'
import React from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    postListLegend: {
      display: 'flex',
      padding: '0.625rem',
      borderRadius: 4,
    },
    legendItem: {
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.text.primary,
      marginRight: '1.25rem',
      '&:last-of-type': {
        marginRight: 0,
      },
    },
    icon: {
      marginRight: '0.625rem',
      '&.notification': {
        color: theme.palette.primary.main,
      },
    },
    label: {},
  }),
)

export const PostListLegend: React.FC<{}> = () => {
  const classes = useStyles()

  return (
    <Box className={classes.postListLegend}>
      <Box className={classes.legendItem}>
        <Box className={classes.icon}>
          <WatchlistIcon />
        </Box>
        <Typography variant="body2" color="textPrimary">
          Watchlist
        </Typography>
      </Box>
      <Box className={classes.legendItem}>
        <Box className={classes.icon}>
          <PersonalityTestIcon />
        </Box>
        <Typography variant="body2" color="textPrimary">
          Personality
        </Typography>
      </Box>
      <Box className={classes.legendItem}>
        <Box className={classes.icon}>
          <TimedIcon />
        </Box>
        <Typography variant="body2" color="textPrimary">
          Timed
        </Typography>
      </Box>
      <Box className={classes.legendItem}>
        <Box className={classes.icon}>
          <PollsIcon />
        </Box>
        <Typography variant="body2" color="textPrimary">
          Polls
        </Typography>
      </Box>
      <Box className={classes.legendItem}>
        <Box className={clsx(classes.icon, 'notification')}>
          <CircleIcon />
        </Box>
        <Typography variant="body2" color="textPrimary">
          Notification
        </Typography>
      </Box>
      <Box className={classes.legendItem}>
        <Box className={classes.icon}>
          <RiskIcon />
        </Box>
        <Typography variant="body2" color="textPrimary">
          Risk
        </Typography>
      </Box>
    </Box>
  )
}
