import { ManageMediaItemModal } from 'modules/media-items/media-item-input/modal'
import React, { useState } from 'react'
import { AspectRatio } from 'types/common/images/enum/aspect-ratio'
import { MediaOrientation } from 'types/common/images/enum/media-orientation'
import { MediaUploadModel } from 'types/common/images/models/entities/image-upload-model'
import { MediaItem } from 'types/modules/media-items/models/entities/media-item'
import { Box, Button, ButtonBase, IconButton, SvgIcon, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { SimpleImageDisplay } from 'components/simple-image-display'
import { MediaItemsProvider } from 'modules/media-items/media-item-context'
import { CrossIcon, PaperClipIcon, PlusIcon } from 'components/icons'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    imageUploaderButton: {
      display: 'flex',
      flex: 1,
      width: '100%',
      height: '100%'
    },
    link: {
      textDecoration: 'underline',
      cursor: 'pointer'
    },
    linkAndRemoveIcon: {
      display: 'flex'
    }
  }),
)

export interface IMediaItemInputProps {
  isVideo?: boolean
  url?: string
  disabled?: boolean
  loading?: boolean
  mediaItemId?: string
  imageLabel?: string
  options: {
    aspectRatio: AspectRatio
    orientation: MediaOrientation
  }
  onComplete: (fileParams: MediaUploadModel) => void
  onCreate?: (mediaItem: MediaItem) => void
  accept?: string
  button?: boolean
  hasAttachment?: boolean
  buttonLabel?: string
  removeAttachment?: () => void
  removeCrop?: boolean
  mediaSquareHeight?: number
  doneLabel?: string
  uploadMediaLabel?: string
  previewAsUrl?: boolean
}

export const MediaItemInput: React.FC<IMediaItemInputProps> = ({
  url,
  isVideo,
  mediaItemId,
  disabled,
  options,
  loading,
  accept='image/*',
  button,
  hasAttachment,
  buttonLabel,
  removeCrop,
  mediaSquareHeight,
  removeAttachment,
  onComplete,
  onCreate,
  doneLabel = 'Save',
  uploadMediaLabel = 'Upload Media',
  previewAsUrl = false
}) => {
  const classes = useStyles()
  const [opened, setOpened] = useState(false)
  const closeModal = (): void => {
    if (loading) return
    setOpened(false)
  }
  const openModal = (): void => {
    if (disabled || loading) return
    setOpened(true)
  }

  return (
    <MediaItemsProvider
      existingUrl={url}
      mediaItemId={mediaItemId}
      onComplete={(fileParams): void => {
        onComplete(fileParams)
        setOpened(false)
      }}
      onCreate={onCreate}
      disabled={disabled}
      loading={loading}
      options={options}
      closeModal={closeModal}
      accept={accept}
      isVideo={isVideo}
      removeCrop={removeCrop}
      mediaSquareHeight={mediaSquareHeight}
      uploadMediaLabel={uploadMediaLabel}
      doneLabel={doneLabel}
      previewAsUrl={previewAsUrl}
    >
      {button
        ? <>
          {
            hasAttachment
            ? <Box className={classes.linkAndRemoveIcon}>
              <Button
                variant="contained"
                color="primary"
                onClick={openModal}
                startIcon={hasAttachment ? <PaperClipIcon/> : <PlusIcon />}
              >
                View
              </Button>
              {!!removeAttachment && <IconButton onClick={removeAttachment} size="large">
                <SvgIcon>
                  <CrossIcon />
                </SvgIcon>
              </IconButton>}
            </Box>
            : <Button
              variant="contained"
              color="primary"
              onClick={openModal}
              startIcon={hasAttachment ? <PaperClipIcon/> : <PlusIcon />}
            >
              {buttonLabel}
            </Button>}
          </>
        : <ButtonBase
          className={classes.imageUploaderButton}
          onClick={openModal}
        >
          {
            !isVideo
              ? <SimpleImageDisplay
                width="100%"
                height="100%"
                imageUrl={url}
                backgroundSize="contain"
              />
              : <video width="100%" preload="auto" controls autoPlay>
                <source src={url} type="video/mp4" />
              </video>
          }
        </ButtonBase>}
      <ManageMediaItemModal
        open={opened}
      />
    </MediaItemsProvider>
  );
}
