import React, { useState } from 'react'
import { Box, Button, Divider, IconButton, SvgIcon, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useLeaderboardContext } from 'modules/leaderboards/context'
import { useFormik } from 'formik'
import { LeaderboardPositionRewardField as FieldName } from 'types/modules/leaderboards/enums/leaderboard-position-reward-field'
import { ILeaderboardPositionRewardDraft } from 'types/modules/leaderboards/models/entities/leaderboard-position-reward-draft'
import { CrossIcon, UndoIcon, SaveIcon } from 'components/icons'
import { PanelContainer, Panel } from 'components/panel'
import { getLeaderboardPositionLabel } from 'modules/leaderboards/utils/get-leaderboard-position-label'
import { PositionRewardForm } from 'modules/leaderboards/views/components/edit-position-reward-panel/position-reward-form'
import ILeaderboardPositionReward from 'types/modules/leaderboards/models/entities/leaderboard-position-reward'
import { IPrize } from 'types/modules/prizes/models/entities/prize'

enum DisplayMode {
  View = 'View',
  Edit = 'Edit',
  Create = 'Create',
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    panelHeader: {
      display: 'flex',
      width: '100%',
      flexShrink: 0,
      height: 72,
    },
    panelTitle: {
      display: 'flex',
      flexGrow: 1,
      alignItems: 'center',
      padding: '0 0 0 1.25rem',
    },
    rightHeaderSection: {
      display: 'flex',
      alignItems: 'center',
      padding: '0 1.25rem 0 0',
      flexDirection: 'row-reverse',
      '& .MuiButton-contained': {
        marginRight: 20,
      },
    },
    contentWrapper: {
      display: 'flex',
      flexGrow: 1,
      overflow: 'hidden',
    },
    contentPanel: {
      position: 'relative',
      display: 'flex',
      flex: 1,
      padding: '1.25rem',
      overflow: 'auto',
      borderRight: '1px solid rgba(0,0,0,0.12)',
      '&:last-child': {
        border: 'none',
      },
    },
    footer: {
      display: 'flex',
      width: '100%',
      justifySelf: 'flex-end',
    },
    footerSection: {
      display: 'flex',
      padding: '1.25rem',
      flex: 1,
      justifyContent: 'space-between',
      borderRight: '1px solid rgba(0,0,0,0.12)',
      '&:last-child': {
        border: 'none',
      },
    },
    updateButton: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    resetButton: {
      color: theme.palette.text.primary,
      backgroundColor: '#ecf0f1',
      '&:hover': {
        backgroundColor: '#f2f5f5',
      },
    },
  }),
)

interface IManagePositionRewardPanel {
  positionReward: ILeaderboardPositionReward
  isModal?: boolean
}

export const EditPositionRewardPanel: React.FC<IManagePositionRewardPanel> = (
  props: IManagePositionRewardPanel,
) => {
  const classes = useStyles()

  const { positionReward, isModal } = props

  const {
    currentLeaderboard,
    clearCurrentPositionReward,
    updatePositionReward,
  } = useLeaderboardContext()

  const [displayMode, setDisplayMode] = useState(DisplayMode.Edit)

  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      [FieldName.ID]: positionReward[FieldName.ID],
      [FieldName.LEADERBOARD_ID]: positionReward[FieldName.LEADERBOARD_ID],
      [FieldName.START]: positionReward[FieldName.START],
      [FieldName.END]: positionReward[FieldName.END],
      [FieldName.TYPE]: positionReward[FieldName.TYPE],
      [FieldName.COINS]: positionReward[FieldName.COINS],
      [FieldName.STARS]: positionReward[FieldName.STARS],
      [FieldName.PRIZES]: positionReward[FieldName.PRIZES],
      [FieldName.GOLD_BOX]: positionReward[FieldName.GOLD_BOX],
      [FieldName.BROWN_BOX]: positionReward[FieldName.BROWN_BOX],
      [FieldName.UPDATED_AT]: positionReward[FieldName.UPDATED_AT],
    },
    onSubmit: (value: ILeaderboardPositionReward) => {
      value.prizes = (value.prizes as IPrize[]).map((prize) => prize.id)
      updatePositionReward(currentLeaderboard!.id, value)
    },
  })

  const { start, end, type } = form.values

  return (
    <PanelContainer>
      <Panel container xs={12}>
        <Box className={classes.panelHeader}>
          <Box className={classes.panelTitle}>
            <Typography
              variant="h5"
              color="textPrimary"
              style={{
                paddingRight: 10,
              }}
            >
              {`${displayMode} ${getLeaderboardPositionLabel(
                start!,
                end!,
                type!,
              )} Position Rewards`}
            </Typography>
          </Box>
          <Box className={classes.rightHeaderSection}>
            {props.isModal && (
              <IconButton onClick={(): void => clearCurrentPositionReward()} size="large">
                <SvgIcon>
                  <CrossIcon />
                </SvgIcon>
              </IconButton>
            )}
          </Box>
        </Box>
        <Divider />
        <Box className={classes.contentWrapper}>
          <Box className={classes.contentPanel}>
            <PositionRewardForm
              {...{
                positionReward: form.values,
                handleChange: form.handleChange,
                setFieldTouched: form.setFieldTouched,
                setFieldValue: form.setFieldValue,
              }}
            />
          </Box>
        </Box>
        <Divider />
        <Box className={classes.footer}>
          <Box className={classes.footerSection}>
            <Button
              variant="contained"
              startIcon={<UndoIcon />}
              className={classes.resetButton}
              size="small"
              onClick={(): void => {
                form.resetForm()
              }}
              disabled={!form.dirty}
            >
              Undo
            </Button>
            <Button
              variant="contained"
              startIcon={<SaveIcon />}
              className={classes.updateButton}
              size="small"
              onClick={(): void => {
                form.submitForm()
              }}
              disabled={!form.dirty}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Panel>
    </PanelContainer>
  );
}
