import imageCompression from 'browser-image-compression'
import { FileUploadModel } from 'types/common/images/models/entities/image-upload-model'

export const compressionOptions = {
  maxSizeMB: 0.5,
  useWebWorker: true,
}

export function getExtension(filename: string): string {
  return filename.lastIndexOf('.') < 1 ? '' : filename.split('.').slice(-1)[0]
}

export function isImage(file: File): boolean {
  return file && file.type.split('/')[0] === 'image'
}

export function readFile(file: File, isVideo: boolean): Promise<FileUploadModel> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    const originalReader = new FileReader()
    const action = (): void => {
      const fileExtension = getExtension(file.name)
      resolve({
        fileExtension,
        compressedUrl: reader.result,
        originalUrl: originalReader.result,
      })
    }
    if (getExtension(file.name) !== 'gif' && !isVideo) {
      imageCompression(file, compressionOptions).then((fileToUpload) => {
        reader.readAsDataURL(fileToUpload)
      })
    } else {
      if (isVideo) {
        reader.readAsArrayBuffer(file)
      } else {
        originalReader.readAsDataURL(file)
      }
    }
    reader.addEventListener('load', action, false)
  })
}


export const DEFAULT_IMAGE_TRANSFORM_PARAMS = {
  width: 0,
  height: 0,
  rotate: 0,
}
