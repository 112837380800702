import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { isUndefined } from 'lodash-es'
import { IPostQuestionOption } from 'types/modules/posts/models/entities/post-question-option'

export function numberWithCommas(value?: number): string {
  if (isUndefined(value)) return ''
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const sortQuestionOptionsByOrder = (options: IPostQuestionOption[]): IPostQuestionOption[] => {
  if (options.some((option) => IsNullOrUndefined(option.order))) return options
  return options.sort((a, b) => {
    if (a.order < b.order) return -1
    if (a.order > b.order) return 1
    return 0
  })
}

export const createOptionsFromEnum = (values: string[]) => {
  return values
    .map((code) => ({
      value: code,
      name: code === 'NotSet' ? 'Select an option' : code
    }))
}

export const parseJSON = (jsonString: string | undefined, defaultValue: any) => {
  if (!jsonString) return []
  try {
    return JSON.parse(jsonString)
  } catch (e) {
    return defaultValue
  }
}