import { Button, ButtonProps, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx'
import { ConfirmationDialog } from 'components/confirmation-dialog'
import { DiscardIcon } from 'components/icons'
import { useInfoCardContext } from 'modules/info-cards/context'
import React from 'react'
import { InfoCardStatus } from 'types/modules/info-cards/enums/info-card-status'
import { IInfoCardActionButtonProps } from 'types/modules/info-cards/models/props/info-card-action-button-props'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.primary.contrastText,
      backgroundColor: '#ff7400',
      '&:hover': {
        backgroundColor: '#ff9033',
      },
    },
  }),
)

export const DiscardInfoCardButton: React.FC<IInfoCardActionButtonProps> = (
  props: IInfoCardActionButtonProps,
) => {
  const { discardInfoCard } = useInfoCardContext()
  const classes = useStyles()
  const isDiscarded = props.infoCard.status === InfoCardStatus.Discarded

  const buttonProps: ButtonProps = {
    variant: 'contained',
    startIcon: <DiscardIcon />,
    className: clsx(classes.root),
    size: 'small',
    disabled: props.disabled,
  }

  const buttonLabel = 'discard'

  return !isDiscarded ? (
    <ConfirmationDialog
      popoverId={`${buttonLabel}-infoCard-${props.infoCard.id}`}
      buttonProps={buttonProps}
      onConfirmation={(): void => discardInfoCard(props.infoCard.id)}
      confirmationText={`Are you sure you want to ${buttonLabel} this info card?`}
      buttonLabel={buttonLabel}
      onPopoverClose={props.onPopoverClose}
    />
  ) : null
}
