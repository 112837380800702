import { Fade, Modal, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Panel } from 'components/panel'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { EventDetailsPanel } from 'modules/events/views/components/event-details-panel'
import React from 'react'
import { IEvent } from 'types/modules/events/models/entities/event'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 40,
    },
  }),
)

interface IEventDetailsModalProps {
  currentEvent: IEvent | null
  open: boolean
  closeModal?: () => void
}

export const EventDetailsModal: React.FC<IEventDetailsModalProps> = (
  props: IEventDetailsModalProps,
) => {
  const classes = useStyles()

  return (
    <div>
      <Modal
        className={classes.modal}
        open={props.open}
      >
        <Fade in={!IsNullOrUndefined(props.currentEvent)}>
          <Panel
            xs={12}
            style={{
              display: 'flex',
              flex: 1,
              height: '100%',
            }}
          >
            <EventDetailsPanel isModal closeModal={props.closeModal} />
          </Panel>
        </Fade>
      </Modal>
    </div>
  )
}
