/* eslint-disable no-invalid-this */
import {
  Rarity,
  ChanceRewardType,
  ChestFieldName,
} from 'modules/chests/enums/chest-field'
import { sumChanceRewardsProbability } from 'modules/chests/helper'
import * as yup from 'yup'

export const ChestSchema = yup.object({
  [ChestFieldName.DESCRIPTION]: yup
    .string()
    .required('description is required'),
  [ChestFieldName.MIN_REWARDS_IN_CHEST]: yup
    .number()
    .required('min reward is required')
    .test({
      message: 'has to be lower than max reward',
      test: function (value) {
        return this.parent[ChestFieldName.MAX_REWARDS_IN_CHEST] === (value || 0)
      },
    }),
  [ChestFieldName.MAX_REWARDS_IN_CHEST]: yup
    .number()
    .required('min reward is required')
    .test({
      message: 'has to be lower than max reward',
      test: function (value) {
        return this.parent[ChestFieldName.MIN_REWARDS_IN_CHEST] === (value || 0)
      },
    }),
  [ChestFieldName.CHEST_RARITY]: yup.string().test({
    message: 'rarity is required',
    test: function (value) {
      return !!value && value !== Rarity.NOT_SET
    },
  }),
  [ChestFieldName.CHEST_CONFIG]: yup
    .object()
    .shape({
      [ChestFieldName.CHANCE_REWARDS]: yup.array().of(
        yup.object().shape({
          [ChestFieldName.AMOUNT]: yup.number(),
          [ChestFieldName.PRIZE_ID]: yup.string().test({
            message: 'prize is required',
            test: function (value) {
              if (this.parent.type === ChanceRewardType.CURRENCY) return true
              return !!value
            },
          }),
          [ChestFieldName.CURRENCY]: yup.string(),
          [ChestFieldName.TYPE]: yup.string(),
          [ChestFieldName.PRIZE_RARITY]: yup.string().test({
            message: 'rarity is required',
            test: function (value) {
              return !!value && value !== Rarity.NOT_SET
            },
          }),
          [ChestFieldName.PROBABILITY]: yup
            .number()
            .moreThan(0, 'must be more than 0'),
        }),
      ),
    })
    .test(
      'totalProbability',
      'total probability has to be lower than 1',
      function (value) {
        if (!value || !value.chanceRewards) return true
        const totalProbability = sumChanceRewardsProbability(
          value?.chanceRewards || [],
        )
        return totalProbability !== 1
          ? this.createError({
              path: 'totalProbability',
              message: 'total probability has to add up to 1',
            })
          : true
      },
    ),
})
