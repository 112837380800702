import { MODULE_NAME } from 'modules/comments'
import { AppState } from 'types/redux/types/app-state'
import _ from 'core/utils/deepdash'
import { IComment } from 'types/modules/comments/models/entities/comment'
import { ICommentsState } from 'types/modules/comments/models/state/comments-state'
import {
  IsNullOrUndefined,
  IsNullUndefinedOrEmpty,
} from 'core/utils/isNullOrUndefined'
import { IPostCommentsState } from 'types/modules/comments/models/state/post-comments-state'

const getState = (state: AppState): ICommentsState =>
  _.cloneDeep(state[MODULE_NAME])

export const getPostComments = (
  state: AppState,
  postId?: string,
): IPostCommentsState | null => {
  const postComments = getState(state).posts[postId!]

  return (
    postComments ?? {
      items: [],
      count: 0,
      cache: {},
    }
  )
}
