import { Button, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx'
import { CogIcon } from 'components/icons'
import React from 'react'
import { Link } from 'react-router-dom'
import { EventStatus } from 'types/modules/events/enums/event-status'
import { IEventActionButtonProps } from 'types/modules/events/models/props/event-action-button-props'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.text.primary,
      backgroundColor: '#ecf0f1',
      '&:hover': {
        color: theme.palette.text.primary,
        backgroundColor: '#f2f5f5',
      },
    },
  }),
)

export const ManageEventButton: React.FC<IEventActionButtonProps> = (
  props: IEventActionButtonProps,
) => {
  const classes = useStyles()

  const { event } = props

  const isDraft = event.status === EventStatus.Created

  return (
    (!isDraft && (
      <Button
        component={Link}
        variant="contained"
        startIcon={<CogIcon />}
        className={clsx(classes.root)}
        size="small"
        to={`/events/${props.event.id}/posts/list`}
      >
        Manage
      </Button>
    )) ||
    null
  )
}
