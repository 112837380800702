import {
  ParameterTypes,
  QuestRewardCurrency,
  QuestRewardField,
  QuestRewardTypes,
  QuestTaskField,
} from 'modules/quests/enums/quest-field'

export const MODULE_NAME = 'quests'
export const QUEST__GROUP_MODULE_NAME = 'questGroups'
export const EVERGREEN_QUEST_MODULE_NAME = 'evergreenQuests'
export const GAME_TASK_MODULE_NAME = 'gameTasks'

export const PAGE_SIZE = 1000

export const NEW_QUEST_REWARD = {
  [QuestRewardField.CURRENCY]: '',
  [QuestRewardField.REWARD_TEXT]: '',
  [QuestRewardField.TYPE]: '',
  [QuestRewardField.AMOUNT]: 1,
}

export const NEW_QUEST_TASK = {
  [QuestTaskField.CODE]: '',
  [QuestTaskField.QUANTITY]: 1,
}

export const REWARD_TYPE_OPTIONS = [
  {
    name: 'Currency',
    value: QuestRewardTypes.CURRENCY,
  },
  {
    name: 'Prize',
    value: QuestRewardTypes.PRIZE,
  },
]

export const CURRENCY_OPTIONS = [
  {
    name: 'Star',
    value: QuestRewardCurrency.STAR,
  },
  {
    name: 'Coin',
    value: QuestRewardCurrency.COIN,
  },
]

export const TASK_CODES_WITH_PARAMS: { [key: string]: ParameterTypes } = {
  WinXCurrency: ParameterTypes.CURRENCY,
  PlayPostWithTags: ParameterTypes.TAGS,
  WinXPostsWithTags: ParameterTypes.TAGS,
}

export const DEFAULT_PARAM_VALUES = {
  [ParameterTypes.CURRENCY]: { currency: QuestRewardCurrency.COIN },
  [ParameterTypes.TAGS]: {},
}
