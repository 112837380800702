import { Theme, Button, ButtonProps } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx'
import { ConfirmationDialog } from 'components/confirmation-dialog'
import { SubmitIcon } from 'components/icons'
import { useShippingContext } from 'modules/shipping/context'
import React from 'react'
import { UserPrizeStatus } from 'types/modules/shipping/enums/user-prize-status'
import { IUserPrizeActionButtonProps } from 'types/modules/shipping/models/props/user-prize-action-button-props'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.primary.contrastText,
      backgroundColor: '#02b3dc',
      '&:hover': {
        backgroundColor: '#14d1fd',
      },
    },
  }),
)

export const DispatchUserPrizeButton: React.FC<IUserPrizeActionButtonProps> = (
  props: IUserPrizeActionButtonProps,
) => {
  const { dispatchUserPrize } = useShippingContext()
  const classes = useStyles()

  const isReadyToShip = props.userPrize.status === UserPrizeStatus.ReadyToShip
  const isReturned = props.userPrize.status === UserPrizeStatus.Returned

  const buttonProps: ButtonProps = {
    variant: 'contained',
    startIcon: <SubmitIcon />,
    className: clsx(classes.root),
    size: 'small',
    disabled: props.disabled,
  }

  const buttonLabel = 'dispatch'

  return isReadyToShip || isReturned ? (
    <ConfirmationDialog
      popoverId={`${buttonLabel}-user-prize-${props.userPrize.id}`}
      buttonProps={buttonProps}
      onConfirmation={(): void => dispatchUserPrize(props.userPrize.id)}
      confirmationText={`Are you sure you want to ${buttonLabel} this item?`}
      buttonLabel={buttonLabel}
      onPopoverClose={props.onPopoverClose}
    />
  ) : null
}
