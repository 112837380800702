import { FormHelperText, Grid, InputLabel } from '@mui/material'
import { AutocompleteSearchSelect } from 'components/controls/inputs/downshift/autocomplete/autocomplete-search-select'
import { FormGen, FormTypes, useSimpleFormStyles } from 'components/simple-form-gen'
import { useTypedSelector } from 'core/redux/utils'
import { useEnums } from 'hooks/use-enums'
import { Selectors } from 'modules/prizes'
import { searchPrizes } from 'modules/prizes/utils/get-search-filter-option'
import { QuestRewardField, QuestRewardTypes } from 'modules/quests/enums/quest-field'
import { IQuestReward } from 'modules/quests/models/quest'
import React from 'react'
import { IPrize } from 'types/modules/prizes/models/entities/prize'

interface IQuestRewardFormProps {
  item: IQuestReward,
  setValue: (value: any, fieldName: string, id: string) => void
  errors?: any
}

export const QuestRewardForm = ({
  setValue,
  item,
  errors
}: IQuestRewardFormProps): JSX.Element => {
  const selectedPrize = useTypedSelector((state) =>
    item?.prizeId
      ? Selectors.getCachedPrizeById(state, item?.prizeId)
      : null,
  )
  const simpleFormClasses = useSimpleFormStyles()
  const { currencies, rewardOptions } = useEnums()

  return <>
    <Grid item xs={4}>
      <FormGen
        handleChange={({ target: { value } }): void => {
          setValue(value, QuestRewardField.TYPE, item.id || '')
        }}
        value={item[QuestRewardField.TYPE]}
        type={FormTypes.SELECT}
        name={QuestRewardField.TYPE}
        options={rewardOptions.map(((curr) => ({ value: curr, name: curr })))}
        label="Reward type"
        hideInitialOption
        otherProps={{
          error: !!errors?.[QuestRewardField.TYPE],
          helperText: errors?.[QuestRewardField.TYPE]
        }}
      />
    </Grid>
    {item[QuestRewardField.TYPE] === QuestRewardTypes.CURRENCY && <>
      <Grid item xs={4}>
        <FormGen
          handleChange={({ target: { value } }): void => {
            setValue(value, QuestRewardField.CURRENCY, item.id || '')
          }}
          value={item[QuestRewardField.CURRENCY]}
          type={FormTypes.SELECT}
          name={QuestRewardField.CURRENCY}
          options={currencies.map((curr) => ({ value: curr, name: curr }))}
          label="Currency type"
          hideInitialOption
          otherProps={{
            type: 'number',
            error: !!errors?.[QuestRewardField.CURRENCY],
            helperText: errors?.[QuestRewardField.CURRENCY]
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <FormGen
          handleChange={({ target: { value } }): void => {
            setValue(value, QuestRewardField.AMOUNT, item.id || '')
          }}
          value={item[QuestRewardField.AMOUNT]}
          type={FormTypes.TEXT_INPUT}
          name={QuestRewardField.AMOUNT}
          label="Amount"
          otherProps={{
            type: 'number',
            error: !!errors?.[QuestRewardField.AMOUNT],
            helperText: errors?.[QuestRewardField.AMOUNT]
          }}
        />
      </Grid>
    </>}

    {item[QuestRewardField.TYPE] === QuestRewardTypes.PRIZE && <Grid item xs={8}>
      <InputLabel htmlFor="prizeId" className={simpleFormClasses.inputLabel}>
        Prize
      </InputLabel>
      <AutocompleteSearchSelect
        variant="filled"
        initialSelectedItem={selectedPrize}
        availableItems={[]}
        onSelectedItemChange={(selectedPrize: IPrize | null): void => {
          setValue(
            selectedPrize?.id,
            QuestRewardField.PRIZE_ID,
            item.id || '',
          )
        }}
        fieldName="item.name"
        searchAsyncActionCreator={searchPrizes}
        selectorMethod={Selectors.getCachedInstantPrizes}
        options={{
          autocompleteSearchResultContent: (item: unknown): string => {
            const prize = item as IPrize
            return `${prize?.item?.name} - ${prize?.item?.details?.brand}`
          },
        }}
      />
      {!!errors?.[QuestRewardField.PRIZE_ID] &&
        <FormHelperText error>{errors?.[QuestRewardField.PRIZE_ID]}</FormHelperText>
      }
    </Grid>}
  </>
};