import { Grid, InputLabel, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react'
import { getDurationText } from 'utils/date-range'

interface IDurationProps {
  start?: string | Date
  end?: string | Date
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputLabel: {
      display: 'flex',
      marginBottom: '0.625rem',
    },
  }),
)

export const Duration = ({ start, end }: IDurationProps): JSX.Element => {
  const classes = useStyles()
  const duration = getDurationText(start, end)
  return (
    <Grid item xs={12}>
      <InputLabel className={classes.inputLabel}>
        Duration: {duration}
      </InputLabel>
    </Grid>
  )
}
