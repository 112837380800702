import { all, AllEffect, ForkEffect, takeEvery } from 'redux-saga/effects'
import MilestoneActionTypes from 'modules/milestones/action-types/milestone-action-types'
import { GetMilestonesSaga } from './get-milestone-saga'

type RootSagaReturnType = Generator<
  ForkEffect<never> | AllEffect<any>,
  void,
  any
>

export const RootSaga = function* (): RootSagaReturnType {
  yield all([

    yield takeEvery(
      MilestoneActionTypes.GET_MILESTONES,
      GetMilestonesSaga,
    ),
    yield takeEvery(
      MilestoneActionTypes.GET_MILESTONE_BY_ID,
      GetMilestonesSaga,
    ),
    yield takeEvery(
      MilestoneActionTypes.UPDATE_MILESTONE,
      GetMilestonesSaga,
    ),
  ])
}
