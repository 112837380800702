import { Avatar, Box, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {
  GossEditorProvider,
  IEditorSubmitResult,
} from 'components/goss-editor/context'
import { GossEditor } from 'components/goss-editor/views'
import { useAuth } from 'core/authentication/hooks/use-auth'
import { useCommentsContext } from 'modules/comments/context'
import React from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    commentBox: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
    },
    leftSection: {
      display: 'flex',
      height: '100%',
      marginRight: 16,
    },
    mainSection: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
    },
    avatar: {
      display: 'flex',
      width: 40,
      height: 40,
      fontSize: '1.25rem',
      fontWeight: 500,
      backgroundColor: 'rgba(0,0,0,0.14)',
    },
  }),
)

export interface ICommentBoxProps {
  onSubmit: (result: IEditorSubmitResult) => void
}

export const CommentBox: React.FC<ICommentBoxProps> = (
  props: ICommentBoxProps,
) => {
  const classes = useStyles()

  const { currentUser } = useAuth()

  const currentUserInitials = currentUser.username.substr(0, 1)

  return (
    <Box className={classes.commentBox}>
      <Box className={classes.leftSection}>
        <Avatar className={classes.avatar} variant={'circular'}>
          {currentUserInitials.toUpperCase()}
        </Avatar>
      </Box>
      <Box className={classes.mainSection}>
        <GossEditorProvider
          editableProps={{
            spellCheck: true,
            autoFocus: true,
            placeholder: 'Write a comment...',
          }}
          onSubmit={props.onSubmit}
        >
          <GossEditor />
        </GossEditorProvider>
      </Box>
    </Box>
  )
}
