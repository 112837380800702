import { IInfoCardState } from 'types/modules/info-cards/models/state/info-card-state'
import { AppState } from 'types/redux/types/app-state'
import _ from 'core/utils/deepdash'
import { InfoCardStatus } from 'types/modules/info-cards/enums/info-card-status'
import {
  IsNullOrUndefined,
  IsNullUndefinedOrEmpty,
} from 'core/utils/isNullOrUndefined'
import { IInfoCard } from 'types/modules/info-cards/models/entities/info-card'
import { MODULE_NAME } from '../constants'

const getState = (state: AppState): IInfoCardState =>
  _.cloneDeep(state[MODULE_NAME])

export const getInfoCards = (state: AppState): IInfoCardState => getState(state)

export const getInfoCardsByStatus = (
  state: AppState,
  statuses: InfoCardStatus[] | null,
): IInfoCardState => {
  const infoCards = getInfoCards(state)

  if (!IsNullUndefinedOrEmpty(statuses)) {
    infoCards.items = infoCards.items.filter((infoCard) =>
      statuses?.includes(infoCard.status),
    )
  }

  return infoCards
}

export const getCurrentInfoCard = (state: AppState): IInfoCard | null => {
  const infoCards = getInfoCards(state)

  if (IsNullUndefinedOrEmpty(infoCards.currentInfoCardId)) return null

  const currentInfoCard = infoCards.items.find(
    (infoCard) => infoCard.id === infoCards.currentInfoCardId,
  )

  return currentInfoCard ?? null
}

export const getInfoCardById = (
  state: AppState,
  infoCardId: string,
): IInfoCard | null => {
  return IsNullUndefinedOrEmpty(infoCardId)
    ? getInfoCards(state).items.find(
        (infoCard) => infoCard.id === infoCardId,
      ) ?? null
    : null
}

export const getStatuses = (state: AppState): IInfoCardState['statuses'] =>
  getInfoCards(state).statuses

export const getCachedInfoCards = (state: AppState): IInfoCard[] =>
  Object.values(getInfoCards(state).cache)

export const getCachedInfoCardById = (
  state: AppState,
  infoCardId: string,
): IInfoCard | null => {
  const infoCards = getCachedInfoCards(state)
  return infoCards.find((infoCard) => infoCard.id === infoCardId) ?? null
}
