import { Grid, Button, Box, InputLabel } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { FormGen, FormTypes } from 'components/simple-form-gen'
import { ICampaignItem } from 'modules/campaigns/models/campaign'
import { MediaItemInput } from 'modules/media-items/media-item-input'
import React from 'react'
import { SaveBeforeWarning } from 'components/save-before-warning';
import { MediaUploadModel } from 'types/common/images/models/entities/image-upload-model';
import { AspectRatio } from 'types/common/images/enum/aspect-ratio';
import { MediaOrientation } from 'types/common/images/enum/media-orientation';

interface Props {
  item: ICampaignItem
  errors: any
  handleChange: any
  index: number
  deleteItem: (itemId: string) => void
}

const useStyles = makeStyles(() =>
  createStyles({
    inputLabel: {
      display: 'flex',
      marginBottom: '0.625rem',
    },

    imageHint: {
      marginTop: 8,
      color: 'red',
    },
    imageHintText: {
      marginLeft: 8,
    },
    imageContainer: {
      height: 200,
      width: '100%',
    },
  }),
)

export function CampaignItemForm({ item, errors, handleChange, index, deleteItem }: Props) {

  const classes = useStyles()

  const title = {
    name: `items[${index}]title`,
    label: 'Title',
    value: item?.title || '',
    type: FormTypes.TEXT_INPUT,
    otherProps: {
      error: !!errors?.items?.[0]?.title,
      helperText: errors?.title,
    },
  }
  const description = {
    name: `items[${index}]description`,
    label: 'Description',
    value: item?.description || '',
    type: FormTypes.TEXT_INPUT,
    otherProps: {
      error: !!errors?.items?.[0]?.description,
      helperText: errors?.description,
      multiline: true
    },
  }
  return (<Grid container spacing={2}>
    <Grid item xs={4}>
      <InputLabel className={classes.inputLabel}>Media</InputLabel>
      <Box className={classes.imageContainer}>
        <MediaItemInput
          url={item.imageUrl || ''}
          imageLabel={'Cover Image'}
          options={{
            aspectRatio: AspectRatio.Standard,
            orientation: MediaOrientation.Portrait,
          }}
          onComplete={(fileParams: MediaUploadModel): void => {
            handleChange({ target: { value: fileParams.imageUrl, name: `items[${index}]imageUrl` } })
          }}
          mediaSquareHeight={400}
          removeCrop
          uploadMediaLabel='Pick Image'
          doneLabel="Done"
        />
      </Box>
    </Grid>
    <Grid item xs={12}>
      <FormGen handleChange={handleChange} {...title} />
    </Grid>
    <Grid item xs={12}>
      <FormGen handleChange={handleChange} {...description} />
    </Grid>
    <Grid item xs={12}>
      <Box alignSelf="flex-end">
        <Button variant="outlined" onClick={() => deleteItem(item.id!)}>
          Delete Item
        </Button>
      </Box>
    </Grid>
  </Grid>)
}