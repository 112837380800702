import { IKeyValueStringCollection } from 'types/common/interfaces/IKeyValueStringCollection'

const filters: IKeyValueStringCollection = {
  tagsOrTitle: '(tagsortitle)',
  status: '(status)',
  type: '(type)',
  start: '(start)',
  close: '(close)',
  eventId: '(eventId)',
  watchlist: '(watchlist)',
  createdBy: '(createdBy)',
  public: '(public)',
  title: '(title)',
}

export default filters
