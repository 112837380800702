import { MobileAppUserFieldName } from 'modules/mobile-app-users/enums/mobile-app-user-field'
import { Currency } from 'fe-shared-resources'

export const MODULE_NAME = 'mobileAppUsers'

export const PAGE_SIZE = 10
export const TICKETS_PAGE_SIZE = 8

export const currencyFieldNameMap = {
  [Currency.Coin]: MobileAppUserFieldName.COIN_ACCOUNT,
  [Currency.Star]: MobileAppUserFieldName.STAR_ACCOUNT,
  [Currency.BrownBox]: MobileAppUserFieldName.BROWN_BOX_ACCOUNT,
  [Currency.GoldBox]: MobileAppUserFieldName.GOLD_BOX_ACCOUNT,
  [Currency.Xp]: MobileAppUserFieldName.XP_ACCOUNT,
  [Currency.UncutDiamond]: MobileAppUserFieldName.UNCUT_DIAMOND_ACCOUNT,
  [Currency.Chest]: MobileAppUserFieldName.CHEST_ACCOUNT,
}
