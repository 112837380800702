import { Button, ButtonProps, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx'
import { ConfirmationDialog } from 'components/confirmation-dialog'
import { ClockIcon } from 'components/icons'
import { useEventsContext } from 'modules/events/context'
import moment from 'moment-timezone'
import React from 'react'
import { EventStatus } from 'types/modules/events/enums/event-status'
import { IEventActionButtonProps } from 'types/modules/events/models/props/event-action-button-props'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.text.primary,
      backgroundColor: '#ecf0f1',
      '&:hover': {
        backgroundColor: '#f2f5f5',
      },
    },
  }),
)

export const ScheduleEventButton: React.FC<IEventActionButtonProps> = (
  props: IEventActionButtonProps,
) => {
  const { scheduleEvent } = useEventsContext()
  const classes = useStyles()

  const isPending = props.event.status === EventStatus.Pending

  const buttonProps: ButtonProps = {
    variant: 'contained',
    startIcon: <ClockIcon />,
    className: clsx(classes.root),
    size: 'small',
    disabled: props.disabled,
  }

  const buttonLabel = 'schedule'

  const startTimeIsInFuture = moment(props.event.start).isSameOrAfter(moment())

  return isPending && startTimeIsInFuture ? (
    <ConfirmationDialog
      popoverId={`${buttonLabel}-event-${props.event.id}`}
      buttonProps={buttonProps}
      onConfirmation={(): void => scheduleEvent(props.event.id)}
      confirmationText={`Are you sure you want to ${buttonLabel} this event?`}
      buttonLabel={buttonLabel}
    />
  ) : null
}
