import paths from 'core/api/endpoints/live-schedules/paths'
import filters from 'core/api/endpoints/live-schedules/filters'
import params from 'core/api/endpoints/live-schedules/params'
import { RequestMethod } from 'types/common/api/enums/request-method'
import { EndpointRoute } from 'types/common/api/models/entities/endpoint-route'

const LiveScheduleEndpoints: { [key: string]: EndpointRoute } = {
  getLiveSchedules: {
    method: RequestMethod.GET,
    path: [paths.liveSchedules],
    filters,
  },
  getLiveScheduleById: {
    method: RequestMethod.GET,
    path: [paths.liveSchedules, params.liveScheduleId],
  },
  createLiveSchedule: {
    method: RequestMethod.POST,
    path: [paths.liveSchedules],
  },
  updateLiveSchedule: {
    method: RequestMethod.PUT,
    path: [paths.liveSchedules, params.liveScheduleId],
  },
}

export default LiveScheduleEndpoints
