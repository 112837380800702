import { actionTypeSuccess, cacheItem, cacheItems } from 'core/redux/utils'
import { ActionTypes } from 'modules/comments'
import { Reducer } from 'redux'
import { IComment } from 'types/modules/comments/models/entities/comment'
import { ICommentsState } from 'types/modules/comments/models/state/comments-state'
import { IPrizeState } from 'types/modules/prizes/models/state/prize-state'
import { ISimpleAction, ISuccessAction } from 'types/redux/interfaces/IAction'
import _ from 'core/utils/deepdash'
import IPaginatedItems from 'types/common/pagination/models/entities/paginated-items'
import {
  IsNullOrUndefined,
  IsNullUndefinedOrEmpty,
} from 'core/utils/isNullOrUndefined'
import { ICommentActionParams } from 'modules/comments/actions'
import { mergeArrays } from 'core/utils/mergeArrays'
import { IPostCommentsState } from 'types/modules/comments/models/state/post-comments-state'

const initialState = (): ICommentsState => ({
  posts: {},
})

type AllowedActionTypes = ISuccessAction | ISimpleAction

const CommentsReducer: Reducer<ICommentsState, AllowedActionTypes> = (
  state = initialState(),
  action: AllowedActionTypes,
) => {
  const simpleAction = action as ISimpleAction
  const successAction = action as ISuccessAction

  switch (action.type) {
    case actionTypeSuccess(ActionTypes.GET_POST_COMMENTS): {
      const newState = _.cloneDeep(state)
      const response = successAction.data as IPaginatedItems<IComment>
      const { page, postId } = successAction.payload! as ICommentActionParams

      const pageIsFirstOrNull = IsNullOrUndefined(page) || page === 1

      const postCommentState = state.posts[postId!]

      newState.posts[postId!] = {
        ...(postCommentState ?? {}),
        ...response,
        items: pageIsFirstOrNull
          ? response.items
          : mergeArrays(postCommentState?.items ?? [], response.items),
        pageSize: response.pageSize ?? response.count,
        cache: cacheItems(postCommentState?.cache ?? {}, response.items),
      } as IPostCommentsState

      return newState
    }
    case actionTypeSuccess(ActionTypes.UPDATE_POST_COMMENT): {
      const newState = _.cloneDeep(state)
      const response = successAction.data as IComment
      const { postId } = successAction.payload! as ICommentActionParams

      const postCommentState = state.posts[postId!]
      const commentIndex = postCommentState.items.findIndex(
        (comment) => comment.id === response.id,
      )

      if (commentIndex >= 0) {
        newState.posts[postId!].items[commentIndex] = response
        newState.posts[postId!].cache = cacheItem(
          postCommentState.cache,
          response,
        )
      }

      return newState
    }
    case actionTypeSuccess(ActionTypes.DELETE_POST_COMMENT): {
      const newState = _.cloneDeep(state)
      const { postId, commentId } =
        successAction.payload! as ICommentActionParams

      const postCommentState = state.posts[postId!]
      const commentIndex = postCommentState.items.findIndex(
        (comment) => comment.id === commentId,
      )

      if (
        !IsNullUndefinedOrEmpty(newState.posts[postId!]) &&
        commentIndex >= 0
      ) {
        delete newState.posts[postId!].cache[commentId!]
        newState.posts[postId!].items.splice(commentIndex, 1)
      }

      return newState
    }
    default:
      return state
  }
}

export default CommentsReducer
