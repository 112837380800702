import { Box, Chip, SvgIcon, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx'
import {
  ArrowRightIcon,
  CalendarStarIcon,
  CircleUserIcon,
  CoinIcon,
  HomeIcon,
  SearchIcon,
  SparklesIcon,
  StarIcon,
  TagSubscribeIcon,
} from 'components/icons'
import { PreviewContainer } from 'components/preview'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { PostFeedItem } from 'modules/app/views/components/previews/post-feed-item'
import { PostFeedPoll } from 'modules/app/views/components/previews/post-feed-poll'
import React from 'react'
import PostType from 'types/modules/posts/enums/post-type'
import { IPost } from 'types/modules/posts/models/entities/post'
import { Tag } from 'types/modules/tags/models/entities/tag'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    postFeedContainer: {
      position: 'relative',
      display: 'flex',
      width: '100%',
      height: '100%',
      flexDirection: 'column',
      overflow: 'hidden',
      background: '#ff787d',
    },
    coverFeedHeader: {
      display: 'flex',
      width: '100%',
      padding: '10px 20px 10px 20px',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    logo: {
      color: theme.palette.primary.contrastText,
      fontWeight: 600,
      fontStyle: 'italic',
      fontSize: '1.75rem',
    },
    feedContentWrapper: {
      display: 'flex',
      padding: 20,
      overflow: 'hidden',
      background: '#ffffff',
      height: '100%',
      borderRadius: '15px 15px 0 0',
    },
    navigationBar: {
      width: 'calc(100% - 40px)',
      marginLeft: 20,
      marginRight: 20,
      position: 'absolute',
      bottom: 30,
      height: 90,
      borderRadius: 20,
      backgroundColor: '#ff787d',
      padding: 5,
      display: 'flex',
      flexDirection: 'row',
    },
    navigationItem: {
      display: 'flex',
      flex: 1,
      borderRadius: 20,
      color: '#ffffff',
      justifyContent: 'center',
      alignItems: 'center',
    },
    activeNavItem: {
      background: 'rgba(255,255,255,0.2)',
    },
    currencyBar: {
      display: 'flex',
      flexDirection: 'row',
    },
    currencyItem: {
      position: 'relative',
      width: 60,
      background: 'rgba(255,255,255,0.2)',
      border: '1px solid #ffffff',
      borderRadius: 5,
      display: 'flex',
      height: '100%',
      justifyContent: 'flex-end',
      marginLeft: 40,
      paddingRight: 7,
    },
    currencyIcon: {
      position: 'absolute',
      width: 30,
      height: 30,
      top: '-6px',
      left: '-18px',
    },
    currencyBalance: {
      fontSize: '0.8rem',
      color: '#ffffff',
    },
  }),
)

export const PostFeedPreview: React.FC<IPost> = (post: IPost) => {
  const classes = useStyles()

  return (
    <PreviewContainer withDevice={true}>
      <Box className={classes.postFeedContainer}>
        <Box className={classes.coverFeedHeader}>
          <Typography variant="h6" className={classes.logo}>
            goss.
          </Typography>
          <Box className={classes.currencyBar}>
            <Box className={classes.currencyItem}>
              <Box className={classes.currencyIcon}>
                <StarIcon />
              </Box>
              <Typography className={classes.currencyBalance}>0</Typography>
            </Box>
            <Box className={classes.currencyItem}>
              <Box className={classes.currencyIcon}>
                <CoinIcon />
              </Box>
              <Typography className={classes.currencyBalance}>0</Typography>
            </Box>
          </Box>
        </Box>
        <Box className={classes.feedContentWrapper}>
          {post.type === PostType.Poll ? (
            <PostFeedPoll {...post} />
          ) : (
            <PostFeedItem {...post} />
          )}
        </Box>
        <Box className={classes.navigationBar}>
          <Box className={classes.navigationItem}>
            <SvgIcon>
              <SparklesIcon />
            </SvgIcon>
          </Box>
          <Box className={classes.navigationItem}>
            <SvgIcon>
              <SearchIcon />
            </SvgIcon>
          </Box>
          <Box className={clsx(classes.navigationItem, classes.activeNavItem)}>
            <SvgIcon>
              <HomeIcon />
            </SvgIcon>
          </Box>
          <Box className={classes.navigationItem}>
            <SvgIcon>
              <CalendarStarIcon />
            </SvgIcon>
          </Box>
          <Box className={classes.navigationItem}>
            <SvgIcon>
              <CircleUserIcon />
            </SvgIcon>
          </Box>
        </Box>
      </Box>
    </PreviewContainer>
  )
}
