import { RequestMethod } from 'types/common/api/enums/request-method'
import paths from './paths'
import params from './params'
import filters from './filters'

export const IapProductEndpoints = {
  getIapProducts: {
    method: RequestMethod.GET,
    path: [paths.products],
    filters,
  },
  getIapProductById: {
    method: RequestMethod.GET,
    path: [paths.products, params.iapProductId],
  },
  createIapProduct: {
    method: RequestMethod.POST,
    path: [paths.products],
  },
  updateIapProduct: {
    method: RequestMethod.PUT,
    path: [paths.products, params.iapProductId],
  },
  deleteIapProductById: {
    method: RequestMethod.DELETE,
    path: [paths.products, params.iapProductId],
  },
}
