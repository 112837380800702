import {
  useCombobox,
  UseComboboxState,
  UseComboboxStateChangeOptions,
} from 'downshift'
import { Item } from 'components/controls/inputs/downshift/item'

export default <TItem>(
  state: Partial<UseComboboxState<Item<TItem>>>,
  actionAndChanges: UseComboboxStateChangeOptions<Item<TItem>>,
): Partial<UseComboboxState<Item<TItem>>> => {
  const { changes, type } = actionAndChanges

  switch (type) {
    case useCombobox.stateChangeTypes.InputKeyDownEnter:
    case useCombobox.stateChangeTypes.ItemClick:
      return { ...changes }
  }

  return changes
}
