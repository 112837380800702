import { EndpointRoute } from 'types/common/api/models/entities/endpoint-route'
import { RequestMethod } from 'types/common/api/enums/request-method'
import paths from './paths'
import params from './params'
import filters from './filters'

const QuestEndpoints: { [key: string]: EndpointRoute } = {
  getQuests: {
    method: RequestMethod.GET,
    path: [paths.quests],
    filters,
  },
  getQuestById: {
    method: RequestMethod.GET,
    path: [paths.quests, params.questId],
  },
  createQuest: {
    method: RequestMethod.POST,
    path: [paths.quests],
  },
  updateQuest: {
    method: RequestMethod.PUT,
    path: [paths.quests, params.questId],
  },
  deleteQuestById: {
    method: RequestMethod.DELETE,
    path: [paths.quests, params.questId],
  },
  uploadQuestImage: {
    method: RequestMethod.PUT,
    path: [paths.quests, params.questId, paths.images, paths.upload],
  },
  activateQuest: {
    method: RequestMethod.PUT,
    path: [paths.quests, params.questId, paths.active],
  },
  deactivateQuest: {
    method: RequestMethod.PUT,
    path: [paths.quests, params.questId, paths.inactive],
  }
}

export default QuestEndpoints
