import { Fade, Modal, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Panel } from 'components/panel'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { EventDetailsPanel } from 'modules/events/views/components/event-details-panel'
import { UserPrizeDetailsPanel } from 'modules/shipping/views/components/manage-user-prize-panel'
import React from 'react'
import { IUserPrize } from 'types/modules/shipping/models/entities/user-prize'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 40,
    },
  }),
)

interface IUserPrizeDetailsModalProps {
  currentUserPrize: IUserPrize | null
  open: boolean
  closeModal?: () => void
}

export const UserPrizeDetailsModal: React.FC<IUserPrizeDetailsModalProps> = (
  props: IUserPrizeDetailsModalProps,
) => {
  const classes = useStyles()

  return (
    <div>
      <Modal
        className={classes.modal}
        open={props.open}
      >
        <Fade in={!IsNullOrUndefined(props.currentUserPrize)}>
          <Panel
            xs={12}
            style={{
              display: 'flex',
              height: '100%',
            }}
          >
            <UserPrizeDetailsPanel isModal />
          </Panel>
        </Fade>
      </Modal>
    </div>
  )
}
