import { NEW_QUEST_REWARD, NEW_QUEST_TASK } from 'modules/quests/constants'
import { QuestField } from 'modules/quests/enums/quest-field'
import { IQuest, IQuestReward, IQuestTask } from 'modules/quests/models/quest'
import { v4 as uuidv4 } from 'uuid'

interface IParams {
  quest: IQuest,
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
  key: QuestField.QUEST_REWARDS | QuestField.QUEST_TASKS
}

interface IReturn {
  add: () => void,
  remove: (id: string) => void,
  setValue: (value: any, fieldName: string, id: string) => void
}

export const useRewardsAndTasksHandlers = ({
  quest,
  setFieldValue,
  key
}: IParams): IReturn => {
  const newItem = key === QuestField.QUEST_REWARDS ? NEW_QUEST_REWARD : NEW_QUEST_TASK
  const add = (): void => {
    setFieldValue(
      key,
      [
        { ...newItem, id: `new-${uuidv4()}` },
        ...quest[key],
      ],
    )
  }

  const remove = (id: string): void => {
    // @ts-expect-error will write problem later
    setFieldValue(key, (quest[key]).filter((item: { id: string }) => id !== item.id))
  }

  const setValue = (
    value: any,
    fieldName: string,
    id: string,
  ): void => {
    const items = quest[key]
    const index = items.findIndex((item: IQuestReward | IQuestTask) => item.id! === id)
    items[index] = { ...items[index], [fieldName]: value }
    setFieldValue(key, items)
  }

  return {
    add,
    remove,
    setValue
  }
}