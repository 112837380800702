import { InputLabel } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react'

const useStyles = makeStyles(() =>
  createStyles({
    inputLabel: {
      display: 'flex',
      marginBottom: '0.625rem',
      justifyContent: 'space-between',
    },
    lengthHintText: {
      marginLeft: 8,
      color: 'rgb(181 166 166)',
      fontSize: 12,
      marginTop: 3,
    },
  }),
)

interface ILabelWithCharLimitProps {
  htmlFor: string
  className?: string
  label: string
  limit: number
  valueLength: number
}

export const LabelWithCharLimit = ({
  htmlFor,
  className = '',
  label,
  limit,
  valueLength,
}: ILabelWithCharLimitProps): JSX.Element => {
  const classes = useStyles()

  return (
    <InputLabel
      htmlFor={htmlFor}
      className={`${classes.inputLabel} ${className}`}
    >
      {label}
      <span className={classes.lengthHintText}>
        {limit - valueLength} chars left
      </span>
    </InputLabel>
  )
}
