import React, { useState } from 'react'
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Modal from '@mui/material/Modal'
import Fade from '@mui/material/Fade'
import { Panel } from 'components/panel'
import _ from 'core/utils/deepdash'
import { UserEditModal } from 'modules/mobile-app-users/views/mobile-user-modal/user-edit-modal'
import {
  Box,
  ButtonBase,
  IconButton,
  SvgIcon,
  Typography,
} from '@mui/material'
import clsx from 'clsx'
import { CrossIcon, HomeIcon, LinkIcon, TreasureChest } from 'components/icons'
import { useStylesForModalsWithTabs } from 'hooks/use-styles-for-modals-with-tabs'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { useMobileAppUsersContext } from 'modules/mobile-app-users/context'
import { TicketList } from 'modules/mobile-app-users/views/mobile-user-modal/tickets/ticket-list'
import { TabSwitch } from 'modules/mobile-app-users/views/mobile-user-modal/tab-switch'
import { AccSwitch } from 'modules/mobile-app-users/views/mobile-user-modal/account-switch/acc-switch'

const useStyles = makeStyles(() =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    hidden: {
      display: 'none',
    },
  }),
)

enum Tabs {
  details = 'details',
  tickets = 'tickets',
  switchAccount = 'switchAccount'
}

const TabsTitle = {
  [Tabs.details]: 'Information',
  [Tabs.tickets]: 'Tickets',
  [Tabs.switchAccount]: 'Switch account',
}

const TabsIcon = {
  [Tabs.details]: HomeIcon,
  [Tabs.tickets]: TreasureChest,
  [Tabs.switchAccount]: LinkIcon,
}

export interface IEditModalProps {
  open: boolean
  handleClose: () => void
}

export const MobileUserModal: React.FC<IEditModalProps> = ({
  open,
  handleClose,
}) => {
  const classes = useStyles()
  const otherClasses = useStylesForModalsWithTabs()
  const [tab, setTab] = useState(Tabs.details)
  const { clearCurrentMobileAppUser, currentMobileAppUser } =
    useMobileAppUsersContext()

  return (
    <div>
      <Modal
        className={classes.modal}
        open={open}
      >
        <Fade in={open}>
          <Panel xs={8}>
            <Box className={otherClasses.panelHeader}>
              <Box className={otherClasses.panelTitle}>
                <Typography variant="h6">
                  Manage user -{' '}
                  {TabsTitle[tab]}
                </Typography>
              </Box>
              <Box className={otherClasses.navigationContainer}>
                {
                  Object.values(Tabs).map((tabItem) => {
                    const Icon = TabsIcon[tabItem]
                    return <TabSwitch
                      key={tabItem}
                      icon={<Icon />}
                      onSelectTab={(): void => setTab(tabItem)}
                      selected={tabItem === tab}
                    />
                  })
                }
              </Box>
              <Box className={otherClasses.rightHeaderSection}>
                <IconButton
                  onClick={(): void =>
                    !IsNullOrUndefined(handleClose)
                      ? handleClose!()
                      : clearCurrentMobileAppUser()
                  }
                  size="large">
                  <SvgIcon>
                    <CrossIcon />
                  </SvgIcon>
                </IconButton>
              </Box>
            </Box>
            <Box className={tab !== Tabs.details ? classes.hidden : ''}>
              <UserEditModal handleClose={handleClose} />
            </Box>
            <Box className={tab !== Tabs.tickets ? classes.hidden : ''}>
              <TicketList mobileAppUserId={currentMobileAppUser?.id} />
            </Box>
            <Box className={tab !== Tabs.switchAccount ? classes.hidden : ''}>
              <AccSwitch mobileAppUserId={currentMobileAppUser?.id} />
            </Box>
          </Panel>
        </Fade>
      </Modal>
    </div>
  );
}
