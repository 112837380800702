import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  selectContainer: {
    display: 'flex',
    width: '100%',
    position: 'relative',
    flexDirection: 'column',
  },
  selectedItem: {
    display: 'block',
    background: '#efefef',
    width: '100%',
    height: 39,
    padding: '10px 12px',
    position: 'relative',
  },
  hidden: {
    display: 'none',
  },
  multiselectContainer: {
    display: 'flex',
    width: '100%',
    position: 'relative',
    flexDirection: 'column',
  },
  autoCompleteList: {
    width: '100%',
    position: 'absolute',
    top: 56,
    zIndex: 5,
    backgroundColor: '#efefef',
    maxHeight: 200,
    overflow: 'auto',
  },
  chip: {
    margin: theme.spacing(0.5),
    boxSizing: 'border-box',
    background: '#ffffff',
    border: '2px solid rgba(51,51,51,0.34)',
  },
  chipWrapper: {
    width: '100%',
    marginTop: 16,
    maxHeight: 208,
    overflow: 'auto'
  }
}))
