import React from 'react'
import { Box, Grid, IconButton, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { CrossIcon, PlusIcon } from 'components/icons'
import { QuestFormTypes } from 'modules/quests/enums/quest-field'
import { IQuestReward, IQuestTask } from 'modules/quests/models/quest'
import { QuestRewardForm } from 'modules/quests/view/quests/quest-modal/quest-rewards-form'
import { QuestTasksForm } from 'modules/quests/view/quests/quest-modal/quest-tasks-form'

interface ITaskAndRewardFormsProps {
  tasks?: IQuestTask[],
  rewards?: IQuestReward[],
  add: () => void,
  remove: (id: string) => void,
  setValue: (value: any, fieldName: string, id: string) => void,
  type: QuestFormTypes,
  label: string
  errors: any
}

const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      borderLeft: '1px solid #f4f4f4',
      padding: 8,
      height: '100%',
      overflow: 'auto'
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 8,
      paddingBottom: 8,
    },
    index: {
      marginTop: 6
    },
    iconButton: {
      width: 30,
      height: 30,
    },
    innerFormWrapper: {
      marginTop: 14,
      marginBottom: 8,
      border: '1px solid #f4f4f4'
    }
  }),
)

export const TaskAndRewardForms = ({
  tasks = [],
  rewards = [],
  remove,
  add,
  type,
  setValue,
  label,
  errors
}: ITaskAndRewardFormsProps): JSX.Element => {
  const items = type === QuestFormTypes.REWARDS ? rewards : tasks
  const classes = useStyles()
  const length = items.length
  return <Box className={classes.wrapper}>
    <Box className={classes.header}>
      <Typography variant="subtitle1">{label}</Typography>
      <IconButton
        size="small"
        onClick={add}
        className={classes.iconButton}
      >
        <PlusIcon />
      </IconButton>
    </Box>
    {
      // @ts-ignore
      items.map((item: IQuestReward | IQuestTask, index: number) => <Box key={item.id}>
        <Grid container spacing={2}>
          <Grid item xs={1} className={classes.index}>
            <Typography className="h5">
              {length - index}.
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Grid container spacing={1}>
              {
                QuestFormTypes.TASKS === type
                  && <QuestTasksForm setValue={setValue} item={item as IQuestTask} errors={errors[index]} />
              }
              {
                QuestFormTypes.REWARDS === type
                  && <QuestRewardForm setValue={setValue} item={item as IQuestReward} errors={errors[index]} />
              }
            </Grid>
          </Grid>
          <Grid item xs={1}>
            <IconButton
              size="small"
              onClick={(): void => remove(item.id || '')}
              className={classes.iconButton}
            >
              <CrossIcon />
            </IconButton>
          </Grid>
        </Grid>
        <hr className={classes.innerFormWrapper} />
      </Box>)
    }
  </Box>
}