import React, { useState } from 'react'
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Box, Button, Divider, Grid, IconButton, SvgIcon, Typography } from '@mui/material'
import { NIL as NIL_UUID } from 'uuid'
import { PanelContent, PanelFooter } from 'components/panel'
import _ from 'core/utils/deepdash'
import { useFormik } from 'formik'
import { colors } from 'utils/colors'
import { ModalWrapper } from 'components/modal-wrapper'
import { useEvergreenQuestsContext } from 'modules/quests/context/evergreen-quest-context'
import { IEvergreenQuest } from 'modules/quests/models/evergreen-quest'
import { EvergreenQuestForm } from 'modules/quests/view/evergreen/evergreen-quest-modal/evergreen-form'
import { EvergreenQuestSchema } from 'modules/quests/validation/evergreen-quest-schema'
import { CrossIcon, HomeIcon, PlusIcon } from 'components/icons'
import { EvergreenQuestLevelSwitch } from 'modules/quests/view/evergreen/evergreen-quest-modal/evergreen-quest-level-switch'
import { EvergreenQuestLevel } from 'modules/quests/view/evergreen/evergreen-quest-modal/evergreen-quest-level'
import { TASK_CODES_WITH_PARAMS } from 'modules/quests/constants'
import { ParameterTypes } from 'modules/quests/enums/quest-field'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
    },
    panelHeader: {
      display: 'flex',
      width: '100%',
      flexShrink: 0,
    },
    panelTitle: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      padding: '0 0 0 1.25rem',
    },
    navigationContainer: {
      display: 'flex',
      flex: 2,
      flexGrow: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    rightHeaderSection: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      padding: '0 1.25rem 0 0',
      flexDirection: 'row-reverse',
      '& .MuiButton-contained': {
        marginRight: 20,
      },
    },
    footerContent: {
      display: 'flex',
      flex: 1,
      justifyContent: 'flex-end',
    },
    cancelBtn: {
      marginRight: '1.25rem',
    },
    wrapper: {
      borderLeft: `1px solid ${colors.rgba_0_0_0_12}`,
    },
    form: {
      height: '600px',
      overflow: 'auto',
    },
  }),
)

const capitalizeFirstLetter = (val: string): string => {
    return val.charAt(0).toUpperCase() + val.slice(1);
}

export const EvergreenQuestModal: React.FC = () => {
  const classes = useStyles()
  const {
    currentEvergreenQuest,
    updateEvergreenQuest,
    createEvergreenQuest,
    clearCurrentEvergreenQuest,
    sortedLevelsIds,
    navigationDisabled,
    selectedLevel,
    setSelectedLevel: onChooseLevel,
    requesting
  } = useEvergreenQuestsContext()
  const isUpdating = currentEvergreenQuest?.id !== NIL_UUID
  const { actionCode, tagIds = [], ...restTaskParameters } = currentEvergreenQuest?.taskParameters || {}
  const tagIdsMap = (tagIds as string[]).reduce((acc, curr) => ({ [`Tag${curr}`]: curr, ...acc }), {})

  const form = useFormik({
    enableReinitialize: false,
    validationSchema: EvergreenQuestSchema,
    initialValues: {
      id: currentEvergreenQuest?.id ?? '',
      name: currentEvergreenQuest?.name || '',
      description: currentEvergreenQuest?.description || '',
      taskCode: currentEvergreenQuest?.taskCode ?? '',
      actionCode: actionCode || '',
      taskParameters: { ...tagIdsMap, ...restTaskParameters },
      maxLevelMessage: currentEvergreenQuest?.maxLevelMessage || '',
      isVisible: !IsNullOrUndefined(currentEvergreenQuest?.isVisible) ?  currentEvergreenQuest?.isVisible : true
    },
    onSubmit: (value: IEvergreenQuest) => {
      const request = isUpdating ? updateEvergreenQuest : createEvergreenQuest
      const { actionCode, taskParameters, ...rest } = value
      const finalParameters = TASK_CODES_WITH_PARAMS[rest.taskCode] === ParameterTypes.TAGS
        ? Object
          .values(taskParameters || {})
          .reduce((acc, param) => {
            return { ...acc, [`TagId${param}`]: param}
          }, {})
        : Object.keys(taskParameters || {}).reduce((acc, curr) => ({
          [capitalizeFirstLetter(curr)]: taskParameters[curr],
          ...acc
        }), {})
      request({
        ...rest,
        code: rest.taskCode,
        taskParameters: { ...finalParameters, ActionCode: actionCode! }
      })
    },
  })

  return (
    <ModalWrapper
      panelSize={8}
      closeModal={clearCurrentEvergreenQuest}
      title={isUpdating ? 'Edit Evergreen Quest' : 'Create Evergreen Quest'}
      open={!!currentEvergreenQuest}
      PanelHeader={
        <>
          <Box className={classes.panelHeader}>
            <Box className={classes.panelTitle}>
              <Typography
                variant="h5"
                color="textPrimary"
                style={{
                  paddingRight: 10,
                }}
              >
                {isUpdating ? 'Edit Evergreen Quest' : 'Create Evergreen Quest'}
              </Typography>
            </Box>
            {isUpdating && <Box className={classes.navigationContainer}>
              <EvergreenQuestLevelSwitch
                active={!selectedLevel}
                onClick={onChooseLevel}
                disabled={navigationDisabled || requesting}
                Icon={<HomeIcon />}
              />
              {sortedLevelsIds.map((id) => <EvergreenQuestLevelSwitch
                key={id}
                evergreenQuestLevelId={id}
                active={selectedLevel === id}
                onClick={onChooseLevel}
                disabled={navigationDisabled || requesting}
              />)}
               <EvergreenQuestLevelSwitch
                evergreenQuestLevelId="new"
                active={selectedLevel === 'new'}
                onClick={onChooseLevel}
                disabled={navigationDisabled || requesting}
                Icon={<PlusIcon />}
              />
            </Box>}
            <Box className={classes.rightHeaderSection}>
              <IconButton onClick={(): void => clearCurrentEvergreenQuest()} size="large">
                <SvgIcon>
                  <CrossIcon />
                </SvgIcon>
              </IconButton>
            </Box>
          </Box>
          <Divider />
        </>
      }
    >
      {
        !selectedLevel
          ? <>
          <PanelContent>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <EvergreenQuestForm
                  evergreenQuest={form.values}
                  handleChange={form.handleChange}
                  setFieldTouched={form.setFieldTouched}
                  setFieldValue={form.setFieldValue}
                  errors={form.errors}
                />
              </Grid>
            </Grid>
          </PanelContent>
          <PanelFooter>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box className={classes.footerContent}>
                  <Button
                    size="large"
                    className={classes.cancelBtn}
                    onClick={clearCurrentEvergreenQuest}
                    variant="contained"
                    disabled={requesting}
                    color="secondary"
                  >
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    size="large"
                    onClick={form.submitForm}
                    variant="contained"
                    disabled={(form.dirty && !form.isValid) || requesting}
                  >
                    Save
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </PanelFooter>
        </>
        : <EvergreenQuestLevel
          questLevelId={selectedLevel}
          key={selectedLevel}
          onCancel={(): void => onChooseLevel()}
        />}
    </ModalWrapper>
  );
}
