import { Box, Grid, InputLabel, SvgIcon, TextField, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { IInfoCardFormProps } from 'modules/info-cards/views/components/info-card-details-panel/types/info-card-form-props'
import React from 'react'
import { InfoCardField as FieldName } from 'types/modules/info-cards/enums/info-card-field'
import { getIn } from 'formik'
import { useInfoCardContext } from 'modules/info-cards/context'
import {
  IsNullOrUndefined,
  IsNullUndefinedOrEmpty,
} from 'core/utils/isNullOrUndefined'
import { ImageIcon } from 'components/icons'
import { ImageUploader } from 'components/image-uploader'
import { MediaUploadModel } from 'types/common/images/models/entities/image-upload-model'
import { NIL as NIL_UUID } from 'uuid'
import { INFO_CARD_BODY_LIMIT } from 'modules/info-cards/constants'
import { LabelWithCharLimit } from 'modules/posts/views/components/manage-post-panel/label-with-char-limit'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    infoCardForm: {
      width: '100%',
      height: 'auto',
      '& .MuiGrid-item': {
        display: 'flex',
        flexDirection: 'column',
      },
    },
    inputLabel: {
      display: 'flex',
      marginBottom: '0.625rem',
    },
    adornmentIcon: {
      display: 'flex',
      width: 24,
      height: 24,
      marginRight: 12,
    },
    imageContainer: {
      display: 'flex',
      flex: 1,
      width: '100%',
      height: '100%',
      background: 'rgba(0,0,0,0.03)',
      color: 'rgba(0,0,0,0.54)',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
)

export const InfoCardForm: React.FC<IInfoCardFormProps> = (
  props: IInfoCardFormProps,
) => {
  const classes = useStyles()
  const { infoCard, handleChange, setFieldValue, errors } = props

  const { uploadInfoCardImage } = useInfoCardContext()

  const hasValidId =
    !IsNullUndefinedOrEmpty(infoCard.id) && infoCard.id !== NIL_UUID

  const hasImage = !IsNullOrUndefined(infoCard.media?.imageUrl)
  const imageContainerStyles: { [key: string]: string | number } = {}

  if (hasImage) {
    imageContainerStyles.backgroundImage = `url(${infoCard.media!.imageUrl!})`
  } else {
    imageContainerStyles.background = 'rgba(0,0,0,0.03)'
  }

  return (
    <Box className={classes.infoCardForm}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <InputLabel htmlFor={FieldName.TITLE} className={classes.inputLabel}>
            Title
          </InputLabel>
          <TextField
            id={FieldName.TITLE}
            name={FieldName.TITLE}
            onChange={handleChange as React.ChangeEventHandler}
            value={infoCard[FieldName.TITLE]}
            variant="filled"
            fullWidth
          />
        </Grid>
        <Grid item xs={hasValidId ? 9 : 12}>
          <LabelWithCharLimit
            htmlFor="body"
            className={classes.inputLabel}
            label="Body"
            valueLength={(infoCard?.body || '').length}
            limit={INFO_CARD_BODY_LIMIT}
          />
          <TextField
            id={FieldName.BODY}
            name={FieldName.BODY}
            onChange={handleChange as React.ChangeEventHandler}
            value={infoCard[FieldName.BODY]}
            multiline
            rows={4}
            variant="filled"
            fullWidth
            error={!!errors?.body}
            inputProps={{
              maxLength: INFO_CARD_BODY_LIMIT,
            }}
          />
        </Grid>
        {hasValidId && (
          <Grid item xs={3}>
            <InputLabel className={classes.inputLabel}>Image</InputLabel>
            <ImageUploader
              handleUpload={(fileParams: MediaUploadModel): void => {
                uploadInfoCardImage(infoCard!.id!, fileParams)
              }}
            >
              <Box
                className={classes.imageContainer}
                style={imageContainerStyles}
              >
                {!hasImage && (
                  <SvgIcon>
                    <ImageIcon />
                  </SvgIcon>
                )}
              </Box>
            </ImageUploader>
          </Grid>
        )}
        <Grid item xs={12}>
          <InputLabel
            htmlFor={`${FieldName.MEDIA}[${FieldName.FOOTER_TEXT}]`}
            className={classes.inputLabel}
          >
            Footer Text
          </InputLabel>
          <TextField
            id={`${FieldName.MEDIA}[${FieldName.FOOTER_TEXT}]`}
            name={`${FieldName.MEDIA}[${FieldName.FOOTER_TEXT}]`}
            value={getIn(
              infoCard,
              `${FieldName.MEDIA}[${FieldName.FOOTER_TEXT}]`,
            )}
            onChange={(event): void => {
              setFieldValue(
                `${FieldName.MEDIA}[${FieldName.FOOTER_TEXT}]`,
                event.target.value,
              )
            }}
            variant="filled"
            fullWidth
          />
        </Grid>
      </Grid>
    </Box>
  )
}
