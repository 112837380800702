import React, { useState } from 'react'
import { RequestContextProvider } from 'core/api/context'
import { QuestsProvider } from 'modules/quests/context/quest-context'
import { QuestGroupsProvider } from 'modules/quests/context/quest-group-context'
import { PanelContainer } from 'components/panel'
import QuestList from 'modules/quests/view/quests/quest-list'
import QuestGroupList from 'modules/quests/view/quest-groups/quest-group-list'
import { Box, Grid, Theme, Tab, Tabs } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { EvergreenQuestList } from 'modules/quests/view/evergreen/evergreen-quest-list'
import { TabPanel } from 'components/tab-panel'
import { EvergreenQuestsProvider } from 'modules/quests/context/evergreen-quest-context'
import { useLocation, useParams } from 'react-router-dom'
import { GameTasksProvider } from 'modules/quests/context/game-task-context'
import { GameTaskList } from 'modules/quests/view/game-tasks/game-task-list'


export const QuestDashboard: React.FC<{}> = () => {
  let initialValue = 0
  const { search } = useLocation()
  if (search) {
    initialValue = search.includes('evergreenQuestId') ? 1 : 0
  }
  const [value, setValue] = useState(initialValue)

  const handleChange = (
    event: React.ChangeEvent<{}>,
    newValue: number,
  ): void => {
    setValue(newValue)
  }
  return (
    <RequestContextProvider>
      <Tabs
        value={value}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
        aria-label="disabled tabs example"
      >
        <Tab label="Tasks" />
        <Tab label="Quests" />
        <Tab label="Evergreens" />
      </Tabs>
      <TabPanel value={value} index={0}>
        <GameTasksProvider>
          <PanelContainer noHeight>
            <GameTaskList />
          </PanelContainer>
        </GameTasksProvider>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <QuestsProvider>
          <QuestGroupsProvider>
            <PanelContainer noHeight>
              <QuestList />
              <QuestGroupList />
            </PanelContainer>
          </QuestGroupsProvider>
        </QuestsProvider>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <EvergreenQuestsProvider>
          <PanelContainer noHeight>
            <EvergreenQuestList />
          </PanelContainer>
        </EvergreenQuestsProvider>
      </TabPanel>
    </RequestContextProvider>
  )
}
