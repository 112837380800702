const mimeMapping: { [key: string]: string } = {
  png: 'image/png',
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  gif: 'image/gif',
}

export const mapMimeType = (extension?: string): string => {
  if (!extension) return '*/*'
  return mimeMapping[extension] || '*/*'
}
