import _ from 'core/utils/deepdash'
import {
  IsNullOrUndefined,
  IsNullUndefinedOrEmpty,
} from 'core/utils/isNullOrUndefined'
import SortDirection from 'types/common/enums/sort-direction'
import { LiveScheduleField } from 'types/modules/live-schedules/enums/live-schedule-field'
import { ILiveSchedule } from 'types/modules/live-schedules/models/entities/live-schedule'
import { ILiveSchedulesState } from 'types/modules/live-schedules/models/state/live-schedules-state'
import { AppState } from 'types/redux/types/app-state'
import { NIL as NIL_UUID } from 'uuid'

import { MODULE_NAME } from '../constants'

const getState = (state: AppState): ILiveSchedulesState =>
  _.cloneDeep(state[MODULE_NAME])

export const getLiveSchedules = (state: AppState): ILiveSchedulesState => {
  const liveSchedules = getState(state)

  liveSchedules.items = liveSchedules.items.filter(
    (liveSchedule) => liveSchedule.id !== NIL_UUID,
  )

  return liveSchedules
}

export const getCurrentLiveSchedule = (
  state: AppState,
): ILiveSchedule | null => {
  const liveSchedules = getState(state)

  if (IsNullOrUndefined(liveSchedules.currentLiveScheduleId)) return null

  const currentliveSchedule = liveSchedules.items.find(
    (liveSchedule) => liveSchedule.id === liveSchedules.currentLiveScheduleId,
  )

  return currentliveSchedule ?? null
}

export const getCachedLiveSchedulesByEvent = (
  state: AppState,
  eventId?: string,
  sortBy: string = LiveScheduleField.START,
  sortDirection: SortDirection = SortDirection.Descending,
): ILiveSchedule[] => {
  if (IsNullUndefinedOrEmpty(eventId)) return []

  const cache = getState(state).cache

  const filtered = Object.values(cache).filter(
    (liveSchedule) => liveSchedule.eventId === eventId,
  )

  const sorted = _.sortBy(filtered, sortBy)

  return sortDirection === SortDirection.Ascending ? sorted : _.reverse(sorted)
}
