import { Box, ButtonBase, StepIconProps, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx'
import { IPostNavigationConfigItem } from 'modules/posts/views/components/manage-post-panel/types/post-navigation-config-item'
import React from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    navItemButton: {
      display: 'flex',
      padding: 16,
    },
    postNavigationItem: {
      display: 'flex',
      width: 40,
      height: 40,
      borderRadius: 90,
      alignItems: 'center',
      justifyContent: 'center',
      background: 'rgba(0,0,0,0.03)',
      color: theme.palette.text.primary,
      '&.active': {
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      },
    },
    navItemText: {
      fontSize: '0.8rem',
    },
    navItemIcon: {
      fontSize: 16,
    },
  }),
)

interface IPostNavigationItemProps {
  config: IPostNavigationConfigItem
  page: number
  active: boolean
  onClick: (page: number) => void
  disabled?: boolean
}

export const PostNavigationItem: React.FC<IPostNavigationItemProps> = (
  props: IPostNavigationItemProps,
) => {
  const classes = useStyles()
  return (
    <ButtonBase
      disabled={props.disabled}
      className={clsx(classes.navItemButton, props.active && 'active')}
      onClick={(): void => props.onClick(props.page)}
    >
      <Box
        className={clsx(classes.postNavigationItem, props.active && 'active')}
      >
        {typeof props.config.label === 'string' ? (
          <Typography className={classes.navItemText}>
            {props.config.label}
          </Typography>
        ) : (
          <Box className={classes.navItemIcon}>{props.config.label}</Box>
        )}
      </Box>
    </ButtonBase>
  )
}
