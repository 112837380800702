import paths from 'core/api/endpoints/events/paths'
import filters from 'core/api/endpoints/events/filters'
import params from 'core/api/endpoints/events/params'
import { RequestMethod } from 'types/common/api/enums/request-method'

const EventsEndpoints = {
  getEventStatuses: {
    method: RequestMethod.GET,
    path: [paths.events, paths.statuses],
    filters,
  },
  getEvents: {
    method: RequestMethod.GET,
    path: [paths.events],
    filters,
  },
  getEventById: {
    method: RequestMethod.GET,
    path: [paths.events, params.eventId],
  },
  createEvent: {
    method: RequestMethod.POST,
    path: [paths.events],
  },
  updateEvent: {
    method: RequestMethod.PUT,
    path: [paths.events, params.eventId],
  },
  updateEventStatus: {
    method: RequestMethod.PUT,
    path: [
      paths.events,
      params.eventId,
      paths.updateStatus,
      params.eventStatus,
    ],
  },
  uploadEventImage: {
    method: RequestMethod.PUT,
    path: [paths.events, params.eventId, paths.images, paths.upload],
  },
  uploadEventPassImage: {
    method: RequestMethod.PUT,
    path: [paths.events, params.eventId, paths.images, paths.eventPassUpload],
  },
  uploadDefaultLiveScheduleImage: {
    method: RequestMethod.PUT,
    path: [
      paths.events,
      params.eventId,
      paths.images,
      paths.defaultScheduleImageUpload,
    ],
  },
  promoteEvent: {
    method: RequestMethod.PUT,
    path: [paths.events, params.eventId, paths.promote],
  },
}

export default EventsEndpoints
