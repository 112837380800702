import { Box, Button, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { ConfirmationDialog } from 'components/confirmation-dialog'
import { useRequestContext } from 'core/api/context'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import React from 'react'
import { PrizeField } from 'types/modules/prizes/enums/prize-field'
import { CreatePrizeDialogButton } from 'modules/prizes/views/components/create-prize-dialog-button'
import PrizeStatus from 'types/modules/prizes/enums/prize-status'
import { usePrizeListContext } from 'modules/prizes/context/prize-list'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    prizeListHeaderPanel: {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      minWidth: 250,
      '& .MuiButton-contained': {
        marginBottom: 20,
        '&:last-of-type': {
          marginBottom: 0,
        },
      },
    },
    clearTrashButton: {
      backgroundColor: '#FDD219',
      '&:hover': {
        backgroundColor: '#fddd4e',
      },
    },
  }),
)

export const PrizeListHeaderPanel: React.FC<{}> = () => {
  const rc = useRequestContext()
  const { hasTrash, clearTrash } = usePrizeListContext()

  const statusFilter = rc?.getFilterByField(PrizeField.STATUS)

  const classes = useStyles()

  return (
    <Box className={classes.prizeListHeaderPanel}>
      <CreatePrizeDialogButton
        buttonProps={{
          variant: 'contained',
          size: 'large',
        }}
      />
      {!IsNullOrUndefined(statusFilter) &&
        (statusFilter?.value as PrizeStatus[]).includes(PrizeStatus.Trashed) &&
        hasTrash && (
          <ConfirmationDialog
            popoverId="clear-trash-button"
            confirmationText={'Are you sure you want to clear the trash?'}
            onConfirmation={(): void => clearTrash()}
            buttonProps={{
              variant: 'contained',
              size: 'large',
              className: classes.clearTrashButton,
            }}
            buttonLabel="Clear Trash"
          />
        )}
    </Box>
  )
}
