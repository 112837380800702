import React, { PropsWithChildren, useState } from 'react'
import { CssBaseline, Theme, Box } from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import Header from 'components/layouts/header'
import Navigation from 'components/layouts/navigation'
import { useAuth } from 'core/authentication/hooks/use-auth'
import { PortalPermissions } from 'core/authorization/rules'
import { useLayoutContext } from 'core/layout/context'
import { TagsProvider } from 'modules/tags/context'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    mainWrapper: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      marginTop: 65,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        maxWidth: 'calc(100vw - 250px)',
      },
    },
    toolbarContainer: {
      display: 'flex',
      width: '100%',
      flexShrink: 0,
    },
    contentWrapper: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      padding: '1.25rem',
      width: '100%',
      overflow: 'auto',
    },
  }),
)

const DashboardLayout: React.FC<PropsWithChildren<{}>> = (
  props: PropsWithChildren<{}>,
) => {
  const classes = useStyles()
  const { currentUser } = useAuth()
  const { setToolbarRef } = useLayoutContext()

  const [mobileNavOpen, setMobileNavOpen] = useState<boolean>(false)

  const toggleMobileNav = (): void => {
    setMobileNavOpen(!mobileNavOpen)
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <Header {...{ toggleMobileNav }} />
      {currentUser.can(PortalPermissions.DASHBOARD_VIEW) && (
        <Navigation {...{ mobileNavOpen, setMobileNavOpen }} />
      )}
      <main className={classes.mainWrapper}>
        <div
          className={classes.toolbarContainer}
          ref={(el): void => setToolbarRef(el)}
        />
        <Box className={classes.contentWrapper}>
          <TagsProvider>{props.children ?? null}</TagsProvider>
        </Box>
      </main>
    </React.Fragment>
  )
}

export default DashboardLayout
