import { useTypedSelector } from 'core/redux/utils'
import { getUsers } from 'modules/users/portal/actions'
import React, { PropsWithChildren, useContext } from 'react'
import { useDispatch } from 'react-redux'
import { PortalUser } from 'types/modules/users/portal/models/entities/portal-user'
import { Selectors } from '..'

export interface IPortalUsersContext {
  users: PortalUser[]
  loadUsers: () => void
}

export const PortalUsersContext = React.createContext<IPortalUsersContext>(
  {} as IPortalUsersContext,
)

export const PortalUsersProvider: React.FC<PropsWithChildren<{}>> = (
  props: PropsWithChildren<{}>,
) => {
  const dispatch = useDispatch()

  const users = useTypedSelector((state) => Selectors.getUsers(state))

  const loadUsers = (): void => {
    dispatch(getUsers())
  }

  const context: IPortalUsersContext = {
    users,
    loadUsers,
  }

  return (
    <PortalUsersContext.Provider value={context}>
      {props.children}
    </PortalUsersContext.Provider>
  )
}

export const usePortalUsersContext = (): IPortalUsersContext =>
  useContext(PortalUsersContext)
