import { useTypedSelector } from 'core/redux/utils'
import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
} from 'react'
import { IAsyncActionParams } from 'types/common/api/models/entities/async-action-params'
import { ISelectFilterConfig } from 'types/common/filtering/models/entities/ISelectFilterConfig'
import { UserPrizeStatus } from 'types/modules/shipping/enums/user-prize-status'
import { IUserPrize } from 'types/modules/shipping/models/entities/user-prize'
import { IUserPrizeState } from 'types/modules/shipping/models/state/user-prize-state'
import { Actions, Selectors } from 'modules/shipping'
import { UserPrizeField } from 'types/modules/shipping/enums/user-prize-field'
import { useDispatch } from 'react-redux'
import { getStatusFilterOptions } from 'modules/shipping/utils/get-status-filter-options'
import { useRequestContext } from 'core/api/context'
import { FilterType } from 'types/common/filtering/enums/FilterType'
import { FilterOperator } from 'types/common/filtering/enums/FilterOperator'
import SortDirection from 'types/common/enums/sort-direction'
import { useRouter } from 'core/routing/hooks/use-router'
import { IUserPrizeHistory } from 'types/modules/shipping/models/entities/user-prize-history'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import moment from 'moment-timezone'
import { useAuth } from 'core/authentication/hooks/use-auth'

export interface IShippingContext {
  userPrizes: IUserPrizeState
  statusFilterConfig: ISelectFilterConfig
  currentUserPrize: IUserPrize | null
  initialiseDashboard: (dashboardView: string) => void
  setCurrentUserPrize: (id: string) => void
  clearCurrentUserPrize: () => void
  loadUserPrizes: () => void
  currentUserPrizeHistory: IUserPrizeHistory[]
  createUserPrizeNote: (note: string, callbackFn?: () => void) => void
  updateUserPrizeStatus: (userPrizeId: string, status: UserPrizeStatus) => void
  dispatchUserPrize: (userPrizeId: string) => void
  cancelUserPrize: (userPrizeId: string) => void
  returnUserPrize: (userPrizeId: string) => void
  deliveredUserPrize: (userPrizeId: string) => void
}

const ShippingContext = createContext<IShippingContext>({} as IShippingContext)

export const ShippingProvider: React.FC<PropsWithChildren<{}>> = (
  props: PropsWithChildren<{}>,
) => {
  const rc = useRequestContext()

  const { currentUser } = useAuth()

  const statusFilter = rc?.getFilterByField(UserPrizeField.STATUS)

  const userPrizes: IUserPrizeState = useTypedSelector((state) =>
    Selectors.getUserPrizesByStatus(
      state,
      statusFilter?.value as UserPrizeStatus[],
    ),
  )

  const currentUserPrize = useTypedSelector((state) =>
    Selectors.getCurrentPrize(state),
  )

  const currentUserPrizeHistory = !IsNullOrUndefined(currentUserPrize)
    ? currentUserPrize?.userPrizeHistories.sort((a, b) =>
        moment(b.createdAt).diff(moment(a.createdAt)),
      ) ?? []
    : []

  const statusFilterConfig = {
    field: UserPrizeField.STATUS,
    options: getStatusFilterOptions(userPrizes.statuses),
  }

  const dispatch = useDispatch()

  const { routeParams, pushQueryParams, removeQueryParams } = useRouter()

  const { userPrizeId } = routeParams

  useEffect(() => {
    dispatch(Actions.setCurrentUserPrizeId({ userPrizeId }))

    // if (!IsNullOrUndefined(userPrizeId)) {
    //   dispatch(Actions.getUserPrizeById({
    //     userPrizeId
    //   }));
    // }
  }, [userPrizeId])

  const setCurrentUserPrize = (userPrizeId: string): void => {
    pushQueryParams({
      userPrizeId,
    })
  }

  const clearCurrentUserPrize = (): void => {
    removeQueryParams()
  }

  const initialiseDashboard = (dashboardView: string): void => {
    dispatch(Actions.getUserPrizeStatuses())

    rc?.applyFilter({
      field: statusFilterConfig.field,
      type: FilterType.Select,
      operator: FilterOperator.Equals,
      value:
        dashboardView === 'list'
          ? [UserPrizeStatus.ReadyToShip]
          : [
              UserPrizeStatus.Cancelled,
              UserPrizeStatus.ReadyToShip,
              UserPrizeStatus.Dispatched,
              UserPrizeStatus.Delivered,
            ],
    })

    rc?.setSortValue({
      sortBy: UserPrizeField.CREATED_AT,
      sortDirection: SortDirection.Ascending,
    })
  }

  const loadUserPrizes = useCallback((): void => {
    dispatch(
      Actions.getUserPrizes({
        filters: rc?.filters,
        page: rc?.page,
        pageSize: rc?.pageSize,
        ...rc?.sortValue,
      }),
    )
  }, [rc?.active, rc?.filters, rc?.page, rc?.pageSize, rc?.sortValue])

  useEffect(() => {
    dispatch(Actions.clearUserPrizes())
  }, [rc?.filters, rc?.sortValue])

  useEffect(() => {
    if (!rc?.active) return
    loadUserPrizes()
  }, [rc?.active, rc?.filters, rc?.page, rc?.pageSize, rc?.sortValue])

  const updateUserPrizeStatus = (
    userPrizeId: string,
    userPrizeStatus: UserPrizeStatus,
  ): void => {
    new Promise((onResolve, onReject) => {
      dispatch(
        Actions.updateUserPrizeStatus({
          userPrizeId,
          userPrizeStatus,
          promise: {
            onResolve,
            onReject,
          },
        }),
      )
    }).then(() => {
      dispatch(Actions.getUserPrizeStatuses())
    })
  }

  const dispatchUserPrize = (userPrizeId: string): void => {
    updateUserPrizeStatus(userPrizeId, UserPrizeStatus.Dispatched)
  }

  const cancelUserPrize = (userPrizeId: string): void => {
    updateUserPrizeStatus(userPrizeId, UserPrizeStatus.Cancelled)
  }

  const returnUserPrize = (userPrizeId: string): void => {
    updateUserPrizeStatus(userPrizeId, UserPrizeStatus.Returned)
  }

  const deliveredUserPrize = (userPrizeId: string): void => {
    updateUserPrizeStatus(userPrizeId, UserPrizeStatus.Delivered)
  }

  const createUserPrizeNote = (note: string, callbackFn?: () => void): void => {
    new Promise((onResolve, onReject) => {
      dispatch(
        Actions.createUserPrizeNote({
          userPrizeId,
          item: {
            note,
            createdBy: currentUser.username,
          },
          promise: {
            onResolve,
            onReject,
          },
        }),
      )
    }).then(() => {
      if (!IsNullOrUndefined(callbackFn)) callbackFn!()
    })
  }

  const context = {
    userPrizes,
    statusFilterConfig,
    currentUserPrize,
    currentUserPrizeHistory,
    initialiseDashboard,
    setCurrentUserPrize,
    clearCurrentUserPrize,
    loadUserPrizes,
    updateUserPrizeStatus,
    dispatchUserPrize,
    cancelUserPrize,
    returnUserPrize,
    deliveredUserPrize,
    createUserPrizeNote,
  }

  return (
    <ShippingContext.Provider value={context}>
      {props.children}
    </ShippingContext.Provider>
  )
}

export const useShippingContext = (): IShippingContext =>
  useContext(ShippingContext)
