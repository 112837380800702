import { MODULE_NAME } from 'modules/quests/constants'

const ActionTypes = {
  SET_CURRENT_QUEST_ID: `${MODULE_NAME}/SET_CURRENT_QUEST_ID`,
  GET_QUESTS: `${MODULE_NAME}/GET_QUESTS`,
  GET_QUEST_BY_ID: `${MODULE_NAME}/GET_QUEST_BY_ID`,
  CREATE_QUEST: `${MODULE_NAME}/CREATE_QUEST`,
  CREATE_QUEST_DRAFT: `${MODULE_NAME}/CREATE_QUEST_DRAFT`,
  UPDATE_QUEST: `${MODULE_NAME}/UPDATE_QUEST`,
  UPLOAD_QUEST_IMAGE: `${MODULE_NAME}/UPLOAD_QUEST_IMAGE`,
  ACTIVATE_QUEST: `${MODULE_NAME}/ACTIVATE_QUEST`,
  DEACTIVATE_QUEST: `${MODULE_NAME}/DEACTIVATE_QUEST`,
}

export default ActionTypes
