import { RootSagaReturnType } from 'core/redux/rootSaga'
import { ActionTypes } from 'modules/media-items'
import { GetTagsSaga } from 'modules/tags/sagas/get-tags-saga'
import { all, takeEvery } from 'redux-saga/effects'

const enabledAsyncActions = [
  ActionTypes.GET_MEDIA_ITEM_BY_ID,
  ActionTypes.UPLOAD_MEDIA_ITEM_IMAGE,
  ActionTypes.UPDATE_MEDIA_ITEM_IMAGE,
  ActionTypes.UPDATE_MEDIA_ITEM_TRANSFORM_DATA,
  ActionTypes.CREATE_VIDEO_PRESIGNED_URL,
]

export const RootSaga = function* (): RootSagaReturnType {
  yield all([yield takeEvery(enabledAsyncActions, GetTagsSaga)])
}
