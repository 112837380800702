import { EndpointRoute } from 'types/common/api/models/entities/endpoint-route'
import { RequestMethod } from 'types/common/api/enums/request-method'
import params from 'core/api/endpoints/chests/params'
import paths from './paths'
import filters from './filters'

export const ChestEndpoints: { [key: string]: EndpointRoute } = {
  getChest: {
    method: RequestMethod.GET,
    path: [paths.chests, params.chestId],
  },
  getChests: {
    method: RequestMethod.GET,
    path: [paths.chests],
    filters,
  },
  createChest: {
    method: RequestMethod.POST,
    path: [paths.chests],
  },
  updateChest: {
    method: RequestMethod.PUT,
    path: [paths.chests, params.chestId],
  },
  uploadChestImage: {
    method: RequestMethod.PUT,
    path: [paths.chests, params.chestId, paths.images, paths.upload],
  },
}
