import { RootSagaReturnType } from 'core/redux/rootSaga'
import { ActionTypes } from 'modules/leaderboards'
import { GetTagsSaga } from 'modules/tags/sagas/get-tags-saga'
import { all, AllEffect, ForkEffect, takeEvery } from 'redux-saga/effects'

const enabledAsyncActions = [
  ActionTypes.GET_LEADERBOARD_POSITION_REWARDS,
  ActionTypes.CREATE_LEADERBOARD_POSITION_REWARD,
  ActionTypes.UPDATE_LEADERBOARD_POSITION_REWARD,
]

export const RootSaga = function* (): RootSagaReturnType {
  yield all([yield takeEvery(enabledAsyncActions, GetTagsSaga)])
}
