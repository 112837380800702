import { Box, CircularProgress, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { IEditorSubmitResult } from 'components/goss-editor/context'
import { useRequestContext } from 'core/api/context'
import { useCommentsContext } from 'modules/comments/context'
import { CommentBox } from 'modules/comments/views/components/comment-feed/comment-box'
import React, { ComponentType, useEffect } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import IPaginatedItems from 'types/common/pagination/models/entities/paginated-items'
import { IComment } from 'types/modules/comments/models/entities/comment'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    commentFeed: {
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto',
      flexShrink: 1,
    },
    listContainer: {
      flex: 1,
      flexShrink: 0,
    },
    infiniteScrollLoader: {
      width: 40,
      height: 40,
      display: 'flex',
      overflow: 'hidden',
      margin: 'auto',
      marginTop: 20,
      marginBottom: 20,
    },
    addCommentSection: {
      display: 'flex',
      width: '100%',
      paddingTop: '1.25rem',
    },
  }),
)

export interface ICommentFeedProps {
  commentComponent: ComponentType<IComment>
  data: IPaginatedItems<IComment> | null
  onCreate: (result: IEditorSubmitResult) => void
  onUpdate: (result: IEditorSubmitResult) => void
}

export const CommentFeed: React.FC<ICommentFeedProps> = (
  props: ICommentFeedProps,
) => {
  const classes = useStyles()

  const rc = useRequestContext()

  const renderComments = (): JSX.Element[] | null => {
    const CommentComponent = props.commentComponent

    return (
      props.data?.items.map((item) => (
        <CommentComponent key={(item as unknown as any).id} {...item} />
      )) ?? null
    )
  }

  return (
    <React.Fragment>
      <Box className={classes.commentFeed}>
        <Box id="CommentListContainer" className={classes.listContainer}>
          <InfiniteScroll
            dataLength={props.data?.items?.length!}
            next={(): void => {
              rc?.setPage(rc?.page! + 1)
            }}
            hasMore={props.data?.items?.length! < props.data?.count!}
            loader={
              <CircularProgress className={classes.infiniteScrollLoader} />
            }
            scrollThreshold={'400px'}
            scrollableTarget="CommentListContainer"
            style={{
              width: '100%',
            }}
          >
            {renderComments()}
          </InfiniteScroll>
        </Box>
      </Box>
      <Box className={classes.addCommentSection}>
        <CommentBox onSubmit={props.onCreate} />
      </Box>
    </React.Fragment>
  )
}
