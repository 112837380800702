import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import { useTypedSelector } from 'core/redux/utils'
import { SortDirection } from 'types/common/enums/sort-direction'
import { SortValue } from 'types/common/sorting/models/entities/sort-value'
import { PanelContent } from 'components/panel'
import { makeStyles } from '@mui/styles'
import { TicketField } from 'modules/mobile-app-users/enums/ticket-fields'
import { TicketCard } from 'modules/mobile-app-users/views/mobile-user-modal/tickets/ticket-card'
import { TICKETS_PAGE_SIZE } from 'modules/mobile-app-users/constants'
import { TicketDetails } from 'modules/mobile-app-users/views/mobile-user-modal/tickets/ticket-details'
import { Selectors, Actions } from 'modules/mobile-app-users'
import { useMobileAppUsersContext } from 'modules/mobile-app-users/context'

const useStyles = makeStyles(
  createStyles({
    infiniteScrollLoader: {
      width: 40,
      height: 40,
      display: 'flex',
      overflow: 'hidden',
      margin: 'auto',
      marginTop: 20,
      marginBottom: 20,
    },
    gridWrapper: {
      display: 'grid',
      gridTemplateColumns: '1fr',
      gap: 6,
      overflow: 'hidden !important',
    },
    listWrapper: {
      height: 600,
      overflow: 'auto',
      paddingRight: 24,
    },
    detailsWrapper: {
      height: 600,
      overflow: 'auto',
      paddingRight: 24,
    },
    loader: {
      display: 'flex',
      justifyContent: 'center',
    },
    emptyScreen: {
      width: '100%',
      height: 600,
      textAlign: 'center',
      paddingTop: 32,
    },
  }),
)

interface ITicketListProps {
  mobileAppUserId?: string
}

export const TicketList: React.FC<ITicketListProps> = ({ mobileAppUserId }) => {
  const defaultSortValue: SortValue = {
    sortBy: TicketField.CREATED_AT,
    sortDirection: SortDirection.Ascending,
  }

  const classes = useStyles()

  const { selectedTicketId, selectTicket } = useMobileAppUsersContext()

  const dispatch = useDispatch()

  const tickets = useTypedSelector((state) => Selectors.getTickets(state))

  const count = useTypedSelector((state) => Selectors.getTicketsCount(state))

  const [sortValue] = useState(defaultSortValue)

  const [pageNumber, setPageNumber] = useState(1)

  const [fetching, setFetching] = useState(false)

  const pageSize = TICKETS_PAGE_SIZE

  useEffect(() => {
    const getParams: Actions.IMobileAppUserActionParams = {
      sortBy: sortValue.sortBy,
      sortDirection: sortValue.sortDirection,
      page: pageNumber,
      pageSize: pageSize,
      filters: [],
      mobileAppUserId,
    }

    const fetchTickets = async (): Promise<void> => {
      setFetching(true)
      dispatch(
        await Actions.getTickets(getParams, () => {
          setFetching(false)
        }),
      )
    }
    fetchTickets()
  }, [dispatch, pageNumber, sortValue])

  const loadMore = (): void => {
    setPageNumber(pageNumber + 1)
  }

  const renderCards = (): JSX.Element[] => {
    return (
      tickets?.map((ticket) => {
        return (
          <TicketCard
            key={`ticket-${ticket.id}`}
            ticket={ticket}
            onClick={(id: string): void => selectTicket(id)}
            selected={ticket.id === selectedTicketId}
          />
        )
      }) ?? null
    )
  }

  if (!fetching && count === 0) {
    return (
      <PanelContent>
        <Box className={classes.emptyScreen}>
          <Typography variant="h5">No tickets found for this user.</Typography>
        </Box>
      </PanelContent>
    )
  }

  return (
    <PanelContent>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          {fetching && tickets.length === 0 && (
            <Box className={classes.loader}>
              <CircularProgress />
            </Box>
          )}
          <Box className={classes.listWrapper} id="TicketContent">
            <InfiniteScroll
              dataLength={tickets?.length!}
              next={loadMore}
              hasMore={tickets?.length! < count}
              loader={
                <CircularProgress className={classes.infiniteScrollLoader} />
              }
              scrollThreshold={'300px'}
              scrollableTarget="TicketContent"
              className={classes.gridWrapper}
            >
              {renderCards()}
            </InfiniteScroll>
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Box className={classes.detailsWrapper}>
            <TicketDetails ticketId={selectedTicketId} />
          </Box>
        </Grid>
      </Grid>
    </PanelContent>
  )
}
