import {
  Box,
  Typography,
  IconButton,
  InputAdornment,
  MenuItem,
  Portal,
  Select,
  SvgIcon,
  Theme,
  ButtonBase,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {
  ArrowLeftIcon,
  CircleIcon,
  CrossIcon,
  ExclamationCircleIcon,
  FeaturedIcon,
  ImageIcon,
  SolidTickCircleIcon,
} from 'components/icons'
import { useRequestContext } from 'core/api/context'
import { useLayoutContext } from 'core/layout/context'
import { useRouter } from 'core/routing/hooks/use-router'
import {
  IsNullOrUndefined,
  IsNullUndefinedOrEmpty,
} from 'core/utils/isNullOrUndefined'
import { DisplayOptions, useWatchlistContext } from 'modules/watchlist/context'
import React, { useEffect } from 'react'
import SortDirection from 'types/common/enums/sort-direction'
import { PostField } from 'types/modules/posts/enums/post-field'
import { IPostQuestion } from 'types/modules/posts/models/entities/post-question'
import _ from 'core/utils/deepdash'
import { WatchlistPostModal } from 'modules/watchlist/views/components/post-modal'
import { IPost } from 'types/modules/posts/models/entities/post'
import { usePostListContext } from 'modules/posts/context/post-list'
import { usePostQuestionsManager } from 'modules/posts/context/post-questions-manager'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    categoryScreen: {
      display: 'flex',
      flexDirection: 'column',
    },
    toolbar: {
      display: 'flex',
      width: '100%',
      padding: '0.625rem',
      background: 'white',
      justifyContent: 'space-between',
    },
    displayFilter: {
      display: 'flex',
      alignItems: 'center',
    },
    toolbarSelectInput: {
      display: 'flex',
      flex: 1,
      height: '2.5rem',
      overflow: 'hidden',
    },
    toolbarSelectInputText: {
      textOverflow: 'clip',
    },
    categoryHeader: {
      display: 'flex',
      width: '100%',
      marginBottom: '1.25rem',
    },
    content: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
    },
    listItem: {
      display: 'flex',
      width: '100%',
      backgroundColor: 'white',
      borderRadius: 12,
      marginBottom: '0.625rem',
      '&:last-child': {
        marginBottom: 0,
      },
    },
    imageWrapper: {
      display: 'flex',
      width: 65,
      height: 65,
      background: 'rgba(0,0,0,0.03)',
      borderRadius: 90,
      margin: '0.625rem',
      overflow: 'hidden',
      justifyContent: 'center',
      alignItems: 'center',
    },
    contentImage: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
    },
    contentDetails: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      padding: '0.625rem',
      paddingLeft: 0,
    },
    statusIndicatorContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '40px',
      padding: '0.625rem',
      height: '100%',
      borderLeft: '1px solid rgba(0,0,0,0.12)',
    },
  }),
)

export const WatchlistCategoryScreen: React.FC<{}> = () => {
  const classes = useStyles()

  const { toolbarRef } = useLayoutContext()
  const requestContext = useRequestContext()
  const { currentPost } = usePostListContext()
  const { setCurrentQuestion } = usePostQuestionsManager()
  const { location, routeParams, history } = useRouter()
  const {
    currentCategory,
    currentCategoryPosts,
    groupedPosts,
    navigateToWatchlist,
    displayBy,
    handleDisplayChange,
  } = useWatchlistContext()

  const { tagCode } = routeParams

  useEffect(() => {
    requestContext?.setSortValue({
      sortBy: PostField.START,
      sortDirection: SortDirection.Ascending,
    })
  }, [])

  const renderToolbar = (): JSX.Element => (
    <Portal container={toolbarRef}>
      <Box className={classes.toolbar}>
        <IconButton onClick={(): void => navigateToWatchlist()} size="large">
          <SvgIcon>
            <ArrowLeftIcon />
          </SvgIcon>
        </IconButton>
        <Box className={classes.displayFilter}>
          <Select
            value={displayBy}
            onChange={handleDisplayChange}
            className={classes.toolbarSelectInput}
            inputProps={{
              'aria-label': 'Without label',
              className: classes.toolbarSelectInputText,
            }}
            startAdornment={
              <InputAdornment position="start">Display as: </InputAdornment>
            }
            IconComponent={'object'}
            variant="filled"
          >
            {Object.keys(DisplayOptions).map((option, index) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>
    </Portal>
  )

  const renderQuestionStatusIndicator = (
    question: IPostQuestion,
  ): JSX.Element => {
    const portalAnswers = question.answers?.filter(
      (answer) =>
        !IsNullOrUndefined(answer.updatedBy) && answer.updatedBy?.fromPortal,
    )

    const hasAnswers = !IsNullUndefinedOrEmpty(portalAnswers)

    let color = '#ffd500'
    let icon = 'circle'

    if (hasAnswers) {
      const optionsWithAnswers = question.options.filter((option) => {
        const fromPortal = option.answers.filter(
          (answer) =>
            !IsNullOrUndefined(answer.updatedBy) &&
            answer.updatedBy?.fromPortal,
        )

        return fromPortal.length > 0
      })

      _.forEach(optionsWithAnswers, (option) => {
        option.answers = option.answers.filter(
          (answer) =>
            !IsNullOrUndefined(answer.updatedBy) &&
            answer.updatedBy?.fromPortal,
        )
      })

      const anyOptionHasMultipleAnswers = optionsWithAnswers.some(
        (option) => option.answers.length > 1,
      )

      const questionHasDiscrepancies = optionsWithAnswers.length > 1

      if (anyOptionHasMultipleAnswers) {
        color = '#2cd06e'
        icon = 'tick'
      } else if (questionHasDiscrepancies) {
        color = '#ff6565'
        icon = 'cross'
      } else {
        color = '#02b3dc'
      }
    }

    return (
      <SvgIcon style={{ color }}>
        {((): JSX.Element => {
          switch (icon) {
            case 'tick':
              return <SolidTickCircleIcon />
            case 'cross':
              return <ExclamationCircleIcon />
            default:
              return <CircleIcon />
          }
        })()}
      </SvgIcon>
    )
  }

  const renderQuestions = (): JSX.Element => {
    const questions: IPostQuestion[] = []

    currentCategoryPosts?.forEach((post) => {
      post.questions.forEach((question) => {
        questions.push(question)
      })
    })

    return (
      <React.Fragment>
        {questions.map((question) => {
          return (
            <ButtonBase
              key={`question-${question.id}`}
              className={classes.listItem}
              onClick={(): void => {
                setCurrentQuestion(question)
              }}
            >
              <Box className={classes.imageWrapper}>
                {question.media?.imageUrl ? (
                  <img
                    src={question.media!.imageUrl!}
                    className={classes.contentImage}
                  />
                ) : (
                  <Box className={classes.contentImage}>
                    <ImageIcon />
                  </Box>
                )}
              </Box>
              <Box className={classes.contentDetails}>
                <Typography
                  variant="subtitle2"
                  color="textPrimary"
                  component="span"
                >
                  {question.text}
                </Typography>
                {/* <Typography
                    variant='subtitle2'
                    color='textPrimary'
                    component='span'>
                    {question.text}
                  </Typography> */}
              </Box>
              <Box className={classes.statusIndicatorContainer}>
                {renderQuestionStatusIndicator(question)}
              </Box>
            </ButtonBase>
          )
        })}
      </React.Fragment>
    )
  }

  const renderPostStatusIndicator = (post: IPost): JSX.Element => {
    // eslint-disable-next-line prefer-const
    let color = '#ffd500'
    // eslint-disable-next-line prefer-const
    let icon = 'circle'

    const postHasAnswers = post.questions.some(
      (question) =>
        question.answers.filter(
          (answer) =>
            !IsNullOrUndefined(answer.updatedBy) &&
            answer.updatedBy?.fromPortal,
        ).length > 0,
    )

    if (postHasAnswers) {
      const allQuestionsHaveMultipleCorrectAnswers = post.questions.every(
        (question) => {
          const optionsWithAnswers = question.options.filter((option) => {
            const fromPortal = option.answers.filter(
              (answer) =>
                !IsNullOrUndefined(answer.updatedBy) &&
                answer.updatedBy?.fromPortal,
            )

            return fromPortal.length > 0
          })

          _.forEach(optionsWithAnswers, (option) => {
            option.answers = option.answers.filter(
              (answer) =>
                !IsNullOrUndefined(answer.updatedBy) &&
                answer.updatedBy?.fromPortal,
            )
          })

          return optionsWithAnswers.some((option) => option.answers.length > 1)
        },
      )

      const anyQuestionsHaveDiscrepancies = post.questions.some((question) => {
        const optionsWithAnswers = question.options.filter((option) => {
          const fromPortal = option.answers.filter(
            (answer) =>
              !IsNullOrUndefined(answer.updatedBy) &&
              answer.updatedBy?.fromPortal,
          )

          return fromPortal.length > 0
        })

        _.forEach(optionsWithAnswers, (option) => {
          option.answers = option.answers.filter(
            (answer) =>
              !IsNullOrUndefined(answer.updatedBy) &&
              answer.updatedBy?.fromPortal,
          )
        })

        return optionsWithAnswers.length > 1
      })

      if (allQuestionsHaveMultipleCorrectAnswers) {
        color = '#2cd06e'
        icon = 'tick'
      } else if (anyQuestionsHaveDiscrepancies) {
        color = '#ff6565'
        icon = 'cross'
      } else {
        color = '#02b3dc'
      }
    }

    return (
      <SvgIcon style={{ color }}>
        {((): JSX.Element => {
          switch (icon) {
            case 'tick':
              return <SolidTickCircleIcon />
            case 'cross':
              return <ExclamationCircleIcon />
            default:
              return <CircleIcon />
          }
        })()}
      </SvgIcon>
    )
  }

  const renderPosts = (): JSX.Element => {
    return (
      <React.Fragment>
        {currentCategoryPosts?.map((post) => {
          return (
            <ButtonBase
              key={`post-${post.id}`}
              className={classes.listItem}
              onClick={(): void => {
                setCurrentQuestion(post.questions[0])
              }}
            >
              <Box className={classes.imageWrapper}>
                {post.media?.imageUrl ? (
                  <img
                    src={post.media!.imageUrl!}
                    className={classes.contentImage}
                  />
                ) : (
                  <Box className={classes.contentImage}>
                    <ImageIcon />
                  </Box>
                )}
              </Box>
              <Box className={classes.contentDetails}>
                <Typography
                  variant="subtitle2"
                  color="textPrimary"
                  component="span"
                >
                  {post.title}
                </Typography>
              </Box>
              <Box className={classes.statusIndicatorContainer}>
                {renderPostStatusIndicator(post)}
              </Box>
            </ButtonBase>
          )
        })}
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <Box className={classes.categoryScreen}>
        {renderToolbar()}
        <Box className={classes.categoryHeader}>
          <Typography variant="h5" color="textPrimary">
            {currentCategory}
          </Typography>
        </Box>
        <Box className={classes.content}>
          {displayBy === DisplayOptions.Questions
            ? renderQuestions()
            : renderPosts()}
        </Box>
      </Box>
      {!IsNullOrUndefined(currentPost) && (
        <WatchlistPostModal post={currentPost as IPost} />
      )}
    </React.Fragment>
  )
}
