import { RequestMethod } from 'types/common/api/enums/request-method'
import { EndpointRoute } from 'types/common/api/models/entities/endpoint-route'

const PortalUserEndpoints: { [key: string]: EndpointRoute } = {
  getUsers: {
    method: RequestMethod.GET,
    path: ['portalusers'],
  },
}

export default PortalUserEndpoints
