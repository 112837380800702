import { Box, ButtonBase, Popover, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx'
import { usePopover } from 'components/popover/use-popover'
import { InfoCardTimeGridContextMenu } from 'modules/info-cards/views/components/dashboard/info-card-calendar-time-grid-cell/context-menu'
import { TimeGridInfoCard } from 'modules/info-cards/views/components/dashboard/info-card-calendar-time-grid-cell/info-card'
import moment from 'moment-timezone'
import React from 'react'
import { IInfoCard } from 'types/modules/info-cards/models/entities/info-card'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    postEvent: {
      display: 'flex',
      flex: 1,
      boxSizing: 'border-box',
      flexDirection: 'column',
      height: 79,
      '&.multiple': {
        background: '#02599D',
        border: '1px solid #02599D',
      },
    },
    eventHeader: {
      display: 'flex',
      height: 15,
      width: '100%',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingLeft: '0.3rem',
      paddingRight: '0.3rem',
    },
    headerText: {
      fontSize: '0.7rem',
      color: theme.palette.primary.contrastText,
      '&.textPrimary': {
        color: theme.palette.text.primary,
      },
    },
    eventContent: {
      display: 'flex',
      flex: 1,
      width: '100%',
      height: 'calc(100% - 15px)',
      background: 'rgba(255,255,255,0.60)',
      padding: '0.3rem',
      '&.darker': {
        background: 'rgba(255,255,255,0.30)',
      },
    },
    multiinfoCardPopover: {
      display: 'flex',
      flexDirection: 'column',
      width: 230,
    },
    multiinfoCardCount: {
      color: theme.palette.primary.contrastText,
      textAlign: 'center',
      margin: 'auto',
    },
  }),
)

interface ITimeGridInfoCardMultiviewProps {
  infoCards: IInfoCard[]
  dateTime: string
  dateField: string
}

export const TimeGridInfoCardMultiview: React.FC<
  ITimeGridInfoCardMultiviewProps
> = (props: ITimeGridInfoCardMultiviewProps) => {
  const { infoCards, dateTime, dateField } = props
  const classes = useStyles()

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handleMultiviewPopoverClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleMultiviewPopoverClose = (): void => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  // eslint-disable-next-line id-blacklist
  const id = open ? `multiple-infoCards-${dateTime}` : undefined

  const contextMenu = usePopover({
    id: `context-menu-${dateTime}`,
    anchorReference: 'anchorPosition',
  })

  return (
    <React.Fragment>
      <ButtonBase
        aria-describedby={id}
        className={clsx(classes.postEvent, 'multiple')}
        onClick={handleMultiviewPopoverClick}
        onContextMenu={(e: React.MouseEvent<HTMLButtonElement>): void => {
          e.preventDefault()
          contextMenu.onClick(e)
        }}
      >
        <Box className={classes.eventHeader}>
          <Typography
            component="span"
            variant="button"
            className={clsx(classes.headerText)}
          >
            {moment(dateTime).format('h:mm A')}
          </Typography>
          <Typography
            component="span"
            variant="button"
            className={clsx(classes.headerText)}
          >
            {`.`}
          </Typography>
        </Box>
        <Box className={clsx(classes.eventContent, 'darker')}>
          <Typography
            component="span"
            variant="body2"
            className={clsx(classes.multiinfoCardCount)}
          >
            {`${infoCards.length} Info Cards`}
          </Typography>
        </Box>
      </ButtonBase>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleMultiviewPopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box className={classes.multiinfoCardPopover}>
          {infoCards.map((infoCard, index) => (
            <TimeGridInfoCard
              key={`info-card-${infoCard.id}`}
              index={index}
              infoCard={infoCard}
              dateField={dateField}
              onClick={(): void => handleMultiviewPopoverClose()}
            />
          ))}
        </Box>
      </Popover>
      <InfoCardTimeGridContextMenu
        dateTime={dateTime}
        dateField={dateField}
        popoverProps={contextMenu.popoverProps}
      />
    </React.Fragment>
  )
}
