import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { EditLiveScheduleButton } from 'modules/live-schedules/views/components/action-buttons/edit'
import React, { ComponentType } from 'react'
import { LiveScheduleActionButtonType } from 'types/modules/live-schedules/enums/live-schedule-action-button-type'
import { ILiveScheduleActionButtonProps } from 'types/modules/live-schedules/models/props/live-schedule-action-button-props'
import { ILiveScheduleActionButtonsProps } from 'types/modules/live-schedules/models/props/live-schedule-action-buttons-props'

export const LiveScheduleActionButtons: React.FC<
  ILiveScheduleActionButtonsProps
> = (props: ILiveScheduleActionButtonsProps) => {
  const { liveSchedule, exclude, disabled } = props

  const renderbuttons = (): JSX.Element => {
    const buttonsToRender: [
      LiveScheduleActionButtonType,
      ComponentType<ILiveScheduleActionButtonProps>,
    ][] = []

    Object.values(LiveScheduleActionButtonType).forEach((buttonType) => {
      if (!IsNullOrUndefined(exclude) && exclude?.includes(buttonType)) {
        return
      }

      let currentButton: ComponentType<ILiveScheduleActionButtonProps> | null =
        null

      switch (buttonType) {
        case LiveScheduleActionButtonType.Edit: {
          currentButton = EditLiveScheduleButton
        }
      }

      if (!IsNullOrUndefined(currentButton)) {
        buttonsToRender.push([buttonType, currentButton!])
      }
    })

    return (
      <React.Fragment>
        {buttonsToRender.map(([buttonType, Component]) => (
          <Component
            key={buttonType}
            liveSchedule={liveSchedule}
            disabled={disabled}
          />
        ))}
      </React.Fragment>
    )
  }

  return !IsNullOrUndefined(liveSchedule) ? renderbuttons() : null
}
