import { Button, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx'
import { EditIcon } from 'components/icons'
import { useLeaderboardContext } from 'modules/leaderboards/context'
import React from 'react'
import { IPositionRewardActionButtonProps } from 'types/modules/leaderboards/models/prop/position-reward-action-button-props'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.text.primary,
      backgroundColor: '#ecf0f1',
      '&:hover': {
        backgroundColor: '#f2f5f5',
      },
    },
  }),
)

export const EditPositionRewardButton: React.FC<
  IPositionRewardActionButtonProps
> = (props: IPositionRewardActionButtonProps) => {
  const classes = useStyles()

  const { setCurrentPositionReward } = useLeaderboardContext()

  return (
    <Button
      variant="contained"
      startIcon={<EditIcon />}
      className={clsx(classes.root)}
      size="small"
      onClick={(): void => setCurrentPositionReward(props.positionReward.id)}
    >
      Edit
    </Button>
  )
}
