export enum ChestFieldName {
  ID = 'id',
  DESCRIPTION = 'description',
  NAME = 'name',
  MAX_REWARDS_IN_CHEST = 'maxRewardsInChest',
  MIN_REWARDS_IN_CHEST = 'minRewardsInChest',
  STATUS = 'status',
  CHEST_CONFIG = 'chestConfig',
  CHANCE_REWARDS = 'chanceRewards',
  PRIZE_RARITY = 'prizeRarity',
  PROBABILITY = 'probability',
  TYPE = 'type',
  CURRENCY = 'currency',
  AMOUNT = 'amount',
  PRIZE_ID = 'prizeId',
  PRIZE = 'prize',
  CHEST_RARITY = 'chestRarity',
  MEDIA = 'media',
  IMAGE_URL = 'imageUrl',
  MEDIA_ITEM_ID = 'mediaItemId',
}

export enum Rarity {
  NOT_SET = 'NotSet',
  BASIC = 'Basic',
  RARE = 'Rare',
  EPIC = 'Epic',
  LEGENDARY = 'Legendary',
}

export enum ChanceRewardType {
  CURRENCY = 'Currency',
  PRIZE = 'Prize',
}

export enum ChanceRewardCurrencies {
  STAR = 'Star',
  COIN = 'Coin',
}
