import { IKeyValueStringCollection } from 'types/common/interfaces/IKeyValueStringCollection'
import { MODULE_NAME } from '../constants'

const ActionTypes = {
  // Posts
  SET_CURRENT_POST_ID: `${MODULE_NAME}/SET_CURRENT_POST_ID`,
  CLEAR_POSTS: `${MODULE_NAME}/CLEAR_POSTS`,
  GET_POSTS: `${MODULE_NAME}/GET_POSTS`,
  GET_POST_BY_ID: `${MODULE_NAME}/GET_POST_BY_ID`,
  CREATE_POST: `${MODULE_NAME}/CREATE_POST`,
  DUPLICATE_POST: `${MODULE_NAME}/DUPLICATE_POST`,
  UPDATE_POST: `${MODULE_NAME}/UPDATE_POST`,
  UPDATE_POST_TAGS: `${MODULE_NAME}/UPDATE_POST_TAGS`,
  UPLOAD_POST_IMAGE: `${MODULE_NAME}/UPLOAD_POST_IMAGE`,
  UPDATE_POST_STATUS: `${MODULE_NAME}/UPDATE_POST_STATUS`,
  SETTLE_POST_TICKETS: `${MODULE_NAME}/SETTLE_POST_TICKETS`,
  PROMOTE_POST: `${MODULE_NAME}/PROMOTE_POST`,
  UPDATE_STAKE_DISTRIBUTION: `${MODULE_NAME}/UPDATE_STAKE_DISTRIBUTION`,
  SET_POST_COMPROMISED: `${MODULE_NAME}/SET_POST_COMPROMISED`,
  DELETE_POST: `${MODULE_NAME}/DELETE_POST`,
  CLEAR_TRASH: `${MODULE_NAME}/CLEAR_TRASH`,

  // Questions
  ADD_QUESTION: `${MODULE_NAME}/ADD_QUESTION`,
  GET_QUESTION_BY_ID: `${MODULE_NAME}/GET_QUESTION_BY_ID`,
  UPDATE_QUESTION: `${MODULE_NAME}/UPDATE_QUESTION`,
  SAVE_QUESTION_AND_OPTIONS: `${MODULE_NAME}/SAVE_QUESTION_AND_OPTIONS`,
  UPLOAD_QUESTION_IMAGE: `${MODULE_NAME}/UPLOAD_QUESTION_IMAGE`,
  DELETE_QUESTION: `${MODULE_NAME}/DELETE_QUESTION`,

  // Question Alerts
  GET_QUESTION_ALERTS: `${MODULE_NAME}/GET_QUESTION_ALERTS`,
  DELETE_QUESTION_ALERT: `${MODULE_NAME}/DELETE_QUESTION_ALERT`,

  // Results
  ADD_RESULT: `${MODULE_NAME}/ADD_RESULT`,
  GET_RESULT_BY_ID: `${MODULE_NAME}/GET_RESULT_BY_ID`,
  UPDATE_RESULT: `${MODULE_NAME}/UPDATE_RESULT`,
  UPLOAD_RESULT_IMAGE: `${MODULE_NAME}/UPLOAD_RESULT_IMAGE`,
  DELETE_RESULT: `${MODULE_NAME}/DELETE_RESULT`,

  // Options
  ADD_OPTION: `${MODULE_NAME}/ADD_OPTION`,
  GET_OPTION_BY_ID: `${MODULE_NAME}/GET_OPTION_BY_ID`,
  UPDATE_OPTION: `${MODULE_NAME}/UPDATE_OPTION`,
  UPLOAD_OPTION_IMAGE: `${MODULE_NAME}/UPLOAD_OPTION_IMAGE`,
  DELETE_OPTION: `${MODULE_NAME}/DELETE_OPTION`,

  // Answers
  ADD_ANSWER: `${MODULE_NAME}/ADD_ANSWER`,
  GET_ANSWER_BY_ID: `${MODULE_NAME}/GET_ANSWER_BY_ID`,
  UPDATE_ANSWER: `${MODULE_NAME}/UPDATE_ANSWER`,
  UPLOAD_ANSWER_IMAGE: `${MODULE_NAME}/UPLOAD_ANSWER_IMAGE`,
  DELETE_ANSWER: `${MODULE_NAME}/DELETE_ANSWER`,

  // Answer Option Matches
  ADD_ANSWER_OPTION_MATCH: `${MODULE_NAME}/ADD_ANSWER_OPTION_MATCH`,
  GET_ANSWER_OPTION_MATCH_BY_ID: `${MODULE_NAME}/GET_ANSWER_OPTION_MATCH_BY_ID`,
  UPDATE_ANSWER_OPTION_MATCH: `${MODULE_NAME}/UPDATE_ANSWER_OPTION_MATCH`,
  DELETE_ANSWER_OPTION_MATCH: `${MODULE_NAME}/DELETE_ANSWER_OPTION_MATCH`,
  GET_AUTOCOMPLETE_SEARCH_POSTS: `${MODULE_NAME}/GET_AUTOCOMPLETE_SEARCH_POSTS`,

  // Post Statuses
  GET_POST_STATUSES: `${MODULE_NAME}/GET_POST_STATUSES`,
}

export default ActionTypes
