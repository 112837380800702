import { ordinalSuffixOf } from 'core/utils/ordinal-suffix-of'
import { LeaderboardPositionType } from 'types/modules/leaderboards/models/entities/leaderboard-position-type'

export const getLeaderboardPositionLabel = (
  start: number,
  end: number,
  type: LeaderboardPositionType,
): string => {
  switch (type) {
    case LeaderboardPositionType.Fixed: {
      if (start === end) {
        return ordinalSuffixOf(start)
      } else {
        return `${ordinalSuffixOf(start)} - ${ordinalSuffixOf(end)}`
      }
    }
    case LeaderboardPositionType.Percentage: {
      return `${start} - ${end}%`
    }
  }
}
