import { getRadianAngle } from 'modules/media-items/utils/get-radian-angle'

export const rotateSize = (
  width: number,
  height: number,
  rotation: number,
): {
  width: number
  height: number
} => {
  const rotRad = getRadianAngle(rotation)

  return {
    width:
      Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height:
      Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  }
}
