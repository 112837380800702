import React from 'react'
import { RequestContextProvider } from 'core/api/context'
import { IapProductsProvider } from 'modules/iap-products/context/iap-product-context'
import { IapProductList } from 'modules/iap-products/view/iap-product-list'

export const IapProductDashboard: React.FC<{}> = () => {
  return (
    <RequestContextProvider>
      <IapProductsProvider>
        <IapProductList />
      </IapProductsProvider>
    </RequestContextProvider>
  )
}
