import { call, CallEffect, fork, ForkEffect } from 'redux-saga/effects'
import { IAsyncAction } from 'types/redux/interfaces/IAction'
import Api from 'core/api'
import { ApiRequestActions } from 'types/common/api/models/entities/api-request-actions'

export type GetAppSettingsSagaReturnType = Generator<
  CallEffect<ApiRequestActions> | ForkEffect<void>,
  void,
  ApiRequestActions
>

export function* GetAppSettingsSaga(action: IAsyncAction): GetAppSettingsSagaReturnType {
  const actions: ApiRequestActions = yield call(
    Api.Utils.CreateApiRequestActions,
    action,
  )

  yield fork(Api.Sagas.AsyncRequestSaga, actions)
}
