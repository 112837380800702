import { Box, InputAdornment, MenuItem, Select, SelectChangeEvent, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useRequestContext } from 'core/api/context'
import { useTypedSelector } from 'core/redux/utils'
import { titleCase } from 'core/utils/titleCase'
import { getUsers } from 'modules/users/portal/selectors'
import React from 'react'
import { FilterOperator } from 'types/common/filtering/enums/FilterOperator'
import { FilterType } from 'types/common/filtering/enums/FilterType'
import { InfoCardField } from 'types/modules/info-cards/enums/info-card-field'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    customFilters: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
    },
    writerFilter: {
      display: 'flex',
      flexDirection: 'column',
    },
    createdByFilter: {
      display: 'flex',
      minWidth: 225,
      height: '2.5rem',
      overflow: 'hidden',
    },
    toolbarSortInputText: {
      textOverflow: 'clip',
    },
  }),
)

export const CustomFilters: React.FC<{}> = () => {
  const classes = useStyles()

  const rc = useRequestContext()

  const createdByFilter = rc?.getFilterByField(InfoCardField.CREATED_BY)

  const portalUsers = useTypedSelector((state) => getUsers(state))

  const handleCreatedByChange = (
    event: SelectChangeEvent<string>,
  ): void => {
    rc?.applyFilter({
      field: InfoCardField.CREATED_BY,
      type: FilterType.Select,
      operator: FilterOperator.Equals,
      value: event.target.value as string,
    })
  }

  return (
    <Box className={classes.customFilters}>
      <Box className={classes.writerFilter}>
        <Select
          variant={'outlined'}
          value={`${createdByFilter?.value ?? ''}`}
          onChange={handleCreatedByChange}
          className={classes.createdByFilter}
          inputProps={{
            'aria-label': 'Without label',
            className: classes.toolbarSortInputText,
          }}
          startAdornment={
            <InputAdornment position="start">Created by: </InputAdornment>
          }
        >
          <MenuItem key={`created-by-null`} value={''}>
            {'None'}
          </MenuItem>
          {portalUsers.map((user, index) => (
            <MenuItem key={`${user.username}-${index}`} value={user.username}>
              {titleCase(user.username)}
            </MenuItem>
          ))}
        </Select>
      </Box>
    </Box>
  )
}
