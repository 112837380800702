const paths = {
  milestones: 'milestones',
  add: 'add',
  save: 'save',
  update: 'update',
  upload: 'upload',
  images: 'images'
}

export default paths
