import { useRequestContext } from 'core/api/context'
import React, { ComponentType, PropsWithoutRef } from 'react'
import { SelectFilterMode } from 'types/common/filtering/enums/SelectFilterMode'
import { ISelectFilterConfig } from 'types/common/filtering/models/entities/ISelectFilterConfig'
import { ISelectOption } from 'types/common/filtering/models/entities/ISelectOption'
import _ from 'core/utils/deepdash'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { FilterType } from 'types/common/filtering/enums/FilterType'
import { FilterOperator } from 'types/common/filtering/enums/FilterOperator'
import { IFilter } from 'types/common/filtering/models/entities/IFilter'

export interface IFilterOptionComponentProps extends ISelectOption {
  onClick: (optionValue: ISelectOption['value'], isActive: boolean) => void
  isActive: boolean
}

export interface ISelectFilterProps extends ISelectFilterConfig {
  mode: SelectFilterMode
  filterOptionComponent: ComponentType<
    PropsWithoutRef<IFilterOptionComponentProps>
  >
  onChange?: (value: IFilter['value'], mode: SelectFilterMode) => void
}

export const SelectFilter: React.FC<ISelectFilterProps> = (
  props: ISelectFilterProps,
) => {
  const rc = useRequestContext()

  const filter = rc?.getFilterByField(props.field)

  const FilterOptionComponent = props.filterOptionComponent

  const handleOptionClick = (
    optionValue: ISelectOption['value'],
    isActive: boolean,
  ): void => {
    const valueIsArray = Array.isArray(optionValue)
    const valueAsArray = optionValue as any[]

    const newFilter = !IsNullOrUndefined(filter)
      ? _.clone(filter!)
      : {
          field: props.field,
          type: FilterType.Select,
          operator: FilterOperator.Equals,
          value: [],
        }

    switch (props.mode) {
      case SelectFilterMode.Single: {
        if (!isActive) {
          newFilter.value = valueIsArray ? [...valueAsArray] : [optionValue]

          rc?.applyFilter(newFilter!, {
            delay: !IsNullOrUndefined(props.onChange),
          })
        }
        break
      }
      case SelectFilterMode.Multiple: {
        if (isActive) {
          newFilter.value = _.filter(newFilter.value as any[], (val) => {
            return !valueAsArray.includes(val)
          })
        } else {
          newFilter.value = [...(newFilter.value as any[]), ...valueAsArray]
        }
        rc?.applyFilter(newFilter, {
          delay: !IsNullOrUndefined(props.onChange),
        })
        break
      }
    }

    props.onChange && props.onChange(newFilter.value, props.mode)
  }

  const optionIsActive = (option: ISelectOption): boolean => {
    const valueIsArray = Array.isArray(option.value)
    const filterValue = (filter?.value as any[]) ?? []

    if (valueIsArray) {
      const optionArray = option.value as any[]
      return (
        filterValue.includes(optionArray[0])
      )
    } else {
      return filterValue.includes(option.value)
    }
  }
  const Tag = props.OptionWrapperTag || React.Fragment
  const wrapperProps = props.optionWrapperProps || {}

  return (
    <React.Fragment>
      {props.options.map((option) => (
        <Tag {...wrapperProps} key={option.label}>
          <FilterOptionComponent
            onClick={handleOptionClick}
            isActive={optionIsActive(option)}
            {...option}
          />
        </Tag>
      ))}
    </React.Fragment>
  )
}
