import { Box } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { ExclamationTriangle } from 'components/icons'
import React from 'react'

const useStyles = makeStyles(() =>
  createStyles({
    imageHint: {
      marginTop: 8,
      color: 'red',
    },
    imageHintText: {
      marginLeft: 8,
    },
  }),
)

const defaultLabel = 'Save details before uploading image'

interface IProps {
  label?: string
}

export const SaveBeforeWarning = ({
  label = defaultLabel,
}: IProps): JSX.Element => {
  const classes = useStyles()

  return (
    <Box className={classes.imageHint}>
      <ExclamationTriangle />
      <span className={classes.imageHintText}>{label}</span>
    </Box>
  )
}
