import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { EditPositionRewardButton } from 'modules/leaderboards/views/components/position-reward-action-buttons/edit'
import React, { ComponentType } from 'react'
import { PositionRewardButtonType } from 'types/modules/leaderboards/enums/posittion-reward-button-type'
import { IPositionRewardActionButtonProps } from 'types/modules/leaderboards/models/prop/position-reward-action-button-props'
import { IPositionRewardActionButtonsProps } from 'types/modules/leaderboards/models/prop/position-reward-action-buttons-props'

const PositionRewardActionButtons: React.FC<
  IPositionRewardActionButtonsProps
> = (props: IPositionRewardActionButtonsProps) => {
  const { positionReward, exclude, onPopoverClose } = props

  const renderButtons = (): JSX.Element => {
    const buttonsToRender: [
      PositionRewardButtonType,
      ComponentType<IPositionRewardActionButtonProps>,
    ][] = []

    Object.values(PositionRewardButtonType).forEach((buttonType) => {
      if (!IsNullOrUndefined(exclude) && exclude?.includes(buttonType)) {
        return
      }

      let currentButton: ComponentType<IPositionRewardActionButtonProps> | null =
        null

      switch (buttonType) {
        case PositionRewardButtonType.Edit: {
          currentButton = EditPositionRewardButton
          break
        }
      }
      if (!IsNullOrUndefined(currentButton)) {
        buttonsToRender.push([buttonType, currentButton!])
      }
    })

    return (
      <React.Fragment>
        {buttonsToRender.map(([buttonType, Component]) => (
          <Component
            key={buttonType}
            positionReward={positionReward}
            disabled={props.disabled}
            onPopoverClose={onPopoverClose}
          />
        ))}
      </React.Fragment>
    )
  }

  return !IsNullOrUndefined(positionReward) ? renderButtons() : null
}

export default PositionRewardActionButtons
