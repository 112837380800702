export enum TicketField {
  ID = 'id',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
  COINS_WON = 'coinsWon',
  STARS_WON = 'starsWon',
  STARS_WIN = 'starsWin',
  STAKE = 'stake',
  HAS_PRIZES = 'hasPrizes',
  POST = 'post',
  STATUS = 'status',
  POST_ID = 'postId',
  OPTIONS_SELECTED = 'optionsSelected',
}
