import { useTypedSelector } from 'core/redux/utils'
import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
} from 'react'
import { Actions, Selectors } from 'modules/comments'
import { IComment } from 'types/modules/comments/models/entities/comment'
import { useRequestContext } from 'core/api/context'
import { useDispatch } from 'react-redux'
import { ICommentActionParams } from 'modules/comments/actions'
import {
  IsNullOrUndefined,
  IsNullUndefinedOrEmpty,
} from 'core/utils/isNullOrUndefined'
import { ICommentDraft } from 'types/modules/comments/models/entities/comment-draft'
import { IPostCommentsState } from 'types/modules/comments/models/state/post-comments-state'
import { usePostListContext } from 'modules/posts/context/post-list'

export interface ICommentsContext {
  currentPostComments: IPostCommentsState | null
  loadPostComments: (postId?: string) => void
  createPostComment: (
    text: string,
    postId?: string,
    callbackFn?: (value: IComment) => void,
  ) => void
  updatePostComment: (
    text: string,
    commentId: string,
    postId?: string,
    callbackFn?: (comment: IComment) => void,
  ) => void
  deletePostComment: (commentId: string, postId?: string) => void
}

export const CommentsContext = createContext<ICommentsContext>(
  {} as ICommentsContext,
)

export const CommentsProvider: React.FC<{}> = (
  props: PropsWithChildren<{}>,
) => {
  const { children } = props

  const { currentPost } = usePostListContext()
  const rc = useRequestContext()
  const dispatch = useDispatch()

  const currentPostComments = useTypedSelector((state) =>
    Selectors.getPostComments(state, currentPost?.id),
  )

  const loadPostComments = useCallback(
    (postId?: string): void => {
      dispatch(
        Actions.getPostComments({
          postId: IsNullUndefinedOrEmpty(postId) ? currentPost?.id : postId,
          filters: rc?.filters,
          page: rc?.page,
          pageSize: rc?.pageSize,
          ...rc?.sortValue,
        } as ICommentActionParams),
      )
    },
    [rc?.active, rc?.filters, rc?.page, rc?.pageSize, rc?.sortValue],
  )

  useEffect(() => {
    if (!rc?.active) return
    loadPostComments(currentPost?.id)
  }, [rc?.active, rc?.filters, rc?.page, rc?.pageSize, rc?.sortValue])

  const createPostComment = (
    text: string,
    postId?: string,
    callbackFn?: (value: IComment) => void,
  ): void => {
    const requestBody = {
      postId: IsNullUndefinedOrEmpty(postId) ? currentPost?.id : postId,
      comment: text,
    }

    new Promise<IComment>((onResolve, onReject) => {
      dispatch(
        Actions.createPostComment({
          postId: requestBody.postId,
          item: requestBody,
          promise: {
            onResolve,
            onReject,
          },
        }),
      )
    }).then((value) => {
      if (rc?.page !== 1) {
        rc?.setPage(1)
      } else {
        loadPostComments()
      }

      if (!IsNullOrUndefined(callbackFn)) callbackFn!(value)
    })
  }

  const updatePostComment = (
    text: string,
    commentId: string,
    postId?: string,
    callbackFn?: (comment: IComment) => void,
  ): void => {
    const requestBody = {
      postId: IsNullUndefinedOrEmpty(postId) ? currentPost?.id : postId,
      id: commentId,
      comment: text,
    }

    new Promise<IComment>((onResolve, onReject) => {
      dispatch(
        Actions.updatePostComment({
          postId: requestBody.postId,
          commentId: requestBody.id,
          item: requestBody,
          promise: {
            onResolve,
            onReject,
          },
        }),
      )
    }).then((comment: IComment) => {
      if (!IsNullOrUndefined(callbackFn)) {
        callbackFn!(comment)
      }
    })
  }

  const deletePostComment = (commentId: string, postId?: string): void => {
    dispatch(
      Actions.deletePostComment({
        postId: IsNullUndefinedOrEmpty(postId) ? currentPost?.id : postId,
        commentId: commentId,
      }),
    )
  }

  const context = {
    currentPostComments,
    loadPostComments,
    createPostComment,
    updatePostComment,
    deletePostComment,
  }

  return (
    <CommentsContext.Provider value={context}>
      {children}
    </CommentsContext.Provider>
  )
}

export const useCommentsContext = (): ICommentsContext =>
  useContext(CommentsContext)
