import React from 'react'
import { Theme, TableCell, TableRow } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { GameTask } from 'fe-shared-resources';

interface ICardProps {
  item: GameTask
  onClick: () => void
}

export const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }),
)(TableCell)

export const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.secondary.light,
      },
    },
  }),
)(TableRow)

export const GameTaskCard: React.FC<ICardProps> = ({
  item,
  onClick,
}: ICardProps) => {
  return (
    <StyledTableRow key={item.id} onClick={onClick}>
      <StyledTableCell component="th" scope="row">
        {item.name}
      </StyledTableCell>
      <StyledTableCell>{item.type}</StyledTableCell>
      <StyledTableCell>{item.schedule}</StyledTableCell>
      <StyledTableCell>{item.maxLevel}</StyledTableCell>
      <StyledTableCell>{`${item.isEnabled}`}</StyledTableCell>
      <StyledTableCell>{`${item.isVisible}`}</StyledTableCell>
    </StyledTableRow>
  )
}
