import * as PKG from '../../../package.json'

export const ENV = {
  NODE_ENV: process.env.NODE_ENV ?? 'development',
  TARGET_ENV: process.env.TARGET_ENV ?? 'development',
  APP_NAME: process.env.APP_NAME ?? 'Goss Media Content Portal',
  APP_VERSION: PKG.version,
  APP_BASE_URL: process.env.APP_BASE_URL ?? 'http://portal-local.goss.media',
  API_BASE_URL:
    process.env.API_BASE_URL ?? 'https://api-dev.goss.media/cms/api',
  COGNITO_AUTH_REGION: process.env.COGNITO_AUTH_REGION ?? '',
  COGNITO_AUTH_USER_POOL_ID: process.env.COGNITO_AUTH_USER_POOL_ID ?? '',
  COGNITO_AUTH_WEB_CLIENT_ID: process.env.COGNITO_AUTH_WEB_CLIENT_ID ?? '',
  COGNITO_AUTH_FLOWTYPE: process.env.COGNITO_AUTH_FLOWTYPE ?? '',
  API_BASE_URL_V2:
    process.env.API_BASE_URL_V2 ?? 'https://api-dev.goss.media/api/v2',
}
