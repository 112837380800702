import { Box, Button, ButtonProps, Popover, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx'
import { PrizeType } from 'fe-shared-resources'
import { usePrizeListContext } from 'modules/prizes/context/prize-list'
import React from 'react'
import { IPrize } from 'types/modules/prizes/models/entities/prize'
import { IPrizeDraft } from 'types/modules/prizes/models/entities/prize-draft'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popover: {
      padding: '0.625rem',
      flexDirection: 'column',
    },
    createButton: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    prizeTypeButton: {
      display: 'flex',
      width: '100%',
      marginBottom: '0.625rem',
      '&:last-of-type': {
        margin: 0,
      },
    },
  }),
)

interface ICreatePrizeDialogButtonProps {
  initialData?: Partial<IPrize>
  buttonProps: ButtonProps
  onPopoverClose?: () => void
}

export const CreatePrizeDialogButton: React.FC<
  ICreatePrizeDialogButtonProps
> = (props: ICreatePrizeDialogButtonProps) => {
  const classes = useStyles()

  const { createPrizeDraft } = usePrizeListContext()

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handlePopoverClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ): void => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = (): void => {
    setAnchorEl(null)
    props.onPopoverClose && props.onPopoverClose()
  }

  const open = Boolean(anchorEl)

  // eslint-disable-next-line id-blacklist
  const id = open ? 'create-prize-button' : undefined

  const renderPrizeTypeButtons = (): JSX.Element[] => {
    return Object.keys(PrizeType).map((prizeType) => {
      return (
        <Button
          key={`create-prize-button-${prizeType}`}
          className={clsx(classes.prizeTypeButton, prizeType)}
          {...props.buttonProps}
          onClick={(): void => {
            createPrizeDraft(prizeType as PrizeType)
            handlePopoverClose()
          }}
        >
          {prizeType}
        </Button>
      )
    })
  }

  return (
    <React.Fragment>
      <Button
        className={classes.createButton}
        {...props.buttonProps}
        onClick={handlePopoverClick}
      >
        Create Prize
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box className={classes.popover}>{renderPrizeTypeButtons()}</Box>
      </Popover>
    </React.Fragment>
  )
}
