import { Box, Theme, Toolbar } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useGossEditor } from 'components/goss-editor/context'
import { EditorToolbar } from 'components/goss-editor/views/toolbar'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import React, { useEffect } from 'react'
import { Editable, Slate } from 'slate-react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    richTextEditor: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      width: '100%',
      height: 'auto',
      border: '1px solid rgba(0,0,0,0.12)',
    },
    textAreaWrapper: {
      flexGrow: 1,
      width: '100%',
      maxHeight: 320,
      overflowY: 'auto',
      padding: '0.625rem',
    },
  }),
)

export interface IGossEditorProps {
  onCancel?: () => void
}

export const GossEditor: React.FC<IGossEditorProps> = (
  props: IGossEditorProps,
) => {
  const classes = useStyles()

  const { onCancel } = props

  const {
    editor,
    editableProps,
    value,
    setValue,
    renderElement,
    renderLeaf,
    onKeyDown,
    resetEditor,
  } = useGossEditor()

  return (
    <Box className={classes.richTextEditor}>
      <Slate editor={editor} value={value} onChange={setValue}>
        <Box className={classes.textAreaWrapper}>
          <Editable
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            onKeyDown={onKeyDown}
            {...editableProps}
          />
        </Box>
        <EditorToolbar
          onCancel={(): void => {
            resetEditor()
            if (!IsNullOrUndefined(onCancel)) onCancel!()
          }}
        />
      </Slate>
    </Box>
  )
}
