import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
} from 'react'
import { MediaUploadModel } from 'types/common/images/models/entities/image-upload-model'
import { useRequestContext } from 'core/api/context'
import { useTypedSelector } from 'core/redux/utils'
import { useRouter } from 'core/routing/hooks/use-router'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { mapMimeType } from 'core/utils/mime-mapper'
import { Actions, Selectors } from 'modules/info-cards'
import {
  IInfoCardActionParams,
  uploadInfoCardImage,
} from 'modules/info-cards/actions'
import { getStatusFilterOptions } from 'modules/info-cards/utils/get-status-filter-options'
import { useDispatch } from 'react-redux'
import { IAsyncActionParams } from 'types/common/api/models/entities/async-action-params'
import SortDirection from 'types/common/enums/sort-direction'
import { FilterOperator } from 'types/common/filtering/enums/FilterOperator'
import { FilterType } from 'types/common/filtering/enums/FilterType'
import { ISelectFilterConfig } from 'types/common/filtering/models/entities/ISelectFilterConfig'
import { InfoCardField } from 'types/modules/info-cards/enums/info-card-field'
import { InfoCardStatus } from 'types/modules/info-cards/enums/info-card-status'
import { IInfoCard } from 'types/modules/info-cards/models/entities/info-card'
import { IInfoCardDraft } from 'types/modules/info-cards/models/entities/info-card-draft'
import { IInfoCardState } from 'types/modules/info-cards/models/state/info-card-state'
import { NIL as NIL_UUID } from 'uuid'

export interface IInfoCardContext {
  infoCards: IInfoCardState
  currentInfoCard: IInfoCard | null
  setCurrentInfoCard: (infoCardId: string) => void
  clearCurrentInfoCard: () => void
  statusFilterConfig: ISelectFilterConfig
  initialiseDashboard: (dashboardView: string) => void
  loadInfoCards: (params: IAsyncActionParams<IInfoCard>) => void
  createInfoCard: (infoCard?: IInfoCardDraft) => void
  createInfoCardDraft: (infoCard?: IInfoCardDraft) => void
  updateInfoCard: (infoCard: IInfoCardDraft) => void
  activateInfoCard: (infoCardId: string) => void
  deactivateInfoCard: (infoCardId: string) => void
  discardInfoCard: (infoCardId: string) => void
  restoreInfoCard: (infoCardId: string) => void
  uploadInfoCardImage: (
    infoCardId: string,
    fileParams: MediaUploadModel,
  ) => void
}

export const InfoCardContext = createContext<IInfoCardContext>(
  {} as IInfoCardContext,
)

export const InfoCardProvider: React.FC<PropsWithChildren<{}>> = (
  props: PropsWithChildren<{}>,
) => {
  const rc = useRequestContext()

  const statusFilter = rc?.getFilterByField(InfoCardField.STATUS)

  const { routeParams, pushQueryParams, removeQueryParams } = useRouter()

  const { infoCardId } = routeParams

  const infoCards = useTypedSelector((state) =>
    Selectors.getInfoCardsByStatus(
      state,
      statusFilter?.value as InfoCardStatus[],
    ),
  )

  const currentInfoCard = useTypedSelector((state) =>
    Selectors.getCurrentInfoCard(state),
  )

  const setCurrentInfoCard = (infoCardId: string): void => {
    pushQueryParams({
      infoCardId,
    })
  }

  const clearCurrentInfoCard = (): void => {
    removeQueryParams()
  }

  const statusFilterConfig = {
    field: InfoCardField.STATUS,
    options: getStatusFilterOptions(infoCards.statuses),
  }

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(Actions.setCurrentInfoCardId({ infoCardId }))

    if (!IsNullOrUndefined(infoCardId) && infoCardId !== NIL_UUID) {
      dispatch(
        Actions.getInfoCardById({
          infoCardId,
        }),
      )
    }
  }, [infoCardId])

  const initialiseDashboard = (dashboardView: string): void => {
    dispatch(Actions.getInfoCardStatuses())

    rc?.applyFilter({
      field: statusFilterConfig.field,
      type: FilterType.Select,
      operator: FilterOperator.Equals,
      value:
        dashboardView === 'list'
          ? [InfoCardStatus.Active]
          : [
              InfoCardStatus.Active,
              InfoCardStatus.Inactive,
              InfoCardStatus.Discarded,
            ],
    })

    rc?.setSortValue({
      sortBy:
        dashboardView === 'list'
          ? InfoCardField.UPDATED_AT
          : InfoCardField.CREATED_AT,
      sortDirection:
        dashboardView === 'list'
          ? SortDirection.Ascending
          : SortDirection.Descending,
    })
  }

  const loadInfoCards = useCallback((): void => {
    dispatch(
      Actions.getInfoCards({
        filters: rc?.filters,
        page: rc?.page,
        pageSize: rc?.pageSize,
        ...rc?.sortValue,
      } as IInfoCardActionParams),
    )
  }, [rc?.active, rc?.filters, rc?.page, rc?.pageSize, rc?.sortValue])

  useEffect(() => {
    dispatch(Actions.clearInfoCards())
  }, [rc?.filters, rc?.sortValue])

  useEffect(() => {
    if (!rc?.active) return
    loadInfoCards()
  }, [rc?.active, rc?.filters, rc?.page, rc?.pageSize, rc?.sortValue])

  const createInfoCard = (infoCard?: IInfoCardDraft): void => {
    const item: IInfoCardDraft = {
      ...(infoCard ?? {}),
    }

    new Promise<IInfoCard>((onResolve, onReject) => {
      dispatch(
        Actions.createInfoCard({
          item,
          promise: {
            onResolve,
            onReject,
          },
        }),
      )
    }).then((infoCard: IInfoCard) => {
      dispatch(Actions.getInfoCardStatuses())
      setCurrentInfoCard(infoCard.id)
    })
  }

  const createInfoCardDraft = (infoCard?: IInfoCardDraft): void => {
    const newInfoCard = {
      id: NIL_UUID,
      [InfoCardField.TITLE]: infoCard?.[InfoCardField.TITLE] ?? '',
      [InfoCardField.BODY]: infoCard?.[InfoCardField.BODY] ?? '',
      [InfoCardField.STATUS]: InfoCardStatus.Inactive,
      [InfoCardField.MEDIA]: infoCard?.[InfoCardField.MEDIA] ?? {
        [InfoCardField.FOOTER_TEXT]: infoCard?.media?.footerText ?? '',
      },
    }

    dispatch(
      Actions.createInfoCardDraft({
        item: newInfoCard,
      }),
    )

    setCurrentInfoCard(NIL_UUID)
  }

  const updateInfoCard = (infoCard: IInfoCardDraft): void => {
    dispatch(
      Actions.updateInfoCard({
        infoCardId: infoCard.id,
        item: infoCard,
      }),
    )
  }

  const updateInfoCardStatus = (
    infoCardId: string,
    status: InfoCardStatus,
  ): void => {
    new Promise<IInfoCard>((onResolve, onReject) => {
      dispatch(
        Actions.updateInfoCardStatus({
          infoCardId: infoCardId,
          infoCardStatus: status,
          promise: {
            onResolve,
            onReject,
          },
        }),
      )
    }).then(() => {
      dispatch(Actions.getInfoCardStatuses())
    })
  }

  const activateInfoCard = (infoCardId: string): void => {
    updateInfoCardStatus(infoCardId, InfoCardStatus.Active)
  }

  const deactivateInfoCard = (infoCardId: string): void => {
    updateInfoCardStatus(infoCardId, InfoCardStatus.Inactive)
  }

  const discardInfoCard = (infoCardId: string): void => {
    updateInfoCardStatus(infoCardId, InfoCardStatus.Discarded)
  }

  const restoreInfoCard = (infoCardId: string): void => {
    updateInfoCardStatus(infoCardId, InfoCardStatus.Inactive)
  }

  const uploadInfoCardImage = (
    infoCardId: string,
    fileParams: MediaUploadModel,
  ): void => {
    dispatch(
      Actions.uploadInfoCardImage({
        infoCardId,
        item: fileParams.binaryData,
        extension: `image.${fileParams.fileExtension}`,
        overrideHeaders: {
          'content-type': mapMimeType(fileParams.fileExtension),
          accept: '*/*',
        },
      }),
    )
  }

  const context: IInfoCardContext = {
    infoCards,
    currentInfoCard,
    setCurrentInfoCard,
    clearCurrentInfoCard,
    statusFilterConfig,
    initialiseDashboard,
    loadInfoCards,
    createInfoCard,
    createInfoCardDraft,
    updateInfoCard,
    activateInfoCard,
    deactivateInfoCard,
    discardInfoCard,
    restoreInfoCard,
    uploadInfoCardImage,
  }

  return (
    <InfoCardContext.Provider value={context}>
      {props.children}
    </InfoCardContext.Provider>
  )
}

export const useInfoCardContext = (): IInfoCardContext =>
  useContext(InfoCardContext)
