import { Button, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx'
import { EditIcon } from 'components/icons'
import { useLiveScheduleContext } from 'modules/live-schedules/context'
import React from 'react'
import { ILiveScheduleActionButtonProps } from 'types/modules/live-schedules/models/props/live-schedule-action-button-props'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.text.primary,
      backgroundColor: '#ecf0f1',
      '&:hover': {
        backgroundColor: '#f2f5f5',
      },
    },
  }),
)

export const EditLiveScheduleButton: React.FC<
  ILiveScheduleActionButtonProps
> = (props: ILiveScheduleActionButtonProps) => {
  const classes = useStyles()

  const { setCurrentLiveSchedule } = useLiveScheduleContext()

  return (
    <Button
      variant="contained"
      startIcon={<EditIcon />}
      className={clsx(classes.root)}
      size="small"
      onClick={(): void => setCurrentLiveSchedule(props.liveSchedule.id)}
    >
      Edit
    </Button>
  )
}
