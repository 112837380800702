import { Box, Button, Chip, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { SimpleImageDisplay } from 'components/simple-image-display'
import { PostGroup } from 'fe-shared-resources';
import moment from 'moment-timezone'
import React from 'react'
import { NavLink, useHistory } from 'react-router-dom';
import { renderDates } from 'utils/date-range';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    postGroupListCard: {
      display: 'flex',
      width: '100%',
      marginBottom: '0.625rem',
      flexDirection: 'row',
      boxShadow: 'none',
      border: '1px solid rgba(0,0,0,0.12)',
      borderRadius: 4,
      '&:last-child': {
        marginBottom: 'calc(48px + 0.625rem)',
      },
    },
    postGroupContent: {
      padding: '0.625rem',
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
      justifyContent: 'space-between'
    },
    postGroupDetails: {
      display: 'flex',
      flexGrow: 1,
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    chip: {
      marginRight: 8
    }
  }),
)

interface Props {
  postGroup: PostGroup
}

export const PostGroupListItem: React.FC<Props> = ({ postGroup }: Props) => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <Box className={classes.postGroupListCard}>
        <Box className={classes.postGroupContent}>
          <Box>
            {!!postGroup?.imageUrl && <SimpleImageDisplay imageUrl={postGroup?.imageUrl} width={60} height={60}/>}
            <Box className={classes.postGroupDetails}>
              <NavLink to={`/post-groups/${postGroup.id}/posts/list`}>
                <Typography variant="subtitle1" color="textPrimary" component="span">
                  {postGroup.name}
                </Typography>
              </NavLink>
              <Typography variant="body2" color="textPrimary" component="span">
                {renderDates(postGroup.startAt, postGroup.endAt)}
              </Typography>
              <Box>
                <Chip label={`Type: ${postGroup.type}`} size="small" className={classes.chip} />
                <Chip label={`Status: ${postGroup.status}`} size="small" className={classes.chip} />
              </Box>
            </Box>
          </Box>
          <Box>
            <Button
              variant="contained"
              size="small"
              onClick={() => history.push(`/post-groups/${postGroup.id}/posts/list`)}
              color="secondary"
            >
              Manage
            </Button>
          </Box>
        </Box>
    </Box>
  )
}
