import { IapProductEndpoints } from 'core/api/endpoints/iap-products'
import ActionTypes from 'modules/iap-products/action-types/iap-products-action-types'
import { createAction, createAsyncAction } from 'core/redux/utils'
import { IAsyncAction, ISimpleAction } from 'types/redux/interfaces/IAction'
import { IAsyncActionParams } from 'types/common/api/models/entities/async-action-params'
import { IIapProduct } from 'modules/iap-products/models/iap-product'
import { Version } from 'types/common/enums/versions'

export interface IIapProductActionParams
  extends IAsyncActionParams<IIapProduct> {
  iapProductId?: string
  iapProductLevelId?: string
}

export const setCurrentIapProductId: (
  params: IIapProductActionParams,
) => ISimpleAction = (params) =>
  createAction(ActionTypes.SET_CURRENT_IAP_PRODUCT_ID, params)

export const getIapProducts: (
  params: IIapProductActionParams,
) => IAsyncAction = (params) =>
  createAsyncAction({
    type: ActionTypes.GET_IAP_PRODUCTS,
    endpoint: IapProductEndpoints.getIapProducts,
    payload: params,
    version: Version.V2,
  })

export const getIapProductById: (
  params: IIapProductActionParams,
) => IAsyncAction = (params) =>
  createAsyncAction({
    type: ActionTypes.GET_IAP_PRODUCT_BY_ID,
    endpoint: IapProductEndpoints.getIapProductById,
    payload: params,
    version: Version.V2,
  })

export const createIapProductDraft: (
  params: IIapProductActionParams,
) => ISimpleAction = (params) =>
  createAction(ActionTypes.CREATE_IAP_PRODUCT_DRAFT, params)

export const createIapProduct: (
  params: IIapProductActionParams,
) => IAsyncAction = (params) =>
  createAsyncAction({
    type: ActionTypes.CREATE_IAP_PRODUCT,
    endpoint: IapProductEndpoints.createIapProduct,
    payload: params,
    version: Version.V2,
  })

export const updateIapProduct: (
  params: IIapProductActionParams,
) => IAsyncAction = (params) =>
  createAsyncAction({
    type: ActionTypes.UPDATE_IAP_PRODUCT,
    endpoint: IapProductEndpoints.updateIapProduct,
    payload: params,
    version: Version.V2,
  })

export const deleteIapProduct: (
  params: IIapProductActionParams,
) => IAsyncAction = (params) =>
  createAsyncAction({
    type: ActionTypes.DELETE_IAP_PRODUCT,
    endpoint: IapProductEndpoints.deleteIapProductById,
    payload: params,
    version: Version.V2,
  })
