import React from 'react'
import { RequestContextProvider } from 'core/api/context'
import { MobileAppUsersProvider } from 'modules/mobile-app-users/context'
import MobileAppUsersListView from 'modules/mobile-app-users/views/dashboard-components/mobile-app-users-list'

export const MobileAppUserDashboard: React.FC<{}> = () => {
  return (
    <RequestContextProvider>
      <MobileAppUsersProvider>
        <MobileAppUsersListView />
      </MobileAppUsersProvider>
    </RequestContextProvider>
  )
}
