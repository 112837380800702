import { MODULE_NAME } from 'modules/campaigns/constants'

const ActionTypes = {
  SET_CURRENT_CAMPAIGN_ID: `${MODULE_NAME}/SET_CURRENT_CAMPAIGN_ID`,
  GET_CAMPAIGNS: `${MODULE_NAME}/GET_CAMPAIGNS`,
  GET_CAMPAIGN_BY_ID: `${MODULE_NAME}/GET_CAMPAIGN_BY_ID`,
  CREATE_CAMPAIGN: `${MODULE_NAME}/CREATE_CAMPAIGN`,
  CREATE_CAMPAIGN_DRAFT: `${MODULE_NAME}/CREATE_CAMPAIGN_DRAFT`,
  UPDATE_CAMPAIGN: `${MODULE_NAME}/UPDATE_CAMPAIGN`,
  DELETE_CAMPAIGN: `${MODULE_NAME}/DELETE_CAMPAIGN`,
  UPLOAD_CAMPAIGN_IMAGE: `${MODULE_NAME}/UPLOAD_CAMPAIGN_IMAGE`,
}

export default ActionTypes
