import { RequestMethod } from 'types/common/api/enums/request-method'
import paths from './paths'
import params from './params'
import filters from './filters'

const GameTaskEndpoints = {
  getGameTasks: {
    method: RequestMethod.GET,
    path: [paths.gameTasks],
    filters,
  },
  getGameTaskById: {
    method: RequestMethod.GET,
    path: [paths.gameTasks, params.gameTaskId],
  },
  getGameTaskSteps: {
    method: RequestMethod.GET,
    path: [paths.gameTasks, params.gameTaskId, paths.steps],
  },
  createGameTask: {
    method: RequestMethod.POST,
    path: [paths.gameTasks],
  },
  updateGameTask: {
    method: RequestMethod.PUT,
    path: [paths.gameTasks, params.gameTaskId],
  },
  createGameTaskStep: {
    method: RequestMethod.POST,
    path: [paths.gameTasks, params.gameTaskId],
  },
  updateGameTaskStep: {
    method: RequestMethod.PUT,
    path: [paths.gameTasks,paths.steps, params.gameTaskStepId],
  },
  deleteGameTaskById: {
    method: RequestMethod.DELETE,
    path: [paths.gameTasks, params.gameTaskId],
  },
  uploadGameTaskImage: {
    method: RequestMethod.PUT,
    path: [paths.gameTasks, params.gameTaskId, paths.steps, params.gameTaskStepId, paths.uploadStepImage],
  },
}

export default GameTaskEndpoints
