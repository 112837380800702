import { AppState } from 'types/redux/types/app-state'
import { MODULE_NAME, NEW_CHANCE_REWARD } from 'modules/chests/constants'
import { IChestState } from 'modules/chests/models/state/IChestState'
import { Chest } from 'modules/chests/models/entities/chest'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { NIL as NIL_UUID, v4 as uuidv4 } from 'uuid'
import { Rarity } from 'modules/chests/enums/chest-field'

const getState = (state: AppState): IChestState => state[MODULE_NAME]

export const getChests = (state: AppState): Chest[] => getState(state).items

export const getChestsCount = (state: AppState): number => getState(state).count

export const getCache = (state: AppState): { [key: string]: Chest } =>
  getState(state).cache

export const getAutocompleteSearchChests = (state: AppState): Chest[] => {
  return Object.values(getState(state).cache)
}

export const getChest = (state: AppState, chestId: string): Chest =>
  getCache(state)[chestId]

export const getCurrentChest = (state: AppState): Chest | null => {
  const chests = getState(state)
  if (IsNullOrUndefined(chests.currentChestId)) return null
  if (chests.currentChestId === NIL_UUID) {
    return {
      id: NIL_UUID,
      description: '',
      maxRewardsInChest: 1,
      minRewardsInChest: 1,
      status: 'Active',
      chestRarity: Rarity.NOT_SET,
      chestConfig: {
        chanceRewards: [{ ...NEW_CHANCE_REWARD, id: uuidv4() }],
      },
    }
  }
  return getCache(state)[chests.currentChestId || ''] || null
}
