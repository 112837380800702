import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useRequestContext } from 'core/api/context'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { useEventsContext } from 'modules/events/context'
import React, { useCallback } from 'react'
import { EventFieldName } from 'types/modules/events/enums/event-field'
import _ from 'core/utils/deepdash'
import { SortDirection } from 'types/common/enums/sort-direction'
import { EventStatus } from 'types/modules/events/enums/event-status'
import { IContentDashboardProps } from 'components/content-dashboard/props/content-dashboard-props'
import { IEvent } from 'types/modules/events/models/entities/event'
import { DashboardContextProvider } from 'components/content-dashboard/context'
import ContentDashboard from 'components/content-dashboard'
import { EventsListToolbar } from 'modules/events/views/components/events-dashboard/events-list-toolbar'
import { EventsListItem } from 'modules/events/views/components/events-dashboard/events-list-item'
import { EventsCalendarDateGridCell } from 'modules/events/views/components/events-dashboard/events-calendar-date-grid-cell'
import { EventsCalendarTimeGridCell } from 'modules/events/views/components/events-dashboard/events-calendar-time-grid-cell'
import { EventsListHeaderPanel } from 'modules/events/views/components/events-dashboard/events-list-header-panel'
import { EventDetailsModal } from 'modules/events/views/components/event-details-panel/modal'
import { SelectFilterMode } from 'types/common/filtering/enums/SelectFilterMode'
import { IFilter } from 'types/common/filtering/models/entities/IFilter'

const useStyles = makeStyles((theme: Theme) => createStyles({}))

export const EventsDashboard: React.FC<{}> = () => {
  const classes = useStyles()

  const { currentEvent, events, statusFilterConfig, initialiseDashboard } =
    useEventsContext()

  const rc = useRequestContext()

  const statusFilter = rc?.getFilterByField(EventFieldName.STATUS)

  const onViewModeChange = useCallback(
    (viewMode: string) => {
      if (IsNullOrUndefined(statusFilter)) return

      const newSortValue = _.clone(rc?.sortValue)
      const newStatusFilter = _.clone(statusFilter)

      switch (viewMode) {
        case 'list': {
          newSortValue!.sortBy = EventFieldName.START
          newSortValue!.sortDirection = SortDirection.Descending

          newStatusFilter!.value = [EventStatus.Active]

          rc?.setSortValue(newSortValue!)
          rc?.applyFilter(newStatusFilter!)
          break
        }

        case 'calendar': {
          newSortValue!.sortBy = EventFieldName.START
          newSortValue!.sortDirection = SortDirection.Ascending

          newStatusFilter!.value = [
            EventStatus.Active,
            EventStatus.Scheduled,
            EventStatus.Pending,
          ]

          rc?.setSortValue(newSortValue!)
          rc?.applyFilter(newStatusFilter!)

          break
        }

        default:
          return
      }
    },
    [statusFilter],
  )

  const onTabChange = useCallback(
    (value: IFilter['value'], mode: SelectFilterMode) => {
      const valueIsArray = Array.isArray(value)
      const valueAsArray = value as EventStatus[]

      if (IsNullOrUndefined(statusFilter) || !valueIsArray) return

      if (mode === SelectFilterMode.Multiple) {
        rc?.setActive(true)
        return
      }

      const status = valueAsArray[0]

      switch (status) {
        case EventStatus.Active:
        case EventStatus.Scheduled: {
          rc?.setSortValue({
            sortBy: EventFieldName.START,
            sortDirection: SortDirection.Descending,
          })
          return
        }

        default: {
          rc?.setSortValue({
            sortBy: EventFieldName.UPDATED_AT,
            sortDirection: SortDirection.Descending,
          })
          return
        }
      }
    },
    [statusFilter],
  )

  const dashboardProps: IContentDashboardProps<IEvent> = {
    onDashboardMount: initialiseDashboard,
    onViewModeChange,
    onTabChange,
    tabFilterConfig: statusFilterConfig,
    listOptions: {
      headerPanelComponent: <EventsListHeaderPanel />,
      listItemComponent: EventsListItem,
      toolbarComponent: <EventsListToolbar />,
    },
    calendarOptions: {
      toolbarComponent: <EventsListToolbar />,
      timeGridCellComponent: EventsCalendarTimeGridCell,
      dateGridCellComponent: EventsCalendarDateGridCell,
    },
    data: events,
  }

  return (
    <React.Fragment>
      <DashboardContextProvider>
        <ContentDashboard {...dashboardProps} />
      </DashboardContextProvider>
      {!IsNullOrUndefined(currentEvent) && (
        <EventDetailsModal currentEvent={currentEvent} open={true} />
      )}
    </React.Fragment>
  )
}
