import React, { PropsWithChildren } from 'react'
import { Box, Divider, GridProps, Paper, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import PanelContainer from 'components/panel/panel-container'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    panelFooter: {
      display: 'flex',
      width: '100%',
      padding: '1.25rem',
      justifySelf: 'flex-end',
    },
  }),
)

const PanelFooter: React.FC<PropsWithChildren<{}>> = (
  props: PropsWithChildren<{}>,
) => {
  const classes = useStyles()

  return (
    <React.Fragment>
      <Divider />
      <Box className={classes.panelFooter}>{props.children ?? null}</Box>
      <Divider />
    </React.Fragment>
  )
}

export default PanelFooter
