import { Box, Grid, InputAdornment, MenuItem, Select, SelectChangeEvent, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useRequestContext } from 'core/api/context'
import { CheckboxFilter } from 'core/filtering/components/checkbox-filter'
import { SelectFilter } from 'core/filtering/components/select-filter'
import { useTypedSelector } from 'core/redux/utils'
import { titleCase } from 'core/utils/titleCase'
import { isEmpty } from 'lodash-es'
import { getTypeLabel } from 'modules/posts/utils/get-type-label'
import { getUsers } from 'modules/users/portal/selectors'
import React from 'react'
import { FilterOperator } from 'types/common/filtering/enums/FilterOperator'
import { FilterType } from 'types/common/filtering/enums/FilterType'
import { SelectFilterMode } from 'types/common/filtering/enums/SelectFilterMode'
import { ISelectOption } from 'types/common/filtering/models/entities/ISelectOption'
import { PostField } from 'types/modules/posts/enums/post-field'
import PostType from 'types/modules/posts/enums/post-type'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'

enum PublicType {
  PUBLIC = 'Public',
  NOT_PUBLC =  'Not public'
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filterWrapper: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    customPostFilters: {
      display: 'flex',
      marginLeft: 8
    },
    postTypeFilter: {
      display: 'flex',
      flexDirection: 'column',
      flexShrink: 1,
    },
    writerFilter: {
      display: 'flex',
      flexDirection: 'column',
    },
    createdByFilter: {
      display: 'flex',
      minWidth: 225,
      height: '2.5rem',
      overflow: 'hidden',
    },
    toolbarSortInputText: {
      textOverflow: 'clip',
    },
  }),
)

const getPublicFilterValue = (val: any): string => {
  if (val === '' || IsNullOrUndefined(val)) return ''
  return val ? PublicType.PUBLIC : PublicType.NOT_PUBLC
}

export const CustomPostFilters: React.FC<{}> = () => {
  const classes = useStyles()

  const rc = useRequestContext()

  const createdByFilter = rc?.getFilterByField(PostField.CREATED_BY)
  const publicFilter = rc?.getFilterByField(PostField.PUBLIC)

  const portalUsers = useTypedSelector((state) => getUsers(state))

  const postTypeFilterOptions: ISelectOption[] = Object.values(PostType).map(
    (postType) => {
      return {
        label: getTypeLabel(postType),
        value: [postType],
      }
    },
  )

  const handleCreatedByChange = (
    event: SelectChangeEvent<string>,
  ): void => {
    rc?.applyFilter({
      field: PostField.CREATED_BY,
      type: FilterType.Select,
      operator: FilterOperator.Equals,
      value: event.target.value as string,
    })
  }

  const handleViewTypeChange = (
    event: SelectChangeEvent<string>,
  ): void => {
    rc?.applyFilter({
      field: PostField.PUBLIC,
      type: FilterType.Select,
      operator: FilterOperator.Equals,
      value: event.target.value ? event.target.value === PublicType.PUBLIC : '',
    })
  }


  return (
    <Box className={classes.customPostFilters}>
      <Box className={classes.filterWrapper}>
        <Box>
          <Select
            value={`${createdByFilter?.value || ''}`}
            onChange={handleCreatedByChange}
            className={classes.createdByFilter}
            inputProps={{
              'aria-label': 'Without label',
              className: classes.toolbarSortInputText,
            }}
            startAdornment={
              <InputAdornment position="start">Created by: </InputAdornment>
            }
          >
            <MenuItem key={`created-by-null`} value={''}>
              {'None'}
            </MenuItem>
            {portalUsers.map((user, index) => (
              <MenuItem key={`${user.username}-${index}`} value={user.username}>
                {titleCase(user.username)}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box className={classes.writerFilter}>
          <Select
            variant={'outlined'}
            value={getPublicFilterValue(publicFilter?.value)}
            onChange={handleViewTypeChange}
            className={classes.createdByFilter}
            inputProps={{
              'aria-label': 'Without label',
              className: classes.toolbarSortInputText,
            }}
            startAdornment={
              <InputAdornment position="start">Public type</InputAdornment>
            }
          >
            <MenuItem key={`created-by-null`} value={''}>
              {'None'}
            </MenuItem>
            {Object.values(PublicType).map((viewType) => (
              <MenuItem key={viewType} value={viewType}>
                {titleCase(viewType)}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box className={classes.postTypeFilter}>
          <Grid container spacing={1}>
            <SelectFilter
              field={PostField.TYPE}
              mode={SelectFilterMode.Multiple}
              options={postTypeFilterOptions}
              filterOptionComponent={CheckboxFilter}
              optionWrapperProps={{ xs: 6, item: true }}
              OptionWrapperTag={Grid}
            />
          </Grid>
        </Box>
      </Box>
    </Box>
  )
}
