import { AppState } from 'types/redux/types/app-state'
import {
  EVERGREEN_QUEST_MODULE_NAME,
  GAME_TASK_MODULE_NAME,
  MODULE_NAME,
  QUEST__GROUP_MODULE_NAME,
} from 'modules/quests/constants'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { NIL as NIL_UUID } from 'uuid'
import { IQuest } from 'modules/quests/models/quest'
import { QuestField } from 'modules/quests/enums/quest-field'
import {
  GameTaskState,
  IEvergreenQuestState,
  IQuestGroupState,
  IQuestState,
} from 'modules/quests/models/state'
import { IQuestGroup } from 'modules/quests/models/quest-group'
import { QuestGroupField } from 'modules/quests/enums/quest-group-field'
import { IEvergreenQuest } from 'modules/quests/models/evergreen-quest'
import { GameTask, GameTaskSchedule, GameTaskStep, GameTaskType } from 'fe-shared-resources'

const getState = (state: AppState): IQuestState => state[MODULE_NAME]
const getQuestGroupsState = (state: AppState): IQuestGroupState =>
  state[QUEST__GROUP_MODULE_NAME]
const getEvergreenQuestState = (state: AppState): IEvergreenQuestState =>
  state[EVERGREEN_QUEST_MODULE_NAME]
const getGameTaskState = (state: AppState): GameTaskState =>
  state[GAME_TASK_MODULE_NAME]

export const getCurrentQuest = (state: AppState): IQuest | null => {
  const { currentQuestId, cache } = getState(state)
  if (IsNullOrUndefined(currentQuestId)) return null
  if (currentQuestId === NIL_UUID) {
    return {
      [QuestField.ID]: NIL_UUID,
      [QuestField.QUEST_TEXT]: '',
      [QuestField.SET]: 1,
      [QuestField.SCHEDULE]: '',
      [QuestField.QUEST_REWARDS]: [],
      [QuestField.QUEST_TASKS]: [],
    }
  }
  const currentQuest = cache[currentQuestId!]
  return currentQuest ?? null
}

export const getQuests = (state: AppState): IQuest[] => getState(state).items

export const getQuestsCount = (state: AppState): number => getState(state).count

export const getCurrentQuestGroup = (state: AppState): IQuestGroup | null => {
  const { currentQuestGroupId, cache } = getQuestGroupsState(state)
  if (IsNullOrUndefined(currentQuestGroupId)) return null
  if (currentQuestGroupId === NIL_UUID) {
    return {
      [QuestGroupField.ID]: NIL_UUID,
      [QuestGroupField.NAME]: '',
      [QuestGroupField.ACTIVE]: true,
      [QuestGroupField.TEST]: '',
      [QuestGroupField.VARIANT]: '',
      [QuestGroupField.QUEST_GROUP_QUESTS]: [],
    }
  }
  const currentQuest = cache[currentQuestGroupId!]
  return currentQuest ?? null
}

export const getQuestGroups = (state: AppState): IQuestGroup[] =>
  getQuestGroupsState(state).items

export const getQuestGroupsCount = (state: AppState): number =>
  getQuestGroupsState(state).count

export const getQuestGetter = (
  state: AppState,
): ((questId: string) => IQuest) => {
  return (questId: string): IQuest => getState(state).cache[questId]
}

export const getCurrentEvergreenQuest = (
  state: AppState,
): IEvergreenQuest | null => {
  const { currentEvergreenQuestId, cache } = getEvergreenQuestState(state)
  if (IsNullOrUndefined(currentEvergreenQuestId)) return null
  if (currentEvergreenQuestId === NIL_UUID) {
    return {
      id: NIL_UUID,
      name: '',
      description: '',
      taskParameters: {},
      taskCode: '',
    }
  }
  const currentEvergreenQuest = cache[currentEvergreenQuestId!]
  return currentEvergreenQuest ?? null
}

export const getEvergreenQuests = (state: AppState): IEvergreenQuest[] =>
  getEvergreenQuestState(state).items

export const getEvergreenQuestsCount = (state: AppState): number =>
  getEvergreenQuestState(state).count



export const getCurrentGameTask = (
  state: AppState,
): GameTask | null => {
  const { currentGameTaskId, cache } = getGameTaskState(state)
  if (IsNullOrUndefined(currentGameTaskId)) return null
  if (currentGameTaskId === NIL_UUID) {
    return {
      id: NIL_UUID,
      name: '',
      description: '',
      isVisible: true,
      isEnabled: false,
      maxLevel: 0,
      schedule: GameTaskSchedule.NotSet,
      type: GameTaskType.NotSet
    }
  }
  const currentGameTask = cache[currentGameTaskId!]
  return currentGameTask ?? null
}

export const getGameTasks = (state: AppState): GameTask[] =>
  getGameTaskState(state).items

export const getGameTasksCount = (state: AppState): number =>
  getGameTaskState(state).count

export const getGameTaskSteps = (state: AppState): GameTaskStep[] =>
  getGameTaskState(state).currentGameTaskSteps
