import { MediaUploadModel } from 'types/common/images/models/entities/image-upload-model'
import { useRequestContext } from 'core/api/context'
import { useTypedSelector } from 'core/redux/utils'
import { useRouter } from 'core/routing/hooks/use-router'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { mapMimeType } from 'core/utils/mime-mapper'
import { Actions, Selectors } from 'modules/events'
import { IEventActionParams } from 'modules/events/actions'
import { getStatusFilterOptions } from 'modules/events/utils/get-status-filter-options'
import moment from 'moment-timezone'
import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
} from 'react'
import { useDispatch } from 'react-redux'
import { IAsyncActionParams } from 'types/common/api/models/entities/async-action-params'
import SortDirection from 'types/common/enums/sort-direction'
import { FilterOperator } from 'types/common/filtering/enums/FilterOperator'
import { FilterType } from 'types/common/filtering/enums/FilterType'
import { ISelectFilterConfig } from 'types/common/filtering/models/entities/ISelectFilterConfig'
import { EventFieldName } from 'types/modules/events/enums/event-field'
import { EventStatus } from 'types/modules/events/enums/event-status'
import { IEvent } from 'types/modules/events/models/entities/event'
import { IEventDraft } from 'types/modules/events/models/entities/event-draft'
import { IEventsState } from 'types/modules/events/models/state/IEventsState'
import { getCachedTagGroupById } from 'modules/tags/selectors'
import { getTagGroupById } from 'modules/tags/actions'
import { usePageSizeMonitor } from 'modules/common/usePageSizeMonitor'
import { Currency } from 'fe-shared-resources/lib/currency'

export interface IEventsContext {
  events: IEventsState
  statusFilterConfig: ISelectFilterConfig
  currentEvent: IEvent | null
  initialiseDashboard: () => void
  setCurrentEvent: (eventId: string) => void
  clearCurrentEvent: () => void
  loadEvents: (params: IAsyncActionParams<IEvent>) => void
  createEvent: (event?: IEventDraft) => void
  updateEvent: (event: Partial<IEvent>) => void
  submitEvent: (eventId: string) => void
  launchEvent: (eventId: string) => void
  scheduleEvent: (eventId: string) => void
  closeEvent: (eventId: string) => void
  promoteEvent: (eventId: string) => void
  uploadEventImage: (eventId: string, fileParams: MediaUploadModel) => void
  uploadEventPassImage: (eventId: string, fileParams: MediaUploadModel) => void
  uploadDefaultLiveScheduleImage: (
    eventId: string,
    fileParams: MediaUploadModel,
  ) => void
}

export const EventsContext = React.createContext<IEventsContext>(
  {} as IEventsContext,
)

export const EventsContextProvider: React.FC<React.ReactNode> = (
  props: PropsWithChildren<React.ReactNode>,
) => {
  const rc = useRequestContext()

  const statusFilter = rc?.getFilterByField(EventFieldName.STATUS)

  const pageSize = usePageSizeMonitor({ fetchItems: Actions.getEvents })

  const { routeParams, pushQueryParams, removeQueryParams } = useRouter()

  const { eventId } = routeParams

  const events = useTypedSelector((state) =>
    Selectors.getEventsByStatus(state, statusFilter?.value as EventStatus[]),
  )

  const currentEvent = useTypedSelector((state) =>
    Selectors.getCurrentEvent(state),
  )

  const setCurrentEvent = (id: string): void => {
    pushQueryParams({
      eventId: id,
    })
  }

  const clearCurrentEvent = (): void => {
    removeQueryParams()
  }

  const statusFilterConfig = {
    field: 'status',
    options: getStatusFilterOptions(events.statuses),
  }

  const selectedTagGroup = useTypedSelector((state) =>
    getCachedTagGroupById(state, currentEvent?.tagGroupId),
  )

  // Actions
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(Actions.setCurrentEventId({ eventId }))

    if (!IsNullOrUndefined(eventId)) {
      dispatch(Actions.getEventById({ eventId }))
    }
  }, [eventId])

  const requiredTagGroupNotLoaded = !selectedTagGroup && currentEvent?.tagGroupId

  useEffect(() => {
    if (requiredTagGroupNotLoaded) {
      dispatch(getTagGroupById({ tagGroupId: currentEvent?.tagGroupId }))
    }

  }, [requiredTagGroupNotLoaded])

  // Initialise Request Context
  const initialiseDashboard = (): void => {
    dispatch(Actions.getEventStatuses())
    rc?.applyFilter({
      field: statusFilterConfig.field,
      type: FilterType.Select,
      operator: FilterOperator.Equals,
      value: [EventStatus.Active],
    })
    rc?.setSortValue({
      sortBy: 'start',
      sortDirection: SortDirection.Descending,
    })
  }

  const loadEvents = useCallback((): void => {
    dispatch(
      Actions.getEvents({
        filters: rc?.filters,
        page: rc?.page,
        pageSize,
        ...rc?.sortValue,
      } as IEventActionParams),
    )
  }, [rc?.active, rc?.filters, rc?.page, pageSize, rc?.sortValue])

  useEffect(() => {
    dispatch(Actions.clearEvents())
  }, [rc?.filters, rc?.sortValue])

  useEffect(() => {
    if (rc?.active) {
      loadEvents()
    }
  }, [rc?.active, rc?.filters, rc?.page, rc?.sortValue])

  const createEvent = (event?: IEventDraft): void => {
    const newEvent: IEventDraft = {
      name: '',
      currency: Currency.Coin,
      amount: 0,
      start: moment().toISOString(),
      media: {
        description: '',
        icon: '',
      },
      locale: '',
      ...(event ?? {}),
    }

    new Promise<IEvent>((resolve, reject) => {
      dispatch(
        Actions.createEvent({
          item: newEvent,
          promise: {
            onResolve: resolve,
            onReject: reject,
          },
        }),
      )
    }).then((event: IEvent) => {
      setCurrentEvent(event.id)
    })
  }

  const updateEvent = (event: Partial<IEvent>): void => {
    dispatch(
      Actions.updateEvent({
        eventId: event.id,
        item: event,
      }),
    )
  }

  const uploadEventImage = (
    eventId: string,
    fileParams: MediaUploadModel,
  ): void => {
    dispatch(
      Actions.uploadEventImage({
        eventId,
        item: fileParams.binaryData,
        extension: `image.${fileParams.fileExtension}`,
        overrideHeaders: {
          'content-type': mapMimeType(fileParams.fileExtension),
          accept: '*/*',
        },
      }),
    )
  }

  const uploadEventPassImage = (
    eventId: string,
    fileParams: MediaUploadModel,
  ): void => {
    dispatch(
      Actions.uploadEventPassImage({
        eventId,
        item: fileParams.binaryData,
        extension: `image.${fileParams.fileExtension}`,
        overrideHeaders: {
          'content-type': mapMimeType(fileParams.fileExtension),
          accept: '*/*',
        },
      }),
    )
  }

  const uploadDefaultLiveScheduleImage = (
    eventId: string,
    fileParams: MediaUploadModel,
  ): void => {
    dispatch(
      Actions.uploadDefaultLiveScheduleImage({
        eventId,
        item: fileParams.binaryData,
        extension: `image.${fileParams.fileExtension}`,
        overrideHeaders: {
          'content-type': mapMimeType(fileParams.fileExtension),
          accept: '*/*',
        },
      }),
    )
  }

  const updateEventStatus = (
    eventId: string,
    eventStatus: EventStatus,
  ): void => {
    new Promise((resolve, reject) => {
      dispatch(
        Actions.updateEventStatus({
          eventId,
          eventStatus,
          promise: {
            onResolve: resolve,
            onReject: reject,
          },
        }),
      )
    }).then(() => {
      dispatch(Actions.getEventStatuses())
    })
  }

  const submitEvent = (eventId: string): void => {
    updateEventStatus(eventId, EventStatus.Pending)
  }

  const scheduleEvent = (eventId: string): void => {
    updateEventStatus(eventId, EventStatus.Scheduled)
  }

  const launchEvent = (eventId: string): void => {
    updateEventStatus(eventId, EventStatus.Active)
  }

  const closeEvent = (eventId: string): void => {
    updateEventStatus(eventId, EventStatus.Closed)
  }

  const promoteEvent = (eventId: string): void => {
    dispatch(Actions.promoteEvent({ eventId }))
  }

  const context: IEventsContext = {
    events,
    statusFilterConfig,
    currentEvent,
    initialiseDashboard,
    setCurrentEvent,
    clearCurrentEvent,
    loadEvents,
    createEvent,
    updateEvent,
    submitEvent,
    launchEvent,
    scheduleEvent,
    closeEvent,
    uploadEventImage,
    uploadEventPassImage,
    uploadDefaultLiveScheduleImage,
    promoteEvent,
  }

  return (
    <EventsContext.Provider value={context}>
      {props.children}
    </EventsContext.Provider>
  )
}

export const useEventsContext = (): IEventsContext => useContext(EventsContext)
