import { Box, SvgIcon, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { CoinIcon, ImageIcon, StarIcon } from 'components/icons'
import {
  IsNullOrUndefined,
  IsNullUndefinedOrEmpty,
} from 'core/utils/isNullOrUndefined'
import { ordinalSuffixOf } from 'core/utils/ordinal-suffix-of'
import { getLeaderboardPositionLabel } from 'modules/leaderboards/utils/get-leaderboard-position-label'
import PositionRewardActionButtons from 'modules/leaderboards/views/components/position-reward-action-buttons'
import React from 'react'
import ILeaderboardPositionReward from 'types/modules/leaderboards/models/entities/leaderboard-position-reward'
import { LeaderboardPositionType } from 'types/modules/leaderboards/models/entities/leaderboard-position-type'
import { IPrize } from 'types/modules/prizes/models/entities/prize'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    positionRewardListCard: {
      display: 'flex',
      width: '100%',
      marginBottom: '0.625rem',
      padding: '0.625rem',
      flexDirection: 'row',
      boxShadow: 'none',
      border: '1px solid rgba(0,0,0,0.12)',
      borderRadius: 4,
    },
    positionContainer: {
      display: 'flex',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    rewardsContainer: {
      display: 'flex',
      flexShrink: 0,
      height: '100%',
      marginLeft: 'auto',
      justifyContent: 'flex-end',
      marginRight: 275,
    },
    rewardIcon: {
      width: 25,
      height: 25,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    rewardCount: {},
    rewardBox: {
      borderRadius: 12,
      border: '1px solid rgba(0,0,0,0.12)',
      width: 80,
      height: 80,
      padding: '0.625rem',
      marginLeft: '0.625rem',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      display: 'flex',
    },
    prizeBox: {
      borderRadius: 12,
      border: '1px solid rgba(0,0,0,0.12)',
      height: 80,
      padding: '0.625rem',
      marginLeft: '0.625rem',
      display: 'flex',
    },
    prizeImage: {
      width: 58,
      height: 58,
      border: '1px solid rgba(0,0,0,0.12)',
      display: 'flex',
      marginRight: '0.625rem',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      borderRadius: 4,
    },
    prizeDetails: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
    },
    actionsAndMetadata: {
      marginLeft: '1.25rem',
      display: 'flex',
      flexShrink: 1,
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    actions: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      '& .MuiButton-root': {
        marginRight: '0.625rem',
        '&:last-of-type': {
          marginRight: 0,
        },
      },
    },
  }),
)

export const PositionRewardListItem: React.FC<ILeaderboardPositionReward> = (
  positionReward: ILeaderboardPositionReward,
) => {
  const classes = useStyles()

  const renderPrizes = (prizes: IPrize[]): JSX.Element | null => {
    return !IsNullOrUndefined(prizes) && prizes.length > 0 ? (
      <React.Fragment>
        {prizes.map((prize) => {
          const prizeHasImage = !IsNullUndefinedOrEmpty(
            prize.item?.details?.imageUrl,
          )
          const prizeImageStyles: { [key: string]: string | number } = {}

          if (prizeHasImage) {
            prizeImageStyles.backgroundImage = `url(${prize.item?.details!
              .imageUrl!})`
          } else {
            prizeImageStyles.background = 'rgba(0,0,0,0.03)'
          }

          return (
            <Box key={`prize-${prize.id}`} className={classes.prizeBox}>
              <Box className={classes.prizeImage} style={prizeImageStyles}>
                {!prizeHasImage && (
                  <SvgIcon>
                    <ImageIcon />
                  </SvgIcon>
                )}
              </Box>
              <Box className={classes.prizeDetails}>
                <Typography
                  variant={'body1'}
                  style={{
                    fontSize: 14,
                    textTransform: 'uppercase',
                    color: 'black',
                    marginBottom: '-5px',
                    fontWeight: 600,
                  }}
                >
                  {prize.item?.name ?? ''}
                </Typography>
                <Typography
                  variant={'body1'}
                  style={{
                    fontSize: 10,
                    textTransform: 'uppercase',
                    color: 'black',
                    marginBottom: '-3px',
                    fontWeight: 600,
                  }}
                >
                  {prize.item?.details?.description ?? ''}
                </Typography>
                <Typography
                  variant={'body1'}
                  style={{
                    fontSize: 8,
                    textTransform: 'uppercase',
                    color: 'rgba(0,0,0,0.54)',
                    fontWeight: 600,
                  }}
                >
                  {prize.item?.details?.brand ?? ''}
                </Typography>
              </Box>
            </Box>
          )
        })}
      </React.Fragment>
    ) : null
  }

  const renderGoldBoxes = (goldBoxes: number): JSX.Element | null => {
    return !IsNullOrUndefined(goldBoxes) && goldBoxes > 0 ? (
      <Box className={classes.rewardBox}>
        <Box className={classes.rewardIcon}>
          <Typography
            variant="h6"
            color="textPrimary"
            component="span"
            style={{
              fontSize: 24,
              lineHeight: '1',
              top: 4,
              position: 'relative',
            }}
          >
            🎁
          </Typography>
        </Box>
        <Typography
          className={classes.rewardCount}
          variant="subtitle2"
          color="textPrimary"
          component="span"
        >
          {goldBoxes}
        </Typography>
      </Box>
    ) : null
  }

  const renderBrownBoxes = (brownBoxes: number): JSX.Element | null => {
    return !IsNullOrUndefined(brownBoxes) && brownBoxes > 0 ? (
      <Box className={classes.rewardBox}>
        <Box className={classes.rewardIcon}>
          <Typography
            variant="h6"
            color="textPrimary"
            component="span"
            style={{
              fontSize: 24,
              lineHeight: '1',
              top: 4,
              position: 'relative',
            }}
          >
            📦
          </Typography>
        </Box>
        <Typography
          className={classes.rewardCount}
          variant="subtitle2"
          color="textPrimary"
          component="span"
        >
          {brownBoxes}
        </Typography>
      </Box>
    ) : null
  }

  const renderStars = (stars: number): JSX.Element | null => {
    return !IsNullOrUndefined(stars) && stars > 0 ? (
      <Box className={classes.rewardBox}>
        <Box className={classes.rewardIcon}>
          <StarIcon />
        </Box>
        <Typography
          className={classes.rewardCount}
          variant="subtitle2"
          color="textPrimary"
          component="span"
        >
          {stars}
        </Typography>
      </Box>
    ) : null
  }

  const renderCoins = (coins: number): JSX.Element | null => {
    return !IsNullOrUndefined(coins) && coins > 0 ? (
      <Box className={classes.rewardBox}>
        <Box className={classes.rewardIcon}>
          <CoinIcon />
        </Box>
        <Typography
          className={classes.rewardCount}
          variant="subtitle2"
          color="textPrimary"
          component="span"
        >
          {coins}
        </Typography>
      </Box>
    ) : null
  }

  const renderRewards = (): JSX.Element => {
    const { goldBox, brownBox, coins, stars, prizes } = positionReward

    return (
      <React.Fragment>
        {renderPrizes(prizes as IPrize[])}
        {renderGoldBoxes(goldBox)}
        {renderBrownBoxes(brownBox)}
        {renderStars(stars)}
        {renderCoins(coins)}
      </React.Fragment>
    )
  }

  const { start, end, type } = positionReward

  return (
    <Box className={classes.positionRewardListCard}>
      <Box className={classes.positionContainer}>
        <Typography variant="h6" color="textPrimary" component="span">
          {getLeaderboardPositionLabel(start, end, type)}
        </Typography>
      </Box>
      <Box className={classes.rewardsContainer}>{renderRewards()}</Box>
      <Box className={classes.actionsAndMetadata}>
        <Box className={classes.actions}>
          <PositionRewardActionButtons positionReward={positionReward} />
        </Box>
      </Box>
    </Box>
  )
}
