import { ActionTypes } from 'modules/mobile-app-users/action-types'
import { Reducer } from 'redux'
import { actionTypeSuccess, cacheItem, cacheItems } from 'core/redux/utils'
import { ISimpleAction, ISuccessAction } from 'types/redux/interfaces/IAction'
import _ from 'core/utils/deepdash'
import { mergeArrays } from 'core/utils/mergeArrays'
import { IMobileAppUserState } from 'modules/mobile-app-users/models/state/IMobileAppUserState'
import {
  GetMobileUsersApiResponse,
  GetTicketsApiResponse,
} from 'modules/mobile-app-users/models/responses'
import {
  IAccountCurrencyUpdateRequest,
  IMobileAppUserActionParams,
} from 'modules/mobile-app-users/actions'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { MobileAppUser } from 'modules/mobile-app-users/models/entities/mobile-app-user'
import IPaginatedItems from 'types/common/pagination/models/entities/paginated-items'
import {
  currencyFieldNameMap,
  PAGE_SIZE,
  TICKETS_PAGE_SIZE,
} from 'modules/mobile-app-users/constants'
import { TicketStatus } from 'modules/mobile-app-users/enums/ticket-status'

const initialState: IMobileAppUserState = {
  count: 0,
  page: 0,
  pageSize: PAGE_SIZE,
  items: [],
  cache: {},
  topUps: {},
  tickets: {
    pageSize: TICKETS_PAGE_SIZE,
    count: 0,
    page: 0,
    items: [],
    cache: {},
  },
}

type AllowedActionTypes = ISuccessAction | ISimpleAction

const mobileAppUsersReducer: Reducer<
  IMobileAppUserState,
  AllowedActionTypes
> = (state = initialState, action: AllowedActionTypes) => {
  const simpleAction = action as ISimpleAction
  const successAction = action as ISuccessAction

  switch (action.type) {
    case ActionTypes.SET_CURRENT_MOBILE_APP_USER_ID: {
      const newState = _.cloneDeep(state)
      const { mobileAppUserId } =
        simpleAction.payload as IMobileAppUserActionParams
      if (IsNullOrUndefined(mobileAppUserId)) {
        delete newState.currentMobileAppUserId
        newState.tickets.count = 0
        newState.tickets.page = 0
        newState.tickets.items = []
      } else {
        newState.currentMobileAppUserId = mobileAppUserId
      }
      return newState
    }

    case ActionTypes.SET_MOBILE_APP_USERS_AS_EMPTY: {
      const newState = _.cloneDeep(state)
      newState.items = []
      newState.page = 0
      newState.count = 0
      return newState
    }

    case actionTypeSuccess(ActionTypes.GET_MOBILE_APP_USER): {
      const newState = _.cloneDeep(state)
      const fetchedmobileAppUser = successAction.data as MobileAppUser

      // I have taken the accounts that are in the accounts array
      // but not on the user object and put them on the user object.
      // This is a hack, but fuck the stupid portal, it would take forever
      // to do it the propper way. At somepoint we will create a ticket
      // to migrate everything to the array, but not today.
      //
      // TODO - Make the portal less shit
      const mobileAppUser = fetchedmobileAppUser.accounts.reduce(
        (acc, account) => ({
          ...acc,
          [`${_.camelCase(account.currency)}Account`]: account,
        }),
        fetchedmobileAppUser,
      )
      // ------ end of hack --------
      const index = state.items.findIndex(
        (item) => item.id === mobileAppUser.id,
      )

      if (index < 0) {
        newState.items = [...newState.items, mobileAppUser]
      } else {
        newState.items[index] = mobileAppUser
      }
      newState.cache = cacheItem(state.cache, mobileAppUser)
      return newState
    }

    case actionTypeSuccess(ActionTypes.GET_MOBILE_APP_USERS): {
      let newState = _.cloneDeep(state)
      const response = successAction.data as GetMobileUsersApiResponse
      const page = response?.page
      if (page === 1) {
        newState = {
          ...state,
          ...response,
        }
      } else {
        newState.items = mergeArrays(newState.items!, response.items!)
      }
      newState.cache = cacheItems(state.cache, response.items)
      return newState
    }

    case actionTypeSuccess(
      ActionTypes.GET_AUTOCOMPLETE_SEARCH_MOBILE_APP_USERS,
    ): {
      const newState = _.cloneDeep(state)

      const response = successAction.data as IPaginatedItems<MobileAppUser>

      newState.cache = cacheItems(state.cache, response.items)

      return newState
    }

    case actionTypeSuccess(ActionTypes.UPDATE_MOBILE_APP_USER):
    case actionTypeSuccess(ActionTypes.DISABLE_MOBILE_APP_USER): {
      const newState = _.cloneDeep(state)
      const response = successAction.data as MobileAppUser
      const index = state.items.findIndex(
        (mobileAppUser) => mobileAppUser.id === response.id,
      )

      if (index >= 0) {
        newState.items[index] = response
      }

      newState.cache[response.id] = response

      return newState
    }

    case actionTypeSuccess(ActionTypes.UPDATE_MOBILE_APP_USER_CURRENCY): {
      const newState = _.cloneDeep(state)
      const { item, mobileAppUserId } =
        successAction.payload as IMobileAppUserActionParams
      const { amount, currency } = item as IAccountCurrencyUpdateRequest
      if (!mobileAppUserId || !currency) return newState
      if (newState.cache[mobileAppUserId]) {
        const key = currencyFieldNameMap[currency]

        newState.cache = {
          ...newState.cache,
          [mobileAppUserId]: {
            ...newState.cache[mobileAppUserId],
            [key]: {
              // @ts-expect-error will write problem later
              ...newState.cache[mobileAppUserId][key],
              balance:
                (amount || 0) +
                // @ts-expect-error will write problem later
                (newState.cache[mobileAppUserId][key]?.balance || 0),
            },
          },
        }
      }
      return newState
    }

    case actionTypeSuccess(ActionTypes.VOID_TICKET): {
      const newState = _.cloneDeep(state)
      const { ticketId } = successAction.payload as IMobileAppUserActionParams
      const index = state.tickets.items.findIndex(
        (ticket) => ticket.id === ticketId,
      )

      if (!ticketId || !newState.tickets.cache[ticketId] || index === -1)
        return newState

      newState.tickets.items[index].status = TicketStatus.VOID
      newState.tickets.cache = {
        ...newState.tickets.cache,
        [ticketId]: {
          ...newState.tickets.cache[ticketId],
          status: TicketStatus.VOID,
        },
      }
      return newState
    }

    case actionTypeSuccess(ActionTypes.GET_TICKETS): {
      let newState = _.cloneDeep(state)
      const response = successAction.data as GetTicketsApiResponse
      const page = response?.page
      if (page === 1) {
        newState = {
          ...state,
          tickets: {
            ...state.tickets,
            ...response,
          },
        }
      } else {
        newState.tickets.items = mergeArrays(
          newState.tickets.items!,
          response.items!,
        )
      }
      newState.tickets.cache = cacheItems(state.tickets.cache, response.items)
      return newState
    }

    default:
      return state
  }
}

export default mobileAppUsersReducer
