import { Button, ButtonProps, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx'
import { ConfirmationDialog } from 'components/confirmation-dialog'
import { CloseIcon } from 'components/icons'
import { useAuth } from 'core/authentication/hooks/use-auth'
import { PortalPermissions } from 'core/authorization/rules'
import { useInfoCardContext } from 'modules/info-cards/context'
import React from 'react'
import { InfoCardStatus } from 'types/modules/info-cards/enums/info-card-status'
import { IInfoCardActionButtonProps } from 'types/modules/info-cards/models/props/info-card-action-button-props'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.primary.contrastText,
      backgroundColor: '#ff6565',
      '&:hover': {
        backgroundColor: '#fd8f8f',
      },
    },
  }),
)

export const DeactivateInfoCardButton: React.FC<IInfoCardActionButtonProps> = (
  props: IInfoCardActionButtonProps,
) => {
  const { deactivateInfoCard } = useInfoCardContext()

  const classes = useStyles()

  const isActive = props.infoCard.status === InfoCardStatus.Active

  const buttonProps: ButtonProps = {
    variant: 'contained',
    startIcon: <CloseIcon />,
    className: clsx(classes.root),
    size: 'small',
    disabled: props.disabled,
  }

  const popoverButtonProps = {
    ...buttonProps,
    disabled: props.disabled,
  }

  const buttonLabel = 'deactivate'

  return isActive ? (
    <ConfirmationDialog
      popoverId={`${buttonLabel}-infoCard-${props.infoCard.id}`}
      buttonProps={popoverButtonProps}
      onConfirmation={(): void => deactivateInfoCard(props.infoCard.id)}
      confirmationText={`Are you sure you want to ${buttonLabel} this info card?`}
      buttonLabel={buttonLabel}
    />
  ) : null
}
