import { PostGroup } from 'fe-shared-resources'
import { IPostContextOverrides, PostContextProvider } from 'modules/posts/context/post-list'
import { PostManagerProvider } from 'modules/posts/context/post-manager'
import { PostQuestionsManagerProvider } from 'modules/posts/context/post-questions-manager'
import { PostResultsManagerProvider } from 'modules/posts/context/post-results-manager'
import { PostStatusManagerProvider } from 'modules/posts/context/post-status-manager'
import React from 'react'
import { IEvent } from 'types/modules/events/models/entities/event'

interface IProps {
  event?: IEvent
  postGroup?: PostGroup
  overrides?: IPostContextOverrides
  children: React.ReactChild
}

export const PostContext = ({ event, children, postGroup, overrides }: IProps): JSX.Element => {
  return (
    <PostContextProvider event={event} postGroup={postGroup} overrides={overrides}>
      <PostManagerProvider>
        <PostStatusManagerProvider>
          <PostQuestionsManagerProvider>
            <PostResultsManagerProvider>{children}</PostResultsManagerProvider>
          </PostQuestionsManagerProvider>
        </PostStatusManagerProvider>
      </PostManagerProvider>
    </PostContextProvider>
  )
}
