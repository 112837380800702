import React from 'react'
import { Theme, TableCell, TableRow } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { IMilestone } from 'modules/milestones/models/milestone'
import moment from 'moment-timezone'

interface ICardProps {
  item: IMilestone
  onClick: () => void
}

export const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }),
)(TableCell)

export const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.secondary.light,
      },
    },
  }),
)(TableRow)

export const Milestone: React.FC<ICardProps> = ({
  item,
  onClick,
}: ICardProps) => {
  return (
    <StyledTableRow key={item.id} onClick={onClick}>
      <StyledTableCell component="th" scope="row">
        {`${item.name || ''} / ${item.code}`}
      </StyledTableCell>
      <StyledTableCell>{item?.description}</StyledTableCell>
      <StyledTableCell>{item.start ? moment(item.start).format('D MMMM YYYY HH:mm:ss') : ''}</StyledTableCell>
      <StyledTableCell>{`${item?.rewardAmount} ${item?.rewardCurrency}`}</StyledTableCell>
    </StyledTableRow>
  )
}
