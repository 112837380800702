import OnboardingEndpoints from 'core/api/endpoints/onboardings'
import TagGroupEndpoints from 'core/api/endpoints/tag-groups'
import { createAsyncAction } from 'core/redux/utils'
import ActionTypes from 'modules/watchlist/action-types'
import { IAsyncAction } from 'types/redux/interfaces/IAction'

export const getOnboardings = (): IAsyncAction =>
  createAsyncAction({
    type: ActionTypes.GET_ONBOARDINGS,
    endpoint: OnboardingEndpoints.getOnboardingConfigs,
    payload: null,
  })

export const getTagGroups = (): IAsyncAction =>
  createAsyncAction({
    type: ActionTypes.GET_TAG_GROUPS,
    endpoint: TagGroupEndpoints.getTagGroups,
    payload: null,
  })
