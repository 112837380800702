import { Theme, Box, Typography, Divider, IconButton, SvgIcon } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { CrossIcon } from 'components/icons'
import { PanelContainer, Panel } from 'components/panel'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { getStatusLabel } from 'modules/posts/utils/get-status-label'
import { getTypeLabel } from 'modules/posts/utils/get-type-label'
import PostActionButtons from 'modules/posts/views/components/action-buttons'
import { PostDetailsScreen } from 'modules/posts/views/components/manage-post-panel/post-details-screen'
import { PostNavigation } from 'modules/posts/views/components/manage-post-panel/post-navigation'
import { QuestionScreen } from 'modules/posts/views/components/manage-post-panel/question-screen'
import { ResultScreen } from 'modules/posts/views/components/manage-post-panel/result-screen'
import { ManagePostView } from 'modules/posts/views/components/manage-post-panel/types/manage-post-view'
import { IPostNavigationConfigItem } from 'modules/posts/views/components/manage-post-panel/types/post-navigation-config-item'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import PostStatus from 'types/modules/posts/enums/post-status'
import { PostActionButtonType } from 'types/modules/posts/enums/post-action-button-type'
import { PostDynamicViewMode } from 'types/modules/posts/enums/post-dynamic-view-mode'
import { ContentNavigation } from 'modules/posts/views/components/manage-post-panel/content-navigation'
import { PostComments } from 'modules/posts/views/components/manage-post-panel/post-comments'
import { CommentsProvider } from 'modules/comments/context'
import { RequestContextProvider } from 'core/api/context'
import { usePostListContext } from 'modules/posts/context/post-list'
import { IPost } from 'types/modules/posts/models/entities/post'
import { NIL } from 'uuid'
import { useEventsContext } from 'modules/events/context'
import Utils from './utils'

enum DisplayMode {
  View = 'View',
  Edit = 'Edit',
  Create = 'Create',
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    panelHeader: {
      display: 'flex',
      width: '100%',
      flexShrink: 0,
    },
    panelTitle: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      padding: '0 0 0 1.25rem',
    },
    navigationContainer: {
      display: 'flex',
      flex: 2,
      flexGrow: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    rightHeaderSection: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      padding: '0 1.25rem 0 0',
      flexDirection: 'row-reverse',
      '& .MuiButton-contained': {
        marginRight: 20,
      },
    },
    postStatus: {
      marginRight: 20,
    },
    contentWrapper: {
      position: 'relative',
      display: 'flex',
      flexGrow: 1,
      overflow: 'hidden',
    },
    contentPanel: {
      position: 'relative',
      display: 'flex',
      flex: 1,
      padding: '1.25rem',
      overflow: 'auto',
      borderRight: '1px solid rgba(0,0,0,0.12)',
      '&:last-child': {
        border: 'none',
      },
    },
    splitFooter: {
      display: 'flex',
      width: '100%',
      justifySelf: 'flex-end',
    },
    leftFooterSection: {
      display: 'flex',
      padding: '1.25rem',
      flex: 1,
      justifyContent: 'space-between',
      borderRight: '1px solid rgba(0,0,0,0.12)',
      '&:last-child': {
        border: 'none',
      },
    },
    rightFooterSection: {
      display: 'flex',
      padding: '1.25rem',
      flex: 1,
      justifyContent: 'center',
      '& .MuiButton-contained': {
        marginLeft: '0.625rem',
        marginRight: '0.625rem',
      },
      borderRight: '1px solid rgba(0,0,0,0.12)',
      '&:last-child': {
        border: 'none',
      },
    },
  }),
)

interface IManagePostPanelProps {
  isModal?: boolean
}

export const ManagePostPanel: React.FC<IManagePostPanelProps> = (
  props: IManagePostPanelProps,
) => {
  const { currentPost, clearCurrentPost, fetching } = usePostListContext()
  const post = currentPost as IPost

  const displayMode = useRef(
    post?.id !== NIL ? DisplayMode.Edit : DisplayMode.Create,
  ).current

  const displayCommentsFirst =
    !IsNullOrUndefined(post) &&
    [PostStatus.Pending, PostStatus.Rejected].includes(post?.status!) &&
    post!.postCommentCount! > 0

  const [dynamicViewMode, setDynamicViewMode] = useState(
    displayCommentsFirst
      ? PostDynamicViewMode.Comments
      : PostDynamicViewMode.Preview,
  )

  const [currentPage, setCurrentPage] = useState(1)

  const [navigationConfig, setNavigationConfig] = useState<
    IPostNavigationConfigItem[]
  >(Utils.buildNavigationConfig(post))

  const [buttonsDisabled, setButtonsDisabled] = useState(false)

  const [mainContentPanelRef, setMainContentPanelRef] =
    useState<HTMLDivElement | null>(null)

  const [dynamicContentPanelRef, setDynamicContentPanelRef] =
    useState<HTMLDivElement | null>(null)

  const [formButtonFooterContainerRef, setFormButtonFooterContainerRef] =
    useState<HTMLDivElement | null>(null)

  useEffect(() => {
    setNavigationConfig(Utils.buildNavigationConfig(post))
  }, [post, post?.questions, post?.results])

  useEffect(() => {
    if (currentPage > navigationConfig.length) {
      setCurrentPage(navigationConfig.length)
    }
  }, [navigationConfig])

  const navigateToResultOrQuestion = useCallback(
    (itemId: string) => {
      const configIndex = navigationConfig.findIndex((config) => {
        return Object.values(config.params).includes(itemId)
      })

      if (configIndex >= 1) {
        setCurrentPage(configIndex + 1)
      }
    },
    [post, post?.questions, post?.results, navigationConfig],
  )

  const classes = useStyles()

  const renderContent = useCallback((): JSX.Element | null => {
    const pageConfig = navigationConfig[currentPage - 1]

    if (IsNullOrUndefined(pageConfig)) return null

    const postDetailsScreenProps = {
      mainContentPanelRef,
      dynamicContentPanelRef,
      formButtonFooterContainerRef,
      disableButtons: setButtonsDisabled,
      renderPreview: dynamicViewMode === PostDynamicViewMode.Preview,
    }

    switch (pageConfig.view) {
      case ManagePostView.Details: {
        return <PostDetailsScreen {...postDetailsScreenProps} />
      }
      case ManagePostView.Result: {
        const result = post!.results.find((x) => {
          return pageConfig.params.resultId! === x.id
        })

        return (
          <ResultScreen
            key={`result-view-${result!.id}`}
            result={result!}
            {...postDetailsScreenProps}
          />
        )
      }
      case ManagePostView.Question: {
        return (
          <QuestionScreen
            key={`question-view-${pageConfig.params.questionId}`}
            questionId={pageConfig.params.questionId!}
            {...postDetailsScreenProps}
          />
        )
      }
    }
  }, [
    navigationConfig,
    currentPage,
    formButtonFooterContainerRef,
    dynamicViewMode,
  ])

  return !IsNullOrUndefined(post) && !fetching ? (
    <PanelContainer>
      <Panel container xs={12}>
        <Box className={classes.panelHeader}>
          <Box className={classes.panelTitle}>
            <Typography
              variant="h5"
              color="textPrimary"
              style={{
                paddingRight: 10,
              }}
            >
              {`${displayMode} ${getTypeLabel(post!.type)} `}
            </Typography>
            <Typography
              variant="h5"
              color="primary"
              className={classes.postStatus}
            >
              {getStatusLabel(post!.status)}
            </Typography>
          </Box>
          <Box className={classes.navigationContainer}>
            <PostNavigation
              {...{
                post: post!,
                currentPage,
                setCurrentPage,
                navigateToResultOrQuestion,
                navigationConfig,
                disabled: buttonsDisabled,
              }}
            />
          </Box>
          <Box className={classes.rightHeaderSection}>
            {props.isModal && (
              <IconButton onClick={(): void => clearCurrentPost()} size="large">
                <SvgIcon>
                  <CrossIcon />
                </SvgIcon>
              </IconButton>
            )}
          </Box>
        </Box>
        <Divider />
        <Box className={classes.contentWrapper}>
          <div
            className={classes.contentPanel}
            ref={(el): void => setMainContentPanelRef(el)}
          />
          <div
            className={classes.contentPanel}
            ref={(el): void => setDynamicContentPanelRef(el)}
          >
            <ContentNavigation
              activeMode={dynamicViewMode}
              onChange={(mode: PostDynamicViewMode): void =>
                setDynamicViewMode(mode)
              }
            />
            {dynamicViewMode === PostDynamicViewMode.Comments && (
              <RequestContextProvider>
                <CommentsProvider>
                  <PostComments />
                </CommentsProvider>
              </RequestContextProvider>
            )}
          </div>
          {renderContent()}
        </Box>
        <Divider />
        <Box className={classes.splitFooter}>
          <div
            className={classes.leftFooterSection}
            ref={(el): void => setFormButtonFooterContainerRef(el)}
          />
          <Box className={classes.rightFooterSection}>
            <PostActionButtons
              post={post!}
              exclude={[
                PostActionButtonType.Feature,
                PostActionButtonType.Review,
                PostActionButtonType.View,
              ]}
              disabled={buttonsDisabled}
            />
          </Box>
        </Box>
      </Panel>
    </PanelContainer>
  ) : null;
}
