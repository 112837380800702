import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {
  LeaderboardContextProvider,
  useLeaderboardContext,
} from 'modules/leaderboards/context'
import { PositionRewardList } from 'modules/leaderboards/views/components/position-reward-list'
import React, { useEffect } from 'react'
import { IEvent } from 'types/modules/events/models/entities/event'

interface IEventRewardsProps {
  event: IEvent
}

export const EventRewards: React.FC<IEventRewardsProps> = (
  props: IEventRewardsProps,
) => {
  const { event } = props

  const { setCurrentLeaderboard, clearCurrentLeaderboard } =
    useLeaderboardContext()

  useEffect(() => {
    setCurrentLeaderboard(event.leaderboardId)

    return (): void => {
      clearCurrentLeaderboard()
    }
  }, [])

  return <PositionRewardList />
}
