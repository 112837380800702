import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { DateTimePicker } from '@mui/x-date-pickers';
import { AutocompleteSearchMultiselect } from 'components/controls/inputs/downshift/autocomplete/autocomplete-search-multiselect';
import { OverrideChipProps } from 'components/controls/inputs/downshift/multiselect/multiselect-props';
import { RegionsSelect } from 'components/regions-select';
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined';
import { mapMimeType } from 'core/utils/mime-mapper'
import { MediaItemInput } from 'modules/media-items/media-item-input'
import moment from 'moment-timezone';
import React from 'react'
import { AspectRatio } from 'types/common/images/enum/aspect-ratio'
import { MediaOrientation } from 'types/common/images/enum/media-orientation'
import { MediaUploadModel } from 'types/common/images/models/entities/image-upload-model'
import { MediaItem } from 'types/modules/media-items/models/entities/media-item'
import { IAsyncAction } from 'types/redux/interfaces/IAction';
import { AppState } from 'types/redux/types/app-state';

export enum FormTypes {
  TEXT_INPUT,
  SELECT,
  DATE_TIME,
  IMAGE,
  CHECKBOX,
  SEARCH_MULTISELECT,
  COUNTRIES_SELECT
}

export const useSimpleFormStyles = makeStyles(() =>
  createStyles({
    tagForm: {
      width: '100%',
    },
    inputLabel: {
      display: 'flex',
      marginBottom: 8,
      marginTop: 8,
    },
    hint: {
      color: 'orange',
    }
  }),
)

interface IFormGenProps {
  type: FormTypes
  value?: string | number | boolean | any[]
  label?: string
  imageUrl?: string
  name: string
  id?: string
  options?: any[]
  otherProps?: any
  disabled?: boolean
  hideInitialOption?: boolean
  media?: any
  hideMainLabel?: boolean
  optionLabelKey?: string
  optionValueKey?: string
  onComplete?: (fileParams: any) => void
  onCreate?: (value: MediaItem) => void
  handleChange: (e: React.ChangeEvent<any>) => void
  displayNameInMultiSelect?: (val: any) => string
  searchAsyncActionCreator?: <TActionParamsType>(
    params: TActionParamsType | any,
  ) => IAsyncAction
  selectorMethod?: (state: AppState) => any[]
}

export const FormGen = ({
  type,
  label,
  hideMainLabel = false,
  handleChange,
  hideInitialOption = false,
  value = '',
  name,
  id,
  imageUrl,
  options = [],
  otherProps = {},
  disabled = false,
  media,
  optionLabelKey = 'name',
  optionValueKey = 'value',
  onComplete,
  onCreate = (value: MediaItem): void => {},
  displayNameInMultiSelect,
  searchAsyncActionCreator,
  selectorMethod
}: IFormGenProps): JSX.Element | null => {
  let element = null
  const classes = useSimpleFormStyles()

  const commonProps = {
    id: id || name,
    name,
    onChange: handleChange,
    value,
    variant: 'filled',
    fullWidth: true,
    disabled,
  }

  const { error, helperText, ...rest } = otherProps

  switch (type) {
    case FormTypes.TEXT_INPUT:
      element = <TextField  {...commonProps} {...rest} />
      break
    case FormTypes.COUNTRIES_SELECT:
      element = <RegionsSelect
        // @ts-expect-error to be decided later
        onSelect={(value) => handleChange({ target: { value, name }, name })}
        selectedRegions={value as string[]}
      />
      break
    case FormTypes.SELECT:
      element = (
        <Select
          {...commonProps}
          {...rest}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getcontentanchorel: null,
          }}>
          {!hideInitialOption && <MenuItem value="">Select an option</MenuItem>}
          {options.map((option) => (
            <MenuItem key={option[optionValueKey]} value={option[optionValueKey]}>
              {option[optionLabelKey]}
            </MenuItem>
          ))}
        </Select>
      )
      break
    case FormTypes.CHECKBOX:
      element = (
        <FormControlLabel
          label={label}
          name={name}
          control={
            <Checkbox
              size="small"
              checked={!!value}
              onClick={(): void => {
                // @ts-expect-error to be decided later
                handleChange({ target: { value: !value as boolean, name }, name })
              }}
              name={label}
              color="primary"
              disabled={disabled}
            />
          }
        />
      )
      break
    case FormTypes.IMAGE:
      element = (
        <MediaItemInput
          disabled={disabled}
          imageUrl={imageUrl}
          url={imageUrl}
          imageLabel={'Cover Image'}
          mediaItemId={media?.mediaItemId}
          options={{
            aspectRatio: AspectRatio.Standard,
            orientation: MediaOrientation.Portrait,
          }}
          onComplete={(fileParams: MediaUploadModel): void => {
            !!onComplete &&
              onComplete({
                item: fileParams.binaryData,
                extension: `image.${fileParams.fileExtension}`,
                overrideHeaders: {
                  'content-type': mapMimeType(fileParams.fileExtension),
                  accept: '*/*',
                },
              })
          }}
          onCreate={onCreate}
          {...rest}
        />
      )
      break
    case FormTypes.DATE_TIME:
      element = (
        <DateTimePicker
          name={name}
          value={
            IsNullOrUndefined(value)
              ? null
              : moment(value as string)
          }
          // @ts-expect-error to decide later
          onChange={(value): void => handleChange({
            target: {
              value: moment(value?.utc().toISOString()).toISOString(),
              name
            }
          })}
          format={'YYYY-MM-DD HH:mm'}
          minDate={moment(otherProps.minDate)}
        />
      )
      break

    case FormTypes.SEARCH_MULTISELECT:
      element = (
        <AutocompleteSearchMultiselect
            key={`${name}-empty`}
            label={label}
            initialSelectedItems={
              (value as any[]) ?? []
            }
            availableItems={[]}
            onSelectedItemsChange={
              // @ts-expect-error to decide later
              (selected: any[]) => handleChange({
                target: {
                  name,
                  value: selected
                }
              })
            }
            searchAsyncActionCreator={searchAsyncActionCreator!}
            selectorMethod={selectorMethod!}
            options={{
              selectedItemsChipProps: (item: any): OverrideChipProps => {
                const props: OverrideChipProps = {
                  label: displayNameInMultiSelect ? displayNameInMultiSelect(item) : item.name!,
                }
                return props
              },
              autocompleteSearchResultContent: (item: any): string =>
                displayNameInMultiSelect ? displayNameInMultiSelect(item) : item.name!,
            }}
          />
      )
    break
    default:
      element = null
  }

  return (
    <>
      {label && !hideMainLabel && (
        <InputLabel htmlFor={name} className={classes.inputLabel}>
          {label}
        </InputLabel>
      )}
      {element}
      {!!otherProps.error && (
        <FormHelperText error>{otherProps.helperText}</FormHelperText>
      )}
      {!!otherProps.hint && (
        <FormHelperText className={classes.hint}>{otherProps.hint}</FormHelperText>
      )}
    </>
  )
}
