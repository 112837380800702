import { GAME_TASK_MODULE_NAME } from 'modules/quests/constants'

const ActionTypes = {
  SET_CURRENT_GAME_TASK_ID: `${GAME_TASK_MODULE_NAME}/SET_CURRENT_GAME_TASK_ID`,
  GET_GAME_TASKS: `${GAME_TASK_MODULE_NAME}/GET_GAME_TASKS`,
  GET_GAME_TASK_BY_ID: `${GAME_TASK_MODULE_NAME}/GET_GAME_TASK_BY_ID`,
  CREATE_GAME_TASK: `${GAME_TASK_MODULE_NAME}/CREATE_GAME_TASK`,
  CREATE_GAME_TASK_DRAFT: `${GAME_TASK_MODULE_NAME}/CREATE_GAME_TASK_DRAFT`,
  UPDATE_GAME_TASK: `${GAME_TASK_MODULE_NAME}/UPDATE_GAME_TASK`,
  GET_GAME_TASK_STEPS: `${GAME_TASK_MODULE_NAME}/GET_GAME_TASK_STEPS`,
  CREATE_GAME_TASK_STEP: `${GAME_TASK_MODULE_NAME}/CREATE_GAME_TASK_STEP`,
  UPDATE_GAME_TASK_STEP: `${GAME_TASK_MODULE_NAME}/UPDATE_GAME_TASK_STEP`,
  UPLOAD_GAME_TASK_IMAGE: `${GAME_TASK_MODULE_NAME}/UPLOAD_GAME_TASK_IMAGE`,
}

export default ActionTypes
