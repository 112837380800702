import { ROUTES, ROUTE_CONFIG } from './config/routes'
import { ActionSegments } from './config/action-segments'
import { ControllerSegments } from './config/controller-segments'
import { RouteParams } from './config/route-params'
import { default as Router } from './components/router'
import { default as LinkResolver } from './components/link-resolver'
import { default as AccessDenied } from './views/access-denied'
import { default as ExtendedRoute } from './components/extended-route'

export default {
  Router,
  ROUTES,
  ActionSegments,
  ControllerSegments,
  RouteParams,
  AccessDenied,
  LinkResolver,
  ExtendedRoute,
  ROUTE_CONFIG,
}

export {
  Router,
  ROUTES,
  ActionSegments,
  ControllerSegments,
  RouteParams,
  AccessDenied,
  LinkResolver,
  ExtendedRoute,
  ROUTE_CONFIG,
}
