import { EndpointRoute } from 'types/common/api/models/entities/endpoint-route'
import { RequestMethod } from 'types/common/api/enums/request-method'
import paths from './paths'
import params from './params'
import filters from './filters'

const OnboardingEndpoints: { [key: string]: EndpointRoute } = {
  getOnboardingConfigs: {
    method: RequestMethod.GET,
    path: [paths.onboardings],
    filters,
  },
}

export default OnboardingEndpoints
