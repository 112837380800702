import LiveScheduleEndpoints from 'core/api/endpoints/live-schedules'
import { createAction, createAsyncAction } from 'core/redux/utils'
import ActionTypes from 'modules/live-schedules/action-types'
import { IAsyncActionParams } from 'types/common/api/models/entities/async-action-params'
import { ILiveSchedule } from 'types/modules/live-schedules/models/entities/live-schedule'
import { ILiveScheduleDraft } from 'types/modules/live-schedules/models/entities/live-schedule-draft'
import { IAsyncAction, ISimpleAction } from 'types/redux/interfaces/IAction'

export interface ILiveScheduleActionParams
  extends IAsyncActionParams<ILiveSchedule | ILiveScheduleDraft> {
  liveScheduleId?: string
}

export const setCurrentLiveScheduleId: (
  params: ILiveScheduleActionParams,
) => ISimpleAction = (params) =>
  createAction(ActionTypes.SET_CURRENT_LIVE_SCHEDULE_ID, params)

export const clearLiveSchedules: () => ISimpleAction = () =>
  createAction(ActionTypes.CLEAR_LIVE_SCHEDULES, null)

export const createLiveScheduleDraft: (
  params: ILiveScheduleActionParams,
) => ISimpleAction = (params) =>
  createAction(ActionTypes.CREATE_LIVE_SCHEDULE_DRAFT, params)

export const getLiveSchedules: (
  params: ILiveScheduleActionParams,
) => IAsyncAction = (params) =>
  createAsyncAction({
    type: ActionTypes.GET_LIVE_SCHEDULES,
    endpoint: LiveScheduleEndpoints.getLiveSchedules,
    payload: params,
  })

export const getLiveScheduleById: (
  params: ILiveScheduleActionParams,
) => IAsyncAction = (params) =>
  createAsyncAction({
    type: ActionTypes.GET_LIVE_SCHEDULE_BY_ID,
    endpoint: LiveScheduleEndpoints.getLiveScheduleById,
    payload: params,
  })

export const createLiveSchedule: (
  params: ILiveScheduleActionParams,
) => IAsyncAction = (params) =>
  createAsyncAction({
    type: ActionTypes.CREATE_LIVE_SCHEDULE,
    endpoint: LiveScheduleEndpoints.createLiveSchedule,
    payload: params,
  })

export const updateLiveSchedule: (
  params: ILiveScheduleActionParams,
) => IAsyncAction = (params) =>
  createAsyncAction({
    type: ActionTypes.UPDATE_LIVE_SCHEDULE,
    endpoint: LiveScheduleEndpoints.updateLiveSchedule,
    payload: params,
  })
