import React, { createContext, PropsWithChildren, useContext } from 'react'
import { useRequestContext } from 'core/api/context'
import { Actions } from 'modules/prizes'
import { useDispatch } from 'react-redux'
import PrizeStatus from 'types/modules/prizes/enums/prize-status'
import { IPrize } from 'types/modules/prizes/models/entities/prize'
import _ from 'core/utils/deepdash'

export interface IPrizeStatusManagerContext {
  restorePrize: (prizeId: string) => void
  submitPrize: (prizeId: string) => void
  approvePrize: (prizeId: string) => void
  closePrize: (prizeId: string) => void
  reactivatePrize: (prizeId: string) => void
  toggleFeaturePrize: (prizeId: string) => void
  promotePrize: (prizeId: string) => void
}

export const PrizeStatusManagerContext =
  createContext<IPrizeStatusManagerContext>({} as IPrizeStatusManagerContext)

export const PrizeStatusManagerContextProvider: React.FC<
  PropsWithChildren<{}>
> = (props: PropsWithChildren<{}>) => {
  const rc = useRequestContext()

  const dispatch = useDispatch()

  const updatePrizeStatus = (
    prizeId: string,
    prizeStatus: PrizeStatus,
  ): void => {
    new Promise((onResolve, onReject) => {
      dispatch(
        Actions.updatePrizeStatus({
          prizeId,
          prizeStatus,
          promise: {
            onResolve,
            onReject,
          },
        }),
      )
    }).then(() => {
      dispatch(Actions.getPrizeStatuses())
    })
  }

  const discardPrize = (prizeId: string): void => {
    updatePrizeStatus(prizeId, PrizeStatus.Trashed)
  }

  const submitPrize = (prizeId: string): void => {
    updatePrizeStatus(prizeId, PrizeStatus.Pending)
  }

  const approvePrize = (prizeId: string): void => {
    updatePrizeStatus(prizeId, PrizeStatus.Open)
  }

  const closePrize = (prizeId: string): void => {
    updatePrizeStatus(prizeId, PrizeStatus.Closed)
  }

  const restorePrize = (prizeId: string): void => {
    updatePrizeStatus(prizeId, PrizeStatus.Open)
  }

  const reactivatePrize = (prizeId: string): void => {
    updatePrizeStatus(prizeId, PrizeStatus.Open)
  }

  const toggleFeaturePrize = (prizeId: string): void => {
    new Promise<IPrize>((onResolve, onReject) => {
      dispatch(
        Actions.getPrizeById({
          prizeId,
          promise: {
            onResolve,
            onReject,
          },
        }),
      )
    }).then((prize) => {
      const newPrize = _.clone(prize)
      newPrize.featured = !newPrize.featured
      dispatch(
        Actions.updatePrize({
          prizeId: prize.id,
          item: newPrize,
        }),
      )
    })
  }

  const promotePrize = (prizeId: string): void => {
    dispatch(
      Actions.promotePrize({
        prizeId,
      }),
    )
  }

  const context: IPrizeStatusManagerContext = {
    restorePrize,
    submitPrize,
    approvePrize,
    closePrize,
    reactivatePrize,
    toggleFeaturePrize,
    promotePrize,
  }

  return (
    <PrizeStatusManagerContext.Provider value={context}>
      {props.children}
    </PrizeStatusManagerContext.Provider>
  )
}

export const usePrizeStatusManagerContext = (): IPrizeStatusManagerContext =>
  useContext(PrizeStatusManagerContext)
