import React, { ComponentType } from 'react'
import { IPostActionButtonsProps } from 'types/modules/posts/models/props/post-action-buttons-props'
import { AbandonPostButton } from 'modules/posts/views/components/action-buttons/abandon'
import { ApprovePostButton } from 'modules/posts/views/components/action-buttons/approve'
import { ClosePostButton } from 'modules/posts/views/components/action-buttons/close'
import { DiscardPostButton } from 'modules/posts/views/components/action-buttons/discard'
import { FeaturePostButton } from 'modules/posts/views/components/action-buttons/feature'
import { PausePostButton } from 'modules/posts/views/components/action-buttons/pause'
import { PromotePostButton } from 'modules/posts/views/components/action-buttons/promote'
import { ReactivatePostButton } from 'modules/posts/views/components/action-buttons/reactivate'
import { RestorePostButton } from 'modules/posts/views/components/action-buttons/restore'
import { ReviewPostButton } from 'modules/posts/views/components/action-buttons/review'
import { ViewPostButton } from 'modules/posts/views/components/action-buttons/view'
import { SubmitPostButton } from 'modules/posts/views/components/action-buttons/submit'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { PostActionButtonType } from 'types/modules/posts/enums/post-action-button-type'
import { DuplicatePostButton } from 'modules/posts/views/components/action-buttons/duplicate'
import { IPostActionButtonProps } from 'types/modules/posts/models/props/post-action-button-props'
import { SettlePostButton } from 'modules/posts/views/components/action-buttons/settle'
import { RejectPostButton } from 'modules/posts/views/components/action-buttons/reject'

const PostActionButtons: React.FC<IPostActionButtonsProps> = (
  props: IPostActionButtonsProps,
) => {
  const { post, exclude, onPopoverClose } = props

  const renderButtons = (): JSX.Element => {
    const buttonsToRender: [
      PostActionButtonType,
      ComponentType<IPostActionButtonProps>,
    ][] = []

    Object.values(PostActionButtonType).forEach((buttonType) => {
      if (!IsNullOrUndefined(exclude) && exclude?.includes(buttonType)) {
        return
      }

      let currentButton: ComponentType<IPostActionButtonProps> | null = null

      switch (buttonType) {
        case PostActionButtonType.Settle: {
          currentButton = SettlePostButton
          break
        }
        case PostActionButtonType.Feature: {
          currentButton = FeaturePostButton
          break
        }
        case PostActionButtonType.Promote: {
          currentButton = PromotePostButton
          break
        }
        case PostActionButtonType.Abandon: {
          currentButton = AbandonPostButton
          break
        }
        case PostActionButtonType.Pause: {
          currentButton = PausePostButton
          break
        }
        case PostActionButtonType.Reactivate: {
          currentButton = ReactivatePostButton
          break
        }
        case PostActionButtonType.Close: {
          currentButton = ClosePostButton
          break
        }
        case PostActionButtonType.Review: {
          currentButton = ReviewPostButton
          break
        }
        case PostActionButtonType.View: {
          currentButton = ViewPostButton
          break
        }
        case PostActionButtonType.Approve: {
          currentButton = ApprovePostButton
          break
        }
        case PostActionButtonType.Reject: {
          currentButton = RejectPostButton
          break
        }
        case PostActionButtonType.Submit: {
          currentButton = SubmitPostButton
          break
        }
        case PostActionButtonType.Restore: {
          currentButton = RestorePostButton
          break
        }
        case PostActionButtonType.Discard: {
          currentButton = DiscardPostButton
          break
        }
        case PostActionButtonType.Duplicate: {
          currentButton = DuplicatePostButton
          break
        }
      }
      if (!IsNullOrUndefined(currentButton)) {
        buttonsToRender.push([buttonType, currentButton!])
      }
    })

    return (
      <React.Fragment>
        {buttonsToRender.map(([buttonType, Component]) => (
          <Component
            key={buttonType}
            post={post}
            disabled={props.disabled}
            onPopoverClose={onPopoverClose}
          />
        ))}
      </React.Fragment>
    )
  }

  return !IsNullOrUndefined(post) ? renderButtons() : null
}

export default PostActionButtons
