import AppSettingsEndpoints from 'core/api/endpoints/app-settings'
import { createAsyncAction } from 'core/redux/utils'
import ActionTypes from 'modules/app-settings/action-types'
import { IAsyncAction } from 'types/redux/interfaces/IAction'

export const getPortalDefaults: () => IAsyncAction = () =>
  createAsyncAction({
    type: ActionTypes.GET_PORTAL_DEFAULTS,
    endpoint: AppSettingsEndpoints.getPortalDefaults,
    payload: null,
  })

export const getPortalWarnings: () => IAsyncAction = () =>
  createAsyncAction({
    type: ActionTypes.GET_PORTAL_WARNINGS,
    endpoint: AppSettingsEndpoints.getPortalWarnings,
    payload: null,
  })
