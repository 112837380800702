import { MAX_TITLE_LENGTH } from 'modules/posts/constants'
import { PostField } from 'types/modules/posts/enums/post-field'
import {
  AHEAD_OF_END_DATE_MESSAGE,
  BEFORE_START_DATE_MESSAGE,
  testDateRange,
} from 'utils/validation-helper'
import * as yup from 'yup'

export const PostDetailsSchema = yup.object({
  [PostField.TITLE]: yup
    .string()
    .max(MAX_TITLE_LENGTH, `can not be more than ${MAX_TITLE_LENGTH}`),
  [PostField.PUBLIC]: yup.boolean(),
  [PostField.WATCHLIST]: yup.boolean(),
  [PostField.FEATURED]: yup.boolean(),
  [PostField.SKIP_MAIN_FEED]: yup.boolean(),
  [PostField.EVENT_ID]: yup.string(),
  [PostField.MEDIA]: yup.object().shape({
    [PostField.IMAGE_URL]: yup.string(),
    [PostField.NEWS_LINK]: yup.string(),
  }),
  [PostField.MIN_BET]: yup.number(),
  [PostField.STARS_WIN]: yup.number(),
  [PostField.DIFFICULTY]: yup.string(),
  [PostField.TAGS]: yup.array().of(yup.object()),
  [PostField.START]: yup
    .string()
    .required('start date is required')
    .test({
      message: AHEAD_OF_END_DATE_MESSAGE,
      test: function (value) {
        return testDateRange(value, this.parent[PostField.CLOSE])
      },
    }),
  [PostField.CLOSE]: yup.string().test({
    message: BEFORE_START_DATE_MESSAGE,
    test: function (value) {
      return testDateRange(this.parent[PostField.START], value)
    },
  }),
})
