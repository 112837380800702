import { Box, Divider } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useTypedSelector } from 'core/redux/utils'
import { Selectors } from 'modules/mobile-app-users'
import { DetailItem } from 'modules/mobile-app-users/views/mobile-user-modal/common/detail-item'
import {
  Actions as PostActions,
  Selectors as PostSelectors,
} from 'modules/posts'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      width: '100%',
    },
    value: {
      paddingLeft: 16,
      paddingTop: 4,
    },
  }),
)

interface IQuestionsAndAnswersProps {
  ticketId?: string
}

export const QuestionsAndAnswers: React.FC<IQuestionsAndAnswersProps> = ({
  ticketId,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const ticket = useTypedSelector((state) =>
    Selectors.getTicket(state, ticketId),
  )
  const post = useTypedSelector((state) =>
    PostSelectors.getPostById(state, ticket?.postId || ''),
  )
  useEffect(() => {
    if (!post) {
      dispatch(PostActions.getPostById({ postId: ticket?.postId }))
    }
  }, [ticket?.postId])

  if (!post) return null

  return (
    <Box className={classes.wrapper}>
      {post?.questions.map((question, index) => {
        const selectedOption =
          ticket?.optionsSelected && ticket?.optionsSelected.length > index
            ? question.options.find(
                (option) => option.id === ticket?.optionsSelected[index],
              )
            : null
        return (
          <Box key={question.id}>
            <DetailItem label={`${index + 1}. ${question.text}`}>
              <Box className={classes.value}>
                {selectedOption?.text || '-- no selection --'}
              </Box>
            </DetailItem>
            <Divider />
          </Box>
        )
      })}
    </Box>
  )
}
