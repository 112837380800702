import { Box, Button, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { usePostGroupsContext } from 'modules/post-groups/context'
import React, { useCallback, useEffect, useState } from 'react'
import _ from 'core/utils/deepdash'
import { PostGroupListItem } from 'modules/post-groups/view/components/post-groups-dashboard/post-group-list-item'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined';
import { PostGroupDetailsModal } from 'modules/post-groups/view/components/post-group-details-modal';
import { FormGen, FormTypes } from 'components/simple-form-gen';
import { useStatusFilter } from 'hooks/use-status-filter';
import StatusSelect from 'modules/post-groups/view/components/status-select';
import { PostGroup, PostGroupType } from 'fe-shared-resources';

const useStyles = makeStyles((theme: Theme) => createStyles({
  createButtonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 24,
    marginBottom: 24,
    paddingRight: 24,
    paddingLeft: 24
  },
  filterContainer: {
    display: 'flex',
  }
}))

const typeOptions = Object
  .values(PostGroupType)
  .filter((type) => type !== PostGroupType.NotSet)
  .map((type) => ({ value: type as string, name: type as string }))
  .concat({ value: 'All', name: 'All' })

export const PostGroupsDashboard: React.FC<{}> = () => {
  const classes = useStyles()
  const [name, setName] = useState('')
  const [type, setType] = useState('All')
  const { currentPostGroup, postGroups, goToCreatePostGroup, initialiseDashboard } =
    usePostGroupsContext()

  useEffect(() => {
    initialiseDashboard()
  }, [])

  const {
    filtered,
    statusOptions,
    onSelect,
    status,
    countMap
  } = useStatusFilter<PostGroup>({
    items: postGroups.postGroups,
    shouldIgnoreItem: useCallback(
      (item) => {
        if (type as string === 'All') return false
        return item.type as string !== type
      },
      [type])
  })

  return (
    <React.Fragment>
      <Box className={classes.createButtonContainer}>
        <Box className={classes.filterContainer}>
          <Box flex={1}>
            <FormGen
              value={name}
              handleChange={(e): void => setName(e.target.value)}
              name="name"
              type={FormTypes.TEXT_INPUT}
              label="Group Name"
            />
          </Box>
          <Box marginLeft={4}>
            <FormGen
              value={type}
              handleChange={(e): void => setType(e.target.value)}
              name="type"
              type={FormTypes.SELECT}
              label="Group type"
              options={typeOptions as unknown as any[]}
              hideInitialOption
            />
          </Box>
        </Box>
        <Box marginLeft={2}>
          <Button
            onClick={goToCreatePostGroup}
            variant="contained"
            color="primary"
          >
            <Typography variant="body1">
              Create Post Group
            </Typography>
          </Button>
          <Box display="flex" marginTop={2}>
            <StatusSelect
              options={statusOptions}
              value={status}
              onSelect={onSelect}
              countMap={countMap}
            />
          </Box>
        </Box>
      </Box>
      <Box marginRight={2} marginLeft={2} overflow="auto">
        {
          filtered
            .filter(postGroup => (
              postGroup.type === type || type as string === 'All')
              && !!postGroup.name.toUpperCase().match(name.toUpperCase()
            ))
            .map((postGroup) => <PostGroupListItem
              postGroup={postGroup}
              key={postGroup.id}
            />)
        }
      </Box>
      {!IsNullOrUndefined(currentPostGroup) && (
        <PostGroupDetailsModal />
      )}
    </React.Fragment>
  )
}
