const paths = {
  newsCards: 'newscards',
  add: 'add',
  save: 'save',
  update: 'update',
  upload: 'upload',
  images: 'images'
}

export default paths
