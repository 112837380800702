import React, { ReactElement, useState } from 'react'
import { Box, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { DateGrid } from 'components/calendar/views/date-grid'
import { CalendarViewMode } from 'components/calendar/types/enums/calendar-view-mode'
import { useCalendarContext } from 'components/calendar/context/calendar-context'
import { ICalendarProps } from 'components/calendar/props/calendar-props'
import { TimeGrid } from 'components/calendar/views/time-grid'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    calendarContainer: {
      position: 'relative',
      display: 'flex',
      flex: 1,
    },
  }),
)

function Calendar<TModel>(
  props: ICalendarProps<TModel>,
): ReactElement<any, any> {
  const { viewMode } = useCalendarContext()

  const renderCalendarView = (viewMode: CalendarViewMode): JSX.Element => {
    switch (viewMode) {
      case CalendarViewMode.Day:
      case CalendarViewMode.Week:
        return <TimeGrid {...props} data={props.data!} />
      case CalendarViewMode.Month:
        return <DateGrid {...props} data={props.data!} />
    }
  }

  const classes = useStyles()

  return (
    <Box className={classes.calendarContainer}>
      {renderCalendarView(viewMode)}
    </Box>
  )
}

export default Calendar
