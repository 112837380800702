import React, { createContext, useContext } from 'react'
import { AspectRatio } from 'types/common/images/enum/aspect-ratio'
import { MediaOrientation } from 'types/common/images/enum/media-orientation'
import { MediaUploadModel } from 'types/common/images/models/entities/image-upload-model'
import { MediaItem } from 'types/modules/media-items/models/entities/media-item'

export interface IMediaItemsContext {
  existingUrl?: string | null,
  loading?: boolean
  isVideo?: boolean
  onComplete: (fileParams: MediaUploadModel) => void
  onCreate?: (mediaItem: MediaItem) => void
  mediaItemId?: string
  disabled?: boolean
  options: {
    aspectRatio: AspectRatio
    orientation: MediaOrientation
  }
  closeModal: () => void
  accept: string
  removeCrop?: boolean
  mediaSquareHeight?: number
  doneLabel?: string
  uploadMediaLabel?: string
  previewAsUrl?: boolean
}

interface IProps extends IMediaItemsContext {
  children: JSX.Element[]
}

const MediaItemsContext = createContext<IMediaItemsContext>({} as IMediaItemsContext)

export const MediaItemsProvider = (
  {
    children,
    ...rest
  }: IProps,
): JSX.Element => {

  const context = {
    ...rest
  }

  return (
    <MediaItemsContext.Provider value={context}>
      {children}
    </MediaItemsContext.Provider>
  )
}

export const useMediaItemsContext = (): IMediaItemsContext => useContext(MediaItemsContext)
