import { Box, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { MobileDevice } from 'components/preview/mobile-device'
import React, { PropsWithChildren } from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    previewContainer: {
      display: 'flex',
      flex: 1,
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
)

export interface IPreviewContainerProps {
  withDevice: boolean
}

export const PreviewContainer: React.FC<
  PropsWithChildren<IPreviewContainerProps>
> = (props: PropsWithChildren<IPreviewContainerProps>) => {
  const classes = useStyles()
  return (
    <Box className={classes.previewContainer}>
      {props.withDevice ? (
        <MobileDevice>{props.children}</MobileDevice>
      ) : (
        <React.Fragment>{props.children}</React.Fragment>
      )}
    </Box>
  )
}
