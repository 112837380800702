import React from 'react'
import { useTypedSelector } from 'core/redux/utils'
import { useCampaignsContext } from 'modules/campaigns/context/campaign-context'
import {
  Campaign,
  StyledTableCell,
} from 'modules/campaigns/view/campaign'
import { CampaignModal } from 'modules/campaigns/view/campaign-modal'
import { ICampaign } from 'modules/campaigns/models/campaign'
import { LocalListWrapper } from 'components/local-list-wrapper'
import { CampaignActions, Selectors } from '..'

const sortOptions = [
  { name: 'Created at', value: 'createdAt', descending: true },
  { name: 'Name', value: 'name' },
]

export const CampaignList: React.FC<{}> = () => {
  const list = useTypedSelector((state) =>
    Selectors.getCampaigns(state),
  )
  const count = useTypedSelector((state) =>
    Selectors.getCampaignsCount(state),
  )
  const {
    currentCampaign,
    createCampaignDraft,
    setCurrentCampaign,
  } = useCampaignsContext()

  return (
    <LocalListWrapper<
      ICampaign,
      CampaignActions.ICampaignActionParams,
      null
    >
      list={list}
      count={count}
      searchKey="title"
      currentItem={currentCampaign}
      createDraft={createCampaignDraft}
      setCurrentItem={setCurrentCampaign}
      CardComponent={Campaign}
      ModalComponent={CampaignModal}
      getItems={CampaignActions.getCampaigns}
      searchLabel="Title"
      title="Campaigns"
      sortOptions={sortOptions}
      panelXs={12}
    >
      <>
        <StyledTableCell>Name</StyledTableCell>
        <StyledTableCell>Created at</StyledTableCell>
      </>
    </LocalListWrapper>
  )
}
