import React from 'react'
import { IPost } from 'types/modules/posts/models/entities/post'
import { HomeIcon } from 'components/icons'
import { ManagePostView } from 'modules/posts/views/components/manage-post-panel/types/manage-post-view'
import { IPostNavigationConfigItem } from 'modules/posts/views/components/manage-post-panel/types/post-navigation-config-item'
import { sortBy } from 'lodash-es'

export const buildNavigationConfig = (
  post: IPost | null,
): IPostNavigationConfigItem[] => {
  const config = [
    {
      label: <HomeIcon />,
      view: ManagePostView.Details,
      params: {
        postId: post?.id ?? '',
      },
    },
  ] as IPostNavigationConfigItem[]

  post?.results.forEach((result, index) => {
    config.push({
      label: `R${index + 1}`,
      view: ManagePostView.Result,
      params: {
        postId: result.postId,
        resultId: result.id,
      },
    })
  })

  sortBy((post?.questions || []), 'order').forEach((question, index) => {
    config.push({
      label: `Q${index + 1}`,
      view: ManagePostView.Question,
      params: {
        postId: question.postId,
        questionId: question.id,
      },
    })
  })

  return config
}
