import { Box, Button, Divider, Slider, Typography } from '@mui/material'
import { CrossIcon, SaveIcon } from 'components/icons'
import { PreviewAndControls } from 'modules/media-items/media-item-input/preview-and-controls'
import { useModalStyles } from 'modules/media-items/media-item-input/use-modal-styles'
import { useImageUpload } from 'modules/media-items/media-item-input/use-image-upload'
import { Panel, PanelContainer } from 'components/panel'
import React, { useState } from 'react'
import Cropper from 'react-easy-crop'
import { MediaOrientation } from 'types/common/images/enum/media-orientation'
import { AspectRatio } from 'types/common/images/enum/aspect-ratio'
import {
  MediaUploadModel,
} from 'types/common/images/models/entities/image-upload-model'
import { MediaItem } from 'types/modules/media-items/models/entities/media-item'
import getCroppedImage from 'modules/media-items/utils/get-cropped-image'
import {
  getExtension,
  isImage,
} from 'modules/media-items/media-item-input/helper'
import { useVideoUpload } from 'modules/media-items/media-item-input/use-video-upload'
import { useMediaItemsContext } from 'modules/media-items/media-item-context'

export const MediaHandler: React.FC<{}> = (): JSX.Element => {
  const [error, setError] = useState('')
  const {
    mediaItemId: existingMediaItemId,
    isVideo,
    existingUrl,
    disabled,
    loading,
    removeCrop,
    mediaSquareHeight,
    closeModal,
    onComplete,
    onCreate,
    doneLabel,
  } = useMediaItemsContext()
  const modalClasses = useModalStyles()
  const {
    uploadMediaItemImage,
    handleFileChange: handleImageChange,
    originalImage,
    createTransformation,
    onCropComplete,
    aspect,
    croppedAreaPixels,
    measurementControl,
    measurements,
    imageUrl,
    clearMeasurements,
    clearOriginalImage
  } = useImageUpload({ existingMediaItemId })
  const {
    handleFileChange,
    uploadMediaItemVideo,
    videoSource,
    originalVideo,
    clearVideo
  } = useVideoUpload()


  const onFileChange = async (files: FileList | null): Promise<void> => {
    if (!files || files.length === 0) return

    const fileToUpload = files[0]
    if (!isImage(fileToUpload)) {
      await handleFileChange(fileToUpload)
      clearMeasurements()
      return
    }
    await handleImageChange(fileToUpload)
    clearVideo()
  }


  const onSave = (): void => {
    if (videoSource && originalVideo) {
      uploadMediaItemVideo({
        onFail: () => setError('Error uploading video'),
        onSuccess: (videoFinalUrl: string): void => onComplete({ videoFinalUrl })
      })
      return
    }
    uploadMediaItemImage({
      transformation: createTransformation(),
      callbackFn: async (mediaItem: MediaItem): Promise<void> => {

        if (originalImage) {
          onCreate && onCreate(mediaItem)
        }
        if (removeCrop) {
          onComplete({
            imageUrl: mediaItem?.media?.imageUrl,
            fileExtension: mediaItem!
              .media!.imageUrl!.split(/[#?]/)[0]!
              .split('.')!
              .pop()!
              .trim(),
          })
        } else {
          const croppedImage = await getCroppedImage(
            mediaItem!.media!.imageUrl || '',
            croppedAreaPixels!,
            0,
          )

          const fileParams: MediaUploadModel = {
            fileExtension: mediaItem!
              .media!.imageUrl!.split(/[#?]/)[0]!
              .split('.')!
              .pop()!
              .trim(),
            binaryData: Buffer.from(croppedImage as string, 'base64'),
            imageUrl: mediaItem?.media?.imageUrl,
            mediaItemId: mediaItem?.id
          }
          onComplete(fileParams)
        }
        clearOriginalImage()
      },
    })
  }

  const saveButtonDisabled =
    disabled ||
    (getExtension(existingUrl || '') === 'gif' &&
      !originalImage)
  const videoUrl = videoSource || existingUrl || ''
  return (
    <PanelContainer>
      <Panel container xs={12}>
        <Box className={modalClasses.panelHeader}>
          <Box className={modalClasses.panelTitle}>
            <Typography variant="h5" color="textPrimary">
              Manage media item
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box className={modalClasses.contentWrapper}>
          <Box className={modalClasses.transformationPanel}>
            <Box className={modalClasses.contentPanel}>
              <Box className={modalClasses.cropContainer}>
                {!saveButtonDisabled && (<>
                    {isVideo && !!videoUrl
                    && <video width="100%" preload="auto" controls autoPlay key={videoSource}>
                      <source src={videoSource || existingUrl || ''} type="video/mp4" key={videoSource} />
                    </video>}
                    {!isVideo && !removeCrop && <Cropper
                      image={imageUrl}
                      crop={measurements.crop}
                      zoom={measurements.zoom}
                      aspect={aspect}
                      onZoomChange={measurementControl.setZoom}
                      onCropChange={measurementControl.setCrop}
                      onCropComplete={onCropComplete}
                    />}
                    {
                      !isVideo && removeCrop && <div className={modalClasses.imageWithoutCropper}>
                        <img
                          src={imageUrl || existingUrl || ''}
                          alt="ALT"
                          style={{ width: mediaSquareHeight, height: mediaSquareHeight }}
                        />
                      </div>
                    }
                  </>
                )}
              </Box>
            </Box>
            <Box className={modalClasses.footerPanel}>
              <Box className={modalClasses.leftFooterSection}>
                <Button
                  variant="contained"
                  startIcon={<CrossIcon />}
                  className={modalClasses.cancelButton}
                  size="small"
                  onClick={(): void => {
                    closeModal()
                  }}
                >
                  Close
                </Button>
              </Box>
              {!isVideo && !removeCrop && <Box className={modalClasses.footerSection}>
                <Box className={modalClasses.zoomSlider}>
                  <Slider
                    value={measurements.zoom}
                    min={1}
                    max={3}
                    step={0.01}
                    onChange={(e, value): void => {
                      measurementControl.setZoom(value as number)
                    }}
                    disabled={saveButtonDisabled || loading}
                  />
                </Box>
              </Box>}
              <Box className={modalClasses.rightFooterSection}>
                <Button
                  variant="contained"
                  startIcon={<SaveIcon />}
                  className={modalClasses.updateButton}
                  size="small"
                  disabled={saveButtonDisabled || loading}
                  onClick={onSave}
                >
                  {loading ? 'Saving' : doneLabel}
                </Button>
              </Box>
            </Box>
          </Box>
          <PreviewAndControls
            disabled={disabled}
            orientation={measurements.orientation}
            aspectRatio={measurements.aspectRatio}
            onChangeAspectRatio={(value): void =>
              measurementControl.setAspectRatio(value as AspectRatio)
            }
            onChangeOrientation={(value): void =>
              measurementControl.setOrientation(value as MediaOrientation)
            }
            onFileChange={onFileChange}
            imageUrl={imageUrl}
          />
        </Box>
      </Panel>
      {!!error && <Box><br/><Typography variant="caption" color="error">{error}</Typography></Box>}
    </PanelContainer>
  )
}
