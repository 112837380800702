import { Theme, Button, ButtonProps } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx'
import { ConfirmationDialog } from 'components/confirmation-dialog'
import { BetOfDayIcon } from 'components/icons'
import { usePrizeStatusManagerContext } from 'modules/prizes/context/prize-status-manager'
import React from 'react'
import PrizeStatus from 'types/modules/prizes/enums/prize-status'
import { IPrizeActionButtonProps } from 'types/modules/prizes/models/props/prize-action-button-props'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.primary.contrastText,
      backgroundColor: '#02b3dc',
      '&:hover': {
        backgroundColor: '#14d1fd',
      },
      '&.promoted': {
        color: theme.palette.text.primary,
        backgroundColor: '#ecf0f1',
        '&:hover': {
          backgroundColor: '#f2f5f5',
        },
      },
    },
  }),
)

export const PromotePrizeButton: React.FC<IPrizeActionButtonProps> = (
  props: IPrizeActionButtonProps,
) => {
  const { promotePrize } = usePrizeStatusManagerContext()

  const classes = useStyles()

  const isActive = props.prize.status === PrizeStatus.Open

  const buttonProps: ButtonProps = {
    variant: 'contained',
    startIcon: <BetOfDayIcon />,
    className: clsx(classes.root, props.prize.promoted && 'promoted'),
    size: 'small',
    disabled: props.disabled,
  }

  const buttonLabel = 'promote'

  return isActive ? (
    <ConfirmationDialog
      popoverId={`${buttonLabel}-prize-${props.prize.id}`}
      buttonProps={buttonProps}
      onConfirmation={(): void => promotePrize(props.prize.id)}
      confirmationText={`Are you sure you want to promote this prize?`}
      buttonLabel={buttonLabel}
      onPopoverClose={props.onPopoverClose}
    />
  ) : null
}
