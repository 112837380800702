import { MODULE_NAME } from 'modules/shipping/constants'
import { IUserPrizeState } from 'types/modules/shipping/models/state/user-prize-state'
import { AppState } from 'types/redux/types/app-state'
import _ from 'core/utils/deepdash'
import { IUserPrize } from 'types/modules/shipping/models/entities/user-prize'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { UserPrizeStatus } from 'types/modules/shipping/enums/user-prize-status'

const getState = (state: AppState): IUserPrizeState =>
  _.cloneDeep(state[MODULE_NAME])

export const getUserPrizes = (state: AppState): IUserPrizeState =>
  getState(state)

export const getUserPrizesByStatus = (
  state: AppState,
  statuses: UserPrizeStatus[] | null,
): IUserPrizeState => {
  const userPrizes = getUserPrizes(state)

  if (!IsNullOrUndefined(statuses)) {
    userPrizes.items = userPrizes.items.filter((userPrize) =>
      statuses?.includes(userPrize.status),
    )
  }

  return userPrizes
}

export const getCurrentPrize = (state: AppState): IUserPrize | null => {
  const userPrizes = getUserPrizes(state)

  if (IsNullOrUndefined(userPrizes.currentUserPrizeId)) return null

  const currentUserPrize = userPrizes.items.find(
    (userPrize) => userPrize.id === userPrizes.currentUserPrizeId,
  )

  return currentUserPrize ?? null
}
