import React from 'react'
import { Panel, PanelContainer } from 'components/panel'
import { RequestContextProvider } from 'core/api/context'
import { PostContext } from 'modules/posts/context'
import { PostsDashboard } from 'modules/posts/views/components/dashboard'

const PostsDashboardPage: React.FunctionComponent<{}> = () => {
  return (
    <RequestContextProvider>
      <PostContext>
        <PanelContainer>
          <Panel container xs={12}>
            <PostsDashboard />
          </Panel>
        </PanelContainer>
      </PostContext>
    </RequestContextProvider>
  )
}

export default PostsDashboardPage
