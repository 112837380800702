import { MODULE_NAME } from 'modules/tags/constants'
import { IKeyValueStringCollection } from 'types/common/interfaces/IKeyValueStringCollection'

const ActionTypes: IKeyValueStringCollection = {
  SET_CURRENT_TAG_ID: `${MODULE_NAME}/SET_CURRENT_TAG_ID`,
  GET_TAGS: `${MODULE_NAME}/GET_TAGS`,
  GET_TAG_BY_ID: `${MODULE_NAME}/GET_TAG_BY_ID`,
  CREATE_TAG: `${MODULE_NAME}/CREATE_TAG`,
  CREATE_TAG_DRAFT: `${MODULE_NAME}/CREATE_TAG_DRAFT`,
  UPDATE_TAG: `${MODULE_NAME}/UPDATE_TAG`,
  DELETE_TAG_BY_ID: `${MODULE_NAME}/DELETE_TAG_BY_ID`,
  GET_TAG_GROUPS: `${MODULE_NAME}/GET_TAG_GROUPS`,
  GET_TAG_GROUP_BY_ID: `${MODULE_NAME}/GET_TAG_GROUP_BY_ID`,
  CREATE_TAG_GROUP: `${MODULE_NAME}/CREATE_TAG_GROUP`,
  UPDATE_TAG_GROUP: `${MODULE_NAME}/UPDATE_TAG_GROUP`,
  GET_AUTOCOMPLETE_SEARCH_TAGS: `${MODULE_NAME}/GET_AUTOCOMPLETE_SEARCH_TAGS`,
  GET_AUTOCOMPLETE_SEARCH_TAG_GROUPS: `${MODULE_NAME}/GET_AUTOCOMPLETE_SEARCH_TAG_GROUPS`,
  GET_TAG_PROMOTION: `${MODULE_NAME}/GET_TAG_PROMOTION`,
  UPDATE_TAG_PROMOTION: `${MODULE_NAME}/UPDATE_TAG_PROMOTION`,
  GET_TAG_SUGGESTION: `${MODULE_NAME}/GET_TAG_SUGGESTION`,
  UPDATE_TAG_SUGGESTION: `${MODULE_NAME}/UPDATE_TAG_SUGGESTION`,
  UPLOAD_TAG_IMAGE: `${MODULE_NAME}/UPLOAD_TAG_IMAGE`,
}

export default ActionTypes
