import _ from 'core/utils/deepdash'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { EventStatus } from 'types/modules/events/enums/event-status'
import { IEvent } from 'types/modules/events/models/entities/event'
import { IEventsState } from 'types/modules/events/models/state/IEventsState'
import { AppState } from 'types/redux/types/app-state'

import { MODULE_NAME } from '../constants'

const getState = (state: AppState): IEventsState =>
  _.cloneDeep(state[MODULE_NAME])

export const getEvents = (state: AppState): IEventsState => getState(state)

export const getEventsByStatus = (
  state: AppState,
  statuses: EventStatus[] | null,
): IEventsState => {
  const events = getEvents(state)

  if (!IsNullOrUndefined(statuses)) {
    events.items = events.items.filter((event) =>
      statuses?.includes(event.status),
    )
  }

  return events
}

export const getCurrentEvent = (state: AppState): IEvent | null => {
  const events = getEvents(state)

  if (IsNullOrUndefined(events.currentEventId)) return null

  const currentEvent = events.items.find(
    (event) => event.id === events.currentEventId,
  )

  return currentEvent ?? null
}

export const getCachedEvents = (state: AppState): IEvent[] =>
  Object.values(getState(state).cache)

export const getCachedEventById = (
  state: AppState,
  eventId?: string,
): IEvent | null =>
  getCachedEvents(state).find((event) => event.id === eventId) ?? null
