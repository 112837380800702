import { InputLabel } from '@mui/material'
import { useSimpleFormStyles } from 'components/simple-form-gen'
import { useTypedSelector } from 'core/redux/utils'
import { getPostGroups } from 'modules/post-groups/actions'
import { Selectors } from 'modules/post-groups'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import DownshiftMultiselect from 'components/controls/inputs/downshift/multiselect'
import { OnChange } from 'types/common/interfaces/IForm'
import { PostGroupStatus } from 'fe-shared-resources'



interface ITagsSelectProps {
  handleChange: (value: OnChange) => void
  initialSelectedIds: string[]
  disabled?: boolean
}

const HIDDEN_STATUSSES: Partial<Record<PostGroupStatus, boolean>> = {
  [PostGroupStatus.Settled]: true,
}

export const PostGroupsSelect = ({
  handleChange,
  initialSelectedIds,
  disabled
}: ITagsSelectProps): JSX.Element | null => {
  const getPostGroup = useTypedSelector((state) => Selectors.getPostGroupGetter(state))
  const postGroups = useTypedSelector((state) => Selectors.getPostGroups(state)).postGroups
  const dispatch = useDispatch()
  const initiallySelected = initialSelectedIds.map((id) => getPostGroup(id))
  const simpleFormClasses = useSimpleFormStyles()
  const [hasFetched, setHasFetched] = useState(false)

  const availablePostGroups = useMemo(() => {
    return postGroups.filter((postGroup) => !HIDDEN_STATUSSES[postGroup.status])
  }, [hasFetched])

  useEffect(() => {
    if (hasFetched) return
    const fetchPostGroup = () => {
      dispatch(getPostGroups({
        promise: {
          onResolve: () => setHasFetched(true),
          onReject: () => setHasFetched(true)
        }
      }))
    }
    fetchPostGroup()
  }, [hasFetched])

  const availablePostGroupsMap:  { [key: string]: boolean } = useMemo(() => {
    return availablePostGroups.reduce((acc, curr) => ({
      ...acc,
      [curr.id!]: true
    }), {})
  }, [availablePostGroups.length])

  const hasLoadedInitiallySelectedPostGroups = initialSelectedIds.every((id) => !!availablePostGroupsMap[id])

  if (availablePostGroups.length === 0 || !hasLoadedInitiallySelectedPostGroups) return null

  return  <>
    <InputLabel className={simpleFormClasses.inputLabel}>
      Post groups
    </InputLabel>
    <DownshiftMultiselect
      onSelectedItemsChange={(items): void => {
        handleChange({
          // @ts-expect-error ignore for now
          target: {
            name: 'groups',
            value: items.map((item) => item.id!)
          }
        })
      }}
      availableItems={availablePostGroups}
      initialSelectedItems={initiallySelected}
      variant="filled"
      disabled={disabled}
    />
  </>
}