import { Box, Button, Grid } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import { makeStyles } from '@mui/styles'
import { PanelContent, PanelFooter } from 'components/panel'
import { useFormik } from 'formik'
import { useGameTasksContext } from 'modules/quests/context/game-task-context'
import { GameTaskStepSchema } from 'modules/quests/validation/game-task-step-schema'
import { FormGameTaskStep, GameTaskStepForm } from 'modules/quests/view/game-tasks/game-task-modal/game-task-step-form'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { LiveOpsAction, GameTaskStepCode, validateStepParams } from 'fe-shared-resources';
import { parseJSON } from 'utils/common';

interface IGameTaskStepProps {
  gameTaskStepId?: string,
  onCancel: () => void
}


const useStyles = makeStyles(() => createStyles({
    footerContent: {
      display: 'flex',
      flex: 1,
      justifyContent: 'space-between',
    },
    cancelBtn: {
      marginRight: '1.25rem',
    },
}))

export const GameTaskStepFormWrapper = ({ gameTaskStepId, onCancel }:  IGameTaskStepProps): JSX.Element => {
  const classes = useStyles()
  const {
    gameTaskSteps,
    createGameTaskStep,
    updateGameTaskStep,
    setNavigationDisabled,
    navigationDisabled,
    selectedStep,
    requesting
  } = useGameTasksContext()
  const initialRequestingValue = useRef(requesting)
  const gameTaskStep = gameTaskSteps.find(step => step.id === gameTaskStepId)
  const isUpdating = gameTaskStepId !== 'new'

  const [resetCounter, setResetCounter] = useState(0)
  const [stepParamsErrors, setStepParamsErrors] = useState({})

  const form = useFormik({
    enableReinitialize: isUpdating,
    validationSchema: GameTaskStepSchema,
    initialValues: {
      id: gameTaskStep?.id ?? '',
      description: gameTaskStep?.description || '',
      stepNumber: gameTaskStep?.stepNumber || gameTaskSteps.length + 1 ,
      pointsRequired: gameTaskStep?.pointsRequired || 0,
      name: gameTaskStep?.name || Object.values(LiveOpsAction)[0],
      taskStepCode: gameTaskStep?.taskStepCode || GameTaskStepCode.ShareAppXTimes,
      stepParams: gameTaskStep?.stepParams || {},
      imageUrl: gameTaskStep?.imageUrl ?? '',
      stepParamsObject: parseJSON(gameTaskStep?.stepParams.liveOps, [])
        .reduce(
          (acc: { [key: string]: any }, curr: { [key: string]: any }) => ({
            ...acc, [curr.variant]: curr
          }),
          {}
        )
    },
    onSubmit: async (value: FormGameTaskStep) => {
      const { id, stepParamsObject, ...rest } = value
      const errors = await validateStepParams(
        value.name as LiveOpsAction,
        stepParamsObject
      )
      if (errors) {
        setStepParamsErrors(errors)
        return
      }
      setStepParamsErrors({})
      const stepParams = stepParamsObject
        ? {
          liveOps: JSON.stringify(
            Object.values(stepParamsObject || {})
          )
        }
        : {}
      if (gameTaskStep) {
        updateGameTaskStep(
          // @ts-expect-error to be decided
          { ...value, stepParams },
          () => {
            if (stepParamsObject?.new) {
              setResetCounter(resetCounter + 1)
            }
          }
        )
      } else {
        // @ts-expect-error to be decided
        createGameTaskStep({ ...rest, stepParams })
      }
    },
  })

  const validateParams = useCallback((callback?: (errors: any) => void) => {
    const validate = async () => {
      if (!form.values.stepParamsObject) return
      const errors = await validateStepParams(
        form.values.name as LiveOpsAction,
        form.values.stepParamsObject || {}
      )
      setStepParamsErrors(errors || {})
      if (callback) {
        callback(errors)
      }
    }
    validate()
  }, [JSON.stringify(form.values.stepParamsObject || {}), form.values.name])

  useEffect(() => {
    setNavigationDisabled(form.dirty && selectedStep !== 'new')
  }, [form.dirty, selectedStep])

  useEffect(() => {
    if (requesting === false && initialRequestingValue.current) {
      form.resetForm({ values: form.values })
    }
    initialRequestingValue.current = requesting
  }, [requesting])

  useEffect(() => {
    validateParams()
  }, [validateParams])

  return <>
    <PanelContent>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <GameTaskStepForm
            gameTaskStep={form.values}
            handleChange={form.handleChange}
            setFieldTouched={form.setFieldTouched}
            setFieldValue={form.setFieldValue}
            errors={form.errors}
            stepParamsErrors={stepParamsErrors}
            isUpdating={isUpdating}
            resetCounter={resetCounter}
          />
        </Grid>
      </Grid>
    </PanelContent>
    <PanelFooter>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box className={classes.footerContent}>
            <Button
              size="large"
              className={classes.cancelBtn}
              onClick={(): void => {
                form.resetForm()
                setResetCounter(resetCounter + 1)
              }}
              variant="contained"
              disabled={requesting}
            >
              Reset
            </Button>
            <Box>
              <Button
                size="large"
                className={classes.cancelBtn}
                onClick={onCancel}
                variant="contained"
                disabled={navigationDisabled || requesting}
                color="secondary"
              >
                Cancel
              </Button>
              <Button
                color="primary"
                size="large"
                onClick={form.submitForm}
                variant="contained"
                disabled={(form.dirty && !form.isValid) || requesting}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </PanelFooter>
  </>
}