import LeaderboardEndpoints from 'core/api/endpoints/leaderboards'
import { createAction, createAsyncAction } from 'core/redux/utils'
import { ActionTypes } from 'modules/leaderboards'
import { IAsyncActionParams } from 'types/common/api/models/entities/async-action-params'
import ILeaderboardPositionReward from 'types/modules/leaderboards/models/entities/leaderboard-position-reward'
import { ILeaderboardPositionRewardDraft } from 'types/modules/leaderboards/models/entities/leaderboard-position-reward-draft'
import { IAsyncAction, ISimpleAction } from 'types/redux/interfaces/IAction'

export interface ILeaderboardActionParams
  extends IAsyncActionParams<
    ILeaderboardPositionReward | ILeaderboardPositionRewardDraft | string
  > {
  leaderboardId?: string
  positionId?: string
}

export const setCurrentLeaderboardId: (
  params: ILeaderboardActionParams,
) => ISimpleAction = (params) =>
  createAction(ActionTypes.SET_CURRENT_LEADERBOARD_ID, params)

export const getLeaderboardPositionRewards: (
  params: ILeaderboardActionParams,
) => IAsyncAction = (params) =>
  createAsyncAction({
    type: ActionTypes.GET_LEADERBOARD_POSITION_REWARDS,
    endpoint: LeaderboardEndpoints.getLeaderboardPositionRewards,
    payload: params,
  })

export const createLeaderboardPositionReward: (
  params: ILeaderboardActionParams,
) => IAsyncAction = (params) =>
  createAsyncAction({
    type: ActionTypes.CREATE_LEADERBOARD_POSITION_REWARD,
    endpoint: LeaderboardEndpoints.createLeaderboardPositionReward,
    payload: params,
  })

export const updateLeaderboardPositionReward: (
  params: ILeaderboardActionParams,
) => IAsyncAction = (params) =>
  createAsyncAction({
    type: ActionTypes.UPDATE_LEADERBOARD_POSITION_REWARD,
    endpoint: LeaderboardEndpoints.updateLeaderboardPositionReward,
    payload: params,
  })
