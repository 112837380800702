import React, { PropsWithChildren } from 'react'
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

// @ts-ignore
import LogoImage from '../../public/images/logo.png'
// @ts-ignore
import BgImage from '../../public/images/bg2.jpg'

const useStyles = makeStyles(() => createStyles({
  container: {
    display: 'flex',
    height: '100vh',
    flex: 1,
    width: '100%',
  },
  imageContainer: {
    flex: 1,
    backgroundSize: 'contain',
    borderRight: '#E8CBC0 solid 1px'
  },

  box: {
    width: 500,
    height: '100%',
    textAlign: 'center',
    borderWidth: 1,
    paddingRight: 30,
    paddingLeft: 30,
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center'
  },

  innerBox: {
    padding: 40,
    backgroundColor: '#f9f9f9',
    borderRadius: 16,
    marginTop: -40
  },

  header: {
    color: 'gray',
    fontSize: 40,
    marginTop: 65,
    marginBottom: 20,
    fontFamily: 'Mukta'
  },

  logo: {
    width: 80,
    height: 80,
    backgroundSize: 'contain',
    margin: '0 auto',
    marginBottom: 30,
  },

  fearNot: {
    color: 'red'
  }
})
);

const AccountLayout: React.FC<PropsWithChildren<{}>> = (
  props: PropsWithChildren<{}>,
) => {

  const styles = useStyles();

  return (
    <div className={styles.container}>
      <div className={styles.imageContainer} style={{ backgroundImage: `url(${BgImage})` }}>
      </div>
      <div className={styles.box}>
        <div className={styles.innerBox}>
          <div className={styles.header}>
            <div
              className={styles.logo}
              style={{ backgroundImage: `url(${LogoImage})` }}
            />
            <span>Admin</span>
          </div>
          {props.children}
        </div>
      </div>
    </div>
  )
}

export default AccountLayout
