import { Box, SvgIcon, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx'
import { StarIcon, CoinIcon, HeartIcon, SparklesIcon } from 'components/icons'
import {
  IsNullOrUndefined,
  IsNullUndefinedOrEmpty,
} from 'core/utils/isNullOrUndefined'
import React from 'react'
import { IEvent } from 'types/modules/events/models/entities/event'
import { EventStatus } from 'types/modules/events/enums/event-status'
import moment from 'moment-timezone'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    eventCoverContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: '100%',
      height: 450,
      borderRadius: 12,
      padding: 15,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
    },
    eventCoverTopContainer: {
      display: 'flex',
      width: '100%',
      height: 'auto',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    typeContainer: {
      display: 'flex',
      background: '#FFFFFF',
      borderRadius: 3,
      padding: '0.2rem 0.5rem',
      height: 'max-content',
    },
    eventCoverReward: {
      display: 'flex',
      background: '#FFFFFF',
      borderRadius: 3,
      padding: '0.2rem',
      flexDirection: 'column',
    },
    rewardCurrency: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      minHeight: 15,
      justifyContent: 'center',
      alignItems: 'center',
    },
    coinIcon: {
      display: 'flex',
      width: 19,
      height: 15,
      marginRight: 2,
      '&:last-child': {
        marginRight: 0,
      },
    },
    starIcon: {
      display: 'flex',
      width: 15,
      height: 15,
      marginRight: 2,
      '&:last-child': {
        marginRight: 0,
      },
    },
    starCount: {
      color: theme.palette.text.primary,
      fontSize: '0.8rem',
      fontWeight: 300,
      position: 'relative',
      top: 1,
      marginRight: '0.2rem',
    },
    typeIcon: {
      fontSize: '0.6rem',
      position: 'relative',
      top: 4,
      marginRight: 2,
    },
    typeLabel: {
      color: theme.palette.text.primary,
      fontSize: '0.8rem',
      fontWeight: 300,
      position: 'relative',
      top: 1,
    },
    eventCoverDetailsContainer: {
      display: 'flex',
      width: '100%',
      height: 'auto',
      padding: 20,
      background: '#FFFFFF',
      borderRadius: 12,
      flexDirection: 'column',
    },
    eventCoverDetailsTopLine: {
      display: 'flex',
      width: '100%',
      height: 'auto',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    },
    eventTitle: {
      fontSize: 20,
      lineHeight: 1.4,
      fontWeight: 600,
    },
    heartContainer: {
      display: 'flex',
      marginLeft: 10,
      fontSize: 25,
    },
    eventDescriptionContainer: {
      display: 'flex',
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    eventDescription: {
      fontSize: 16,
      color: '#939393',
      fontWeight: 600,
    },
    fakeButton: {
      display: 'flex',
      borderRadius: 10,
      width: '100%',
      height: 45,
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 10,
      background: '#3D65FF',
      '&.active': {
        background: '#2FEDBA',
      },
      '&.closed': {
        background: '#939393',
      },
    },
    buttonText: {
      fontSize: 16,
      textAlign: 'center',
      color: '#ffffff',
    },
  }),
)

export const EventFeedItem: React.FC<IEvent> = (event: IEvent) => {
  const classes = useStyles()

  const hasCoverImage = !IsNullOrUndefined(event.media?.imageUrl)
  const eventCoverContainerStyles: { [key: string]: string | number } = {}

  if (hasCoverImage) {
    eventCoverContainerStyles.backgroundImage = `url(${event.media!.imageUrl!})`
  } else {
    eventCoverContainerStyles.background = 'rgba(0,0,0,0.03)'
  }

  const renderButtonText = (): string => {
    switch (event.status) {
      case EventStatus.Active:
        return 'Active'
      case EventStatus.Closed:
        return 'See Results'
      default:
        return `Opening ${moment(event.start).format('MMMM do')}`
    }
  }

  return (
    <Box
      className={classes.eventCoverContainer}
      style={eventCoverContainerStyles}
    >
      <Box className={classes.eventCoverTopContainer}>
        <Box className={classes.typeContainer}>
          <Typography className={classes.typeIcon}>{`🎪`}</Typography>
          <Typography className={classes.typeLabel}>{'Event'}</Typography>
        </Box>
        <Box className={classes.eventCoverReward}>
          <Box className={classes.rewardCurrency}>
            <i className={classes.starIcon}>
              <StarIcon />
            </i>
            <i className={classes.coinIcon}>
              <CoinIcon />
            </i>
            <i className={classes.coinIcon}>🎁</i>
            <i className={classes.coinIcon}>📦</i>
            <i
              className={classes.coinIcon}
              style={{
                color: '#ff787d',
              }}
            >
              <SparklesIcon />
            </i>
          </Box>
        </Box>
      </Box>
      <Box className={classes.eventCoverDetailsContainer}>
        <Box className={classes.eventCoverDetailsTopLine}>
          <Typography className={classes.eventTitle}>{event.name}</Typography>
          <Box className={classes.heartContainer}>
            <HeartIcon />
          </Box>
        </Box>
        {!IsNullUndefinedOrEmpty(event.media?.description) && (
          <Box className={classes.eventDescriptionContainer}>
            <Typography className={classes.eventDescription}>
              {event.media?.description}
            </Typography>
          </Box>
        )}

        <Box
          className={clsx(
            classes.fakeButton,
            event.status === EventStatus.Active && 'active',
            event.status === EventStatus.Closed && 'closed',
          )}
        >
          <Typography className={classes.buttonText}>
            {renderButtonText()}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}
