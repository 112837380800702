import paths from 'core/api/endpoints/tags/paths'
import { useAuth } from 'core/authentication/hooks/use-auth'
import { AccessDenied } from 'core/routing'
import { ROUTE_CONFIG } from 'core/routing/config/routes'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import {
  getPortalDefaults,
  getPortalWarnings,
} from 'modules/app-settings/actions'
import React, { PropsWithChildren, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { RouteConfig } from 'types/common/routing/route-config'

export interface IExtendedRouteProps extends RouteProps {
  route: RouteConfig
}

const ExtendedRoute: React.FC<IExtendedRouteProps> = ({
  route,
  ...rest
}: IExtendedRouteProps) => {
  const auth = useAuth()

  const {
    layout: Layout,
    component: Component,
    requiredPermissions,
    isPublic,
  } = route

  const dispatch = useDispatch()
  const isAuthorized = (): boolean => {
    if (
      IsNullOrUndefined(requiredPermissions) ||
      requiredPermissions?.length === 0
    )
      return true

    return requiredPermissions!.every((permission) => {
      const userCan = auth.currentUser.can(permission)
      return userCan
    })
  }

  const isAuthed = isAuthorized()

  const renderComponent = (): JSX.Element => {
    const component =
      typeof Component === 'function' ? <Component /> : Component

    return isAuthorized() ? component : <AccessDenied />
  }

  useEffect(() => {
    if (isAuthed) {
      dispatch(getPortalDefaults())
      dispatch(getPortalWarnings())
    }
  }, [isAuthed])

  const renderLayout = (): JSX.Element => {
    return !Layout ? renderComponent() : <Layout>{renderComponent()}</Layout>
  }

  return (
    <Route
      {...rest}
      render={(props: Readonly<RouteProps>): React.ReactNode => {
        if (auth.isVerifyingLogin) {
          return null
        }

        if (isPublic || auth.isAuthenticated) {
          return renderLayout()
        } else {
          return (
            <Redirect
              to={{
                pathname: ROUTE_CONFIG.ACCOUNT_LOGIN.path,
                state: { from: props.location },
              }}
            />
          )
        }
      }}
    />
  )
}

export default ExtendedRoute
