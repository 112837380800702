import { useRouter } from 'core/routing/hooks/use-router'
import React, { PropsWithChildren, useState } from 'react'
import { Actions } from 'modules/posts'
import { AnyNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { IPostQuestion } from 'types/modules/posts/models/entities/post-question'
import { useDispatch } from 'react-redux'
import _ from 'core/utils/deepdash'
import { MediaUploadModel } from 'types/common/images/models/entities/image-upload-model'
import { mapMimeType } from 'core/utils/mime-mapper'
import { IEvent } from 'types/modules/events/models/entities/event'
import { useAuth } from 'core/authentication/hooks/use-auth'
import { IPostQuestionOption } from 'types/modules/posts/models/entities/post-question-option'
import { IPostQuestionAnswer } from 'types/modules/posts/models/entities/post-question-answer'
import { usePostListContext } from 'modules/posts/context/post-list'

export interface IPostQuestionsManager {
  currentQuestion: IPostQuestion | null
  updatePostQuestion: (question: IPostQuestion) => void
  setCurrentQuestion: (question: IPostQuestion) => void
  uploadQuestionImage: (
    postId: string,
    questionId: string,
    fileParams: MediaUploadModel,
  ) => void
  deleteQuestion: (postId: string, questionId: string) => void
  addQuestionAnswer: (
    option: IPostQuestionOption,
    questionId: string,
    postId: string,
  ) => void
  updateQuestionAnswer: (
    answerId: string,
    option: IPostQuestionOption,
    questionId: string,
    postId: string,
  ) => void
  updatePostQuestionWithVideoUrl: (
    questionId: string,
    videoUrl?: string | null,
  ) => void
  updatingQuestion: boolean
}

export const PostQuestionsManager = React.createContext<IPostQuestionsManager>(
  {} as IPostQuestionsManager,
)

interface IPostQuestionsManagerProps {
  event?: IEvent
}

export const PostQuestionsManagerProvider: React.FC<
  IPostQuestionsManagerProps
> = (props: PropsWithChildren<IPostQuestionsManagerProps>) => {
  const { currentUser } = useAuth()

  const { currentPost } = usePostListContext()

  const { routeParams, pushQueryParams } = useRouter()

  const { questionId } = routeParams

  const [updatingQuestion, setUpdatingQuestion] = useState(false)

  const currentQuestion = !AnyNullOrUndefined(currentPost, questionId)
    ? (currentPost?.questions || []).find((q) => q.id === questionId) ?? null
    : null

  const setCurrentQuestion = (question: IPostQuestion): void => {
    pushQueryParams({
      postId: question.postId,
      questionId: question.id,
    })
  }

  const dispatch = useDispatch()

  const updatePostQuestion = (question: IPostQuestion): void => {
    setUpdatingQuestion(true)
    const existingQuestion = (currentPost?.questions || []).find(
      (q) => question.id === q.id,
    )
    dispatch(
      Actions.saveQuestionAndOptions({
        postId: question.postId,
        questionId: question.id,
        item: {
          ...question,
          media: existingQuestion?.media || question.media,
        },
        promise: {
          onResolve: (): void => setUpdatingQuestion(false),
          onReject: (): void => setUpdatingQuestion(false),
        },
      }),
    )
  }

  const updatePostQuestionWithVideoUrl = (
    questionId: string,
    videoUrl?: string | null,
  ): void => {
    setUpdatingQuestion(true)
    const question = (currentPost?.questions || []).find(
      (question) => questionId === question.id,
    )
    dispatch(
      Actions.saveQuestionAndOptions({
        postId: currentPost?.id,
        questionId: question?.id,
        item: {
          ...question,
          media: {
            ...question?.media,
            videoUrl,
          },
        },
        promise: {
          onResolve: (): void => setUpdatingQuestion(false),
          onReject: (): void => setUpdatingQuestion(false),
        },
      }),
    )
  }

  const deleteQuestion = (postId: string, questionId: string): void => {
    dispatch(
      Actions.deleteQuestion({
        postId,
        questionId,
      }),
    )
  }

  const uploadQuestionImage = (
    postId: string,
    questionId: string,
    fileParams: MediaUploadModel,
  ): void => {
    dispatch(
      Actions.uploadQuestionImage({
        postId,
        questionId,
        item: fileParams.binaryData,
        extension: `image.${fileParams.fileExtension}`,
        overrideHeaders: {
          'content-type': mapMimeType(fileParams.fileExtension!),
          accept: '*/*',
        },
      }),
    )
  }

  const addQuestionAnswer = (
    option: IPostQuestionOption,
    questionId: string,
    postId: string,
  ): void => {
    const answer = {
      questionId,
      optionId: option.id,
      text: option.text,
      updatedBy: {
        name: currentUser.username,
        fromPortal: true,
        roles: currentUser.roles?.toArray() ?? [],
      },
    } as IPostQuestionAnswer

    new Promise((resolve, reject) => {
      dispatch(
        Actions.addAnswer({
          postId,
          questionId,
          optionId: option.id,
          item: answer,
          promise: {
            onResolve: resolve,
            onReject: reject,
          },
        }),
      )
    }).then(() => {
      dispatch(
        Actions.getPostById({
          postId,
        }),
      )
    })
  }

  const updateQuestionAnswer = (
    answerId: string,
    option: IPostQuestionOption,
    questionId: string,
    postId: string,
  ): void => {
    const answer = {
      id: answerId,
      questionId,
      optionId: option.id,
      text: option.text,
      updatedBy: {
        name: currentUser.username,
        fromPortal: true,
        roles: currentUser.roles?.toArray() ?? [],
      },
    } as IPostQuestionAnswer

    new Promise((resolve, reject) => {
      dispatch(
        Actions.updateAnswer({
          postId,
          questionId,
          optionId: option.id,
          answerId,
          item: answer,
          promise: {
            onResolve: resolve,
            onReject: reject,
          },
        }),
      )
    }).then(() => {
      dispatch(
        Actions.getPostById({
          postId,
        }),
      )
    })
  }

  const context: IPostQuestionsManager = {
    currentQuestion,
    setCurrentQuestion,
    updatePostQuestion,
    uploadQuestionImage,
    deleteQuestion,
    addQuestionAnswer,
    updateQuestionAnswer,
    updatingQuestion,
    updatePostQuestionWithVideoUrl,
  }

  return (
    <PostQuestionsManager.Provider value={context}>
      {props.children}
    </PostQuestionsManager.Provider>
  )
}

export const usePostQuestionsManager = (): IPostQuestionsManager =>
  React.useContext(PostQuestionsManager)
