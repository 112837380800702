import TagEndpoints from 'core/api/endpoints/tags'
import TagActionTypes from 'modules/tags/action-types'
import { createAction, createAsyncAction } from 'core/redux/utils'
import { Tag } from 'types/modules/tags/models/entities/tag'
import { IAsyncAction, ISimpleAction } from 'types/redux/interfaces/IAction'
import TagGroupEndpoints from 'core/api/endpoints/tag-groups'
import { TagGroup } from 'types/modules/tags/models/entities/tag-group'
import { IAsyncActionParams } from 'types/common/api/models/entities/async-action-params'
import { ITagPromotion } from 'types/modules/tags/models/entities/tag-promotion'

export interface ITagActionParams extends IAsyncActionParams<Tag> {
  tagId?: string
  level?: number
  tagGroupId?: string
  name?: string
}

export interface ITagPromotionActionsParams
  extends IAsyncActionParams<ITagPromotion> {
  tagIds?: string[]
  promotionEndDate?: string
}

export interface ITagGroupActionParams extends IAsyncActionParams<TagGroup> {
  tagGroupId?: string
}

// Tags
export const setCurrentTagId: (params: ITagActionParams) => ISimpleAction = (
  params,
) => createAction(TagActionTypes.SET_CURRENT_TAG_ID, params)

export const getTags: (params: ITagActionParams) => IAsyncAction = (params) =>
  createAsyncAction({ type: TagActionTypes.GET_TAGS, endpoint: TagEndpoints.getTags, payload: params })

export const getTagById: (params: ITagActionParams) => IAsyncAction = (
  params,
) =>
  createAsyncAction({
    type: TagActionTypes.GET_TAG_BY_ID,
    endpoint: TagEndpoints.getTagById,
    payload: params,
  })


export const createTag: (params: ITagActionParams) => IAsyncAction = (params) =>
  createAsyncAction({ type: TagActionTypes.CREATE_TAG, endpoint: TagEndpoints.createTag, payload: params })

export const createTagDraft: (params: ITagActionParams) => ISimpleAction = (
  params,
) => createAction(TagActionTypes.CREATE_TAG_DRAFT, params)

export const updateTag: (params: ITagActionParams) => IAsyncAction = (params) =>
  createAsyncAction({ type: TagActionTypes.UPDATE_TAG, endpoint: TagEndpoints.updateTag, payload: params})

export const initialAllPostTags: (params: ITagActionParams) => IAsyncAction = (
  params,
) =>
  createAsyncAction({
    type: TagActionTypes.INITIAL_ALL_POST_TAGS,
    endpoint: TagEndpoints
.initialAllPostTags,
    payload: params,
  })


export const deleteTagById: (params: ITagActionParams) => IAsyncAction = (
  params,
) =>
  createAsyncAction({
    type: TagActionTypes.DELETE_TAG_BY_ID,
    endpoint: TagEndpoints
.deleteTagById,
    payload: params,
  })


export const getAutocompleteSearchTags: <TActionParamsType = ITagActionParams>(
  params: TActionParamsType,
) => IAsyncAction = (params) =>
  createAsyncAction({
    type: TagActionTypes.GET_AUTOCOMPLETE_SEARCH_TAGS,
    endpoint: TagEndpoints
.getTags,
    payload: params,
  })


// Tag Groups
export const getTagGroups: (params: ITagActionParams) => IAsyncAction = (
  params,
) =>
  createAsyncAction({
    type: TagActionTypes.GET_TAG_GROUPS,
    endpoint: TagGroupEndpoints.getTagGroups,
    payload: params,
  })


export const getTagGroupById: (params: ITagActionParams) => IAsyncAction = (
  params,
) =>
  createAsyncAction({
    type: TagActionTypes.GET_TAG_GROUP_BY_ID,
    endpoint: TagGroupEndpoints.getTagGroupById,
    payload: params,
  })


export const createTagGroup: (params: ITagActionParams) => IAsyncAction = (
  params,
) =>
  createAsyncAction({
    type: TagActionTypes.CREATE_TAG_GROUP,
    endpoint: TagGroupEndpoints.createTagGroup,
    payload: params,
  })


export const updateTagGroup: (params: ITagActionParams) => IAsyncAction = (
  params,
) =>
  createAsyncAction({
    type: TagActionTypes.UPDATE_TAG_GROUP,
    endpoint: TagGroupEndpoints.updateTagGroup,
    payload: params,
  })


export const getAutocompleteSearchTagGroups: <
  TActionParamsType = ITagActionParams,
>(
  params: TActionParamsType,
) => IAsyncAction = (params) =>
  createAsyncAction({
    type: TagActionTypes.GET_AUTOCOMPLETE_SEARCH_TAG_GROUPS,
    endpoint: TagGroupEndpoints.getTagGroups,
    payload: params,
  })


export const getTagPromotion: () => IAsyncAction = () =>
  createAsyncAction({
    type: TagActionTypes.GET_TAG_PROMOTION,
    endpoint: TagEndpoints.getTagPromotion,
    payload: null,
  })

export const updateTagPromotion: (
  params: ITagPromotionActionsParams,
) => IAsyncAction = (params) =>
  createAsyncAction({
    type: TagActionTypes.UPDATE_TAG_PROMOTION,
    endpoint: TagEndpoints
.updateTagPromotion,
    payload: params,
  })


export const getTagSuggestion: () => IAsyncAction = () =>
  createAsyncAction({
    type: TagActionTypes.GET_TAG_SUGGESTION,
    endpoint: TagEndpoints.getTagSuggestion,
    payload: null,
  })

export const updateTagSuggestion: (
  params: IAsyncActionParams<string>,
) => IAsyncAction = (params) =>
  createAsyncAction({
    type: TagActionTypes.UPDATE_TAG_SUGGESTION,
    endpoint: TagEndpoints.updateTagSuggestion,
    payload: params,
  })


export const uploadTagImage: (params: ITagActionParams) => IAsyncAction = (
  params,
) =>
  createAsyncAction({
    type: TagActionTypes.UPLOAD_TAG_IMAGE,
    endpoint: TagEndpoints.uploadTagImage,
    payload: params,
  })
