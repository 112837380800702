import React, { PropsWithChildren } from 'react'
import { AuthContext } from 'core/authentication/context/auth-context'
import { useProvideAuth } from 'core/authentication/hooks/use-provide-auth'

export const AuthProvider: React.FC<React.ReactNode> = (
  props: PropsWithChildren<React.ReactNode>,
) => {
  const auth = useProvideAuth()

  return (
    <AuthContext.Provider value={auth}>{props.children}</AuthContext.Provider>
  )
}
