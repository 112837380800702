import { RequestMethod } from 'types/common/api/enums/request-method'
import paths from './paths'
import params from './params'
import filters from './filters'

const NewsCardEndpoints = {
  getNewsCards: {
    method: RequestMethod.GET,
    path: [paths.newsCards],
    filters,
  },
  getNewsCardById: {
    method: RequestMethod.GET,
    path: [paths.newsCards, params.newsCardId],
  },
  createNewsCard: {
    method: RequestMethod.POST,
    path: [paths.newsCards],
  },
  updateNewsCard: {
    method: RequestMethod.PUT,
    path: [paths.newsCards, params.newsCardId],
  },
  deleteNewsCardById: {
    method: RequestMethod.DELETE,
    path: [paths.newsCards, params.newsCardId],
  },

  uploadNewsCardImage: {
    method: RequestMethod.PUT,
    path: [paths.newsCards, params.newsCardId, paths.images, paths.upload],
  },
}

export default NewsCardEndpoints
