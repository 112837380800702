export enum LiveScheduleField {
  ID = 'id',
  NAME = 'name',
  DESCRIPTION = 'description',
  XP_MULTIPLIER = 'xpMultiplier',
  TIMEZONE = 'timezone',
  START = 'start',
  END = 'end',
  EVENT_ID = 'eventId',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
  LIVE = 'live',
}
