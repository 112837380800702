import Axios from 'axios'
import MediaItemEndpoints from 'core/api/endpoints/media-items'
import { buildRequestUrl } from 'core/api/utils/build-request-url'
import { readFile } from 'modules/media-items/media-item-input/helper'
import { useRef, useState } from 'react'
import { FileUploadModel } from 'types/common/images/models/entities/image-upload-model'

interface IVideoParams {
  onFail: () => void
  onSuccess: (url: string) => void
}

interface IReturn {
  handleFileChange: (file: File) => Promise<void>
  uploadMediaItemVideo: (value: IVideoParams) => void,
  videoSource: string
  originalVideo: FileUploadModel | undefined
  clearVideo: () => void,
}

export const useVideoUpload = (): IReturn => {
  const [videoSource, setVideoSource] = useState('')
  const [presignedUrl, setPresignedUrl] = useState('')
  const [finalUrl, setFinalUrl] = useState('')
  const [fileType, setFileType] = useState('')
  const originalVideo = useRef<FileUploadModel | undefined>()

  const handleFileChange = async (fileToUpload: File): Promise<void> => {
      if (fileType !== fileToUpload.type) {
        const url = `${buildRequestUrl({
          payload: {},
          path: MediaItemEndpoints.createPresignedUrl.path
        })}?mimeType=${fileToUpload.type}`
        const { data } = (await Axios.post(url)) || {}
        setPresignedUrl(data.presignedUrl)
        // @ts-ignore
        setFinalUrl(data.finalUrl)
      }
      setVideoSource(URL.createObjectURL(fileToUpload))
      // @ts-ignore
      setFileType(fileToUpload.type)
      const videoDetails = await readFile(fileToUpload, true)
      originalVideo.current = videoDetails
  }

  const uploadMediaItemVideo = async ({
    onFail,
    onSuccess
  }: IVideoParams): Promise<void> => {
    const auth = Axios.defaults.headers.common.Authorization
    try {
      const arrayBuffer = originalVideo.current?.compressedUrl
      delete Axios.defaults.headers.common.Authorization
      await Axios.put(
        presignedUrl,
        Buffer.from(arrayBuffer),
        {
          headers: {
            'content-type': fileType,
            accept: '*/*',
          }
        }
      )
      Axios.defaults.headers.common.Authorization = auth
      onSuccess(finalUrl)
    } catch (e) {
      Axios.defaults.headers.common.Authorization = auth
      onFail()
    }
  }

  const clearVideo = (): void => {
    setVideoSource('')
    setPresignedUrl('')
    setFileType('')
    setFinalUrl('')
    originalVideo.current = undefined
  }

  return {
    handleFileChange,
    uploadMediaItemVideo,
    videoSource,
    originalVideo: originalVideo.current,
    clearVideo,
  }
}