import React from 'react'
import { RequestContextProvider } from 'core/api/context'
import { MilestonesProvider } from 'modules/milestones/context/milestone-context'
import { MilestoneList } from 'modules/milestones/view/milestones-list'

export const MilestoneDashboard: React.FC<{}> = () => {
  return (
    <RequestContextProvider>
      <MilestonesProvider>
        <MilestoneList />
      </MilestonesProvider>
    </RequestContextProvider>
  )
}
