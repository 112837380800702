import React from 'react'
import { Box, Chip, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment-timezone'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import clsx from 'clsx'
import {
  ImageIcon,
  InstantPurchaseIcon,
  PersonalityTestIcon,
  PollsIcon,
  SweepstakeIcon,
  TimedIcon,
  WatchlistIcon,
} from 'components/icons'
import { Tag } from 'types/modules/tags/models/entities/tag'
import { PrizeActionButtonType } from 'types/modules/prizes/enums/prize-action-button-type'
import { PrizeActionButtons } from 'modules/prizes/views/components/action-buttons'
import { IPrize } from 'types/modules/prizes/models/entities/prize'
import { PrizeType } from 'fe-shared-resources'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    prizeListCard: {
      display: 'flex',
      width: '100%',
      marginBottom: '0.625rem',
      flexDirection: 'row',
      boxShadow: 'none',
      border: '1px solid rgba(0,0,0,0.12)',
      borderRadius: 4,
      '&:last-child': {
        marginBottom: 'calc(48px + 0.625rem)',
      },
    },
    actionArea: {
      display: 'flex',
    },
    iconIndicatorContainer: {
      padding: 7,
      display: 'flex',
      width: 40,
      borderRight: '1px solid rgba(0,0,0,0.12)',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    iconIndicator: {
      display: 'flex',
      width: 24,
      height: 24,
      alignItems: 'center',
      color: 'rgba(0,0,0,0.54)',
    },
    prizeContent: {
      padding: '0.625rem',
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
    },
    coverImageContainer: {
      display: 'flex',
      width: 80,
      height: 80,
      background: 'rgba(0,0,0,0.03)',
      borderRadius: 90,
      border: '1px solid rgba(0,0,0,0.12)',
      overflow: 'hidden',
      marginRight: '0.625rem',
      justifyContent: 'center',
      alignItems: 'center',
    },
    coverImage: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
    },
    prizeDetails: {
      display: 'flex',
      flexGrow: 1,
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    actionsAndMetadata: {
      display: 'flex',
      flexShrink: 1,
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    actions: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      '& .MuiButton-root': {
        marginRight: '0.625rem',
        '&:last-of-type': {
          marginRight: 0,
        },
      },
    },
    metadata: {
      display: 'flex',
      flexDirection: 'row-reverse',
    },
    metadataItem: {
      marginLeft: '1.25rem',
    },
    buttonWithIcon: {
      '& .MuiButton-label': {
        position: 'relative',
        top: '.1em',
        '& .MuiButton-startIcon': {
          position: 'relative',
          top: '-.1em',
        },
      },
    },
  }),
)

export const PrizeListItem: React.FC<IPrize> = (prize: IPrize) => {
  const classes = useStyles()

  const renderCoverImage = (): JSX.Element => {
    return prize.item?.details?.imageUrl ? (
      <img src={prize.item?.details?.imageUrl} className={classes.coverImage} />
    ) : (
      <Box className={classes.coverImage}>
        <ImageIcon />
      </Box>
    )
  }

  const renderStartClose = (): string => {
    const dateFormat = 'D MMMM YYYY HH:mm'
    let result = moment(prize.start).format(dateFormat)
    if (!IsNullOrUndefined(prize.end)) {
      result += ` - ${moment(prize.end).format(dateFormat)}`
    }
    return result
  }

  const renderIconIndicator = (): JSX.Element | null => {
    switch (prize.type) {
      case PrizeType.Instant:
        return (
          <Box className={classes.iconIndicator}>
            <InstantPurchaseIcon />
          </Box>
        )
      case PrizeType.Sweepstake:
        return (
          <Box className={classes.iconIndicator}>
            <SweepstakeIcon />
          </Box>
        )
      default:
        return null
    }
  }

  const renderMetadata = (): JSX.Element => {
    return (
      <React.Fragment>
        {prize.type === PrizeType.Instant && (
          <React.Fragment>
            <Box className={classes.metadataItem}>
              <Typography variant="body2" color="textPrimary" component="span">
                {'Remaining = '}
              </Typography>
              <Typography variant="h6" color="textPrimary" component="span">
                {prize.item.quantity}
              </Typography>
            </Box>
            <Box className={classes.metadataItem}>
              <Typography variant="body2" color="textPrimary" component="span">
                {'Shipped = '}
              </Typography>
              <Typography variant="h6" color="textPrimary" component="span">
                {prize.item.shipped}
              </Typography>
            </Box>
          </React.Fragment>
        )}
        {prize.type === PrizeType.Sweepstake && (
          <Box className={classes.metadataItem}>
            <Typography variant="body2" color="textPrimary" component="span">
              {'Entries = '}
            </Typography>
            <Typography variant="h6" color="textPrimary" component="span">
              {`${prize.currentEntries} / ${prize.requiredEntries}`}
            </Typography>
          </Box>
        )}
      </React.Fragment>
    )
  }

  return (
    <Box className={classes.prizeListCard}>
      <Box className={classes.iconIndicatorContainer}>
        {renderIconIndicator()}
      </Box>
      <Box className={classes.prizeContent}>
        <Box className={classes.coverImageContainer}>{renderCoverImage()}</Box>
        <Box className={classes.prizeDetails}>
          {!!prize.item?.details?.brand && (
            <Typography
              variant="subtitle2"
              color="textPrimary"
              component="span"
            >
              {prize.item?.details?.brand }
            </Typography>
          )}
          <Typography variant="subtitle2" color="textPrimary" component="span">
            {`${prize.item?.name}${
              !IsNullOrUndefined(prize.item?.details?.description)
                ? ` | ${prize.item?.details?.description}`
                : ''
            }`}
          </Typography>
          <Typography variant="body2" color="textPrimary" component="span">
            {renderStartClose()}
          </Typography>
        </Box>
        <Box className={classes.actionsAndMetadata}>
          <Box className={classes.actions}>
            <PrizeActionButtons
              prize={prize}
              exclude={[
                PrizeActionButtonType.Duplicate,
                PrizeActionButtonType.Approve,
              ]}
            />
          </Box>
          <Box className={classes.metadata}>{renderMetadata()}</Box>
        </Box>
      </Box>
    </Box>
  )
}
