import {
  Box,
  Button,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { TagSubscribeIcon } from 'components/icons'
import { MediaUploadModel } from 'types/common/images/models/entities/image-upload-model'
import { pushNewItemToFieldArray } from 'core/api/utils/formik-array-helpers'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { IQuestionFormProps } from 'modules/posts/views/components/manage-post-panel/types/question-form-props'
import React, { useMemo, useState } from 'react'
import PostType from 'types/modules/posts/enums/post-type'
import { QuestionField } from 'types/modules/posts/enums/question-field'
import _ from 'core/utils/deepdash'
import { MediaOrientation } from 'types/common/images/enum/media-orientation'
import { MediaItem } from 'types/modules/media-items/models/entities/media-item'
import { AspectRatio } from 'types/common/images/enum/aspect-ratio'
import { LabelWithCharLimit } from 'modules/posts/views/components/manage-post-panel/label-with-char-limit'
import { MAX_TITLE_LENGTH } from 'modules/posts/constants'
import { MediaItemInput } from 'modules/media-items/media-item-input'
import { QuestionViewOptions } from 'modules/posts/views/components/manage-post-panel/question-view-options'
import { sortQuestionOptionsByOrder } from 'utils/common'
import { useCurrentQuestion } from 'hooks/use-current-question'
import { usePostQuestionsManager } from 'modules/posts/context/post-questions-manager'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    postQuestionForm: {
      width: '100%',
      height: 'auto',
      '& .MuiGrid-item': {
        display: 'flex',
        flexDirection: 'column',
      },
    },
    inputLabel: {
      display: 'flex',
      marginBottom: '0.625rem',
      justifyContent: 'space-between',
    },
    lengthHintText: {
      marginLeft: 8,
      color: 'rgb(181 166 166)',
      fontSize: 12,
      marginTop: 3,
    },
    imageContainer: {
      display: 'flex',
      flex: 1,
      width: '100%',
      height: '100%',
      background: 'rgba(0,0,0,0.03)',
      color: 'rgba(0,0,0,0.54)',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      justifyContent: 'center',
      alignItems: 'center',
    },
    formSection: {
      marginBottom: '1.25rem',
    },
    addOptionButton: {
      color: '#FFFFFF',
      backgroundColor: theme.palette.primary.main,
      padding: '8px 16px',
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    videoContainer: {
      marginTop: 8,
    },
  }),
)

export const QuestionView: React.FC<IQuestionFormProps> = (
  props: IQuestionFormProps,
) => {
  const {
    uploadQuestionImage,
    updatePostQuestion,
    updatePostQuestionWithVideoUrl,
    updatingQuestion,
  } = usePostQuestionsManager()
  const [correctOptionId, setCorrectOptionId] = useState('')
  const {
    post,
    question,
    handleChange,
    setFieldValue,
    setFieldTouched,
    errors,
  } = props

  const { media: currentQuestionMedia } = useCurrentQuestion(question.id)

  const hasImage = !IsNullOrUndefined(question.media?.imageUrl)
  const imageContainerStyles: { [key: string]: string | number } = {}

  if (hasImage) {
    imageContainerStyles.backgroundImage = `url(${question.media!.imageUrl!})`
  } else {
    imageContainerStyles.background = 'rgba(0,0,0,0.03)'
  }

  const questionNumber =
    post.questions.findIndex((q) => q.id === question.id) + 1

  const createNewOption = (): void => {
    const newOption = {
      questionId: question.id,
      text: '',
      order: question.options.length,
      correct: false,
      temporaryId: Date.now(),
    }

    pushNewItemToFieldArray(newOption, question.options, (newValues) => {
      newValues = newValues.map((val) => {
        val.probability = 1 / newValues.length
        return val
      })

      setFieldValue(QuestionField.OPTIONS, newValues)
    })
  }

  const setCorrectAnswer = (optionId: string | number): void => {
    const options = _.clone(question[QuestionField.OPTIONS])
    setCorrectOptionId(`${optionId}`)
    setFieldValue(
      QuestionField.OPTIONS,
      options.map((option, index) => {
        const newValue = _.clone(option)
        newValue.correct = `${option.id || option.temporaryId}` === optionId
        setFieldTouched?.(`${QuestionField.OPTIONS}[${index}]`, true)
        return newValue
      }),
    )
  }

  const correctOption = question.options.find((option) => option.correct)
  const sortedOptions = useMemo(() => {
    return sortQuestionOptionsByOrder(question.options)
  }, [question.options])
  const classes = useStyles()

  return (
    <Box className={classes.postQuestionForm}>
      <Typography
        variant="subtitle1"
        color="textPrimary"
        style={{ marginBottom: '1.25rem' }}
      >
        {`Question ${questionNumber}`}
      </Typography>
      <Grid container spacing={2} className={classes.formSection}>
        <Grid item xs={3}>
          <InputLabel className={classes.inputLabel}>Image</InputLabel>
          <MediaItemInput
            url={currentQuestionMedia?.imageUrl}
            imageLabel={'Question Image'}
            mediaItemId={currentQuestionMedia?.mediaItemId}
            options={{
              aspectRatio: AspectRatio.Square,
              orientation: MediaOrientation.Portrait,
            }}
            onComplete={(fileParams: MediaUploadModel): void => {
              uploadQuestionImage(post.id, question.id, fileParams)
            }}
            onCreate={(mediaItem: MediaItem): void => {
              const newQuestion = _.cloneDeep(question)
              newQuestion.media = {
                ...(newQuestion.media ?? {}),
                mediaItemId: mediaItem.id,
              }
              updatePostQuestion(newQuestion)
            }}
          />
        </Grid>

        <Grid item xs={9}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <LabelWithCharLimit
                htmlFor={QuestionField.TEXT}
                label="Title"
                valueLength={question[QuestionField.TEXT].length}
                limit={MAX_TITLE_LENGTH}
              />
              <TextField
                id={QuestionField.TEXT}
                name={QuestionField.TEXT}
                onChange={handleChange as React.ChangeEventHandler<HTMLInputElement>}
                value={question[QuestionField.TEXT]}
                multiline
                rows={2}
                variant="filled"
                fullWidth
                error={!!errors?.[QuestionField.TEXT]}
                helperText={errors?.[QuestionField.TEXT]}
                inputProps={{
                  maxLength: MAX_TITLE_LENGTH,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <InputLabel className={classes.inputLabel}>Video</InputLabel>
          <Box>
            <MediaItemInput
              url={currentQuestionMedia?.videoUrl}
              isVideo
              imageLabel="Question Video"
              mediaItemId={currentQuestionMedia?.mediaItemId}
              options={{
                aspectRatio: AspectRatio.Square,
                orientation: MediaOrientation.Portrait,
              }}
              onComplete={(fileParams: MediaUploadModel): void => {
                updatePostQuestionWithVideoUrl(
                  question.id,
                  fileParams.videoFinalUrl,
                )
              }}
              removeAttachment={(): void => {
                updatePostQuestionWithVideoUrl(question.id, null)
              }}
              onCreate={(): void => {}}
              accept="video/*"
              button
              buttonLabel={currentQuestionMedia?.videoUrl || 'Add video'}
              hasAttachment={!!currentQuestionMedia?.videoUrl}
              loading={updatingQuestion}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2} className={classes.formSection}>
        <Grid item xs={12}>
          <Button
            onClick={createNewOption}
            fullWidth
            variant="contained"
            className={classes.addOptionButton}
            startIcon={<TagSubscribeIcon />}
          >
            Add Option
          </Button>
        </Grid>
      </Grid>
      {question.options.length > 0 && post.type !== PostType.Test && (
        <Grid container spacing={2} className={classes.formSection}>
          <Grid item xs={post.type === PostType.Game ? 10 : 12}>
            <InputLabel className={classes.inputLabel}>
              Correct Answer
            </InputLabel>
            <Select
              value={`${correctOption?.id || correctOption?.temporaryId || ''}`}
              onChange={(event): void => {
                // @ts-expect-error todo
                setCorrectAnswer(event.target.value as number)
              }
              }
              variant="filled"
              displayEmpty
              fullWidth
            >
              <MenuItem key={`answer-none`} value={''}>
                No Answer
              </MenuItem>
              {sortedOptions.map((option) => (
                <MenuItem
                  key={`answer-option-${option.id || option.temporaryId}`}
                  value={`${option.id || option.temporaryId}`}
                >
                  {option.text}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          {post.type === PostType.Game && (
            <Grid item xs={2}>
              <InputLabel
                className={classes.inputLabel}
                htmlFor={QuestionField.TIMER_SECONDS}
              >
                Timer (s)
              </InputLabel>
              <TextField
                id={QuestionField.TIMER_SECONDS}
                name={QuestionField.TIMER_SECONDS}
                value={question[QuestionField.TIMER_SECONDS]}
                onChange={handleChange as React.ChangeEventHandler<HTMLInputElement>}
                variant="filled"
                type="number"
                fullWidth
                InputProps={{
                  inputProps: {
                    min: 0,
                  },
                }}
              />
            </Grid>
          )}
        </Grid>
      )}
      <QuestionViewOptions {...props} />
    </Box>
  )
}
