import React from 'react'
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Box, Button, ButtonProps, Grid } from '@mui/material'
import { NIL as NIL_UUID } from 'uuid'
import { PanelContent, PanelFooter } from 'components/panel'
import _ from 'core/utils/deepdash'
import { useFormik } from 'formik'
import { colors } from 'utils/colors'
import { useCampaignsContext } from 'modules/campaigns/context/campaign-context'
import { ICampaign } from 'modules/campaigns/models/campaign'
import { CampaignForm } from 'modules/campaigns/view/campaign-modal/campaign-form'
import { CampaignSchema } from 'modules/campaigns/validation/campaign-schema'
import { ModalWrapper } from 'components/modal-wrapper'
import { ConfirmationDialog } from 'components/confirmation-dialog'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
    },
    panelHeader: {
      display: 'flex',
      width: '100%',
      flexShrink: 0,
    },
    panelTitle: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      padding: '0 0 0 1.25rem',
    },
    navigationContainer: {
      display: 'flex',
      flex: 2,
      flexGrow: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    rightHeaderSection: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      padding: '0 1.25rem 0 0',
      flexDirection: 'row-reverse',
      '& .MuiButton-contained': {
        marginRight: 20,
      },
    },
    footerContent: {
      display: 'flex',
      flex: 1,
      justifyContent: 'flex-end',
    },
    cancelBtn: {
      marginRight: '1.25rem',
    },
    wrapper: {
      borderLeft: `1px solid ${colors.rgba_0_0_0_12}`,
    },
    form: {
      height: '600px',
      overflow: 'auto',
    },
  }),
)

const buttonProps: ButtonProps = {
  variant: 'contained',
  size: 'large',
}

export const CampaignModal: React.FC = () => {
  const classes = useStyles()
  const {
    currentCampaign,
    updateCampaign,
    createCampaign,
    clearCurrentCampaign,
    deleteCampaign
  } = useCampaignsContext()
  const isUpdating = currentCampaign?.id !== NIL_UUID

  const form = useFormik({
    enableReinitialize: isUpdating,
    validationSchema: CampaignSchema,
    initialValues: {
      id: currentCampaign?.id ?? '',
      name: currentCampaign?.name || '',
      items: currentCampaign?.items || [],
    },
    onSubmit: (value: ICampaign) => {
      const recampaign = isUpdating ? updateCampaign : createCampaign
      recampaign(value)
    },
  })

  return (
    <ModalWrapper
      panelSize={8}
      closeModal={clearCurrentCampaign}
      title={isUpdating ? `Edit ${currentCampaign?.name}` : 'Create Campaign'}
      open={!!currentCampaign}
    >
      <>
        <PanelContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <CampaignForm
                campaign={form.values}
                handleChange={form.handleChange}
                setFieldTouched={form.setFieldTouched}
                setFieldValue={form.setFieldValue}
                errors={form.errors}
                isUpdating={isUpdating}
              />
            </Grid>
          </Grid>
        </PanelContent>
        <PanelFooter>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box className={classes.footerContent}>
                <Box>
                  <Button
                    size="large"
                    className={classes.cancelBtn}
                    onClick={clearCurrentCampaign}
                    variant="contained"
                    color="secondary"
                  >
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    size="large"
                    onClick={form.submitForm}
                    variant="contained"
                    disabled={form.dirty && !form.isValid}
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </PanelFooter>
      </>
    </ModalWrapper>
  )
}
