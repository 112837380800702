import { MobileAppUserFieldName } from 'modules/mobile-app-users/enums/mobile-app-user-field'

const filters = {
  [MobileAppUserFieldName.PREFERRED_USERNAME]: '{{preferredUsername}}',
  [MobileAppUserFieldName.GIVEN_NAME]: '{{givenName}}',
  [MobileAppUserFieldName.FAMILY_NAME]: '{{familyName}}',
  [MobileAppUserFieldName.PHONE_NUMBER]: '{{phoneNumber}}',
  [MobileAppUserFieldName.EMAIL]: '{{email}}',
  [MobileAppUserFieldName.USERNAME]: '{{username}}',
}

export default filters
