import Calendar from 'components/calendar'
import { CalendarViewMode } from 'components/calendar/types/enums/calendar-view-mode'
import { getMonthDates, getWeekDates } from 'components/calendar/utils'
import { useRequestContext } from 'core/api/context'
import moment from 'moment-timezone'
import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react'
import { FilterType } from 'types/common/filtering/enums/FilterType'
import { IFilter } from 'types/common/filtering/models/entities/IFilter'

interface ICalendarContext {
  viewMode: CalendarViewMode
  changeViewMode: (mode: CalendarViewMode) => void
  visibleDates: string[]
  currentTimeFrameLabel: () => string
  goToDay: (inputDate: string) => void
  goToWeek: (inputDate: string) => void
  goToMonth: (inputDate: string) => void
  goToNext: () => void
  goToPrevious: () => void
}

const CalendarContext = React.createContext<ICalendarContext>(
  {} as ICalendarContext,
)

const useProvideCalendarContext = (): ICalendarContext => {
  const rc = useRequestContext()

  const dateRangeFilter = rc?.getFilterByField(rc?.sortValue.sortBy)

  const today = moment().utc().startOf('day').toISOString()

  const thisMonth = moment(today).startOf('month').toISOString()

  const [currentMonth, setCurrentMonth] = useState(thisMonth)

  const [viewMode, setViewMode] = useState(CalendarViewMode.Week)

  const [visibleDates, setVisibleDates] = useState(getWeekDates(today))

  const applyDateRangeFilter = (): void => {
    const [startDate, endDate] = [
      visibleDates[0],
      moment(visibleDates[visibleDates.length - 1])
        .endOf('day')
        .toISOString(),
    ]

    const newDateRangeFilter = {
      field: rc?.sortValue.sortBy,
      type: FilterType.DateRange,
      value: [startDate, endDate],
    } as IFilter

    if (rc?.active) {
      rc?.applyFilter(newDateRangeFilter)
    }
  }

  useEffect(() => {
    applyDateRangeFilter()
    rc?.setActive(true)

    return (): void => {
      rc?.removeFilter(rc?.sortValue.sortBy)
    }
  }, [])

  useEffect(() => {
    applyDateRangeFilter()
  }, [visibleDates, rc?.active, rc?.sortValue])

  const updateCurrentMonth = (inputDate: string): void => {
    setCurrentMonth(moment(inputDate).startOf('month').toISOString())
  }

  const goToDay = (inputDate: string): void => {
    const date = moment(inputDate).startOf('day').toISOString()
    updateCurrentMonth(date)
    setVisibleDates([date])
    if (viewMode !== CalendarViewMode.Day) {
      setViewMode(CalendarViewMode.Day)
    }
  }

  const goToWeek = (inputDate: string): void => {
    updateCurrentMonth(inputDate)
    setVisibleDates(getWeekDates(inputDate))
    if (viewMode !== CalendarViewMode.Week) {
      setViewMode(CalendarViewMode.Week)
    }
  }

  const goToMonth = (inputDate: string): void => {
    updateCurrentMonth(inputDate)
    setVisibleDates(getMonthDates(inputDate))
    if (viewMode !== CalendarViewMode.Month) {
      setViewMode(CalendarViewMode.Month)
    }
  }

  const goToNext = (): void => {
    switch (viewMode) {
      case CalendarViewMode.Day:
        {
          const nextDay = moment(visibleDates[0]).add(1, 'day')
          goToDay(nextDay.toISOString())
        }
        break

      case CalendarViewMode.Week:
        {
          const nextWeekStart = moment(visibleDates[0]).add(1, 'week')
          goToWeek(nextWeekStart.toISOString())
        }
        break

      case CalendarViewMode.Month: {
        const nextMonth = moment(currentMonth).add(1, 'month')
        goToMonth(nextMonth.toISOString())
      }
    }
  }

  const goToPrevious = (): void => {
    switch (viewMode) {
      case CalendarViewMode.Day:
        {
          const previousDay = moment(visibleDates[0]).subtract(1, 'day')
          goToDay(previousDay.toISOString())
        }
        break

      case CalendarViewMode.Week:
        {
          const previousWeekStart = moment(visibleDates[0]).subtract(1, 'week')
          goToWeek(previousWeekStart.toISOString())
        }
        break

      case CalendarViewMode.Month: {
        const previousMonth = moment(currentMonth).subtract(1, 'month')
        goToMonth(previousMonth.toISOString())
      }
    }
  }

  const changeViewMode = (mode: CalendarViewMode): void => {
    switch (mode) {
      case CalendarViewMode.Day:
        goToDay(today)
        break
      case CalendarViewMode.Week:
        goToWeek(today)
        break
      case CalendarViewMode.Month:
        goToMonth(today)
    }
  }

  const currentTimeFrameLabel = (): string => {
    switch (viewMode) {
      case CalendarViewMode.Day:
        return moment(visibleDates[0]).format('D MMMM YYYY')
      case CalendarViewMode.Week:
        return `Week of ${moment(visibleDates[0]).format('D MMM YYYY')}`
      case CalendarViewMode.Month:
        return moment(currentMonth).format('MMMM YYYY')
    }
  }

  return {
    viewMode,
    changeViewMode,
    visibleDates,
    currentTimeFrameLabel,
    goToDay,
    goToWeek,
    goToMonth,
    goToNext,
    goToPrevious,
  }
}

export const CalendarContextProvider: React.FC<PropsWithChildren<{}>> = (
  props: PropsWithChildren<{}>,
) => {
  const context = useProvideCalendarContext()

  return (
    <CalendarContext.Provider value={context}>
      {props.children}
    </CalendarContext.Provider>
  )
}

export const useCalendarContext = (): ICalendarContext =>
  useContext(CalendarContext)
