import MilestoneEndpoints from 'core/api/endpoints/milestones'
import MilestoneActionTypes from 'modules/milestones/action-types/milestone-action-types'
import { createAction, createAsyncAction } from 'core/redux/utils'
import { IAsyncAction, ISimpleAction } from 'types/redux/interfaces/IAction'
import { IAsyncActionParams } from 'types/common/api/models/entities/async-action-params'
import { IMilestone } from 'modules/milestones/models/milestone'
import { Version } from 'types/common/enums/versions'

export interface IMilestoneActionParams
  extends IAsyncActionParams<IMilestone> {
  milestoneId?: string
  milestoneLevelId?: string
}

export const setCurrentMilestoneId: (
  params: IMilestoneActionParams,
) => ISimpleAction = (params) =>
  createAction(MilestoneActionTypes.SET_CURRENT_MILESTONE_ID, params)

export const getMilestones: (
  params: IMilestoneActionParams,
) => IAsyncAction = (params) =>
  createAsyncAction({
    type: MilestoneActionTypes.GET_MILESTONES,
    endpoint: MilestoneEndpoints.getMilestones,
    payload: params,
    version: Version.V2
  })

export const getMilestoneById: (
  params: IMilestoneActionParams,
) => IAsyncAction = (params) =>
  createAsyncAction({
    type: MilestoneActionTypes.GET_MILESTONE_BY_ID,
    endpoint: MilestoneEndpoints.getMilestoneById,
    payload: params,
    version: Version.V2
  })

export const updateMilestone: (
  params: IMilestoneActionParams,
) => IAsyncAction = (params) =>
  createAsyncAction({
    type: MilestoneActionTypes.UPDATE_MILESTONE,
    endpoint: MilestoneEndpoints.updateMilestone,
    payload: params,
    version: Version.V2
  })
