import { IAddress } from 'types/modules/shipping/models/entities/address'
import { IAppUser } from 'types/modules/users/app/models/entities/app-user'

interface IGetUserInfoForShippingReturn {
  name: string,
  email: string
  phone: string
  username: string
}

interface IGetUserInfoForShippingParams {
  shippingInfo?: IAddress,
  userInfo?: IAppUser
}

export const getUserInfoForShipping = ({
  shippingInfo, userInfo
}: IGetUserInfoForShippingParams): IGetUserInfoForShippingReturn => {
  const name = !!userInfo?.familyName && !!userInfo?.givenName
    ? `${userInfo?.givenName} ${userInfo?.familyName}`
    : ''
  return {
    name: shippingInfo?.recipient || name,
    email: shippingInfo?.email || userInfo?.email || '',
    phone: userInfo?.phoneNumber || '',
    username: userInfo?.preferredUsername || ''
  }
}

export const getUserInfoForShippingSource = ({
  shippingInfo, userInfo
}: IGetUserInfoForShippingParams): IGetUserInfoForShippingReturn => {
  return {
    name: shippingInfo?.recipient ? 'textPrimary' : 'textSecondary',
    email: shippingInfo?.email ? 'textPrimary' : 'textSecondary',
    phone: 'textSecondary',
    username: 'textSecondary'
  }
}