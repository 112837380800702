import { ApiRequestParams } from 'types/common/api/models/entities/api-request-params'
import { EndpointRoute } from 'types/common/api/models/entities/endpoint-route'
import { IKeyValueStringCollection } from 'types/common/interfaces/IKeyValueStringCollection'
import { IPayload } from 'types/redux/interfaces/IPayload'
import { RequestMethod } from 'types/common/api/enums/request-method'

import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'

import { CaptureFilterParams } from 'core/api/utils/capture-filter-params'
import { buildRequestUrl } from 'core/api/utils/build-request-url'
import { CaptureSorts } from 'core/api/utils/capture-sorts'
import { Version } from 'types/common/enums/versions'

interface IBuildRequestParams {
  endpoint: EndpointRoute,
  payload: IPayload,
  version?: Version
}

export const BuildRequestParams = ({
  endpoint,
  payload,
  version = Version.V1
}: IBuildRequestParams): ApiRequestParams => {
  const { host, path, method, filters: availableFilters } = endpoint

  const requestParams: ApiRequestParams = {
    url: buildRequestUrl({payload, path, host, version }),
    method,
  }

  switch (method) {
    case RequestMethod.GET: {
      if (IsNullOrUndefined(payload)) break

      const params: IKeyValueStringCollection = {}

      const {
        filters: appliedFilters,
        sortBy,
        sortDirection,
        page,
        pageSize,
      } = payload

      if (!IsNullOrUndefined(page) && !IsNullOrUndefined(pageSize)) {
        params.page = page
        params.pageSize = pageSize
      }

      const sorts = CaptureSorts(sortBy, sortDirection)

      if (!IsNullOrUndefined(sorts)) {
        params.sorts = sorts!
      }

      const filterParams = CaptureFilterParams(availableFilters, appliedFilters)

      if (!IsNullOrUndefined(filterParams)) {
        params.filters = filterParams!
      }

      requestParams.params = params

      break
    }

    case RequestMethod.PUT:
    case RequestMethod.DELETE:
    case RequestMethod.POST: {
      const { item, items, overrideHeaders, queryParams } = payload

      if (!IsNullOrUndefined(overrideHeaders)) {
        requestParams.headers = overrideHeaders
      }

      if (!IsNullOrUndefined(item)) {
        requestParams.body = item
      }

      if (!IsNullOrUndefined(items)) {
        requestParams.body = items
      }

      if (!IsNullOrUndefined(queryParams)) {
        requestParams.params = queryParams
      }

      break
    }

    default:
      break
  }

  return requestParams
}
