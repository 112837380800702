import { Box, InputLabel } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react'

interface ITicketDetailsProps {
  label?: string
  value?: string | number
  children?: string | number | JSX.Element
}

const useStyles = makeStyles(() =>
  createStyles({
    label: {
      fontSize: 12,
    },
    details: {
      marginBottom: 8,
      marginTop: 8,
    },
  }),
)

export const DetailItem: React.FC<ITicketDetailsProps> = ({
  label,
  value,
  children,
}) => {
  const classes = useStyles()
  if (!value && !children) return null
  return (
    <Box key={label} className={classes.details}>
      <InputLabel className={classes.label}>{label}</InputLabel>
      <Box>{value || children}</Box>
    </Box>
  )
}
