import { Box, Grid, InputLabel, MenuItem, Select, TextField, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { ILiveScheduleFormProps } from 'modules/live-schedules/views/components/schedule-details-panel/types/live-schedule-form-props'
import React from 'react'
import { LiveScheduleField as FieldName } from 'types/modules/live-schedules/enums/live-schedule-field'
import moment from 'moment-timezone'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { createErrors } from 'utils/validation-helper'
import { getAdjustedEndDate } from 'utils/date-range'
import { Duration } from 'components/duration'
import { TIMEZONE_OPTIONS } from 'utils/constants'
import { useEventsContext } from 'modules/events/context'
import { DateTimePicker } from '@mui/x-date-pickers';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    liveScheduleForm: {
      width: '100%',
      height: 'auto',
      '& .MuiGrid-item': {
        display: 'flex',
        flexDirection: 'column',
      },
    },
    inputLabel: {
      display: 'flex',
      marginBottom: '0.625rem',
    },
    adornmentIcon: {
      display: 'flex',
      width: 24,
      height: 24,
      marginRight: 12,
    },
  }),
)

export const LiveScheduleForm: React.FC<ILiveScheduleFormProps> = (
  props: ILiveScheduleFormProps,
) => {
  const classes = useStyles()
  const { liveSchedule, handleChange, setFieldValue, errors, setValues } = props
  const { currentEvent } = useEventsContext()
  const formErrors = createErrors(errors)

  return (
    <Box className={classes.liveScheduleForm}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <InputLabel htmlFor={FieldName.NAME} className={classes.inputLabel}>
            Name
          </InputLabel>
          <TextField
            id={FieldName.NAME}
            name={FieldName.NAME}
            onChange={handleChange as React.ChangeEventHandler<HTMLInputElement>}
            value={liveSchedule[FieldName.NAME]}
            variant="filled"
            fullWidth
            {...formErrors[FieldName.NAME]}
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel
            htmlFor={FieldName.DESCRIPTION}
            className={classes.inputLabel}
          >
            Description
          </InputLabel>
          <TextField
            id={FieldName.DESCRIPTION}
            name={FieldName.DESCRIPTION}
            onChange={handleChange as React.ChangeEventHandler<HTMLInputElement>}
            value={liveSchedule[FieldName.DESCRIPTION]}
            variant="filled"
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <InputLabel htmlFor={FieldName.START} className={classes.inputLabel}>
            Start Date
          </InputLabel>
          <DateTimePicker
            value={moment(liveSchedule[FieldName.START])}
            onChange={(date): void => {
              if (!setValues) return
              const newValue = moment(date?.utc().toISOString()).toISOString()
              const adjustedEndDate = getAdjustedEndDate(
                newValue,
                liveSchedule[FieldName.START],
                liveSchedule[FieldName.END],
              )
              setValues({
                ...liveSchedule,
                [FieldName.END]: adjustedEndDate,
                [FieldName.START]: newValue,
              })
            }}
            format={'YYYY-MM-DD HH:mm'}
            {...formErrors[FieldName.START]}
          />
        </Grid>
        <Grid item xs={6}>
          <InputLabel htmlFor={FieldName.END} className={classes.inputLabel}>
            End Date
          </InputLabel>
          <DateTimePicker
            value={
              IsNullOrUndefined(liveSchedule[FieldName.END])
                ? null
                : moment(liveSchedule[FieldName.END])
            }
            onChange={(date): void => {
              setFieldValue(
                FieldName.END,
                moment(date?.utc().toISOString()).toISOString(),
              )
            }}
            format={'YYYY-MM-DD HH:mm'}
            minDate={moment(liveSchedule[FieldName.START])}
            {...formErrors[FieldName.END]}
          />
        </Grid>
        <Duration
          end={liveSchedule[FieldName.END]}
          start={liveSchedule[FieldName.START]}
        />
        <Grid item xs={12}>
          <InputLabel
            htmlFor={FieldName.XP_MULTIPLIER}
            className={classes.inputLabel}
          >
            XP Multiplier
          </InputLabel>
          <TextField
            id={FieldName.XP_MULTIPLIER}
            name={FieldName.XP_MULTIPLIER}
            value={liveSchedule[FieldName.XP_MULTIPLIER]}
            onChange={handleChange as React.ChangeEventHandler<HTMLInputElement>}
            variant="filled"
            type="number"
            fullWidth
            inputProps={{
              style: {
                textAlign: 'center',
              },
            }}
            InputProps={{
              inputProps: {
                min: 1,
              },
            }}
          />
        </Grid>
        {currentEvent?.hasTimezone && <Grid item xs={12}>
          <InputLabel className={classes.inputLabel}>
            Timezone
          </InputLabel>
          <Select
            value={liveSchedule[FieldName.TIMEZONE] || ''}
            onChange={(event): void =>
              setFieldValue(FieldName.TIMEZONE, event.target.value)
            }
            variant="filled"
            displayEmpty
            fullWidth
          >
            <MenuItem key={`answer-none`} value={''}>
              No timezone
            </MenuItem>
            {TIMEZONE_OPTIONS.map((option) => (
              <MenuItem key={`answer-option-${option.value}`} value={option.value}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>}
      </Grid>
    </Box>
  )
}
