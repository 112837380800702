import React from 'react'
import { useTypedSelector } from 'core/redux/utils'
import { useQuestsContext } from 'modules/quests/context/quest-context'
import { QuestCard, StyledTableCell } from 'modules/quests/view/quests/quest-card'
import { IQuest } from 'modules/quests/models/quest'
import { QuestModal } from 'modules/quests/view/quests/quest-modal'
import { ActiveOptions, IFilterSetting, QuestCustomFilter } from 'modules/quests/view/quests/quest-custom-filter'
import { LocalListWrapper } from 'components/local-list-wrapper'
import { QuestActions, Selectors } from '../..'

const activeMatchTest = (item: IQuest, activeStatus?: ActiveOptions): boolean => {
  const isActive = activeStatus === ActiveOptions.ACTIVE
  return item?.active === isActive
}

const scheduleSetMatchTest = (item: IQuest, scheduleAndSet?: string): boolean => {
  const [schedule, set] = (scheduleAndSet || '').split(' ')
  return item?.schedule === schedule && `${item?.set}` === set
}

const customFilterFcn = (list: IQuest[], settings: IFilterSetting): IQuest[] => {
  return list.reduce((acc: IQuest[], item) => {
    const titleMatch = !!(item?.media?.title || '')
      .toUpperCase()
      .match((settings?.title || '').toUpperCase())
    const activeMatch = !settings?.active || settings?.active === ActiveOptions.NONE
      ? true
      : activeMatchTest(item, settings?.active as ActiveOptions)
    const scheduleAndSetMatch = !settings?.scheduleAndSet || settings?.scheduleAndSet === 'NONE'
      ? true
      : scheduleSetMatchTest(item, settings?.scheduleAndSet)
    const negativeSetMatch = settings?.showNegativeSet ? true : !item.set.toString().includes('-')
    return activeMatch && scheduleAndSetMatch && titleMatch && negativeSetMatch
      ? [...acc, item]
      : acc;
  }, [])
}

const sortOptions = [
  { name: 'Created at', value: 'createdAt', descending: true },
  { name: 'Title', value: 'media.title' },
  { name: 'Schedule', value: 'schedule' },
  { name: 'Set', value: 'set' },
]


const QuestList: React.FC<{}> = () => {
  const quests = useTypedSelector((state) => Selectors.getQuests(state))
  const count = useTypedSelector((state) => Selectors.getQuestsCount(state))
  const { currentQuest, createQuestDraft, setCurrentQuest } = useQuestsContext()

  return (<LocalListWrapper<IQuest, QuestActions.IQuestActionParams, IFilterSetting>
    list={quests}
    count={count}
    searchKey="media.title"
    currentItem={currentQuest}
    createDraft={createQuestDraft}
    setCurrentItem={setCurrentQuest}
    CardComponent={QuestCard}
    ModalComponent={QuestModal}
    getItems={QuestActions.getQuests}
    searchLabel="Title"
    title="Quests"
    customFilter={{
      Component: QuestCustomFilter,
      filter: customFilterFcn
    }}
    sortOptions={sortOptions}
  >
    <>
      <StyledTableCell>Title</StyledTableCell>
      <StyledTableCell>Schedule</StyledTableCell>
      <StyledTableCell align="center">Active</StyledTableCell>
      <StyledTableCell align="right">Set</StyledTableCell>
    </>
  </LocalListWrapper>)
}

export default QuestList
