import { Box, ButtonBase, SvgIcon, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx'
import { ImageIcon } from 'components/icons'
import { usePopover } from 'components/popover/use-popover'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { usePrizeListContext } from 'modules/prizes/context/prize-list'
import { PrizeTimeGridContextMenu } from 'modules/prizes/views/components/dashboard/prize-calendar-time-grid-cell/context-menu'
import moment from 'moment-timezone'
import React from 'react'
import { PrizeType } from 'fe-shared-resources'
import PrizeStatus from 'types/modules/prizes/enums/prize-status'
import { IPrize } from 'types/modules/prizes/models/entities/prize'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    prizeEvent: {
      display: 'flex',
      flex: '1 1 auto',
      boxSizing: 'border-box',
      flexDirection: 'column',
      height: 80,
      '&.Instant': {
        background: '#ff787d',
        border: '1px solid #ff787d',
      },
      '&.Sweepstake': {
        background: '#FDD219',
        border: '1px solid #FDD219',
      },
    },
    eventHeader: {
      display: 'flex',
      height: 15,
      width: '100%',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingLeft: '0.3rem',
      paddingRight: '0.3rem',
    },
    headerText: {
      fontSize: '0.7rem',
      color: theme.palette.primary.contrastText,
      '&.textPrimary': {
        color: theme.palette.text.primary,
      },
    },
    eventContent: {
      display: 'flex',
      flex: 1,
      width: '100%',
      height: 'calc(100% - 15px)',
      background: 'rgba(255,255,255,0.60)',
      padding: '0.3rem',
      '&.darker': {
        background: 'rgba(255,255,255,0.30)',
      },
    },
    coverImageContainer: {
      display: 'flex',
      flex: 1,
      borderRadius: 4,
      background: '#f7f7f7',
      color: 'rgba(0,0,0,0.54)',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      justifyContent: 'center',
      alignItems: 'center',
    },
    prizeDetailsContainer: {
      display: 'flex',
      flex: 2,
    },
    prizeName: {
      fontSize: '0.7rem',
      color: theme.palette.text.primary,
      overflow: 'hidden',
      textAlign: 'left',
    },
  }),
)

interface ITimeGridPrizeProps {
  prize: IPrize
  index: number
  dateField: string
  onClick?: () => void
}

export const TimeGridPrize: React.FC<ITimeGridPrizeProps> = (
  props: ITimeGridPrizeProps,
) => {
  const { prize, index, dateField } = props
  const { setCurrentPrize } = usePrizeListContext()

  const classes = useStyles()

  const hasCoverImage = !IsNullOrUndefined(prize.media?.imageUrl)
  const postCoverContainerStyles: { [key: string]: string | number } = {}

  if (hasCoverImage) {
    postCoverContainerStyles.backgroundImage = `url(${prize.media!.imageUrl!})`
  } else {
    postCoverContainerStyles.background = '#f7f7f7'
  }

  const prizeStatus =
    prize.status === PrizeStatus.Created ? 'Draft' : prize.status

  const contextMenu = usePopover({
    id: `context-menu-prize-${prize.id}`,
    anchorReference: 'anchorPosition',
  })

  return (
    <React.Fragment>
      <ButtonBase
        key={prize.id}
        className={clsx(classes.prizeEvent, prize.type)}
        onClick={(): void => {
          props.onClick && props.onClick()
          // handleMultipostPopoverClose();
          setCurrentPrize(prize.id, prize.type)
        }}
        onContextMenu={(event: React.MouseEvent<HTMLButtonElement>): void => {
          event.preventDefault()
          contextMenu.onClick(event)
        }}
      >
        <Box className={classes.eventHeader}>
          <Typography
            component="span"
            variant="button"
            className={clsx(
              classes.headerText,
              prize.type === PrizeType.Sweepstake && 'textPrimary',
            )}
          >
            {moment((prize as { [key: string]: any })[dateField]).format(
              'h:mm A',
            )}
          </Typography>
          <Typography
            component="span"
            variant="button"
            className={clsx(
              classes.headerText,
              prize.type === PrizeType.Sweepstake && 'textPrimary',
            )}
          >
            {`${prize.type} | ${prizeStatus}`}
          </Typography>
        </Box>
        <Box className={classes.eventContent}>
          <Box
            className={classes.coverImageContainer}
            style={postCoverContainerStyles}
          >
            {!hasCoverImage && (
              <SvgIcon>
                <ImageIcon />
              </SvgIcon>
            )}
          </Box>
          <Box className={classes.prizeDetailsContainer}>
            <Typography
              component="span"
              variant="body2"
              className={clsx(
                classes.prizeName,
                prize.type === PrizeType.Sweepstake && 'textPrimary',
              )}
            >
              {`${prize.name}`}
            </Typography>
          </Box>
        </Box>
      </ButtonBase>
      <PrizeTimeGridContextMenu
        popoverProps={contextMenu.popoverProps}
        dateTime={moment((prize as { [key: string]: any })[dateField])
          .utc()
          .startOf('hour')
          .toISOString()}
        dateField={dateField}
        prize={prize}
      />
    </React.Fragment>
  )
}
