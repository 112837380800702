import FilterToolbar, {
  IFilterToolbarProps,
} from 'core/filtering/components/filter-toolbar'
import React from 'react'
import SortDirection from 'types/common/enums/sort-direction'

export const PrizeListToolbar: React.FC<{}> = () => {
  const toolbarProps = {
    searchFilterConfig: {
      field: 'name',
    },
    sortOptions: [
      { label: 'Start Date', value: 'start' },
      { label: 'End Date', value: 'end' },
      { label: 'Last Updated', value: 'updatedAt' },
      { label: 'Show', value: 'subCategoryId' },
      { label: 'Volume', value: 'volume' },
      { label: 'Featured', value: 'featured' },
    ],
    initialSortValue: {
      sortBy: 'start',
      sortDirection: SortDirection.Descending,
    },
  } as IFilterToolbarProps

  return <FilterToolbar {...toolbarProps} />
}
