import { Box, Button, Grid, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import _ from 'core/utils/deepdash'
import { FormGen, FormTypes } from 'components/simple-form-gen'
import React, { useEffect, useState } from 'react'
import { IForm } from 'types/common/interfaces/IForm'
import { ICampaign, ICampaignItem } from 'modules/campaigns/models/campaign'
import { CampaignItem } from 'modules/campaigns/view/campaign-modal/campaign-item';
import { CampaignItemForm } from 'modules/campaigns/view/campaign-modal/campaign-item-form';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      width: '100%',
      height: '600px',
      '& .MuiGrid-item': {
        display: 'flex',
        flexDirection: 'column',
      },
    },
    campaignsWrapper: {
      borderLeft: '1px solid #f4f4f4',
      paddingLeft: 8,
      height: '100%',
    },
    container: {
      height: '100%',
    },
    campaignsError: {
      marginTop: -16,
    },

    inputLabel: {
      display: 'flex',
      marginBottom: '0.625rem',
    },

    imageHint: {
      marginTop: 8,
      color: 'red',
    },
    imageHintText: {
      marginLeft: 8,
    },
    imageContainer: {
      height: 200,
      width: '100%',
    },
  }),
)

interface ICampaignFormProps extends IForm {
  campaign: ICampaign
  isUpdating?: boolean
}

export const CampaignForm: React.FC<ICampaignFormProps> = ({
  campaign,
  handleChange,
  setFieldValue,
  errors,
  isUpdating,
}): JSX.Element => {
  const classes = useStyles()
  const [displayedItemId, setDisplayedItemId] = useState<undefined | string>()
  const itemOnDisplayIndex = campaign.items.findIndex(item => item.id === displayedItemId)

  useEffect(() => {
    if (campaign.items[0]) {
      setDisplayedItemId(campaign.items[0].id)
    }
  }, [])

  const addNewItem = () => {
    const id = `${Date.now()}`
    const lastItem = campaign.items[campaign.items.length - 1]
    const newCampaignItem: ICampaignItem = {
      title: '',
      description: '',
      id,
      order: lastItem ? lastItem.order + 1 : 1,
      imageUrl: ''
    }

    setFieldValue('items', [...campaign.items, newCampaignItem])
    setDisplayedItemId(id)
  }

  const deleteItem = (itemId: string) => {
    setFieldValue('items', campaign.items.filter(item => item.id !== itemId))
  }

  const name = {
    name: 'name',
    label: 'Name',
    value: campaign?.name || '',
    type: FormTypes.TEXT_INPUT,
    otherProps: {
      error: !!errors?.title,
      helperText: errors?.title,
    },
  }

  return (
    <Box className={classes.form}>
      <Grid container spacing={2} className={classes.container}>
        <Grid item xs={4}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormGen handleChange={handleChange} {...name} />
            </Grid>
            <Grid item xs={12}>
              <Typography>Campaign Items</Typography>
            </Grid>
            {
              campaign.items.map((item, index) => <Grid item xs={12} key={item.id}>
                <CampaignItem
                  item={item}
                  onClick={() => setDisplayedItemId(item.id)}
                  isSelected={item.id === displayedItemId}
                />
              </Grid>)
            }
            <Grid item xs={12}>
              <Box alignSelf="flex-start">
                <Button variant="outlined" onClick={addNewItem}>
                  Add New Item
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={8}>
          {itemOnDisplayIndex >= 0 && <CampaignItemForm
            handleChange={handleChange}
            item={campaign.items[itemOnDisplayIndex]}
            index={itemOnDisplayIndex}
            errors={errors}
            deleteItem={deleteItem}
          />}
        </Grid>
      </Grid>
    </Box>
  )
}
