import React, { ComponentType } from 'react'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { UserPrizeActionButtonType } from 'types/modules/shipping/enums/user-prize-action-button-type'
import { IUserPrizeActionButtonProps } from 'types/modules/shipping/models/props/user-prize-action-button-props'
import { IUserPrizeActionButtonsProps } from 'types/modules/shipping/models/props/user-prize-action-buttons-props'
import { DispatchUserPrizeButton } from 'modules/shipping/views/components/action-buttons/dispatch'
import { CancelUserPrizeButton } from 'modules/shipping/views/components/action-buttons/cancel'
import { DeliveredUserPrizeButton } from 'modules/shipping/views/components/action-buttons/delivered'
import { ReturnUserPrizeButton } from 'modules/shipping/views/components/action-buttons/return'
import { ViewUserPrizeButton } from 'modules/shipping/views/components/action-buttons/view'

export const UserPrizeActionButtons: React.FC<IUserPrizeActionButtonsProps> = (
  props: IUserPrizeActionButtonsProps,
) => {
  const { userPrize, exclude, onPopoverClose } = props

  const renderButtons = (): JSX.Element => {
    const buttonsToRender: [
      UserPrizeActionButtonType,
      ComponentType<IUserPrizeActionButtonProps>,
    ][] = []

    Object.values(UserPrizeActionButtonType).forEach((buttonType) => {
      if (!IsNullOrUndefined(exclude) && exclude?.includes(buttonType)) {
        return
      }

      let currentButton: ComponentType<IUserPrizeActionButtonProps> | null =
        null

      switch (buttonType) {
        case UserPrizeActionButtonType.Dispatch: {
          currentButton = DispatchUserPrizeButton
          break
        }
        case UserPrizeActionButtonType.Cancel: {
          currentButton = CancelUserPrizeButton
          break
        }
        case UserPrizeActionButtonType.Delivered: {
          currentButton = DeliveredUserPrizeButton
          break
        }
        case UserPrizeActionButtonType.Return: {
          currentButton = ReturnUserPrizeButton
          break
        }
        case UserPrizeActionButtonType.View: {
          currentButton = ViewUserPrizeButton
          break
        }
      }
      if (!IsNullOrUndefined(currentButton)) {
        buttonsToRender.push([buttonType, currentButton!])
      }
    })

    return (
      <React.Fragment>
        {buttonsToRender.map(([buttonType, Component]) => (
          <Component
            key={buttonType}
            userPrize={userPrize}
            disabled={props.disabled}
            onPopoverClose={onPopoverClose}
          />
        ))}
      </React.Fragment>
    )
  }

  return !IsNullOrUndefined(userPrize) ? renderButtons() : null
}
