import { ButtonBase, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { MediaUploadModel } from 'types/common/images/models/entities/image-upload-model'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import React, { PropsWithChildren, useRef } from 'react'
import imageCompression from 'browser-image-compression'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    imageUploaderButton: {
      display: 'flex',
      flex: 1,
      width: '100%',
    },
  }),
)

interface IImageUploaderProps {
  handleUpload: (fileParams: MediaUploadModel) => void
  disabled?: boolean
}

export const ImageUploader: React.FC<PropsWithChildren<IImageUploaderProps>> = (
  props: PropsWithChildren<IImageUploaderProps>,
) => {
  const fileInput = useRef<HTMLInputElement>(null)

  const handleChange = (files: FileList | null): void => {
    if (IsNullOrUndefined(files)) return

    const fileToUpload = files![0]

    const fileReader = new FileReader()

    const extension =
      fileToUpload.name.lastIndexOf('.') < 1
        ? ''
        : fileToUpload.name.split('.').slice(-1)[0]

    fileReader.onload = (): void => {
      const buffer = Buffer.from(fileReader.result as string, 'base64')

      const fileParams: MediaUploadModel = {
        fileExtension: extension,
        binaryData: buffer,
      }

      props.handleUpload(fileParams)
    }

    const compressionOptions = {
      maxSizeMB: 0.5,
      useWebWorker: true,
    }

    if (extension === 'gif') {
      fileReader.readAsArrayBuffer(fileToUpload)
    } else {
      imageCompression(fileToUpload, compressionOptions).then((file) => {
        fileReader.readAsArrayBuffer(file)
      })
    }
  }

  const classes = useStyles()
  return (
    <React.Fragment>
      <input
        type="file"
        ref={fileInput}
        onChange={(event): void => handleChange(event.target.files)}
        style={{
          display: 'none',
        }}
        disabled={props.disabled}
      />
      <ButtonBase
        className={classes.imageUploaderButton}
        onClick={(): void => {
          fileInput?.current?.click()
        }}
        disabled={props.disabled}
      >
        {props.children}
      </ButtonBase>
    </React.Fragment>
  )
}
