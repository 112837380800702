import React, { PropsWithChildren } from 'react'
import { Box, Divider, GridProps, Paper, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import PanelContainer from 'components/panel/panel-container'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    panelHeader: {
      display: 'flex',
      width: '100%',
      padding: '1.25rem',
      flexDirection: 'column',
      flexShrink: 0,
    },
    panelHeaderTopRow: {
      display: 'flex',
      flex: 1,
    },
    panelTitle: {
      display: 'flex',
      flexGrow: 1,
      alignItems: 'center',
    },
    inlineControls: {
      display: 'flex',
      flexGrow: 0,
      alignItems: 'center',
    },
    panelToolbar: {
      display: 'flex',
      flex: 1,
      marginTop: '1.25rem',
      alignItems: 'center',
    },
  }),
)

interface IPanelHeaderProps {
  title?: string
  inlineControls?: React.ReactNode
  toolbar?: React.ReactNode
  children?: React.ReactChild
}

const PanelHeader: React.FC<IPanelHeaderProps> = (props: IPanelHeaderProps) => {
  const classes = useStyles()

  return (
    <React.Fragment>
      <Box className={classes.panelHeader}>
        {props.children
          ? <>{props.children}</>
          : <>
        <Box className={classes.panelHeaderTopRow}>
          <Box className={classes.panelTitle}>
            <Typography variant="h5" color="textPrimary">
              {props.title}
            </Typography>
          </Box>
          {props.inlineControls ? (
            <Box className={classes.inlineControls}>{props.inlineControls}</Box>
          ) : null}
        </Box>
        {props.toolbar ? (
          <Box className={classes.panelToolbar}>{props.toolbar}</Box>
        ) : null}
        </>}
      </Box>
      <Divider />
    </React.Fragment>
  )
}

export default PanelHeader
