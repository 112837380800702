import React from 'react'
import { Grid, GridProps, Paper, Theme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    panel: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      maxHeight: '100%',
      height: '100%',
      width: '100%',
    },
  }),
)

const Panel: React.ForwardRefExoticComponent<GridProps> =
  // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
  React.forwardRef(function PanelForwardRef(props: GridProps, ref) {
    const classes = useStyles()

    return (
      <Grid {...props} ref={ref} item>
        <Paper className={classes.panel} elevation={2}>
          {props.children ?? null}
        </Paper>
      </Grid>
    )
  })

export default Panel
