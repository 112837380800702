import { EndpointRoute } from 'types/common/api/models/entities/endpoint-route'
import { RequestMethod } from 'types/common/api/enums/request-method'
import paths from './paths'
import params from './params'
import filters from './filters'

const PostGroupEndpoints = {
  getPostGroups: {
    method: RequestMethod.GET,
    path: [paths.postGroups],
    filters,
  },
  getPostGroupById: {
    method: RequestMethod.GET,
    path: [paths.postGroups, params.postGroupId],
  },
  getPostGroupPosts: {
    method: RequestMethod.GET,
    path: [paths.postGroups, params.postGroupId, paths.posts]
  },
  createPostGroup: {
    method: RequestMethod.POST,
    path: [paths.postGroups],
  },
  updatePostGroup: {
    method: RequestMethod.PUT,
    path: [paths.postGroups, params.postGroupId],
  },
  settlePostGroup: {
    method: RequestMethod.POST,
    path: [paths.postGroups, params.postGroupId, paths.settle],
  },
  deletePostGroupById: {
    method: RequestMethod.DELETE,
    path: [paths.postGroups, params.postGroupId],
  },
  uploadPostGroupImage: {
    method: RequestMethod.PUT,
    path: [paths.postGroups, params.postGroupId, paths.images, paths.upload],
  },
  activatePostGroup: {
    method: RequestMethod.PUT,
    path: [paths.postGroups, params.postGroupId, paths.active],
  },
  deactivatePostGroup: {
    method: RequestMethod.PUT,
    path: [paths.postGroups, params.postGroupId, paths.inactive],
  }
}

export default PostGroupEndpoints
