import { AppState } from 'types/redux/types/app-state'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { NIL as NIL_UUID } from 'uuid'
import {
  IIapProductState,
} from 'modules/iap-products/models/state'
import { IIapProduct, ProductStatus, ProductType } from 'modules/iap-products/models/iap-product'
import { IapProduct } from 'fe-shared-resources'

const getIapProductState = (state: AppState): IIapProductState => state.iapProducts

export const getCurrentIapProduct = (
  state: AppState,
): IIapProduct | null => {
  const { currentIapProductId, cache } = getIapProductState(state)
  if (IsNullOrUndefined(currentIapProductId)) return null
  if (currentIapProductId === NIL_UUID) {
    return {
      id: NIL_UUID,
      description: '',
      metaData: {
        productConfig: {}
      },
      status: ProductStatus.Inactive,
      type: ProductType.NotSet
    }
  }
  const currentIapProduct = cache[currentIapProductId!]
  return currentIapProduct ?? null
}

export const getIapProducts = (state: AppState): IIapProduct[] =>
  getIapProductState(state).items

export const getIapProductsCount = (state: AppState): number =>
  getIapProductState(state).items.length

  export const getIapProductGetter = (state: AppState): (id: string) => IIapProduct => {
    return (id: string): IIapProduct => getIapProductState(state).cache[id]
  }