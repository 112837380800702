import { CognitoUser } from 'amazon-cognito-identity-js'
import { Amplify, Auth  } from 'aws-amplify'
import axios from 'axios'
import CognitoAuthConfiguration from 'core/authentication/config/cognito-auth-configuration'
import { IAuthContext } from 'core/authentication/context/auth-context'
import { checkUserPermission } from 'core/authorization/service'
import { useTypedSelector } from 'core/redux/utils'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import {
  authenticationSuccess,
  authenticationFailed,
  logoutSuccess,
} from 'modules/account/actions'
import { getCurrentUser } from 'modules/account/selectors'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { IPortalUser } from 'types/modules/users/portal/models/entities/portal-user'
import { usePortalUsersContext } from 'modules/users/portal/context'

export const useProvideAuth = (): IAuthContext => {
  const dispatch = useDispatch()

  // State
  const [isVerifyingLogin, setIsVerifyingLogin] = useState(true)

  const [error, setError] = useState<any>(null)

  const currentUser = useTypedSelector((state) => getCurrentUser(state))

  const isAuthenticated = !IsNullOrUndefined(currentUser)

  const portalUsersContext = usePortalUsersContext()

  useEffect(() => {
    if (!IsNullOrUndefined(error)) {
      dispatch(authenticationFailed({ error }))
    }
  }, [error])

  useEffect(() => {
    if (isAuthenticated) {
      axios.defaults.headers.common.Authorization = currentUser?.token

      portalUsersContext.loadUsers()
    } else {
      delete axios.defaults.headers.common.Authorization
    }
  }, [currentUser, isAuthenticated])

  // Configuration
  const configureAuth = (): void => {
    Amplify.configure(CognitoAuthConfiguration)
  }

  // User Login
  const handleLoginAsync = async (
    username: string,
    password: string,
  ): Promise<CognitoUser> =>
    Auth.signIn(username, password).then((authenticatedUser) => {
      if (authenticatedUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
        return Auth.completeNewPassword(authenticatedUser, password, {})
      }
      return authenticatedUser
    })

  const initiateLogin = (username: string, password: string): void => {
    handleLoginAsync(username, password)
      .then((user: CognitoUser) => dispatch(authenticationSuccess({ user })))
      .catch((error) => setError(error))
  }

  // Login Verification
  const handleVerifyLoginAsync = async (): Promise<CognitoUser> =>
    Auth.currentAuthenticatedUser()

  const verifyLogin = (): void => {
    if (isAuthenticated && !isVerifyingLogin) return

    setIsVerifyingLogin(true)
    handleVerifyLoginAsync()
      .then((user: CognitoUser) => dispatch(authenticationSuccess({ user })))
      .catch((error) => {
        if (error !== 'not authenticated') {
          setError(error)
        }
      })
      .finally(() => setIsVerifyingLogin(false))
  }

  // Log out
  const handleLogoutAsync = async (): Promise<void> => Auth.signOut()

  const logout = (): void => {
    handleLogoutAsync()
      .then(() => dispatch(logoutSuccess()))
      .catch((error) => setError(error))
  }

  const context = {
    isVerifyingLogin,
    isAuthenticated,
    error,
    currentUser: {
      ...currentUser,
      can: (permission: string, params?: any) =>
        checkUserPermission(currentUser, permission, params),
    } as IPortalUser,
    configureAuth,
    verifyLogin,
    initiateLogin,
    logout,
  }

  return context
}
