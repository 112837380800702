import { FailedAlertSaga } from 'core/alerts/sagas/failed-alert-saga'
import { SuccessAlertSaga } from 'core/alerts/sagas/success-alert-saga'
import { ActionTypeSuffix } from 'core/redux/constants'
import { RootSagaReturnType } from 'core/redux/rootSaga'
import { all, AllEffect, ForkEffect, takeEvery } from 'redux-saga/effects'
import { IAsyncAction } from 'types/redux/interfaces/IAction'

export const RootSaga = function* (): RootSagaReturnType {
  yield all([
    takeEvery(
      (action: any) => action.type.endsWith(ActionTypeSuffix.SUCCESS),
      SuccessAlertSaga,
    ),
    takeEvery(
      (action: any) => action.type.endsWith(ActionTypeSuffix.FAILED),
      FailedAlertSaga,
    ),
  ])
}
