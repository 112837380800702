import React, { PropsWithChildren } from 'react'
import { Actions } from 'modules/posts'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { useDispatch } from 'react-redux'
import PostStatus from 'types/modules/posts/enums/post-status'
import _ from 'core/utils/deepdash'
import { getEventById } from 'modules/events/actions'
import { IEvent } from 'types/modules/events/models/entities/event'
import { usePostListContext } from 'modules/posts/context/post-list'
import { usePostManagerContext } from 'modules/posts/context/post-manager'

export interface IPostStatusManager {
  discardPost: (postId: string) => void
  submitPost: (postId: string) => void
  approvePost: (postId: string) => void
  promotePost: (postId: string) => void
  pausePost: (postId: string) => void
  abandonPost: (postId: string) => void
  rejectPost: (postId: string) => void
  closePost: (postId: string) => void
  settlePostTickets: (postId: string) => void
  reactivatePost: (postId: string) => void
  restorePost: (postId: string) => void
}

export const PostStatusManager = React.createContext<IPostStatusManager>(
  {} as IPostStatusManager,
)

interface IPostStatusManagerProps {
  event?: IEvent
}

export const PostStatusManagerProvider: React.FC<IPostStatusManagerProps> = (
  props: PropsWithChildren<IPostStatusManagerProps>,
) => {
  const dispatch = useDispatch()

  const { clearCurrentPost, eventId } = usePostListContext()
  const { updatePostGroup } = usePostManagerContext()

  const updatePostStatus = (postId: string, postStatus: PostStatus): void => {
    new Promise((resolve, reject) => {
      dispatch(
        Actions.updatePostStatus({
          postId,
          postStatus,
          promise: {
            onResolve: (response) => {
              updatePostGroup(response)
              resolve(response)
            },
            onReject: reject,
          },
        }),
      )
    }).then(() => {
      if (postStatus === PostStatus.Pending) {
        clearCurrentPost()
      }

      if (IsNullOrUndefined(event)) {
        dispatch(Actions.getPostStatuses())
      } else {
        dispatch(
          getEventById({
            eventId,
          }),
        )
      }
    })
  }

  const discardPost = (postId: string): void => {
    updatePostStatus(postId, PostStatus.Trashed)
  }

  const submitPost = (postId: string): void => {
    updatePostStatus(postId, PostStatus.Pending)
  }

  const approvePost = (postId: string): void => {
    updatePostStatus(postId, PostStatus.Active)
  }

  const promotePost = (postId: string): void => {
    dispatch(
      Actions.promotePost({
        postId,
        promise: {
          onResolve: (response) => {
            updatePostGroup(response)
          },
        }
      }),
    )
  }

  const pausePost = (postId: string): void => {
    updatePostStatus(postId, PostStatus.Paused)
  }

  const abandonPost = (postId: string): void => {
    updatePostStatus(postId, PostStatus.Abandoned)
  }

  const rejectPost = (postId: string): void => {
    updatePostStatus(postId, PostStatus.Rejected)
  }

  const closePost = (postId: string): void => {
    updatePostStatus(postId, PostStatus.Closed)
  }

  const settlePostTickets = (postId: string): void => {
    dispatch(
      Actions.settlePostTickets({
        postId,
        promise: {
          onResolve: (response) => {
            updatePostGroup(response)
          },
        }
      }),
    )
  }

  const reactivatePost = (postId: string): void => {
    updatePostStatus(postId, PostStatus.Active)
  }

  const restorePost = (postId: string): void => {
    updatePostStatus(postId, PostStatus.Created)
  }

  const context: IPostStatusManager = {
    discardPost,
    submitPost,
    approvePost,
    promotePost,
    pausePost,
    abandonPost,
    rejectPost,
    closePost,
    settlePostTickets,
    reactivatePost,
    restorePost,
  }

  return (
    <PostStatusManager.Provider value={context}>
      {props.children}
    </PostStatusManager.Provider>
  )
}

export const usePostStatusManager = (): IPostStatusManager =>
  React.useContext(PostStatusManager)
