import { Fade, Modal, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Panel } from 'components/panel'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { EditPositionRewardPanel } from 'modules/leaderboards/views/components/edit-position-reward-panel'
import React from 'react'
import ILeaderboardPositionReward from 'types/modules/leaderboards/models/entities/leaderboard-position-reward'
import { ILeaderboardPositionRewardDraft } from 'types/modules/leaderboards/models/entities/leaderboard-position-reward-draft'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 40,
    },
  }),
)

interface IPositionRewardsModalProps {
  positionReward: ILeaderboardPositionReward | null
  open: boolean
  closeModal?: () => void
}

export const PositionRewardsModal: React.FC<IPositionRewardsModalProps> = (
  props: IPositionRewardsModalProps,
) => {
  const classes = useStyles()

  const { positionReward } = props

  return (
    <div>
      <Modal
        className={classes.modal}
        open={props.open}
      >
        <Fade in={!IsNullOrUndefined(positionReward)}>
          <Panel
            xs={12}
            style={{
              display: 'flex',
              maxWidth: 600,
            }}
          >
            <EditPositionRewardPanel isModal positionReward={positionReward!} />
          </Panel>
        </Fade>
      </Modal>
    </div>
  )
}
