import { all, takeEvery, takeLatest } from 'redux-saga/effects'
import { ActionTypes } from 'modules/info-cards'
import { GetTagsSaga } from 'modules/tags/sagas/get-tags-saga'
import { RootSagaReturnType } from 'core/redux/rootSaga'

const enabledAsyncActions = [
  ActionTypes.GET_INFO_CARD_STATUSES,
  ActionTypes.GET_INFO_CARD_BY_ID,
  ActionTypes.CREATE_INFO_CARD,
  ActionTypes.UPDATE_INFO_CARD,
  ActionTypes.UPDATE_INFO_CARD_STATUS,
  ActionTypes.UPLOAD_INFO_CARD_IMAGE,
]

export const RootSaga = function* (): RootSagaReturnType {
  yield all([
    yield takeEvery(enabledAsyncActions, GetTagsSaga),
    yield takeLatest(ActionTypes.GET_INFO_CARDS, GetTagsSaga),
  ])
}
