const paths = {
  evergreenQuests: 'evergreenquests',
  add: 'add',
  save: 'save',
  update: 'update',
  levels: 'levels',
  uploadLevelImage: 'upload-level-image'
}

export default paths
