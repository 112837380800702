/* eslint-disable no-invalid-this */
import * as yup from 'yup'
import { EventFieldName } from 'types/modules/events/enums/event-field'
import {
  AHEAD_OF_END_DATE_MESSAGE,
  BEFORE_START_DATE_MESSAGE,
  testDateRange,
} from 'utils/validation-helper'

export const EventSchema = yup.object({
  [EventFieldName.NAME]: yup.string().required('name is required'),
  [EventFieldName.START]: yup
    .string()
    .required('start date is required')
    .test({
      message: AHEAD_OF_END_DATE_MESSAGE,
      test: function (value) {
        return testDateRange(value, this.parent[EventFieldName.END])
      },
    }),
  [EventFieldName.END]: yup.string().test({
    message: BEFORE_START_DATE_MESSAGE,
    test: function (value) {
      return testDateRange(this.parent[EventFieldName.START], value)
    },
  }),
})
