import { IAutocompleteSearchMultiselectProps } from 'components/controls/inputs/downshift/autocomplete/autocomplete-search-multiselect-props'
import {
  Item,
  RequiredItemProps,
} from 'components/controls/inputs/downshift/item'
import DownshiftMultiselect from 'components/controls/inputs/downshift/multiselect'
import { useTypedSelector } from 'core/redux/utils'
import { get } from 'lodash-es'
import React, {
  PropsWithChildren,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { useDispatch } from 'react-redux'
import { IAsyncActionParams } from 'types/common/api/models/entities/async-action-params'
import { FilterOperator } from 'types/common/filtering/enums/FilterOperator'
import { FilterType } from 'types/common/filtering/enums/FilterType'

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function AutocompleteSearchMultiselect<TItem, TActionTypeParams>(
  props: PropsWithChildren<IAutocompleteSearchMultiselectProps<Item<TItem>>>,
): ReactElement<any, any> | null {
  const [inputValue, setInputValue] = useState('')

  const availableItems: TItem[] = useTypedSelector((state) =>
    props.selectorMethod(state),
  )

  const handleInputValueChanged: (value: string) => void = (value) => {
    setInputValue(value)
  }

  const availableItemsFilter = (item: Item<TItem>, value: string): boolean =>
    get(item, props.searchKey || 'name')
      .toLowerCase()
      .includes(value.toLowerCase())

  const getFilteredItems = useCallback(
    (value: string): TItem[] =>
      availableItems.filter((item) => availableItemsFilter(item, value)),
    [availableItems],
  )

  const dispatch = useDispatch()

  useEffect(() => {
    if (
      inputValue === '' ||
      getFilteredItems(inputValue).length > 0 ||
      inputValue.length < 1
    ) {
      return
    }

    const searchParams: IAsyncActionParams<TItem> = {
      search: {
        fields: [props.searchKey || 'name'],
        value: inputValue,
      },
      filters: [
        {
          field: props.searchKey ||'name',
          type: FilterType.Search,
          operator: FilterOperator.ContainsCaseInsensitive,
          value: inputValue,
        },
      ],
    }

    dispatch(
      props.searchAsyncActionCreator<IAsyncActionParams<TItem>>(searchParams),
    )
  }, [inputValue])

  return (
    <DownshiftMultiselect
      label={props.label}
      variant={props.variant}
      availableItems={availableItems}
      onSelectedItemsChange={props.onSelectedItemsChange}
      onInputValueChanged={handleInputValueChanged}
      initialSelectedItems={props.initialSelectedItems}
      options={props.options}
      searchKey={props.searchKey}
    />
  )
}
