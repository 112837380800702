import { Button, ButtonProps, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx'
import { ConfirmationDialog } from 'components/confirmation-dialog'
import { DuplicateIcon } from 'components/icons'
import { usePrizeManagerContext } from 'modules/prizes/context/prize-manager'
import React from 'react'
import PrizeStatus from 'types/modules/prizes/enums/prize-status'
import { IPrizeActionButtonProps } from 'types/modules/prizes/models/props/prize-action-button-props'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.text.primary,
      backgroundColor: '#ecf0f1',
      '&:hover': {
        backgroundColor: '#f2f5f5',
      },
    },
  }),
)

export const DuplicatePrizeButton: React.FC<IPrizeActionButtonProps> = (
  props: IPrizeActionButtonProps,
) => {
  const { duplicatePrize } = usePrizeManagerContext()

  const classes = useStyles()

  const buttonProps: ButtonProps = {
    variant: 'contained',
    startIcon: <DuplicateIcon />,
    className: clsx(classes.root),
    size: 'small',
    disabled: props.disabled,
  }

  const buttonLabel = 'duplicate'

  return (
    <ConfirmationDialog
      popoverId={`${buttonLabel}-prize-${props.prize.id}`}
      buttonProps={buttonProps}
      onConfirmation={(): void => duplicatePrize(props.prize.id)}
      confirmationText={`Are you sure you want to ${buttonLabel} this prize?`}
      buttonLabel={buttonLabel}
      onPopoverClose={props.onPopoverClose}
    />
  )
}
