import React from 'react'
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Box, Button, ButtonProps, Grid } from '@mui/material'
import { NIL as NIL_UUID } from 'uuid'
import { PanelContent, PanelFooter } from 'components/panel'
import _ from 'core/utils/deepdash'
import { useFormik } from 'formik'
import { colors } from 'utils/colors'
import { usePostGroupsContext } from 'modules/post-groups/context'
import { PostGroupSchema } from 'modules/post-groups/schema/post-group-schema'
import { ModalWrapper } from 'components/modal-wrapper'
import { Currency, PostGroup, PostGroupStatus, PostGroupType } from 'fe-shared-resources';
import { PostGroupForm } from 'modules/post-groups/view/components/post-group-details-modal/post-group-form';
import { useTypedSelector } from 'core/redux/utils';
import { getPostGroupPosts } from 'modules/post-groups/selectors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
    },
    panelHeader: {
      display: 'flex',
      width: '100%',
      flexShrink: 0,
    },
    panelTitle: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      padding: '0 0 0 1.25rem',
    },
    navigationContainer: {
      display: 'flex',
      flex: 2,
      flexGrow: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    rightHeaderSection: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      padding: '0 1.25rem 0 0',
      flexDirection: 'row-reverse',
      '& .MuiButton-contained': {
        marginRight: 20,
      },
    },
    footerContent: {
      display: 'flex',
      flex: 1,
      justifyContent: 'flex-end',
    },
    cancelBtn: {
      marginRight: '1.25rem',
    },
    wrapper: {
      borderLeft: `1px solid ${colors.rgba_0_0_0_12}`,
    },
    form: {
      height: '600px',
      overflow: 'auto',
    },
  }),
)

export const PostGroupDetailsModal: React.FC = () => {
  const classes = useStyles()
  const {
    currentPostGroup,
    updatePostGroup,
    createPostGroup,
    clearCurrentPostGroup,
    hideModal
  } = usePostGroupsContext()
  const isUpdating = !!currentPostGroup?.id && currentPostGroup?.id !== NIL_UUID

  const cancel = () => {
    hideModal()
    clearCurrentPostGroup()
  }

  const posts = useTypedSelector((state) =>
    getPostGroupPosts(state),
  )

  const form = useFormik({
    enableReinitialize: !isUpdating,
    validationSchema: PostGroupSchema,
    initialValues: {
      id:
        !currentPostGroup?.id || currentPostGroup?.id === NIL_UUID
          ? ''
          : currentPostGroup?.id,
      type: currentPostGroup?.type || PostGroupType.DailyStack,
      status: currentPostGroup?.status || PostGroupStatus.Created,
      name: currentPostGroup?.name || '',
      rewardCurrency: currentPostGroup?.rewardCurrency || Currency.Coin,
      rewardAmount: currentPostGroup?.rewardAmount || 0,
      startAt: currentPostGroup?.startAt,
      endAt: currentPostGroup?.endAt,
      settleAt: currentPostGroup?.settleAt,
      postIds: (currentPostGroup?.postIds || []).length === 0
        ? posts.map((post) => post.id!)
        : (currentPostGroup?.postIds || []),
      description: currentPostGroup?.description || '',
      imageUrl: currentPostGroup?.imageUrl || ''
    },
    onSubmit: (value: PostGroup) => {
      const action = isUpdating ? updatePostGroup : createPostGroup
      action(value)
    },
  })

  return (
    <ModalWrapper
      panelSize={8}
      closeModal={cancel}
      title={
        isUpdating
          ? `Edit ${currentPostGroup?.name} (${currentPostGroup?.status})`
          : 'Create Post Group'
      }
      open={!!currentPostGroup}
    >
      <>
        <PanelContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <PostGroupForm
                postGroup={form.values}
                handleChange={form.handleChange}
                setFieldTouched={form.setFieldTouched}
                setFieldValue={form.setFieldValue}
                errors={form.errors}
                isUpdating={isUpdating}
              />
            </Grid>
          </Grid>
        </PanelContent>
        <PanelFooter>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box className={classes.footerContent}>
                <Box>
                  <Button
                    size="large"
                    className={classes.cancelBtn}
                    onClick={cancel}
                    variant="contained"
                    color="secondary"
                  >
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    size="large"
                    onClick={form.submitForm}
                    variant="contained"
                    disabled={(form.dirty && !form.isValid) || !form.dirty}
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </PanelFooter>
      </>
    </ModalWrapper>
  )
}
