import { useTypedSelector } from 'core/redux/utils'
import { useRouter } from 'core/routing/hooks/use-router'
import {
  IsNullOrUndefined,
} from 'core/utils/isNullOrUndefined'
import { QuestGroupActions, Selectors } from 'modules/quests'
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
} from 'react'
import { useDispatch } from 'react-redux'
import { NIL as NIL_UUID } from 'uuid'
import { IQuestGroup } from 'modules/quests/models/quest-group'

export interface IQuestGroupsContext {
  currentQuestGroup: IQuestGroup | null
  setCurrentQuestGroup: (questGroupId: string) => void
  clearCurrentQuestGroup: () => void
  createQuestGroup: (questGroup: IQuestGroup, callbackFn?: (questGroup: IQuestGroup) => void) => void
  createQuestGroupDraft: (questGroup?: IQuestGroup) => void
  updateQuestGroup: (questGroup: IQuestGroup) => void
}

const QuestGroupsContext = createContext<IQuestGroupsContext>({} as IQuestGroupsContext)

export const QuestGroupsProvider: React.FC<PropsWithChildren<{}>> = (
  props: PropsWithChildren<{}>,
) => {
  const dispatch = useDispatch()

  const { routeParams: { questGroupId }, pushQueryParams, removeQueryParams } = useRouter()

  const currentQuestGroup = useTypedSelector((state) => Selectors.getCurrentQuestGroup(state))

  const setCurrentQuestGroup = (questGroupId: string): void => {
    pushQueryParams({
      questGroupId,
    })
  }

  const clearCurrentQuestGroup = (): void => {
    removeQueryParams('questGroupId')
  }

  const createQuestGroup = (questGroup: IQuestGroup): void => {
    dispatch(
      QuestGroupActions.createQuestGroup({
        item: questGroup,
        promise: {
          onResolve: (data: IQuestGroup): void => {
            setCurrentQuestGroup(data.id!)
          }
        }
      }),
    )
  }

  const createQuestGroupDraft = (): void => {
    setCurrentQuestGroup(NIL_UUID)
  }

  const updateQuestGroup = (questGroup: IQuestGroup): void => {
    dispatch(
      QuestGroupActions.updateQuestGroup({
        item: questGroup,
        questGroupId: questGroup.id,
      }),
    )
  }
  useEffect(() => {
    dispatch(QuestGroupActions.setCurrentQuestGroupId({ questGroupId }))
    if (!IsNullOrUndefined(questGroupId) && questGroupId !== NIL_UUID) {
      dispatch(QuestGroupActions.getQuestGroupById({ questGroupId }))
    }
  }, [questGroupId])


  const context = {
    currentQuestGroup,
    setCurrentQuestGroup,
    clearCurrentQuestGroup,
    createQuestGroup,
    createQuestGroupDraft,
    updateQuestGroup,
  }

  return (
    <QuestGroupsContext.Provider value={context}>
      {props.children}
    </QuestGroupsContext.Provider>
  )
}

export const useQuestGroupsContext = (): IQuestGroupsContext => useContext(QuestGroupsContext)
