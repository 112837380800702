import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  SvgIcon,
  Theme,
  Typography,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { CrossIcon, ImageIcon } from 'components/icons'
import { ImageUploader } from 'components/image-uploader'
import { MediaUploadModel } from 'types/common/images/models/entities/image-upload-model'
import { useRequestContext } from 'core/api/context'
import { useTypedSelector } from 'core/redux/utils'
import { useRouter } from 'core/routing/hooks/use-router'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { Selectors } from 'modules/posts'
import { getTypeLabel } from 'modules/posts/utils/get-type-label'
import moment from 'moment-timezone'
import React from 'react'
import ScrollContainer from 'react-indiana-drag-scroll'
import { PostField } from 'types/modules/posts/enums/post-field'
import { Results } from 'modules/posts/views/components/media-modal/results'
import { usePostManagerContext } from 'modules/posts/context/post-manager'
import { usePostQuestionsManager } from 'modules/posts/context/post-questions-manager'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    panelHeader: {
      display: 'flex',
      width: '100%',
      flexShrink: 0,
    },
    panelTitle: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      padding: '1.25rem 0 1.25rem 1.25rem',
    },
    rightHeaderSection: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      padding: '0 1.25rem 0 0',
      flexDirection: 'row-reverse',
      '& .MuiButton-contained': {
        marginRight: 20,
      },
    },
    contentWrapper: {
      display: 'flex',
      flexGrow: 1,
      overflow: 'auto',
      flexDirection: 'column',
      padding: '1.25rem',
    },
    post: {
      width: '100%',
      marginBottom: '2rem',
    },
    postTitle: {
      marginBottom: '0.625rem',
    },
    postImagesContainer: {
      margin: '-1.25rem',
      padding: '1.25rem',
      display: 'flex',
      flexWrap: 'nowrap',
      overflowX: 'auto',
      '-webkit-overflow-scrolling': 'touch',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    postImageCard: {
      flex: '0 0 auto',
      width: 300,
      marginRight: '1.25rem',
      position: 'relative',
      '&:hover': {
        '& $cardInfoPopup': {
          opacity: 1,
          transition: theme.transitions.create(['opacity'], {
            duration: theme.transitions.duration.standard,
          }),
        },
      },
    },
    imageContainer: {
      display: 'flex',
      width: '100%',
      height: 400,
      backgroundSize: 'cover',
      backgroundPosition: 'center top',
      backgroundRepeat: 'no-repeat',
      boxShadow: '0 3px 3px rgba(0,0,0,0.12)',
      justifyContent: 'center',
      alignItems: 'center',
    },
    cardContent: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      flex: 1,
    },
    cardInfoPopup: {
      position: 'absolute',
      bottom: 0,
      width: 300,
      height: 400,
      pointerEvents: 'none',
      display: 'flex',
      padding: '1.25rem',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'rgba(0,0,0,0.54)',
      opacity: 0,
      transition: theme.transitions.create(['opacity'], {
        duration: theme.transitions.duration.standard,
      }),
    },
  }),
)

interface IPostMediaPanelProps {
  date: string
}

export const PostMediaPanel: React.FC<IPostMediaPanelProps> = (
  props: IPostMediaPanelProps,
) => {
  const classes = useStyles()

  const rc = useRequestContext()

  const { uploadPostImage } = usePostManagerContext()
  const { uploadQuestionImage } = usePostQuestionsManager()

  const posts = useTypedSelector((state) =>
    Selectors.getPostsByDate(state, props.date, rc?.sortValue.sortBy!),
  )

  const { removeQueryParams } = useRouter()

  const renderImage = (imageUrl: string | null): JSX.Element => {
    const hasImage = !IsNullOrUndefined(imageUrl)
    const imageContainerStyles: { [key: string]: string | number } = {}

    if (hasImage) {
      imageContainerStyles.backgroundImage = `url(${imageUrl!})`
    } else {
      imageContainerStyles.background = 'rgba(0,0,0,0.03)'
    }

    return (
      <Box className={classes.imageContainer} style={imageContainerStyles}>
        {!hasImage && (
          <SvgIcon>
            <ImageIcon />
          </SvgIcon>
        )}
      </Box>
    )
  }

  const renderContent = (): JSX.Element => {
    return (
      <React.Fragment>
        {posts.map((post) => {
          return (
            <Box key={post.id} className={classes.post}>
              <Box className={classes.postTitle}>
                <Typography
                  variant="h5"
                  color="primary"
                  style={{
                    display: 'inline',
                  }}
                >
                  {`${getTypeLabel(post.type)}: `}
                </Typography>
                <Typography
                  variant="h5"
                  color="textPrimary"
                  style={{
                    display: 'inline',
                  }}
                >
                  {post.title}
                </Typography>
              </Box>
              <ScrollContainer
                className={classes.postImagesContainer}
                nativeMobileScroll
              >
                <Card className={classes.postImageCard} elevation={4}>
                  <CardContent className={classes.cardContent}>
                    <Typography
                      variant="subtitle2"
                      style={{
                        fontWeight: 600,
                      }}
                      color="textPrimary"
                    >
                      Cover Image
                    </Typography>
                  </CardContent>
                  <ImageUploader
                    handleUpload={(fileParams: MediaUploadModel): void => {
                      uploadPostImage(post.id, fileParams)
                    }}
                  >
                    {renderImage(post.media?.imageUrl ?? null)}
                  </ImageUploader>
                  <Box className={classes.cardInfoPopup}>
                    <Typography
                      variant="subtitle2"
                      style={{
                        fontWeight: 600,
                        color: 'white',
                      }}
                      color="textPrimary"
                    >
                      {post.title}
                    </Typography>
                  </Box>
                </Card>
                {!!post.results && post.results.length > 0 && (
                  <Results results={post.results} postId={post.id} />
                )}
                {post.questions.map((question, index) => (
                  <Card
                    key={question.id}
                    className={classes.postImageCard}
                    elevation={4}
                  >
                    <CardContent className={classes.cardContent}>
                      <Typography
                        variant="subtitle2"
                        style={{
                          fontWeight: 600,
                        }}
                        color="textPrimary"
                      >
                        {`Question ${index + 1}`}
                      </Typography>
                    </CardContent>
                    <ImageUploader
                      handleUpload={(fileParams: MediaUploadModel): void => {
                        uploadQuestionImage(post.id, question.id, fileParams)
                      }}
                    >
                      {renderImage(question.media?.imageUrl ?? null)}
                    </ImageUploader>
                    <Box className={classes.cardInfoPopup}>
                      <Typography
                        variant="subtitle2"
                        style={{
                          fontWeight: 600,
                          color: 'white',
                        }}
                      >
                        {question.text}
                      </Typography>
                    </Box>
                  </Card>
                ))}
              </ScrollContainer>
            </Box>
          )
        })}
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <Box className={classes.panelHeader}>
        <Box className={classes.panelTitle}>
          <Typography variant="h5" color="textPrimary">
            {`Post Media for ${moment(props.date).format('Do MMMM YYYY')}`}
          </Typography>
        </Box>
        <Box className={classes.rightHeaderSection}>
          {
            <IconButton onClick={(): void => removeQueryParams()} size="large">
              <SvgIcon>
                <CrossIcon />
              </SvgIcon>
            </IconButton>
          }
        </Box>
      </Box>
      <Divider />
      <Box className={classes.contentWrapper}>{renderContent()}</Box>
    </React.Fragment>
  );
}
