import React from 'react'
import classNames from 'classnames'
import { Theme, Box, Card, CardActionArea, CardContent, Chip, Typography, SvgIcon } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { Tag } from 'types/modules/tags/models/entities/tag'
import { ImageIcon } from 'components/icons'
import { useIconStyles } from 'hooks/use-icon-styles'

const IMAGE_HEIGHT = '100px'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tagCard: {
      width: '100%',
      marginBottom: '1.25rem',
      overflow: 'initial',
      boxShadow: 'none',
      border: '1px solid rgba(0, 0, 0, 0.12)',
    },
    tagDetailsWrapper: {
      display: 'flex',
      height: IMAGE_HEIGHT,
      width: '100%',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    tagHeader: {
      display: 'flex',
      width: '100%',
    },
    tagName: {
      display: 'flex',
      flexGrow: 1,
      alignItems: 'center',
    },
    tagMeta: {
      display: 'flex',
      flexGrow: 1,
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    metaField: {
      display: 'flex',
      marginLeft: '1.25rem',
    },
    metaIcon: {
      width: 16,
      height: 16,
    },
    metaText: {
      marginLeft: '0.40rem',
    },
    tagCardContent: {
      marginTop: '1.25rem',
      display: 'flex',
      overflow: 'hidden',
      minHeight: 36,
    },
    cardChip: {
      marginRight: '0.5rem',
      cursor: 'pointer',
    },
    contentWrapper: {
      display: 'flex',
    },

    coverImageContainer: {
      display: 'flex',
      width: 130,
      height: IMAGE_HEIGHT,
      background: 'rgba(0,0,0,0.03)',
      borderRadius: 10,
      overflow: 'hidden',
      marginRight: '0.625rem',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
    },
  }),
)

interface ITagCardProps {
  tag: Tag
  onClick: () => void
}

export const TagCard: React.FC<ITagCardProps> = (props: ITagCardProps) => {
  const { tag } = props
  const classes = useStyles()
  const iconClasses = useIconStyles()
  const hasCoverImage = !IsNullOrUndefined(tag.media?.imageUrl)
  const eventCoverContainerStyles: { [key: string]: string | number } = {}

  if (hasCoverImage) {
    eventCoverContainerStyles.backgroundImage = `url(${tag.media!.imageUrl!})`
  } else {
    eventCoverContainerStyles.background = 'rgba(0,0,0,0.03)'
  }

  const renderTagGroupsCount = (count: number | null): string => {
    const tagGroupLabel = 'Tag Group'

    if (IsNullOrUndefined(count)) count = 0

    let result = `${count} ${tagGroupLabel}`

    return count !== 1 ? (result += 's') : result
  }

  return (
    <Card className={classes.tagCard}>
      <CardActionArea onClick={props.onClick}>
        <CardContent>
          <Box className={classes.contentWrapper}>
            <Box
              className={classes.coverImageContainer}
              style={eventCoverContainerStyles}
            >
              {!hasCoverImage && (
                <SvgIcon>
                  <ImageIcon />
                </SvgIcon>
              )}
            </Box>
            <Box className={classes.tagDetailsWrapper}>
              <Box className={classes.tagHeader}>
                <Box className={classes.tagName}>
                  <Typography variant="h6" color="textPrimary">
                    {`${props.tag.media?.icon ?? ' '} ${props.tag.name}`}
                  </Typography>
                </Box>
                <Box className={classes.tagMeta}>
                  {props.tag.activePosts && (
                    <Box className={classes.metaField}>
                      <i
                        className={classNames(
                          classes.metaIcon,
                          iconClasses.postsIcon
                        )}
                      />
                    </Box>
                  )}
                  {props.tag.tagGroups && (
                    <Box className={classes.metaField}>
                      <i
                        className={classNames(
                          classes.metaIcon,
                          iconClasses.tagsIcon
                        )}
                      />
                      <Typography
                        variant={'caption'}
                        className={classes.metaText}
                      >
                        {renderTagGroupsCount(props.tag.tagGroups!.length)}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
              <Box className={classes.tagCardContent}>
                {props.tag.tagGroups?.map((tagGroup) => (
                  <Chip
                    key={`tag-${props.tag.id}-tagGroup-${tagGroup.tagGroupId}`}
                    label={tagGroup.name}
                    className={classes.cardChip}
                  />
                ))}
              </Box>
            </Box>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}
