import { Button, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx'
import { ReviewIcon } from 'components/icons'
import { usePrizeListContext } from 'modules/prizes/context/prize-list'
import React from 'react'
import PrizeStatus from 'types/modules/prizes/enums/prize-status'
import { IPrizeActionButtonProps } from 'types/modules/prizes/models/props/prize-action-button-props'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.text.primary,
      backgroundColor: '#ecf0f1',
      '&:hover': {
        backgroundColor: '#f2f5f5',
      },
    },
  }),
)

export const ReviewPrizeButton: React.FC<IPrizeActionButtonProps> = (
  props: IPrizeActionButtonProps,
) => {
  const { setCurrentPrize } = usePrizeListContext()
  const classes = useStyles()
  const isPending = props.prize.status === PrizeStatus.Pending
  const isScheduled = props.prize.status === PrizeStatus.Scheduled

  return isPending || isScheduled ? (
    <Button
      variant="contained"
      color="secondary"
      startIcon={<ReviewIcon />}
      className={clsx(classes.root)}
      size="small"
      onClick={(e): void => {
        setCurrentPrize(props.prize.id, props.prize.type)
        props.onPopoverClose && props.onPopoverClose()
      }}
    >
      Review
    </Button>
  ) : null
}
