import React from 'react'
import { Box, ButtonBase, Popover, SvgIcon, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { ICalendarGridCellProps } from 'components/calendar/props/calendar-grid-cell-props'
import { IPrize } from 'types/modules/prizes/models/entities/prize'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { usePopover } from 'components/popover/use-popover'
import { TimeGridPrize } from 'modules/prizes/views/components/dashboard/prize-calendar-time-grid-cell/prize'
import { PrizeTimeGridContextMenu } from 'modules/prizes/views/components/dashboard/prize-calendar-time-grid-cell/context-menu'
import { TimeGridPrizeMultiview } from 'modules/prizes/views/components/dashboard/prize-calendar-time-grid-cell/multiview'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    prizeGridCellContainer: {
      display: 'flex',
      flex: 1,
      '&:hover': {
        background: 'rgba(0,0,0,0.03)',
        cursor: 'pointer',
      },
    },
    emptyCell: {
      display: 'flex',
      flex: 1,
    },
  }),
)

export const PrizeCalendarTimeGridCell: React.FC<
  ICalendarGridCellProps<IPrize>
> = (props: ICalendarGridCellProps<IPrize>) => {
  const { items, dateTime, sortBy } = props

  const classes = useStyles()

  const contextMenu = usePopover({
    id: `context-menu-${dateTime}`,
    anchorReference: 'anchorPosition',
  })

  const renderEmptyView = (): JSX.Element | null => {
    return (
      <React.Fragment>
        <Box
          className={classes.emptyCell}
          onContextMenu={(e: React.MouseEvent<HTMLDivElement>): void => {
            e.preventDefault()
            contextMenu.onClick(e)
          }}
        />
        <PrizeTimeGridContextMenu
          dateTime={dateTime}
          dateField={sortBy}
          popoverProps={contextMenu.popoverProps}
        />
      </React.Fragment>
    )
  }

  const renderViewSelector = (): JSX.Element | null => {
    if (IsNullOrUndefined(items) || items.length === 0) {
      return renderEmptyView()
    }

    switch (items.length) {
      case 1:
        return <TimeGridPrize prize={items[0]} index={0} dateField={sortBy} />
      default:
        return (
          <TimeGridPrizeMultiview
            prizes={items}
            dateTime={dateTime}
            dateField={sortBy}
          />
        )
    }
  }

  return (
    <Box className={classes.prizeGridCellContainer}>{renderViewSelector()}</Box>
  )
}
