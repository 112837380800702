import { Box, Button, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useInfoCardContext } from 'modules/info-cards/context'
import React from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    infoCardListHeaderPanel: {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      minWidth: 250,
      '& .MuiButton-contained': {
        marginBottom: 20,
        '&:last-of-type': {
          marginBottom: 0,
        },
      },
    },
    createInfoCardButton: {
      color: theme.palette.primary.contrastText,
      backgroundColor: '#ff787d',
      '&:hover': {
        backgroundColor: '#ff999c',
      },
    },
  }),
)

export const InfoCardListHeaderPanel: React.FC<{}> = () => {
  const classes = useStyles()

  const { createInfoCardDraft } = useInfoCardContext()

  return (
    <Box className={classes.infoCardListHeaderPanel}>
      <Button
        variant="contained"
        size="large"
        className={classes.createInfoCardButton}
        onClick={(): void => createInfoCardDraft()}
      >
        Create
      </Button>
    </Box>
  )
}
