import { MODULE_NAME } from 'modules/news-cards/constants'

const ActionTypes = {
  SET_CURRENT_IAP_PRODUCT_ID: `${MODULE_NAME}/SET_CURRENT_IAP_PRODUCT_ID`,
  GET_IAP_PRODUCTS: `${MODULE_NAME}/GET_IAP_PRODUCTS`,
  GET_IAP_PRODUCT_BY_ID: `${MODULE_NAME}/GET_IAP_PRODUCT_BY_ID`,
  CREATE_IAP_PRODUCT: `${MODULE_NAME}/CREATE_IAP_PRODUCT`,
  CREATE_IAP_PRODUCT_DRAFT: `${MODULE_NAME}/CREATE_IAP_PRODUCT_DRAFT`,
  UPDATE_IAP_PRODUCT: `${MODULE_NAME}/UPDATE_IAP_PRODUCT`,
  DELETE_IAP_PRODUCT: `${MODULE_NAME}/DELETE_IAP_PRODUCT`,
}

export default ActionTypes
