export const RouteParams = {
  TYPE: '/:type',
  POST_ID: '/:postId',
  POST_GROUP_ID: '/:postGroupId',
  POST_GROUP_PAGE: '/:postGroupPage(posts)',
  QUESTION_ID: '/:questionId',
  RESULT_ID: '/:resultId',
  PRIZE_ID: '/:prizeId',
  SHOW_ID: '/:showId',
  EVENT_ID: `/:eventId`,
  TAG_CODE: '/:tagCode',
  EVENT_PAGE: '/:eventPage(posts|rewards|quests|schedule)',
  DASHBOARD_VIEW: '/:dashboardView(list|calendar|timeline)',
}
