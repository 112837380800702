import QuestActionTypes from 'modules/quests/action-types/quest-action-types'
import { Reducer } from 'redux'
import { actionTypeSuccess, cacheItem, cacheItems } from 'core/redux/utils'
import { ISimpleAction, ISuccessAction } from 'types/redux/interfaces/IAction'
import _ from 'core/utils/deepdash'
import { mergeArrays } from 'core/utils/mergeArrays'
import { IQuestActionParams } from 'modules/quests/actions/quest-actions'
import { NIL as NIL_UUID } from 'uuid'
import { IQuest } from 'modules/quests/models/quest'
import { IQuestState } from 'modules/quests/models/state'
import { PAGE_SIZE } from 'modules/quests/constants'


const initialState: IQuestState = {
  count: 0,
  page: 0,
  pageSize: PAGE_SIZE,
  items: [],
  cache: {},
}

export type GetQuestsApiResponse = {
  count: number
  page: number
  pageSize: number
  items: IQuest[]
}


type AllowedActionTypes = ISuccessAction | ISimpleAction

const replaceItem = (state: IQuestState, questIndex: number, quest: IQuest): IQuestState => {
  return {
    ...state,
    items: questIndex >= 0
      ? state.items.map((item, index) => index === questIndex ? quest : item)
      : [...state.items, quest],
    cache: cacheItem(state.cache, quest)
  }
}

const handlePutResponse = (
  state: IQuestState,
  action: AllowedActionTypes
): IQuestState => {
  const successAction = action as ISuccessAction
  const response = successAction.data as IQuest
  const payloadItem = successAction.payload!.item as IQuest
  const questIndex = state.items.findIndex((x) => x.id === response.id)

  return replaceItem(state, questIndex, payloadItem)
}

const handleActivationToggle = (
  state: IQuestState,
  action: AllowedActionTypes
): IQuestState => {
  const successAction = action as ISuccessAction
  const response = successAction.data as IQuest
  const questIndex = state.items.findIndex((x) => x.id === response.id)

  return replaceItem(state, questIndex, response)
}

const actionHandlers = {
  [QuestActionTypes.SET_CURRENT_QUEST_ID]: (state: IQuestState, action: AllowedActionTypes): IQuestState => {
    const simpleAction = action as ISimpleAction
    const { questId } = simpleAction.payload as IQuestActionParams
    return {
      ...state,
      currentQuestId: questId ? questId : undefined
    }
  },
  [QuestActionTypes.CREATE_QUEST_DRAFT]: (state: IQuestState, action: AllowedActionTypes): IQuestState => {
    const simpleAction = action as ISimpleAction
    const { item } = simpleAction.payload as IQuestActionParams

    return {
      ...state,
      currentQuestId: NIL_UUID,
      items: [item as IQuest, ...state.items]
    }
  },
  [actionTypeSuccess(QuestActionTypes.GET_QUESTS)]: (state: IQuestState, action: AllowedActionTypes): IQuestState => {
    const successAction = action as ISuccessAction
    const response = successAction.data as GetQuestsApiResponse
    return {
      ...state,
      items: mergeArrays(state.items, response.items!),
      cache: cacheItems(state.cache, response.items.map((item) => ({
        ...item,
        questRewards: state.cache[item.id!]?.questRewards || [],
        questTasks: state.cache[item.id!]?.questTasks || [],
      }))),
      page: response.page,
      count: response.count
    }
  },
  [actionTypeSuccess(QuestActionTypes.GET_QUEST_BY_ID)]: (
    state: IQuestState,
    action: AllowedActionTypes
  ): IQuestState => {
    const successAction = action as ISuccessAction
    const response = successAction.data as IQuest

    return {
      ...state,
      cache: cacheItem(state.cache, response)
    }
  },
  [actionTypeSuccess(QuestActionTypes.CREATE_QUEST)]: (
    state: IQuestState,
    action: AllowedActionTypes
  ): IQuestState => {
    const successAction = action as ISuccessAction
    const responseItem = successAction.data as IQuest
    return {
      ...state,
      items: [responseItem, ...state.items],
      cache: cacheItem(state.cache, responseItem)
    }
  },
  [actionTypeSuccess(QuestActionTypes.ACTIVATE_QUEST)]: handleActivationToggle,
  [actionTypeSuccess(QuestActionTypes.DEACTIVATE_QUEST)]: handleActivationToggle,
  [actionTypeSuccess(QuestActionTypes.UPDATE_QUEST)]: handlePutResponse
}

const questsReducer: Reducer<IQuestState, AllowedActionTypes> = (
  state = initialState,
  action: AllowedActionTypes,
) => {
  return actionHandlers[action.type]
    ? actionHandlers[action.type](state, action)
    : state
}

export default questsReducer