import { CognitoUser } from 'amazon-cognito-identity-js'
import { createAction } from 'core/redux/utils'
import { ISimpleAction } from 'types/redux/interfaces/IAction'
import * as ActionTypes from '../action-types'

export const authenticationSuccess = (params: {
  user: CognitoUser
}): ISimpleAction => createAction(ActionTypes.AUTHENTICATION_SUCCESS, params)

export const authenticationFailed = (params: {
  error: string
}): ISimpleAction => createAction(ActionTypes.AUTHENTICATION_FAILED, params)

export const logoutSuccess = (): ISimpleAction =>
  createAction(ActionTypes.LOGOUT_SUCCESS)
