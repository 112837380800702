import { List } from 'immutable'
import {
  CognitoUser,
  CognitoUserSession,
  CognitoUserAttribute,
} from 'amazon-cognito-identity-js'

import OneDirectionalMapper from 'core/mappers/one-directional-mapper'

import Role from 'types/modules/account/enums/role'
import { PortalUser } from 'types/modules/users/portal/models/entities/portal-user'

// Todo: Remove once login section has been refactored
class UserApiStateMapper extends OneDirectionalMapper<CognitoUser, PortalUser> {
  // eslint-disable-next-line no-dupe-class-members
  public map(from: CognitoUser): PortalUser

  // eslint-disable-next-line no-dupe-class-members
  public map(from: CognitoUser, to: PortalUser): void

  // eslint-disable-next-line no-dupe-class-members
  public map(from: CognitoUser, to?: PortalUser): PortalUser | void {
    const userSession: CognitoUserSession =
      from.getSignInUserSession() as CognitoUserSession
    const jwtToken: string = userSession.getIdToken().getJwtToken()

    const payload = userSession.getIdToken().decodePayload()
    const groups: string[] | undefined = payload['cognito:groups']

    let roles: List<Role> = List.of<Role>()
    if (groups) {
      roles = List.of<Role>(...groups.map((value: string) => value as Role))
    }

    if (to) {
      to.id = payload.sub
      to.username = from.getUsername()
      to.email = payload.email
      to.token = jwtToken
      to.roles = roles
    } else {
      const result: PortalUser = {
        id: payload.sub,
        username: from.getUsername(),
        email: payload.email,
        token: jwtToken,
        roles: roles,
      }

      return result
    }
  }
}

export default new UserApiStateMapper()
