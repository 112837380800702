/* Libs */
import { all, AllEffect, ForkEffect, takeEvery } from 'redux-saga/effects'

/* Module */
import { ActionTypes } from '../action-types'
import { GetMobileAppUsersSagas } from './get-mobile-app-users-saga'

type RootSagaReturnType = Generator<
  ForkEffect<never> | AllEffect<any>,
  void,
  any
>

export const RootSaga = function* (): RootSagaReturnType {
  yield all([
    yield takeEvery(ActionTypes.GET_MOBILE_APP_USERS, GetMobileAppUsersSagas),
    yield takeEvery(ActionTypes.GET_MOBILE_APP_USER, GetMobileAppUsersSagas),
    yield takeEvery(ActionTypes.UPDATE_MOBILE_APP_USER, GetMobileAppUsersSagas),
    yield takeEvery(ActionTypes.SWITCH_USERS, GetMobileAppUsersSagas),
    yield takeEvery(
      ActionTypes.UPDATE_MOBILE_APP_USER_CURRENCY,
      GetMobileAppUsersSagas,
    ),
    yield takeEvery(
      ActionTypes.DISABLE_MOBILE_APP_USER,
      GetMobileAppUsersSagas,
    ),
    yield takeEvery(ActionTypes.GET_TICKETS, GetMobileAppUsersSagas),
    yield takeEvery(ActionTypes.VOID_TICKET, GetMobileAppUsersSagas),
    yield takeEvery(
      ActionTypes.GET_AUTOCOMPLETE_SEARCH_MOBILE_APP_USERS,
      GetMobileAppUsersSagas,
    ),
  ])
}
