/* eslint-disable id-blacklist */
import { PopoverProps } from '@mui/material'
import React, { useState } from 'react'

export interface IPopoverApi {
  onClick: (event: React.MouseEvent<HTMLElement>) => void
  onChange: (event: React.ChangeEvent<HTMLElement>) => void
  popoverProps: IPopoverProps
}

type PopoverPosition = {
  top: number
  left: number
}

export interface IPopoverProps extends PopoverProps {
  onClose: () => void
}

export const usePopover = (props: Partial<PopoverProps>): IPopoverApi => {
  const { id: popoverId } = props

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const [anchorPosition, setAnchorPosition] = useState<PopoverPosition | null>(
    null,
  )

  const handlePopoverClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget)
    setAnchorPosition({
      top: event.pageY,
      left: event.pageX,
    })
  }

  const handlePopoverChange = (event: React.ChangeEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = (): void => {
    setAnchorEl(null)
    setAnchorPosition(null)
  }

  const open = Boolean(anchorEl) || Boolean(anchorPosition)

  const id = open ? popoverId : undefined

  return {
    onClick: handlePopoverClick,
    onChange: handlePopoverChange,
    popoverProps: {
      ...props,
      id,
      anchorPosition:
        props.anchorReference === 'anchorPosition' && open
          ? anchorPosition!
          : undefined,
      anchorEl:
        props.anchorReference === 'anchorPosition' ? undefined : anchorEl,
      onClose: handlePopoverClose,
      open,
    },
  }
}
