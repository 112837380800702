export enum PrizeActionButtonType {
  Feature = 'Feature',
  Promote = 'Promote',
  Reactivate = 'Reactivate',
  Close = 'Close',
  Review = 'Review',
  View = 'View',
  Approve = 'Approve',
  Submit = 'Submit',
  Restore = 'Restore',
  Discard = 'Discard',
  Duplicate = 'Duplicate',
}
