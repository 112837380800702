import { Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { RemoveIcon } from 'components/icons'
import {
  removeItemFromFieldArray,
} from 'core/api/utils/formik-array-helpers'
import { IQuestionFormProps } from 'modules/posts/views/components/manage-post-panel/types/question-form-props'
import React from 'react'
import PostType from 'types/modules/posts/enums/post-type'
import { OptionField } from 'types/modules/posts/enums/option-field'
import { QuestionField } from 'types/modules/posts/enums/question-field'
import _ from 'core/utils/deepdash'
import { LabelWithCharLimit } from 'modules/posts/views/components/manage-post-panel/label-with-char-limit'
import { Volume } from 'modules/posts/views/components/manage-post-panel/volume'
import { sortQuestionOptionsByOrder } from 'utils/common'

const useStyles = makeStyles(() =>
  createStyles({
    inputLabel: {
      display: 'flex',
      marginBottom: '0.625rem',
      justifyContent: 'space-between',
    },
    adornment: { marginRight: 8 },
    select: {
      marginBottom: '1.25rem',
    }
  }),
)

export const QuestionViewOptions: React.FC<IQuestionFormProps> = (
  props: IQuestionFormProps,
) => {
  const {
    post,
    question,
    handleChange,
    setFieldValue,
    errors,
  } = props

  const classes = useStyles()
  const totalVolume = question.options.reduce((acc, curr) => acc + (curr.volume || 0), 0)
  const onClick = (index: number): void => {
    removeItemFromFieldArray(
      index,
      question.options,
      (newValues): void => {
        newValues = newValues.map((val) => {
          val.probability = 1 / newValues.length
          return val
        })
        setFieldValue(QuestionField.OPTIONS, newValues)
      },
    )
  }

  return (
    <Grid container spacing={2}>
      {sortQuestionOptionsByOrder(question.options).map((option, index) => {
        const optionNumber = index + 1
        const optionField = `${QuestionField.OPTIONS}[${index}]`
        const optionTextField = `${optionField}[${OptionField.TEXT}]`
        const optionOddsField = `${optionField}[${OptionField.PROBABILITY}]`
        const optionVolumeField = `${optionField}[${OptionField.VOLUME}]`
        const optionResultIdField = `${optionField}[${OptionField.RESULT_ID}]`
        const error =
          errors?.[QuestionField.OPTIONS]?.[index]?.[OptionField.TEXT]
        return (
          <React.Fragment
            key={`question-${question.id}-option-${option.id ?? index}`}
          >
            <Grid item xs={post.type !== PostType.Test ? 10 : 12}>
              <LabelWithCharLimit
                htmlFor={optionTextField}
                label={`Option ${optionNumber}`}
                valueLength={option[OptionField.TEXT].length}
                limit={80}
              />
              <TextField
                id={optionTextField}
                name={optionTextField}
                onChange={handleChange as React.ChangeEventHandler<HTMLInputElement>}
                multiline
                value={option[OptionField.TEXT]}
                variant="filled"
                inputProps={{
                  maxLength: 80,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" className={classes.adornment}>
                      <IconButton
                        size="small"
                        onClick={(): void => onClick(index)}
                      >
                        <RemoveIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={!!error}
                helperText={error}
                fullWidth
              />
            </Grid>
            {post.type === PostType.Bet && (
              <Grid item xs={2}>
                <InputLabel
                  htmlFor={optionOddsField}
                  className={classes.inputLabel}
                >
                  Odds
                </InputLabel>
                <TextField
                  id={optionOddsField}
                  name={optionOddsField}
                  onChange={handleChange as React.ChangeEventHandler<HTMLInputElement>}
                  value={
                    isNaN(option[OptionField.PROBABILITY]) || !option[OptionField.PROBABILITY]
                      ? 0.01
                      : option[OptionField.PROBABILITY].toPrecision(2)
                  }
                  variant="filled"
                  type="number"
                  InputProps={{
                    inputProps: {
                      min: 0.01,
                      max: 1,
                      step: 0.01,
                    },
                  }}
                  fullWidth
                />
              </Grid>
            )}
            {[PostType.Game, PostType.Poll].includes(post.type) && (
              <Grid item xs={2}>
                <InputLabel
                  htmlFor={optionVolumeField}
                  className={classes.inputLabel}
                >
                  Volume
                </InputLabel>
                <TextField
                  id={optionVolumeField}
                  name={optionVolumeField}
                  value={option[OptionField.VOLUME]}
                  variant="filled"
                  fullWidth
                />
              </Grid>
            )}
            {post.type === PostType.Test && (
              <Grid item xs={12}>
                <Select
                  id={optionResultIdField}
                  name={optionResultIdField}
                  value={option[OptionField.RESULT_ID]}
                  startAdornment={
                    <InputAdornment position="start">
                      Correlation:
                    </InputAdornment>
                  }
                  onChange={handleChange}
                  variant="filled"
                  className={classes.select}
                  displayEmpty
                  fullWidth
                >
                  {post.results.map((result, index) => (
                    <MenuItem
                      key={`option-${option.id}-correlation-result-${result.id}`}
                      value={result.id}
                    >
                      {result.text}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            )}
            <Grid item xs={12}>
              <Volume total={totalVolume} volume={option.volume} />
            </Grid>
          </React.Fragment>
        )
      })}
    </Grid>
  )
}
