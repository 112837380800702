/* Types */
import { EndpointRoute } from 'types/common/api/models/entities/endpoint-route'
import { RequestMethod } from 'types/common/api/enums/request-method'

/* Module */
import filters from './filters'
import params from './params'
import paths from './paths'

const PostsEndpoints = {
  getPosts: {
    method: RequestMethod.GET,
    path: [paths.posts],
    filters,
  },
  getPostById: {
    method: RequestMethod.GET,
    path: [paths.posts, params.postId],
  },
  createPost: {
    method: RequestMethod.POST,
    path: [paths.posts],
  },
  duplicatePost: {
    method: RequestMethod.POST,
    path: [paths.posts, params.postId, paths.duplicate],
  },
  updatePost: {
    method: RequestMethod.PUT,
    path: [paths.posts, params.postId],
  },
  updatePostTags: {
    method: RequestMethod.PUT,
    path: [paths.posts, params.postId, paths.tags],
  },
  uploadPostImage: {
    method: RequestMethod.PUT,
    path: [paths.posts, params.postId, paths.images, params.extension],
  },
  updatePostStatus: {
    method: RequestMethod.PUT,
    path: [paths.posts, params.postId, paths.updateStatus, params.postStatus],
  },
  settlePostTickets: {
    method: RequestMethod.PUT,
    path: [paths.posts, params.postId, paths.tickets, paths.settle],
  },
  promotePost: {
    method: RequestMethod.PUT,
    path: [paths.posts, params.postId, paths.promote],
  },
  updateStakeDistribution: {
    method: RequestMethod.PUT,
    path: [paths.posts, paths.updateStake],
  },
  setPostCompromised: {
    method: RequestMethod.PUT,
    path: [paths.posts, params.postId, paths.setCompromised],
  },
  deletePost: {
    method: RequestMethod.DELETE,
    path: [paths.posts, params.postId],
  },
  clearTrash: {
    method: RequestMethod.DELETE,
    path: [paths.posts, paths.clearTrash],
  },
  addQuestion: {
    method: RequestMethod.POST,
    path: [paths.posts, params.postId, paths.questions],
  },
  getQuestionById: {
    method: RequestMethod.GET,
    path: [paths.posts, params.postId, paths.questions, params.questionId],
  },
  getQuestionAlerts: {
    method: RequestMethod.GET,
    path: [
      paths.posts,
      params.postId,
      paths.questions,
      params.questionId,
      paths.alerts,
    ],
  },
  updateQuestion: {
    method: RequestMethod.PUT,
    path: [paths.posts, params.postId, paths.questions, params.questionId],
  },
  saveQuestionAndOptions: {
    method: RequestMethod.PUT,
    path: [
      paths.posts,
      params.postId,
      paths.questions,
      params.questionId,
      paths.save,
    ],
  },
  uploadQuestionImage: {
    method: RequestMethod.PUT,
    path: [
      paths.posts,
      params.postId,
      paths.questions,
      params.questionId,
      paths.images,
      params.extension,
    ],
  },
  deleteQuestion: {
    method: RequestMethod.DELETE,
    path: [paths.posts, params.postId, paths.questions, params.questionId],
  },
  deleteQuestionAlert: {
    method: RequestMethod.DELETE,
    path: [
      paths.posts,
      params.postId,
      paths.questions,
      params.questionId,
      paths.alerts,
      params.alertId,
    ],
  },
  addResult: {
    method: RequestMethod.POST,
    path: [paths.posts, params.postId, paths.results],
  },
  getResultById: {
    method: RequestMethod.GET,
    path: [paths.posts, params.postId, paths.results, params.resultId],
  },
  updateResult: {
    method: RequestMethod.PUT,
    path: [paths.posts, params.postId, paths.results, params.resultId],
  },
  uploadResultImage: {
    method: RequestMethod.PUT,
    path: [
      paths.posts,
      params.postId,
      paths.results,
      params.resultId,
      paths.images,
      params.extension,
    ],
  },
  deleteResult: {
    method: RequestMethod.DELETE,
    path: [paths.posts, params.postId, paths.results, params.resultId],
  },
  addOption: {
    method: RequestMethod.POST,
    path: [
      paths.posts,
      params.postId,
      paths.questions,
      params.questionId,
      paths.options,
    ],
  },
  getOptionById: {
    method: RequestMethod.GET,
    path: [
      paths.posts,
      params.postId,
      paths.questions,
      params.questionId,
      paths.options,
      params.optionId,
    ],
  },
  updateOption: {
    method: RequestMethod.PUT,
    path: [
      paths.posts,
      params.postId,
      paths.questions,
      params.questionId,
      paths.options,
      params.optionId,
    ],
  },
  uploadOptionImage: {
    method: RequestMethod.PUT,
    path: [
      paths.posts,
      params.postId,
      paths.questions,
      params.questionId,
      paths.options,
      params.optionId,
      paths.images,
      paths.upload,
    ],
  },
  deleteOption: {
    method: RequestMethod.DELETE,
    path: [
      paths.posts,
      params.postId,
      paths.questions,
      params.questionId,
      paths.options,
      params.optionId,
    ],
  },
  addAnswer: {
    method: RequestMethod.POST,
    path: [
      paths.posts,
      params.postId,
      paths.questions,
      params.questionId,
      paths.answers,
    ],
  },
  getAnswerById: {
    method: RequestMethod.GET,
    path: [
      paths.posts,
      params.postId,
      paths.questions,
      params.questionId,
      paths.answers,
      params.answerId,
    ],
  },
  updateAnswer: {
    method: RequestMethod.PUT,
    path: [
      paths.posts,
      params.postId,
      paths.questions,
      params.questionId,
      paths.answers,
      params.answerId,
    ],
  },
  uploadanswerImage: {
    method: RequestMethod.PUT,
    path: [
      paths.posts,
      params.postId,
      paths.questions,
      params.questionId,
      paths.answers,
      params.answerId,
      paths.images,
      paths.upload,
    ],
  },
  deleteAnswer: {
    method: RequestMethod.DELETE,
    path: [
      paths.posts,
      params.postId,
      paths.questions,
      params.questionId,
      paths.answers,
      params.answerId,
    ],
  },
  addAnswerOptionMatch: {
    method: RequestMethod.POST,
    path: [
      paths.posts,
      params.postId,
      paths.questions,
      params.questionId,
      paths.answers,
      paths.matches,
    ],
  },
  getAnswerOptionMatchById: {
    method: RequestMethod.GET,
    path: [
      paths.posts,
      params.postId,
      paths.questions,
      params.questionId,
      paths.answers,
      params.answerId,
      paths.matches,
      params.matchId,
    ],
  },
  updateAnswerOptionMatch: {
    method: RequestMethod.PUT,
    path: [
      paths.posts,
      params.postId,
      paths.questions,
      params.questionId,
      paths.answers,
      params.answerId,
      paths.matches,
      params.matchId,
    ],
  },
  deleteAnswerOptionMatch: {
    method: RequestMethod.DELETE,
    path: [
      paths.posts,
      params.postId,
      paths.questions,
      params.questionId,
      paths.answers,
      params.answerId,
      paths.matches,
      params.matchId,
    ],
  },
  getPostStatuses: {
    method: RequestMethod.GET,
    path: [paths.posts, paths.statuses],
  },
}

export default PostsEndpoints
