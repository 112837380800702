import { Currency } from 'fe-shared-resources'

export enum ProductType {
  NotSet = 'NotSet',
  Coin = 'Coin',
  Diamond = 'Diamond',
  PremiumPost = 'PremiumPost',
  NewUserOffer1 = 'NewUserOffer1',
  NewUserOffer2 = 'NewUserOffer2',
  NewUserOffer3 = 'NewUserOffer3'
}

export enum ProductStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

export enum ConsumbaleType {
  SuperBetToken = 'SuperBetToken',
  LotteryTripleEntries = 'LotteryTripleEntries',
  LotteryDoubleUp = 'LotteryDoubleUp',
}

export interface IIapProductCurrencyAndAmount {
  currency: Currency
  amount: number
  id?: number
}

export interface IIapProductConsumables {
  id: string
  type: ConsumbaleType
}

export interface IIapProductConfig {
  prizes?: string[]
  currencies?: IIapProductCurrencyAndAmount[]
  consumable?: IIapProductConsumables
}

export interface IIapProductMetData {
  productConfig: IIapProductConfig
}

export interface IIapProduct {
  id: string
  description: string
  metaData: IIapProductMetData
  status: ProductStatus
  type: ProductType
  createdAt?: string
  updatedAt?: string
  secondsFromUserCreationToStart?: string | number
  secondsFromUserCreationToEnd?: string | number
}
