import React from 'react'
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Box, Button, ButtonProps, Grid } from '@mui/material'
import { NIL as NIL_UUID } from 'uuid'
import { PanelContent, PanelFooter } from 'components/panel'
import _ from 'core/utils/deepdash'
import { useFormik } from 'formik'
import { colors } from 'utils/colors'
import { useMilestonesContext } from 'modules/milestones/context/milestone-context'
import { IMilestone } from 'modules/milestones/models/milestone'
import { MilestoneForm } from 'modules/milestones/view/milestone-modal/milestone-form'
import { MilestoneSchema } from 'modules/milestones/validation/milestone-schema'
import { ModalWrapper } from 'components/modal-wrapper'
import { ConfirmationDialog } from 'components/confirmation-dialog'
import { Currency } from 'fe-shared-resources';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
    },
    panelHeader: {
      display: 'flex',
      width: '100%',
      flexShrink: 0,
    },
    panelTitle: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      padding: '0 0 0 1.25rem',
    },
    navigationContainer: {
      display: 'flex',
      flex: 2,
      flexGrow: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    rightHeaderSection: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      padding: '0 1.25rem 0 0',
      flexDirection: 'row-reverse',
      '& .MuiButton-contained': {
        marginRight: 20,
      },
    },
    footerContent: {
      display: 'flex',
      flex: 1,
      justifyContent: 'space-between',
    },
    cancelBtn: {
      marginRight: '1.25rem',
    },
    wrapper: {
      borderLeft: `1px solid ${colors.rgba_0_0_0_12}`,
    },
    form: {
      height: '600px',
      overflow: 'auto',
    },
  }),
)

const buttonProps: ButtonProps = {
  variant: 'contained',
  size: 'large',
}

export const MilestoneModal: React.FC = () => {
  const classes = useStyles()
  const {
    currentMilestone,
    updateMilestone,
    clearCurrentMilestone,
  } = useMilestonesContext()
  const isUpdating = currentMilestone?.id !== NIL_UUID

  const form = useFormik({
    enableReinitialize: isUpdating,
    validationSchema: MilestoneSchema,
    initialValues: {
      id: currentMilestone?.id ?? '',
      name: currentMilestone?.name || '',
      code: currentMilestone?.code || '',
      description: currentMilestone?.description || '',
      rewardAmount: currentMilestone?.rewardAmount || 0,
      rewardCurrency: currentMilestone?.rewardCurrency || Currency.Coin,
      start: currentMilestone?.start || '',
      active: currentMilestone?.active || false,
      internalOnly: currentMilestone?.internalOnly || false,
      updatedAt: currentMilestone?.updatedAt || '',
      createdAt: currentMilestone?.createdAt || '',
    },
    onSubmit: (value: IMilestone) => {
      updateMilestone(value)
    },
  })

  return (
    <ModalWrapper
      panelSize={8}
      closeModal={clearCurrentMilestone}
      title={isUpdating ? `Edit ${currentMilestone?.code}` : 'Create Milestone'}
      open={!!currentMilestone}
    >
      <>
        <PanelContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <MilestoneForm
                milestone={form.values}
                handleChange={form.handleChange}
                setFieldTouched={form.setFieldTouched}
                setFieldValue={form.setFieldValue}
                errors={form.errors}
                isUpdating={isUpdating}
              />
            </Grid>
          </Grid>
        </PanelContent>
        <PanelFooter>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box className={classes.footerContent}>
                <Box>
                  <Button
                    size="large"
                    className={classes.cancelBtn}
                    onClick={clearCurrentMilestone}
                    variant="contained"
                    color="secondary"
                  >
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    size="large"
                    onClick={form.submitForm}
                    variant="contained"
                    disabled={form.dirty && !form.isValid}
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </PanelFooter>
      </>
    </ModalWrapper>
  )
}
