import { Box, Button, Divider, IconButton, SvgIcon, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { CrossIcon, SaveIcon, UndoIcon } from 'components/icons'
import { Panel, PanelContainer } from 'components/panel'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { useFormik } from 'formik'
import { EventFeedPreview } from 'modules/app/views/components/previews/event-feed'
import { useEventsContext } from 'modules/events/context'
import { getStatusLabel } from 'modules/events/utils/get-status-label'
import { EventSchema } from 'modules/events/validation/event-schema'
import { EventActionButtons } from 'modules/events/views/components/action-buttons'
import { EventDetailsForm } from 'modules/events/views/components/event-details-panel/event-details-form'
import moment from 'moment-timezone'
import React, { useState } from 'react'
import { EventActionButtonType } from 'types/modules/events/enums/event-action-button-type'
import { EventFieldName as EventField } from 'types/modules/events/enums/event-field'
import { IEvent } from 'types/modules/events/models/entities/event'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    panelHeader: {
      display: 'flex',
      width: '100%',
      flexShrink: 0,
      height: 72,
    },
    panelTitle: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      padding: '0 0 0 1.25rem',
    },
    eventStatus: {
      marginRight: 20,
    },
    rightHeaderSection: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      padding: '0 1.25rem 0 0',
      flexDirection: 'row-reverse',
      '& .MuiButton-contained': {
        marginRight: 20,
      },
    },
    contentWrapper: {
      display: 'flex',
      flexGrow: 1,
      overflow: 'hidden',
    },
    contentPanel: {
      position: 'relative',
      display: 'flex',
      flex: 1,
      padding: '1.25rem',
      overflow: 'auto',
      borderRight: '1px solid rgba(0,0,0,0.12)',
      '&:last-child': {
        border: 'none',
      },
    },
    splitFooter: {
      display: 'flex',
      width: '100%',
      justifySelf: 'flex-end',
    },
    leftFooterSection: {
      display: 'flex',
      padding: '1.25rem',
      flex: 1,
      justifyContent: 'space-between',
      borderRight: '1px solid rgba(0,0,0,0.12)',
      '&:last-child': {
        border: 'none',
      },
    },
    rightFooterSection: {
      display: 'flex',
      padding: '1.25rem',
      flex: 1,
      justifyContent: 'center',
      '& .MuiButton-contained': {
        marginLeft: '0.625rem',
        marginRight: '0.625rem',
      },
      borderRight: '1px solid rgba(0,0,0,0.12)',
      '&:last-child': {
        border: 'none',
      },
    },
    updateButton: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
      marginRight: '0.625rem',
    },
    resetButton: {
      color: theme.palette.text.primary,
      backgroundColor: '#ecf0f1',
      marginLeft: '0.625rem',
      '&:hover': {
        backgroundColor: '#f2f5f5',
      },
    },
  }),
)

enum DisplayMode {
  View = 'View',
  Edit = 'Edit',
  Create = 'Create',
}

interface IEventDetailsPanelProps {
  isModal?: boolean
  closeModal?: () => void
}

export const EventDetailsPanel: React.FC<IEventDetailsPanelProps> = (
  props: IEventDetailsPanelProps,
) => {
  const {
    currentEvent: event,
    clearCurrentEvent,
    updateEvent,
  } = useEventsContext()

  const [displayMode, setDisplayMode] = useState(DisplayMode.Edit)

  const [buttonsDisabled, setButtonsDisabled] = useState(false)

  const form = useFormik({
    enableReinitialize: true,
    validationSchema: EventSchema,
    initialValues: {
      [EventField.ID]: event?.[EventField.ID] ?? '',
      [EventField.NAME]: event?.[EventField.NAME] ?? '',
      [EventField.CURRENCY]: event?.[EventField.CURRENCY] ?? '',
      [EventField.AMOUNT]: event?.[EventField.AMOUNT] ?? 0,
      [EventField.START]: event?.[EventField.START] ?? moment().toISOString(),
      [EventField.END]: event?.[EventField.END],
      [EventField.STATUS]: event?.[EventField.STATUS],
      [EventField.TAG_GROUP_ID]: event?.[EventField.TAG_GROUP_ID] ?? '',
      [EventField.MEDIA]: event?.[EventField.MEDIA] ?? {
        [EventField.DESCRIPTION]: '',
        [EventField.ICON]: '',
        [EventField.IMAGE_URL]: '',
        [EventField.PASS_IMAGE_URL]: '',
        [EventField.AUTO_PARLAY]: false,
        [EventField.DEFAULT_LIVE_SCHEDULE_IMAGE_URL]: '',
      },
      [EventField.LOCALE]: event?.[EventField.LOCALE] ?? '',
      [EventField.STOP_SCHEDULED_CLOSE]:
        event?.[EventField.STOP_SCHEDULED_CLOSE] ?? false,
      [EventField.HAS_TIMEZONE]: !!event?.[EventField.HAS_TIMEZONE],
    },
    onSubmit: (values: Partial<IEvent>) => {
      updateEvent(values)
    },
  })

  const classes = useStyles()

  return (
    <PanelContainer>
      <Panel container xs={12}>
        <Box className={classes.panelHeader}>
          <Box className={classes.panelTitle}>
            <Typography
              variant="h5"
              color="textPrimary"
              style={{
                paddingRight: 10,
              }}
            >
              {`${displayMode} Event - `}
            </Typography>
            <Typography
              variant="h5"
              color="primary"
              className={classes.eventStatus}
            >
              {getStatusLabel(event!.status)}
            </Typography>
          </Box>
          <Box className={classes.rightHeaderSection}>
            {props.isModal && (
              <IconButton
                onClick={(): void =>
                  !IsNullOrUndefined(props.closeModal)
                    ? props.closeModal!()
                    : clearCurrentEvent()
                }
                size="large">
                <SvgIcon>
                  <CrossIcon />
                </SvgIcon>
              </IconButton>
            )}
          </Box>
        </Box>
        <Divider />
        <Box className={classes.contentWrapper}>
          <Box className={classes.contentPanel}>
            <EventDetailsForm
              {...{
                event: form.values,
                errors: form.errors,
                handleChange: form.handleChange,
                setFieldTouched: form.setFieldTouched,
                setFieldValue: form.setFieldValue,
                setValues: form.setValues,
              }}
            />
          </Box>
          <Box className={classes.contentPanel}>
            <EventFeedPreview {...(form.values as IEvent)} />
          </Box>
        </Box>
        <Divider />
        <Box className={classes.splitFooter}>
          <Box className={classes.leftFooterSection}>
            <Button
              variant="contained"
              startIcon={<UndoIcon />}
              className={classes.resetButton}
              size="small"
              onClick={(): void => {
                form.resetForm()
              }}
              disabled={!form.dirty}
            >
              Undo
            </Button>
            <Button
              variant="contained"
              startIcon={<SaveIcon />}
              className={classes.updateButton}
              size="small"
              onClick={(): void => {
                form.submitForm()
              }}
              disabled={!form.dirty}
            >
              Save
            </Button>
          </Box>
          <Box className={classes.rightFooterSection}>
            <EventActionButtons
              event={event!}
              exclude={[
                EventActionButtonType.Edit,
                ...(props.closeModal ? [EventActionButtonType.Manage] : []),
              ]}
              disabled={buttonsDisabled}
            />
          </Box>
        </Box>
      </Panel>
    </PanelContainer>
  );
}
