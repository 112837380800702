import { Button, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx'
import { ViewIcon } from 'components/icons'
import { usePostListContext } from 'modules/posts/context/post-list'
import React from 'react'
import PostStatus from 'types/modules/posts/enums/post-status'
import { IPostActionButtonProps } from 'types/modules/posts/models/props/post-action-button-props'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.text.primary,
      backgroundColor: '#ecf0f1',
      '&:hover': {
        backgroundColor: '#f2f5f5',
      },
    },
  }),
)

export const ViewPostButton: React.FC<IPostActionButtonProps> = (
  props: IPostActionButtonProps,
) => {
  const classes = useStyles()

  const { setCurrentPost } = usePostListContext()

  const isScheduled = props.post.status === PostStatus.Scheduled
  const isPending = props.post.status === PostStatus.Pending

  return !isScheduled && !isPending ? (
    <Button
      variant="contained"
      startIcon={<ViewIcon />}
      className={clsx(classes.root)}
      size="small"
      onClick={(): void => {
        setCurrentPost(props.post.id, props.post.type)
        props.onPopoverClose && props.onPopoverClose()
      }}
    >
      View
    </Button>
  ) : null
}
