import { Fade, Modal, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Panel } from 'components/panel'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { EventDetailsPanel } from 'modules/events/views/components/event-details-panel'
import { LiveScheduleDetailsPanel } from 'modules/live-schedules/views/components/schedule-details-panel'
import React from 'react'
import { ILiveSchedule } from 'types/modules/live-schedules/models/entities/live-schedule'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 40,
    },
  }),
)

interface ILiveScheduleDetailsModal {
  currentSchedule: ILiveSchedule | null
  open: boolean
  closeModal?: () => void
}

export const LiveScheduleDetailsModal: React.FC<ILiveScheduleDetailsModal> = (
  props: ILiveScheduleDetailsModal,
) => {
  const classes = useStyles()

  return (
    <div>
      <Modal
        className={classes.modal}
        open={props.open}
      >
        <Fade in={!IsNullOrUndefined(props.currentSchedule)}>
          <Panel
            xs={12}
            style={{
              display: 'flex',
              maxWidth: 600,
            }}
          >
            <LiveScheduleDetailsPanel isModal closeModal={props.closeModal} />
          </Panel>
        </Fade>
      </Modal>
    </div>
  )
}
