import { ActionTypes } from 'modules/chests/action-types'
import { Reducer } from 'redux'
import { actionTypeSuccess, cacheItem, cacheItems } from 'core/redux/utils'
import { ISimpleAction, ISuccessAction } from 'types/redux/interfaces/IAction'
import _ from 'core/utils/deepdash'
import { mergeArrays } from 'core/utils/mergeArrays'
import { IChestState } from 'modules/chests/models/state/IChestState'
import { GetChestsApiResponse } from 'modules/chests/models/responses'
import { IChestActionParams } from 'modules/chests/actions'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { Chest } from 'modules/chests/models/entities/chest'
import { NIL as NIL_UUID, v4 as uuidv4 } from 'uuid'
import IPaginatedItems from 'types/common/pagination/models/entities/paginated-items'
import { PAGE_SIZE } from 'modules/chests/constants'

const initialState: IChestState = {
  count: 0,
  page: 0,
  pageSize: PAGE_SIZE,
  items: [],
  cache: {},
}

type AllowedActionTypes = ISuccessAction | ISimpleAction

const attachIdsToChanceRewards = (item: Chest): Chest => ({
  ...item,
  chestConfig: {
    ...item.chestConfig,
    chanceRewards: item.chestConfig.chanceRewards.map((chanceReward) => ({
      ...chanceReward,
      id: uuidv4(),
    })),
  },
})

const ChestsReducer: Reducer<IChestState, AllowedActionTypes> = (
  state = initialState,
  action: AllowedActionTypes,
) => {
  const simpleAction = action as ISimpleAction
  const successAction = action as ISuccessAction

  switch (action.type) {
    case ActionTypes.SET_CURRENT_CHEST_ID: {
      const newState = _.cloneDeep(state)
      const { chestId } = simpleAction.payload as IChestActionParams
      if (IsNullOrUndefined(chestId)) {
        delete newState.currentChestId
      } else {
        newState.currentChestId = chestId
      }
      return newState
    }
    case actionTypeSuccess(ActionTypes.GET_CHEST): {
      const newState = _.cloneDeep(state)
      const response = successAction.data as Chest
      const chest = attachIdsToChanceRewards(response)
      const index = state.items.findIndex((x) => x.id === chest.id)

      if (index < 0) {
        newState.items = [...newState.items, chest]
      } else {
        newState.items[index] = chest
      }

      newState.cache = cacheItem(state.cache, chest)

      return newState
    }
    case actionTypeSuccess(ActionTypes.GET_CHESTS): {
      let newState = _.cloneDeep(state)
      const response = successAction.data as GetChestsApiResponse
      const page = response?.page
      if (page === 1) {
        newState = {
          ...state,
          ...response,
        }
      } else {
        newState.items = mergeArrays(newState.items!, response.items!)
      }
      newState.cache = cacheItems(state.cache, response.items)
      return newState
    }
    case ActionTypes.CREATE_CHEST_DRAFT: {
      const newState = _.cloneDeep(state)
      const { item } = simpleAction.payload as IChestActionParams

      newState.items = [item as Chest, ...state.items]

      newState.currentChestId = NIL_UUID

      return newState
    }
    case actionTypeSuccess(ActionTypes.GET_AUTOCOMPLETE_SEARCH_CHESTS): {
      const newState = _.cloneDeep(state)

      const response = successAction.data as IPaginatedItems<Chest>

      newState.cache = cacheItems(state.cache, response.items)

      return newState
    }
    case actionTypeSuccess(ActionTypes.UPDATE_CHEST): {
      const newState = _.cloneDeep(state)
      const payloadItem = successAction.payload!.item as Chest
      const chest = attachIdsToChanceRewards(payloadItem)
      const index = newState.items!.findIndex((item) => item.id === chest.id)

      newState.items![index] = chest

      newState.cache = cacheItem(state.cache, chest)

      return newState
    }

    case actionTypeSuccess(ActionTypes.UPLOAD_CHEST_IMAGE): {
      const newState = _.cloneDeep(state)
      const payloadItem = successAction.data as Chest
      const index = newState.items!.findIndex(
        (item) => item.id === payloadItem.id,
      )

      newState.items[index].media = payloadItem.media

      newState.cache = cacheItem(state.cache, newState.items[index])

      return newState
    }

    default:
      return state
  }
}

export default ChestsReducer
