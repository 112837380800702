import { Box, Chip, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { buildPostStatusConfig } from 'modules/events/utils/build-post-status-config'
import React from 'react'
import { IEventPostStatus } from 'types/modules/events/models/entities/event'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    postStatus: {
      marginRight: 4,
      marginBottom: 4,
      fontSize: 12,
    },
  }),
)

interface IEventStatussesProps {
  postStatus: IEventPostStatus[]
  eventId: string
}

export const EventStatusses = ({
  postStatus,
  eventId,
}: IEventStatussesProps): JSX.Element => {
  const classes = useStyles()
  return (
    <Box>
      {buildPostStatusConfig(postStatus).map((status) => (
        <Chip
          key={`event-${eventId}-${status.label}`}
          className={classes.postStatus}
          size="small"
          label={`${
            !isNaN(status.metadata?.count) ? status.metadata!.count! : ''
          } ${status.label}`}
          variant="outlined"
          style={{ color: status.labelColor }}
        />
      ))}
    </Box>
  )
}
