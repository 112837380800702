import React from 'react'
import { Button, ButtonProps, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx'
import { ConfirmationDialog } from 'components/confirmation-dialog'
import { useShippingContext } from 'modules/shipping/context'
import { IUserPrizeActionButtonProps } from 'types/modules/shipping/models/props/user-prize-action-button-props'
import { UserPrizeStatus } from 'types/modules/shipping/enums/user-prize-status'
import { CrossIcon } from 'components/icons'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.primary.contrastText,
      backgroundColor: '#ff6565',
      '&:hover': {
        backgroundColor: '#fd8f8f',
      },
    },
  }),
)

export const CancelUserPrizeButton: React.FC<IUserPrizeActionButtonProps> = (
  props: IUserPrizeActionButtonProps,
) => {
  const { cancelUserPrize } = useShippingContext()
  const classes = useStyles()
  const isReadyToShip = props.userPrize.status === UserPrizeStatus.ReadyToShip
  const isDispatched = props.userPrize.status === UserPrizeStatus.Dispatched
  const isReturned = props.userPrize.status === UserPrizeStatus.Returned

  const buttonProps: ButtonProps = {
    variant: 'contained',
    startIcon: <CrossIcon />,
    className: clsx(classes.root),
    size: 'small',
    disabled: props.disabled,
  }

  const buttonLabel = 'cancel'

  return isReadyToShip || isDispatched || isReturned ? (
    <ConfirmationDialog
      popoverId={`${buttonLabel}-user-prize-${props.userPrize.id}`}
      buttonProps={buttonProps}
      onConfirmation={(): void => cancelUserPrize(props.userPrize.id)}
      confirmationText={`Are you sure you want to ${buttonLabel} this item?`}
      buttonLabel={buttonLabel}
      onPopoverClose={props.onPopoverClose}
    />
  ) : null
}
