import { createAction } from 'core/redux/utils'
import { SnackbarKey } from 'notistack'
import { IAlert } from 'types/common/alerts/models/entities/alert'
import { ISimpleAction } from 'types/redux/interfaces/IAction'
import ActionTypes from '../action-types'

export const enqueueAlert: (alert: IAlert) => ISimpleAction = (alert) =>
  createAction(ActionTypes.ENQUEUE_ALERT, alert)

export const dismissAlert: (key?: SnackbarKey) => ISimpleAction = (key) =>
  createAction(ActionTypes.DISMISS_ALERT, {
    dismissAll: !key,
    key,
  })

export const removeAlert: (key: SnackbarKey) => ISimpleAction = (key) =>
  createAction(ActionTypes.REMOVE_ALERT, {
    key,
  })
