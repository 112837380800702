import React, { useState } from 'react'
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Box, Button, Divider, Grid, IconButton, SvgIcon, Typography } from '@mui/material'
import { NIL as NIL_UUID } from 'uuid'
import { PanelContent, PanelFooter } from 'components/panel'
import _ from 'core/utils/deepdash'
import { useFormik } from 'formik'
import { colors } from 'utils/colors'
import { ModalWrapper } from 'components/modal-wrapper'
import { useGameTasksContext } from 'modules/quests/context/game-task-context'
import { GameTaskForm } from 'modules/quests/view/game-tasks/game-task-modal/game-task-form'
import { GameTaskSchema } from 'modules/quests/validation/game-task-schema'
import { CrossIcon, HomeIcon, PlusIcon } from 'components/icons'
import { GameTaskStepSwitch } from 'modules/quests/view/game-tasks/game-task-modal/game-task-step-switch'
import { GameTaskStepFormWrapper } from 'modules/quests/view/game-tasks/game-task-modal/game-task-step-form-wrapper'
import { TASK_CODES_WITH_PARAMS } from 'modules/quests/constants'
import { ParameterTypes } from 'modules/quests/enums/quest-field'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined';
import { GameTask, GameTaskSchedule, GameTaskType } from 'fe-shared-resources';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
    },
    panelHeader: {
      display: 'flex',
      width: '100%',
      flexShrink: 0,
    },
    panelTitle: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      padding: '0 0 0 1.25rem',
    },
    navigationContainer: {
      display: 'flex',
      flex: 2,
      flexGrow: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    rightHeaderSection: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      padding: '0 1.25rem 0 0',
      flexDirection: 'row-reverse',
      '& .MuiButton-contained': {
        marginRight: 20,
      },
    },
    footerContent: {
      display: 'flex',
      flex: 1,
      justifyContent: 'flex-end',
    },
    cancelBtn: {
      marginRight: '1.25rem',
    },
    wrapper: {
      borderLeft: `1px solid ${colors.rgba_0_0_0_12}`,
    },
    form: {
      height: '600px',
      overflow: 'auto',
    },
  }),
)

const capitalizeFirstLetter = (val: string): string => {
    return val.charAt(0).toUpperCase() + val.slice(1);
}

export const GameTaskModal: React.FC = () => {
  const classes = useStyles()
  const {
    currentGameTask,
    updateGameTask,
    createGameTask,
    clearCurrentGameTask,
    sortedStepsIds,
    navigationDisabled,
    selectedStep,
    setSelectedStep: onChooseStep,
    requesting
  } = useGameTasksContext()
  const isUpdating = currentGameTask?.id !== NIL_UUID

  const form = useFormik({
    enableReinitialize: false,
    validationSchema: GameTaskSchema,
    initialValues: {
      id: currentGameTask?.id ?? '',
      name: currentGameTask?.name || '',
      description: currentGameTask?.description || '',
      isEnabled: !IsNullOrUndefined(currentGameTask?.isEnabled) ? currentGameTask?.isEnabled : false,
      isVisible: !IsNullOrUndefined(currentGameTask?.isVisible) ?  currentGameTask?.isVisible : true,
      schedule: currentGameTask?.schedule || GameTaskSchedule.NotSet,
      type: currentGameTask?.type || GameTaskType.NotSet,
      maxLevel: currentGameTask?.maxLevel || 0,
    },
    onSubmit: (value: Partial<GameTask>) => {
      const request = isUpdating ? updateGameTask : createGameTask
      request(value as GameTask)
    },
  })

  return (
    <ModalWrapper
      panelSize={8}
      closeModal={clearCurrentGameTask}
      title={isUpdating ? currentGameTask?.name || '' : 'Create Task'}
      open={!!currentGameTask}
      PanelHeader={
        <>
          <Box className={classes.panelHeader}>
            <Box className={classes.panelTitle}>
              <Typography
                variant="h5"
                color="textPrimary"
                style={{
                  paddingRight: 10,
                }}
              >
                {isUpdating ? currentGameTask?.name : 'Create Task'}
              </Typography>
            </Box>
            {isUpdating && <Box className={classes.navigationContainer}>
              <GameTaskStepSwitch
                active={!selectedStep}
                onClick={onChooseStep}
                disabled={navigationDisabled || requesting}
                Icon={<HomeIcon />}
              />
              {sortedStepsIds.map((id) => <GameTaskStepSwitch
                key={id}
                gameTaskStepId={id}
                active={selectedStep === id}
                onClick={onChooseStep}
                disabled={navigationDisabled || requesting}
              />)}
               <GameTaskStepSwitch
                gameTaskStepId="new"
                active={selectedStep === 'new'}
                onClick={onChooseStep}
                disabled={navigationDisabled || requesting}
                Icon={<PlusIcon />}
              />
            </Box>}
            <Box className={classes.rightHeaderSection}>
              <IconButton onClick={(): void => clearCurrentGameTask()} size="large">
                <SvgIcon>
                  <CrossIcon />
                </SvgIcon>
              </IconButton>
            </Box>
          </Box>
          <Divider />
        </>
      }
    >
      {
        !selectedStep
          ? <>
          <PanelContent>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <GameTaskForm
                  gameTask={form.values as GameTask}
                  handleChange={form.handleChange}
                  setFieldTouched={form.setFieldTouched}
                  setFieldValue={form.setFieldValue}
                  errors={form.errors}
                  isUpdating={isUpdating}
                />
              </Grid>
            </Grid>
          </PanelContent>
          <PanelFooter>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box className={classes.footerContent}>
                  <Button
                    size="large"
                    className={classes.cancelBtn}
                    onClick={clearCurrentGameTask}
                    variant="contained"
                    disabled={requesting}
                    color="secondary"
                  >
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    size="large"
                    onClick={form.submitForm}
                    variant="contained"
                    disabled={(form.dirty && !form.isValid) || requesting}
                  >
                    Save
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </PanelFooter>
        </>
        : <GameTaskStepFormWrapper
          gameTaskStepId={selectedStep}
          key={selectedStep}
          onCancel={(): void => onChooseStep()}
        />}
    </ModalWrapper>
  );
}
