import { AppState } from 'types/redux/types/app-state'
import {
  MODULE_NAME,
} from 'modules/campaigns/constants'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { NIL as NIL_UUID } from 'uuid'
import {
  ICampaignState,
} from 'modules/campaigns/models/state'
import { ICampaign } from 'modules/campaigns/models/campaign'


const getCampaignState = (state: AppState): ICampaignState => state[MODULE_NAME]


export const getCurrentCampaign = (
  state: AppState,
): ICampaign | null => {
  const { currentCampaignId, cache } = getCampaignState(state)
  if (IsNullOrUndefined(currentCampaignId)) return null
  if (currentCampaignId === NIL_UUID) {
    return {
      id: NIL_UUID,
      name: '',
      items: []
    }
  }
  const currentCampaign = cache[currentCampaignId!]
  return currentCampaign ?? null
}

export const getCampaigns = (state: AppState): ICampaign[] =>
  getCampaignState(state).items

export const getCampaignsCount = (state: AppState): number =>
  getCampaignState(state).count
