import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import FilterToolbar, {
  IFilterToolbarProps,
} from 'core/filtering/components/filter-toolbar'
import React from 'react'
import SortDirection from 'types/common/enums/sort-direction'
import { EventFieldName } from 'types/modules/events/enums/event-field'

export const EventsListToolbar: React.FC<{}> = () => {
  const toolbarProps = {
    searchFilterConfig: {
      field: EventFieldName.NAME,
    },
    sortOptions: [
      { label: 'Start Date', value: EventFieldName.START },
      { label: 'End Date', value: EventFieldName.END },
      { label: 'Last Updated', value: EventFieldName.UPDATED_AT },
    ],
    initialSortValue: {
      sortBy: EventFieldName.START,
      sortDirection: SortDirection.Descending,
    },
  } as IFilterToolbarProps

  return <FilterToolbar {...toolbarProps} />
}
