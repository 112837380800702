import { Box, Theme, Tooltip, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import React from 'react'
import { IPostQuestionOption } from 'types/modules/posts/models/entities/post-question-option'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    optionContainer: {
      display: 'flex',
      minHeight: 58,
      width: '100%',
      border: '1px solid rgb(216, 216, 216)',
      borderRadius: 18,
      flexDirection: 'row',
      marginBottom: 10,
      '&:last-child': {
        marginBottom: 0,
      },
      '&.correct': {
        background: '#bfffca',
      },
    },
    optionIndexContainer: {
      width: 58,
      height: '100%',
      background: theme.palette.primary.main,
      borderRight: '1px solid rgb(216, 216, 216)',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      borderRadius: 18,
    },
    optionIndexText: {
      color: '#ffffff',
      fontSize: 14,
      textAlign: 'center',
    },
    optionTextContainer: {
      display: 'flex',
      flex: 1,
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    optionText: {
      color: '#000000',
      fontSize: 14,
      textAlign: 'center',
      wordWrap: 'normal',
    },
    optionAnswers: {
      display: 'flex',
      position: 'absolute',
      right: 10,
      height: 30,
      width: 30,
      borderRadius: 90,
      background: 'rgba(0, 0, 0, 0.03)',
      color: theme.palette.text.primary,
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        width: '9.554140127388536VW',
        height: '9.554140127388536VW',
      },
    },
    answerCount: {
      display: 'flex',
      lineHeight: '0.8rem',
      fontSize: '0.75rem',
      fontWeight: 600,
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        lineHeight: '4.076433121019109VW',
        fontSize: '3.821656050955414VW',
      },
    },
  }),
)

export interface IQuestionOptionProps {
  option: IPostQuestionOption
  index: number
}

export const QuestionOption: React.FC<IQuestionOptionProps> = (
  props: IQuestionOptionProps,
) => {
  const classes = useStyles()

  const portalAnswers =
    props.option.answers?.filter(
      (answer) =>
        !IsNullOrUndefined(answer.updatedBy) && answer.updatedBy?.fromPortal,
    ) ?? []

  return (
    <Box
      className={clsx(
        classes.optionContainer,
        props.option.correct && 'correct',
      )}
    >
      <Box className={classes.optionIndexContainer}>
        <Typography className={classes.optionIndexText}>
          {props.index + 1}
        </Typography>
      </Box>
      <Box className={classes.optionTextContainer}>
        <Typography className={classes.optionText}>
          {props.option.text}
        </Typography>
        {portalAnswers?.length > 0 && (
          <Tooltip
            title={portalAnswers
              .map((answer) => answer.updatedBy?.name)
              .join(', ')}
          >
            <Box className={classes.optionAnswers}>
              <Typography variant="body2" className={classes.answerCount}>
                {portalAnswers.length}
              </Typography>
            </Box>
          </Tooltip>
        )}
      </Box>
    </Box>
  )
}
