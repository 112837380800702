/* eslint-disable @typescript-eslint/explicit-function-return-type */
// Lib
import { call, put } from 'redux-saga/effects'
import LogRocket from 'logrocket'

// Core
import * as Axios from 'core/axios'

// Types
import { ApiRequestActions } from 'types/common/api/models/entities/api-request-actions'
import { IFailedAction } from 'types/redux/interfaces/IAction'

const formatErrorAction = (action: IFailedAction) => {
  const result: { [key: string]: string | number | boolean } = {}

  Object.entries(action).forEach((property) => {
    const [key, value] = property
    result[key] = JSON.stringify(value)
  })

  return result
}

const AsyncRequestSaga = function* (actions: ApiRequestActions) {
  const { REQUEST, SUCCESS, FAILED } = actions

  const requestAction = REQUEST()

  yield put(requestAction)

  const { requestParams } = requestAction

  try {
    // @ts-expect-error will write problem later
    const result = yield call(Axios.request, requestParams)

    if (result.response.ok) {
      const { data } = result
      if (requestAction.payload?.callbackFn)
        requestAction.payload?.callbackFn(data)
      if (requestAction.payload?.promise) {
        requestAction.payload?.promise.onResolve(data)
      }
      yield put(SUCCESS(data))
      if (requestAction.finalCallback) requestAction.finalCallback()
    } else {
      const { error } = result

      if (error.status !== 422) {
        LogRocket.captureException(error, {
          extra: {
            ...formatErrorAction(FAILED(error)),
          },
        })
      }

      if (requestAction.payload?.promise && requestAction.payload?.promise.onReject) {
        requestAction.payload?.promise.onReject(error)
      }
      yield put(FAILED(error))
      if (requestAction.finalCallback) requestAction.finalCallback()
    }
  } catch (error) {
    // @ts-expect-error will write problem later
    const exceptionExtras = formatErrorAction(FAILED(error))
    // eslint-disable-next-line no-console
    console.log(exceptionExtras)
    // @ts-expect-error will write problem later
    LogRocket.captureException(error, {
      extra: {
        ...exceptionExtras,
      },
    })
    //  @ts-expect-error will write problem later
    yield put(FAILED(error))
    if (requestAction.finalCallback) requestAction.finalCallback()
  }
}

export default AsyncRequestSaga
