import React from 'react'
import {
  faArrowRight,
  faImage,
  faPlusCircle,
  faMobileAlt,
} from '@fortawesome/pro-light-svg-icons'
import {
  faBars,
  faCalendarAlt,
  faTasksAlt,
  faTvRetro,
  faCheckCircle,
  faTreasureChest,
  faSunHaze,
  faPauseCircle,
  faStopCircle,
  faCopy,
  faBan,
  faThumbsUp,
  faTrash,
  faTrashRestore,
  faPlayCircle,
  faStopwatch,
  faPoll,
  faClipboardListCheck,
  faEye,
  faArrowSquareUp,
  faGripLines,
  faTimes,
  faSave,
  faUndo,
  faPlus,
  faEdit,
  faCog,
  faArrowLeft,
  faClock,
  faRocketLaunch,
  faGift,
  faStar,
  faHandPaper,
  faUserEdit,
  faComment,
  faBold,
  faItalic,
  faUnderline,
  faStrikethrough,
  faH1,
  faH2,
  faParagraph,
  faCode,
  faAlignLeft,
  faAlignCenter,
  faAlignRight,
  faListUl,
  faListOl,
  faClipboardList,
  faLink,
  faShippingFast,
  faNewspaper,
  faSparkles,
  faSearch,
  faCalendarStar,
  faUserCircle,
  faExclamationTriangle,
  faHeart,
} from '@fortawesome/pro-regular-svg-icons'
import {
  faCircle,
  faExclamationCircle,
  faFlag,
  faHome,
  faTimesCircle,
  faVial,
  faCheckCircle as faCheckCircleSolid,
  faQuoteRight,
  faPaperclip
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'
import { useIconStyles } from 'hooks/use-icon-styles'

const iconStyles = {
  fontSize: 24,
  width: 24,
  height: 24,
}

export const PaperClipIcon = (): JSX.Element => {
  return <FontAwesomeIcon icon={faPaperclip} />
}

export const CoinIcon = (): JSX.Element => {
  const classes = useIconStyles()
  return <Box className={classes.coinIcon} />
}

export const StarIcon = (): JSX.Element => {
  const classes = useIconStyles()
  return <Box className={classes.starIcon} />
}

export const SparklesIcon = (): JSX.Element => (
  <FontAwesomeIcon icon={faSparkles} />
)

export const SearchIcon = (): JSX.Element => <FontAwesomeIcon icon={faSearch} />

export const HomeIcon = (): JSX.Element => <FontAwesomeIcon icon={faHome} />

export const CalendarStarIcon = (): JSX.Element => (
  <FontAwesomeIcon icon={faCalendarStar} />
)

export const CircleUserIcon = (): JSX.Element => (
  <FontAwesomeIcon icon={faUserCircle} />
)

export const HeartIcon = (): JSX.Element => <FontAwesomeIcon icon={faHeart} />

export const HamburgerIcon = (): JSX.Element => (
  <FontAwesomeIcon icon={faBars} />
)

export const CogIcon = (): JSX.Element => <FontAwesomeIcon icon={faCog} />

export const EditIcon = (): JSX.Element => <FontAwesomeIcon icon={faEdit} />

export const SaveIcon = (): JSX.Element => <FontAwesomeIcon icon={faSave} />

export const PlusIcon = (): JSX.Element => <FontAwesomeIcon icon={faPlus} />

export const UndoIcon = (): JSX.Element => <FontAwesomeIcon icon={faUndo} />

export const CrossIcon = (): JSX.Element => <FontAwesomeIcon icon={faTimes} />

export const ClockIcon = (): JSX.Element => <FontAwesomeIcon icon={faClock} />

export const LaunchIcon = (): JSX.Element => (
  <FontAwesomeIcon icon={faRocketLaunch} />
)

export const RemoveIcon = (): JSX.Element => (
  <FontAwesomeIcon icon={faTimesCircle} />
)

export const GripLinesHorizontalIcon = (): JSX.Element => (
  <FontAwesomeIcon icon={faGripLines} />
)

export const ArrowLeftIcon = (): JSX.Element => (
  <FontAwesomeIcon icon={faArrowLeft} />
)

export const ArrowRightIcon = (): JSX.Element => (
  <FontAwesomeIcon icon={faArrowRight} />
)

export const TagSubscribeIcon = (): JSX.Element => (
  <FontAwesomeIcon icon={faPlusCircle} />
)

export const ImageIcon = (): JSX.Element => (
  <FontAwesomeIcon icon={faImage} style={iconStyles} />
)

export const WatchlistIcon = (): JSX.Element => (
  <FontAwesomeIcon icon={faTvRetro} style={iconStyles} />
)

export const TimedIcon = (): JSX.Element => (
  <FontAwesomeIcon icon={faStopwatch} style={iconStyles} />
)

export const PollsIcon = (): JSX.Element => (
  <FontAwesomeIcon icon={faPoll} style={iconStyles} />
)

export const PersonalityTestIcon = (): JSX.Element => (
  <FontAwesomeIcon icon={faVial} style={iconStyles} />
)

export const ListIcon = (): JSX.Element => (
  <FontAwesomeIcon icon={faBars} style={iconStyles} />
)

export const ReviewIcon = (): JSX.Element => (
  <FontAwesomeIcon icon={faClipboardListCheck} />
)

export const CalendarIcon = (): JSX.Element => (
  <FontAwesomeIcon icon={faCalendarAlt} style={iconStyles} />
)

export const TimelineIcon = (): JSX.Element => (
  <FontAwesomeIcon icon={faTasksAlt} style={iconStyles} />
)

export const FeaturedIcon = (): JSX.Element => (
  <FontAwesomeIcon icon={faCheckCircle} />
)

export const BetOfDayIcon = (): JSX.Element => (
  <FontAwesomeIcon icon={faSunHaze} />
)

export const PauseIcon = (): JSX.Element => (
  <FontAwesomeIcon icon={faPauseCircle} />
)

export const CloseIcon = (): JSX.Element => (
  <FontAwesomeIcon icon={faStopCircle} />
)

export const DuplicateIcon = (): JSX.Element => (
  <FontAwesomeIcon icon={faCopy} />
)

export const AbandonIcon = (): JSX.Element => <FontAwesomeIcon icon={faBan} />

export const ApproveIcon = (): JSX.Element => (
  <FontAwesomeIcon icon={faThumbsUp} />
)

export const DiscardIcon = (): JSX.Element => <FontAwesomeIcon icon={faTrash} />

export const ReactivateIcon = (): JSX.Element => (
  <FontAwesomeIcon icon={faPlayCircle} />
)

export const RestoreIcon = (): JSX.Element => (
  <FontAwesomeIcon icon={faTrashRestore} />
)

export const ViewIcon = (): JSX.Element => <FontAwesomeIcon icon={faEye} />

export const CircleIcon = (): JSX.Element => <FontAwesomeIcon icon={faCircle} />

export const RiskIcon = (): JSX.Element => (
  <FontAwesomeIcon icon={faFlag} style={{ color: '#ff6565' }} />
)

export const SubmitIcon = (): JSX.Element => (
  <FontAwesomeIcon icon={faArrowSquareUp} />
)

export const ExclamationCircleIcon = (): JSX.Element => (
  <FontAwesomeIcon icon={faExclamationCircle} />
)

export const SolidTickCircleIcon = (): JSX.Element => (
  <FontAwesomeIcon icon={faCheckCircleSolid} />
)

export const InstantPurchaseIcon = (): JSX.Element => (
  <FontAwesomeIcon icon={faGift} style={iconStyles} />
)

export const SweepstakeIcon = (): JSX.Element => (
  <FontAwesomeIcon icon={faStar} style={iconStyles} />
)

export const HandPaperIcon = (): JSX.Element => (
  <FontAwesomeIcon icon={faHandPaper} />
)

export const WriterIcon = (): JSX.Element => (
  <FontAwesomeIcon icon={faUserEdit} />
)

export const MobileIcon = (): JSX.Element => (
  <FontAwesomeIcon icon={faMobileAlt} />
)

export const CommentIcon = (): JSX.Element => (
  <FontAwesomeIcon icon={faComment} />
)

export const BoldIcon = (): JSX.Element => <FontAwesomeIcon icon={faBold} />

export const ItalicIcon = (): JSX.Element => <FontAwesomeIcon icon={faItalic} />

export const UnderlineIcon = (): JSX.Element => (
  <FontAwesomeIcon icon={faUnderline} />
)

export const StrikethroughIcon = (): JSX.Element => (
  <FontAwesomeIcon icon={faStrikethrough} />
)

export const QuoteIcon = (): JSX.Element => (
  <FontAwesomeIcon icon={faQuoteRight} />
)

export const HeadingOneIcon = (): JSX.Element => <FontAwesomeIcon icon={faH1} />

export const HeadingTwoIcon = (): JSX.Element => <FontAwesomeIcon icon={faH2} />

export const ParagraphIcon = (): JSX.Element => (
  <FontAwesomeIcon icon={faParagraph} />
)

export const CodeIcon = (): JSX.Element => <FontAwesomeIcon icon={faCode} />

export const AlignLeftIcon = (): JSX.Element => (
  <FontAwesomeIcon icon={faAlignLeft} />
)

export const AlignCenterIcon = (): JSX.Element => (
  <FontAwesomeIcon icon={faAlignCenter} />
)

export const AlignRightIcon = (): JSX.Element => (
  <FontAwesomeIcon icon={faAlignRight} />
)

export const ListUlIcon = (): JSX.Element => <FontAwesomeIcon icon={faListUl} />

export const ListOlIcon = (): JSX.Element => <FontAwesomeIcon icon={faListOl} />

export const ChecklistIcon = (): JSX.Element => (
  <FontAwesomeIcon icon={faClipboardList} />
)

export const LinkIcon = (): JSX.Element => <FontAwesomeIcon icon={faLink} />

export const ShippingIcon = (): JSX.Element => (
  <FontAwesomeIcon icon={faShippingFast} />
)

export const NewspaperIcon = (): JSX.Element => (
  <FontAwesomeIcon icon={faNewspaper} />
)

export const ExclamationTriangle = (): JSX.Element => (
  <FontAwesomeIcon icon={faExclamationTriangle} />
)

export const GiftIcon = (): JSX.Element => <FontAwesomeIcon icon={faGift} />

export const TreasureChest = (): JSX.Element => (
  <FontAwesomeIcon icon={faTreasureChest} />
)
