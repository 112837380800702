import { useTypedSelector } from 'core/redux/utils'
import { IsNullUndefinedOrEmpty } from 'core/utils/isNullOrUndefined'
import { mapMimeType } from 'core/utils/mime-mapper'
import { Actions, Selectors } from 'modules/media-items'
import { DEFAULT_IMAGE_TRANSFORM_PARAMS, readFile } from 'modules/media-items/media-item-input/helper'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Area } from 'react-easy-crop'
import { useDispatch } from 'react-redux'
import { AspectRatio } from 'types/common/images/enum/aspect-ratio'
import { MediaOrientation } from 'types/common/images/enum/media-orientation'
import { FileUploadModel, MediaUploadModel } from 'types/common/images/models/entities/image-upload-model'
import { MediaItem } from 'types/modules/media-items/models/entities/media-item'
import { MediaItemTransformation } from 'types/modules/media-items/models/entities/media-item-transformation'

interface IParams {
  existingMediaItemId?: string
}

interface IUploadMediaItemsParams {
  transformation: MediaItemTransformation,
  callbackFn?: (mediaItem: MediaItem) => void,
}

interface ICrop { x: number, y: number }
interface IReturn {
  originalImage: FileUploadModel | undefined
  croppedAreaPixels: Area | null,
  aspect: number
  measurementControl: {
    setZoom: (val: number) => void,
    setCrop: (val: ICrop) => void,
    setAspectRatio: (val: AspectRatio) => void
    setOrientation: (val: MediaOrientation) => void
  },
  measurements: {
    crop: ICrop,
    zoom: number,
    aspectRatio: AspectRatio,
    orientation: MediaOrientation
  },
  uploadMediaItemImage: ({
    transformation,
    callbackFn,
  }: IUploadMediaItemsParams) => void
  imageUrl: string | undefined,
  handleFileChange: (file: File) => Promise<void>
  onCropComplete: (croppedArea: Area, croppedAreaPixels: Area) => void
  createTransformation: () => MediaItemTransformation
  clearOriginalImage: () => void
  clearMeasurements: () => void
}

export const useImageUpload = ({
  existingMediaItemId
}: IParams): IReturn => {
  const dispatch = useDispatch()
  const existingMediaItem = useTypedSelector((state) =>
    Selectors.getMediaItemById(state, existingMediaItemId),
  )


  const { imageTransform, imageUrl: existingImageUrl } =
    existingMediaItem?.media || {}

  const [imageUrl, setImageUrl] = useState<string | undefined>(existingImageUrl)
  const [crop, setCrop] = useState({
    x: imageTransform?.x || 0,
    y: imageTransform?.y || 0,
  })
  const [zoom, setZoom] = useState(imageTransform?.scale || 1)
  const [aspectRatio, setAspectRatio] = useState(
    (imageTransform?.aspectRatio as AspectRatio) || AspectRatio.Standard,
  )
  const [orientation, setOrientation] = useState(
    (imageTransform?.orientation as MediaOrientation) ||
      MediaOrientation.Portrait,
  )
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null)
  const [[cropWidth, cropHeight], setCropDimensions] = useState<string[]>(
    aspectRatio.toString().split(':'),
  )
  const originalImage = useRef<FileUploadModel | undefined>()

  const clearMeasurements = (): void => {
    setImageUrl(undefined)
    setCrop({ x: 0, y: 0 })
    setZoom(1)
    setOrientation(MediaOrientation.Portrait)
    setCroppedAreaPixels(null)
    setCropDimensions(AspectRatio.Standard.split(':'))
    originalImage.current = undefined
  }

  useEffect(() => {
    setCropDimensions(aspectRatio.toString().split(':'))
  }, [aspectRatio])


  useEffect(() => {
    if (!IsNullUndefinedOrEmpty(existingMediaItemId)) {
      dispatch(
        Actions.getMediaItemById({
          mediaItemId: existingMediaItemId,
          promise: {
            onResolve: (data: MediaItem): void => {
              const {
                media: { imageTransform, imageUrl },
              } = data
              setImageUrl(imageUrl)
              setZoom(imageTransform?.scale || 1)
              setAspectRatio(
                (imageTransform?.aspectRatio as AspectRatio) ||
                  AspectRatio.Standard,
              )
              setOrientation(
                (imageTransform?.orientation as MediaOrientation) ||
                  MediaOrientation.Portrait,
              )
              setCrop({
                x: imageTransform?.x || 0,
                y: imageTransform?.y || 0,
              })
              setCroppedAreaPixels(null)
            },
            onReject: (): void => {
              // do nothing
            },
          },
        }),
      )
    }
  }, [existingMediaItemId])

  const onCropComplete = useCallback(
    (croppedArea: Area, croppedAreaPixels: Area) => {
      setCroppedAreaPixels(croppedAreaPixels)
    },
    [],
  )

  const handleFileChange = async (fileToUpload: File): Promise<void> => {
    const imageDetails = await readFile(fileToUpload, false)
    originalImage.current = imageDetails
    setImageUrl(imageDetails.compressedUrl)
    setZoom(1)
    setAspectRatio(AspectRatio.Standard)
    setOrientation(MediaOrientation.Portrait)
    setCrop({ x: 0, y: 0 })
    setCroppedAreaPixels(null)
  }

  const updateMediaItemTransformation = (
    mediaItemId: string,
    transformation: MediaItemTransformation,
  ): void => {
    dispatch(
      Actions.updateMediaItemTransformData({
        mediaItemId,
        item: transformation,
      }),
    )
  }

  const uploadMediaItemImage = ({
    transformation,
    callbackFn,
  }: IUploadMediaItemsParams): void => {
    const action = existingMediaItem
      ? Actions.updateMediaItemImage
      : Actions.uploadMediaItemImage
    new Promise<MediaItem>((onResolve, onReject) => {

      if (!originalImage.current && existingMediaItem?.id) {
        onResolve(existingMediaItem)
      } else {
        const buffer = originalImage.current?.fileExtension === 'gif'
            ? originalImage.current.originalUrl
            : originalImage.current?.compressedUrl
        const fileParams = {
          fileExtension: originalImage.current?.fileExtension || '',
          // @ts-ignore Buffer is a keyword
          binaryData: Buffer.from(
            (buffer || '').split('base64,')[1],
            'base64',
          ),
        }
        dispatch(
          action({
            mediaItemId: existingMediaItem?.id,
            item: fileParams.binaryData,
            extension: `image.${fileParams.fileExtension}`,
            overrideHeaders: {
              'content-type': mapMimeType(fileParams.fileExtension),
              accept: '*/*',
            },
            promise: {
              onResolve,
              onReject,
            },
          }),
        )
      }
    }).then((mediaItem) => {
      callbackFn && callbackFn(mediaItem)
      updateMediaItemTransformation(mediaItem.id, transformation)
    })
  }

  const createTransformation = (): MediaItemTransformation => ({
    ...crop,
    scale: zoom,
    aspectRatio: aspectRatio as AspectRatio,
    orientation: orientation as MediaOrientation,
    ...DEFAULT_IMAGE_TRANSFORM_PARAMS,
  })


  return {
    uploadMediaItemImage,
    handleFileChange,
    originalImage: originalImage.current,
    createTransformation,
    onCropComplete,
    croppedAreaPixels,
    aspect:
    orientation === MediaOrientation.Landscape
      ? parseInt(cropWidth, 10) / parseInt(cropHeight, 10)
      : parseInt(cropHeight, 10) / parseInt(cropWidth, 10),
    measurementControl: {
      setZoom: (value: number): void => setZoom(value),
      setCrop: (value: ICrop): void => setCrop(value),
      setAspectRatio: (value: AspectRatio): void => setAspectRatio(value),
      setOrientation: (value: MediaOrientation): void => setOrientation(value),
    },
    measurements: {
      crop,
      zoom,
      aspectRatio,
      orientation
    },
    imageUrl,
    clearOriginalImage: (): void => originalImage.current = undefined,
    clearMeasurements
  }
}
