import { Rules } from 'core/authorization/rules'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { RolePermissions } from 'types/common/authorization/role-permissions'
import { PortalUser } from 'types/modules/users/portal/models/entities/portal-user'

const getUserPermissions = (user: PortalUser): RolePermissions => {
  const userRoles = user.roles?.toArray() ?? []

  const permissions: RolePermissions = {
    static: [],
  }

  userRoles.forEach((role) => {
    const rolePermissions = Rules[role]

    if (!IsNullOrUndefined(rolePermissions)) {
      permissions.static = [...permissions.static, ...rolePermissions.static]
    }
  })

  // Remove duplicates
  permissions.static = [...new Set(permissions.static)]

  return permissions
}

export const checkUserPermission = (
  user: PortalUser | null,
  permission: string,
  params?: any,
): boolean => {
  if (IsNullOrUndefined(user)) return false

  const permissions = getUserPermissions(user!)

  const staticPermissions = permissions.static

  if (staticPermissions && staticPermissions.includes(permission)) {
    return true
  }

  const dynamicPermissions = permissions.dynamic

  if (dynamicPermissions) {
    const permissionCondition = dynamicPermissions[permission]

    return !permissionCondition ? false : permissionCondition(params)
  }

  return false
}
