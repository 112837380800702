import { MODULE_NAME } from '../constants'

const ActionTypes = {
  GET_PRIZE_STATUSES: `${MODULE_NAME}/GET_PRIZE_STATUSES`,
  GET_PRIZES: `${MODULE_NAME}/GET_PRIZES`,
  GET_PRIZE_BY_ID: `${MODULE_NAME}/GET_PRIZE_BY_ID`,
  CREATE_PRIZE: `${MODULE_NAME}/CREATE_PRIZE`,
  DUPLICATE_PRIZE: `${MODULE_NAME}/DUPLICATE_PRIZE`,
  UPDATE_PRIZE: `${MODULE_NAME}/UPDATE_PRIZE`,
  UPDATE_PRIZE_STATUS: `${MODULE_NAME}/UPDATE_PRIZE_STATUS`,
  PROMOTE_PRIZE: `${MODULE_NAME}/PROMOTE_PRIZE`,
  DELETE_PRIZE: `${MODULE_NAME}/DELETE_PRIZE`,
  CLEAR_TRASH: `${MODULE_NAME}/CLEAR_TRASH`,
  SET_CURRENT_PRIZE_ID: `${MODULE_NAME}/SET_CURRENT_PRIZE_ID`,
  CLEAR_PRIZES: `${MODULE_NAME}/CLEAR_PRIZES`,
  AUTOCOMPLETE_PRIZE_SEARCH: `${MODULE_NAME}/AUTOCOMPLETE_PRIZE_SEARCH`,
  UPLOAD_PRIZE_IMAGE: `${MODULE_NAME}/UPLOAD_PRIZE_IMAGE`,
  UPLOAD_FEATURED_IMAGE: `${MODULE_NAME}/UPLOAD_FEATURED_IMAGE`,
  UPLOAD_LOGO: `${MODULE_NAME}/UPLOAD_LOGO`,
}

export default ActionTypes
