import { Box, Button, ButtonBase, Divider, IconButton, SvgIcon, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx'
import {
  CrossIcon,
  HomeIcon,
  SaveIcon,
  TreasureChest,
  UndoIcon,
} from 'components/icons'
import { Panel, PanelContainer } from 'components/panel'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { ItemType } from 'fe-shared-resources'
import { useFormik } from 'formik'
import { ManageChest } from 'modules/chests/components/manage-chest'
import { useChestsContext } from 'modules/chests/context'
import { PrizeFeedPreview } from 'modules/posts/views/components/manage-post-panel/prize-feed-preview'
import { usePrizeListContext } from 'modules/prizes/context/prize-list'
import { usePrizeManagerContext } from 'modules/prizes/context/prize-manager'
import { getStatusLabel } from 'modules/prizes/utils/get-status-label'
import { PrizeSchema } from 'modules/prizes/validation/prize-schema'
import { PrizeActionButtons } from 'modules/prizes/views/components/action-buttons'
import { PrizeDetailsForm } from 'modules/prizes/views/components/manage-prize-panel/prize-details-form'
import moment from 'moment-timezone'
import React, { useRef, useState } from 'react'
import { DisplayMode } from 'types/common/enums/display-mode'
import { Regions } from 'types/common/enums/regions'
import { PrizeActionButtonType } from 'types/modules/prizes/enums/prize-action-button-type'
import { PrizeField } from 'types/modules/prizes/enums/prize-field'
import { IPrize } from 'types/modules/prizes/models/entities/prize'
import { NIL } from 'uuid'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    navItemButton: {
      display: 'flex',
      padding: 16,
    },
    postNavigationItem: {
      display: 'flex',
      width: 40,
      height: 40,
      borderRadius: 90,
      alignItems: 'center',
      justifyContent: 'center',
      background: 'rgba(0,0,0,0.03)',
      color: theme.palette.text.primary,
    },
    navItemIcon: {
      fontSize: 16,
    },
    selectedNavItem: {
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    panelHeader: {
      display: 'flex',
      width: '100%',
      flexShrink: 0,
      height: 72,
    },
    navigationContainer: {
      display: 'flex',
      flex: 2,
      flexGrow: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    panelTitle: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      padding: '0 0 0 1.25rem',
    },
    prizeStatus: {
      marginRight: 20,
    },
    rightHeaderSection: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      padding: '0 1.25rem 0 0',
      flexDirection: 'row-reverse',
      '& .MuiButton-contained': {
        marginRight: 20,
      },
    },
    contentWrapper: {
      display: 'flex',
      flexGrow: 1,
      overflow: 'hidden',
    },
    contentPanel: {
      position: 'relative',
      display: 'flex',
      flex: 1,
      padding: '1.25rem',
      overflow: 'auto',
      borderRight: '1px solid rgba(0,0,0,0.12)',
      '&:last-child': {
        border: 'none',
      },
    },
    splitFooter: {
      display: 'flex',
      width: '100%',
      justifySelf: 'flex-end',
    },
    leftFooterSection: {
      display: 'flex',
      padding: '1.25rem',
      flex: 1,
      justifyContent: 'space-between',
      borderRight: '1px solid rgba(0,0,0,0.12)',
      '&:last-child': {
        border: 'none',
      },
    },
    rightFooterSection: {
      display: 'flex',
      padding: '1.25rem',
      flex: 1,
      justifyContent: 'center',
      '& .MuiButton-contained': {
        marginLeft: '0.625rem',
        marginRight: '0.625rem',
      },
      borderRight: '1px solid rgba(0,0,0,0.12)',
      '&:last-child': {
        border: 'none',
      },
    },
    updateButton: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
      marginRight: '0.625rem',
    },
    resetButton: {
      color: theme.palette.text.primary,
      backgroundColor: '#ecf0f1',
      marginLeft: '0.625rem',
      '&:hover': {
        backgroundColor: '#f2f5f5',
      },
    },
  }),
)

enum Tabs {
  Prize = 'Prize',
  Chest = 'Chest',
}

interface IPrizeDetailsPanelProps {
  isModal?: boolean
  closeModal?: () => void
}

export const ManagePrizePanel: React.FC<IPrizeDetailsPanelProps> = (
  props: IPrizeDetailsPanelProps,
) => {
  const { currentPrize: prize, clearCurrentPrize } = usePrizeListContext()
  const { updatePrize, createPrize } = usePrizeManagerContext()

  const { currentChest } = useChestsContext()

  const displayMode = useRef(
    prize?.id !== NIL ? DisplayMode.Edit : DisplayMode.Create,
  ).current
  const [tab, setTab] = useState(Tabs.Prize)

  const goToPrizeTab = (): void => {
    setTab(Tabs.Prize)
  }

  const goToChestTab = (): void => {
    if (displayMode === DisplayMode.Create) return
    setTab(Tabs.Chest)
  }

  const form = useFormik({
    enableReinitialize: displayMode === DisplayMode.Edit,
    validationSchema: PrizeSchema,
    initialValues: {
      [PrizeField.ID]: prize?.[PrizeField.ID] ?? '',
      [PrizeField.ALLOW_FREE_ENTRY]:
        prize?.[PrizeField.ALLOW_FREE_ENTRY] ?? false,
      [PrizeField.COIN_COST]: prize?.[PrizeField.COIN_COST] ?? 0,
      [PrizeField.START]: prize?.[PrizeField.START] ?? moment().toISOString(),
      [PrizeField.END]: prize?.[PrizeField.END],
      [PrizeField.FEATURED]: prize?.[PrizeField.FEATURED] ?? false,
      [PrizeField.ITEM_ID]: prize?.[PrizeField.ITEM_ID],
      [PrizeField.CHEST_ID]: prize?.[PrizeField.CHEST_ID],
      [PrizeField.ITEM]: {
        [PrizeField.NAME]: '',
        [PrizeField.QUANTITY]: 0,
        [PrizeField.TYPE]: prize?.item?.type || ItemType.Physical,
        [PrizeField.UNIT_COST]: 0,
        ...(prize?.[PrizeField.ITEM] ?? {}),
        [PrizeField.DETAILS]: {
          [PrizeField.BRAND]: '',
          [PrizeField.DESCRIPTION]: '',
          [PrizeField.IMAGE_URL]: '',
          [PrizeField.FEATURED_IMAGE_URL]: '',
          [PrizeField.LOGO_IMAGE_URL]: '',
          [PrizeField.PROMOTION]: {
            [PrizeField.PROMOTION_CODE]: '',
            [PrizeField.PROMOTION_DESCRIPTION]: '',
            [PrizeField.PROMOTION_URL]: '',
            ...(prize?.[PrizeField.ITEM]?.[PrizeField.DETAILS]?.[
              PrizeField.PROMOTION
            ] ?? {}),
          },
          ...(prize?.[PrizeField.ITEM]?.[PrizeField.DETAILS] ?? {}),
        },
      },
      [PrizeField.NAME]: prize?.[PrizeField.NAME] ?? '',
      [PrizeField.PROMOTED]: prize?.[PrizeField.PROMOTED] ?? false,
      [PrizeField.REQUIRED_ENTRIES]: prize?.[PrizeField.REQUIRED_ENTRIES],
      [PrizeField.STATUS]: prize?.[PrizeField.STATUS],
      [PrizeField.TYPE]: prize?.[PrizeField.TYPE],
      [PrizeField.QUANTITY]: prize?.[PrizeField.QUANTITY] ?? 0,
      [PrizeField.VISIBILITY]: prize?.[PrizeField.VISIBILITY],
      tagIds: displayMode === DisplayMode.Edit
        ? prize?.prizeTags ? prize?.prizeTags.map((prizeTag) =>
        prizeTag.tagId) : []
        : [],
      [PrizeField.COUNTRIES]: prize?.[PrizeField.PRIZE_COUNTRIES]
        ? (prize?.[PrizeField.PRIZE_COUNTRIES] || []).map(
            ({ countryId }) => countryId as Regions,
          )
        : [],
      metaData: prize?.metaData || { theme: { backgroundColor: ''} },
      salesStarCost: prize?.starCostBeforeDiscount ? prize?.starCost : undefined,
      starCost: prize?.starCostBeforeDiscount ? prize?.starCostBeforeDiscount : (prize?.starCost ?? 0),
    },
    onSubmit: (values: Partial<IPrize>) => {
      const modifiedValues: Partial<IPrize> = {
        ...values,
        metaData: values.metaData?.theme.backgroundColor ? values.metaData : null,
        tags: values.tagIds || [],
        starCost: values.salesStarCost ? values.salesStarCost : (values.starCostBeforeDiscount || values.starCost),
        starCostBeforeDiscount: values.salesStarCost ? (values.starCostBeforeDiscount || values.starCost) : null
      }
      if (displayMode === DisplayMode.Create) {
        createPrize(modifiedValues)
        return
      }
      updatePrize(modifiedValues)
    },
  })

  const classes = useStyles()

  return (
    <PanelContainer>
      <Panel container xs={12}>
        <Box className={classes.panelHeader}>
          <Box className={classes.panelTitle}>
            <Typography
              variant="h5"
              color="textPrimary"
              style={{
                paddingRight: 10,
              }}
            >
              {`${displayMode} ${prize?.type ?? ''} `}
            </Typography>
            <Typography
              variant="h5"
              color="primary"
              className={classes.prizeStatus}
            >
              {getStatusLabel(prize!.status!)}
            </Typography>
          </Box>
          <Box className={classes.navigationContainer}>
            <ButtonBase
              className={classes.navItemButton}
              onClick={goToPrizeTab}
            >
              <Box
                className={clsx(
                  classes.postNavigationItem,
                  tab === Tabs.Prize ? classes.selectedNavItem : '',
                )}
              >
                <Box className={classes.navItemIcon}>
                  <HomeIcon />
                </Box>
              </Box>
            </ButtonBase>
            <ButtonBase
              className={classes.navItemButton}
              onClick={goToChestTab}
              disabled={displayMode === DisplayMode.Create}
              disableRipple={displayMode === DisplayMode.Create}
              disableTouchRipple={displayMode === DisplayMode.Create}
            >
              <Box
                className={clsx(
                  classes.postNavigationItem,
                  tab === Tabs.Chest ? classes.selectedNavItem : '',
                )}
              >
                <Box className={classes.navItemIcon}>
                  <TreasureChest />
                </Box>
              </Box>
            </ButtonBase>
          </Box>
          <Box className={classes.rightHeaderSection}>
            {props.isModal && (
              <IconButton
                onClick={(): void =>
                  !IsNullOrUndefined(props.closeModal)
                    ? props.closeModal!()
                    : clearCurrentPrize()
                }
                size="large">
                <SvgIcon>
                  <CrossIcon />
                </SvgIcon>
              </IconButton>
            )}
          </Box>
        </Box>
        <Divider />
        {tab === Tabs.Prize && (
          <>
            <Box className={classes.contentWrapper}>
              <Box className={classes.contentPanel}>
                <PrizeDetailsForm
                  {...{
                    prize: form.values,
                    handleChange: form.handleChange,
                    setFieldTouched: form.setFieldTouched,
                    setFieldValue: form.setFieldValue,
                    errors: form.errors,
                    setValues: form.setValues,
                    chestDescription: currentChest?.description,
                    goToChestTab,
                    displayMode: displayMode,
                  }}
                />
              </Box>
              <Box className={classes.contentPanel}>
                <PrizeFeedPreview prize={form.values} />
              </Box>
            </Box>
            <Divider />
            <Box className={classes.splitFooter}>
              <Box className={classes.leftFooterSection}>
                <Button
                  variant="contained"
                  startIcon={<UndoIcon />}
                  className={classes.resetButton}
                  size="small"
                  onClick={(): void => {
                    form.resetForm()
                  }}
                  disabled={!form.dirty}
                >
                  Undo
                </Button>
                <Button
                  variant="contained"
                  startIcon={<SaveIcon />}
                  className={classes.updateButton}
                  size="small"
                  onClick={(): void => {
                    form.submitForm()
                  }}
                  disabled={!form.dirty}
                >
                  Save
                </Button>
              </Box>
              <Box className={classes.rightFooterSection}>
                <PrizeActionButtons
                  prize={prize! as IPrize}
                  exclude={[
                    PrizeActionButtonType.View,
                    PrizeActionButtonType.Feature,
                    PrizeActionButtonType.Review,
                  ]}
                />
              </Box>
            </Box>
          </>
        )}
        {tab === Tabs.Chest && displayMode === DisplayMode.Edit && (
          <ManageChest onChestRemoved={goToPrizeTab}>
            <PrizeFeedPreview prize={form.values} />
          </ManageChest>
        )}
      </Panel>
    </PanelContainer>
  );
}
