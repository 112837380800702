import { InputLabel } from '@mui/material'
import { AutocompleteSearchSelect } from 'components/controls/inputs/downshift/autocomplete/autocomplete-search-select'
import { useSimpleFormStyles } from 'components/simple-form-gen'
import { useTypedSelector } from 'core/redux/utils'
import { Actions, Selectors } from 'modules/prizes'
import { searchPrizes } from 'modules/prizes/utils/get-search-filter-option'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { OnChange } from 'types/common/interfaces/IForm'
import { IPrize } from 'types/modules/prizes/models/entities/prize'

interface IPrizesSelectProps {
  handleChange: (value: OnChange) => void
  initialSelectedId: string
  name: string
  label: string
}

export const PrizeSelect = ({
  handleChange,
  initialSelectedId,
  name,
  label
}: IPrizesSelectProps): JSX.Element | null => {
  const [counter, setCounter] = useState(0)
  const getPrize = useTypedSelector((state) => Selectors.getPrizeGetter(state))
  const dispatch = useDispatch()
  const initiallySelectedPrize = getPrize(initialSelectedId)
  const simpleFormClasses = useSimpleFormStyles()
  const hasFetchedPrize = !initialSelectedId || !!initiallySelectedPrize

  useEffect(() => {
    if (!hasFetchedPrize) {
      dispatch(Actions.getPrizeById({
        prizeId: initialSelectedId,
        promise: {
          onResolve: (): void => setCounter(counter + 1)
        }
      }))
    }
  }, [hasFetchedPrize])

  if (!hasFetchedPrize) return null

  return  (<>
    <InputLabel className={simpleFormClasses.inputLabel}>
      {label || 'Prize'}
    </InputLabel>
    <AutocompleteSearchSelect
        variant="filled"
        initialSelectedItem={initiallySelectedPrize || null}
        availableItems={[]}
        onSelectedItemChange={(item): void => {
          handleChange({
            // @ts-expect-error to be decided
            target: {
              name,
              value: item?.id || ''
            }
          })
        }}
        fieldName="item.name"
        searchAsyncActionCreator={searchPrizes}
        selectorMethod={Selectors.getCachedInstantPrizes}
        options={{
          autocompleteSearchResultContent: (item: unknown): string => {
            const prize = item as IPrize
            return `${prize?.item?.name} - ${prize?.item?.details?.brand}`
          },
        }}
      />
  </>)
}