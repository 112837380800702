import * as yup from 'yup'
import { MAX_RESULT_DESCRIPTION } from 'modules/posts/constants'
import { ResultField } from 'types/modules/posts/enums/result-field'

export const ResultSchema = yup.object({
  [ResultField.MEDIA]: yup.object().shape({
    [ResultField.DESCRIPTION]: yup.string()
      .max(MAX_RESULT_DESCRIPTION, `can not be more than ${MAX_RESULT_DESCRIPTION}`)
  }),
})
