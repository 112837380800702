import { MODULE_NAME } from '../constants'

const ActionTypes: { [key: string]: string } = {
  GET_POST_COMMENTS: `${MODULE_NAME}/GET_POST_COMMENTS`,
  CREATE_POST_COMMENT: `${MODULE_NAME}/CREATE_POST_COMMENT`,
  UPDATE_POST_COMMENT: `${MODULE_NAME}/UPDATE_POST_COMMENT`,
  DELETE_POST_COMMENT: `${MODULE_NAME}/DELETE_POST_COMMENT`,
}

export default ActionTypes
