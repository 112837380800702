import React, { PropsWithRef, useEffect, useState } from 'react'
import _ from 'core/utils/deepdash'
import {
  Box,
  IconButton,
  SvgIcon,
  TextField,
  Typography,
} from '@mui/material'
import {
  Item,
} from 'components/controls/inputs/downshift/item'
import { useCombobox, UseComboboxStateChange } from 'downshift'
import { useStyles } from 'components/controls/inputs/downshift/styles'
import { ISelectProps } from 'components/controls/inputs/downshift/select/select-props'
import { IAutoCompleteListProps } from 'components/controls/inputs/downshift/autocomplete/autocomplete-list-props'
import AutocompleteList from 'components/controls/inputs/downshift/autocomplete/autocomplete-list'
import clsx from 'clsx'
import { CrossIcon } from 'components/icons'
import { get } from 'lodash-es'
import SelectStateReducer from './select-state-reducer'

const DownshiftSelect = <TItem,>(
  props: PropsWithRef<ISelectProps<Item<TItem>>>,
): JSX.Element => {
  const classes = useStyles()

  const [inputValue, setInputValue] = useState('')
  const { fieldName = 'name' } = props

  useEffect(() => {
    props.onInputValueChanged && props.onInputValueChanged(inputValue)
  }, [inputValue])

  const availableItemsFilter = (item: Item<TItem>): boolean =>
    !_.isEqual(
      get((props.initialSelectedItem as unknown as any), fieldName) ?? '',
      get((item as unknown as any),fieldName),
    ) &&
    (get((item as unknown as any),fieldName) || '')
      .toLowerCase()
      .includes(inputValue.toLowerCase())

  const inputItems = props.availableItems.filter((item) =>
    availableItemsFilter(item),
  )

  const comboboxProps = {
    inputValue,
    defaultHighlightedIndex: 0,
    selectedItem: props.initialSelectedItem,
    items: inputItems,
    stateReducer: SelectStateReducer,
    onStateChange: (changes: UseComboboxStateChange<Item<TItem>>): void => {
      const { inputValue, type, selectedItem } = changes

      switch (type) {
        case useCombobox.stateChangeTypes.InputChange:
          setInputValue(inputValue ?? '')
          break

        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur:
          if (selectedItem) {
            setInputValue('')
            props.onSelectedItemChange(selectedItem)
          }
          break

        default:
          break
      }
    },
  }

  const {
    isOpen,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    selectedItem: inputSelectedItem,
  } = useCombobox<TItem>(comboboxProps)

  const autoCompleteListProps: IAutoCompleteListProps<TItem> = {
    isOpen,
    getItemProps,
    getMenuProps,
    inputItems,
    highlightedIndex,
    autocompleteSearchResultContent:
      props.options?.autocompleteSearchResultContent,
    optionDisabled: props.optionDisabled,
    fieldName: props.fieldName
  }

  return (
    <div className={classes.selectContainer}>
      <React.Fragment>
        <div {...getComboboxProps()}>
          <TextField
            className={clsx(
              props.initialSelectedItem !== null && classes.hidden,
            )}
            fullWidth
            InputProps={{
              ...getInputProps(),
            }}
            label={props.label ?? null}
            variant={props.variant ?? 'outlined'}
          />
          {props.initialSelectedItem !== null && (
            <Box className={classes.selectedItem}>
              <Typography
                color={'textPrimary'}
                style={{
                  overflow: 'hidden',
                  whiteSpace: 'pre',
                  width: 'calc(100% - 20px)',
                }}
              >
                {
                  props.options?.autocompleteSearchResultContent ?
                    props.options?.autocompleteSearchResultContent!(
                      props.initialSelectedItem,
                    )
                    : get(props.initialSelectedItem, props.fieldName || 'name')
                }
              </Typography>
              <IconButton
                style={{
                  position: 'absolute',
                  right: '-4px',
                  top: '-4px',
                }}
                onClick={(): void => {
                  props.onSelectedItemChange(null)
                }}
                size="large">
                <SvgIcon>
                  <CrossIcon />
                </SvgIcon>
              </IconButton>
            </Box>
          )}
        </div>
      </React.Fragment>
      <AutocompleteList {...autoCompleteListProps} />
    </div>
  );
}

export default DownshiftSelect
