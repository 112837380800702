import ContentDashboard from 'components/content-dashboard'
import { DashboardContextProvider } from 'components/content-dashboard/context'
import { IContentDashboardProps } from 'components/content-dashboard/props/content-dashboard-props'
import { useRequestContext } from 'core/api/context'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import React, { useCallback } from 'react'
import { PostField } from 'types/modules/posts/enums/post-field'
import _ from 'core/utils/deepdash'
import SortDirection from 'types/common/enums/sort-direction'
import { IFilter } from 'types/common/filtering/models/entities/IFilter'
import { SelectFilterMode } from 'types/common/filtering/enums/SelectFilterMode'
import { ImageIcon } from 'components/icons'
import { useRouter } from 'core/routing/hooks/use-router'
import PrizeStatus from 'types/modules/prizes/enums/prize-status'
import { CustomPrizeFilters } from 'modules/prizes/views/components/dashboard/custom-filters'
import { IPrize } from 'types/modules/prizes/models/entities/prize'
import { PrizeCalendarDateGridCell } from 'modules/prizes/views/components/dashboard/prize-calendar-date-grid-cell'
import { PrizeListHeaderPanel } from 'modules/prizes/views/components/dashboard/prize-list-header-panel'
import { PrizeListToolbar } from 'modules/prizes/views/components/dashboard/prize-list-toolbar'
import { PrizeListItem } from 'modules/prizes/views/components/dashboard/prize-list-item'
import { PrizeCalendarTimeGridCell } from 'modules/prizes/views/components/dashboard/prize-calendar-time-grid-cell'
import { PrizeListLegend } from 'modules/prizes/views/components/dashboard/prize-list-legend'
import { ManagePrizeModal } from 'modules/prizes/views/components/manage-prize-panel/modal'
import { usePrizeListContext } from 'modules/prizes/context/prize-list'

export const PrizesDashboard: React.FC<{}> = () => {
  const { currentPrize, prizes, statusFilterConfig, initialiseDashboard } =
    usePrizeListContext()

  const { pushQueryParams } = useRouter()

  const rc = useRequestContext()

  const statusFilter = rc?.getFilterByField(PostField.STATUS)

  const onViewModeChange = React.useCallback(
    (viewMode: string) => {
      if (IsNullOrUndefined(statusFilter)) return

      const newSortValue = _.clone(rc?.sortValue!)
      const newStatusFilter = _.clone(statusFilter)

      switch (viewMode) {
        case 'list': {
          newSortValue.sortBy = PostField.START
          newSortValue.sortDirection = SortDirection.Descending

          newStatusFilter!.value = [PrizeStatus.Open]

          rc?.setSortValue(newSortValue)
          rc?.applyFilter(newStatusFilter!)
          break
        }

        case 'calendar': {
          newSortValue.sortBy = PostField.START
          newSortValue.sortDirection = SortDirection.Ascending

          newStatusFilter!.value = [
            PrizeStatus.Open,
            PrizeStatus.Scheduled,
            PrizeStatus.Pending,
          ]

          rc?.setSortValue(newSortValue)
          rc?.applyFilter(newStatusFilter!)

          break
        }

        default:
          return
      }
    },
    [statusFilter],
  )

  const onTabChange = useCallback(
    (value: IFilter['value'], mode: SelectFilterMode) => {
      const valueIsArray = Array.isArray(value)
      const valueAsArray = value as PrizeStatus[]

      if (IsNullOrUndefined(statusFilter) || !valueIsArray) return

      if (mode === SelectFilterMode.Multiple) {
        rc?.setActive(true)
        return
      }

      const status = valueAsArray[0]

      switch (status) {
        case PrizeStatus.Open:
        case PrizeStatus.Scheduled: {
          rc?.setSortValue({
            sortBy: PostField.START,
            sortDirection: SortDirection.Descending,
          })
          return
        }

        default: {
          rc?.setSortValue({
            sortBy: PostField.UPDATED_AT,
            sortDirection: SortDirection.Descending,
          })
          return
        }
      }
    },
    [statusFilter],
  )

  const dashboardProps: IContentDashboardProps<IPrize> = {
    onDashboardMount: initialiseDashboard,
    onViewModeChange,
    onTabChange,
    tabFilterConfig: statusFilterConfig,
    customFilterComponent: <CustomPrizeFilters />,
    listOptions: {
      headerPanelComponent: <PrizeListHeaderPanel />,
      listItemComponent: PrizeListItem,
      toolbarComponent: <PrizeListToolbar />,
      legendComponent: <PrizeListLegend />,
    },
    calendarOptions: {
      toolbarComponent: <PrizeListToolbar />,
      timeGridCellComponent: PrizeCalendarTimeGridCell,
      dateGridCellComponent: PrizeCalendarDateGridCell,
      headerActionButton: {
        icon: <ImageIcon />,
        onClick: (date: string): void => {
          pushQueryParams({
            viewMedia: date,
          })
        },
      },
    },
    data: prizes,
  }

  return (
    <React.Fragment>
      <DashboardContextProvider>
        <ContentDashboard {...dashboardProps} />
      </DashboardContextProvider>
      {!IsNullOrUndefined(currentPrize) && (
        <ManagePrizeModal currentPrize={currentPrize} />
      )}
    </React.Fragment>
  )
}
