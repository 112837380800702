import { Box, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx'
import { ICalendarGridCellProps } from 'components/calendar/props/calendar-grid-cell-props'
import {
  IsNullOrUndefined,
  IsNullUndefinedOrEmpty,
} from 'core/utils/isNullOrUndefined'
import _ from 'core/utils/deepdash'
import React from 'react'
import { ILiveSchedule } from 'types/modules/live-schedules/models/entities/live-schedule'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    eventGridCellContainer: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      '&:hover': {
        background: 'rgba(0,0,0,0.03)',
        cursor: 'pointer',
      },
    },
    event: {
      display: 'flex',
      alignItems: 'center',
    },
    eventLabel: {
      fontSize: '0.7rem',
      position: 'relative',
      top: 2,
    },
    circleIcon: {
      width: 10,
      height: 10,
      borderRadius: 90,
      background: theme.palette.text.primary,
      marginRight: '0.3rem',
    },
  }),
)

export const LiveScheduleCalendarDateGridCell: React.FC<
  ICalendarGridCellProps<ILiveSchedule>
> = (props: ICalendarGridCellProps<ILiveSchedule>) => {
  const { items, dateTime } = props

  const classes = useStyles()

  const renderCellContent = (): JSX.Element | null => {
    if (IsNullOrUndefined(items)) return null

    return (
      <React.Fragment>
        {_.map(items, (liveSchedule) => {
          return (
            <Box className={classes.event}>
              <Box component="span" className={clsx(classes.circleIcon)} />
              <Typography
                variant="body2"
                color="textPrimary"
                className={clsx(classes.eventLabel)}
              >
                {liveSchedule.name}
              </Typography>
              {!IsNullUndefinedOrEmpty(liveSchedule.description) && (
                <Typography
                  component="span"
                  variant="body2"
                  className={clsx(classes.eventLabel)}
                >
                  {`: ${liveSchedule.description}`}
                </Typography>
              )}
            </Box>
          )
        })}
      </React.Fragment>
    )
  }

  return (
    <Box className={classes.eventGridCellContainer}>{renderCellContent()}</Box>
  )
}
