import { AppState } from 'types/redux/types/app-state'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import _ from 'core/utils/deepdash'

import IPaginatedItems from 'types/common/pagination/models/entities/paginated-items'
import { TagGroup } from 'types/modules/tags/models/entities/tag-group'
import { IWatchlistState } from 'types/modules/watchlist/models/state/watchlist-state'
import { MODULE_NAME } from '../constants'

const getState = (state: AppState): IWatchlistState =>
  _.cloneDeep(state[MODULE_NAME])

export const getTagGroups = (state: AppState): IPaginatedItems<TagGroup> =>
  getState(state).tagGroups
