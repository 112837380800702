const paths = {
  portalUsers: 'portalusers',
  switchAccount: 'switch-accounts',
  mobileAppUsers: 'users',
  accounts: 'accounts',
  topUp: 'top-up',
  tickets: 'tickets',
  api: 'api',
  void: 'void',
}

export default paths
