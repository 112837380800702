import { Box, ButtonBase, Typography } from '@mui/material'
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { SimpleImageDisplay } from 'components/simple-image-display'
import { ICampaignItem } from 'modules/campaigns/models/campaign'
import React from 'react'

interface Props {
  item: ICampaignItem
  onClick: () => void
  isSelected: boolean
}

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      flex: 1,
      display: 'flex'
    },
    rightContainer: {
      flex: 1,
      alignItems: 'flex-start'
    },
    text: {
      textAlign: 'left'
    },
    selected: {
      background: '#e3dede38',
      borderRadius: 12
    }
  }),
)


export function CampaignItem({ item, onClick, isSelected }: Props) {
  const classes = useStyles()


  return (
    <ButtonBase onClick={onClick} className={isSelected ? classes.selected : ''}>
      <Box alignItems="flex-start" className={classes.container}>
        <Box>
          <SimpleImageDisplay imageUrl={item.imageUrl} width={80} />
        </Box>
        <Box className={classes.rightContainer}>
          <Box className={classes.text}>
            <Typography component="span" variant="button">
              {item.title}
            </Typography>
          </Box>
          <Box className={classes.text}>
            <Typography component="span" variant="button">
              {item.description}
            </Typography>
          </Box>
          <Box className={classes.text}>
            <Typography component="span" variant="button">
              {item.order}
            </Typography>
          </Box>
        </Box>
      </Box>
    </ButtonBase>
  )
}