import { Reducer } from 'redux'

import {
  AUTHENTICATION_FAILED,
  AUTHENTICATION_SUCCESS,
  LOGOUT_SUCCESS,
} from 'modules/account/action-types'

import IAccountState from 'types/modules/account/models/state/IAccountState'
import { ISimpleAction } from 'types/redux/interfaces/IAction'
import _ from 'core/utils/deepdash'
import userApiStateMapper from 'modules/account/mappers/user-api-state-mapper'
import { CognitoUser } from 'amazon-cognito-identity-js'

const initialState: IAccountState = {
  user: null,
}

const loginReducer: Reducer<IAccountState, ISimpleAction> = (
  state = initialState,
  action,
) => {
  const newState = _.clone(state)

  switch (action.type) {
    case AUTHENTICATION_SUCCESS:
      newState.user = userApiStateMapper.map(
        action.payload?.user as CognitoUser,
      )
      return newState
    case AUTHENTICATION_FAILED:
      return state
    case LOGOUT_SUCCESS:
      newState.user = null
      return newState
    default:
      return state
  }
}

// eslint-disable-next-line no-undef
export { loginReducer, IAccountState as ILoggedInUserState }

export default loginReducer
