import { Box, ButtonBase, Step, StepIconProps, StepLabel, Stepper, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx'
import { PostNavigationAddButton } from 'modules/posts/views/components/manage-post-panel/post-navigation-add-button'
import { PostNavigationItem } from 'modules/posts/views/components/manage-post-panel/post-navigation-item'
import { IPostNavigationConfigItem } from 'modules/posts/views/components/manage-post-panel/types/post-navigation-config-item'
import React from 'react'
import PostStatus from 'types/modules/posts/enums/post-status'
import { IPost } from 'types/modules/posts/models/entities/post'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    postNavigation: {
      display: 'flex',
      flexDirection: 'row',
      '&.disabled': {
        opacity: 0.3,
      },
    },
  }),
)

interface IPostNavigationProps {
  post: IPost
  currentPage: number
  setCurrentPage: (page: number) => void
  navigateToResultOrQuestion: (itemId: string) => void
  navigationConfig: IPostNavigationConfigItem[]
  disabled?: boolean
}

export const PostNavigation: React.FC<IPostNavigationProps> = (
  props: IPostNavigationProps,
) => {
  const {
    post,
    currentPage,
    setCurrentPage,
    navigateToResultOrQuestion,
    navigationConfig,
    disabled,
  } = props

  const classes = useStyles()

  const handleNavigationChange = (page: number): void => {
    setCurrentPage(page)
  }

  const canAddQuestion = [
    PostStatus.Created,
    PostStatus.Pending,
    PostStatus.Scheduled,
  ].includes(post.status)

  return (
    <Box className={clsx(classes.postNavigation, disabled && 'disabled')}>
      {navigationConfig.map((config, index) => (
        <PostNavigationItem
          key={`page-${index + 1}`}
          {...{
            config,
            page: index + 1,
            active: currentPage === index + 1,
            onClick: handleNavigationChange,
            disabled: disabled,
          }}
        />
      ))}
      {canAddQuestion && (
        <PostNavigationAddButton
          {...{
            post,
            navigateToResultOrQuestion,
          }}
        />
      )}
    </Box>
  )
}
