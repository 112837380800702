import { Box, Chip, SvgIcon, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx'
import {
  StarIcon,
  CoinIcon,
  ArrowRightIcon,
  PlusIcon,
  HeartIcon,
} from 'components/icons'
import { useTypedSelector } from 'core/redux/utils'
import {
  IsNullOrUndefined,
  IsNullUndefinedOrEmpty,
} from 'core/utils/isNullOrUndefined'
import React from 'react'
import { IPostDetails } from 'types/modules/posts/models/entities/post-details'
import { Tag } from 'types/modules/tags/models/entities/tag'
import { Selectors as PrizeSelectors } from 'modules/prizes'
import { PostFeedWrapper } from 'modules/app/views/components/previews/post-feed-wrapper'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    postCoverContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: '100%',
      height: 450,
      borderRadius: 12,
      padding: 15,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
    },
    postCoverTopContainer: {
      display: 'flex',
      width: '100%',
      height: 'auto',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    postCoverPostType: {
      display: 'flex',
      background: '#FFFFFF',
      borderRadius: 3,
      padding: '0.2rem 0.5rem',
      height: 'max-content',
    },
    postCoverReward: {
      display: 'flex',
      background: '#FFFFFF',
      borderRadius: 3,
      padding: '0.2rem',
      flexDirection: 'column',
    },
    rewardCurrency: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      minHeight: 15,
      justifyContent: 'center',
      alignItems: 'center',
    },
    coinIcon: {
      display: 'flex',
      width: 19,
      height: 15,
      marginRight: 2,
      '&:last-child': {
        marginRight: 0,
      },
    },
    starIcon: {
      display: 'flex',
      width: 15,
      height: 15,
      marginRight: 2,
      '&:last-child': {
        marginRight: 0,
      },
    },
    starCount: {
      color: theme.palette.text.primary,
      fontSize: '0.8rem',
      fontWeight: 300,
      position: 'relative',
      top: 1,
      marginRight: '0.2rem',
    },
    postCoverPostTypeIcon: {
      fontSize: '0.6rem',
      position: 'relative',
      top: 4,
      marginRight: 2,
    },
    postCoverPostTypeLabel: {
      color: theme.palette.text.primary,
      fontSize: '0.8rem',
      fontWeight: 300,
      position: 'relative',
      top: 1,
    },
    postCoverDetailsContainer: {
      display: 'flex',
      width: '100%',
      height: 'auto',
      padding: 20,
      background: '#FFFFFF',
      borderRadius: 12,
      flexDirection: 'column',
    },
    postCoverDetailsTopLine: {
      display: 'flex',
      width: '100%',
      height: 'auto',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      marginBottom: 10,
    },
    postDetailsTagsAndArrowContainer: {
      display: 'flex',
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    postTitle: {
      fontSize: 20,
      lineHeight: 1.4,
      fontWeight: 600,
    },
    heartContainer: {
      display: 'flex',
      marginLeft: 10,
      fontSize: 25,
    },
    postTags: {
      marginRight: 'auto',
    },
    tag: {
      height: 'auto',
      background: '#ffffff',
      border: '1px solid',
      borderColor: 'rgb(216, 216, 216)',
      borderRadius: 8,
      marginRight: 5,
      '& .MuiChip-label': {
        paddingLeft: 8,
        paddingRight: 8,
        fontSize: '0.7rem',
        position: 'relative',
        top: 1,
      },
    },
    tagIcon: {
      fontSize: '0.6rem',
    },
    tagSubscribeIcon: {
      marginLeft: 2,
      width: 10,
      color: theme.palette.text.primary,
    },
    rightArrowContainer: {
      display: 'flex',
      position: 'relative',
      width: 25,
      height: 25,
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: '0.4rem',
      color: '#000000',
      border: '1px solid #000000',
      borderRadius: 90,
    },
    postPrize: {
      display: 'flex',
      marginTop: 5,
      width: 35,
      height: 35,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      marginLeft: 10,
      marginRight: 10,
    },
  }),
)

export const PostFeedItem: React.FC<IPostDetails> = (
  postDetails: IPostDetails,
) => {
  const classes = useStyles()
  const postTypeTag = (postDetails.tags as Tag[]).find((tag) => tag.level === 0)

  const hasStarReward =
    !IsNullOrUndefined(postDetails.starsWin) && postDetails.starsWin !== 0

  const hasPrize =
    postDetails.betCard &&
    !IsNullUndefinedOrEmpty(postDetails.postPrizes) &&
    !IsNullUndefinedOrEmpty((postDetails.postPrizes || [])[0]?.prizeId)

  const postPrize = useTypedSelector((state) =>
    PrizeSelectors.getCachedPrizeById(
      state,
      (postDetails.postPrizes || [])[0]?.prizeId,
    ),
  )

  const postPrizeStyles = {
    backgroundImage: `url(${postPrize?.item.details?.imageUrl})`,
  }

  return (
    <PostFeedWrapper>
      <Box className={classes.postCoverTopContainer}>
        <Box className={classes.postCoverPostType}>
          <Typography className={classes.postCoverPostTypeIcon}>
            {`${postTypeTag?.media?.icon ?? ''} `}
          </Typography>
          <Typography className={classes.postCoverPostTypeLabel}>
            {postTypeTag?.name ?? ''}
          </Typography>
        </Box>
        <Box className={classes.postCoverReward}>
          <Box className={classes.rewardCurrency}>
            {hasStarReward ? (
              postDetails.starsWin <= 5 ? (
                new Array(postDetails.starsWin).fill(null).map((x, index) => (
                  <i key={`star-${index}`} className={classes.starIcon}>
                    <StarIcon />
                  </i>
                ))
              ) : (
                <React.Fragment>
                  <Typography className={classes.starCount}>
                    {postDetails.starsWin}
                  </Typography>
                  <i key={`star`} className={classes.starIcon}>
                    <StarIcon />
                  </i>
                </React.Fragment>
              )
            ) : (
              <i className={classes.coinIcon}>
                <CoinIcon />
              </i>
            )}
          </Box>
          {hasPrize && !IsNullOrUndefined(postPrize) && (
            <Box className={classes.postPrize} style={postPrizeStyles} />
          )}
        </Box>
      </Box>
      <Box className={classes.postCoverDetailsContainer}>
        <Box className={classes.postCoverDetailsTopLine}>
          <Typography className={classes.postTitle}>
            {postDetails.title}
          </Typography>
          <Box className={classes.heartContainer}>
            <HeartIcon />
          </Box>
        </Box>
        <Box className={classes.postDetailsTagsAndArrowContainer}>
          <Box className={classes.postTags}>
            {postDetails.tags &&
              (postDetails.tags as Tag[])
                .filter((tag) => tag.level !== 0)
                .sort((a, b) => a.level! - b.level!)
                .slice(0, 2)
                .map((tag) => (
                  <Chip
                    key={`post-${postDetails.id}-tag-${tag.id}`}
                    label={tag.name}
                    icon={
                      <i className={classes.tagIcon}>
                        {tag.media?.icon ?? null}
                      </i>
                    }
                    className={clsx(classes.tag)}
                    onDelete={(): void => {
                      return
                    }}
                    deleteIcon={
                      <SvgIcon className={classes.tagSubscribeIcon}>
                        <PlusIcon />
                      </SvgIcon>
                    }
                  />
                ))}
          </Box>
          <Box className={classes.rightArrowContainer}>
            <SvgIcon
              style={{
                fontSize: 15,
              }}
            >
              <ArrowRightIcon />
            </SvgIcon>
          </Box>
        </Box>
      </Box>
    </PostFeedWrapper>
  )
}
