/* eslint-disable @typescript-eslint/no-empty-interface */
import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

// Material UI Theme
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material/styles';
import GossTheme from 'core/theme/goss-theme'

import Router from 'core/routing/components/router'
import store from 'core/redux/store'
import { AuthProvider } from 'core/authentication/providers/auth-provider'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { SnackbarProvider, SnackbarProviderProps } from 'notistack'
import { LayoutProvider } from 'core/layout/context'
import { PortalUsersProvider } from 'modules/users/portal/context'
import { TagsProvider } from 'modules/tags/context'
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import { Notifier } from 'core/alerts/views/components/notifier';
import moment from 'moment-timezone';
import { TOAST_CONFIG } from 'utils/constants';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}


moment?.tz?.setDefault?.('Etc/UTC')


const AppProvider: React.FC<{}> = () => {

  const snackbarProviderProps: Partial<SnackbarProviderProps> = {
    maxSnack: 5,
    preventDuplicate: true,
  }

  return (
    <Provider store={store}>
      <BrowserRouter>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={GossTheme}>
            <LocalizationProvider dateAdapter={AdapterMoment} dateLibInstance={moment}>
              <SnackbarProvider {...snackbarProviderProps}>
                <>
                  <PortalUsersProvider>
                    {/* @ts-expect-error overdoing it */}
                    <ToastContainer {...TOAST_CONFIG} style={{ width: '60%', fontSize: 14 }} />
                    <Notifier />
                    <AuthProvider>
                      <LayoutProvider>
                        <TagsProvider>
                          <Router />
                        </TagsProvider>
                      </LayoutProvider>
                    </AuthProvider>
                  </PortalUsersProvider>
                </>
              </SnackbarProvider>
            </LocalizationProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </BrowserRouter>
    </Provider>
  );
}

export default AppProvider
