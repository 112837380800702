import { Box, FormHelperText, Grid, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import _ from 'core/utils/deepdash'
import { FormGen, FormTypes } from 'components/simple-form-gen'
import React from 'react'
import { IForm } from 'types/common/interfaces/IForm'
import { IQuestGroup } from 'modules/quests/models/quest-group'
import { QuestGroupField } from 'modules/quests/enums/quest-group-field'
import { QuestsSelect } from 'modules/quests/view/quest-groups/quest-group-modal/quests-select'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      width: '100%',
      height: '600px',
      '& .MuiGrid-item': {
        display: 'flex',
        flexDirection: 'column',
      },
    },
    questsWrapper: {
      borderLeft: '1px solid #f4f4f4',
      paddingLeft: 8,
      height: '100%',
    },
    container: {
      height: '100%'
    },
    questsError: {
      marginTop: -16
    }
  }),
)

interface IQuestGroupFormProps extends IForm {
  questGroup: IQuestGroup
}

export const QuestGroupForm: React.FC<IQuestGroupFormProps> = ({
  questGroup,
  handleChange,
  setFieldValue,
  errors
}): JSX.Element => {
  const classes = useStyles()
  const elements = [
    {
      name: QuestGroupField.NAME,
      label: 'Name',
      value: questGroup?.[QuestGroupField.NAME] || '',
      type: FormTypes.TEXT_INPUT,
      otherProps: {
        error: !!errors[QuestGroupField.NAME],
        helperText: errors[QuestGroupField.NAME]
      }
    },
    {
      name: QuestGroupField.TEST,
      label: 'Test',
      value: questGroup?.[QuestGroupField.TEST] || '',
      type: FormTypes.TEXT_INPUT,
      otherProps: {
        error: !!errors[QuestGroupField.TEST],
        helperText: errors[QuestGroupField.TEST]
      }
    },
    {
      name: QuestGroupField.VARIANT,
      label: 'Variant',
      value: questGroup?.[QuestGroupField.VARIANT] || '',
      type: FormTypes.TEXT_INPUT,
      otherProps: {
        error: !!errors[QuestGroupField.VARIANT],
        helperText: errors[QuestGroupField.VARIANT]
      }
    },
  ]

  return (
    <Box className={classes.form}>
      <Grid container spacing={2} className={classes.container}>
        <Grid item xs={4}>
          <Grid container spacing={2}>
              {elements.map((element) => (
                <Grid
                  item xs={12}
                  key={element.name}
                >
                  <FormGen
                    handleChange={handleChange}
                    {...element}
                  />
                </Grid>
              ))}
            </Grid>
        </Grid>

        <Grid item xs={8} className={classes.questsWrapper}>
          <QuestsSelect
            handleChange={(items): void => {
              setFieldValue(QuestGroupField.QUEST_GROUP_QUESTS, items)
            }}
            initialSelectedIds={questGroup.questGroupQuests.map((group) => group.questId || group.id)}
          />
          {!!errors[QuestGroupField.QUEST_GROUP_QUESTS] &&
            <FormHelperText error className={classes.questsError}>
              {errors[QuestGroupField.QUEST_GROUP_QUESTS]}
            </FormHelperText>
          }
        </Grid>
      </Grid>
    </Box>
  )
}
