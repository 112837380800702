import React from 'react'
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Box, Grid, InputLabel } from '@mui/material'
import { TagPromotionField } from 'types/modules/tags/enums/tag-promotion-fields'
import { DateTimePicker } from '@mui/x-date-pickers';
import { ITagPromotion } from 'types/modules/tags/models/entities/tag-promotion'
import { Actions, Selectors } from 'modules/tags'
import moment from 'moment-timezone'
import { AutocompleteSearchMultiselect } from 'components/controls/inputs/downshift/autocomplete/autocomplete-search-multiselect'
import { Tag } from 'types/modules/tags/models/entities/tag'
import { OverrideChipProps } from 'components/controls/inputs/downshift/multiselect/multiselect-props'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputLabel: {
      display: 'flex',
      marginBottom: '0.625rem',
      marginTop: 20,
    },
    inputLabelHint: {
      fontSize: '12',
      color: '#ccc',
      marginLeft: 10,
    },
    tagsContainer: {
      marginTop: 20,
      zIndex: 10,
      minHeight: 350,
    },
  }),
)

export interface IPromoteFormProps {
  tagPromotion: ITagPromotion
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  initialSelectedTags: Tag[]
}

export const TagPromoteForm: React.FC<IPromoteFormProps> = (
  props: IPromoteFormProps,
) => {
  const classes = useStyles()
  const { tagPromotion, setFieldValue } = props

  const handleSelectedTagsChange = (selectedTags: Tag[]): void => {
    setFieldValue(TagPromotionField.TAGS, selectedTags)
  }

  return (
    <Box>
      <Grid item xs={6}>
        <InputLabel
          htmlFor={TagPromotionField.PROMOTION_END_DATE}
          className={classes.inputLabel}
        >
          Promotion End Date
        </InputLabel>
        <DateTimePicker
          value={moment(tagPromotion[TagPromotionField.PROMOTION_END_DATE])}
          onChange={(date): void => {
            setFieldValue(
              TagPromotionField.PROMOTION_END_DATE,
              moment(date?.utc().toISOString()).toISOString(),
            )
          }}
          format={'YYYY-MM-DD HH:mm'}
        />
      </Grid>
      <Grid item xs={12} className={classes.tagsContainer}>
        <InputLabel className={classes.inputLabel}>
          Tags <span className={classes.inputLabelHint}>(3 max)</span>
        </InputLabel>
        <AutocompleteSearchMultiselect
          variant="filled"
          key={`autocomplete-promotion}`}
          initialSelectedItems={props.initialSelectedTags}
          availableItems={[]}
          onSelectedItemsChange={handleSelectedTagsChange}
          searchAsyncActionCreator={Actions.getAutocompleteSearchTags}
          selectorMethod={Selectors.getAutocompleteSearchTags}
          options={{
            selectedItemsChipProps: (item: Tag): OverrideChipProps => {
              const props: OverrideChipProps = {
                label: item.name!,
              }
              if (!IsNullOrUndefined(item.media?.icon)) {
                props.icon = <i>{item.media!.icon!}</i>
              }
              return props
            },
            autocompleteSearchResultContent: (item: Tag): string =>
              `${item.media?.icon ?? ''} ${item.name} - Level: ${item.level}`,
          }}
        />
      </Grid>
    </Box>
  )
}
