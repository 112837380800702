/* Types */
import { EndpointRoute } from 'types/common/api/models/entities/endpoint-route'
import { RequestMethod } from 'types/common/api/enums/request-method'

/* Module */
import filters from './filters'
import params from './params'
import paths from './paths'

const PrizeEndpoints: { [key: string]: EndpointRoute } = {
  getPrizeStatuses: {
    method: RequestMethod.GET,
    path: [paths.prizes, paths.statuses],
    filters,
  },
  getPrizes: {
    method: RequestMethod.GET,
    path: [paths.prizes],
    filters,
  },
  getPrizeById: {
    method: RequestMethod.GET,
    path: [paths.prizes, params.prizeId],
  },
  createPrize: {
    method: RequestMethod.POST,
    path: [paths.prizes],
  },
  duplicatePrize: {
    method: RequestMethod.POST,
    path: [paths.prizes, params.prizeId, paths.duplicate],
  },
  updatePrize: {
    method: RequestMethod.PUT,
    path: [paths.prizes, params.prizeId],
  },
  updatePrizeStatus: {
    method: RequestMethod.PUT,
    path: [
      paths.prizes,
      params.prizeId,
      paths.updateStatus,
      params.prizeStatus,
    ],
  },
  promotePrize: {
    method: RequestMethod.PUT,
    path: [paths.prizes, params.prizeId, paths.promote],
  },
  deletePrize: {
    method: RequestMethod.DELETE,
    path: [paths.prizes, params.prizeId],
  },
  clearTrash: {
    method: RequestMethod.DELETE,
    path: [paths.prizes, paths.clearTrash],
  },
  uploadPrizeImage: {
    method: RequestMethod.PUT,
    path: [paths.items, params.itemId, paths.images, paths.upload, paths.image],
  },
  uploadFeaturedImage: {
    method: RequestMethod.PUT,
    path: [
      paths.items,
      params.itemId,
      paths.images,
      paths.upload,
      paths.featured,
    ],
  },
  uploadLogo: {
    method: RequestMethod.PUT,
    path: [paths.items, params.itemId, paths.images, paths.upload, paths.logo],
  },
}

export default PrizeEndpoints
