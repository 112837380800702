import React, { PropsWithChildren, ReactElement } from 'react'
import { Chip } from '@mui/material'

import {
  Item,
  RequiredItemProps,
} from 'components/controls/inputs/downshift/item'
import { ISelectedChipDisplayProps } from 'components/controls/inputs/downshift/multiselect/selected-chip-display-props'
import { useStyles } from 'components/controls/inputs/downshift/styles'
import { OverrideChipProps } from 'components/controls/inputs/downshift/multiselect/multiselect-props'
import { get } from 'lodash-es'

const SelectedChipDisplay = <TItem,>(
  props: PropsWithChildren<ISelectedChipDisplayProps<Item<TItem>>>,
): ReactElement<any, any> | null => {
  const classes = useStyles()

  const chipProps = (item: Item<TItem>): OverrideChipProps =>
    props.selectedItemsChipProps
      ? props.selectedItemsChipProps(item)
      : ({
          label: get((item as unknown as RequiredItemProps), props.fieldName || 'name'),
        } as OverrideChipProps)

  return (
    <div className={classes.chipWrapper}>
      {(props.selectedItems as Item<TItem>[]).map(
        (selectedItem: Item<TItem>, index: number) => (
          <Chip
            key={`chipIndex-${index}-itemId-${
              (selectedItem as unknown as RequiredItemProps)?.id
            }`}
            {...props.getSelectedItemProps({
              selectedItem,
              index,
            })}
            {...chipProps(selectedItem)}
            onDelete={(): void => props.removeSelectedItem(selectedItem)}
            className={classes.chip}
          />
        ),
      )}
    </div>
  )
}

export default SelectedChipDisplay
