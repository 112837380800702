import { RequestMethod } from 'types/common/api/enums/request-method'
import params from 'core/api/endpoints/mobile-app-users/params'
import paths from './paths'
import filters from './filters'

export const MobileAppUsersEndpoints = {
  getMobileAppUser: {
    method: RequestMethod.GET,
    path: [paths.mobileAppUsers, params.mobileAppUserId],
  },
  getMobileAppUsers: {
    method: RequestMethod.GET,
    path: [paths.mobileAppUsers],
    filters,
  },
  updateMobileAppUsers: {
    method: RequestMethod.PUT,
    path: [paths.mobileAppUsers, params.mobileAppUserId],
  },
  switchMobileAppUsers: {
    method: RequestMethod.PUT,
    path: [paths.portalUsers, paths.switchAccount],
  },
  updateMobileAppUserCurrency: {
    method: RequestMethod.PUT,
    path: [
      paths.mobileAppUsers,
      params.mobileAppUserId,
      paths.accounts,
      params.accountId,
      paths.topUp,
    ],
  },
  disableMobileAppUser: {
    method: RequestMethod.DELETE,
    path: [paths.mobileAppUsers, params.mobileAppUserId],
  },
  getTickets: {
    method: RequestMethod.GET,
    path: [paths.mobileAppUsers, params.mobileAppUserId, paths.tickets],
  },
  voidTickets: {
    method: RequestMethod.PUT,
    path: [paths.tickets, params.ticketId, paths.void],
  },
}
