/* eslint-disable no-invalid-this */
import * as yup from 'yup'
import { PrizeField } from 'types/modules/prizes/enums/prize-field'
import {
  AHEAD_OF_END_DATE_MESSAGE,
  BEFORE_START_DATE_MESSAGE,
  testDateRange,
} from 'utils/validation-helper'
import { PrizeType } from 'fe-shared-resources'

export const PrizeSchema = yup.object({
  [PrizeField.ITEM]: yup.object().shape({
    [PrizeField.NAME]: yup.string().required('name is required'),
    [PrizeField.QUANTITY]: yup
      .number()
      .required('quantity is required')
      .moreThan(0, 'must be more than 0'),
  }),
  [PrizeField.START]: yup
    .string()
    .required('start date is required')
    .test({
      message: AHEAD_OF_END_DATE_MESSAGE,
      test: function (value) {
        return testDateRange(value, this.parent[PrizeField.END])
      },
    }),
  [PrizeField.END]: yup
    .string()
    .nullable()
    .test({
      message: BEFORE_START_DATE_MESSAGE,
      test: function (value) {
        return testDateRange(this.parent[PrizeField.START], value)
      },
    })
    .test({
      message: 'end date is required',
      test: function (value) {
        if (this.parent.type === PrizeType.Lottery && !value) return false
        return true
      },
    }),
})
