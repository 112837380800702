import PostStatus from 'types/modules/posts/enums/post-status'
import PostType from 'types/modules/posts/enums/post-type'
import { PostDifficulty } from 'types/modules/posts/enums/post-difficulty'
import { PostField as FieldName } from 'types/modules/posts/enums/post-field'
import { Tag } from 'types/modules/tags/models/entities/tag'
import { PostAcceptedCurrency } from 'fe-shared-resources'

export enum PostPricingModel {
  NotSet = 'NotSet',
  Free = 'Free',
  Premium = 'PredictPlus',
}

export enum PostSettlePeriod {
  NotSet = 'NotSet',
  MANUAL = 'Manual',
  DAILY = 'Daily'
}

export interface IPostDetails {
  [FieldName.BET_CARD]: boolean
  [FieldName.CATEGORY]?: string
  [FieldName.CLOSE]?: Date | string
  [FieldName.COINS_WIN]: number
  [FieldName.CREATED_BY]?: string
  [FieldName.DIFFICULTY]: PostDifficulty
  [FieldName.DISPLAY_IF_LOCKED]: boolean
  [FieldName.EVENT_ID]?: string
  [FieldName.FEATURED]: boolean
  [FieldName.ID]: string
  [FieldName.MAX_BET]?: number
  [FieldName.MIN_BET]: number
  [FieldName.MULTIPLIER_MID]?: number
  [FieldName.MULTIPLIER_MAX]?: number
  [FieldName.MEDIA]?: {
    [FieldName.DESCRIPTION]?: string
    [FieldName.IMAGE_URL]?: string | null
    [FieldName.NEWS_LINK]?: string
    [FieldName.MEDIA_ITEM_ID]?: string
    [FieldName.VIDEO_URL]?: string | null
    [FieldName.IS_VIDEO]?: boolean
  }
  [FieldName.PAYOUT_CAP]: number
  [FieldName.POST_PRIZES]: {
    [FieldName.PRIZE_ID]: string
    [FieldName.QUANTITY]: number
  }[]
  [FieldName.PUBLIC]: boolean
  [FieldName.LIVE_SCHEDULE_ID]?: string
  [FieldName.SKIP_MAIN_FEED]: boolean
  [FieldName.STARS_WIN]: number
  [FieldName.START]: Date | string
  [FieldName.STATUS]: PostStatus
  [FieldName.SUB_CATEGORY_ID]?: string
  [FieldName.TAGS]: Tag[] | string[]
  [FieldName.TAG_IDS]?: string[]
  [FieldName.TIMED]: boolean
  [FieldName.TITLE]: string
  [FieldName.TYPE]: PostType
  [FieldName.WATCHLIST]: boolean
  [FieldName.UPDATED_BY]?: string
  [FieldName.CHECK_BY_DATE]?: string
  [FieldName.UPDATED_AT]?: string
  postPricingModel: PostPricingModel
  pricingModelInAppPurchaseCode?: string
  isDailyStack: boolean
  countries: string[]
  shouldBeSettledBy?: string
  groups: string[]
  settlePeriod?: PostSettlePeriod
  acceptedCurrency: PostAcceptedCurrency
}
