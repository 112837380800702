import { MobileAppUsersEndpoints } from 'core/api/endpoints/mobile-app-users'
import { createAction, createAsyncAction } from 'core/redux/utils'
import { ActionTypes } from 'modules/mobile-app-users/action-types'
import {
  EditableMobileAppUser,
  MobileAppUser,
} from 'modules/mobile-app-users/models/entities/mobile-app-user'
import { IAsyncActionParams } from 'types/common/api/models/entities/async-action-params'
import { IAsyncAction, ISimpleAction } from 'types/redux/interfaces/IAction'
import { Currency } from 'fe-shared-resources'

export interface IAccountCurrencyUpdateRequest {
  amount?: number
  currency?: Currency
}
export interface IMobileAppUserActionParams
  extends IAsyncActionParams<
    EditableMobileAppUser | IAccountCurrencyUpdateRequest
  > {
  mobileAppUserId?: string
  accountId?: string
  ticketId?: string
  amount?: number
  username?: string
}

export const setCurrentMobileAppUserId: (
  params: IMobileAppUserActionParams,
) => ISimpleAction = (params) =>
  createAction(ActionTypes.SET_CURRENT_MOBILE_APP_USER_ID, params)

export const getMobileAppUser: (
  params: IMobileAppUserActionParams,
) => IAsyncAction = (params) =>
  createAsyncAction({
    type: ActionTypes.GET_MOBILE_APP_USER,
    endpoint: MobileAppUsersEndpoints.getMobileAppUser,
payload: params,
  })

export const getMobileAppUsers: (
  params: IMobileAppUserActionParams,
  finalCallback: () => void,
) => Promise<IAsyncAction> = async (params, finalCallback) => {
  return createAsyncAction({
    type: ActionTypes.GET_MOBILE_APP_USERS,
    endpoint: MobileAppUsersEndpoints.getMobileAppUsers,
    payload: params,
    alertsEnabled: false,
    finalCallback,
})
}

export const updateMobileAppUsers: (
  params: IMobileAppUserActionParams,
) => IAsyncAction = (params) =>
  createAsyncAction({
    type: ActionTypes.UPDATE_MOBILE_APP_USER,
    endpoint: MobileAppUsersEndpoints.updateMobileAppUsers,
    payload: params,
  })

export const disableMobileAppUser: (
  params: IMobileAppUserActionParams,
) => IAsyncAction = (params) =>
  createAsyncAction({
    type: ActionTypes.DISABLE_MOBILE_APP_USER,
    endpoint: MobileAppUsersEndpoints.disableMobileAppUser,
    payload: params,
  })

export const updateMobileAppUserCurrency: (
  params: IMobileAppUserActionParams,
) => IAsyncAction = (params) =>
  createAsyncAction({
    type: ActionTypes.UPDATE_MOBILE_APP_USER_CURRENCY,
    endpoint: MobileAppUsersEndpoints.updateMobileAppUserCurrency,
    payload: params,
  })

export const setMobileAppUsersAsEmpty: () => ISimpleAction = () =>
  createAction(ActionTypes.SET_MOBILE_APP_USERS_AS_EMPTY)

export const getAutocompleteSearchMobileAppUsers: <
  TActionParamsType = IMobileAppUserActionParams,
>(
  params: TActionParamsType,
) => IAsyncAction = (params) =>
  createAsyncAction({
    type: ActionTypes.GET_AUTOCOMPLETE_SEARCH_MOBILE_APP_USERS,
    endpoint: MobileAppUsersEndpoints.getMobileAppUsers,
payload: params,
  })

export const getTickets: (
  params: IMobileAppUserActionParams,
  finalCallback: () => void,
) => Promise<IAsyncAction> = async (params, finalCallback) => {
  return createAsyncAction({
    type: ActionTypes.GET_TICKETS,
    endpoint: MobileAppUsersEndpoints.getTickets,
    payload: params,
    alertsEnabled: false,
    finalCallback,
  })
}

export const makeTicketVoid: (
  params: IMobileAppUserActionParams,
) => IAsyncAction = (params) =>
  createAsyncAction({
    type: ActionTypes.VOID_TICKET,
    endpoint: MobileAppUsersEndpoints.voidTickets,
    payload: params,
  })

export const switchUsers: (params: IMobileAppUserActionParams) => IAsyncAction = (params) =>
  createAsyncAction({
    type: ActionTypes.SWITCH_USERS,
    endpoint: MobileAppUsersEndpoints.switchMobileAppUsers,
    payload: params,
  })
