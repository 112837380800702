import { Box, Button, Divider, Grid, InputLabel } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { SimpleImageDisplay } from 'components/simple-image-display'
import { useTypedSelector } from 'core/redux/utils'
import { Actions, Selectors } from 'modules/mobile-app-users'
import { TicketStatus } from 'modules/mobile-app-users/enums/ticket-status'
import { DetailItem } from 'modules/mobile-app-users/views/mobile-user-modal/common/detail-item'
import { QuestionsAndAnswers } from 'modules/mobile-app-users/views/mobile-user-modal/tickets/questions-and-answers'
import React from 'react'
import { useDispatch } from 'react-redux'
import { renderDate } from 'utils/date-range'

interface ITicketDetailsProps {
  ticketId?: string
}

const useStyles = makeStyles(() =>
  createStyles({
    imageWrapper: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      marginBottom: 24,
      position: 'relative',
    },
    detailsWrapper: {
      width: '100%',
      marginTop: 16,
    },
    label: {
      fontSize: 12,
      marginBottom: 24,
      marginTop: 8,
    },
    voidButton: {
      position: 'absolute',
      right: 0,
      bottom: 0,
    },
  }),
)

export const TicketDetails: React.FC<ITicketDetailsProps> = ({
  ticketId,
}): JSX.Element => {
  const ticket = useTypedSelector((state) =>
    Selectors.getTicket(state, ticketId),
  )
  const classes = useStyles()
  const dispatch = useDispatch()
  const makeTicketVoid = (): void => {
    dispatch(Actions.makeTicketVoid({ ticketId }))
  }

  if (!ticket) return <Box>Select a ticket</Box>

  return (
    <Box>
      <Box className={classes.imageWrapper}>
        <SimpleImageDisplay
          imageUrl={ticket?.post.media?.imageUrl}
          width={200}
          height={200}
        />
        <Button
          color="secondary"
          onClick={makeTicketVoid}
          variant="contained"
          className={classes.voidButton}
          disabled={[TicketStatus.SETTLED, TicketStatus.VOID].includes(
            ticket.status as TicketStatus,
          )}
        >
          Void
        </Button>
      </Box>
      <Divider />
      <Box className={classes.detailsWrapper}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            {[
              { label: 'Post title', value: ticket?.post.title },
              { label: 'Post status', value: ticket?.post.status },
              { label: 'Ticket status', value: ticket?.status },
              { label: 'Stake', value: ticket?.stake },
              { label: 'Coins won', value: ticket?.coinsWon },
              { label: 'Stars won', value: ticket?.starsWon },
              {
                label: 'Ticket creation date',
                value: renderDate(ticket?.createdAt),
              },
              {
                label: 'Ticket updated date',
                value: renderDate(ticket?.updatedAt),
              },
              { label: 'Post ID', value: ticket?.postId },
              { label: 'Ticket ID', value: ticket?.id },
            ].map(({ label, value }) => (
              <DetailItem key={label} label={label} value={value} />
            ))}
          </Grid>
          <Grid item xs={6}>
            <InputLabel className={classes.label}>
              Questions and answers
            </InputLabel>
            <QuestionsAndAnswers ticketId={ticketId} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
