import { Box, Button, ButtonProps, Divider, IconButton, SvgIcon, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useTagsContext } from 'modules/tags/context'
import React from 'react'
import { Tag, TagDraft } from 'types/modules/tags/models/entities/tag'
import { NIL as NIL_UUID } from 'uuid'
import { TagField as FieldName } from 'types/modules/tags/enums/tag-field'
import { useFormik } from 'formik'
import { CrossIcon, UndoIcon, SaveIcon } from 'components/icons'
import { PanelContainer, Panel } from 'components/panel'
import { TagForm } from 'modules/tags/views/manage-tag-panel/tag-form'
import { DisplayMode } from 'types/common/enums/display-mode'
import { ConfirmationDialog } from 'components/confirmation-dialog'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    panelHeader: {
      display: 'flex',
      width: '100%',
      flexShrink: 0,
      height: 72,
    },
    panelTitle: {
      display: 'flex',
      alignItems: 'center',
      padding: '0 0 0 1.25rem',
    },
    rightHeaderSection: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      padding: '0 1.25rem 0 0',
      flexDirection: 'row-reverse',
      '& .MuiButton-contained': {
        marginRight: 20,
      },
    },
    contentWrapper: {
      display: 'flex',
      flexGrow: 1,
      overflow: 'hidden',
    },
    contentPanel: {
      position: 'relative',
      display: 'flex',
      flex: 1,
      padding: '1.25rem',
      overflow: 'auto',
      borderRight: '1px solid rgba(0,0,0,0.12)',
      '&:last-child': {
        border: 'none',
      },
    },
    footer: {
      display: 'flex',
      width: '100%',
      justifySelf: 'flex-end',
    },
    footerSection: {
      display: 'flex',
      padding: '1.25rem',
      flex: 1,
      justifyContent: 'space-between',
      borderRight: '1px solid rgba(0,0,0,0.12)',
      '&:last-child': {
        border: 'none',
      },
    },
    updateButton: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    resetButton: {
      color: theme.palette.text.primary,
      backgroundColor: '#ecf0f1',
      '&:hover': {
        backgroundColor: '#f2f5f5',
      },
    },
    deleteAndUndoButton: {
      display: 'flex'
    },
    deleteButton: {
      marginRight: 16
    }
  }),
)

interface IManageTagPanelProps {
  isModal?: boolean
  closeModal?: () => void
}

export const ManageTagPanel: React.FC<IManageTagPanelProps> = (
  props: IManageTagPanelProps,
) => {
  const classes = useStyles()

  const {
    currentTag: tag,
    setCurrentTag,
    clearCurrentTag,
    updateTag,
    createTag,
    deleteTag,
    processing
  } = useTagsContext()

  const displayMode =
    tag?.id !== NIL_UUID ? DisplayMode.Edit : DisplayMode.Create ?? 'Loading'

  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      [FieldName.ID]: tag?.[FieldName.ID] ?? NIL_UUID,
      [FieldName.NAME]: tag?.[FieldName.NAME] ?? '',
      [FieldName.CODE]: tag?.[FieldName.CODE] ?? '',
      [FieldName.LEVEL]: tag?.[FieldName.LEVEL] ?? 3,
      [FieldName.MEDIA]: tag?.[FieldName.MEDIA] ?? {
        [FieldName.ICON]: tag?.[FieldName.MEDIA]?.[FieldName.ICON] ?? '',
      },
    },
    onSubmit: (value: TagDraft) => {
      displayMode === DisplayMode.Create
        ? createTag(value, (tag: Tag) => {
            if (tag?.id) setCurrentTag(tag.id)
          })
        : updateTag(value)
    },
  })

  const buttonProps: ButtonProps = {
    variant: 'contained',
    size: 'small',
    disabled: processing
  }

  return (
    <PanelContainer>
      <Panel container xs={12}>
        <Box className={classes.panelHeader}>
          <Box className={classes.panelTitle}>
            <Typography
              variant="h5"
              color="textPrimary"
              style={{
                paddingRight: 10,
              }}
            >
              {`${displayMode} Tag`}
            </Typography>
          </Box>
          <Box className={classes.rightHeaderSection}>
            {props.isModal && (
              <IconButton
                onClick={(): void => clearCurrentTag()}
                disabled={processing}
                size="large">
                <SvgIcon>
                  <CrossIcon />
                </SvgIcon>
              </IconButton>
            )}
          </Box>
        </Box>
        <Divider />
        <Box className={classes.contentWrapper}>
          <Box className={classes.contentPanel}>
            <TagForm
              {...{
                tag: form.values,
                handleChange: form.handleChange,
                setFieldTouched: form.setFieldTouched,
                setFieldValue: form.setFieldValue,
                displayMode,
              }}
            />
          </Box>
        </Box>
        <Divider />
        <Box className={classes.footer}>
          <Box className={classes.footerSection}>
            <Box className={classes.deleteAndUndoButton}>
              {displayMode === DisplayMode.Edit && <Box className={classes.deleteButton}>
                <ConfirmationDialog
                popoverId={`delete-newsCard-${tag?.id}`}
                onConfirmation={(): void => deleteTag(tag?.id!)}
                confirmationText={`Are you sure you want to delete this tag?`}
                buttonLabel="Delete"
                buttonProps={buttonProps}
              />
            </Box>}
            <Button
              variant="contained"
              startIcon={<UndoIcon />}
              className={classes.resetButton}
              size="small"
              onClick={(): void => {
                form.resetForm()
              }}
              disabled={!form.dirty || processing}
            >
              Undo
            </Button>

            </Box>
            <Button
              variant="contained"
              startIcon={<SaveIcon />}
              className={classes.updateButton}
              size="small"
              onClick={(): void => {
                form.submitForm()
              }}
              disabled={!form.dirty || processing}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Panel>
    </PanelContainer>
  );
}
