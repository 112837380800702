import { Bounce, Slide } from 'react-toastify'
import { Regions } from 'types/common/enums/regions'
import { Timezones } from 'types/common/enums/timezones'

export const REGIONS_NAME = {
  [Regions.CA]: 'Canada',
  [Regions.GB]: 'United Kingdom',
  [Regions.US]: 'United States',
  [Regions.IE]: 'Ireland',
}

export const REGIONS_OPTIONS = [
  { name: REGIONS_NAME.GB, value: Regions.GB },
  { name: REGIONS_NAME.US, value: Regions.US },
  { name: REGIONS_NAME.CA, value: Regions.CA },
  { name: REGIONS_NAME.IE, value: Regions.IE },
]

export const TIMEZONE_NAME = {
  [Timezones.ET]: 'Eastern Time (ET)',
  [Timezones.PST]: 'Pacific Standard Time (PST) ',
}

export const TIMEZONE_OPTIONS = [
  { name: TIMEZONE_NAME.ET, value: Timezones.ET },
  { name: TIMEZONE_NAME.PST, value: Timezones.PST },
]

export const TOAST_CONFIG = {
  position: "bottom-center",
  autoClose: 500,
  hideProgressBar: false,
  newestOnTop: false,
  closeOnClick: true,
  rtl: false,
  pauseOnFocusLoss: true,
  draggable: true,
  pauseOnHover: true,
  theme: 'light',
  transition: Slide
}