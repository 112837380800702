import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { ManagePostModal } from 'modules/posts/views/components/manage-post-panel/modal'
import React from 'react'
import { IPost } from 'types/modules/posts/models/entities/post'
import _ from 'core/utils/deepdash'
import { useRouter } from 'core/routing/hooks/use-router'
import { PostMediaModal } from 'modules/posts/views/components/media-modal'
import { usePostListContext } from 'modules/posts/context/post-list'
import { PostList } from 'modules/posts/views/components/dashboard/post-list'

interface IProps {
  ListComponent?: JSX.Element
}

export const PostsDashboard: React.FC<IProps> = ({ ListComponent }) => {
  const { currentPost } = usePostListContext()
  const { routeParams } = useRouter()
  const { viewMedia } = routeParams

  return (
    <React.Fragment>
      {ListComponent ? ListComponent :  <PostList />}
      {!IsNullOrUndefined(currentPost) && (
        <ManagePostModal currentPost={currentPost as IPost} />
      )}
      {!IsNullOrUndefined(viewMedia) && <PostMediaModal date={viewMedia} />}
    </React.Fragment>
  )
}
