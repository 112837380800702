import { Box, Button } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { ConfirmationDialog } from 'components/confirmation-dialog'
import { useMobileAppUsersContext } from 'modules/mobile-app-users/context'
import React, { useState } from 'react'
import { Currency } from 'fe-shared-resources'

const labels = {
  [Currency.Coin]: 'Coins topup',
  [Currency.Star]: 'Starts topup',
}

const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    innerBox: {
      width: 350,
    },
  }),
)

export const DisableUser: React.FC = () => {
  const classes = useStyles()
  const { disableMobileAppUser, currentMobileAppUser } =
    useMobileAppUsersContext()
  const handleClick = (): void => {
    disableMobileAppUser(currentMobileAppUser?.id)
  }

  return (
    <Box className={classes.wrapper}>
      <ConfirmationDialog
        popoverId="disableUser"
        buttonProps={{ variant: 'contained' }}
        onConfirmation={(): void => handleClick()}
        confirmationText={`Are you sure you want to disable this user`}
        buttonLabel="Disable"
      />
    </Box>
  )
}
