import { InputLabel } from '@mui/material'
import { AutocompleteSearchMultiselect } from 'components/controls/inputs/downshift/autocomplete/autocomplete-search-multiselect'
import { OverrideChipProps } from 'components/controls/inputs/downshift/multiselect/multiselect-props'
import { useSimpleFormStyles } from 'components/simple-form-gen'
import { useTypedSelector } from 'core/redux/utils'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { Actions, Selectors } from 'modules/tags'
import { ITagActionParams } from 'modules/tags/actions'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import SortDirection from 'types/common/enums/sort-direction'
import { FilterOperator } from 'types/common/filtering/enums/FilterOperator'
import { FilterType } from 'types/common/filtering/enums/FilterType'
import { IFilter } from 'types/common/filtering/models/entities/IFilter'
import { Tag } from 'types/modules/tags/models/entities/tag'
import { IAsyncAction } from 'types/redux/interfaces/IAction'

interface ITagsSelectProps {
  handleChange: (selectedTags: Tag[]) => void
  initialSelectedIds: string[]
}

export const TagsSelect = ({ handleChange, initialSelectedIds  }: ITagsSelectProps): JSX.Element | null => {
  const [counter, setCounter] = useState(0)
  const getTag = useTypedSelector((state) => Selectors.getTagGetter(state))
  const dispatch = useDispatch()
  const initiallySelectedTags = initialSelectedIds.map((id) => getTag(id))
  const simpleFormClasses = useSimpleFormStyles()

  useEffect(() => {
    initialSelectedIds?.forEach((id) => {
      if (!getTag(id)) {
        dispatch(Actions.getTagById({
          tagId: id,
          promise: {
            onResolve: (): void => setCounter(counter + 1)
          }
        }))
      }
    })
  }, [])

  const searchTags = (params: ITagActionParams): IAsyncAction => {
    const filters: IFilter[] = [
      {
        field: 'name',
        type: FilterType.Search,
        operator: FilterOperator.ContainsCaseInsensitive,
        value: params.search!.value,
      },
    ]

    return Actions.getAutocompleteSearchTags({
      filters,
      sortyBy: 'name',
      sortDirection: SortDirection.Ascending,
    })
  }

  if (initialSelectedIds.length && initiallySelectedTags.some(tag => !tag?.id)) return null

  return  <>
    <InputLabel className={simpleFormClasses.inputLabel}>
      Tags
    </InputLabel>
    <AutocompleteSearchMultiselect
      variant="filled"
      initialSelectedItems={initiallySelectedTags}
      availableItems={[]}
      onSelectedItemsChange={(selectedTags: Tag[]): void => {
        handleChange(selectedTags)
      }}
      searchAsyncActionCreator={searchTags}
      selectorMethod={Selectors.getAutocompleteSearchTags}
      options={{
        selectedItemsChipProps: (item: Tag): OverrideChipProps => {
          const props: OverrideChipProps = {
            label: item.name!,
          }
          if (!IsNullOrUndefined(item.media?.icon)) {
            props.icon = <i>{item.media!.icon!}</i>
          }
          return props
        },
        autocompleteSearchResultContent: (item: Tag): string =>
          `${item.media?.icon ?? ''} ${item.name} - Level: ${item.level}`,
      }}
    />
  </>
}