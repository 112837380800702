import { EndpointRoute } from 'types/common/api/models/entities/endpoint-route'
import { RequestMethod } from 'types/common/api/enums/request-method'
import paths from './paths'

const AppSettingsEndpoints: { [key: string]: EndpointRoute } = {
  getPortalDefaults: {
    method: RequestMethod.GET,
    path: [paths.appSettings, paths.portalDefaults],
  },
  getPortalWarnings: {
    method: RequestMethod.GET,
    path: [paths.portalWarnings],
  },
}

export default AppSettingsEndpoints
