import React from 'react'
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Box, Button, Grid } from '@mui/material'
import { PanelContent, PanelFooter } from 'components/panel'
import _ from 'core/utils/deepdash'
import { useMobileAppUsersContext } from 'modules/mobile-app-users/context'
import { useFormik } from 'formik'
import { MobileAppUserFieldName } from 'modules/mobile-app-users/enums/mobile-app-user-field'
import { EditableMobileAppUser } from 'modules/mobile-app-users/models/entities/mobile-app-user'
import { UserEditForm } from 'modules/mobile-app-users/views/mobile-user-modal/user-edit-form'
import { DisableUser } from 'modules/mobile-app-users/views/mobile-user-modal/disable-user'
import { CurrencyForm } from 'modules/mobile-app-users/views/mobile-user-modal/currency-form'
import { useAuth } from 'core/authentication/hooks/use-auth'
import { PortalPermissions } from 'core/authorization/rules'
import { colors } from 'utils/colors'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    footerContent: {
      display: 'flex',
      flex: 1,
      justifyContent: 'flex-end',
    },
    cancelBtn: {
      marginRight: '1.25rem',
    },
    wrapper: {
      borderLeft: `1px solid ${colors.rgba_0_0_0_12}`,
    },
  }),
)

export interface IEditModalProps {
  handleClose: () => void
}

export const UserEditModal: React.FC<IEditModalProps> = ({ handleClose }) => {
  const classes = useStyles()

  const { currentUser } = useAuth()
  const { currentMobileAppUser, updateMobileAppUser } =
    useMobileAppUsersContext()

  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      [MobileAppUserFieldName.ID]:
        currentMobileAppUser?.[MobileAppUserFieldName.ID] ?? '',
      [MobileAppUserFieldName.PREFERRED_USERNAME]:
        currentMobileAppUser?.[MobileAppUserFieldName.PREFERRED_USERNAME] ?? '',
      [MobileAppUserFieldName.USERNAME]:
        currentMobileAppUser?.[MobileAppUserFieldName.USERNAME] ?? '',
      [MobileAppUserFieldName.MEDIA]:
        currentMobileAppUser?.[MobileAppUserFieldName.MEDIA],
      [MobileAppUserFieldName.GIVEN_NAME]:
        currentMobileAppUser?.[MobileAppUserFieldName.GIVEN_NAME] ?? '',
      [MobileAppUserFieldName.FAMILY_NAME]:
        currentMobileAppUser?.[MobileAppUserFieldName.FAMILY_NAME] ?? '',
      [MobileAppUserFieldName.EMAIL]:
        currentMobileAppUser?.[MobileAppUserFieldName.EMAIL] ?? '',
      [MobileAppUserFieldName.PHONE_NUMBER]:
        currentMobileAppUser?.[MobileAppUserFieldName.PHONE_NUMBER] ?? '',
      [MobileAppUserFieldName.BIRTHDAY]:
        currentMobileAppUser?.[MobileAppUserFieldName.BIRTHDAY] ?? '',
      [MobileAppUserFieldName.LOCALE]:
        currentMobileAppUser?.[MobileAppUserFieldName.LOCALE] ?? '',
    },
    onSubmit: (value: EditableMobileAppUser) => {
      updateMobileAppUser(value)
    },
  })

  return (
    <>
      <PanelContent>
        <Grid container spacing={2}>
          <Grid item xs={7}>
            <UserEditForm
              mobileAppUser={form.values}
              handleChange={form.handleChange}
              setFieldTouched={form.setFieldTouched}
              setFieldValue={form.setFieldValue}
            />
          </Grid>
          <Grid item xs={5} className={classes.wrapper}>
            <CurrencyForm />
          </Grid>
        </Grid>
      </PanelContent>
      <PanelFooter>
        <Grid container spacing={2}>
          <Grid item xs={7}>
            <Box className={classes.footerContent}>
              <Button
                size="large"
                className={classes.cancelBtn}
                onClick={handleClose}
                variant="contained"
                color="secondary"
              >
                Cancel
              </Button>
              <Button
                color="primary"
                size="large"
                onClick={form.submitForm}
                variant="contained"
              >
                Save
              </Button>
            </Box>
          </Grid>
          <Grid item xs={5}>
            {currentUser.can(PortalPermissions.MANAGE_USERS) && <DisableUser />}
          </Grid>
        </Grid>
      </PanelFooter>
    </>
  )
}
