import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { FilterOperator } from 'types/common/filtering/enums/FilterOperator'
import { FilterType } from 'types/common/filtering/enums/FilterType'
import { IFilter } from 'types/common/filtering/models/entities/IFilter'
import { IKeyValueStringCollection } from 'types/common/interfaces/IKeyValueStringCollection'

const isEmpty = (value: string | number | string[] | number[] | boolean): boolean => {
  if (Array.isArray(value) || typeof value === 'string') {
    return value.length === 0
  }
  return false
}

export const CaptureFilterParams = (
  availableFilters?: IKeyValueStringCollection,
  appliedFilters?: IFilter[],
): string | null => {
  if (
    IsNullOrUndefined(availableFilters) ||
    IsNullOrUndefined(appliedFilters)
  ) {
    return null
  }
  const filters: string[] = []

  appliedFilters?.forEach((appliedFilter) => {
    const { field, type, operator, value } = appliedFilter

    const filterField = Object.keys(availableFilters!).find((key) => {
      return key.toLowerCase() === field.toLowerCase()
    })

    if (IsNullOrUndefined(filterField) || isEmpty(value)) return

    switch (type) {
      case FilterType.DateRange: {
        const [startDate, endDate] = value as string[]

        const startDateFilter = `${filterField}${FilterOperator.GreaterThanOrEqualTo}${startDate}`

        const endDateFilter = `${filterField}${FilterOperator.LessThanOrEqualTo}${endDate}`

        filters.push(`${startDateFilter},${endDateFilter}`)
        break
      }
      default: {
        const valueString = Array.isArray(value)
          ? (value as string[] | number[]).join('|')
          : value.toString()

        filters.push(`${filterField}${operator}${valueString}`)
        break
      }
    }
  })

  return filters.length > 0 ? filters.join(',') : null
}
