import React from 'react'
import { useTypedSelector } from 'core/redux/utils'
import { useQuestGroupsContext } from 'modules/quests/context/quest-group-context'
import { QuestGroupCard, StyledTableCell } from 'modules/quests/view/quest-groups/quest-group-card'
import { QuestGroupModal } from 'modules/quests/view/quest-groups/quest-group-modal'
import { IQuestGroup } from 'modules/quests/models/quest-group'
import { LocalListWrapper } from 'components/local-list-wrapper'
import { QuestGroupActions, Selectors } from '../..'

const sortOptions = [
  { name: 'Created at', value: 'createdAt', descending: true },
  { name: 'Name', value: 'name' },
  { name: 'Variant', value: 'variant' },
  { name: 'Test', value: 'test' },
]

const QuestGroupList: React.FC<{}> = () => {
  const questGroups = useTypedSelector((state) => Selectors.getQuestGroups(state))
  const count = useTypedSelector((state) => Selectors.getQuestGroupsCount(state))
  const { currentQuestGroup, createQuestGroupDraft, setCurrentQuestGroup } = useQuestGroupsContext()

  return (<LocalListWrapper<IQuestGroup, QuestGroupActions.IQuestGroupActionParams, null>
    list={questGroups}
    count={count}
    searchKey="name"
    currentItem={currentQuestGroup}
    createDraft={createQuestGroupDraft}
    setCurrentItem={setCurrentQuestGroup}
    CardComponent={QuestGroupCard}
    ModalComponent={QuestGroupModal}
    getItems={QuestGroupActions.getQuestGroups}
    searchLabel="Name"
    title="Quest groups"
    sortOptions={sortOptions}
  >
    <>
      <StyledTableCell>Name</StyledTableCell>
      <StyledTableCell>Variant</StyledTableCell>
      <StyledTableCell>Test</StyledTableCell>
    </>
  </LocalListWrapper>)
}

export default QuestGroupList
