export enum PrizeVisibility {
  Public = 'Public',
  Private = 'Private',
  NewUser = 'NewUser',
  InactiveUser1 = 'InactiveUser1',
  InactiveUser2 = 'InactiveUser2',
  InactiveUser3 = 'InactiveUser3',
}

export const PrizeVisibilityDisplay = {
  [PrizeVisibility.Public]: 'Public',
  [PrizeVisibility.Private]: 'Private',
  [PrizeVisibility.NewUser]: 'Carrot Prize',
  [PrizeVisibility.InactiveUser1]: 'Inactive User (1 month)',
  [PrizeVisibility.InactiveUser2]: 'Inactive User (2 months)',
  [PrizeVisibility.InactiveUser3]: 'Inactive User (3 months)',
}
