import { all, AllEffect, ForkEffect, takeEvery } from 'redux-saga/effects'
import NewsCardActionTypes from 'modules/news-cards/action-types/news-card-action-types'
import { GetNewsCardsSaga } from './get-news-card-saga'

type RootSagaReturnType = Generator<
  ForkEffect<never> | AllEffect<any>,
  void,
  any
>

export const RootSaga = function* (): RootSagaReturnType {
  yield all([

    yield takeEvery(
      NewsCardActionTypes.GET_NEWS_CARDS,
      GetNewsCardsSaga,
    ),
    yield takeEvery(
      NewsCardActionTypes.GET_NEWS_CARD_BY_ID,
      GetNewsCardsSaga,
    ),
    yield takeEvery(
      NewsCardActionTypes.CREATE_NEWS_CARD,
      GetNewsCardsSaga,
    ),
    yield takeEvery(
      NewsCardActionTypes.UPDATE_NEWS_CARD,
      GetNewsCardsSaga,
    ),
    yield takeEvery(
      NewsCardActionTypes.UPLOAD_NEWS_CARD_IMAGE,
      GetNewsCardsSaga,
    ),
    yield takeEvery(
      NewsCardActionTypes.DELETE_NEWS_CARD,
      GetNewsCardsSaga,
    ),
  ])
}
