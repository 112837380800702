import { ISelectOption } from 'types/common/filtering/models/entities/ISelectOption'
import { InfoCardStatus } from 'types/modules/info-cards/enums/info-card-status'
import { IInfoCardState } from 'types/modules/info-cards/models/state/info-card-state'

export const getStatusFilterOptions = (
  statuses: IInfoCardState['statuses'],
): ISelectOption[] => {
  return [
    {
      label: InfoCardStatus.Active,
      value: [InfoCardStatus.Active],
      metadata: {
        count: statuses[InfoCardStatus.Active],
      },
    },
    {
      label: InfoCardStatus.Inactive,
      value: [InfoCardStatus.Inactive],
      metadata: {
        count: statuses[InfoCardStatus.Inactive],
      },
    },
    {
      label: InfoCardStatus.Discarded,
      value: [InfoCardStatus.Discarded],
      metadata: {
        count: statuses[InfoCardStatus.Discarded],
      },
    },
    {
      label: InfoCardStatus.Closed,
      value: [InfoCardStatus.Closed],
      metadata: {
        count: statuses[InfoCardStatus.Closed],
      },
    },
  ]
}
