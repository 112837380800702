import { MODULE_NAME } from 'modules/mobile-app-users/constants'

export const ActionTypes = {
  GET_MOBILE_APP_USER: `${MODULE_NAME}/GET_MOBILE_APP_USER`,
  UPDATE_MOBILE_APP_USER: `${MODULE_NAME}/UPDATE_MOBILE_APP_USER`,
  DISABLE_MOBILE_APP_USER: `${MODULE_NAME}/DISABLE_MOBILE_APP_USER`,
  UPDATE_MOBILE_APP_USER_CURRENCY: `${MODULE_NAME}/UPDATE_MOBILE_APP_USER_CURRENCY`,
  GET_MOBILE_APP_USERS: `${MODULE_NAME}/GET_MOBILE_APP_USERS`,
  GET_TICKETS: `${MODULE_NAME}/GET_TICKETS`,
  VOID_TICKET: `${MODULE_NAME}/VOID_TICKET`,
  SET_CURRENT_MOBILE_APP_USER_ID: `${MODULE_NAME}/SET_CURRENT_MOBILE_APP_USER_ID`,
  SET_MOBILE_APP_USERS_AS_EMPTY: `${MODULE_NAME}/SET_MOBILE_APP_USERS_AS_EMPTY`,
  GET_AUTOCOMPLETE_SEARCH_MOBILE_APP_USERS: `${MODULE_NAME}/GET_AUTOCOMPLETE_SEARCH_MOBILE_APP_USERS`,
  SWITCH_USERS: `${MODULE_NAME}/SWITCH_USERS`,
}
