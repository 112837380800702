import { Theme, Popover, PopoverProps, Box } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { IPopoverProps } from 'components/popover/use-popover'
import { InfoCardActionButtons } from 'modules/info-cards/views/components/action-buttons'
import React from 'react'
import { IInfoCard } from 'types/modules/info-cards/models/entities/info-card'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contextMenu: {
      display: 'flex',
      flexDirection: 'column',
      padding: '0.625rem',
      '& .MuiButton-contained': {
        marginBottom: '0.625rem',
        '&:last-child': {
          marginBottom: 0,
        },
      },
    },
  }),
)

interface IInfoCardTimeGridContextMenuProps {
  infoCard?: IInfoCard
  dateTime: string
  dateField: string
  popoverProps: IPopoverProps
}

export const InfoCardTimeGridContextMenu: React.FC<
  IInfoCardTimeGridContextMenuProps
> = (props: IInfoCardTimeGridContextMenuProps) => {
  const { infoCard, dateTime, dateField, popoverProps } = props

  const classes = useStyles()

  return (
    <Popover {...popoverProps}>
      <Box className={classes.contextMenu}>
        {infoCard && (
          <InfoCardActionButtons
            infoCard={infoCard}
            onPopoverClose={(): void => {
              popoverProps.onClose()
            }}
          />
        )}
      </Box>
    </Popover>
  )
}
