import { Fade, Modal, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Panel } from 'components/panel'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { InfoCardDetailsPanel } from 'modules/info-cards/views/components/info-card-details-panel'
import React from 'react'
import { IInfoCard } from 'types/modules/info-cards/models/entities/info-card'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 40,
    },
  }),
)

interface IInfoCardDetailsModal {
  currentInfoCard: IInfoCard | null
  open: boolean
  closeModal?: () => void
}

export const InfoCardDetailsModal: React.FC<IInfoCardDetailsModal> = (
  props: IInfoCardDetailsModal,
) => {
  const classes = useStyles()

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.open}
      >
        <Fade in={!IsNullOrUndefined(props.currentInfoCard)}>
          <Panel
            xs={12}
            style={{
              display: 'flex',
              maxWidth: 600,
            }}
          >
            <InfoCardDetailsPanel isModal closeModal={props.closeModal} />
          </Panel>
        </Fade>
      </Modal>
    </div>
  )
}
