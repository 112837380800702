import { Box } from '@mui/material'
import React from 'react'

interface Props {
  label: string,
  value: string
}

export function LabelValue ({
  label,
  value
}: Props) {
  return <Box>
    <Box>{label}</Box>
    <Box>{value}</Box>
  </Box>
}