import { EventStatus } from 'types/modules/events/enums/event-status'

export const getStatusLabel = (status: EventStatus | string): string => {
  switch (status) {
    case EventStatus.Created:
      return 'Draft'

    default:
      return status
  }
}
