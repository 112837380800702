import {
  Box,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SvgIcon,
  Switch,
  TextField,
  Theme,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx'
import { CoinIcon, EditIcon, ImageIcon, StarIcon } from 'components/icons'
import { ImageUploader } from 'components/image-uploader'
import { MediaUploadModel } from 'types/common/images/models/entities/image-upload-model'
import {
  IsNullOrUndefined,
  IsNullUndefinedOrEmpty,
} from 'core/utils/isNullOrUndefined'
import { getIn } from 'formik'
import moment from 'moment-timezone'
import React from 'react'
import { PrizeField } from 'types/modules/prizes/enums/prize-field'
import _ from 'core/utils/deepdash'
import { ItemType, PrizeType } from 'fe-shared-resources'
import {
  PrizeVisibility,
  PrizeVisibilityDisplay,
} from 'types/modules/prizes/enums/prize-visibility'
import { IPrizeFormProps } from 'types/modules/prizes/models/props/prize-form-props'
import { AspectRatio } from 'types/common/images/enum/aspect-ratio'
import { MediaOrientation } from 'types/common/images/enum/media-orientation'
import { MediaItem } from 'types/modules/media-items/models/entities/media-item'
import { createErrors } from 'utils/validation-helper'
import { getAdjustedEndDate } from 'utils/date-range'
import { Duration } from 'components/duration'
import { RegionsSelect } from 'components/regions-select'
import { MediaItemInput } from 'modules/media-items/media-item-input'
import { usePrizeManagerContext } from 'modules/prizes/context/prize-manager'
import { DisplayMode } from 'types/common/enums/display-mode'
import { SaveBeforeWarning } from 'components/save-before-warning'
import { ColorPicker } from 'components/color-picker/color-picker'
import { NIL as NIL_UUID } from 'uuid'
import { AutocompleteSearchMultiselect } from 'components/controls/inputs/downshift/autocomplete/autocomplete-search-multiselect'
import { Tag } from 'types/modules/tags/models/entities/tag'
import { OverrideChipProps } from 'components/controls/inputs/downshift/multiselect/multiselect-props'
import { useTagSelect } from 'hooks/use-tag-select'
import { TagsSelect } from 'components/tags-select'
import { DateTimePicker } from '@mui/x-date-pickers';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    eventDetailsForm: {
      width: '100%',
      height: 'auto',
      '& .MuiGrid-item': {
        display: 'flex',
        flexDirection: 'column',
      },
    },
    inputLabel: {
      display: 'flex',
      marginBottom: '0.625rem',
    },
    imageContainer: {
      display: 'flex',
      flex: 1,
      width: '100%',
      height: '100%',
      background: 'rgba(0,0,0,0.03)',
      color: 'rgba(0,0,0,0.54)',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      justifyContent: 'center',
      alignItems: 'center',
    },
    logoImageUploader: {
      backgroundSize: 'contain',
    },
    adornmentIcon: {
      display: 'flex',
      width: 24,
      height: 24,
      marginRight: 12,
    },
    switches: {
      display: 'flex',
      flex: 1,
      justifyContent: 'flex-start',
    },
    switchLabel: {
      display: 'flex',
      marginBottom: '0.625rem',
    },
    chestDescription: {
      display: 'flex',
      justifyContent: 'space-between',
      fontSize: '1rem',
      background: 'rgba(0,0,0,0.03)',
      padding: '10px 12px',
      height: 40,
      borderRadius: 4,
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    colorContainer: {
      marginTop: 16
    }
  }),
)

export const PrizeDetailsForm: React.FC<IPrizeFormProps> = (
  props: IPrizeFormProps,
) => {
  const classes = useStyles()

  const { uploadPrizeImage, uploadFeaturedImage, uploadLogo, updatePrize } =
    usePrizeManagerContext()

  const {
    prize,
    handleChange,
    setFieldValue,
    errors,
    setValues,
    chestDescription,
    goToChestTab,
    displayMode,
  } = props
  const formErrors = createErrors(errors)
  const nameError = errors?.[PrizeField.ITEM]?.[PrizeField.NAME]
  const quantityError = errors?.[PrizeField.ITEM]?.[PrizeField.QUANTITY]

  const hasCoverImage = !IsNullUndefinedOrEmpty(prize.item?.details?.imageUrl)
  const prizeCoverContainerStyles: { [key: string]: string | number } = {}

  if (hasCoverImage) {
    prizeCoverContainerStyles.backgroundImage = `url(${prize.item?.details!
      .imageUrl})`
  } else {
    prizeCoverContainerStyles.background = 'rgba(0,0,0,0.03)'
  }

  const hasFeaturedImage = !IsNullUndefinedOrEmpty(
    prize.item?.details?.featuredImageUrl,
  )
  const featuredImageContainerStyles: { [key: string]: string | number } = {}

  if (hasFeaturedImage) {
    featuredImageContainerStyles.backgroundImage = `url(${prize.item?.details!
      .featuredImageUrl})`
  } else {
    featuredImageContainerStyles.background = 'rgba(0,0,0,0.03)'
  }

  const hasLogoImage = !IsNullUndefinedOrEmpty(
    prize.item?.details?.logoImageUrl,
  )
  const logoImageContainerStyles: { [key: string]: string | number } = {}

  if (hasLogoImage) {
    logoImageContainerStyles.backgroundImage = `url(${prize.item?.details!
      .logoImageUrl})`
  } else {
    logoImageContainerStyles.background = 'rgba(0,0,0,0.03)'
  }

  const isLottery = prize.type === PrizeType.Lottery

  return (
    <React.Fragment>
      <Box className={classes.eventDetailsForm}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {displayMode === DisplayMode.Create && (
                <Grid item xs={12}>
                  <SaveBeforeWarning label="You can upload images and create chest after saving details" />
                </Grid>
              )}
              <Grid item xs={4} style={{ minHeight: 324 }}>
                <InputLabel className={classes.inputLabel}>
                  Cover Image
                </InputLabel>
                <ImageUploader
                  handleUpload={(fileParams: MediaUploadModel): void => {
                    uploadPrizeImage(prize.itemId!, fileParams)
                  }}
                  disabled={displayMode === DisplayMode.Create}
                >
                  <Box
                    className={classes.imageContainer}
                    style={prizeCoverContainerStyles}
                  >
                    {!hasCoverImage && (
                      <SvgIcon>
                        <ImageIcon />
                      </SvgIcon>
                    )}
                  </Box>
                </ImageUploader>
              </Grid>
              <Grid item xs={4} style={{ minHeight: 324 }}>
                <InputLabel className={classes.inputLabel}>
                  Featured Image
                </InputLabel>
                <MediaItemInput
                  disabled={displayMode === DisplayMode.Create}
                  url={prize.item?.details?.featuredImageUrl}
                  imageLabel={'Featured Image'}
                  mediaItemId={prize.item?.details?.featuredMediaItemId}
                  options={{
                    aspectRatio: AspectRatio.Wide,
                    orientation: MediaOrientation.Landscape,
                  }}
                  onComplete={(fileParams: MediaUploadModel): void => {
                    uploadFeaturedImage(prize.itemId!, fileParams)
                  }}
                  onCreate={(mediaItem: MediaItem): void => {
                    const newPrize = _.cloneDeep(prize)
                    newPrize.item!.details = {
                      ...(newPrize.item?.details ?? {}),
                      featuredMediaItemId: mediaItem.id,
                    }
                    updatePrize(newPrize)
                  }}
                />
              </Grid>
              <Grid item xs={4} style={{ minHeight: 324 }}>
                <InputLabel className={classes.inputLabel}>Logo</InputLabel>
                <ImageUploader
                  handleUpload={(fileParams: MediaUploadModel): void => {
                    uploadLogo(prize.itemId!, fileParams)
                  }}
                  disabled={displayMode === DisplayMode.Create}
                >
                  <Box
                    className={clsx(
                      classes.imageContainer,
                      classes.logoImageUploader,
                    )}
                    style={logoImageContainerStyles}
                  >
                    {!hasLogoImage && (
                      <SvgIcon>
                        <ImageIcon />
                      </SvgIcon>
                    )}
                  </Box>
                </ImageUploader>
              </Grid>
              <Grid item xs={4}>
                {!isLottery && (
                  <>
                    <InputLabel className={classes.inputLabel}>
                      Attributes
                    </InputLabel>
                    <FormGroup className={classes.switches}>
                      <FormControlLabel
                        className={classes.switchLabel}
                        control={
                          <Switch
                            color="primary"
                            id={PrizeField.FEATURED}
                            name={PrizeField.FEATURED}
                            checked={prize[PrizeField.FEATURED]}
                            onChange={(event, checked): void => {
                              setFieldValue(PrizeField.FEATURED, checked)
                            }}
                          />
                        }
                        label={<InputLabel>Featured</InputLabel>}
                      />
                      <FormControlLabel
                        className={classes.switchLabel}
                        control={
                          <Switch
                            color="primary"
                            id={PrizeField.ALLOW_FREE_ENTRY}
                            name={PrizeField.ALLOW_FREE_ENTRY}
                            checked={prize[PrizeField.ALLOW_FREE_ENTRY]}
                            onChange={(event, checked): void => {
                              setFieldValue(
                                PrizeField.ALLOW_FREE_ENTRY,
                                checked,
                              )
                            }}
                          />
                        }
                        label={<InputLabel>Allow Free Entry</InputLabel>}
                      />
                      <Box>
                        <InputLabel className={classes.inputLabel}>
                          Regions
                        </InputLabel>
                        <RegionsSelect
                          selectedRegions={prize.countries || []}
                          onSelect={(val: any[]): void => {
                            setFieldValue(PrizeField.COUNTRIES, val)
                          }}
                        />
                      </Box>
                      <Box className={classes.colorContainer}>
                        <InputLabel
                          htmlFor="metaData.theme.backgroundColor"
                          className={classes.inputLabel}
                        >
                          Background color
                        </InputLabel>
                        <ColorPicker
                          name="backgroundColor"
                          value={prize.metaData?.theme.backgroundColor}
                          onChange={(color): void => {
                            setFieldValue('metaData.theme.backgroundColor', color)
                          }}
                        />
                      </Box>
                    </FormGroup>
                  </>
                )}
              </Grid>
              <Grid item xs={8}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <InputLabel
                      htmlFor={`${PrizeField.ITEM}[${PrizeField.DETAILS}][${PrizeField.BRAND}]`}
                      className={classes.inputLabel}
                    >
                      Brand
                    </InputLabel>
                    <TextField
                      id={`${PrizeField.ITEM}[${PrizeField.DETAILS}][${PrizeField.BRAND}]`}
                      name={`${PrizeField.ITEM}[${PrizeField.DETAILS}][${PrizeField.BRAND}]`}
                      onChange={handleChange as React.ChangeEventHandler<HTMLInputElement>}
                      value={getIn(
                        prize,
                        `${PrizeField.ITEM}[${PrizeField.DETAILS}][${PrizeField.BRAND}]`,
                      )}
                      variant="filled"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InputLabel className={classes.inputLabel}>
                      Item Type
                    </InputLabel>
                    <Select
                      id="item.type"
                      name="item.type"
                      value={prize.item?.type}
                      onChange={(event): void => {
                        setFieldValue('item.type', event.target.value as string)
                      }}
                      variant="filled"
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'left',
                        },
                        // getContentAnchorEl: null,
                      }}
                      fullWidth
                    >
                      {Object.values(ItemType).map((itemType) => (
                        <MenuItem key={itemType} value={itemType}>
                          {itemType}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel
                      htmlFor={`${PrizeField.ITEM}[${PrizeField.NAME}]`}
                      className={classes.inputLabel}
                    >
                      Name
                    </InputLabel>
                    <TextField
                      id={`${PrizeField.ITEM}[${PrizeField.NAME}]`}
                      name={`${PrizeField.ITEM}[${PrizeField.NAME}]`}
                      onChange={handleChange as React.ChangeEventHandler<HTMLInputElement>}
                      value={getIn(
                        prize,
                        `${PrizeField.ITEM}[${PrizeField.NAME}]`,
                      )}
                      variant="filled"
                      fullWidth
                      error={!!nameError}
                      helperText={nameError}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel
                      htmlFor={`${PrizeField.CHEST_ID}`}
                      className={classes.inputLabel}
                    >
                      Chest
                    </InputLabel>
                    <Box
                      className={classes.chestDescription}
                      onClick={goToChestTab}
                    >
                      <span>{chestDescription}</span>
                      <EditIcon />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <TagsSelect
                      initialSelectedIds={prize.tagIds || []}
                      handleChange={(selectedTags): void => {
                        setFieldValue('tagIds', selectedTags.map((tag => tag.id)))
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel
                      htmlFor={`${PrizeField.ITEM}[${PrizeField.DETAILS}][${PrizeField.DESCRIPTION}]`}
                      className={classes.inputLabel}
                    >
                      Description
                    </InputLabel>
                    <TextField
                      id={`${PrizeField.ITEM}[${PrizeField.DETAILS}][${PrizeField.DESCRIPTION}]`}
                      name={`${PrizeField.ITEM}[${PrizeField.DETAILS}][${PrizeField.DESCRIPTION}]`}
                      onChange={handleChange as React.ChangeEventHandler<HTMLInputElement>}
                      value={getIn(
                        prize,
                        `${PrizeField.ITEM}[${PrizeField.DETAILS}][${PrizeField.DESCRIPTION}]`,
                      )}
                      variant="filled"
                      fullWidth
                      multiline
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InputLabel className={classes.inputLabel}>
                      Visibility
                    </InputLabel>
                    <Select
                      id={PrizeField.VISIBILITY}
                      name={PrizeField.VISIBILITY}
                      disabled={isLottery}
                      value={prize[PrizeField.VISIBILITY]}
                      onChange={({ target: { value } }): void => {
                        setFieldValue(PrizeField.VISIBILITY, value)
                        if (PrizeVisibility.NewUser === value) {
                          setFieldValue(PrizeField.END, undefined)
                        }
                      }}
                      variant="filled"
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'left',
                        },
                        // getContentAnchorEl: null,
                      }}
                      fullWidth
                    >
                      {Object.values(PrizeVisibility).map((visibility) => (
                        <MenuItem key={visibility} value={visibility}>
                          {PrizeVisibilityDisplay[visibility]}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xs={6}>
                    <InputLabel
                      htmlFor={PrizeField.START}
                      className={classes.inputLabel}
                    >
                      Start Date
                    </InputLabel>
                    <DateTimePicker
                      value={moment(prize[PrizeField.START])}
                      onChange={(date): void => {
                        const newValue = moment(
                          date?.utc().toISOString(),
                        ).toISOString()
                        const adjustedEndDate = getAdjustedEndDate(
                          newValue,
                          prize[PrizeField.START],
                          prize[PrizeField.END],
                        )
                        if (setValues) {
                          setValues({
                            ...prize,
                            [PrizeField.END]: adjustedEndDate,
                            [PrizeField.START]: newValue,
                          })
                        }
                      }}
                      format={'YYYY-MM-DD HH:mm'}
                      {...formErrors[PrizeField.START]}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InputLabel
                      htmlFor={PrizeField.END}
                      className={classes.inputLabel}
                    >
                      End Date
                    </InputLabel>
                    <DateTimePicker
                      disabled={
                        prize[PrizeField.VISIBILITY] === PrizeVisibility.NewUser
                      }
                      value={
                        IsNullOrUndefined(prize[PrizeField.END])
                          ? null
                          : moment(prize[PrizeField.END])
                      }
                      onChange={(date): void => {
                        setFieldValue(
                          PrizeField.END,
                          moment(date?.utc().toISOString()).toISOString(),
                        )
                      }}
                      format={'YYYY-MM-DD HH:mm'}
                      minDate={moment(prize[PrizeField.START])}
                      {...formErrors[PrizeField.END]}
                    />
                  </Grid>
                  <Duration
                    end={prize[PrizeField.END]}
                    start={prize[PrizeField.START]}
                  />
                  <Grid item xs={6}>
                    <InputLabel
                      htmlFor={`${PrizeField.ITEM}[${PrizeField.UNIT_COST}]`}
                      className={classes.inputLabel}
                    >
                      Unit Cost (£)
                    </InputLabel>
                    <TextField
                      id={`${PrizeField.ITEM}[${PrizeField.UNIT_COST}]`}
                      name={`${PrizeField.ITEM}[${PrizeField.UNIT_COST}]`}
                      value={getIn(
                        prize,
                        `${PrizeField.ITEM}[${PrizeField.UNIT_COST}]`,
                      )}
                      onChange={handleChange as React.ChangeEventHandler<HTMLInputElement>}
                      variant="filled"
                      type="number"
                      fullWidth
                      InputProps={{
                        inputProps: {
                          min: 0,
                        },
                      }}
                    />
                  </Grid>
                  {!isLottery && (
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor={PrizeField.STAR_COST}
                        className={classes.inputLabel}
                      >
                        Star Cost
                      </InputLabel>
                      <TextField
                        id={PrizeField.STAR_COST}
                        name={PrizeField.STAR_COST}
                        value={prize[PrizeField.STAR_COST]}
                        onChange={handleChange as React.ChangeEventHandler<HTMLInputElement>}
                        variant="filled"
                        type="number"
                        InputProps={{
                          inputProps: {
                            min: 0,
                          },
                          endAdornment: (
                            <InputAdornment position="end">
                              <Box className={classes.adornmentIcon}>
                                <StarIcon />
                              </Box>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  )}
                  {prize.type === PrizeType.Instant && (
                    <>
                      <Grid item xs={6}/>
                      <Grid item xs={6}>
                        <InputLabel
                          htmlFor="salesStarCost"
                          className={classes.inputLabel}
                        >
                          Sales Star Cost
                        </InputLabel>
                        <TextField
                          id="salesStarCost"
                          name="salesStarCost"
                          value={prize.salesStarCost}
                          onChange={handleChange as React.ChangeEventHandler<HTMLInputElement>}
                          variant="filled"
                          type="number"
                          InputProps={{
                            inputProps: {
                              min: 0,
                            },
                            endAdornment: (
                              <InputAdornment position="end">
                                <Box className={classes.adornmentIcon}>
                                  <StarIcon />
                                </Box>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </>
                  )}
                  {isLottery && (
                    <Grid item xs={6}>
                      <InputLabel
                        htmlFor={PrizeField.COIN_COST}
                        className={classes.inputLabel}
                      >
                        Coin Cost
                      </InputLabel>
                      <TextField
                        id={PrizeField.COIN_COST}
                        name={PrizeField.COIN_COST}
                        value={prize[PrizeField.COIN_COST]}
                        onChange={handleChange as React.ChangeEventHandler<HTMLInputElement>}
                        variant="filled"
                        type="number"
                        InputProps={{
                          inputProps: {
                            min: 0,
                          },
                          endAdornment: (
                            <InputAdornment position="end">
                              <Box className={classes.adornmentIcon}>
                                <CoinIcon />
                              </Box>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  )}
                  <Grid item xs={6}>
                    <InputLabel
                      htmlFor={`${PrizeField.ITEM}[${PrizeField.QUANTITY}]`}
                      className={classes.inputLabel}
                    >
                      Stock Quantity
                    </InputLabel>
                    <TextField
                      id={`${PrizeField.ITEM}[${PrizeField.QUANTITY}]`}
                      name={`${PrizeField.ITEM}[${PrizeField.QUANTITY}]`}
                      value={getIn(
                        prize,
                        `${PrizeField.ITEM}[${PrizeField.QUANTITY}]`,
                      )}
                      onChange={handleChange as React.ChangeEventHandler<HTMLInputElement>}
                      variant="filled"
                      type="number"
                      fullWidth
                      error={!!quantityError}
                      helperText={quantityError}
                      InputProps={{
                        inputProps: {
                          min: 0,
                        },
                      }}
                    />
                  </Grid>
                  {!isLottery && (
                    <>
                      <Grid item xs={6}>
                        <InputLabel
                          htmlFor={`${PrizeField.ITEM}[${PrizeField.DETAILS}][${PrizeField.PROMOTION}][${PrizeField.PROMOTION_URL}]`}
                          className={classes.inputLabel}
                        >
                          Promo Url
                        </InputLabel>
                        <TextField
                          id={`${PrizeField.ITEM}[${PrizeField.DETAILS}][${PrizeField.PROMOTION}][${PrizeField.PROMOTION_URL}]`}
                          name={`${PrizeField.ITEM}[${PrizeField.DETAILS}][${PrizeField.PROMOTION}][${PrizeField.PROMOTION_URL}]`}
                          onChange={handleChange as React.ChangeEventHandler<HTMLInputElement>}
                          value={getIn(
                            prize,
                            `${PrizeField.ITEM}[${PrizeField.DETAILS}][${PrizeField.PROMOTION}][${PrizeField.PROMOTION_URL}]`,
                          )}
                          variant="filled"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <InputLabel
                          htmlFor={`${PrizeField.ITEM}[${PrizeField.DETAILS}][${PrizeField.PROMOTION}][${PrizeField.PROMOTION_DESCRIPTION}]`}
                          className={classes.inputLabel}
                        >
                          Promo Button
                        </InputLabel>
                        <TextField
                          id={`${PrizeField.ITEM}[${PrizeField.DETAILS}][${PrizeField.PROMOTION}][${PrizeField.PROMOTION_DESCRIPTION}]`}
                          name={`${PrizeField.ITEM}[${PrizeField.DETAILS}][${PrizeField.PROMOTION}][${PrizeField.PROMOTION_DESCRIPTION}]`}
                          onChange={handleChange as React.ChangeEventHandler<HTMLInputElement>}
                          value={getIn(
                            prize,
                            `${PrizeField.ITEM}[${PrizeField.DETAILS}][${PrizeField.PROMOTION}][${PrizeField.PROMOTION_DESCRIPTION}]`,
                          )}
                          variant="filled"
                          fullWidth
                          error={!!nameError}
                          helperText={nameError}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <InputLabel
                          htmlFor={`${PrizeField.ITEM}[${PrizeField.DETAILS}][${PrizeField.PROMOTION}][${PrizeField.PROMOTION_CODE}]`}
                          className={classes.inputLabel}
                        >
                          Promo Code
                        </InputLabel>

                        <TextField
                          id={`${PrizeField.ITEM}[${PrizeField.DETAILS}][${PrizeField.PROMOTION}][${PrizeField.PROMOTION_CODE}]`}
                          name={`${PrizeField.ITEM}[${PrizeField.DETAILS}][${PrizeField.PROMOTION}][${PrizeField.PROMOTION_CODE}]`}
                          onChange={handleChange as React.ChangeEventHandler<HTMLInputElement>}
                          value={getIn(
                            prize,
                            `${PrizeField.ITEM}[${PrizeField.DETAILS}][${PrizeField.PROMOTION}][${PrizeField.PROMOTION_CODE}]`,
                          )}
                          variant="filled"
                          fullWidth
                        />
                      </Grid>
                    </>
                  )}
                  {prize.type === PrizeType.Sweepstake && (
                    <Grid item xs={12}>
                      <InputLabel
                        htmlFor={PrizeField.REQUIRED_ENTRIES}
                        className={classes.inputLabel}
                      >
                        # of Entries
                      </InputLabel>
                      <TextField
                        id={PrizeField.REQUIRED_ENTRIES}
                        name={PrizeField.REQUIRED_ENTRIES}
                        value={prize[PrizeField.REQUIRED_ENTRIES]}
                        onChange={handleChange as React.ChangeEventHandler<HTMLInputElement>}
                        variant="filled"
                        type="number"
                        fullWidth
                        InputProps={{
                          inputProps: {
                            min: 1,
                          },
                        }}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  )
}
