/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroll-component'
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import { Panel, PanelHeader, PanelContent } from 'components/panel'
import { makeStyles } from '@mui/styles'
import { PAGE_SIZE } from 'modules/quests/constants'
import { useLocalListManager } from 'hooks/use-local-list-manager'
import { FormGen, FormTypes } from 'components/simple-form-gen'
import { IListWrapperProps, Item, ActionTypeParent } from './types'

const useStyles = makeStyles(
  createStyles({
    infiniteScrollLoader: {
      width: 40,
      height: 40,
      display: 'flex',
      overflow: 'hidden',
      margin: 'auto',
      marginTop: 20,
      marginBottom: 20,
    },
    table: {
      minWidth: '100%',
    },
    filterWrapper: {
      marginBottom: 16,
    },
    listContainer: {
      overflowY: 'auto',
    }
  }),
)

export function LocalListWrapper<
  Type extends Item,
  ActionType extends ActionTypeParent,
  FilterSettingType,
>({
  children,
  list,
  count,
  searchKey,
  searchLabel,
  currentItem,
  createDraft,
  setCurrentItem,
  CardComponent,
  title,
  ModalComponent,
  getItems,
  sortOptions,
  panelXs = 6,
  customFilter,
  hasFetchMore = false,
}: IListWrapperProps<Type, ActionType, FilterSettingType>): JSX.Element {
  const {
    pageNumber,
    loadMore,
    searchValue,
    setSearchValue,
    filteredList,
    onChangeCustomFilterSettings,
    fullList,
    sortValue,
    setSortValue,
  } = useLocalListManager<Type, FilterSettingType>({
    list,
    searchKey,
    customFilterFcn: customFilter?.filter,
    sortOptions,
  })

  const dispatch = useDispatch()

  useEffect(() => {
    const params = {
      page: pageNumber,
      pageSize: PAGE_SIZE,
    }
    if (list.length === 0) {
      dispatch(getItems(params as ActionType))
    }
  }, [dispatch, pageNumber])

  const renderList = (): JSX.Element[] | null => {
    return (
      filteredList?.map((item) => {
        return (
          <CardComponent
            key={`item-${item.id!}`}
            item={item}
            onClick={(): void => {
              setCurrentItem(item.id!)
            }}
          />
        )
      }) ?? null
    )
  }

  const renderSort = (): JSX.Element => {
    return (
      <FormGen
        value={sortValue}
        handleChange={(e): void => setSortValue(e.target.value)}
        name="sortBy"
        type={FormTypes.SELECT}
        label="Sort by"
        options={sortOptions}
        hideInitialOption
      />
    )
  }

  const classes = useStyles()
  const innerComponent = <>
    {customFilter ? (
      <customFilter.Component
        handleChange={onChangeCustomFilterSettings}
        list={fullList}
      >
        {sortOptions && (
          <Grid item xs={6}>
            {renderSort()}
          </Grid>
        )}
      </customFilter.Component>
    ) : (
      <Box className={classes.filterWrapper}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormGen
              value={searchValue}
              handleChange={(e): void => setSearchValue(e.target.value)}
              name="search"
              type={FormTypes.TEXT_INPUT}
              label={searchLabel}
            />
          </Grid>
          {sortOptions && (
            <Grid item xs={6}>
              {renderSort()}
            </Grid>
          )}
        </Grid>
      </Box>
    )}
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>{children}</TableRow>
        </TableHead>
        <TableBody>{renderList()}</TableBody>
      </Table>
    </TableContainer>
  </>

  return (
    <>
      <Panel xs={panelXs}>
        <PanelHeader
          title={title}
          inlineControls={
            createDraft
              ? <Button
                variant="contained"
                color="primary"
                onClick={(): void => createDraft?.()}
                >
                  Create
                </Button>
              : <></>
          }
        />
        <PanelContent id="QuestContent">
          {!hasFetchMore
            ? <InfiniteScroll dataLength={list.length!}
              next={loadMore}
              hasMore={list.length! < count}
              loader={
                <CircularProgress className={classes.infiniteScrollLoader} />
              }
              scrollThreshold={'300px'}
              scrollableTarget="QuestContent"
            >
              {innerComponent}
            </InfiniteScroll>
            : <Box className={classes.listContainer}>
              {innerComponent}
            </Box>}
        </PanelContent>
      </Panel>
      {currentItem && <ModalComponent />}
    </>
  )
}
