import { MODULE_NAME } from '../constants'

const ActionTypes: { [key: string]: string } = {
  CLEAR_USER_PRIZES: `${MODULE_NAME}/CLEAR_USER_PRIZES`,
  SET_CURRENT_USER_PRIZE_ID: `${MODULE_NAME}/SET_CURRENT_USER_PRIZE_ID`,
  GET_USER_PRIZES: `${MODULE_NAME}/GET_USER_PRIZES`,
  GET_USER_PRIZE_STATUSES: `${MODULE_NAME}/GET_USER_PRIZE_STATUSES`,
  UPDATE_USER_PRIZE_STATUS: `${MODULE_NAME}/UPDATE_USER_PRIZE_STATUS`,
  CREATE_USER_PRIZE_NOTE: `${MODULE_NAME}/CREATE_USER_PRIZE_NOTE`,
}

export default ActionTypes
