import { useTypedSelector } from 'core/redux/utils'
import { useRouter } from 'core/routing/hooks/use-router'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { IapProductActions, Selectors } from 'modules/iap-products'
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
} from 'react'
import { useDispatch } from 'react-redux'
import { NIL as NIL_UUID } from 'uuid'
import { IIapProduct } from 'modules/iap-products/models/iap-product'
import { MediaUploadModel } from 'types/common/images/models/entities/image-upload-model'

export interface IIapProductsContext {
  currentIapProduct: IIapProduct | null
  setCurrentIapProduct: (iapProductId: string) => void
  clearCurrentIapProduct: () => void
  createIapProduct: (
    iapProduct: IIapProduct,
    callbackFn?: (iapProduct: IIapProduct) => void,
  ) => void
  createIapProductDraft: (iapProduct?: IIapProduct) => void
  updateIapProduct: (iapProduct: IIapProduct) => void
  deleteIapProduct: () => void
}

const IapProductsContext = createContext<IIapProductsContext>(
  {} as IIapProductsContext,
)

export const IapProductsProvider: React.FC<PropsWithChildren<{}>> = (
  props: PropsWithChildren<{}>,
) => {
  const dispatch = useDispatch()

  const {
    routeParams: { iapProductId },
    pushQueryParams,
    removeQueryParams,
  } = useRouter()

  const currentIapProduct = useTypedSelector((state) =>
    Selectors.getCurrentIapProduct(state),
  )

  const setCurrentIapProduct = (iapProductId: string): void => {
    pushQueryParams({
      iapProductId,
    })
  }

  const clearCurrentIapProduct = (): void => {
    removeQueryParams('iapProductId')
  }

  const createIapProduct = (iapProduct: IIapProduct): void => {
    dispatch(
      IapProductActions.createIapProduct({
        item: iapProduct,
        promise: {
          onResolve: (data: IIapProduct): void => {
            setCurrentIapProduct(data.id!)
          },
        },
      }),
    )
  }

  const createIapProductDraft = (): void => {
    setCurrentIapProduct(NIL_UUID)
  }

  const updateIapProduct = (iapProduct: IIapProduct): void => {
    dispatch(
      IapProductActions.updateIapProduct({
        item: iapProduct,
        iapProductId: iapProduct.id,
      }),
    )
  }

  const deleteIapProduct = (): void => {
    dispatch(
      IapProductActions.deleteIapProduct({
        iapProductId: currentIapProduct?.id,
      }),
    )
    clearCurrentIapProduct()
  }

  useEffect(() => {
    dispatch(IapProductActions.setCurrentIapProductId({ iapProductId }))
    if (!IsNullOrUndefined(iapProductId) && iapProductId !== NIL_UUID) {
      dispatch(IapProductActions.getIapProductById({ iapProductId }))
    }
  }, [iapProductId])

  const context = {
    currentIapProduct,
    setCurrentIapProduct,
    clearCurrentIapProduct,
    createIapProduct,
    createIapProductDraft,
    updateIapProduct,
    deleteIapProduct,
  }

  return (
    <IapProductsContext.Provider value={context}>
      {props.children}
    </IapProductsContext.Provider>
  )
}

export const useIapProductsContext = (): IIapProductsContext =>
  useContext(IapProductsContext)
