import { InputLabel } from '@mui/material'
import { AutocompleteSearchMultiselect } from 'components/controls/inputs/downshift/autocomplete/autocomplete-search-multiselect'
import { OverrideChipProps } from 'components/controls/inputs/downshift/multiselect/multiselect-props'
import { useSimpleFormStyles } from 'components/simple-form-gen'
import { useTypedSelector } from 'core/redux/utils'
import { Actions, Selectors } from 'modules/posts'
import { IPostActionParams } from 'modules/posts/actions'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import SortDirection from 'types/common/enums/sort-direction'
import { FilterOperator } from 'types/common/filtering/enums/FilterOperator'
import { FilterType } from 'types/common/filtering/enums/FilterType'
import { IFilter } from 'types/common/filtering/models/entities/IFilter'
import { IPost, IPostAutoCompleteFilter } from 'types/modules/posts/models/entities/post'
import { IAsyncAction } from 'types/redux/interfaces/IAction'

interface IPostsSelectProps {
  handleChange: (selectedPosts: string[]) => void
  initialSelectedIds: string[]
  filter?: IPostAutoCompleteFilter
}

export const PostsSelect = ({ handleChange, initialSelectedIds, filter }: IPostsSelectProps): JSX.Element | null => {
  const [counter, setCounter] = useState(0)
  const getPost = useTypedSelector((state) => Selectors.getPostGetter(state))
  const dispatch = useDispatch()
  const initiallySelectedPosts = initialSelectedIds.map((id) => getPost(id))
  const simpleFormClasses = useSimpleFormStyles()

  useEffect(() => {
    initialSelectedIds?.forEach((id) => {
      if (!getPost(id)) {
        dispatch(Actions.getPostById({
          postId: id,
          promise: {
            onResolve: (): void => setCounter(counter + 1)
          }
        }))
      }
    })
  }, [])

  const searchPosts = (params: IPostActionParams): IAsyncAction => {
    const filters: IFilter[] = [
      {
        field: 'title',
        type: FilterType.Search,
        operator: FilterOperator.ContainsCaseInsensitive,
        value: params.search!.value,
      }
    ]
    if (filter && filter.type) {
      filters.push({
        field: 'type',
        type: FilterType.Search,
        operator: FilterOperator.Equals,
        value: filter.type || []
      })
    }
    if (filter && filter.status) {
      filters.push({
        field: 'status',
        type: FilterType.Select,
        operator: FilterOperator.Equals,
        value: filter.status || [],
      })
    }
    return Actions.getAutocompleteSearchPosts({
      filters,
      sortyBy: 'updatedAt',
      sortDirection: SortDirection.Ascending,
    })
  }

  if (initialSelectedIds.length && initiallySelectedPosts.some(post => !post?.id)) return null

  return  (<>
    <InputLabel className={simpleFormClasses.inputLabel}>
      Posts
    </InputLabel>
    <AutocompleteSearchMultiselect
      variant="filled"
      initialSelectedItems={initiallySelectedPosts}
      availableItems={[]}
      onSelectedItemsChange={(selectedPosts: IPost[]): void => {
        handleChange(selectedPosts.map((post) => post.id))
      }}
      searchAsyncActionCreator={searchPosts}
      selectorMethod={(state) => Selectors.getAutocompleteSearchPosts(state, filter)}
      options={{
        selectedItemsChipProps: (item: IPost): OverrideChipProps => {
          const props: OverrideChipProps = {
            label: item.title!,
          }
          return props
        },
        autocompleteSearchResultContent: (item: IPost): string =>
          item.title,
      }}
      searchKey="title"
    />
  </>)
}