import { Box, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { PreviewContainer } from 'components/preview'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import React from 'react'
import { IPostResult } from 'types/modules/posts/models/entities/post-result'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    resultScreen: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      position: 'relative',
    },
    headerToolbar: {
      display: 'flex',
      width: '100%',
      background: theme.palette.primary.main,
      height: 66,
    },
    resultImage: {
      display: 'flex',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '50%',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundColor: '#ffffff',
    },
    mainContentWrapper: {
      display: 'flex',
      width: '100%',
      height: '55%',
      padding: 20,
      position: 'absolute',
      bottom: 0,
      background: '#FFFFFF',
      borderRadius: '15px 15px 0 0',
      flexDirection: 'column',
    },
    resultContainer: {
      display: 'flex',
      position: 'relative',
      flex: 1,
      width: '100%',
      height: '100%',
      overflow: 'auto',
      background: 'rgb(243, 240, 240)',
      borderRadius: 20,
      padding: 15,
      flexDirection: 'column',
    },
    resultTitleContainer: {
      display: 'flex',
      width: '100%',
      marginBottom: 15,
      justifyContent: 'center',
      alignItems: 'center',
    },
    resultTitle: {
      display: 'flex',
      textAlign: 'center',
      fontWeight: 600,
      fontSize: 20,
      color: '#000000',
    },
    resultDescription: {
      display: 'flex',
      fontSize: 18,
      textAlign: 'center',
      fontWeight: 600,
      color: '#939393',
    },
    fakeButton: {
      display: 'flex',
      borderRadius: 20,
      width: '100%',
      height: 60,
      justifyContent: 'center',
      alignItems: 'center',
      background: 'rgb(255, 151, 57)',
      marginTop: 20,
    },
    buttonText: {
      fontSize: 16,
      textAlign: 'center',
      color: 'rgba(255,255,255,0.4)',
    },
  }),
)

export const ResultScreenPreview: React.FC<IPostResult> = (
  result: IPostResult,
) => {
  const classes = useStyles()

  const hasImage = !IsNullOrUndefined(result.media?.imageUrl)
  const resultImageStyles: { [key: string]: string | number } = {}

  if (hasImage) {
    resultImageStyles.backgroundImage = `url(${result.media!.imageUrl!})`
  } else {
    resultImageStyles.background = 'rgba(0,0,0,0.03)'
  }

  return (
    <PreviewContainer withDevice={true}>
      <Box className={classes.resultScreen}>
        <Box className={classes.resultImage} style={resultImageStyles} />
        <Box className={classes.mainContentWrapper}>
          <Box className={classes.resultContainer}>
            <Box className={classes.resultTitleContainer}>
              <Typography className={classes.resultTitle}>
                {result.text}
              </Typography>
            </Box>
            <Typography className={classes.resultDescription}>
              {result.media?.description}
            </Typography>
          </Box>
          <Box className={classes.fakeButton}>
            <Typography className={classes.buttonText}>Play Again</Typography>
          </Box>
        </Box>
      </Box>
    </PreviewContainer>
  )
}
