import { useTypedSelector } from 'core/redux/utils';
import { Selectors } from 'modules/app-settings';

interface IEnums {
  taskCodes: string[]
  currencies: string[]
  schedules: string[]
  eventCodes: string[]
  rewardOptions: string[]
  actionCodes: string[]
  questMediaLocations: string[]
}

export const useEnums = (): IEnums => {
  const currencies = useTypedSelector((state) => Selectors.getCurrentCurrencies(state))
  const eventCodes = useTypedSelector((state) => Selectors.getEventCodes(state))
  const schedules = useTypedSelector((state) => Selectors.getSchedules(state))
  const taskCodes = useTypedSelector((state) => Selectors.getTaskCodes(state))
  const actionCodes = useTypedSelector((state) => Selectors.getActionCodes(state))
  const questMediaLocations = useTypedSelector((state) => Selectors.getQuestMediaLocations(state))
  const rewardOptions = useTypedSelector((state) => Selectors.getRewardOptions(state))

  return {
    currencies,
    schedules,
    eventCodes,
    taskCodes,
    rewardOptions,
    actionCodes,
    questMediaLocations
  }
}