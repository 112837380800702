import ContentDashboard from 'components/content-dashboard'
import { DashboardContextProvider } from 'components/content-dashboard/context'
import { IContentDashboardProps } from 'components/content-dashboard/props/content-dashboard-props'
import { useRequestContext } from 'core/api/context'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import React, { useCallback } from 'react'
import { PostField } from 'types/modules/posts/enums/post-field'
import _ from 'core/utils/deepdash'
import SortDirection from 'types/common/enums/sort-direction'
import { IFilter } from 'types/common/filtering/models/entities/IFilter'
import { SelectFilterMode } from 'types/common/filtering/enums/SelectFilterMode'
import { ImageIcon } from 'components/icons'
import { useRouter } from 'core/routing/hooks/use-router'
import { useShippingContext } from 'modules/shipping/context'
import { UserPrizeField } from 'types/modules/shipping/enums/user-prize-field'
import { UserPrizeStatus } from 'types/modules/shipping/enums/user-prize-status'
import { IUserPrize } from 'types/modules/shipping/models/entities/user-prize'
import { UserPrizeListItem } from 'modules/shipping/views/components/dashboard/user-prize-list-item'
import { UserPrizeListToolbar } from 'modules/shipping/views/components/dashboard/user-prize-list-toolbar'
import { UserPrizeCalendarDateGridCell } from 'modules/shipping/views/components/dashboard/user-prize-calendar-date-grid-cell'
import { UserPrizeCalendarTimeGridCell } from 'modules/shipping/views/components/dashboard/user-prize-calendar-time-grid-cell'
import { UserPrizeDetailsModal } from 'modules/shipping/views/components/manage-user-prize-panel/modal'

export const UserPrizesDashboard: React.FC<{}> = () => {
  const {
    currentUserPrize,
    userPrizes,
    statusFilterConfig,
    initialiseDashboard,
  } = useShippingContext()

  const { routeParams, pushQueryParams } = useRouter()

  const rc = useRequestContext()

  const statusFilter = rc?.getFilterByField(PostField.STATUS)

  const onViewModeChange = React.useCallback(
    (viewMode: string) => {
      if (IsNullOrUndefined(statusFilter)) return

      const newSortValue = _.clone(rc?.sortValue!)
      const newStatusFilter = _.clone(statusFilter)

      switch (viewMode) {
        case 'list': {
          newSortValue.sortBy = UserPrizeField.CREATED_AT
          newSortValue.sortDirection = SortDirection.Descending

          newStatusFilter!.value = [UserPrizeStatus.ReadyToShip]

          rc?.setSortValue(newSortValue)
          rc?.applyFilter(newStatusFilter!)
          break
        }

        case 'calendar': {
          newSortValue.sortBy = UserPrizeField.CREATED_AT
          newSortValue.sortDirection = SortDirection.Ascending

          newStatusFilter!.value = [
            UserPrizeStatus.Cancelled,
            UserPrizeStatus.ReadyToShip,
            UserPrizeStatus.Dispatched,
            UserPrizeStatus.Delivered,
          ]

          rc?.setSortValue(newSortValue)
          rc?.applyFilter(newStatusFilter!)

          break
        }

        default:
          return
      }
    },
    [statusFilter],
  )

  const onTabChange = useCallback(
    (value: IFilter['value'], mode: SelectFilterMode) => {
      const valueIsArray = Array.isArray(value)
      const valueAsArray = value as UserPrizeStatus[]

      if (IsNullOrUndefined(statusFilter) || !valueIsArray) return

      if (mode === SelectFilterMode.Multiple) {
        rc?.setActive(true)
        return
      }

      const status = valueAsArray[0]

      switch (status) {
        case UserPrizeStatus.ReadyToShip: {
          rc?.setSortValue({
            sortBy: UserPrizeField.CREATED_AT,
            sortDirection: SortDirection.Ascending,
          })
          return
        }

        default: {
          rc?.setSortValue({
            sortBy: PostField.UPDATED_AT,
            sortDirection: SortDirection.Descending,
          })
          return
        }
      }
    },
    [statusFilter],
  )

  const dashboardProps: IContentDashboardProps<IUserPrize> = {
    onDashboardMount: initialiseDashboard,
    onViewModeChange,
    onTabChange,
    tabFilterConfig: statusFilterConfig,
    listOptions: {
      listItemComponent: UserPrizeListItem,
      toolbarComponent: <UserPrizeListToolbar />,
    },
    calendarOptions: {
      toolbarComponent: <UserPrizeListToolbar />,
      timeGridCellComponent: UserPrizeCalendarTimeGridCell,
      dateGridCellComponent: UserPrizeCalendarDateGridCell,
      headerActionButton: {
        icon: <ImageIcon />,
        onClick: (date: string): void => {
          pushQueryParams({
            viewMedia: date,
          })
        },
      },
    },
    data: userPrizes,
  }

  return (
    <React.Fragment>
      <DashboardContextProvider>
        <ContentDashboard {...dashboardProps} />
      </DashboardContextProvider>
      {!IsNullOrUndefined(currentUserPrize) && (
        <UserPrizeDetailsModal currentUserPrize={currentUserPrize} open />
      )}
    </React.Fragment>
  )
}
