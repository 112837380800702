import { actionTypeSuccess, cacheItem } from 'core/redux/utils'
import _ from 'core/utils/deepdash'
import { ActionTypes } from 'modules/media-items'
import { Reducer } from 'redux'
import { MediaItem } from 'types/modules/media-items/models/entities/media-item'
import { IMediaItemState } from 'types/modules/media-items/models/state/media-item-state'
import { ISimpleAction, ISuccessAction } from 'types/redux/interfaces/IAction'

const initialState = (): IMediaItemState => {
  return {
    items: {},
  }
}

type AllowedActionTypes = ISuccessAction | ISimpleAction

const MediaItemsReducer: Reducer<IMediaItemState, AllowedActionTypes> = (
  state = initialState(),
  action: AllowedActionTypes,
) => {
  const simpleAction = action as ISimpleAction
  const successAction = action as ISuccessAction

  switch (action.type) {
    case actionTypeSuccess(ActionTypes.GET_MEDIA_ITEM_BY_ID):
    case actionTypeSuccess(ActionTypes.UPLOAD_MEDIA_ITEM_IMAGE):
    case actionTypeSuccess(ActionTypes.UPDATE_MEDIA_ITEM_IMAGE):
    case actionTypeSuccess(ActionTypes.UPDATE_MEDIA_ITEM_TRANSFORM_DATA): {
      const newState = _.cloneDeep(state)
      const response = successAction.data as MediaItem

      newState.items = cacheItem(state.items, response)

      return newState
    }

    default:
      return state
  }
}

export default MediaItemsReducer
