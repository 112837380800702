/* eslint-disable no-extra-boolean-cast */
import React from 'react'
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Modal from '@mui/material/Modal'
import Fade from '@mui/material/Fade'
import { Panel } from 'components/panel'
import _ from 'core/utils/deepdash'
import {
  Box,
  GridSize,
  IconButton,
  SvgIcon,
  Typography,
} from '@mui/material'
import { CrossIcon } from 'components/icons'
import { useStylesForModalsWithTabs } from 'hooks/use-styles-for-modals-with-tabs'

const useStyles = makeStyles(() =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    hidden: {
      display: 'none',
    },
  }),
)

interface IModalWrapperProps {
  title: string,
  open: boolean,
  panelSize: GridSize
  children: JSX.Element
  PanelHeader?: JSX.Element
  closeModal: () => void
}

export const ModalWrapper = ({ title, open, panelSize, children, closeModal, PanelHeader }: IModalWrapperProps): JSX.Element => {
  const classes = useStyles()
  const otherClasses = useStylesForModalsWithTabs()

  return (
    <div>
      <Modal
        className={classes.modal}
        open={open}
      >
        <Fade in={open}>
          <Panel xs={panelSize} container>
            {!!PanelHeader ? PanelHeader : <Box className={otherClasses.panelHeader}>
              <Box className={otherClasses.panelTitle}>
                <Typography variant="h6">
                  {title}
                </Typography>
              </Box>
              <Box className={otherClasses.rightHeaderSection}>
                <IconButton onClick={closeModal} size="large">
                  <SvgIcon>
                    <CrossIcon />
                  </SvgIcon>
                </IconButton>
              </Box>
            </Box>}
            <Box>
              {children}
            </Box>
          </Panel>
        </Fade>
      </Modal>
    </div>
  );
}
