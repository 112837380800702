import { EndpointRoute } from 'types/common/api/models/entities/endpoint-route'
import { RequestMethod } from 'types/common/api/enums/request-method'
import paths from './paths'
import params from './params'
import filters from './filters'

const QuestGroupEndpoints: { [key: string]: EndpointRoute } = {
  getQuestGroups: {
    method: RequestMethod.GET,
    path: [paths.questGroups],
    filters,
  },
  getQuestGroupById: {
    method: RequestMethod.GET,
    path: [paths.questGroups, params.questGroupId],
  },
  createQuestGroup: {
    method: RequestMethod.POST,
    path: [paths.questGroups],
  },
  updateQuestGroup: {
    method: RequestMethod.PUT,
    path: [paths.questGroups, params.questGroupId],
  },
}

export default QuestGroupEndpoints
