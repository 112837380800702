import { createAction, createAsyncAction } from 'core/redux/utils'
import { IAsyncAction, ISimpleAction } from 'types/redux/interfaces/IAction'
import PostsEndpoints from 'core/api/endpoints/posts'
import ActionTypes from 'modules/posts/action-types'
import { IAsyncActionParams } from 'types/common/api/models/entities/async-action-params'
import { IPost } from 'types/modules/posts/models/entities/post'
import { IPostQuestion } from 'types/modules/posts/models/entities/post-question'
import { IPostResult } from 'types/modules/posts/models/entities/post-result'
import { IPostDraft } from 'types/modules/posts/models/entities/post-draft'
import { IPostQuestionAnswer } from 'types/modules/posts/models/entities/post-question-answer'
import { Version } from 'types/common/enums/versions'

export interface IPostActionParams
  extends IAsyncActionParams<
    | IPost
    | IPostDraft
    | IPostQuestion
    | IPostResult
    | IPostQuestionAnswer
    | string
  > {
  alertId?: string
  answerId?: string
  matchId?: string
  optionId?: string
  postId?: string
  postStatus?: string
  questionId?: string
  resultId?: string
  postGroupId?: string
}

export const setCurrentPostId: (params: IPostActionParams) => ISimpleAction = (
  params,
) => createAction(ActionTypes.SET_CURRENT_POST_ID, params)

export const clearPosts: () => ISimpleAction = () =>
  createAction(ActionTypes.CLEAR_POSTS, null)

export const getPosts: (params: IPostActionParams) => IAsyncAction = (params) =>
  createAsyncAction({ type: ActionTypes.GET_POSTS, endpoint: PostsEndpoints.getPosts, payload: params})

export const getPostById: (params: IPostActionParams) => IAsyncAction = (
  params,
) =>
  createAsyncAction({
    type: ActionTypes.GET_POST_BY_ID,
    endpoint: PostsEndpoints
.getPostById,
    payload: params,
  })

export const createPost: (params: IPostActionParams) => IAsyncAction = (
  params,
) =>
  createAsyncAction({ type: ActionTypes.CREATE_POST, endpoint: PostsEndpoints.createPost, payload: params })

export const duplicatePost: (params: IPostActionParams) => IAsyncAction = (
  params,
) =>
  createAsyncAction({
    type: ActionTypes.DUPLICATE_POST,
    endpoint: PostsEndpoints
.duplicatePost,
    payload: params,
  })

export const updatePost: (params: IPostActionParams) => IAsyncAction = (
  params,
) =>
  createAsyncAction({ type: ActionTypes.UPDATE_POST, endpoint: PostsEndpoints.updatePost, payload: params})

export const updatePostTags: (params: IPostActionParams) => IAsyncAction = (
  params,
) =>
  createAsyncAction({
    type: ActionTypes.UPDATE_POST_TAGS,
    endpoint: PostsEndpoints
.updatePostTags,
    payload: params,
  })

export const uploadPostImage: (params: IPostActionParams) => IAsyncAction = (
  params,
) =>
  createAsyncAction({
    type: ActionTypes.UPLOAD_POST_IMAGE,
    endpoint: PostsEndpoints
.uploadPostImage,
    payload: params,
  })

export const updatePostStatus: (params: IPostActionParams) => IAsyncAction = (
  params,
) =>
  createAsyncAction({
    type: ActionTypes.UPDATE_POST_STATUS,
    endpoint: PostsEndpoints
.updatePostStatus,
    payload: params,
  })

export const settlePostTickets: (params: IPostActionParams) => IAsyncAction = (
  params,
) =>
  createAsyncAction({
    type: ActionTypes.SETTLE_POST_TICKETS,
    endpoint: PostsEndpoints
.settlePostTickets,
    payload: params,
  })

export const promotePost: (params: IPostActionParams) => IAsyncAction = (
  params,
) =>
  createAsyncAction({
    type: ActionTypes.PROMOTE_POST,
    endpoint: PostsEndpoints
.promotePost,
    payload: params,
  })

export const updateStakeDistribution: (
  params: IPostActionParams,
) => IAsyncAction = (params) =>
  createAsyncAction({
    type: ActionTypes.UPDATE_STAKE_DISTRIBUTION,
    endpoint: PostsEndpoints
.updateStakeDistribution,
    payload: params,
  })

export const setPostCompromised: (params: IPostActionParams) => IAsyncAction = (
  params,
) =>
  createAsyncAction({
    type: ActionTypes.SET_POST_COMPROMISED,
    endpoint: PostsEndpoints
.setPostCompromised,
    payload: params,
  })

export const deletePost: (params: IPostActionParams) => IAsyncAction = (
  params,
) =>
  createAsyncAction({ type: ActionTypes.DELETE_POST, endpoint: PostsEndpoints.deletePost, payload: params})

export const clearTrash: () => IAsyncAction = () =>
  createAsyncAction({ type: ActionTypes.CLEAR_TRASH, endpoint: PostsEndpoints.clearTrash, payload: null })

export const addQuestion: (params: IPostActionParams) => IAsyncAction = (
  params,
) =>
  createAsyncAction({
    type: ActionTypes.ADD_QUESTION,
    endpoint: PostsEndpoints
.addQuestion,
    payload: params,
  })

export const getQuestionById: (params: IPostActionParams) => IAsyncAction = (
  params,
) =>
  createAsyncAction({
    type: ActionTypes.GET_QUESTION_BY_ID,
    endpoint: PostsEndpoints
.getQuestionById,
    payload: params,
  })

export const updateQuestion: (params: IPostActionParams) => IAsyncAction = (
  params,
) =>
  createAsyncAction({
    type: ActionTypes.UPDATE_QUESTION,
    endpoint: PostsEndpoints.updateQuestion,
    payload: params,
    alertsEnabled: params.alertsEnabled
  })

export const saveQuestionAndOptions: (
  params: IPostActionParams,
) => IAsyncAction = (params) =>
  createAsyncAction({
    type: ActionTypes.SAVE_QUESTION_AND_OPTIONS,
    endpoint: PostsEndpoints
.saveQuestionAndOptions,
    payload: params,
  })

export const uploadQuestionImage: (
  params: IPostActionParams,
) => IAsyncAction = (params) =>
  createAsyncAction({
    type: ActionTypes.UPLOAD_QUESTION_IMAGE,
    endpoint: PostsEndpoints
.uploadQuestionImage,
    payload: params,
  })

export const deleteQuestion: (params: IPostActionParams) => IAsyncAction = (
  params,
) =>
  createAsyncAction({
    type: ActionTypes.DELETE_QUESTION,
    endpoint: PostsEndpoints
.deleteQuestion,
    payload: params,
  })

export const getQuestionAlerts: (params: IPostActionParams) => IAsyncAction = (
  params,
) =>
  createAsyncAction({
    type: ActionTypes.GET_QUESTION_ALERTS,
    endpoint: PostsEndpoints
.getQuestionAlerts,
    payload: params,
  })

export const deleteQuestionAlert: (
  params: IPostActionParams,
) => IAsyncAction = (params) =>
  createAsyncAction({
    type: ActionTypes.DELETE_QUESTION_ALERT,
    endpoint: PostsEndpoints
.deleteQuestionAlert,
    payload: params,
  })

export const addResult: (params: IPostActionParams) => IAsyncAction = (
  params,
) => createAsyncAction({ type: ActionTypes.ADD_RESULT, endpoint: PostsEndpoints.addResult, payload: params})

export const getResultById: (params: IPostActionParams) => IAsyncAction = (
  params,
) =>
  createAsyncAction({
    type: ActionTypes.GET_RESULT_BY_ID,
    endpoint: PostsEndpoints
.getResultById,
    payload: params,
  })

export const updateResult: (params: IPostActionParams) => IAsyncAction = (
  params,
) =>
  createAsyncAction({
    type: ActionTypes.UPDATE_RESULT,
    endpoint: PostsEndpoints
.updateResult,
    payload: params,
  })

export const uploadResultImage: (params: IPostActionParams) => IAsyncAction = (
  params,
) =>
  createAsyncAction({
    type: ActionTypes.UPLOAD_RESULT_IMAGE,
    endpoint: PostsEndpoints
.uploadResultImage,
    payload: params,
  })

export const deleteResult: (params: IPostActionParams) => IAsyncAction = (
  params,
) =>
  createAsyncAction({
    type: ActionTypes.DELETE_RESULT,
    endpoint: PostsEndpoints.deleteResult,
    payload: params,
  })

export const addOption: (params: IPostActionParams) => IAsyncAction = (
  params,
) => createAsyncAction({ type: ActionTypes.ADD_OPTION, endpoint: PostsEndpoints.addOption, payload: params})

export const getOptionById: (params: IPostActionParams) => IAsyncAction = (
  params,
) =>
  createAsyncAction({
    type: ActionTypes.GET_OPTION_BY_ID,
    endpoint: PostsEndpoints
.getOptionById,
    payload: params,
  })

export const updateOption: (params: IPostActionParams) => IAsyncAction = (
  params,
) =>
  createAsyncAction({
    type: ActionTypes.UPDATE_OPTION,
    endpoint: PostsEndpoints
.updateOption,
    payload: params,
  })

export const uploadOptionImage: (params: IPostActionParams) => IAsyncAction = (
  params,
) =>
  createAsyncAction({
    type: ActionTypes.UPLOAD_OPTION_IMAGE,
    endpoint: PostsEndpoints
.uploadOptionImage,
    payload: params,
  })

export const deleteOption: (params: IPostActionParams) => IAsyncAction = (
  params,
) =>
  createAsyncAction({
    type: ActionTypes.DELETE_OPTION,
    endpoint: PostsEndpoints
.deleteOption,
    payload: params,
  })

export const addAnswer: (params: IPostActionParams) => IAsyncAction = (
  params,
) => createAsyncAction({ type: ActionTypes.ADD_ANSWER, endpoint: PostsEndpoints.addAnswer, payload: params })

export const getAnswerById: (params: IPostActionParams) => IAsyncAction = (
  params,
) =>
  createAsyncAction({
    type: ActionTypes.GET_ANSWER_BY_ID,
    endpoint: PostsEndpoints
.getAnswerById,
    payload: params,
  })

export const updateAnswer: (params: IPostActionParams) => IAsyncAction = (
  params,
) =>
  createAsyncAction({
    type: ActionTypes.UPDATE_ANSWER,
    endpoint: PostsEndpoints
.updateAnswer,
    payload: params,
  })

export const uploadanswerImage: (params: IPostActionParams) => IAsyncAction = (
  params,
) =>
  createAsyncAction({
    type: ActionTypes.UPLOAD_ANSWER_IMAGE,
    endpoint: PostsEndpoints
.uploadanswerImage,
    payload: params,
  })

export const deleteAnswer: (params: IPostActionParams) => IAsyncAction = (
  params,
) =>
  createAsyncAction({
    type: ActionTypes.DELETE_ANSWER,
    endpoint: PostsEndpoints
.deleteAnswer,
    payload: params,
  })

export const addAnswerOptionMatch: (
  params: IPostActionParams,
) => IAsyncAction = (params) =>
  createAsyncAction({
    type: ActionTypes.ADD_ANSWER_OPTION_MATCH,
    endpoint: PostsEndpoints
.addAnswerOptionMatch,
    payload: params,
  })

export const getAnswerOptionMatchById: (
  params: IPostActionParams,
) => IAsyncAction = (params) =>
  createAsyncAction({
    type: ActionTypes.GET_ANSWER_OPTION_MATCH_BY_ID,
    endpoint: PostsEndpoints
.getAnswerOptionMatchById,
    payload: params,
  })

export const updateAnswerOptionMatch: (
  params: IPostActionParams,
) => IAsyncAction = (params) =>
  createAsyncAction({
    type: ActionTypes.UPDATE_ANSWER_OPTION_MATCH,
    endpoint: PostsEndpoints
.updateAnswerOptionMatch,
    payload: params,
  })

export const deleteAnswerOptionMatch: (
  params: IPostActionParams,
) => IAsyncAction = (params) =>
  createAsyncAction({
    type: ActionTypes.DELETE_ANSWER_OPTION_MATCH,
    endpoint: PostsEndpoints
.deleteAnswerOptionMatch,
    payload: params,
  })

export const getPostStatuses: () => IAsyncAction = () =>
  createAsyncAction({
    type: ActionTypes.GET_POST_STATUSES,
    endpoint: PostsEndpoints.getPostStatuses,
    payload: null,
  })

export const getAutocompleteSearchPosts: <TActionParamsType = IPostActionParams>(
  params: TActionParamsType,
) => IAsyncAction = (params) =>
  createAsyncAction({
    type: ActionTypes.GET_AUTOCOMPLETE_SEARCH_POSTS,
    endpoint: PostsEndpoints
      .getPosts,
          payload: params,
        })