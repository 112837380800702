import { RequestMethod } from 'types/common/api/enums/request-method'
import params from './params'
import paths from './paths'

const MediaItemEndpoints = {
  getMediaItemById: {
    method: RequestMethod.GET,
    path: [paths.mediaItems, params.mediaItemId],
  },
  updateMediaItemTransformData: {
    method: RequestMethod.PUT,
    path: [paths.mediaItems, params.mediaItemId, paths.transform],
  },
  uploadMediaItemImage: {
    method: RequestMethod.POST,
    path: [paths.mediaItems, paths.uploadImage],
  },
  updateMediaItemImage: {
    method: RequestMethod.PUT,
    path: [paths.mediaItems, params.mediaItemId, paths.image],
  },
  createPresignedUrl: {
    method: RequestMethod.POST,
    path: [paths.mediaItems, paths.presigned],
  }
}

export default MediaItemEndpoints
