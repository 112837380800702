import { Box, Button, Portal, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { UndoIcon, SaveIcon } from 'components/icons'
import { useFormik } from 'formik'
import { PostDetailsSchema } from 'modules/posts/validation/post-details-schema'
import { PostDetailsView } from 'modules/posts/views/components/manage-post-panel/post-details-view'
import React, { useEffect, useRef } from 'react'
import { PostField as FieldName } from 'types/modules/posts/enums/post-field'
import { IPost } from 'types/modules/posts/models/entities/post'
import { IPostDetails, PostPricingModel, PostSettlePeriod } from 'types/modules/posts/models/entities/post-details'
import { Tag } from 'types/modules/tags/models/entities/tag'
import _ from 'core/utils/deepdash'
import { useAuth } from 'core/authentication/hooks/use-auth'
import moment from 'moment-timezone'
import { titleCase } from 'core/utils/titleCase'
import { IPostDetailsScreenProps } from 'types/modules/posts/models/props/post-details-screen-props'
import { PostFeedPreview } from 'modules/app/views/components/previews/post-feed'
import { usePostManagerContext } from 'modules/posts/context/post-manager'
import { DisplayMode } from 'types/common/enums/display-mode'
import { NIL } from 'uuid'
import { usePostListContext } from 'modules/posts/context/post-list'
import { PostAcceptedCurrency } from 'fe-shared-resources';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentPanel: {
      position: 'relative',
      display: 'flex',
      flex: 1,
      padding: '1.25rem',
      overflow: 'auto',
      borderRight: '1px solid rgba(0,0,0,0.12)',
      '&:last-child': {
        border: 'none',
      },
    },
    updateButton: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
      marginRight: '0.625rem',
    },
    resetButton: {
      color: theme.palette.text.primary,
      backgroundColor: '#ecf0f1',
      marginLeft: '0.625rem',
      '&:hover': {
        backgroundColor: '#f2f5f5',
      },
    },
    metadataContainer: {
      position: 'absolute',
      display: 'flex',
      flexDirection: 'column',
      bottom: '1.25rem',
      right: '1.25rem',
    },
    metadata: {
      display: 'flex',
      fontSize: '0.7rem',
      justifyContent: 'flex-end',
    },
  }),
)

export const PostDetailsScreen: React.FC<IPostDetailsScreenProps> = ({
  mainContentPanelRef,
  dynamicContentPanelRef,
  formButtonFooterContainerRef,
  renderPreview,
  disableButtons,
}: IPostDetailsScreenProps) => {
  const { currentUser } = useAuth()
  const classes = useStyles()

  const { currentPost, initialTags, postGroupId } = usePostListContext()
  const post = currentPost as IPost
  const { updatePost, updatingPost, createPost, isOrderRepeated, reorderQuestions } = usePostManagerContext()
  const previousUpdatingValue = useRef(updatingPost)
  const displayMode = useRef(
    post?.id !== NIL ? DisplayMode.Edit : DisplayMode.Create,
  ).current


  const postDetailsForm = useFormik({
    enableReinitialize: displayMode === DisplayMode.Edit,
    initialValues: {
      [FieldName.ID]: post.id,
      [FieldName.BET_CARD]: post.betCard,
      [FieldName.CLOSE]: post.close,
      [FieldName.COINS_WIN]: post.coinsWin,
      [FieldName.DIFFICULTY]: post.difficulty,
      [FieldName.DISPLAY_IF_LOCKED]: post.displayIfLocked,
      [FieldName.EVENT_ID]: post.eventId ?? '',
      [FieldName.FEATURED]: post.featured,
      [FieldName.MAX_BET]: post.maxBet,
      [FieldName.MULTIPLIER_MID]: post.multiplierMid,
      [FieldName.MULTIPLIER_MAX]: post.multiplierMax,
      [FieldName.MEDIA]: post.media ?? {},
      [FieldName.MIN_BET]: post.minBet,
      [FieldName.PAYOUT_CAP]: post.payoutCap,
      [FieldName.PUBLIC]: post.public,
      [FieldName.SKIP_MAIN_FEED]: post.skipMainFeed,
      [FieldName.STARS_WIN]: post.starsWin,
      [FieldName.START]: post.start,
      [FieldName.CHECK_BY_DATE]: post.checkByDate,
      [FieldName.STATUS]: post.status,
      [FieldName.LIVE_SCHEDULE_ID]: post.liveScheduleId ?? '',
      [FieldName.TAGS]: displayMode === DisplayMode.Edit
        ? post.tags
        : initialTags || (post.tagIds || []).map(tagId => ({ id: tagId})) || post.tags || [],
      [FieldName.TAG_IDS]: post.tagIds,
      [FieldName.TIMED]: post.timed,
      [FieldName.TITLE]: post.title,
      [FieldName.TYPE]: post.type,
      [FieldName.WATCHLIST]: post.watchlist,
      [FieldName.POST_PRIZES]: post.postPrizes,
      postPricingModel: post.postPricingModel,
      pricingModelInAppPurchaseCode: post.pricingModelInAppPurchaseCode || '',
      isDailyStack: !!post.isDailyStack,
      countries: post.countries || [],
      shouldBeSettledBy: post.shouldBeSettledBy,
      groups: postGroupId ? [postGroupId] : (post.groups || []),
      settlePeriod: post?.settlePeriod || PostSettlePeriod.MANUAL,
      acceptedCurrency: post?.acceptedCurrency || PostAcceptedCurrency.Coin
    },
    validationSchema: PostDetailsSchema,
    onSubmit: (values: IPostDetails): void => {
      const newPost = _.cloneDeep(values)
      newPost.updatedBy = currentUser.username
      newPost.title = newPost.title.trim().replace('  ', ' ')
      newPost.tagIds = (newPost.tags as Tag[]).map((tag) => tag.id!)
      if (displayMode === DisplayMode.Create) {
        createPost(newPost)
        return
      }
      updatePost(newPost as IPost, true)
    },
  })

  useEffect(() => {
    disableButtons(postDetailsForm.dirty)
  }, [postDetailsForm.dirty])

  useEffect(() => {
    const values = postDetailsForm.values
    if (previousUpdatingValue.current && !updatingPost) {
      postDetailsForm.resetForm({
        values,
      })
    }
    previousUpdatingValue.current = updatingPost
  }, [updatingPost])


  const renderFormButtons = (): JSX.Element => {
    return (
      <Portal container={formButtonFooterContainerRef}>
        <Box>
          <Button
            variant="contained"
            startIcon={<UndoIcon />}
            className={classes.resetButton}
            size="small"
            onClick={(): void => {
              postDetailsForm.resetForm()
            }}
            disabled={!postDetailsForm.dirty}
          >
            Undo
          </Button>
          <Button
            variant="contained"
            className={classes.resetButton}
            size="small"
            onClick={reorderQuestions}
            disabled={!isOrderRepeated || updatingPost}
          >
            Reorder questions
          </Button>
        </Box>
        <Button
          variant="contained"
          startIcon={<SaveIcon />}
          className={classes.updateButton}
          size="small"
          onClick={(): void => {
            postDetailsForm.submitForm()
          }}
          disabled={!postDetailsForm.dirty}
        >
          Save
        </Button>
      </Portal>
    )
  }

  const liveUpdatePost: IPost = {
    ...post,
    ...postDetailsForm.values,
  }

  return (
    <React.Fragment>
      {renderFormButtons()}
      <Portal container={mainContentPanelRef}>
        <PostDetailsView
          {...{
            post,
            postDetails: postDetailsForm.values,
            handleChange: postDetailsForm.handleChange,
            setFieldValue: postDetailsForm.setFieldValue,
            setFieldTouched: postDetailsForm.setFieldTouched,
            errors: postDetailsForm.errors,
            setValues: postDetailsForm.setValues,
          }}
        />
      </Portal>
      {renderPreview && (
        <Portal container={dynamicContentPanelRef}>
          <PostFeedPreview {...liveUpdatePost} />
          <Box className={classes.metadataContainer}>
            <Typography color="textPrimary" className={classes.metadata}>
              {`Created By: ${titleCase(post.createdBy ?? 'Unknown')}`}
            </Typography>
            <Typography color="textPrimary" className={classes.metadata}>
              {`Updated By: ${titleCase(post.updatedBy ?? 'Unknown')}`}
            </Typography>
            <Typography color="textPrimary" className={classes.metadata}>
              {`${moment(post.updatedAt).format('Do MMMM YYYY, h:mm A')}`}
            </Typography>
          </Box>
        </Portal>
      )}
    </React.Fragment>
  )
}
