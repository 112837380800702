import React from 'react'
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Box, Button, ButtonProps, Grid } from '@mui/material'
import { NIL as NIL_UUID } from 'uuid'
import { PanelContent, PanelFooter } from 'components/panel'
import _ from 'core/utils/deepdash'
import { useFormik } from 'formik'
import { colors } from 'utils/colors'
import { useQuestsContext } from 'modules/quests/context/quest-context'
import {
  ParameterTypes,
  QuestField,
  QuestFormTypes,
  QuestMediaField,
  QuestRewardTypes,
} from 'modules/quests/enums/quest-field'
import { IQuest } from 'modules/quests/models/quest'
import { QuestForm } from 'modules/quests/view/quests/quest-modal/quest-form'
import { useRewardsAndTasksHandlers } from 'modules/quests/hooks/use-rewards-and-tasks-handlers'
import { TaskAndRewardForms } from 'modules/quests/view/quests/quest-modal/task-and-reward-forms'
import { useTypedSelector } from 'core/redux/utils'
import { Selectors } from 'modules/app-settings'
import { QuestSchema } from 'modules/quests/validation/quest-schema'
import { ModalWrapper } from 'components/modal-wrapper'
import { TASK_CODES_WITH_PARAMS } from 'modules/quests/constants'
import { ConfirmationDialog } from 'components/confirmation-dialog'
import clsx from 'clsx'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
    },
    footerContent: {
      display: 'flex',
      flex: 1,
      justifyContent: 'space-between',
    },
    cancelBtn: {
      marginRight: '1.25rem',
    },
    wrapper: {
      borderLeft: `1px solid ${colors.rgba_0_0_0_12}`,
    },
    form: {
      height: '600px',
      overflow: 'auto',
    },
    flexEnd: {
      justifyContent: 'flex-end',
    },
    root: {
      color: theme.palette.text.primary,
      backgroundColor: '#ecf0f1',
      '&:hover': {
        backgroundColor: '#f2f5f5',
      },
    },
  }),
)

export const QuestModal: React.FC = () => {
  const classes = useStyles()
  const {
    currentQuest,
    updateQuest,
    createQuest,
    clearCurrentQuest,
    activateQuest,
    deactivateQuest,
  } = useQuestsContext()
  const isUpdating = currentQuest?.id !== NIL_UUID

  const buttonProps: ButtonProps = {
    variant: 'contained',
    className: clsx(classes.root),
    size: 'small',
  }

  const form = useFormik({
    enableReinitialize: isUpdating,
    validationSchema: QuestSchema,
    initialValues: {
      [QuestField.ID]: currentQuest?.[QuestField.ID] ?? '',
      [QuestField.QUEST_TEXT]: currentQuest?.[QuestField.QUEST_TEXT] ?? '',
      [QuestField.SCHEDULE]: currentQuest?.[QuestField.SCHEDULE] || '',
      [QuestField.SET]: currentQuest?.[QuestField.SET] ?? 0,
      [QuestField.QUEST_REWARDS]:
        currentQuest?.[QuestField.QUEST_REWARDS] ?? [],
      [QuestField.ACTIVE]: currentQuest?.[QuestField.ACTIVE] ?? false,
      [QuestField.QUEST_TASKS]: currentQuest?.[QuestField.QUEST_TASKS] ?? [],
      [QuestField.MEDIA]: {
        [QuestMediaField.IMAGE_URL]:
          currentQuest?.[QuestField.MEDIA]?.[QuestMediaField.IMAGE_URL] ?? '',
        [QuestMediaField.DESCRIPTION]:
          currentQuest?.[QuestField.MEDIA]?.[QuestMediaField.DESCRIPTION] ?? '',
        [QuestMediaField.MEDIA_ITEM_ID]:
          currentQuest?.[QuestField.MEDIA]?.[QuestMediaField.MEDIA_ITEM_ID] ??
          '',
        [QuestMediaField.TITLE]:
          currentQuest?.[QuestField.MEDIA]?.[QuestMediaField.TITLE] ?? '',
        location: currentQuest?.media?.location ?? '',
      },
    },
    onSubmit: (value: IQuest) => {
      const request = isUpdating ? updateQuest : createQuest
      request({
        ...value,
        description:
          value?.[QuestField.MEDIA]?.[QuestMediaField.DESCRIPTION] ?? '',
        location: value?.[QuestField.MEDIA]?.location ?? '',
        title: value?.[QuestField.MEDIA]?.[QuestMediaField.TITLE] ?? '',
        questRewards: value.questRewards.map((reward) => ({
          ...reward,
          id: reward.id?.includes('new') ? undefined : reward.id,
          amount: reward.type === QuestRewardTypes.CURRENCY ? reward.amount : 1,
          currency:
            reward.type === QuestRewardTypes.CURRENCY
              ? reward.currency
              : undefined,
          prizeId:
            reward.type === QuestRewardTypes.PRIZE ? reward.prizeId : undefined,
        })),
        questTasks: value.questTasks.map((tasks) => ({
          ...tasks,
          id: tasks.id?.includes('new') ? undefined : tasks.id,
          parameters:
            TASK_CODES_WITH_PARAMS[tasks.code] === ParameterTypes.TAGS
              ? Object.values(tasks.parameters || {}).reduce((acc, param) => {
                  return { ...acc, [`TagId${param}`]: param }
                }, {})
              : tasks.parameters,
        })),
      })
    },
  })

  const {
    add: addTask,
    setValue: setTasksValue,
    remove: removeTask,
  } = useRewardsAndTasksHandlers({
    quest: form.values,
    setFieldValue: form.setFieldValue,
    key: QuestField.QUEST_TASKS,
  })

  const {
    add: addReward,
    setValue: setRewardsValue,
    remove: removeReward,
  } = useRewardsAndTasksHandlers({
    quest: form.values,
    setFieldValue: form.setFieldValue,
    key: QuestField.QUEST_REWARDS,
  })

  const enumsAreFetched = useTypedSelector((state) =>
    Selectors.getIsFetched(state),
  )
  if (!enumsAreFetched) return null

  const buttonLabel = !currentQuest?.active ? 'activate' : 'deactivate'

  return (
    <ModalWrapper
      panelSize={11}
      closeModal={clearCurrentQuest}
      title={
        isUpdating
          ? `Edit ${currentQuest?.media?.title} (${
              currentQuest?.active ? 'Activated' : 'Deactivated'
            })`
          : 'Create quest'
      }
      open={!!currentQuest}
    >
      <>
        <PanelContent>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <QuestForm
                quest={form.values}
                handleChange={form.handleChange}
                setFieldTouched={form.setFieldTouched}
                setFieldValue={form.setFieldValue}
                errors={form.errors}
                isUpdating={isUpdating}
              />
            </Grid>
            <Grid item xs={5} className={classes.form}>
              <TaskAndRewardForms
                rewards={form.values.questRewards}
                add={addReward}
                remove={removeReward}
                setValue={setRewardsValue}
                type={QuestFormTypes.REWARDS}
                label="Rewards"
                errors={form.errors[QuestField.QUEST_REWARDS] || []}
              />
            </Grid>
            <Grid item xs={4} className={classes.form}>
              <TaskAndRewardForms
                tasks={form.values.questTasks}
                add={addTask}
                remove={removeTask}
                setValue={setTasksValue}
                type={QuestFormTypes.TASKS}
                label="Tasks"
                errors={form.errors[QuestField.QUEST_TASKS] || []}
              />
            </Grid>
          </Grid>
        </PanelContent>
        <PanelFooter>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box
                className={`${classes.footerContent} ${
                  !isUpdating ? classes.flexEnd : ''
                }`}
              >
                {isUpdating && (
                  <ConfirmationDialog
                    popoverId={`quest-${currentQuest?.id}`}
                    buttonProps={buttonProps}
                    onConfirmation={(): void =>
                      (!currentQuest?.active ? activateQuest : deactivateQuest)(
                        currentQuest?.id,
                      )
                    }
                    confirmationText={`Are you sure you want to ${buttonLabel} this quest?`}
                    buttonLabel={buttonLabel}
                  />
                )}
                <Box>
                  <Button
                    size="large"
                    className={classes.cancelBtn}
                    onClick={clearCurrentQuest}
                    variant="contained"
                    color="secondary"
                  >
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    size="large"
                    onClick={form.submitForm}
                    variant="contained"
                    disabled={form.dirty && !form.isValid}
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </PanelFooter>
      </>
    </ModalWrapper>
  )
}
