import { RootSagaReturnType } from 'core/redux/rootSaga'
import { ActionTypes } from 'modules/prizes'
import { GetTagsSaga } from 'modules/tags/sagas/get-tags-saga'
import { all, takeEvery, takeLatest } from 'redux-saga/effects'

const enabledAsyncActions = [
  ActionTypes.GET_PRIZE_STATUSES,
  ActionTypes.GET_PRIZE_BY_ID,
  ActionTypes.CREATE_PRIZE,
  ActionTypes.UPDATE_PRIZE,
  ActionTypes.UPDATE_PRIZE_STATUS,
  ActionTypes.PROMOTE_PRIZE,
  ActionTypes.DELETE_PRIZE,
  ActionTypes.CLEAR_TRASH,
  ActionTypes.AUTOCOMPLETE_PRIZE_SEARCH,
  ActionTypes.UPLOAD_PRIZE_IMAGE,
  ActionTypes.UPLOAD_FEATURED_IMAGE,
  ActionTypes.UPLOAD_LOGO,
  ActionTypes.DUPLICATE_PRIZE,
]

export const RootSaga = function* (): RootSagaReturnType {
  yield all([
    yield takeEvery(enabledAsyncActions, GetTagsSaga),
    yield takeLatest(ActionTypes.GET_PRIZES, GetTagsSaga),
  ])
}
