import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { RequestContextProvider } from 'core/api/context'
import { PostContext } from 'modules/posts/context'
import { WatchlistContextProvider } from 'modules/watchlist/context'
import { WatchlistCategoryScreen } from 'modules/watchlist/views/components/category-screen'
import React from 'react'
import { FilterOperator } from 'types/common/filtering/enums/FilterOperator'
import { FilterType } from 'types/common/filtering/enums/FilterType'
import { IFilter } from 'types/common/filtering/models/entities/IFilter'
import { PostField } from 'types/modules/posts/enums/post-field'
import PostStatus from 'types/modules/posts/enums/post-status'

const useStyles = makeStyles((theme: Theme) => createStyles({}))

export const WatchlistCategoryPage: React.FC<{}> = () => {
  const classes = useStyles()

  const initialFilters: IFilter[] = [
    {
      field: PostField.WATCHLIST,
      type: FilterType.Switch,
      operator: FilterOperator.Equals,
      value: true,
    },
    {
      field: PostField.STATUS,
      type: FilterType.Select,
      operator: FilterOperator.Equals,
      value: [PostStatus.Paused, PostStatus.Active],
    },
  ]

  return (
    <RequestContextProvider {...{ initialFilters }}>
      <PostContext>
        <WatchlistContextProvider>
          <WatchlistCategoryScreen />
        </WatchlistContextProvider>
      </PostContext>
    </RequestContextProvider>
  )
}
