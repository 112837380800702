import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { ActivateInfoCardButton } from 'modules/info-cards/views/components/action-buttons/activate'
import { DeactivateInfoCardButton } from 'modules/info-cards/views/components/action-buttons/deactivate'
import { DiscardInfoCardButton } from 'modules/info-cards/views/components/action-buttons/discard'
import { EditInfoCardButton } from 'modules/info-cards/views/components/action-buttons/edit'
import { RestoreInfoCardButton } from 'modules/info-cards/views/components/action-buttons/restore'
import React, { ComponentType } from 'react'
import { InfoCardActionButtonType } from 'types/modules/info-cards/enums/info-card-action-button-type'
import { IInfoCardActionButtonProps } from 'types/modules/info-cards/models/props/info-card-action-button-props'
import { IInfoCardActionButtonsProps } from 'types/modules/info-cards/models/props/info-card-action-buttons-props'

export const InfoCardActionButtons: React.FC<IInfoCardActionButtonsProps> = (
  props: IInfoCardActionButtonsProps,
) => {
  const { infoCard, exclude, disabled } = props

  const renderButtons = (): JSX.Element => {
    const buttonsToRender: [
      InfoCardActionButtonType,
      ComponentType<IInfoCardActionButtonProps>,
    ][] = []

    Object.values(InfoCardActionButtonType).forEach((buttonType) => {
      if (!IsNullOrUndefined(exclude) && exclude?.includes(buttonType)) {
        return
      }

      let currentButton: ComponentType<IInfoCardActionButtonProps> | null = null

      switch (buttonType) {
        case InfoCardActionButtonType.Edit: {
          currentButton = EditInfoCardButton
          break
        }
        case InfoCardActionButtonType.Activate: {
          currentButton = ActivateInfoCardButton
          break
        }
        case InfoCardActionButtonType.Deactivate: {
          currentButton = DeactivateInfoCardButton
          break
        }
        case InfoCardActionButtonType.Discard: {
          currentButton = DiscardInfoCardButton
          break
        }
        case InfoCardActionButtonType.Restore: {
          currentButton = RestoreInfoCardButton
          break
        }
      }
      if (!IsNullOrUndefined(currentButton)) {
        buttonsToRender.push([buttonType, currentButton!])
      }
    })

    return (
      <React.Fragment>
        {buttonsToRender.map(([buttonType, Component]) => (
          <Component key={buttonType} infoCard={infoCard} disabled={disabled} />
        ))}
      </React.Fragment>
    )
  }

  return !IsNullOrUndefined(infoCard) ? renderButtons() : null
}
