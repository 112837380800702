import PostGroupEndpoints from 'core/api/endpoints/post-groups'
import PostGroupActionTypes from 'modules/post-groups/action-types'
import { createAction, createAsyncAction } from 'core/redux/utils'
import { IAsyncAction, ISimpleAction } from 'types/redux/interfaces/IAction'
import { IAsyncActionParams } from 'types/common/api/models/entities/async-action-params'
import { Version } from 'types/common/enums/versions'
import { PostGroup } from 'fe-shared-resources'

export interface IPostGroupActionParams extends IAsyncActionParams<PostGroup> {
  postGroupId?: string
}

export const setCurrentPostGroupId: (params: IPostGroupActionParams) => ISimpleAction = (
  params,
) => createAction(PostGroupActionTypes.SET_CURRENT_POST_GROUP_ID, params)


export const clearPostGroupPost: (params: IPostGroupActionParams) => ISimpleAction = (
  params,
) => createAction(PostGroupActionTypes.CLEAR_POST_GROUP_POSTS, params)

export const getPostGroups: (params: IPostGroupActionParams) => IAsyncAction = (params) =>
  createAsyncAction({
    type: PostGroupActionTypes.GET_POST_GROUPS,
    endpoint: PostGroupEndpoints.getPostGroups,
    payload: params,
    version: Version.V2,
  })

export const getPostGroupById: (params: IPostGroupActionParams) => IAsyncAction = (
  params,
) =>
  createAsyncAction({
    type: PostGroupActionTypes.GET_POST_GROUP_BY_ID,
    endpoint: PostGroupEndpoints.getPostGroupById,
    payload: params,
    version: Version.V2
  })

export const createPostGroup: (params: IPostGroupActionParams) => IAsyncAction = (params) =>
  createAsyncAction({
    type: PostGroupActionTypes.CREATE_POST_GROUP,
    endpoint: PostGroupEndpoints.createPostGroup,
    payload: params,
    version: Version.V2
  })

export const createPostGroupDraft: (params: IPostGroupActionParams) => ISimpleAction = (
  params,
) => createAction(PostGroupActionTypes.CREATE_POST_GROUP_DRAFT, params)

export const updatePostGroup: (params: IPostGroupActionParams) => IAsyncAction = (params) =>
  createAsyncAction({
    type: PostGroupActionTypes.UPDATE_POST_GROUP,
    endpoint: PostGroupEndpoints.updatePostGroup,
    payload: params,
    version: Version.V2
  })

export const settlePostGroup: (params: IPostGroupActionParams) => IAsyncAction = (params) =>
  createAsyncAction({
    type: PostGroupActionTypes.SETTLE_POST_GROUP,
    endpoint: PostGroupEndpoints.settlePostGroup,
    payload: params,
    version: Version.V2
  })

export const getPostGroupPosts: (params: IPostGroupActionParams) => IAsyncAction = (params) =>
  createAsyncAction({
    type: PostGroupActionTypes.GET_POST_GROUP_POSTS,
    endpoint: PostGroupEndpoints.getPostGroupPosts,
    payload: params,
    version: Version.V2
  })
