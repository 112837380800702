
import { RequestContextProvider } from 'core/api/context'
import { Actions } from 'modules/post-groups'
import { PostContext } from 'modules/posts/context'
import { PostsDashboard } from 'modules/posts/views/components/dashboard'
import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { CustomPostList } from 'modules/post-groups/view/components/post-group-dashboard/custom-post-list'
import { PostGroup } from 'fe-shared-resources'

interface IPostGroupPostsProps {
  postGroup?: PostGroup
}

export const PostGroupPosts: React.FC<IPostGroupPostsProps> = (
  props: IPostGroupPostsProps,
) => {
  const dispatch = useDispatch()
  // @ts-expect-error to decide later
  const { postGroupId } = useParams()
  const loadPosts = useCallback(() => {
    dispatch(
      Actions.getPostGroupPosts({
        postGroupId,
      }),
    )
  }, [])

  return (
    <RequestContextProvider>
      <PostContext postGroup={props.postGroup} overrides={{ loadPosts }}>
        <PostsDashboard ListComponent={<CustomPostList />} />
      </PostContext>
    </RequestContextProvider>
  )
}
