import ActionTypes from 'modules/quests/action-types/game-task-action-types'
import { Reducer } from 'redux'
import { actionTypeSuccess, cacheItem, cacheItems } from 'core/redux/utils'
import { ISimpleAction, ISuccessAction } from 'types/redux/interfaces/IAction'
import _ from 'core/utils/deepdash'
import { mergeArrays } from 'core/utils/mergeArrays'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { IGameTaskActionParams } from 'modules/quests/actions/game-task-actions'
import { NIL as NIL_UUID } from 'uuid'
import { GameTaskState } from 'modules/quests/models/state'
import { GameTask, GameTaskStep } from 'fe-shared-resources'

const pageSize = 20

const initialState: GameTaskState = {
  count: 0,
  page: 0,
  pageSize: pageSize,
  items: [],
  cache: {},
  currentGameTaskSteps: []
}

export type GetGameTasksApiResponse = {
  count: number
  page: number
  pageSize: number
  items: GameTask[]
}

type AllowedActionTypes = ISuccessAction | ISimpleAction

const handleUpdate = (state: GameTaskState,
    action: AllowedActionTypes): GameTaskState => {
  const successAction = action as ISuccessAction
    const payloadItem = successAction.data as GameTask
    const itemIndex = state.items.findIndex((x) => x.id === payloadItem?.id)

    return {
      ...state,
      items:
        itemIndex >= 0
          ? state.items.map((item, index) =>
              index === itemIndex ? payloadItem : item,
            )
          : [...state.items, payloadItem],
      cache: cacheItem(state.cache, payloadItem),
    }
}

const actionHandlers = {
  [ActionTypes.SET_CURRENT_GAME_TASK_ID]: (
    state: GameTaskState,
    action: AllowedActionTypes,
  ): GameTaskState => {
    const simpleAction = action as ISimpleAction
    const { gameTaskId } =
      simpleAction.payload as IGameTaskActionParams
    return {
      ...state,
      currentGameTaskId: gameTaskId ? gameTaskId : undefined,
      currentGameTaskSteps: []
    }
  },
  [ActionTypes.CREATE_GAME_TASK_DRAFT]: (
    state: GameTaskState,
    action: AllowedActionTypes,
  ): GameTaskState => {
    const simpleAction = action as ISimpleAction
    const { item } = simpleAction.payload as IGameTaskActionParams

    return {
      ...state,
      currentGameTaskId: NIL_UUID,
      items: [item as GameTask, ...state.items],
    }
  },
  [actionTypeSuccess(ActionTypes.GET_GAME_TASKS)]: (
    state: GameTaskState,
    action: AllowedActionTypes,
  ): GameTaskState => {
    const successAction = action as ISuccessAction
    const response = successAction.data as { items: GameTask[] }
    return {
      ...state,
      items: mergeArrays(state.items, response.items),
      cache: cacheItems(state.cache, response.items),
    }
  },
  [actionTypeSuccess(ActionTypes.GET_GAME_TASK_STEPS)]: (
    state: GameTaskState,
    action: AllowedActionTypes,
  ): GameTaskState => {
    const successAction = action as ISuccessAction
    const response = successAction.data as { items: GameTaskStep[] }
    return {
      ...state,
      currentGameTaskSteps: response.items
    }
  },
  [actionTypeSuccess(ActionTypes.GET_GAME_TASK_BY_ID)]: (
    state: GameTaskState,
    action: AllowedActionTypes,
  ): GameTaskState => {
    const successAction = action as ISuccessAction
    const { value } = successAction.data as { value: GameTask }
    return {
      ...state,
      cache: cacheItem(state.cache, value),
    }
  },
  [actionTypeSuccess(ActionTypes.CREATE_GAME_TASK)]: (
    state: GameTaskState,
    action: AllowedActionTypes,
  ): GameTaskState => {
    const successAction = action as ISuccessAction
    const responseItem = successAction.data as GameTask

    const { items } = state

    return {
      ...state,
      items: [responseItem, ...items],
      cache: cacheItem(state.cache, responseItem),
    }
  },
  [actionTypeSuccess(ActionTypes.UPDATE_GAME_TASK)]: (
    state: GameTaskState,
    action: AllowedActionTypes,
  ): GameTaskState => handleUpdate(state, action),
  [actionTypeSuccess(ActionTypes.CREATE_GAME_TASK_STEP)]: (
    state: GameTaskState,
    action: AllowedActionTypes,
  ): GameTaskState => {
    const successAction = action as ISuccessAction
    const response = successAction.data as GameTaskStep
    return {
      ...state,
      currentGameTaskSteps: [...state.currentGameTaskSteps, response]
    }
  },
  [actionTypeSuccess(ActionTypes.UPDATE_GAME_TASK_STEP)]: (
    state: GameTaskState,
    action: AllowedActionTypes,
  ): GameTaskState => {
    const successAction = action as ISuccessAction
    const response = successAction.data as GameTaskStep
    return {
      ...state,
      currentGameTaskSteps: state.currentGameTaskSteps.map((step) => {
        if (response.id === step.id) {
          return response
        }
        return step
      })
    }
  },
  [actionTypeSuccess(ActionTypes.UPLOAD_GAME_TASK_IMAGE)]: (
    state: GameTaskState,
    action: AllowedActionTypes,
  ): GameTaskState => handleUpdate(state, action)
}

const gameTasksReducer: Reducer<GameTaskState, AllowedActionTypes> = (
  state = initialState,
  action: AllowedActionTypes,
) => {
  return actionHandlers[action.type]
    ? actionHandlers[action.type](state, action)
    : state
}

export default gameTasksReducer
