import { Box, IconButton, SvgIcon, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx'
import { useCalendarContext } from 'components/calendar/context/calendar-context'
import moment from 'moment-timezone'
import React from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    timeGridHeader: {
      display: 'flex',
      width: '100%',
      height: 80,
      position: 'absolute',
      top: 0,
      background: '#ffffff',
      zIndex: 1,
      overflowY: 'scroll',
    },
    timeColumnCover: {
      display: 'flex',
      width: 'calc(40px + 0.625rem)',
      background: '#ffffff',
      zIndex: 1,
    },
    dateHeading: {
      position: 'relative',
      display: 'flex',
      flex: 1,
      background: 'rgba(0,0,0,0.03)',
      // boxSizing: 'border-box',
      border: '1px solid rgba(0,0,0,0.12)',
      borderLeft: 'none',
      '&:first-child': {
        marginLeft: 'calc(40px + 0.625rem)',
        borderLeft: '1px solid rgba(0,0,0,0.12)',
      },
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'rgba(0,0,0,0.54)',
      '&.active': {
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      },
    },
    dayOfMonth: {},
    dayOfWeek: {},
    actionButton: {
      position: 'absolute',
      left: '0.625rem',
      marginTop: 'auto',
      marginBottom: 'auto',
      color: theme.palette.text.primary,
      '&.white': {
        color: theme.palette.primary.contrastText,
      },
    },
  }),
)

interface ITimeGridHeaderProps {
  actionButton?: {
    icon: JSX.Element
    onClick: (date: string) => void
  }
}

export const TimeGridHeader: React.FC<ITimeGridHeaderProps> = (
  props: ITimeGridHeaderProps,
) => {
  const { actionButton } = props

  const classes = useStyles()

  const { visibleDates } = useCalendarContext()

  return (
    <Box className={classes.timeGridHeader}>
      {/* <Box className={classes.timeColumnCover}></Box> */}
      {visibleDates.map((date) => {
        const dayOfMonth = moment(date).format('D')
        const dayOfWeek = moment(date).format('dddd')
        const isToday = moment().isSame(date, 'day')
        return (
          <Box
            key={`date-heading-${date}`}
            className={clsx(classes.dateHeading, isToday && 'active')}
          >
            {actionButton && (
              <IconButton
                className={clsx(classes.actionButton, isToday && 'white')}
                onClick={(): void => {
                  actionButton.onClick(moment(date).format('YYYY-MM-DD'))
                }}
                size="large">
                <SvgIcon>{actionButton.icon}</SvgIcon>
              </IconButton>
            )}
            <Typography
              component="span"
              variant="h5"
              className={classes.dayOfMonth}
            >
              {dayOfMonth}
            </Typography>
            <Typography
              component="span"
              variant="button"
              className={classes.dayOfWeek}
            >
              {dayOfWeek}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
}
