import { Box, ButtonBase, SvgIcon, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx'
import { ImageIcon } from 'components/icons'
import { usePopover } from 'components/popover/use-popover'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { usePostListContext } from 'modules/posts/context/post-list'
import { PostTimeGridContextMenu } from 'modules/posts/views/components/dashboard/post-calender-time-grid-cell/context-menu'
import moment from 'moment-timezone'
import React from 'react'
import PostStatus from 'types/modules/posts/enums/post-status'
import PostType from 'types/modules/posts/enums/post-type'
import { IPost } from 'types/modules/posts/models/entities/post'
import { Tag } from 'types/modules/tags/models/entities/tag'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    postEvent: {
      display: 'flex',
      flex: '1 1 auto',
      boxSizing: 'border-box',
      flexDirection: 'column',
      height: 80,
      '&.Bet': {
        background: '#ff787d',
        border: '1px solid #ff787d',
      },
      '&.Game': {
        background: '#FDD219',
        border: '1px solid #FDD219',
      },
      '&.Poll': {
        background: '#06B2BB',
        border: '1px solid #06B2BB',
      },
      '&.Test': {
        background: '#F78828',
        border: '1px solid #F78828',
      },
      '&.multiple': {
        background: '#02599D',
        border: '1px solid #02599D',
      },
    },
    eventHeader: {
      display: 'flex',
      height: 15,
      width: '100%',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingLeft: '0.3rem',
      paddingRight: '0.3rem',
    },
    headerText: {
      fontSize: '0.7rem',
      color: theme.palette.primary.contrastText,
      '&.textPrimary': {
        color: theme.palette.text.primary,
      },
    },
    eventContent: {
      display: 'flex',
      flex: 1,
      width: '100%',
      height: 'calc(100% - 15px)',
      background: 'rgba(255,255,255,0.60)',
      padding: '0.3rem',
      '&.darker': {
        background: 'rgba(255,255,255,0.30)',
      },
    },
    coverImageContainer: {
      display: 'flex',
      flex: 1,
      borderRadius: 4,
      background: '#f7f7f7',
      color: 'rgba(0,0,0,0.54)',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      justifyContent: 'center',
      alignItems: 'center',
    },
    postDetailsContainer: {
      display: 'flex',
      flex: 2,
    },
    postTitle: {
      fontSize: '0.7rem',
      color: theme.palette.text.primary,
      overflow: 'hidden',
      textAlign: 'left',
    },
  }),
)

interface ITimeGridPostProps {
  post: IPost
  index: number
  dateField: string
  onClick?: () => void
}

export const TimeGridPost: React.FC<ITimeGridPostProps> = (
  props: ITimeGridPostProps,
) => {
  const { post, index, dateField } = props
  const { setCurrentPost } = usePostListContext()

  const classes = useStyles()

  const hasCoverImage = !IsNullOrUndefined(post.media?.imageUrl)
  const postCoverContainerStyles: { [key: string]: string | number } = {}

  if (hasCoverImage) {
    postCoverContainerStyles.backgroundImage = `url(${post.media!.imageUrl!})`
  } else {
    postCoverContainerStyles.background = '#f7f7f7'
  }

  const postStatus = post.status === PostStatus.Created ? 'Draft' : post.status

  const contextMenu = usePopover({
    id: `context-menu-post-${post.id}`,
    anchorReference: 'anchorPosition',
  })

  return (
    <React.Fragment>
      <ButtonBase
        key={post.id}
        className={clsx(classes.postEvent, post.type)}
        onClick={(): void => {
          props.onClick && props.onClick()
          // handleMultipostPopoverClose();
          setCurrentPost(post.id, post.type)
        }}
        onContextMenu={(event: React.MouseEvent<HTMLButtonElement>): void => {
          event.preventDefault()
          contextMenu.onClick(event)
        }}
      >
        <Box className={classes.eventHeader}>
          <Typography
            component="span"
            variant="button"
            className={clsx(
              classes.headerText,
              post.type === PostType.Game && 'textPrimary',
            )}
          >
            {moment((post as { [key: string]: any })[dateField]).format(
              'h:mm A',
            )}
          </Typography>
          <Typography
            component="span"
            variant="button"
            className={clsx(
              classes.headerText,
              post.type === PostType.Game && 'textPrimary',
            )}
          >
            {`${post.type} | ${postStatus}`}
          </Typography>
        </Box>
        <Box className={classes.eventContent}>
          <Box
            className={classes.coverImageContainer}
            style={postCoverContainerStyles}
          >
            {!hasCoverImage && (
              <SvgIcon>
                <ImageIcon />
              </SvgIcon>
            )}
          </Box>
          <Box className={classes.postDetailsContainer}>
            <Typography
              component="span"
              variant="body2"
              className={clsx(
                classes.postTitle,
                post.type === PostType.Game && 'textPrimary',
              )}
            >
              {`${
                (post.tags as Tag[]).find((tag) => tag.level === 0)?.media?.icon
              } ${post.title}`}
            </Typography>
          </Box>
        </Box>
      </ButtonBase>
      <PostTimeGridContextMenu
        popoverProps={contextMenu.popoverProps}
        dateTime={moment((post as { [key: string]: any })[dateField])
          .utc()
          .startOf('hour')
          .toISOString()}
        dateField={dateField}
        post={post}
      />
    </React.Fragment>
  )
}
