import ContentDashboard from 'components/content-dashboard'
import { DashboardContextProvider } from 'components/content-dashboard/context'
import { IContentDashboardProps } from 'components/content-dashboard/props/content-dashboard-props'
import { useRequestContext } from 'core/api/context'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import React, { useCallback, useEffect } from 'react'
import { PostField } from 'types/modules/posts/enums/post-field'
import _ from 'core/utils/deepdash'
import SortDirection from 'types/common/enums/sort-direction'
import { IFilter } from 'types/common/filtering/models/entities/IFilter'
import { SelectFilterMode } from 'types/common/filtering/enums/SelectFilterMode'
import { ImageIcon } from 'components/icons'
import { useRouter } from 'core/routing/hooks/use-router'
import { useInfoCardContext } from 'modules/info-cards/context'
import { InfoCardField } from 'types/modules/info-cards/enums/info-card-field'
import { InfoCardStatus } from 'types/modules/info-cards/enums/info-card-status'
import { IInfoCard } from 'types/modules/info-cards/models/entities/info-card'
import { InfoCardListItem } from 'modules/info-cards/views/components/dashboard/info-card-list-item'
import { InfoCardListToolbar } from 'modules/info-cards/views/components/dashboard/info-card-list-toolbar'
import { InfoCardCalendarTimeGridCell } from 'modules/info-cards/views/components/dashboard/info-card-calendar-time-grid-cell'
import { InfoCardCalendarDateGridCell } from 'modules/info-cards/views/components/dashboard/info-card-calendar-date-grid-cell'
import { InfoCardListHeaderPanel } from 'modules/info-cards/views/components/dashboard/info-card-list-header-panel'
import { InfoCardDetailsModal } from 'modules/info-cards/views/components/info-card-details-panel/modal'
import { CustomFilters } from 'modules/info-cards/views/components/dashboard/customer-filters'

export const InfoCardsDashboard: React.FC<{}> = () => {
  const {
    currentInfoCard,
    infoCards,
    statusFilterConfig,
    initialiseDashboard,
  } = useInfoCardContext()

  const { routeParams, pushQueryParams } = useRouter()

  const rc = useRequestContext()

  const statusFilter = rc?.getFilterByField(PostField.STATUS)

  const onViewModeChange = React.useCallback(
    (viewMode: string) => {
      if (IsNullOrUndefined(statusFilter)) return

      const newSortValue = _.clone(rc?.sortValue!)
      const newStatusFilter = _.clone(statusFilter)

      switch (viewMode) {
        case 'list': {
          newSortValue.sortBy = InfoCardField.UPDATED_AT
          newSortValue.sortDirection = SortDirection.Descending

          newStatusFilter!.value = [InfoCardStatus.Active]

          rc?.setSortValue(newSortValue)
          rc?.applyFilter(newStatusFilter!)
          break
        }

        case 'calendar': {
          newSortValue.sortBy = InfoCardField.CREATED_AT
          newSortValue.sortDirection = SortDirection.Ascending

          newStatusFilter!.value = [
            InfoCardStatus.Inactive,
            InfoCardStatus.Active,
            InfoCardStatus.Discarded,
          ]

          rc?.setSortValue(newSortValue)
          rc?.applyFilter(newStatusFilter!)

          break
        }

        default:
          return
      }
    },
    [statusFilter],
  )

  const onTabChange = useCallback(
    (value: IFilter['value'], mode: SelectFilterMode) => {
      const valueIsArray = Array.isArray(value)
      const valueAsArray = value as InfoCardStatus[]

      if (IsNullOrUndefined(statusFilter) || !valueIsArray) return

      if (mode === SelectFilterMode.Multiple) {
        rc?.setActive(true)
        return
      }

      const status = valueAsArray[0]

      switch (status) {
        case InfoCardStatus.Active: {
          rc?.setSortValue({
            sortBy: InfoCardField.UPDATED_AT,
            sortDirection: SortDirection.Ascending,
          })
          return
        }

        default: {
          rc?.setSortValue({
            sortBy: InfoCardField.CREATED_AT,
            sortDirection: SortDirection.Descending,
          })
          return
        }
      }
    },
    [statusFilter],
  )

  const dashboardProps: IContentDashboardProps<IInfoCard> = {
    onDashboardMount: initialiseDashboard,
    onViewModeChange,
    onTabChange,
    tabFilterConfig: statusFilterConfig,
    customFilterComponent: <CustomFilters />,
    listOptions: {
      headerPanelComponent: <InfoCardListHeaderPanel />,
      listItemComponent: InfoCardListItem,
      toolbarComponent: <InfoCardListToolbar />,
    },
    calendarOptions: {
      toolbarComponent: <InfoCardListToolbar />,
      timeGridCellComponent: InfoCardCalendarTimeGridCell,
      dateGridCellComponent: InfoCardCalendarDateGridCell,
      headerActionButton: {
        icon: <ImageIcon />,
        onClick: (date: string): void => {
          pushQueryParams({
            viewMedia: date,
          })
        },
      },
    },
    data: infoCards,
  }

  return (
    <React.Fragment>
      <DashboardContextProvider>
        <ContentDashboard {...dashboardProps} />
      </DashboardContextProvider>
      {!IsNullOrUndefined(currentInfoCard) && (
        <InfoCardDetailsModal currentInfoCard={currentInfoCard} open />
      )}
    </React.Fragment>
  )
}
