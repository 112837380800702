enum PostStatus {
  Created = 'Created',
  Rejected = 'Rejected',
  Pending = 'Pending',
  Active = 'Active',
  Scheduled = 'Scheduled',
  Paused = 'Paused',
  Closed = 'Closed',
  Settled = 'Settled',
  Trashed = 'Trashed',
  Abandoned = 'Abandoned',
  Unsettled = 'Unsettled',
}

export default PostStatus
