import { Box, Button, Divider, Grid } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useChestsContext } from 'modules/chests/context'
import { Chest } from 'modules/chests/models/entities/chest'
import { NIL as NIL_UUID, v4 as uuidv4 } from 'uuid'
import React from 'react'
import { DisplayMode } from 'types/common/enums/display-mode'
import { useFormik } from 'formik'
import { ChestFieldName, Rarity } from 'modules/chests/enums/chest-field'
import { DiscardIcon, SaveIcon, UndoIcon } from 'components/icons'
import { ChestForm } from 'modules/chests/components/manage-chest/chest-form'
import { NEW_CHANCE_REWARD } from 'modules/chests/constants'
import { ChestSchema } from 'modules/chests/validation/chest-schema'
import { usePrizeListContext } from 'modules/prizes/context/prize-list'

const useStyles = makeStyles((theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 40,
      zIndex: 1250,
    },
    panelHeader: {
      display: 'flex',
      width: '100%',
      flexShrink: 0,
      height: 72,
    },
    panelTitle: {
      display: 'flex',
      alignItems: 'center',
      padding: '0 0 0 1.25rem',
    },
    rightHeaderSection: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      padding: '0 1.25rem 0 0',
      flexDirection: 'row-reverse',
      '& .MuiButton-contained': {
        marginRight: 20,
      },
    },
    contentWrapper: {
      display: 'flex',
      flexGrow: 1,
      overflow: 'hidden',
    },
    contentPanel: {
      position: 'relative',
      display: 'flex',
      flex: 1,
      padding: '1.25rem',
      overflow: 'auto',
      borderRight: '1px solid rgba(0,0,0,0.12)',
      '&:last-child': {
        border: 'none',
      },
    },
    footer: {
      display: 'flex',
      width: '100%',
      justifySelf: 'flex-end',
    },
    footerSection: {
      display: 'flex',
      padding: '1.25rem',
      flex: 1,
      justifyContent: 'space-between',
      borderRight: '1px solid rgba(0,0,0,0.12)',
      '&:last-child': {
        border: 'none',
      },
    },
    updateButton: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    removeButton: {
      color: theme.palette.secondary.contrastText,
      backgroundColor: theme.palette.secondary.main,
      marginLeft: 24,
      '&:hover': {
        backgroundColor: theme.palette.secondary.light,
      },
    },
    resetButton: {
      color: theme.palette.text.primary,
      backgroundColor: '#ecf0f1',
      '&:hover': {
        backgroundColor: '#f2f5f5',
      },
    },
  }),
)

const initialNewChanceReward = { ...NEW_CHANCE_REWARD, id: uuidv4() }

interface IManageChestProps {
  onChestRemoved: () => void
  children: any
}

export const ManageChest: React.FC<IManageChestProps> = ({
  onChestRemoved,
  children,
}) => {
  const classes = useStyles()

  const { currentPrize } = usePrizeListContext()

  const {
    currentChest: chest,
    createChest,
    updateChest,
    removeChest,
  } = useChestsContext()

  const displayMode = currentPrize?.chestId
    ? DisplayMode.Edit
    : DisplayMode.Create

  const form = useFormik({
    enableReinitialize: displayMode === DisplayMode.Edit,
    validationSchema: ChestSchema,
    initialValues: {
      [ChestFieldName.ID]: chest?.[ChestFieldName.ID] ?? NIL_UUID,
      [ChestFieldName.DESCRIPTION]: chest?.[ChestFieldName.DESCRIPTION] ?? '',
      [ChestFieldName.CHEST_RARITY]:
        chest?.[ChestFieldName.CHEST_RARITY] ?? Rarity.NOT_SET,
      [ChestFieldName.MIN_REWARDS_IN_CHEST]:
        chest?.[ChestFieldName.MIN_REWARDS_IN_CHEST] ?? 1,
      [ChestFieldName.MAX_REWARDS_IN_CHEST]:
        chest?.[ChestFieldName.MAX_REWARDS_IN_CHEST] ?? 1,
      [ChestFieldName.STATUS]: chest?.[ChestFieldName.STATUS] ?? 'Active',
      [ChestFieldName.CHEST_CONFIG]: chest?.[ChestFieldName.CHEST_CONFIG] ?? {
        [ChestFieldName.CHANCE_REWARDS]: [initialNewChanceReward],
      },
      [ChestFieldName.MEDIA]: chest?.[ChestFieldName.MEDIA] ?? {
        [ChestFieldName.IMAGE_URL]: '',
      },
    },
    onSubmit: (value: Chest) => {
      displayMode === DisplayMode.Create
        ? createChest(value)
        : updateChest(value)
    },
  })

  const addChanceReward = (): void => {
    form.setFieldValue(ChestFieldName.CHEST_CONFIG, {
      [ChestFieldName.CHANCE_REWARDS]: [
        ...form.values[ChestFieldName.CHEST_CONFIG][
          ChestFieldName.CHANCE_REWARDS
        ],
        { ...NEW_CHANCE_REWARD, id: uuidv4() },
      ],
    })
  }

  const removeChanceReward = (id: string): void => {
    form.setFieldValue(ChestFieldName.CHEST_CONFIG, {
      [ChestFieldName.CHANCE_REWARDS]: form.values[ChestFieldName.CHEST_CONFIG][
        ChestFieldName.CHANCE_REWARDS
      ].filter((item) => id !== item.id),
    })
  }

  const setChanceRewardFieldValue = (
    value: any,
    fieldName: string,
    id: string,
  ): void => {
    let additionalChange = {}
    const chanceRewards =
      form.values[ChestFieldName.CHEST_CONFIG][ChestFieldName.CHANCE_REWARDS]
    const index = chanceRewards.findIndex((reward) => reward.id === id)
    if (fieldName === 'type') {
      additionalChange = value === 'Currency' ? { prizeId: '' } : { currency: '' }
    }
    chanceRewards[index] = { ...chanceRewards[index], [fieldName]: value, ...additionalChange }
    form.setFieldValue(ChestFieldName.CHEST_CONFIG, {
      [ChestFieldName.CHANCE_REWARDS]: chanceRewards,
    })
    form.setFieldTouched(
      `${ChestFieldName.CHEST_CONFIG}.${ChestFieldName.CHANCE_REWARDS}[${index}]`,
      true,
    )
  }

  return (
    <>
      <Box className={classes.contentWrapper}>
        <Box className={classes.contentPanel}>
          <ChestForm
            {...{
              chest: form.values,
              handleChange: form.handleChange,
              setFieldTouched: form.setFieldTouched,
              setFieldValue: form.setFieldValue,
              addChanceReward,
              removeChanceReward,
              setChanceRewardFieldValue,
              errors: form.errors,
              displayMode,
            }}
          />
        </Box>
        {children}
      </Box>
      <Divider />
      <Box className={classes.footer}>
        <Box className={classes.footerSection}>
          <Box>
            <Button
              variant="contained"
              startIcon={<UndoIcon />}
              className={classes.resetButton}
              size="small"
              onClick={(): void => {
                form.resetForm()
              }}
              disabled={!form.dirty}
            >
              Undo
            </Button>

            {DisplayMode.Edit === displayMode && (
              <Button
                variant="contained"
                startIcon={<DiscardIcon />}
                className={classes.removeButton}
                size="small"
                onClick={(): void => {
                  removeChest(onChestRemoved)
                }}
              >
                Remove
              </Button>
            )}
          </Box>
          <Button
            variant="contained"
            startIcon={<SaveIcon />}
            className={classes.updateButton}
            size="small"
            onClick={(): void => {
              form.submitForm()
            }}
          >
            Save
          </Button>
        </Box>
      </Box>
    </>
  )
}
