import React from 'react'
import { Box, Chip, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { IPost } from 'types/modules/posts/models/entities/post'
import moment from 'moment-timezone'
import {
  IsNullOrUndefined,
  IsNullUndefinedOrEmpty,
} from 'core/utils/isNullOrUndefined'
import clsx from 'clsx'
import {
  CommentIcon,
  ImageIcon,
  PersonalityTestIcon,
  PollsIcon,
  TimedIcon,
  WatchlistIcon,
  WriterIcon,
} from 'components/icons'
import PostActionButtons from 'modules/posts/views/components/action-buttons'
import PostType from 'types/modules/posts/enums/post-type'
import PostStatus from 'types/modules/posts/enums/post-status'
import { Tag } from 'types/modules/tags/models/entities/tag'
import { PostActionButtonType } from 'types/modules/posts/enums/post-action-button-type'
import { titleCase } from 'core/utils/titleCase'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    postListCard: {
      display: 'flex',
      width: '100%',
      marginBottom: '0.625rem',
      flexDirection: 'row',
      boxShadow: 'none',
      border: '1px solid rgba(0,0,0,0.12)',
      borderRadius: 4,
      '&:last-child': {
        marginBottom: 'calc(48px + 0.625rem)',
      },
    },
    actionArea: {
      display: 'flex',
    },
    iconIndicatorContainer: {
      padding: 7,
      display: 'flex',
      width: 40,
      borderRight: '1px solid rgba(0,0,0,0.12)',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    iconIndicator: {
      display: 'flex',
      width: 24,
      height: 24,
      alignItems: 'center',
      color: 'rgba(0,0,0,0.54)',
    },
    postContent: {
      padding: '0.625rem',
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
      position: 'relative'
    },
    coverImageContainer: {
      display: 'flex',
      width: 80,
      height: 80,
      background: 'rgba(0,0,0,0.03)',
      borderRadius: 90,
      overflow: 'hidden',
      marginRight: '0.625rem',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative'
    },
    coverImage: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
    },
    postDetails: {
      display: 'flex',
      flexGrow: 1,
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    postTags: {
      display: 'flex',
      width: '100%',
      overflow: 'hidden',
    },
    postTag: {
      display: 'flex',
      flexDirection: 'row',
      marginRight: 10,
      boxSizing: 'border-box',
      background: '#ffffff',
      border: '2px solid rgba(51,51,51,0.34)',
      '&.level-0': {
        order: -1,
        borderColor: theme.palette.primary.main,
      },
    },
    actionsAndMetadata: {
      display: 'flex',
      flexShrink: 1,
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    actions: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      '& .MuiButton-root': {
        marginRight: '0.625rem',
        '&:last-of-type': {
          marginRight: 0,
        },
      },
    },
    metadata: {
      display: 'flex',
      width: '100%',
      flexDirection: 'row-reverse',
    },
    metadataItem: {
      marginLeft: '1.25rem',
    },
    writer: {
      marginRight: 'auto',
      display: 'flex',
      alignItems: 'center',
      paddingTop: 3,
      marginLeft: 10,
    },
    datesWrapper: {
      display: 'flex',
    },
    checkByDate: {
      marginLeft: 8,
      color: '#f78c00',
    },
    buttonWithIcon: {
      '& .MuiButton-label': {
        position: 'relative',
        top: '.1em',
        '& .MuiButton-startIcon': {
          position: 'relative',
          top: '-.1em',
        },
      },
    },
    groupIndication: {
      position: 'absolute',
      top: 8,
      left: 4
    }
  }),
)


const dateFormat = 'D MMMM YYYY HH:mm'

export const PostListItem: React.FC<IPost> = (post: IPost) => {
  const classes = useStyles()

  const renderCoverImage = (): JSX.Element => {
    return post.media?.imageUrl ? (
      <img src={post.media!.imageUrl!} className={classes.coverImage} />
    ) : (
      <Box className={classes.coverImage}>
        <ImageIcon />
      </Box>
    )
  }

  const renderStartClose = (): string => {
    let result = moment(post.start).format(dateFormat)
    if (!IsNullOrUndefined(post.close)) {
      result += ` - ${moment(post.close).format(dateFormat)}`
    }
    return result
  }

  const renderTags = (): JSX.Element => {
    return (
      <div className={classes.postTags}>
        {(post.tags as Tag[]).map((tag, index) =>
          index <= 1 || tag.level === 0 ? (
            <Chip
              key={`post-${post.id}-tag-${tag.id}`}
              label={tag.name}
              icon={<i>{tag.media?.icon ?? null}</i>}
              className={clsx(classes.postTag, tag.level === 0 && 'level-0')}
            />
          ) : null,
        )}
        {post.tags.length > 2 && (
          <Chip
            className={classes.postTag}
            label={`+ ${post.tags.length - 2}`}
          />
        )}
      </div>
    )
  }

  const renderIconIndicator = (): JSX.Element | null => {
    if (post.watchlist) {
      return (
        <Box className={classes.iconIndicator}>
          <WatchlistIcon />
        </Box>
      )
    }
    if (post.timed) {
      return (
        <Box className={classes.iconIndicator}>
          <TimedIcon />
        </Box>
      )
    }
    if (post.type === PostType.Poll) {
      return (
        <Box className={classes.iconIndicator}>
          <PollsIcon />
        </Box>
      )
    }
    if (post.type === PostType.Test) {
      return (
        <Box className={classes.iconIndicator}>
          <PersonalityTestIcon />
        </Box>
      )
    }

    return null
  }

  const renderMetadata = (): JSX.Element => {
    // Todo: Move this logic to future IPost interface
    const hasVolume =
      !IsNullOrUndefined(post.volume) && !isNaN(Number(post.volume))

    const shouldDisplayWinRate =
      ([PostStatus.Paused, PostStatus.Active].includes(post.status) &&
        [PostType.Game].includes(post.type)) ||
      ([PostStatus.Closed, PostStatus.Unsettled, PostStatus.Settled].includes(
        post.status,
      ) &&
        [PostType.Game, PostType.Bet].includes(post.type))

    const winRatePercentage =
      post.volume && post.winningCount
        ? ((post.winningCount * 100) / post.volume).toFixed(2)
        : 0

    return (
      <React.Fragment>
        <Box className={classes.metadataItem}>
          <Typography variant="body2" color="textPrimary" component="span">
            Volume =
          </Typography>
          <Typography variant="h6" color="textPrimary" component="span">
            {` ${hasVolume ? post.volume : 0}`}
          </Typography>
        </Box>
        {shouldDisplayWinRate && (
          <Box className={classes.metadataItem}>
            <Typography variant="body2" color="textPrimary" component="span">
              Win Rate =
            </Typography>
            <Typography variant="h6" color="textPrimary" component="span">
              {` ${winRatePercentage}%`}
            </Typography>
          </Box>
        )}
        {!IsNullOrUndefined(post.postCommentCount) &&
          post.postCommentCount > 0 &&
          [PostStatus.Pending, PostStatus.Rejected].includes(post.status) && (
            <Box className={classes.metadataItem}>
              <Typography variant="body2" color="textPrimary" component="span">
                <CommentIcon />
              </Typography>
              <Typography variant="h6" color="textPrimary" component="span">
                {` ${post.postCommentCount}`}
              </Typography>
            </Box>
          )}
        {!IsNullUndefinedOrEmpty(post.createdBy) && (
          <Box className={classes.writer}>
            <Typography variant="body2" color="textPrimary" component="span">
              <WriterIcon />
              {` ${titleCase(post.createdBy!)}`}
            </Typography>
          </Box>
        )}
      </React.Fragment>
    )
  }

  return (
    <Box className={classes.postListCard}>
      <Box className={classes.iconIndicatorContainer}>
        {renderIconIndicator()}
      </Box>
      <Box className={classes.postContent}>
        <Box className={classes.coverImageContainer}>
          {renderCoverImage()}
        </Box>
        {
          post.groups.length > 0 &&
            <Chip label="Group" className={classes.groupIndication} color="secondary" />
        }
        <Box className={classes.postDetails}>
          <Typography variant="subtitle2" color="textPrimary" component="span">
            {post.title}
          </Typography>
          <Box className={classes.datesWrapper}>
            <Typography variant="body2" color="textPrimary" component="span">
              {renderStartClose()}
            </Typography>
            {!!post.checkByDate && <Typography variant="body2" color="textPrimary" component="span" className={classes.checkByDate}>
              {moment(post.checkByDate).format(dateFormat)}
            </Typography>}
          </Box>
          {renderTags()}
        </Box>
        <Box className={classes.actionsAndMetadata}>
          <Box className={classes.actions}>
            <PostActionButtons
              post={post}
              exclude={[
                PostActionButtonType.Duplicate,
                PostActionButtonType.Approve,
                PostActionButtonType.Reject,
              ]}
            />
          </Box>
          <Box className={classes.metadata}>{renderMetadata()}</Box>
        </Box>
      </Box>
    </Box>
  )
}
