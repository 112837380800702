import React, {  } from 'react'
import { Box, Grid, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import { PanelContent } from 'components/panel'
import { makeStyles } from '@mui/styles'
import { AccSwitchForm } from 'modules/mobile-app-users/views/mobile-user-modal/account-switch/acc-switch-form'
import { useMobileAppUsersContext } from 'modules/mobile-app-users/context'
import { UserDetails } from 'modules/mobile-app-users/views/mobile-user-modal/account-switch/user-details'

const useStyles = makeStyles(
  createStyles({
    detailsWrapper: {
      height: 600,
      overflow: 'auto',
      paddingRight: 24,
    },
  }),
)

interface IAccSwitchProps {
  mobileAppUserId?: string
}

export const AccSwitch: React.FC<IAccSwitchProps> = ({ mobileAppUserId }) => {
  const classes = useStyles()
  const { currentMobileAppUser } = useMobileAppUsersContext()
  return (
    <PanelContent>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Typography variant="h6">
            Main account
          </Typography>
          <UserDetails user={currentMobileAppUser!} />
        </Grid>
        <Grid item xs={8}>
          <Box className={classes.detailsWrapper}>
            <Typography variant="h6">
              Replacement
            </Typography>
            <AccSwitchForm />
          </Box>
        </Grid>
      </Grid>
    </PanelContent>
  )
}
