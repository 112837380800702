import { Box, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { IEditorSubmitResult } from 'components/goss-editor/context'
import { CommentFeed } from 'modules/comments/views/components/comment-feed'
import { CommentBox } from 'modules/comments/views/components/comment-feed/comment-box'
import { useShippingContext } from 'modules/shipping/context'
import { UserPrizeHistoryItem } from 'modules/shipping/views/components/manage-user-prize-panel/user-prize-history-item'
import React from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    userPrizeHistory: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    commentFeed: {
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto',
      flexShrink: 1,
    },
    listContainer: {
      flex: 1,
      flexShrink: 0,
    },
    addCommentSection: {
      display: 'flex',
      width: '100%',
      paddingTop: '1.25rem',
    },
  }),
)

export const UserPrizeHistoryView: React.FC<{}> = () => {
  const classes = useStyles()

  const { currentUserPrizeHistory, createUserPrizeNote } = useShippingContext()

  const renderHistory = (): JSX.Element[] | null => {
    return currentUserPrizeHistory.map((historyItem) => (
      <UserPrizeHistoryItem
        key={`userPrize-history-${historyItem.id}`}
        {...historyItem}
      />
    ))
  }

  return (
    <Box className={classes.userPrizeHistory}>
      <Box className={classes.commentFeed}>
        <Box className={classes.listContainer}>{renderHistory()}</Box>
      </Box>
      <Box className={classes.addCommentSection}>
        <CommentBox
          onSubmit={({ value, callbackFn }: IEditorSubmitResult): void => {
            createUserPrizeNote(JSON.stringify(value), callbackFn)
          }}
        />
      </Box>
    </Box>
  )
}
