import { Grid, GridSize, InputLabel, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, { Fragment } from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputLabel: {
      display: 'flex',
      marginBottom: '0.625rem',
    },
    detailSection: {
      marginBottom: 20,
    },
  }),
)

interface IProps {
  children: JSX.Element
  xs?: GridSize
  label: string
}

export const InfoWrapper: React.FC<IProps> = ({ children, xs, label }) => {
  const classes = useStyles()
  const Component = xs ? Grid : Fragment
  const props = xs ? { xs, className: classes.detailSection, item: true } : {}

  return (
    <Component {...props}>
      <InputLabel className={classes.inputLabel}>{label}</InputLabel>
      {children}
    </Component>
  )
}
