import React from 'react'
import { useTypedSelector } from 'core/redux/utils'
import { useMilestonesContext } from 'modules/milestones/context/milestone-context'
import {
  Milestone,
  StyledTableCell,
} from 'modules/milestones/view/milestone'
import { MilestoneModal } from 'modules/milestones/view/milestone-modal'
import { IMilestone } from 'modules/milestones/models/milestone'
import { LocalListWrapper } from 'components/local-list-wrapper'
import { MilestoneActions, Selectors } from '..'

const sortOptions = [
  { name: 'Start', value: 'start', descending: true },
  { name: 'Created at', value: 'createdAt', descending: true },
  { name: 'Updated at', value: 'updatedAt', descending: true },
  { name: 'Name', value: 'name' },
  { name: 'Code', value: 'code' },
]

export const MilestoneList: React.FC<{}> = () => {
  const list = useTypedSelector((state) =>
    Selectors.getMilestones(state),
  )
  const count = useTypedSelector((state) =>
    Selectors.getMilestonesCount(state),
  )
  const {
    currentMilestone,
    setCurrentMilestone,
  } = useMilestonesContext()

  return (
    <LocalListWrapper<
      IMilestone,
      MilestoneActions.IMilestoneActionParams,
      null
    >
      list={list}
      count={count}
      searchKey="code"
      currentItem={currentMilestone}
      setCurrentItem={setCurrentMilestone}
      CardComponent={Milestone}
      ModalComponent={MilestoneModal}
      getItems={MilestoneActions.getMilestones}
      searchLabel="Code"
      title="Milestones and Rewards"
      sortOptions={sortOptions}
      panelXs={12}
    >
      <>
        <StyledTableCell>Name / Code</StyledTableCell>
        <StyledTableCell>Description</StyledTableCell>
        <StyledTableCell>Start</StyledTableCell>
        <StyledTableCell>Reward</StyledTableCell>
      </>
    </LocalListWrapper>
  )
}
