import { Box, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx'
import { useCalendarContext } from 'components/calendar/context/calendar-context'
import { IDateGridProps } from 'components/calendar/props/date-grid-props'
import { getDaysOfWeek } from 'components/calendar/utils'
import { useRequestContext } from 'core/api/context'
import moment from 'moment-timezone'
import React, { ReactElement } from 'react'
import _ from 'core/utils/deepdash'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dateGridWrapper: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
    },
    dateGridHeader: {
      display: 'flex',
      width: '100%',
      height: 80,
    },
    dateHeading: {
      display: 'flex',
      flex: 1,
      background: 'rgba(0,0,0,0.03)',
      // boxSizing: 'border-box',
      border: '1px solid rgba(0,0,0,0.12)',
      borderLeft: 'none',
      '&:first-child': {
        borderLeft: '1px solid rgba(0,0,0,0.12)',
      },
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'rgba(0,0,0,0.54)',
    },
    dateGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(7, 1fr)',
      gridTemplateRows: 'repeat(5, 1fr)',
      flex: 1,
    },
    dateCell: {
      borderRight: '1px solid rgba(0,0,0,0.12)',
      borderBottom: '1px solid rgba(0,0,0,0.12)',
      padding: '0.325rem',
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      '&:nth-child(7n-6)': {
        borderLeft: '1px solid rgba(0,0,0,0.12)',
      },
      '&.today': {
        background: 'rgba(255,120,125,0.12)',
      },
    },
    dateLabel: {
      color: 'rgba(0,0,0,0.54)',
    },
  }),
)

export function DateGrid<TModel>(
  props: IDateGridProps<TModel>,
): ReactElement<any, any> {
  const classes = useStyles()

  const rc = useRequestContext()

  const { visibleDates } = useCalendarContext()

  const itemsGroupedByDay = _.groupBy(
    props.data?.items ?? [],
    (val: { [key: string]: any }) => {
      return moment(val[rc?.sortValue.sortBy!]).startOf('day').toISOString()
    },
  )

  const DateGridCellComponent = props.dateGridCellComponent

  return (
    <Box className={classes.dateGridWrapper}>
      <Box className={classes.dateGridHeader}>
        {getDaysOfWeek().map((day) => {
          return (
            <Box key={`date-heading-${day}`} className={classes.dateHeading}>
              <Typography component="span" variant="button">
                {day}
              </Typography>
            </Box>
          )
        })}
      </Box>
      <Box className={classes.dateGrid}>
        {visibleDates.map((visibleDate) => {
          const date = moment(visibleDate)
          const startOfMonth = moment(date).startOf('month')
          const isFirstDay = date.isSame(startOfMonth, 'day')
          const isToday = date.isSame(moment(), 'day')
          const items = itemsGroupedByDay[date.toISOString()] ?? null

          return (
            <Box
              key={`date-cell-${date}`}
              className={clsx(classes.dateCell, isToday && 'today')}
            >
              <Box className={classes.dateLabel}>
                <Typography component="span" variant="h6">
                  {date.format(isFirstDay ? 'D MMM' : 'D')}
                </Typography>
              </Box>
              <DateGridCellComponent
                sortBy={rc?.sortValue.sortBy!}
                items={items}
                dateTime={date.toISOString()}
              />
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}
