import { Avatar, Box, Button, IconButton, Popover, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {
  GossEditorProvider,
  IEditorSubmitResult,
} from 'components/goss-editor/context'
import { GossEditor } from 'components/goss-editor/views'
import { RichTextDisplay } from 'components/goss-editor/views/rich-text-display'
import { DiscardIcon, EditIcon } from 'components/icons'
import { usePopover } from 'components/popover/use-popover'
import { useAuth } from 'core/authentication/hooks/use-auth'
import { titleCase } from 'core/utils/titleCase'
import moment from 'moment-timezone'
import React, { useState } from 'react'
import { IComment } from 'types/modules/comments/models/entities/comment'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    commentFeedItem: {
      display: 'flex',
      width: '100%',
      height: 'auto',
      flexDirection: 'row',
      '&:nth-child(n+2)': {
        paddingTop: '1.25rem',
        marginTop: '1.25rem',
        borderTop: '1px solid rgba(0,0,0,0.12)',
      },
    },
    leftSection: {
      display: 'flex',
      height: '100%',
      marginRight: 16,
    },
    avatar: {
      display: 'flex',
      width: 40,
      height: 40,
      fontSize: '1.25rem',
      fontWeight: 500,
      backgroundColor: 'rgba(0,0,0,0.14)',
    },
    mainSection: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
    },
    headerSection: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      height: 40,
    },
    usernameSection: {},
    username: {},
    metadataSection: {},
    actionButtons: {},
    commentSection: {
      paddingBottom: '0.625rem',
      display: 'flex',
      width: '100%',
      height: 'auto',
    },
    confirmationPopover: {
      padding: '1.25rem',
    },
    confirmationTextWrapper: {
      justifyContent: 'center',
      textAlign: 'center',
      marginBottom: '1.25rem',
    },
    confirmationText: {},
    confirmationButtons: {
      display: 'flex',
      justifyContent: 'center',
    },
    confirmButton: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
      marginRight: '0.625rem',
    },
    cancelButton: {
      color: theme.palette.text.primary,
      backgroundColor: '#ecf0f1',
      marginLeft: '0.625rem',
      '&:hover': {
        backgroundColor: '#f2f5f5',
      },
    },
  }),
)

export interface ICommentFeedItemProps {
  comment: IComment
  onUpdate: (result: IEditorSubmitResult) => void
  onDelete: (commentId: string) => void
}

enum CommentDisplayMode {
  View = 'view',
  Edit = 'edit',
}

export const CommentFeedItem: React.FC<ICommentFeedItemProps> = (
  props: ICommentFeedItemProps,
) => {
  const classes = useStyles()

  const { comment, onUpdate } = props

  const { currentUser } = useAuth()

  const createdByInitials = comment.createdBy.substr(0, 1)

  const renderDate = (date: string): string => {
    const dateFormat = 'D MMMM YYYY HH:mm'
    return moment(date).format(dateFormat)
  }

  const [mode, setMode] = useState(CommentDisplayMode.View)

  const confirmDeletePopover = usePopover({
    id: `confirm-delete-comment-${comment.id}`,
    anchorReference: 'anchorPosition',
  })

  return (
    <React.Fragment>
      <Box className={classes.commentFeedItem}>
        <Box className={classes.leftSection}>
          <Avatar className={classes.avatar} variant={'circular'}>
            {createdByInitials.toUpperCase()}
          </Avatar>
        </Box>
        <Box className={classes.mainSection}>
          <Box className={classes.headerSection}>
            <Box className={classes.usernameSection}>
              <Typography className={classes.username} variant="body1">
                {titleCase(comment.createdBy)}
              </Typography>
            </Box>
            <Box className={classes.metadataSection}>
              <Typography className={classes.username} variant="body2">
                {renderDate(comment.createdAt)}
              </Typography>
            </Box>
            <Box className={classes.actionButtons}>
              {currentUser.username === comment.createdBy && (
                <React.Fragment>
                  <IconButton
                    size="small"
                    onClick={(): void => {
                      setMode(CommentDisplayMode.Edit)
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={confirmDeletePopover.onClick}
                  >
                    <DiscardIcon />
                  </IconButton>
                </React.Fragment>
              )}
            </Box>
          </Box>
          <Box className={classes.commentSection}>
            <GossEditorProvider
              initialValue={JSON.parse(comment.comment)}
              editableProps={{
                spellCheck: true,
                autoFocus: true,
                readOnly: mode === CommentDisplayMode.View,
              }}
              onSubmit={(result: IEditorSubmitResult): void => {
                result.callbackFn = (): void => {
                  setMode(CommentDisplayMode.View)
                }

                onUpdate(result)
              }}
            >
              {mode === CommentDisplayMode.View ? (
                <RichTextDisplay />
              ) : (
                <GossEditor
                  onCancel={(): void => {
                    setMode(CommentDisplayMode.View)
                  }}
                />
              )}
            </GossEditorProvider>
          </Box>
        </Box>
      </Box>
      <Popover {...confirmDeletePopover.popoverProps}>
        <Box className={classes.confirmationPopover}>
          <Box className={classes.confirmationTextWrapper}>
            <Typography className={classes.confirmationText}>
              {`Are you sure you want to delete this comment?`}
            </Typography>
          </Box>
          <Box className={classes.confirmationButtons}>
            <Button
              variant="contained"
              className={classes.confirmButton}
              size="small"
              onClick={(): void => {
                props.onDelete(comment.id)
              }}
            >
              Confirm
            </Button>
            <Button
              variant="contained"
              className={classes.cancelButton}
              size="small"
              onClick={(): void => {
                confirmDeletePopover.popoverProps.onClose()
              }}
              color="secondary"
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Popover>
    </React.Fragment>
  )
}
