import React, { ChangeEvent, useCallback, useState } from 'react'
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Box, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { TagGroup } from 'types/modules/tags/models/entities/tag-group'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { useDispatch } from 'react-redux'
import _ from 'core/utils/deepdash'
import { Tag } from 'types/modules/tags/models/entities/tag'
import { Actions, Selectors } from 'modules/tags'
import { ITagGroupActionParams } from 'modules/tags/actions'
import { AutocompleteSearchMultiselect } from 'components/controls/inputs/downshift/autocomplete/autocomplete-search-multiselect'
import { OverrideChipProps } from 'components/controls/inputs/downshift/multiselect/multiselect-props'
import { availableTagItems } from 'modules/tags/utils/available-tags'
import { TagGroupFromContainer } from 'modules/tags/views/form-wrapper'
import { DisplayArea } from 'fe-shared-resources';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    emoticon: {
      maxWidth: 100,
      marginRight: '1.25rem',
    },
    nameField: {
      marginBottom: '20px',
      width: '100%',
    },
    editFormWrapper: {
      flexFlow: 'wrap',
      minHeight: 350,
    },
    footerContent: {
      display: 'flex',
      flex: 1,
      justifyContent: 'flex-end',
    },
    cancelBtn: {
      marginRight: '1.25rem',
    },

    inputLabel: {
      display: 'flex',
      marginBottom: 8,
      marginTop: 8,
    },


    selectField: {
      marginBottom: '20px',
      width: '100%',
    },

    tabContainer: {
      paddingLeft: 20,
      paddingTop: 10,
    },
  }),
)

export interface IManageTagGroupsProps {
  tagGroup: TagGroup | null
  handleClose: () => void
}

enum Mode {
  Create = 'Create',
  Edit = 'Edit',
}

export const ManageTagGroups: React.FC<IManageTagGroupsProps> = ({
  tagGroup: passedInTagGroup,
  handleClose,
}: IManageTagGroupsProps) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const mode = IsNullOrUndefined(passedInTagGroup) ? Mode.Create : Mode.Edit

  const [tagGroup, setTagGroup] = useState<TagGroup>(
    passedInTagGroup ?? { name: '', displayArea: DisplayArea.Everywhere },
  )

  const handleSubmit = useCallback(() => {
    const params: ITagGroupActionParams = {
      item: tagGroup,
      callbackFn: handleClose,
    }

    let action

    switch (mode) {
      case Mode.Create:
        action = Actions.createTagGroup
        if (IsNullOrUndefined((params.item! as TagGroup).tags)) {
          ;(params.item! as TagGroup).tags = []
        }
        break
      case Mode.Edit:
        action = Actions.updateTagGroup
        params.tagGroupId = (params.item! as TagGroup).id
        break
    }

    dispatch(action(params))
  }, [tagGroup])

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const newValue = _.cloneDeep(tagGroup)
    newValue.name = event.currentTarget.value
    setTagGroup(newValue)
  }

  const handleSelectedTagsChange = (selectedTags: Tag[]): void => {
    const newValue = _.cloneDeep(tagGroup)
    newValue.tags = [...selectedTags.map((x) => x.id!)]
    setTagGroup(newValue)
  }

  const handleDisplayAreaChange = (event: any): void => {
    const newValue = _.cloneDeep(tagGroup)
    newValue.displayArea = event.target.value
    setTagGroup(newValue)
  }

  return (
    <TagGroupFromContainer
      handleSubmit={handleSubmit}
      handleClose={handleClose}
      buttonLabel="Save"
    >
      <>
        <TextField
          onChange={handleNameChange}
          value={tagGroup.name}
          variant="outlined"
          label={'Name'}
          className={classes.nameField}
        />
        <Box>
          <InputLabel htmlFor="displayArea" className={classes.inputLabel}>
            Display Area
          </InputLabel>
          <Select
            value={tagGroup.displayArea}
            variant="outlined"
            className={classes.selectField}
            onChange={handleDisplayAreaChange}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              // getContentAnchorEl: null,
            }}
          >
            {Object.keys(DisplayArea).map((displayAreaKey) => (
              <MenuItem
                key={displayAreaKey}
                value={DisplayArea[displayAreaKey as DisplayArea] as string}
              >
                {DisplayArea[displayAreaKey as DisplayArea]}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <AutocompleteSearchMultiselect
          key={`autocomplete-${tagGroup.id ?? '-empty'}`}
          label="Tags"
          initialSelectedItems={(passedInTagGroup?.tags as Tag[]) ?? []}
          availableItems={availableTagItems as any[]}
          onSelectedItemsChange={handleSelectedTagsChange}
          searchAsyncActionCreator={Actions.getAutocompleteSearchTags}
          selectorMethod={Selectors.getAutocompleteSearchTags}
          options={{
            selectedItemsChipProps: (item: Tag): OverrideChipProps => {
              const props: OverrideChipProps = {
                label: item.name!,
              }
              if (!IsNullOrUndefined(item.media?.icon)) {
                props.icon = <i>{item.media!.icon!}</i>
              }
              return props
            },
            autocompleteSearchResultContent: (item: Tag): string =>
              `${item.media?.icon ?? ''} ${item.name} - Level: ${item.level}`,
          }}
        />
      </>
    </TagGroupFromContainer>
  )
}
