import { Box, Grid, InputLabel, SvgIcon, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { ImageIcon } from 'components/icons'
import {
  IsNullOrUndefined,
} from 'core/utils/isNullOrUndefined'
import { useShippingContext } from 'modules/shipping/context'
import { InfoWrapper } from 'modules/shipping/views/components/details/info-wrapper'
import { OrderInfo } from 'modules/shipping/views/components/details/order-info'
import { ShippingInfo } from 'modules/shipping/views/components/details/shipping-info'
import { SourceInfo } from 'modules/shipping/views/components/details/source-info'
import { UserInfo } from 'modules/shipping/views/components/details/user-info'
import React from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    userPrizeDetails: {
      width: '100%',
      height: 'auto',
      '& .MuiGrid-item': {
        display: 'flex',
        flexDirection: 'column',
      },
    },
    inputLabel: {
      display: 'flex',
      marginBottom: '0.625rem',
    },
    prizeImageContainer: {
      display: 'flex',
      flex: 1,
      width: '100%',
      minHeight: 300,
      height: '100%',
      background: 'rgba(0,0,0,0.03)',
      color: 'rgba(0,0,0,0.54)',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
)

export const UserPrizeDetailsView: React.FC<{}> = () => {
  const classes = useStyles()

  const { currentUserPrize: userPrize } = useShippingContext()

  const hasPrizeImage = !IsNullOrUndefined(
    userPrize?.prize?.item?.details?.imageUrl,
  )

  const prizeImageContainerStyles: { [key: string]: string | number } = {}

  if (hasPrizeImage) {
    prizeImageContainerStyles.backgroundImage = `url(${userPrize?.prize?.item?.details?.imageUrl})`
  } else {
    prizeImageContainerStyles.background = 'rgba(0,0,0,0.03)'
  }

  return (
    <Box className={classes.userPrizeDetails}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <InputLabel className={classes.inputLabel}>Prize Image</InputLabel>
          <Box
            className={classes.prizeImageContainer}
            style={prizeImageContainerStyles}
          >
            {!hasPrizeImage && (
              <SvgIcon>
                <ImageIcon />
              </SvgIcon>
            )}
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Grid container spacing={2}>
            <InfoWrapper xs={6} label="Order">
              <OrderInfo userPrize={userPrize} />
            </InfoWrapper>
            <InfoWrapper xs={6} label="User Info">
              <UserInfo userPrize={userPrize} />
            </InfoWrapper>
            <InfoWrapper xs={6} label="Shipping Address">
              <ShippingInfo userPrize={userPrize} />
            </InfoWrapper>
            <InfoWrapper xs={6} label="Source">
              <SourceInfo userPrize={userPrize} />
            </InfoWrapper>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}
