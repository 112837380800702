import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import moment from 'moment-timezone'

export const renderDate = (
  date: string,
  dateFormat: string = 'D MMMM YYYY HH:mm',
): string => {
  return moment(date).format(dateFormat)
}

const timeDifferenceCache: { [key: string]: number | null } = {}
const durationCache: { [key: string]: string } = {}

const getDateString = (date?: string | Date): string => {
  if (!date) return ''
  return date.toString().replace('.000Z', '')
}

export const concatDurationAndLabel = (
  duration: number,
  label: string,
): string =>
  duration ? `${duration} ${duration === 1 ? label : `${label}s`} ` : ''

export const getTimeDifference = (
  start?: string,
  end?: string,
): number | null => {
  const cacheKey = `${start}-${end}`
  if (timeDifferenceCache[cacheKey]) {
    return timeDifferenceCache[cacheKey]
  }
  let timeDiff
  if (!start) {
    timeDiff = 0
  } else if (!end) {
    timeDiff = null
  } else {
    timeDiff = moment(end).utc().diff(start)
  }
  timeDifferenceCache[cacheKey] = timeDiff
  return timeDiff
}

export const getDurationText = (
  start?: string | Date,
  end?: string | Date,
): string => {
  const startDateString = getDateString(start)
  const endDateString = getDateString(end)
  const cacheKey = `${startDateString}-${endDateString}`
  if (durationCache[cacheKey]) {
    return durationCache[cacheKey]
  }
  const timeDifference = getTimeDifference(startDateString, endDateString)
  let durationText
  if (timeDifference === 0) {
    durationText = '0 mins'
  } else if (!timeDifference) {
    durationText = 'Forever'
  } else {
    const duration = moment.duration(timeDifference)
    const years = duration.years()
    const months = duration.months()
    const days = duration.days()
    const hours = duration.hours()
    const minutes = duration.minutes()
    const seconds = duration.seconds()

    durationText = `${concatDurationAndLabel(years, 'year')}
    ${concatDurationAndLabel(months, 'month')}
    ${concatDurationAndLabel(days, 'day')}
    ${concatDurationAndLabel(hours, 'hour')}
    ${concatDurationAndLabel(minutes, 'minute')}
    ${concatDurationAndLabel(seconds, 'second')}`
  }
  durationCache[cacheKey] = durationText
  return durationText
}

export const getAdjustedEndDate = (
  newStartDate: string,
  startDate?: string | Date,
  endDate?: string | Date,
): string => {
  if (!startDate || !endDate) return ''
  const startDateString = getDateString(startDate)
  const endDateString = getDateString(endDate)
  const timeDifference = getTimeDifference(startDateString, endDateString)
  return moment(newStartDate).add(timeDifference, 'milliseconds').toISOString()
}

export const renderDates = (startAt?: string | Date, endAt?: string | Date): string => {
  const dateFormat = 'D MMMM YYYY HH:mm'
  let result = moment(startAt).format(dateFormat)
  if (!IsNullOrUndefined(endAt)) {
    result += ` - ${moment(endAt).format(dateFormat)}`
  }
  return result
}
