import { MODULE_NAME } from '../constants'

const ACTION_TYPES = {
  SET_CURRENT_INFO_CARD_ID: `${MODULE_NAME}/SET_CURRENT_INFO_CARD_ID`,
  CLEAR_INFO_CARDS: `${MODULE_NAME}/CLEAR_INFO_CARDS`,
  GET_INFO_CARD_STATUSES: `${MODULE_NAME}/GET_INFO_CARD_STATUSES`,
  GET_INFO_CARDS: `${MODULE_NAME}/GET_INFO_CARDS`,
  GET_INFO_CARD_BY_ID: `${MODULE_NAME}/GET_INFO_CARD_BY_ID`,
  CREATE_INFO_CARD: `${MODULE_NAME}/CREATE_INFO_CARD`,
  CREATE_INFO_CARD_DRAFT: `${MODULE_NAME}/CREATE_INFO_CARD_DRAFT`,
  UPDATE_INFO_CARD: `${MODULE_NAME}/UPDATE_INFO_CARD`,
  UPDATE_INFO_CARD_STATUS: `${MODULE_NAME}/UPDATE_INFO_CARD_STATUS`,
  UPLOAD_INFO_CARD_IMAGE: `${MODULE_NAME}/UPLOAD_INFO_CARD_IMAGE`,
}

export default ACTION_TYPES
