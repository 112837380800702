import { Box } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { SimpleImageDisplay } from 'components/simple-image-display'
import { MobileAppUser } from 'modules/mobile-app-users/models/entities/mobile-app-user'
import { DetailItem } from 'modules/mobile-app-users/views/mobile-user-modal/common/detail-item'
import React from 'react'

const useStyles = makeStyles(() =>
  createStyles({
    horizontalView: {
      display: 'flex'
    },
    horizontalRightSide: {
      marginLeft: 8
    }
  }),
)

interface IUserDetailsProps {
  user: MobileAppUser
  horizontal?: boolean
}

export const UserDetails = ({
  user,
  horizontal
}: IUserDetailsProps): JSX.Element => {
  const classes = useStyles()

  return <Box className={horizontal ? classes.horizontalView : ''}>
    <SimpleImageDisplay
      imageUrl={user?.media?.backgroundImageUrl}
      width={200}
      height={200}
    />
    <Box className={horizontal ? classes.horizontalRightSide : ''}>
      <DetailItem label='Username' value={user.username} />
      <DetailItem label='Given name' value={user.givenName} />
      <DetailItem label='Family name' value={user.familyName} />
      <DetailItem label='Preferred username' value={user.preferredUsername} />
      <DetailItem label='Phone number' value={user.phoneNumber} />
      <DetailItem label='Email' value={user.email} />
      <DetailItem label='Birthday' value={user.birthday} />
      <DetailItem label='ID' value={user.id} />
    </Box>
  </Box>
}