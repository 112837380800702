import { Box, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { usePostListContext } from 'modules/posts/context/post-list'
import React from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    postCoverContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: '100%',
      height: 450,
      borderRadius: 12,
      padding: 15,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
    },
  }),
)

interface IPostFeedWrapperProps {
  children: JSX.Element[]
}

export const PostFeedWrapper: React.FC<IPostFeedWrapperProps> = ({
  children,
}: IPostFeedWrapperProps) => {
  const classes = useStyles()
  const { currentPost } = usePostListContext()

  const hasCoverImage = !IsNullOrUndefined(currentPost?.media?.imageUrl)
  const postCoverContainerStyles: { [key: string]: string | number } = {}

  if (hasCoverImage) {
    postCoverContainerStyles.backgroundImage = `url(${currentPost?.media?.imageUrl})`
  } else {
    postCoverContainerStyles.background = 'rgba(0,0,0,0.03)'
  }

  return (
    <Box
      className={classes.postCoverContainer}
      style={postCoverContainerStyles}
    >
      {children}
    </Box>
  )
}
