const paths = {
  gameTasks: 'games/tasks',
  add: 'add',
  save: 'save',
  update: 'update',
  steps: 'steps',
  uploadStepImage: 'upload-step-image'
}

export default paths
