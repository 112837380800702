import { Box, Grid, InputLabel, TextField, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react'
import { getIn } from 'formik'
import { ITagFormProps } from 'modules/tags/views/manage-tag-panel/types/tag-form-props'
import { TagField as FieldName } from 'types/modules/tags/enums/tag-field'
import { ColorPicker } from 'components/color-picker/color-picker'
import { MediaOrientation } from 'types/common/images/enum/media-orientation'
import { MediaUploadModel } from 'types/common/images/models/entities/image-upload-model'
import { AspectRatio } from 'types/common/images/enum/aspect-ratio'
import { MediaItem } from 'types/modules/media-items/models/entities/media-item'
import _ from 'core/utils/deepdash'
import { useDispatch } from 'react-redux'
import { updateTag, uploadTagImage } from 'modules/tags/actions'
import { mapMimeType } from 'core/utils/mime-mapper'
import { DisplayMode } from 'types/common/enums/display-mode'
import { MediaItemInput } from 'modules/media-items/media-item-input'
import { SaveBeforeWarning } from 'components/save-before-warning'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tagForm: {
      width: '100%',
    },
    inputLabel: {
      display: 'flex',
      marginBottom: '0.625rem',
      marginTop: '0.625rem',
    },
    coverImageContainer: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: 324,
    },
    imageHint: {
      marginTop: 8,
      color: 'red',
    },
    imageHintText: {
      marginLeft: 8,
    },
  }),
)

export const TagForm: React.FC<ITagFormProps> = (props: ITagFormProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { tag, handleChange, setFieldValue, displayMode } = props

  return (
    <Box className={classes.tagForm}>
      <Grid container spacing={2}>
        <Grid item xs={4} className={classes.coverImageContainer}>
          <InputLabel className={classes.inputLabel}>Cover Image</InputLabel>
          <MediaItemInput
            disabled={displayMode !== DisplayMode.Edit}
            url={tag.media?.imageUrl}
            imageLabel={'Cover Image'}
            mediaItemId={tag.media?.mediaItemId}
            options={{
              aspectRatio: AspectRatio.Standard,
              orientation: MediaOrientation.Portrait,
            }}
            onComplete={(fileParams: MediaUploadModel): void => {
              dispatch(
                uploadTagImage({
                  tagId: tag.id,
                  item: fileParams.binaryData,
                  extension: `image.${fileParams.fileExtension}`,
                  overrideHeaders: {
                    'content-type': mapMimeType(fileParams.fileExtension),
                    accept: '*/*',
                  },
                }),
              )
            }}
            onCreate={(mediaItem: MediaItem): void => {
              const newTag = _.cloneDeep(tag)
              newTag.media = {
                ...(tag.media ?? {}),
                mediaItemId: mediaItem.id,
              }
              dispatch(
                updateTag({
                  item: newTag,
                  tagId: tag.id,
                }),
              )
            }}
          />
          {displayMode !== DisplayMode.Edit && <SaveBeforeWarning />}
        </Grid>
        <Grid item xs={8}>
          <Grid item xs={2}>
            <InputLabel
              htmlFor={`${FieldName.MEDIA}[${FieldName.ICON}]`}
              className={classes.inputLabel}
            >
              Icon
            </InputLabel>
            <TextField
              id={`${FieldName.MEDIA}[${FieldName.ICON}]`}
              name={`${FieldName.MEDIA}[${FieldName.ICON}]`}
              value={getIn(tag, `${FieldName.MEDIA}[${FieldName.ICON}]`)}
              onChange={(event): void => {
                setFieldValue(
                  `${FieldName.MEDIA}[${FieldName.ICON}]`,
                  event.target.value,
                )
              }}
              variant="filled"
              fullWidth
            />
          </Grid>

          <Grid item xs={10}>
            <InputLabel htmlFor={FieldName.NAME} className={classes.inputLabel}>
              Name
            </InputLabel>
            <TextField
              id={FieldName.NAME}
              name={FieldName.NAME}
              onChange={handleChange}
              value={tag[FieldName.NAME]}
              variant="filled"
              fullWidth
            />
          </Grid>

          <Grid item>
            <InputLabel
              htmlFor={`${FieldName.MEDIA}[${FieldName.ICON}]`}
              className={classes.inputLabel}
            >
              Color
            </InputLabel>
            <ColorPicker
              name="backgroundColor"
              value={getIn(
                tag,
                `${FieldName.MEDIA}[${FieldName.BACKGROUND_COLOR}]`,
              )}
              onChange={(color): void => {
                setFieldValue(
                  `${FieldName.MEDIA}[${FieldName.BACKGROUND_COLOR}]`,
                  color,
                )
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}
