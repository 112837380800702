import { PostContext } from 'modules/posts/context'
import { ManagePostPanel } from 'modules/posts/views/components/manage-post-panel'
import React, { PropsWithChildren } from 'react'

export const ManagePostPage: React.FC<PropsWithChildren<{}>> = () => {
  return (
    <PostContext>
      <ManagePostPanel />
    </PostContext>
  )
}
