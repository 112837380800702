import { Box, Divider, IconButton, SvgIcon, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { CrossIcon } from 'components/icons'
import { Panel, PanelContainer } from 'components/panel'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { useShippingContext } from 'modules/shipping/context'
import { getStatusLabel } from 'modules/shipping/utils/get-status-label'
import { UserPrizeActionButtons } from 'modules/shipping/views/components/action-buttons'
import { UserPrizeDetailsView } from 'modules/shipping/views/components/manage-user-prize-panel/user-prize-details-view'
import { UserPrizeHistoryView } from 'modules/shipping/views/components/manage-user-prize-panel/user-prize-history-view'
import React from 'react'
import { UserPrizeActionButtonType } from 'types/modules/shipping/enums/user-prize-action-button-type'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    panelHeader: {
      display: 'flex',
      width: '100%',
      flexShrink: 0,
      height: 72,
    },
    panelTitle: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      padding: '0 0 0 1.25rem',
    },
    rightHeaderSection: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      padding: '0 1.25rem 0 0',
      flexDirection: 'row-reverse',
      '& .MuiButton-contained': {
        marginRight: 20,
      },
    },
    contentWrapper: {
      display: 'flex',
      flexGrow: 1,
      overflow: 'hidden',
    },
    contentPanel: {
      position: 'relative',
      display: 'flex',
      flex: 1,
      padding: '1.25rem',
      overflow: 'auto',
      borderRight: '1px solid rgba(0,0,0,0.12)',
      '&:last-child': {
        border: 'none',
      },
    },
    splitFooter: {
      display: 'flex',
      width: '100%',
      justifySelf: 'flex-end',
    },
    leftFooterSection: {
      display: 'flex',
      padding: '1.25rem',
      flex: 1,
      justifyContent: 'space-between',
      borderRight: '1px solid rgba(0,0,0,0.12)',
      '&:last-child': {
        border: 'none',
      },
    },
    rightFooterSection: {
      display: 'flex',
      padding: '1.25rem',
      flex: 1,
      justifyContent: 'center',
      '& .MuiButton-contained': {
        marginLeft: '0.625rem',
        marginRight: '0.625rem',
      },
      borderRight: '1px solid rgba(0,0,0,0.12)',
      '&:last-child': {
        border: 'none',
      },
    },
  }),
)

interface IUserPrizeDetailsPanelProps {
  isModal: boolean
}

export const UserPrizeDetailsPanel: React.FC<IUserPrizeDetailsPanelProps> = (
  props: IUserPrizeDetailsPanelProps,
) => {
  const classes = useStyles()

  const { currentUserPrize: userPrize, clearCurrentUserPrize } =
    useShippingContext()

  return !IsNullOrUndefined(userPrize) ? (
    <PanelContainer>
      <Panel container xs={12}>
        <Box className={classes.panelHeader}>
          <Box className={classes.panelTitle}>
            <Typography
              variant="h5"
              color="textPrimary"
              style={{
                paddingRight: 10,
              }}
            >
              {'User Prize - '}
            </Typography>
            <Typography variant="h5" color="primary">
              {getStatusLabel(userPrize!.status)}
            </Typography>
          </Box>
          <Box className={classes.rightHeaderSection}>
            {props.isModal && (
              <IconButton onClick={(): void => clearCurrentUserPrize()} size="large">
                <SvgIcon>
                  <CrossIcon />
                </SvgIcon>
              </IconButton>
            )}
          </Box>
        </Box>
        <Divider />
        <Box className={classes.contentWrapper}>
          <Box className={classes.contentPanel}>
            <UserPrizeDetailsView />
          </Box>
          <Box className={classes.contentPanel}>
            <UserPrizeHistoryView />
          </Box>
        </Box>
        <Divider />
        <Box className={classes.splitFooter}>
          <Box className={classes.leftFooterSection}></Box>
          <Box className={classes.rightFooterSection}>
            <UserPrizeActionButtons
              userPrize={userPrize!}
              exclude={[UserPrizeActionButtonType.View]}
            />
          </Box>
        </Box>
      </Panel>
    </PanelContainer>
  ) : null;
}
