import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { initialPostDraftMap } from 'modules/posts/constants'
import moment from 'moment-timezone'
import { IEvent } from 'types/modules/events/models/entities/event'
import PostType from 'types/modules/posts/enums/post-type'
import { IPost } from 'types/modules/posts/models/entities/post'
import { PostPricingModel } from 'types/modules/posts/models/entities/post-details'
import {
  ICreatePostDraftParams,
  IPostDraft,
} from 'types/modules/posts/models/entities/post-draft'

export const createPostDraft = ({
  postType,
  event,
  liveSchedules,
  eventTagGroupTags,
}: ICreatePostDraftParams): Partial<IPost> => {
  const postDraft: IPostDraft = {
    ...initialPostDraftMap[postType],
    title: '',
    start: moment().toISOString(),
    postPricingModel: PostPricingModel.Free,
    questions: [],
    results: [],
    postPrizes: [],
    tags: [],
  }

  switch (postType) {
    case PostType.Game: {
      postDraft.close = moment(postDraft.start).add(2, 'months').toISOString()
      break
    }
    case PostType.Poll: {
      postDraft.close = moment(postDraft.start).add(1, 'week').toISOString()
      break
    }
  }

  if (!IsNullOrUndefined(event)) {
    postDraft.eventId = event?.id

    if (
      !IsNullOrUndefined(event?.tagGroupId) &&
      !IsNullOrUndefined(eventTagGroupTags)
    ) {
      postDraft.tagIds = eventTagGroupTags.map((tag) => tag.id!)
    }

    if (postDraft.type === PostType.Bet) {
      const activeLiveSchedule = liveSchedules.find((schedule) => {
        const currentTime = moment()
        const scheduleStart = moment(schedule.start)
        const scheduleEnd = moment(schedule.end)

        return (
          currentTime.isAfter(scheduleStart) &&
          currentTime.isBefore(scheduleEnd)
        )
      })

      if (!IsNullOrUndefined(activeLiveSchedule)) {
        postDraft.liveScheduleId = activeLiveSchedule?.id
      }
    }
  }

  return postDraft
}
