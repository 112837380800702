/* Lib */
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Button, CircularProgress } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
/* Core */
import { useTypedSelector } from 'core/redux/utils'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'

/* Types */
import { SortDirection } from 'types/common/enums/sort-direction'
import { SortValue } from 'types/common/sorting/models/entities/sort-value'
import { TagGroup } from 'types/modules/tags/models/entities/tag-group'

/* App */
import {
  PanelContainer,
  Panel,
  PanelHeader,
  PanelContent,
} from 'components/panel'
import SearchAndSortToolbar from 'components/panel/toolbars/search+sort-toolbar'

/* Module */
import { TagCard } from 'modules/tags/views/tag-card'
import { TagGroupCard } from 'modules/tags/views/tag-group-card'
import { TagGroupEditModal } from 'modules/tags/views/tag-group-edit-modal'
import { makeStyles } from '@mui/styles'
import { FilterType } from 'types/common/filtering/enums/FilterType'
import { FilterOperator } from 'types/common/filtering/enums/FilterOperator'
import { useTagsContext } from 'modules/tags/context'
import { ManageTagModal } from 'modules/tags/views/manage-tag-panel/modal'
import { TagPromoteModal } from 'modules/tags/views/tag-promote-modal'
import { getTagPromotion } from 'modules/tags/selectors'
import { Actions, Selectors } from '..'

const useStyles = makeStyles(
  createStyles({
    infiniteScrollLoader: {
      width: 40,
      height: 40,
      display: 'flex',
      overflow: 'hidden',
      margin: 'auto',
      marginTop: 20,
      marginBottom: 20,
    },
    promoteTagButton: {
      marginRight: 10,
    },
  }),
)

const TagAndGroupListView: React.FC<{}> = () => {
  const defaultSortValue: SortValue = {
    sortBy: 'name',
    sortDirection: SortDirection.Ascending,
  }

  const tagPromotion = useTypedSelector((state) => getTagPromotion(state))

  const tags = useTypedSelector((state) => Selectors.getTags(state))

  const [tagSearchValue, setTagSearchValue] = useState('')

  const [tagSortValue, setTagSortValue] = useState(defaultSortValue)

  const [tagModalOpen, setTagModalOpen] = useState(false)

  const tagGroups = useTypedSelector((state) => Selectors.getTagGroups(state))

  const [currentTagGroup, setCurrentTagGroup] = useState<TagGroup | null>(null)

  const [tagGroupSearchValue, setTagGroupSearchValue] = useState('')

  const [tagGroupSortValue, setTagGroupSortValue] = useState(defaultSortValue)

  const [tagGroupModalOpen, setTagGroupModalOpen] = useState(false)

  const [tagsPageNumber, setTagsPageNumber] = useState(1)

  const [tagGroupsPageNumber, setTagGroupsPageNumber] = useState(1)

  const [tagPromoteModalOpen, setTagPromoteModalOpen] = useState(false)

  const pageSize = 20

  const dispatch = useDispatch()

  const { currentTag, createTagDraft, setCurrentTag } = useTagsContext()

  // Get Tags Effect
  useEffect(() => {
    dispatch(Actions.getTagPromotion())
    dispatch(Actions.getTagSuggestion())
    const tagIds = tagPromotion?.tagIds || []
    tagIds.forEach((unfetchedTagId) => {
      dispatch(Actions.getTagById({ tagId: unfetchedTagId }))
    })
  }, [tagPromotion.promotionEndDate])

  useEffect(() => {
    const getTagsParams: Actions.ITagActionParams = {
      sortBy: tagSortValue.sortBy,
      sortDirection: tagSortValue.sortDirection,
      page: tagsPageNumber,
      pageSize: pageSize,
    }

    if (tagSearchValue.length > 1) {
      getTagsParams.filters = [
        {
          field: 'name',
          type: FilterType.Search,
          operator: FilterOperator.ContainsCaseInsensitive,
          value: tagSearchValue,
        },
      ]
    }

    dispatch(Actions.getTags(getTagsParams))
  }, [dispatch, tagsPageNumber, tagSearchValue, tagSortValue])

  // Get Tag Groups Effect
  useEffect(() => {
    const getTagGroupsParams: Actions.ITagActionParams = {
      sortBy: tagGroupSortValue.sortBy,
      sortDirection: tagGroupSortValue.sortDirection,
      page: tagGroupsPageNumber,
      pageSize: pageSize,
    }

    if (tagGroupSearchValue.length > 1) {
      getTagGroupsParams.filters = [
        {
          field: 'name',
          type: FilterType.Search,
          operator: FilterOperator.ContainsCaseInsensitive,
          value: tagGroupSearchValue,
        },
      ]
    }

    dispatch(Actions.getTagGroups(getTagGroupsParams))
  }, [dispatch, tagGroupsPageNumber, tagGroupSearchValue, tagGroupSortValue])

  // Tag Actions
  const handleTagSearch = (value: string): void => {
    setTagsPageNumber(1)
    setTagSearchValue(value)
  }

  const handleTagSort = (value: SortValue): void => {
    setTagsPageNumber(1)
    setTagSortValue(value)
  }

  const handleOpenPromoteModal = (): void => {
    setTagPromoteModalOpen(true)
  }

  const handleClosePromoteModal = (): void => {
    setTagPromoteModalOpen(false)
  }

  const loadMoreTags = (): void => {
    setTagsPageNumber(tagsPageNumber + 1)
  }

  // Tag Group Actions
  const handleTagGroupSearch = (value: string): void => {
    setTagGroupsPageNumber(1)
    setTagGroupSearchValue(value)
  }

  const handleTagGroupSort = (value: SortValue): void => {
    setTagGroupsPageNumber(1)
    setTagGroupSortValue(value)
  }

  const handleOpenTagGroupModal = (): void => {
    setTagGroupModalOpen(true)
  }

  const handleCloseTagGroupModal = (): void => {
    setTagGroupModalOpen(false)
    setCurrentTagGroup(null)
  }

  const handleTagGroupOnClick = (tagGroup: TagGroup): void => {
    setCurrentTagGroup(tagGroup)
  }

  const loadMoreTagGroups = (): void => {
    setTagGroupsPageNumber(tagGroupsPageNumber + 1)
  }

  // Modal Triggers
  useEffect(() => {
    if (!IsNullOrUndefined(currentTagGroup)) {
      handleOpenTagGroupModal()
    }
  }, [currentTagGroup])

  // Render Cards
  const renderTagCards = (): JSX.Element[] | null => {
    return (
      tags.items?.map((tag) => {
        return (
          <TagCard
            key={`tag-${tag.code}`}
            tag={tag}
            onClick={(): void => setCurrentTag(tag.id!)}
          />
        )
      }) ?? null
    )
  }

  const renderTagGroupCards = (): JSX.Element[] | null => {
    return (
      tagGroups.items?.map((tagGroup) => {
        return (
          <TagGroupCard
            key={`tagGroup-${tagGroup.id}`}
            tagGroup={tagGroup}
            onClick={(): void => handleTagGroupOnClick(tagGroup)}
          />
        )
      }) ?? null
    )
  }

  // Render Page
  const classes = useStyles()

  return (
    <PanelContainer>
      <Panel xs={6}>
        <PanelHeader
          title="Tags"
          inlineControls={
            <>
              <Button
                variant="contained"
                color="secondary"
                className={classes.promoteTagButton}
                onClick={handleOpenPromoteModal}
              >
                Promote Tags
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={(): void => createTagDraft()}
              >
                Create
              </Button>
            </>
          }
          toolbar={
            <SearchAndSortToolbar
              onSearch={handleTagSearch}
              onSort={handleTagSort}
              sortingOptions={[
                { label: 'Name', value: 'name' },
                { label: 'Code', value: 'code' },
              ]}
              defaultSortValue={defaultSortValue}
            />
          }
        />
        <PanelContent id="TagContent">
          <InfiniteScroll
            dataLength={tags.items?.length!}
            next={loadMoreTags}
            hasMore={tags.items?.length! < tags.count}
            loader={
              <CircularProgress className={classes.infiniteScrollLoader} />
            }
            scrollThreshold={'300px'}
            scrollableTarget="TagContent"
          >
            {renderTagCards()}
          </InfiniteScroll>
        </PanelContent>
      </Panel>
      <Panel xs={6}>
        <PanelHeader
          title="Tag Groups"
          inlineControls={
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenTagGroupModal}
            >
              Create
            </Button>
          }
          toolbar={
            <SearchAndSortToolbar
              onSearch={handleTagGroupSearch}
              onSort={handleTagGroupSort}
              sortingOptions={[{ label: 'Name', value: 'name' }]}
              defaultSortValue={defaultSortValue}
            />
          }
        />
        <PanelContent id="TagGroupContent">
          <InfiniteScroll
            dataLength={tagGroups.items.length!}
            next={loadMoreTagGroups}
            hasMore={(tagGroups.items?.length ?? 0) < tagGroups.count}
            loader={
              <CircularProgress className={classes.infiniteScrollLoader} />
            }
            scrollThreshold={'300px'}
            scrollableTarget="TagGroupContent"
          >
            {renderTagGroupCards()}
          </InfiniteScroll>
        </PanelContent>
      </Panel>
      {!IsNullOrUndefined(currentTag) && (
        <ManageTagModal
          currentTag={currentTag}
          open={!IsNullOrUndefined(currentTag)}
        />
      )}
      {
        /* Todo: Replace this with new tag group panel modal */
        tagGroupModalOpen && (
          <TagGroupEditModal
            key={`tagGroupEditModal-${currentTagGroup?.id ?? 'empty'}`}
            tagGroup={currentTagGroup}
            open={tagGroupModalOpen}
            handleOpen={handleOpenTagGroupModal}
            handleClose={handleCloseTagGroupModal}
          />
        )
      }
      {tagPromoteModalOpen && (
        <TagPromoteModal
          key="promoteModal"
          open={tagPromoteModalOpen}
          handleOpen={handleOpenPromoteModal}
          handleClose={handleClosePromoteModal}
        />
      )}
    </PanelContainer>
  )
}

export default TagAndGroupListView
