import PortalUserEndpoints from 'core/api/endpoints/users/portal'
import { createAsyncAction } from 'core/redux/utils'
import ActionTypes from 'modules/users/portal/action-types'
import { IAsyncActionParams } from 'types/common/api/models/entities/async-action-params'
import { PortalUser } from 'types/modules/users/portal/models/entities/portal-user'
import { IAsyncAction } from 'types/redux/interfaces/IAction'

export interface IPortalUserActionParams
  extends IAsyncActionParams<PortalUser> {
  username?: string
}

export const getUsers: () => IAsyncAction = () =>
  createAsyncAction({ type: ActionTypes.GET_USERS, endpoint: PortalUserEndpoints.getUsers, payload: null })
