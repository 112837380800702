import { Button, ButtonProps, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx'
import { ConfirmationDialog } from 'components/confirmation-dialog'
import { UndoIcon } from 'components/icons'
import { useShippingContext } from 'modules/shipping/context'
import React from 'react'
import { UserPrizeStatus } from 'types/modules/shipping/enums/user-prize-status'
import { IUserPrizeActionButtonProps } from 'types/modules/shipping/models/props/user-prize-action-button-props'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.text.primary,
      backgroundColor: '#ecf0f1',
      '&:hover': {
        backgroundColor: '#f2f5f5',
      },
    },
  }),
)

export const ReturnUserPrizeButton: React.FC<IUserPrizeActionButtonProps> = (
  props: IUserPrizeActionButtonProps,
) => {
  const { returnUserPrize } = useShippingContext()

  const classes = useStyles()

  const isDelivered = props.userPrize.status === UserPrizeStatus.Delivered

  const buttonProps: ButtonProps = {
    variant: 'contained',
    startIcon: <UndoIcon />,
    className: clsx(classes.root),
    size: 'small',
    disabled: props.disabled,
  }

  const buttonLabel = 'return'

  return isDelivered ? (
    <ConfirmationDialog
      popoverId={`${buttonLabel}-prize-${props.userPrize.id}`}
      buttonProps={buttonProps}
      onConfirmation={(): void => returnUserPrize(props.userPrize.id)}
      confirmationText={`Are you sure you want to ${buttonLabel} this prize?`}
      buttonLabel={buttonLabel}
      onPopoverClose={props.onPopoverClose}
    />
  ) : null
}
