import ActionTypes from 'core/alerts/action-types'
import { Reducer } from 'redux'
import { IAlertsState } from 'types/common/alerts/models/state/alerts-state'
import { ISimpleAction } from 'types/redux/interfaces/IAction'
import _ from 'core/utils/deepdash'
import { IAlert } from 'types/common/alerts/models/entities/alert'

const initialState = {
  queue: [],
}

interface IAlertPayload extends IAlert {
  dismissAll?: boolean
}

const AlertsReducer: Reducer<IAlertsState, ISimpleAction> = (
  state = initialState,
  action: ISimpleAction,
) => {
  const payload = action.payload as IAlertPayload

  switch (action.type) {
    case ActionTypes.ENQUEUE_ALERT: {
      const newState = _.cloneDeep(state)

      newState.queue = [...state.queue, payload as IAlert]

      return newState
    }

    case ActionTypes.DISMISS_ALERT: {
      const newState = _.cloneDeep(state)

      newState.queue = state.queue.map((alert) =>
        payload.dismissAll || alert.key === payload.key
          ? { ...alert, dismissed: true }
          : { ...alert },
      )

      return newState
    }

    case ActionTypes.REMOVE_ALERT: {
      const newState = _.cloneDeep(state)

      newState.queue = state.queue.filter((alert) => alert.key !== payload.key)

      return newState
    }

    default:
      return state
  }
}

export default AlertsReducer
