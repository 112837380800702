import { Box, Button } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { AutocompleteSearchSelect } from 'components/controls/inputs/downshift/autocomplete/autocomplete-search-select'
import { FormGen, FormTypes } from 'components/simple-form-gen'
import { Actions } from 'modules/mobile-app-users'
import { IMobileAppUserActionParams } from 'modules/mobile-app-users/actions'
import { useMobileAppUsersContext } from 'modules/mobile-app-users/context'
import { MobileAppUser } from 'modules/mobile-app-users/models/entities/mobile-app-user'
import { getCachedMobileUser } from 'modules/mobile-app-users/selectors'
import { UserDetails } from 'modules/mobile-app-users/views/mobile-user-modal/account-switch/user-details'
import React, { useState } from 'react'
import SortDirection from 'types/common/enums/sort-direction'
import { FilterOperator } from 'types/common/filtering/enums/FilterOperator'
import { FilterType } from 'types/common/filtering/enums/FilterType'
import { IFilter } from 'types/common/filtering/models/entities/IFilter'
import { IAsyncAction } from 'types/redux/interfaces/IAction'

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '90%',
      paddingTop: 8
    },
    detailsWrapper: {
      marginTop: 32,
    },
    confirmationWrapper: {
      display: 'flex'
    },
    confirmButtonWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      marginLeft: 8
    },
    confirmationTextWrapper: {
      flex: 1
    }
  }),
)

export const AccSwitchForm = (): JSX.Element => {
  const classes = useStyles()
  const [selectedAccount, setSelectedAccount] = useState<MobileAppUser | null>(null)
  const [confirmationText, setConfirmationText] = useState('')
  const { currentMobileAppUser, switchAccount, processing } = useMobileAppUsersContext()
  const searchUsers = (params: IMobileAppUserActionParams): IAsyncAction => {
    const filters: IFilter[] = [
      {
        field: 'preferredUsername',
        type: FilterType.Search,
        operator: FilterOperator.ContainsCaseInsensitive,
        value: params.search!.value,
      },
    ]

    return Actions.getAutocompleteSearchMobileAppUsers({
      filters,
      sortyBy: 'preferredUsername',
      sortDirection: SortDirection.Ascending,
    })
  }

  return <Box className={classes.container}>
    <Box>
      <AutocompleteSearchSelect
        variant="outlined"
        initialSelectedItem={selectedAccount}
        availableItems={[]}
        onSelectedItemChange={(
          selectedUser: MobileAppUser | null,
        ): void => {
          setSelectedAccount(selectedUser)
        }}
        label="Preferred Username"
        searchAsyncActionCreator={searchUsers}
        selectorMethod={getCachedMobileUser}
        options={{
          autocompleteSearchResultContent: (
            item: unknown,
          ): string => {
            const user = item as MobileAppUser
            return `${user?.preferredUsername || ''} ${user?.username || ''}`
          },
        }}
        fieldName="preferredUsername"
      />
      {!!selectedAccount && <Box className={classes.detailsWrapper}>
        <UserDetails user={selectedAccount} horizontal />
      </Box>}
    </Box>
    <Box className={classes.confirmationWrapper}>
      <Box className={classes.confirmationTextWrapper}>
        <FormGen
          value={confirmationText}
          handleChange={(e): void => setConfirmationText(e.target.value)}
          name="search"
          type={FormTypes.TEXT_INPUT}
          label="Type `confirm` to enable button"
        />
      </Box>
      <Box className={classes.confirmButtonWrapper}>
        <Button
          variant="contained"
          onClick={(): void => {
            if (selectedAccount && currentMobileAppUser) {
              switchAccount({
                newAccountId: selectedAccount.id,
                oldAccountId: currentMobileAppUser.id,
                callback: (): void => {
                  setSelectedAccount(null)
                  setConfirmationText('')
                }
              })
            }
          }}
          disabled={confirmationText !== 'confirm' || !selectedAccount || processing}
          color="primary"
        >
          {processing ? 'Switching...' : 'Switch account'}
        </Button>
      </Box>
    </Box>
  </Box>
}