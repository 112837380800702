import ActionTypes from 'modules/quests/action-types/quest-group-action-types'
import { Reducer } from 'redux'
import { actionTypeSuccess, cacheItem, cacheItems } from 'core/redux/utils'
import { ISimpleAction, ISuccessAction } from 'types/redux/interfaces/IAction'
import _ from 'core/utils/deepdash'
import { mergeArrays } from 'core/utils/mergeArrays'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { IQuestGroupActionParams } from 'modules/quests/actions/quest-actions'
import { NIL as NIL_UUID } from 'uuid'
import { IQuestGroupState } from 'modules/quests/models/state'
import { IQuestGroup } from 'modules/quests/models/quest-group'

const pageSize = 20

const initialState: IQuestGroupState = {
  count: 0,
  page: 0,
  pageSize: pageSize,
  items: [],
  cache: {},
}

export type GetQuestGroupsApiResponse = {
  count: number
  page: number
  pageSize: number
  items: IQuestGroup[]
}


type AllowedActionTypes = ISuccessAction | ISimpleAction

const actionHandlers = {
  [ActionTypes.SET_CURRENT_QUEST_GROUP_ID]: (
    state: IQuestGroupState,
    action: AllowedActionTypes
  ): IQuestGroupState => {
    const simpleAction = action as ISimpleAction
    const { questGroupId } = simpleAction.payload as IQuestGroupActionParams
    return {
      ...state,
      currentQuestGroupId: questGroupId ? questGroupId : undefined
    }
  },
  [ActionTypes.CREATE_QUEST_GROUP_DRAFT]: (state: IQuestGroupState, action: AllowedActionTypes): IQuestGroupState => {
    const simpleAction = action as ISimpleAction
    const { item } = simpleAction.payload as IQuestGroupActionParams

    return {
      ...state,
      currentQuestGroupId: NIL_UUID,
      items: [item as IQuestGroup, ...state.items]
    }
  },
  [actionTypeSuccess(ActionTypes.GET_QUEST_GROUPS)]: (state: IQuestGroupState, action: AllowedActionTypes): IQuestGroupState => {
    const successAction = action as ISuccessAction
    const response = successAction.data as GetQuestGroupsApiResponse
    return {
      ...state,
      items: mergeArrays(state.items, response.items!),
      cache: cacheItems(state.cache, response.items),
      page: response.page,
      count: response.count
    }
  },
  [actionTypeSuccess(ActionTypes.GET_QUEST_GROUP_BY_ID)]: (
    state: IQuestGroupState,
    action: AllowedActionTypes
  ): IQuestGroupState => {
    const successAction = action as ISuccessAction
    const response = successAction.data as IQuestGroup
    return {
      ...state,
      cache: cacheItem(state.cache, response)
    }
  },
  [actionTypeSuccess(ActionTypes.CREATE_QUEST_GROUP)]: (
    state: IQuestGroupState,
    action: AllowedActionTypes
  ): IQuestGroupState => {
    const successAction = action as ISuccessAction
    const responseItem = successAction.data as IQuestGroup
    const questGroup = {
      ...responseItem,
      questGroupQuests: responseItem.quests?.map((quest) => ({
        questId: quest.id,
        questGroupId: responseItem.id
      }))
    }

    const { items } = state

    return {
      ...state,
      items: [questGroup as IQuestGroup, ...items],
      cache: cacheItem(state.cache, questGroup as IQuestGroup)
    }
  },
  [actionTypeSuccess(ActionTypes.UPDATE_QUEST_GROUP)]: (
    state: IQuestGroupState,
    action: AllowedActionTypes
  ): IQuestGroupState => {
    const successAction = action as ISuccessAction
    const response = successAction.data as IQuestGroup

    const payloadItem = successAction.payload!.item as IQuestGroup
    const questIndex = state.items.findIndex((x) => x.id === response.id)

    return {
      ...state,
      items: questIndex >= 0
        ? state.items.map((item, index) => index === questIndex ? response : item)
        : [...state.items, response],
      cache: cacheItem(state.cache, payloadItem)
    }
}
}

const questGroupsReducer: Reducer<IQuestGroupState, AllowedActionTypes> = (
  state = initialState,
  action: AllowedActionTypes,
) => {
  return actionHandlers[action.type]
    ? actionHandlers[action.type](state, action)
    : state
}

export default questGroupsReducer