import { useTypedSelector } from 'core/redux/utils'
import { useRouter } from 'core/routing/hooks/use-router'
import {
  IsNullOrUndefined,
} from 'core/utils/isNullOrUndefined'
import { Actions, Selectors } from 'modules/tags'
import { formatTagCode } from 'modules/tags/utils/format-tag-code'
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useDispatch } from 'react-redux'
import { Tag, TagDraft } from 'types/modules/tags/models/entities/tag'
import {
  TagGroup,
  TagGroupDraft,
} from 'types/modules/tags/models/entities/tag-group'
import { NIL as NIL_UUID } from 'uuid'

export interface ITagsContext {
  currentTag: Tag | null
  setCurrentTag: (tagId: string) => void
  clearCurrentTag: () => void
  createTag: (tag: TagDraft, callbackFn?: (tag: Tag) => void) => void
  createTagFromName: (tagName: string, callbackFn?: (tag: Tag) => void) => void
  createTagDraft: (tag?: TagDraft) => void
  updateTag: (tag: Tag) => void
  createTagGroup: (tagGroup: TagGroupDraft) => void
  updateTagGroup: (tagGroup: TagGroup) => void
  deleteTag: (tagId: string) => void
  processing: boolean
}

const TagsContext = createContext<ITagsContext>({} as ITagsContext)

export const TagsProvider: React.FC<PropsWithChildren<{}>> = (
  props: PropsWithChildren<{}>,
) => {
  const dispatch = useDispatch()

  const { routeParams, pushQueryParams, removeQueryParams } = useRouter()

  const { tagId } = routeParams

  const currentTag = useTypedSelector((state) => Selectors.getCurrentTag(state))

  const [processing, setProcessing] = useState(false)

  const setCurrentTag = (tagId: string): void => {
    pushQueryParams({
      tagId,
    })
  }

  const clearCurrentTag = (): void => {
    removeQueryParams('tagId')
  }

  useEffect(() => {
    dispatch(Actions.setCurrentTagId({ tagId }))

    if (!IsNullOrUndefined(tagId) && tagId !== NIL_UUID) {
      dispatch(Actions.getTagById({ tagId }))
    }
  }, [tagId])

  const createTag = (tag: TagDraft, callbackFn?: (tag: Tag) => void): void => {
    new Promise<Tag>((onResolve, onReject) => {
      dispatch(
        Actions.createTag({
          item: tag,
          promise: {
            onResolve,
            onReject,
          },
        }),
      )
    }).then((tag) => {
      if (!IsNullOrUndefined(callbackFn)) {
        callbackFn!(tag)
      }
    })
  }

  const createTagFromName = (
    tagName: string,
    callbackFn?: (tag: Tag) => void,
  ): void => {
    const newTag: Tag = {
      name: tagName,
      code: formatTagCode(tagName),
      level: 3,
    }

    createTag(newTag, callbackFn)
  }

  const createTagDraft = (tag?: TagDraft): void => {
    setCurrentTag(NIL_UUID)
  }

  const updateTag = (tag: Tag): void => {
    dispatch(
      Actions.updateTag({
        item: tag,
        tagId: tag.id,
      }),
    )
  }

  const deleteTag = (tagId: string): void => {
    setProcessing(true)
    dispatch(
      Actions.deleteTagById({
        tagId,
        promise: {
          onReject: (): void => setProcessing(false),
          onResolve: (): void => {
            setProcessing(false)
            clearCurrentTag()
          },
        }
      }),
    )
  }


  const createTagGroup = (tagGroup: TagGroupDraft): void => {
    dispatch(
      Actions.createTagGroup({
        item: tagGroup,
      }),
    )
  }

  const updateTagGroup = (tagGroup: TagGroup): void => {
    dispatch(
      Actions.updateTagGroup({
        item: tagGroup,
        tagGroupId: tagGroup.id,
      }),
    )
  }

  const context = {
    currentTag,
    setCurrentTag,
    clearCurrentTag,
    createTag,
    createTagFromName,
    createTagDraft,
    updateTag,
    createTagGroup,
    updateTagGroup,
    deleteTag,
    processing
  }

  return (
    <TagsContext.Provider value={context}>
      {props.children}
    </TagsContext.Provider>
  )
}

export const useTagsContext = (): ITagsContext => useContext(TagsContext)
