import { RootSagaReturnType } from 'core/redux/rootSaga'
import ActionTypes from 'modules/live-schedules/action-types'
import { GetTagsSaga } from 'modules/tags/sagas/get-tags-saga'
import { all, takeEvery, takeLatest } from 'redux-saga/effects'

const enabledAsyncActions = [
  ActionTypes.GET_LIVE_SCHEDULE_BY_ID,
  ActionTypes.CREATE_LIVE_SCHEDULE,
  ActionTypes.UPDATE_LIVE_SCHEDULE,
]

export const RootSaga = function* (): RootSagaReturnType {
  yield all([
    yield takeEvery(enabledAsyncActions, GetTagsSaga),
    yield takeLatest(ActionTypes.GET_LIVE_SCHEDULES, GetTagsSaga),
  ])
}
