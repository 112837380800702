import { all, AllEffect, ForkEffect, takeEvery } from 'redux-saga/effects'
import IapActionTypes from 'modules/iap-products/action-types/iap-products-action-types'
import { GetIapProductSaga } from './get-iap-product-saga'

type RootSagaReturnType = Generator<
  ForkEffect<never> | AllEffect<any>,
  void,
  any
>

export const RootSaga = function* (): RootSagaReturnType {
  yield all([

    yield takeEvery(
      IapActionTypes.GET_IAP_PRODUCTS,
      GetIapProductSaga,
    ),
    yield takeEvery(
      IapActionTypes.GET_IAP_PRODUCT_BY_ID,
      GetIapProductSaga,
    ),
    yield takeEvery(
      IapActionTypes.CREATE_IAP_PRODUCT,
      GetIapProductSaga,
    ),
    yield takeEvery(
      IapActionTypes.UPDATE_IAP_PRODUCT,
      GetIapProductSaga,
    ),
    yield takeEvery(
      IapActionTypes.DELETE_IAP_PRODUCT,
      GetIapProductSaga,
    ),
  ])
}
