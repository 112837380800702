import { DESCRIPTION_MAX } from 'modules/news-cards/constants'
import * as yup from 'yup'

export const NewsCardSchema = yup.object({
  title: yup.string().required('required'),
  media: yup.object().shape({
    description:  yup.string()
    .required('required')
    .max(DESCRIPTION_MAX, `can not be more than ${DESCRIPTION_MAX}`),
  })
})
