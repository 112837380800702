import { IAlert } from 'types/common/alerts/models/entities/alert'
import * as uuid from 'uuid'

export const createAlert = (
  message: string,
  options: IAlert['options'],
): IAlert => {
  const key = uuid.v4()

  return {
    key,
    message,
    options,
    dismissed: false,
  }
}
