import { Box, Grid, InputLabel, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import _ from 'core/utils/deepdash'
import { FormGen, FormTypes } from 'components/simple-form-gen'
import React from 'react'
import { IForm } from 'types/common/interfaces/IForm'
import { INewsCard } from 'modules/news-cards/models/news-card'
import { IEvent } from 'types/modules/events/models/entities/event'
import { Selectors } from 'modules/events'
import { AutocompleteSearchSelect } from 'components/controls/inputs/downshift/autocomplete/autocomplete-search-select'
import { useEventAutoCompleteSearch } from 'hooks/use-event-auto-complete-search'
import { ExclamationTriangle } from 'components/icons'
import { MediaItemInput } from 'modules/media-items/media-item-input'
import { MediaOrientation } from 'types/common/images/enum/media-orientation'
import { useNewsCardsContext } from 'modules/news-cards/context/news-card-context'
import { MediaUploadModel } from 'types/common/images/models/entities/image-upload-model'
import { AspectRatio } from 'types/common/images/enum/aspect-ratio'
import { LabelWithCharLimit } from 'modules/posts/views/components/manage-post-panel/label-with-char-limit'
import { DESCRIPTION_MAX } from 'modules/news-cards/constants'
import { SaveBeforeWarning } from 'components/save-before-warning'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      width: '100%',
      height: '600px',
      '& .MuiGrid-item': {
        display: 'flex',
        flexDirection: 'column',
      },
    },
    newsCardsWrapper: {
      borderLeft: '1px solid #f4f4f4',
      paddingLeft: 8,
      height: '100%',
    },
    container: {
      height: '100%',
    },
    newsCardsError: {
      marginTop: -16,
    },

    inputLabel: {
      display: 'flex',
      marginBottom: '0.625rem',
    },

    imageHint: {
      marginTop: 8,
      color: 'red',
    },
    imageHintText: {
      marginLeft: 8,
    },
    imageContainer: {
      height: 200,
      width: '100%',
    },
  }),
)

interface INewsCardFormProps extends IForm {
  newsCard: INewsCard
  isUpdating?: boolean
}

export const NewsCardForm: React.FC<INewsCardFormProps> = ({
  newsCard,
  handleChange,
  setFieldValue,
  errors,
  isUpdating,
}): JSX.Element => {
  const classes = useStyles()
  const { uploadNewsCardImage, currentNewsCard } = useNewsCardsContext()
  const { selectedEvent, searchEvents } = useEventAutoCompleteSearch({
    eventId: newsCard?.promotedEventId,
  })
  const title = {
    name: 'title',
    label: 'Title',
    value: newsCard?.title || '',
    type: FormTypes.TEXT_INPUT,
    otherProps: {
      error: !!errors?.title,
      helperText: errors?.title,
    },
  }
  const description = {
    name: 'media.description',
    value: newsCard?.media?.description || '',
    type: FormTypes.TEXT_INPUT,
    otherProps: {
      error: !!errors?.description,
      helperText: errors?.description,
      multiline: true,
      inputProps: {
        maxLength: DESCRIPTION_MAX,
      },
    },
  }

  const variant = {
    name: 'variant',
    label: 'Variant',
    value: newsCard?.variant || '',
    type: FormTypes.SELECT,
    options: [
      { value: 'V0', name: 'Variant 0' },
      { value: 'V1', name: 'Variant 1' },
      { value: 'V2', name: 'Variant 2' },
    ],
    otherProps: {
      error: !!errors?.variant,
      helperText: errors?.variant,
    },
  }

  return (
    <Box className={classes.form}>
      <Grid container spacing={2} className={classes.container}>
        <Grid item xs={4}>
          <InputLabel className={classes.inputLabel}>Media</InputLabel>
          <Box className={classes.imageContainer}>
            {!isUpdating && <SaveBeforeWarning />}
            <MediaItemInput
              disabled={!isUpdating}
              url={currentNewsCard?.media?.imageUrl || ''}
              imageLabel={'Cover Image'}
              mediaItemId={currentNewsCard?.media?.mediaItemId}
              options={{
                aspectRatio: AspectRatio.Standard,
                orientation: MediaOrientation.Portrait,
              }}
              onComplete={(fileParams: MediaUploadModel): void => {
                uploadNewsCardImage(newsCard.id!, fileParams)
              }}
              removeCrop
              mediaSquareHeight={400}
            />
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormGen handleChange={handleChange} {...title} />
            </Grid>
            <Grid item xs={12}>
              <LabelWithCharLimit
                htmlFor="media.description"
                className={classes.inputLabel}
                label="Description"
                valueLength={(newsCard?.media?.description || '').length}
                limit={DESCRIPTION_MAX}
              />
              <FormGen handleChange={handleChange} {...description} />
            </Grid>
            <Grid item xs={12}>
              <InputLabel className={classes.inputLabel}>Event</InputLabel>
              <AutocompleteSearchSelect
                variant="filled"
                initialSelectedItem={selectedEvent}
                availableItems={[]}
                onSelectedItemChange={(selectedEvent: IEvent | null): void => {
                  setFieldValue('promotedEventId', selectedEvent?.id)
                }}
                searchAsyncActionCreator={searchEvents}
                selectorMethod={Selectors.getCachedEvents}
                options={{
                  autocompleteSearchResultContent: (item: unknown): string => {
                    const event = item as IEvent
                    return `${event?.name} ${
                      event?.media?.description
                        ? '- ' + event.media.description
                        : ''
                    }`
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormGen handleChange={handleChange} {...variant} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}
