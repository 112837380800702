import React from 'react'
import { Theme, Box, Card, CardActionArea, CardContent, Typography, SvgIcon } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { ImageIcon } from 'components/icons'
import { MobileAppUser } from 'modules/mobile-app-users/models/entities/mobile-app-user'

const IMAGE_HEIGHT = '100px'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      width: '100%',
      marginBottom: '1.25rem',
      boxShadow: 'none',
      border: '1px solid rgba(0, 0, 0, 0.12)',
    },
    detailsWrapper: {
      display: 'flex',
      height: IMAGE_HEIGHT,
      width: '100%',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    header: {
      display: 'flex',
      width: '100%',
    },
    detail: {
      display: 'flex',
      flexGrow: 1,
      alignItems: 'center',
    },
    cardContent: {
      marginTop: '1.25rem',
      display: 'flex',
      overflow: 'hidden',
      minHeight: 36,
    },
    contentWrapper: {
      display: 'flex',
    },
    cardActionArea: {
      height: '100%',
    },
    coverImageContainer: {
      display: 'flex',
      width: 130,
      height: IMAGE_HEIGHT,
      background: 'rgba(0,0,0,0.03)',
      borderRadius: 10,
      overflow: 'hidden',
      marginRight: '0.625rem',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
    },
  }),
)

interface ICardProps {
  mobileAppUser: MobileAppUser
  onClick: () => void
}

export const MobileAppUserCard: React.FC<ICardProps> = (props: ICardProps) => {
  const { mobileAppUser } = props
  const classes = useStyles()
  const hasCoverImage = !IsNullOrUndefined(
    mobileAppUser.media?.backgroundImageUrl,
  )
  const eventCoverContainerStyles: { [key: string]: string | number } = {}

  if (hasCoverImage) {
    eventCoverContainerStyles.backgroundImage = `url(${mobileAppUser.media!
      .backgroundImageUrl!})`
  } else {
    eventCoverContainerStyles.background = 'rgba(0,0,0,0.03)'
  }

  return (
    <Card className={classes.card}>
      <CardActionArea
        className={classes.cardActionArea}
        onClick={props.onClick}
      >
        <CardContent>
          <Box className={classes.contentWrapper}>
            <Box
              className={classes.coverImageContainer}
              style={eventCoverContainerStyles}
            >
              {!hasCoverImage && (
                <SvgIcon>
                  <ImageIcon />
                </SvgIcon>
              )}
            </Box>
            <Box className={classes.detailsWrapper}>
              <Box className={classes.detail}>
                <Typography variant="subtitle2" color="textPrimary">
                  Name: {props.mobileAppUser.givenName}{' '}
                  {props.mobileAppUser.familyName}
                </Typography>
              </Box>
              <Box className={classes.detail}>
                <Typography variant="subtitle2" color="textPrimary">
                  Username: {props.mobileAppUser.preferredUsername}
                </Typography>
              </Box>
              <Box className={classes.detail}>
                <Typography variant="subtitle2" color="textPrimary">
                  Email: {props.mobileAppUser.email}
                </Typography>
              </Box>
              <Box className={classes.detail}>
                <Typography variant="subtitle2" color="textPrimary">
                  Phone number: {props.mobileAppUser.phoneNumber}
                </Typography>
              </Box>
            </Box>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}
