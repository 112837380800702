import { Selectors } from 'core/alerts'
import { useTypedSelector } from 'core/redux/utils'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

export const Notifier: React.FC<{}> = () => {
  const alerts = useTypedSelector((state) => Selectors.getAlerts(state))
  const [alertedMap, setAlertedMap] = useState<any>({})

  useEffect(() => {
    alerts
      .filter(al => !alertedMap[al.key])
      .forEach(({ key, message, options = {}, dismissed = false }) => {
        if (dismissed) {
          return
        }
        toast(message, { type: options.variant })
        setAlertedMap({ ...alertedMap, [key]: true })
      })
  }, [alerts, alertedMap])

  return null
}
