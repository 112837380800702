import { RequestMethod } from 'types/common/api/enums/request-method'
import paths from './paths'
import params from './params'
import filters from './filters'

const EvergreenQuestEndpoints = {
  getEvergreenQuests: {
    method: RequestMethod.GET,
    path: [paths.evergreenQuests],
    filters,
  },
  getEvergreenQuestById: {
    method: RequestMethod.GET,
    path: [paths.evergreenQuests, params.evergreenQuestId],
  },
  createEvergreenQuest: {
    method: RequestMethod.POST,
    path: [paths.evergreenQuests, paths.add],
  },
  updateEvergreenQuest: {
    method: RequestMethod.PUT,
    path: [paths.evergreenQuests, params.evergreenQuestId, paths.save],
  },
  createEvergreenQuestLevel: {
    method: RequestMethod.POST,
    path: [paths.evergreenQuests, params.evergreenQuestId, paths.levels, paths.add],
  },
  updateEvergreenQuestLevel: {
    method: RequestMethod.PUT,
    path: [paths.evergreenQuests, params.evergreenQuestId, paths.levels, params.evergreenQuestLevelId, paths.update],
  },
  deleteEvergreenQuestById: {
    method: RequestMethod.DELETE,
    path: [paths.evergreenQuests, params.evergreenQuestId],
  },
  uploadEvergreenQuestImage: {
    method: RequestMethod.PUT,
    path: [paths.evergreenQuests, params.evergreenQuestId, paths.levels, params.evergreenQuestLevelId, paths.uploadLevelImage],
  },
}

export default EvergreenQuestEndpoints
