import {
  IsNullOrUndefined,
  IsNullUndefinedOrEmpty,
} from 'core/utils/isNullOrUndefined'
import moment from 'moment-timezone'
import { useEffect } from 'react'
import { Actions as TagActions } from 'modules/tags'
import { Tag } from 'types/modules/tags/models/entities/tag'
import { PostField } from 'types/modules/posts/enums/post-field'
import { PostDifficulty } from 'types/modules/posts/enums/post-difficulty'
import { useTypedSelector } from 'core/redux/utils'
import * as LiveSchedules from 'modules/live-schedules'
import PostType from 'types/modules/posts/enums/post-type'
import { useDispatch } from 'react-redux'
import { LiveScheduleField } from 'types/modules/live-schedules/enums/live-schedule-field'
import { FilterType } from 'types/common/filtering/enums/FilterType'
import { FilterOperator } from 'types/common/filtering/enums/FilterOperator'
import { Selectors as PrizeSelectors } from 'modules/prizes'
import { IAsyncAction } from 'types/redux/interfaces/IAction'
import { IPrize } from 'types/modules/prizes/models/entities/prize'
import PostStatus from 'types/modules/posts/enums/post-status'
import _ from 'core/utils/deepdash'
import { calculateTimeBasedDifficulty } from 'modules/posts/utils/calculate-time-based-difficulty'
import { usePopover } from 'components/popover/use-popover'
import { IFilter } from 'types/common/filtering/models/entities/IFilter'
import SortDirection from 'types/common/enums/sort-direction'
import { ITagActionParams } from 'modules/tags/actions'
import { NIL } from 'uuid'
import { getAdjustedEndDate } from 'utils/date-range'
import { useEventAutoCompleteSearch } from 'hooks/use-event-auto-complete-search'
import { usePostListContext } from 'modules/posts/context/post-list'
import { IPost } from 'types/modules/posts/models/entities/post'
import { ILiveSchedule } from 'types/modules/live-schedules/models/entities/live-schedule'
import { IEvent } from 'types/modules/events/models/entities/event'
import { IEventActionParams } from 'modules/events/actions'
import { IPostDetails } from 'types/modules/posts/models/entities/post-details'

interface IParams {
  postDetails: IPostDetails
  post: IPost
  setFieldValue: any
  setValues: any
}

interface IReturn {
  isCreateMode: boolean
  setStartCloseDate: (
    field: PostField.START | PostField.CLOSE,
    dateTime: string,
    autoSetDifficulty?: boolean,
  ) => void,
  setDifficulty: (difficulty: string) => void,
  disableMultibet: () => void,
  useDisableMultibetPopover: any,
  liveSchedules: ILiveSchedule[],
  searchTags: (params: ITagActionParams) => IAsyncAction,
  selectedEvent: IEvent | null,
  searchEvents: (params: IEventActionParams) => IAsyncAction,
  selectedPrize: IPrize | null,
  getPostPrizeMaxQuantity: (prize: IPrize | null) => number
}

export const usePostFormHelper = ({
  postDetails,
  post,
  setFieldValue,
  setValues
}: IParams): IReturn => {
  const dispatch = useDispatch()
  const { currentPost } = usePostListContext()

  useEffect(() => {
    if (IsNullUndefinedOrEmpty(postDetails.eventId)) return

    dispatch(
      LiveSchedules.Actions.getLiveSchedules({
        filters: [
          {
            field: LiveScheduleField.EVENT_ID,
            type: FilterType.Select,
            operator: FilterOperator.Equals,
            value: postDetails.eventId!,
          },
        ],
      }),
    )
  }, [postDetails.eventId])

  const prizeReward = (postDetails.postPrizes || [])[0]

  const selectedPrize = useTypedSelector((state) =>
    PrizeSelectors.getCachedPrizeById(state, prizeReward?.prizeId),
  )

  const getPostPrizeMaxQuantity = (prize: IPrize | null): number =>
    !IsNullOrUndefined(prize?.item?.quantity) && prize!.item!.quantity! < 10
      ? prize!.item!.quantity!
      : 10

  const { searchEvents, selectedEvent } = useEventAutoCompleteSearch({
    eventId: post.eventId,
  })

  const searchTags = (params: ITagActionParams): IAsyncAction => {
    const filters: IFilter[] = [
      {
        field: 'name',
        type: FilterType.Search,
        operator: FilterOperator.ContainsCaseInsensitive,
        value: params.search!.value,
      },
    ]

    return TagActions.getAutocompleteSearchTags({
      filters,
      sortyBy: 'name',
      sortDirection: SortDirection.Ascending,
    })
  }

  const liveSchedules = useTypedSelector((state) =>
    LiveSchedules.Selectors.getCachedLiveSchedulesByEvent(
      state,
      postDetails.eventId,
    ),
  )

  const hasCoverImage = !IsNullOrUndefined(post.media?.imageUrl)
  const postCoverContainerStyles: { [key: string]: string | number } = {}

  if (hasCoverImage) {
    postCoverContainerStyles.backgroundImage = `url(${post.media!.imageUrl!})`
  } else {
    postCoverContainerStyles.background = 'rgba(0,0,0,0.03)'
  }

  const useDisableMultibetPopover = usePopover({
    id: `disable-multibet-popover`,
  })

  const disableMultibet = (): void => {
    setFieldValue(PostField.BET_CARD, false)
    setFieldValue(PostField.POST_PRIZES, [])
  }

  const setDifficulty = (difficulty: string): void => {
    setFieldValue(PostField.DIFFICULTY, difficulty)
    if (post.type === PostType.Bet) {
      const starsWin = (): number => {
        switch (difficulty) {
          default:
          case PostDifficulty.Easy:
            return 1
          case PostDifficulty.Medium:
            return 2
          case PostDifficulty.Hard:
            return 3
          case PostDifficulty.Impossible:
            return 4
        }
      }
      setFieldValue(PostField.STARS_WIN, starsWin())
    } else if (post.type === PostType.Game) {
      const newValues = ((): { minBet: number; coinsWin: number } => {
        const result = {
          minBet: 10,
          coinsWin: 0,
        }

        switch (difficulty) {
          case PostDifficulty.Easy: {
            result.minBet = 5
            break
          }
          case PostDifficulty.Medium: {
            result.minBet = 10
            break
          }
          case PostDifficulty.Hard: {
            result.minBet = 25
            break
          }
          case PostDifficulty.Impossible: {
            result.minBet = 50
            break
          }
        }

        return result
      })()
      setFieldValue(PostField.MIN_BET, newValues.minBet)
      setFieldValue(PostField.COINS_WIN, newValues.coinsWin)
    }
  }

  const setStartCloseDate = (
    field: PostField.START | PostField.CLOSE,
    dateTime: string,
    autoSetDifficulty: boolean = true,
  ): void => {
    if (
      postDetails[PostField.CLOSE] &&
      PostField.START === field &&
      setValues
    ) {
      const adjustedEndDate = getAdjustedEndDate(
        dateTime,
        postDetails[PostField.START],
        postDetails[PostField.CLOSE],
      )
      setValues({
        ...postDetails,
        [PostField.CLOSE]: adjustedEndDate,
        [field]: dateTime,
      })
      return
    }

    if (
      PostField.CLOSE === field &&
      postDetails.isDailyStack &&
      setValues
    ) {
      setValues({
        ...postDetails,
        [PostField.CLOSE]: dateTime,
        [PostField.START]: postDetails.isDailyStack
          ? moment(dateTime).subtract(1, 'day').toISOString()
          : postDetails.start,
      })
      return
    }
    // eslint-disable-next-line id-blacklist
    setFieldValue(field, dateTime ?? undefined, true)
    const isWeAsked100 = (postDetails[PostField.TAGS] as Tag[]).some(
      (tag) => tag.code === 'we-asked-100',
    )
    const isDaily = (postDetails[PostField.TAGS] as Tag[]).some(
      (tag) => tag.code === 'daily',
    )

    const dateTimes = {
      [PostField.START]: postDetails[PostField.START],
      [PostField.CLOSE]: postDetails[PostField.CLOSE],
    }

    dateTimes[field] = dateTime

    if (field === PostField.START) {
      switch (postDetails[PostField.TYPE]) {
        case PostType.Game: {
          setFieldValue(
            PostField.CLOSE,
            moment(dateTimes[PostField.START]).add(2, 'months').toISOString(),
          )
          break
        }
        case PostType.Poll: {
          setFieldValue(
            PostField.CLOSE,
            moment(dateTimes[PostField.START]).add(1, 'week').toISOString(),
          )
          break
        }
        case PostType.Bet: {
          if (isWeAsked100) {
            setFieldValue(
              PostField.CLOSE,
              moment(dateTimes[PostField.START]).add(1, 'week').toISOString(),
            )
          }
          if (isDaily || postDetails.isDailyStack) {
            setFieldValue(
              PostField.CLOSE,
              moment(dateTimes[PostField.START]).add(1, 'day').toISOString(),
            )
          }
        }
      }
    }

    const canAutoSetDifficulty =
      [
        PostStatus.Created,
        PostStatus.Rejected,
        PostStatus.Pending,
        PostStatus.Scheduled,
      ].includes(postDetails[PostField.STATUS]) &&
      postDetails[PostField.TYPE] === PostType.Bet &&
      autoSetDifficulty &&
      !isWeAsked100 &&
      _.every(dateTimes, (dt) => !IsNullOrUndefined(dt))

    if (canAutoSetDifficulty) {
      setDifficulty(
        calculateTimeBasedDifficulty(
          dateTimes[PostField.START].toString(),
          dateTimes[PostField.CLOSE]!.toString(),
        ),
      )
    }
  }

  const isCreateMode = currentPost?.id === NIL

  return {
    isCreateMode,
    setStartCloseDate,
    setDifficulty,
    disableMultibet,
    useDisableMultibetPopover,
    liveSchedules,
    searchTags,
    selectedEvent,
    searchEvents,
    selectedPrize,
    getPostPrizeMaxQuantity
  }

}