import React, { Component, ReactElement, useEffect } from 'react'

import { IDashboardViewProps } from 'components/content-dashboard/props/dashboard-view-props'
import { Box, CircularProgress, Portal, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { IListViewProps } from 'components/content-dashboard/props/list-view-props'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useRequestContext } from 'core/api/context'
import Pagination from 'core/pagination'
import { getPageSize } from 'modules/common/selectors'
import { useTypedSelector } from 'core/redux/utils'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listContainer: {
      flex: 1,
      flexDirection: 'column',
      overflowY: 'auto',
    },
    legend: {
      display: 'flex',
      flexDirection: 'row',
      background: '#ffffff',
      borderRadius: 4,
      boxShadow: `0px 2px 4px -1px rgb(0 0 0 / 20%),
        0px 4px 5px 0px rgb(0 0 0 / 14%),
        0px 1px 10px 0px rgb(0 0 0 / 12%)`,
      position: 'absolute',
      left: 0,
      bottom: 0,
    },
    infiniteScrollLoader: {
      width: 40,
      height: 40,
      display: 'flex',
      overflow: 'hidden',
      margin: 'auto',
      marginTop: 20,
      marginBottom: 20,
    },
    resultCountContainer: {
      display: 'flex',
      height: 40,
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
  }),
)

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function ListView<TModel>(
  props: IListViewProps<TModel> & IDashboardViewProps,
): ReactElement<any, any> {
  const rc = useRequestContext()
  const pageSize = useTypedSelector((state) => getPageSize(state))

  useEffect(() => {
    rc?.setPage(1)
    rc?.setPageSize(pageSize)
    rc?.setActive(true)

    return (): void => {
      rc?.setPage(null)
      rc?.setPageSize(null)
    }
  }, [])

  const classes = useStyles()

  const renderHeaderPanelComponent = (): JSX.Element | null => {
    const HeaderPanelComponent = props.headerPanelComponent

    return !IsNullOrUndefined(HeaderPanelComponent) ? (
      <Portal container={props.dynamicHeaderPanelRef}>
        {HeaderPanelComponent}
        {!IsNullOrUndefined(props.resultCount) && (
          <Box className={classes.resultCountContainer}>
            <Typography variant="body2" color="textPrimary" component="span">
              Total Results =
            </Typography>
            <Typography
              variant="h6"
              color="textPrimary"
              component="span"
              style={{
                marginLeft: 5,
              }}
            >
              {`${props.resultCount}`}
            </Typography>
          </Box>
        )}
      </Portal>
    ) : null
  }

  const renderToolbarComponent = (): JSX.Element | null => {
    const ToolbarComponent = props.toolbarComponent

    return !IsNullOrUndefined(ToolbarComponent) ? (
      <Portal container={props.dynamicToolbarRef}>{ToolbarComponent}</Portal>
    ) : null
  }

  const renderLegend = (): JSX.Element | null => {
    const LegendComponent = props.legendComponent

    return !IsNullOrUndefined(LegendComponent) ? (
      <Box className={classes.legend}>{LegendComponent}</Box>
    ) : null
  }

  const renderItems = (): JSX.Element[] | null => {
    const ItemComponent = props.listItemComponent

    return (
      props.data?.items.map((item) => (
        <ItemComponent
          key={(item as unknown as any).id}
          {...item}
        ></ItemComponent>
      )) ?? null
    )
  }

  return (
    <React.Fragment>
      {renderHeaderPanelComponent()}
      {renderToolbarComponent()}
      {renderLegend()}
      <Box id="ListContainer" className={classes.listContainer}>
        <InfiniteScroll
          dataLength={props.data?.items?.length!}
          next={(): void => {
            rc?.setPage(rc?.page! + 1)
          }}
          hasMore={props.data?.items?.length! < props.data?.count!}
          loader={<CircularProgress className={classes.infiniteScrollLoader} />}
          scrollThreshold={'400px'}
          scrollableTarget="ListContainer"
        >
          {renderItems()}
        </InfiniteScroll>
      </Box>
    </React.Fragment>
  )
}
