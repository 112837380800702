import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { ActionTypes } from 'modules/live-schedules'
import { ActionTypes as EventActionTypes } from 'modules/events'
import { Reducer } from 'redux'
import { ILiveSchedulesState } from 'types/modules/live-schedules/models/state/live-schedules-state'
import { ISimpleAction, ISuccessAction } from 'types/redux/interfaces/IAction'
import _ from 'core/utils/deepdash'
import { ILiveScheduleActionParams } from 'modules/live-schedules/actions'
import { actionTypeSuccess, cacheItem, cacheItems } from 'core/redux/utils'
import { ILiveSchedule } from 'types/modules/live-schedules/models/entities/live-schedule'
import IPaginatedItems from 'types/common/pagination/models/entities/paginated-items'
import { mergeArrays } from 'core/utils/mergeArrays'
import { NIL as NIL_UUID } from 'uuid'
import { IEvent } from 'types/modules/events/models/entities/event'

const initialState = (): ILiveSchedulesState => ({
  items: [],
  cache: {},
})

type AllowedActionTypes = ISuccessAction | ISimpleAction

const LiveSchedulesReducer: Reducer<ILiveSchedulesState, AllowedActionTypes> = (
  state = initialState(),
  action: AllowedActionTypes,
) => {
  const simpleAction = action as ISimpleAction
  const successAction = action as ISuccessAction

  const currentliveSchedule = !IsNullOrUndefined(state.currentLiveScheduleId)
    ? state.items.find(
        (liveSchedule) => liveSchedule.id === state.currentLiveScheduleId,
      )
    : null

  switch (action.type) {
    case ActionTypes.SET_CURRENT_LIVE_SCHEDULE_ID: {
      const newState = _.cloneDeep(state)
      const { liveScheduleId } =
        simpleAction.payload as ILiveScheduleActionParams

      if (IsNullOrUndefined(liveScheduleId)) {
        if (Object.hasOwnProperty.call(newState, 'currentLiveScheduleId')) {
          const page = (newState as any).page ?? 0
          const pageSize = (newState as any).pageSize ?? 0

          if (
            (page === 1 && newState.items.length > pageSize) ||
            state.currentLiveScheduleId === NIL_UUID
          ) {
            const currentPageIndex = newState.items.findIndex(
              (liveSchedule) => liveSchedule.id === currentliveSchedule?.id,
            )

            _.pullAt(newState.items, currentPageIndex)
          }
          delete newState.currentLiveScheduleId
        }
      } else {
        newState.currentLiveScheduleId = liveScheduleId
      }

      return newState
    }

    case ActionTypes.CREATE_LIVE_SCHEDULE_DRAFT: {
      const newState = _.cloneDeep(state)
      const { item } = simpleAction.payload as ILiveScheduleActionParams

      newState.items = [item as ILiveSchedule, ...state.items]

      newState.currentLiveScheduleId = NIL_UUID

      return newState
    }

    case ActionTypes.CLEAR_LIVE_SCHEDULES: {
      const newState = _.cloneDeep(state)
      if (IsNullOrUndefined(newState.currentLiveScheduleId)) {
        newState.items = []
      } else {
        newState.items = newState.items.filter(
          (post) => post.id !== newState.currentLiveScheduleId,
        )
      }

      return newState
    }

    case actionTypeSuccess(EventActionTypes.GET_EVENT_BY_ID): {
      let newState = _.cloneDeep(state)

      const response = successAction.data as IEvent

      const liveSchedules = response.liveSchedules

      newState = {
        ...newState,
        items: liveSchedules,
        cache: cacheItems(state.cache, liveSchedules),
      }

      return newState
    }

    case actionTypeSuccess(ActionTypes.GET_LIVE_SCHEDULES): {
      let newState = _.cloneDeep(state)
      const response = successAction.data as IPaginatedItems<ILiveSchedule>
      const page = successAction.payload!.page

      const pageIsFirstOrNull = IsNullOrUndefined(page) || page === 1

      const currentScheduleIsNullOrResponseContainsCurrentSchedule =
        IsNullOrUndefined(currentliveSchedule) ||
        response.items.some(
          (liveSchedule) => liveSchedule.id === currentliveSchedule!.id,
        )

      if (
        pageIsFirstOrNull &&
        currentScheduleIsNullOrResponseContainsCurrentSchedule
      ) {
        newState = {
          ...newState,
          ...response,
          pageSize: response.pageSize ?? response.count,
          cache: cacheItems(state.cache, response.items),
        }
      } else {
        newState = {
          ...newState,
          ...response,
          items: mergeArrays(state.items, response.items),
          cache: cacheItems(state.cache, response.items),
        }
      }
      return newState
    }

    case actionTypeSuccess(ActionTypes.GET_LIVE_SCHEDULE_BY_ID): {
      const newState = _.cloneDeep(state)
      const response = successAction.data as ILiveSchedule
      const liveScheduleIndex = state.items.findIndex(
        (x) => x.id === response.id,
      )

      if (liveScheduleIndex < 0) {
        newState.items = [...newState.items, response]
      } else {
        newState.items[liveScheduleIndex] = response
      }

      return newState
    }

    case actionTypeSuccess(ActionTypes.UPDATE_LIVE_SCHEDULE): {
      const newState = _.cloneDeep(state)
      const response = successAction.data as ILiveSchedule

      const liveScheduleIndex = state.items.findIndex(
        (event) => event.id === response.id,
      )

      if (liveScheduleIndex >= 0) {
        newState.items[liveScheduleIndex] = {
          ...newState.items[liveScheduleIndex],
          ...response,
        }
      }

      newState.cache = cacheItem(state.cache, response)

      return newState
    }

    case actionTypeSuccess(ActionTypes.CREATE_LIVE_SCHEDULE): {
      const newState = _.cloneDeep(state)
      const response = successAction.data as ILiveSchedule

      newState.currentLiveScheduleId = response.id

      newState.items = [
        ...[response],
        ...state.items.filter((liveSchedule) => liveSchedule.id !== NIL_UUID),
      ]

      newState.cache = cacheItem(state.cache, response)

      return newState
    }

    default:
      return state
  }
}

export default LiveSchedulesReducer
