import {
  Box,
  Chip,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SvgIcon,
  Switch,
  TextField,
  Theme,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { AutocompleteSearchSelect } from 'components/controls/inputs/downshift/autocomplete/autocomplete-search-select'
import { CoinIcon, ImageIcon, StarIcon } from 'components/icons'
import { ImageUploader } from 'components/image-uploader'
import { MediaUploadModel } from 'types/common/images/models/entities/image-upload-model'
import { useTypedSelector } from 'core/redux/utils'
import {
  IsNullOrUndefined,
  IsNullUndefinedOrEmpty,
} from 'core/utils/isNullOrUndefined'
import { getIn } from 'formik'
import { useEventsContext } from 'modules/events/context'
import { IEventFormProps } from 'modules/events/views/components/event-details-panel/types/props/event-form-props'
import {
  getAutocompleteSearchTagGroups,
  getTagGroupById,
  ITagActionParams,
} from 'modules/tags/actions'
import {
  getCachedTagGroupById,
  getCachedTagGroups,
} from 'modules/tags/selectors'
import moment from 'moment-timezone'
import React, { useEffect } from 'react'
import { locale } from 'types/common/enums/locale'
import { FilterOperator } from 'types/common/filtering/enums/FilterOperator'
import { FilterType } from 'types/common/filtering/enums/FilterType'
import { EventFieldName as EventField } from 'types/modules/events/enums/event-field'
import { Tag } from 'types/modules/tags/models/entities/tag'
import { TagGroup } from 'types/modules/tags/models/entities/tag-group'
import { IAsyncAction } from 'types/redux/interfaces/IAction'
import { MediaItemInput } from 'modules/media-items/media-item-input'
import { AspectRatio } from 'types/common/images/enum/aspect-ratio'
import { MediaOrientation } from 'types/common/images/enum/media-orientation'
import { MediaItem } from 'types/modules/media-items/models/entities/media-item'
import _ from 'core/utils/deepdash'
import { createErrors } from 'utils/validation-helper'
import { getAdjustedEndDate } from 'utils/date-range'
import { Duration } from 'components/duration'
import { useDispatch } from 'react-redux'
import { DateTimePicker } from '@mui/x-date-pickers'
import { Currency } from 'fe-shared-resources'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    eventDetailsForm: {
      width: '100%',
      height: 'auto',
      '& .MuiGrid-item': {
        display: 'flex',
        flexDirection: 'column',
      },
    },
    inputLabel: {
      display: 'flex',
      marginBottom: '0.625rem',
    },
    switches: {
      display: 'flex',
      flex: 1,
      justifyContent: 'space-between',
    },
    switchLabel: {
      display: 'flex',
      marginBottom: '0.625rem',
    },
    coverImageContainer: {
      display: 'flex',
      flex: 1,
      width: '100%',
      height: '100%',
      background: 'rgba(0,0,0,0.03)',
      color: 'rgba(0,0,0,0.54)',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      justifyContent: 'center',
      alignItems: 'center',
    },
    adornmentIcon: {
      display: 'flex',
      width: 24,
      height: 24,
      marginRight: 12,
    },
    chip: {
      margin: theme.spacing(0.5),
      boxSizing: 'border-box',
      background: '#ffffff',
      border: '2px solid rgba(51,51,51,0.34)',
    },
    imageWrapper: {
      marginBottom: 16,
    },
    formWrapper: {
      height: '100%',
    },
  }),
)

export const EventDetailsForm: React.FC<IEventFormProps> = (
  props: IEventFormProps,
) => {
  const classes = useStyles()
  const {
    uploadEventImage,
    uploadEventPassImage,
    updateEvent,
    uploadDefaultLiveScheduleImage,
  } = useEventsContext()
  const dispatch = useDispatch()
  const { event, handleChange, setFieldValue, errors, setValues } = props
  const formErrors = createErrors(errors)

  const selectedTagGroup = useTypedSelector((state) =>
    getCachedTagGroupById(state, props.event.tagGroupId),
  )

  const requiredTagGroupNotLoaded = !selectedTagGroup && props.event.tagGroupId

  useEffect(() => {
    if (requiredTagGroupNotLoaded) {
      dispatch(getTagGroupById({ tagGroupId: props.event.tagGroupId }))
    }
  }, [requiredTagGroupNotLoaded])

  const selectedTagGroupTags = selectedTagGroup?.tags as Tag[]

  const searchTagGroups = (params: ITagActionParams): IAsyncAction => {
    const filters = [
      {
        field: 'name',
        type: FilterType.Search,
        operator: FilterOperator.ContainsCaseInsensitive,
        value: params.search!.value,
      },
    ]

    return getAutocompleteSearchTagGroups({
      filters,
    })
  }

  const hasCoverImage = !IsNullOrUndefined(event.media?.imageUrl)
  const eventCoverContainerStyles: { [key: string]: string | number } = {}

  if (hasCoverImage) {
    eventCoverContainerStyles.backgroundImage = `url(${event.media!.imageUrl!})`
  } else {
    eventCoverContainerStyles.background = 'rgba(0,0,0,0.03)'
  }

  const hasEventPassImage = !IsNullOrUndefined(event.media?.passImageUrl)
  const eventPassContainerStyles: { [key: string]: string | number } = {}

  if (hasEventPassImage) {
    eventPassContainerStyles.backgroundImage = `url(${event.media!
      .passImageUrl!})`
  } else {
    eventPassContainerStyles.background = 'rgba(0,0,0,0.03)'
  }

  if (requiredTagGroupNotLoaded) return null

  return (
    <React.Fragment>
      <Box className={classes.eventDetailsForm}>
        <Grid container spacing={2} className={classes.formWrapper}>
          <Grid item xs={4}>
            <Grid item xs={12} className={classes.imageWrapper}>
              <InputLabel className={classes.inputLabel}>
                Cover Image
              </InputLabel>
              <MediaItemInput
                url={event.media?.imageUrl}
                imageLabel={'Cover Image'}
                mediaItemId={event.media?.mediaItemId}
                options={{
                  aspectRatio: AspectRatio.Standard,
                  orientation: MediaOrientation.Portrait,
                }}
                onComplete={(fileParams: MediaUploadModel): void => {
                  uploadEventImage(event.id!, fileParams)
                }}
                onCreate={(mediaItem: MediaItem): void => {
                  const newEvent = _.cloneDeep(event)
                  newEvent.media = {
                    ...(newEvent.media ?? {}),
                    mediaItemId: mediaItem.id,
                  }
                  updateEvent(newEvent)
                }}
              />
            </Grid>
            <Grid item xs={12} className={classes.imageWrapper}>
              <InputLabel className={classes.inputLabel}>
                Event Pass Image
              </InputLabel>
              <MediaItemInput
                url={event.media?.passImageUrl}
                imageLabel={'Pass Image'}
                mediaItemId={event.media?.passMediaItemId}
                options={{
                  aspectRatio: AspectRatio.Square,
                  orientation: MediaOrientation.Portrait,
                }}
                onComplete={(fileParams: MediaUploadModel): void => {
                  uploadEventPassImage(event.id!, fileParams)
                }}
                onCreate={(mediaItem: MediaItem): void => {
                  const newEvent = _.cloneDeep(event)
                  newEvent.media = {
                    ...(newEvent.media ?? {}),
                    passMediaItemId: mediaItem.id,
                  }
                  updateEvent(newEvent)
                }}
              />
            </Grid>
            <Grid item xs={12} className={classes.imageWrapper}>
              <InputLabel className={classes.inputLabel}>
                Default Live Image
              </InputLabel>
              <MediaItemInput
                url={event.media?.defaultLiveScheduleImageUrl}
                imageLabel={'Default Live Image'}
                mediaItemId={event.media?.defaultLiveScheduleMediaItemId}
                options={{
                  aspectRatio: AspectRatio.Square,
                  orientation: MediaOrientation.Portrait,
                }}
                onComplete={(fileParams: MediaUploadModel): void => {
                  uploadDefaultLiveScheduleImage(event.id!, fileParams)
                }}
                onCreate={(mediaItem: MediaItem): void => {
                  const newEvent = _.cloneDeep(event)
                  newEvent.media = {
                    ...(newEvent.media ?? {}),
                    defaultLiveScheduleMediaItemId: mediaItem.id,
                  }
                  updateEvent(newEvent)
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={8}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <InputLabel
                  htmlFor={EventField.NAME}
                  className={classes.inputLabel}
                >
                  Name
                </InputLabel>
                <TextField
                  id={EventField.NAME}
                  name={EventField.NAME}
                  onChange={handleChange as React.ChangeEventHandler<HTMLInputElement>}
                  value={event[EventField.NAME]}
                  variant="filled"
                  fullWidth
                  {...formErrors[EventField.NAME]}
                />
              </Grid>
              <Grid item xs={9}>
                <InputLabel
                  htmlFor={`${EventField.MEDIA}[${EventField.DESCRIPTION}]`}
                  className={classes.inputLabel}
                >
                  Description
                </InputLabel>
                <TextField
                  id={`${EventField.MEDIA}[${EventField.DESCRIPTION}]`}
                  name={`${EventField.MEDIA}[${EventField.DESCRIPTION}]`}
                  onChange={handleChange as React.ChangeEventHandler<HTMLInputElement>}
                  value={getIn(
                    event,
                    `${EventField.MEDIA}[${EventField.DESCRIPTION}]`,
                  )}
                  variant="filled"
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <InputLabel
                  htmlFor={`${EventField.MEDIA}[${EventField.ICON}]`}
                  className={classes.inputLabel}
                >
                  Icon
                </InputLabel>
                <TextField
                  id={`${EventField.MEDIA}[${EventField.ICON}]`}
                  name={`${EventField.MEDIA}[${EventField.ICON}]`}
                  onChange={handleChange as React.ChangeEventHandler<HTMLInputElement>}
                  value={getIn(
                    event,
                    `${EventField.MEDIA}[${EventField.ICON}]`,
                  )}
                  variant="filled"
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <InputLabel
                  htmlFor={EventField.START}
                  className={classes.inputLabel}
                >
                  Start Date
                </InputLabel>
                <DateTimePicker
                  value={moment(event[EventField.START])}
                  onChange={(date): void => {
                    const newValue = moment(
                      date?.utc().toISOString(),
                    ).toISOString()
                    const setDateValues = (): void => {
                      if (!setValues) return
                      if (
                        IsNullUndefinedOrEmpty(event.end) ||
                        moment(event.end).diff(
                          moment(date?.utc().toISOString()),
                          'days',
                        ) < 10
                      ) {
                        setValues({
                          ...event,
                          [EventField.END]: moment(
                            moment(date?.utc().toISOString()),
                          )
                            .add(10, 'd')
                            .toISOString(),
                          [EventField.START]: newValue,
                        })
                      } else {
                        const adjustedEndDate = getAdjustedEndDate(
                          newValue,
                          event[EventField.START],
                          event[EventField.END],
                        )
                        setValues({
                          ...event,
                          [EventField.END]: adjustedEndDate,
                          [EventField.START]: newValue,
                        })
                      }
                    }
                    setDateValues()
                  }}
                  format={'YYYY-MM-DD HH:mm'}
                  name="start"
                />
              </Grid>
              <Grid item xs={6}>
                <InputLabel
                  htmlFor={EventField.END}
                  className={classes.inputLabel}
                >
                  End Date
                </InputLabel>
                <DateTimePicker
                  value={
                    IsNullOrUndefined(event[EventField.END])
                      ? null
                      : moment(event[EventField.END])
                  }
                  onChange={(date): void => {
                    setFieldValue(
                      EventField.END,
                      moment(date?.utc().toISOString()).toISOString(),
                    )
                  }}
                  format={'YYYY-MM-DD HH:mm'}
                  minDate={moment(event[EventField.START])}
                  {...formErrors[EventField.END]}
                />
              </Grid>
              <Duration
                end={event[EventField.END]}
                start={event[EventField.START]}
              />
              <Grid item xs={4}>
                <InputLabel
                  htmlFor={EventField.CURRENCY}
                  className={classes.inputLabel}
                >
                  Currency
                </InputLabel>
                <Select
                  id={EventField.CURRENCY}
                  name={EventField.CURRENCY}
                  value={event[EventField.CURRENCY]}
                  onChange={handleChange}
                  variant="filled"
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    // getContentAnchorEl: null,
                  }}
                  fullWidth
                >
                  <MenuItem value=""></MenuItem>
                  {Object.values(Currency).map((currency) => (
                    <MenuItem key={currency} value={currency}>
                      {`${currency}s`}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={4}>
                <InputLabel
                  htmlFor={EventField.AMOUNT}
                  className={classes.inputLabel}
                >
                  Amount
                </InputLabel>
                <TextField
                  id={EventField.AMOUNT}
                  name={EventField.AMOUNT}
                  value={event[EventField.AMOUNT]}
                  onChange={handleChange as React.ChangeEventHandler<HTMLInputElement>}
                  variant="filled"
                  type="number"
                  fullWidth
                  InputProps={{
                    inputProps: {
                      min: 0,
                    },
                    endAdornment: !IsNullUndefinedOrEmpty(
                      event[EventField.CURRENCY],
                    ) && (
                      <InputAdornment position="end">
                        <Box className={classes.adornmentIcon}>
                          {event[EventField.CURRENCY] === Currency.Coin ? (
                            <CoinIcon />
                          ) : (
                            <StarIcon />
                          )}
                        </Box>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <InputLabel
                  htmlFor={EventField.LOCALE}
                  className={classes.inputLabel}
                >
                  Locale
                </InputLabel>
                <Select
                  id={EventField.LOCALE}
                  name={EventField.LOCALE}
                  value={event[EventField.LOCALE]}
                  onChange={handleChange}
                  variant="filled"
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    // getContentAnchorEl: null,
                  }}
                  displayEmpty
                  fullWidth
                >
                  <MenuItem value="">&nbsp;</MenuItem>
                  {Object.entries(locale).map(([label, value]) => (
                    <MenuItem key={label} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12}>
                <InputLabel className={classes.inputLabel}>
                  Attributes
                </InputLabel>
                <FormGroup className={classes.switches}>
                  <FormControlLabel
                    className={classes.switchLabel}
                    control={
                      <Switch
                        color="primary"
                        id={EventField.STOP_SCHEDULED_CLOSE}
                        name={EventField.STOP_SCHEDULED_CLOSE}
                        checked={event[EventField.STOP_SCHEDULED_CLOSE]}
                        onChange={(event, checked): void => {
                          setFieldValue(
                            EventField.STOP_SCHEDULED_CLOSE,
                            checked,
                          )
                        }}
                      />
                    }
                    label={<InputLabel>Prevent Scheduled Close</InputLabel>}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <InputLabel className={classes.inputLabel}>
                  Auto Parlay
                </InputLabel>
                <FormGroup className={classes.switches}>
                  <FormControlLabel
                    className={classes.switchLabel}
                    control={
                      <Switch
                        color="primary"
                        id={`${EventField.MEDIA}[${EventField.AUTO_PARLAY}]`}
                        name={`${EventField.MEDIA}[${EventField.AUTO_PARLAY}]`}
                        checked={event[EventField.MEDIA]?.[EventField.AUTO_PARLAY]}
                        onChange={(event, checked): void => {
                          setFieldValue(
                            `${EventField.MEDIA}[${EventField.AUTO_PARLAY}]`,
                            checked,
                          )
                        }}
                      />
                    }
                    label={<InputLabel>Show real money</InputLabel>}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <InputLabel className={classes.inputLabel}>
                  Timezone
                </InputLabel>
                <FormGroup className={classes.switches}>
                  <FormControlLabel
                    className={classes.switchLabel}
                    control={
                      <Switch
                        color="primary"
                        id={EventField.HAS_TIMEZONE}
                        name={EventField.HAS_TIMEZONE}
                        checked={event[EventField.HAS_TIMEZONE]}
                        onChange={(event, checked): void => {
                          setFieldValue(
                            EventField.HAS_TIMEZONE,
                            checked,
                          )
                        }}
                      />
                    }
                    label={<InputLabel>Enable timezone for schedules</InputLabel>}
                  />
                </FormGroup>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <InputLabel className={classes.inputLabel}>
                  Tag Group
                </InputLabel>
                <AutocompleteSearchSelect
                  variant="filled"
                  initialSelectedItem={selectedTagGroup}
                  availableItems={[]}
                  onSelectedItemChange={(
                    selectedTagGroup: TagGroup | null,
                  ): void => {
                    setFieldValue(
                      EventField.TAG_GROUP_ID,
                      selectedTagGroup?.id ?? '',
                    )
                  }}
                  searchAsyncActionCreator={searchTagGroups}
                  selectorMethod={getCachedTagGroups}
                  options={{
                    autocompleteSearchResultContent: (
                      item: unknown,
                    ): string => {
                      const tagGroup = item as TagGroup
                      return `${tagGroup?.name ?? ''}`
                    },
                  }}
                />
                {!IsNullOrUndefined(selectedTagGroup) &&
                  !IsNullUndefinedOrEmpty(selectedTagGroup?.tags) && (
                    <div
                      style={{
                        width: '100%',
                        marginTop: 25,
                        maxHeight: 218,
                        overflow: 'auto',
                      }}
                    >
                      {selectedTagGroupTags.map((tag: Tag, index: number) => (
                        <Chip
                          key={`chipIndex-${index}-itemId-${tag.id}`}
                          className={classes.chip}
                          label={tag.name}
                          // eslint-disable-next-line id-blacklist
                          icon={
                            tag.media?.icon ? (
                              <i>{tag.media?.icon}</i>
                            ) : undefined
                          }
                        />
                      ))}
                    </div>
                  )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  )
}
