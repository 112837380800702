import FilterToolbar, {
  IFilterToolbarProps,
} from 'core/filtering/components/filter-toolbar'
import React from 'react'
import SortDirection from 'types/common/enums/sort-direction'

export const UserPrizeListToolbar: React.FC<{}> = () => {
  const toolbarProps = {
    searchFilterConfig: {
      field: 'usernameOrEmail',
    },
    sortOptions: [
      { label: 'Created', value: 'createdAt' },
      { label: 'Last Updated', value: 'updatedAt' },
    ],
    initialSortValue: {
      sortBy: 'updatedAt',
      sortDirection: SortDirection.Descending,
    },
  } as IFilterToolbarProps

  return <FilterToolbar {...toolbarProps} />
}
