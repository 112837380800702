import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { colors } from 'utils/colors'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    panelHeader: {
      display: 'flex',
      width: '100%',
      flexShrink: 0,
      height: 72,
    },
    panelTitle: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      padding: '0 0 0 1.25rem',
    },
    rightHeaderSection: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      padding: '0 1.25rem 0 0',
      flexDirection: 'row-reverse',
      '& .MuiButton-contained': {
        marginRight: 20,
      },
    },
    contentWrapper: {
      display: 'flex',
      flexGrow: 1,
      overflow: 'hidden',
    },
    contentPanel: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      padding: '1.25rem',
      overflow: 'auto',
    },
    sidebarPanel: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      borderRight: `1px solid ${colors.rgba_0_0_0_12}`,
      '&:last-child': {
        border: 'none',
      },
    },
    imageUploadContainer: {
      display: 'flex',
      width: '100%',
      height: '100%',
    },
    transformationPanel: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      flex: 3,
      borderRight: `1px solid ${colors.rgba_0_0_0_12}`,
      '&:last-child': {
        border: 'none',
      },
    },
    inputLabel: {
      display: 'flex',
      marginBottom: '0.625rem',
    },
    cropContainer: {
      position: 'relative',
      display: 'flex',
      flex: 1,
    },
    footerPanel: {
      borderRight: `1px solid ${colors.rgba_0_0_0_12}`,
      display: 'flex',
      width: '100%',
      justifySelf: 'flex-end',
    },
    footerSection: {
      display: 'flex',
      padding: '1.25rem',
      flex: 1,
      justifyContent: 'space-between',
    },
    leftFooterSection: {
      display: 'flex',
      padding: '1.25rem',
      flex: 1,
      justifyContent: 'flex-start',
    },
    rightFooterSection: {
      display: 'flex',
      padding: '1.25rem',
      flex: 1,
      justifyContent: 'flex-end',
    },
    zoomSlider: {
      display: 'flex',
      flex: 1,
    },
    updateButton: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    cancelButton: {
      color: theme.palette.text.primary,
      backgroundColor: '#ecf0f1',
      '&:hover': {
        backgroundColor: '#f2f5f5',
      },
    },
    imageUploaderButton: {
      display: 'flex',
      flex: 1,
      width: '100%',
      height: '100%'
    },
    imageWithoutCropper: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      marginTop: 30
    }
  }),
)

export const useModalStyles = (): any => {
  const classes = useStyles()
  return classes
}
