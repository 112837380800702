import { Box, Button, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useEventsContext } from 'modules/events/context'
import React from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    eventsListHeaderPanel: {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      minWidth: 250,
      '& .MuiButton-contained': {
        marginBottom: 20,
        '&:last-of-type': {
          marginBottom: 0,
        },
      },
    },
    createEventButton: {
      color: theme.palette.primary.contrastText,
      backgroundColor: '#ff787d',
      '&:hover': {
        backgroundColor: '#ff999c',
      },
    },
  }),
)

export const EventsListHeaderPanel: React.FC<{}> = () => {
  const classes = useStyles()
  const { createEvent } = useEventsContext()
  return (
    <Box className={classes.eventsListHeaderPanel}>
      <Button
        variant="contained"
        size="large"
        className={classes.createEventButton}
        onClick={(): void => createEvent()}
      >
        Create Event
      </Button>
    </Box>
  )
}
