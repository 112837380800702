import { Theme, Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { ICalendarGridCellProps } from 'components/calendar/props/calendar-grid-cell-props'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import React from 'react'
import { IPost } from 'types/modules/posts/models/entities/post'
import _ from 'core/utils/deepdash'
import PostType from 'types/modules/posts/enums/post-type'
import { CircleIcon } from 'components/icons'
import clsx from 'clsx'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    postGridCellContainer: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      '&:hover': {
        background: 'rgba(0,0,0,0.03)',
        cursor: 'pointer',
      },
    },
    event: {
      display: 'flex',
      alignItems: 'center',
    },
    eventLabel: {
      fontSize: '0.7rem',
      position: 'relative',
      top: 2,
    },
    circleIcon: {
      width: 10,
      height: 10,
      borderRadius: 90,
      background: theme.palette.text.primary,
      marginRight: '0.3rem',
      '&.Bet': {
        background: '#ff787d',
      },
      '&.Retro': {
        background: '#FDD219',
      },
      '&.Poll': {
        background: '#06B2BB',
      },
      '&.Test': {
        background: '#F78828',
      },
    },
  }),
)

export const PostCalendarDateGridCell: React.FC<
  ICalendarGridCellProps<IPost>
> = (props: ICalendarGridCellProps<IPost>) => {
  const { items, dateTime } = props

  const classes = useStyles()

  const renderCellContent = (): JSX.Element | null => {
    if (IsNullOrUndefined(items)) return null

    const itemsSortedByType = _.sortBy(items, (item) => item.type)

    const itemsGroupedByType = _.groupBy(itemsSortedByType, (item: IPost) => {
      switch (item.type) {
        case PostType.Game:
          return 'Retro'
        default:
          return item.type
      }
    })

    return (
      <React.Fragment>
        {_.map(itemsGroupedByType, (posts, postType) => {
          return (
            <Box className={classes.event}>
              <Box
                component="span"
                className={clsx(classes.circleIcon, postType)}
              />
              <Typography
                variant="body2"
                color="textPrimary"
                className={clsx(classes.eventLabel)}
              >
                {`${posts.length} ${
                  posts.length < 2 ? postType : postType + 's'
                }`}
              </Typography>
            </Box>
          )
        })}
      </React.Fragment>
    )
  }

  return (
    <Box className={classes.postGridCellContainer}>{renderCellContent()}</Box>
  )
}
