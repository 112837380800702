import { ChestEndpoints } from 'core/api/endpoints/chests'
import { createAction, createAsyncAction } from 'core/redux/utils'
import { ActionTypes } from 'modules/chests/action-types'
import { Chest } from 'modules/chests/models/entities/chest'
import { IAsyncActionParams } from 'types/common/api/models/entities/async-action-params'
import { IAsyncAction, ISimpleAction } from 'types/redux/interfaces/IAction'

export interface IChestActionParams extends IAsyncActionParams<Chest> {
  chestId?: string
  description?: string
  maxRewardsInChest?: number
  minRewardsInChest?: number
}

export const setCurrentChestId: (
  params: IChestActionParams,
) => ISimpleAction = (params) =>
  createAction(ActionTypes.SET_CURRENT_CHEST_ID, params)

export const getChest: (params: IChestActionParams) => IAsyncAction = (
  params,
) => createAsyncAction({ type: ActionTypes.GET_CHEST, endpoint: ChestEndpoints.getChest, payload: params})

export const getChests: (params: IChestActionParams) => IAsyncAction = (
  params,
) => createAsyncAction({ type: ActionTypes.GET_CHESTS, endpoint: ChestEndpoints.getChests, payload: params })

export const getAutocompleteSearchChests: <
  TActionParamsType = IChestActionParams,
>(
  params: TActionParamsType,
) => IAsyncAction = (params) =>
  createAsyncAction({
    type: ActionTypes.GET_AUTOCOMPLETE_SEARCH_CHESTS,
    endpoint: ChestEndpoints.getChests,
    payload: params,
  })

export const createChest: (params: IChestActionParams) => IAsyncAction = (
  params,
) =>
  createAsyncAction({
    type: ActionTypes.CREATE_CHEST,
    endpoint: ChestEndpoints.createChest,
    payload: params,
  })

export const updateChest: (params: IChestActionParams) => IAsyncAction = (
  params,
) =>
  createAsyncAction({
    type: ActionTypes.UPDATE_CHEST,
    endpoint: ChestEndpoints.updateChest,
    payload: params,
  })

export const createChestDraft: (params: IChestActionParams) => ISimpleAction = (
  params,
) => createAction(ActionTypes.CREATE_CHEST_DRAFT, params)

export const uploadChestImage: (params: IChestActionParams) => IAsyncAction = (
  params,
) =>
  createAsyncAction({
    type: ActionTypes.UPLOAD_CHEST_IMAGE,
    endpoint: ChestEndpoints.uploadChestImage,
    payload: params,
  })
