import { Box, Chip, Grid, InputLabel, TextField, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { MediaUploadModel } from 'types/common/images/models/entities/image-upload-model'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { getIn } from 'formik'
import { round } from 'lodash-es'
import { IResultFormProps } from 'modules/posts/views/components/manage-post-panel/types/result-form-props'
import React from 'react'
import { ResultField } from 'types/modules/posts/enums/result-field'
import { AspectRatio } from 'types/common/images/enum/aspect-ratio'
import { MediaOrientation } from 'types/common/images/enum/media-orientation'
import _ from 'core/utils/deepdash'
import { MediaItem } from 'types/modules/media-items/models/entities/media-item'
import { MediaItemInput } from 'modules/media-items/media-item-input'
import { Volume } from 'modules/posts/views/components/manage-post-panel/volume'
import { LabelWithCharLimit } from 'modules/posts/views/components/manage-post-panel/label-with-char-limit'
import { MAX_RESULT_DESCRIPTION } from 'modules/posts/constants'
import { usePostResultsManager } from 'modules/posts/context/post-results-manager'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    postResultForm: {
      width: '100%',
      height: 'auto',
      '& .MuiGrid-item': {
        display: 'flex',
        flexDirection: 'column',
      },
    },
    inputLabel: {
      display: 'flex',
      marginBottom: '0.625rem',
    },
    imageContainer: {
      display: 'flex',
      flex: 1,
      width: '100%',
      height: '100%',
      background: 'rgba(0,0,0,0.03)',
      color: 'rgba(0,0,0,0.54)',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
)

export const ResultView: React.FC<IResultFormProps> = (
  props: IResultFormProps,
) => {
  const { uploadResultImage, updatePostResult, outcomeTotal } =
    usePostResultsManager()
  const { result, post, handleChange, setFieldValue, errors } = props

  const hasImage = !IsNullOrUndefined(result.media?.imageUrl)
  const imageContainerStyles: { [key: string]: string | number } = {}

  if (hasImage) {
    imageContainerStyles.backgroundImage = `url(${result.media!.imageUrl!})`
  } else {
    imageContainerStyles.background = 'rgba(0,0,0,0.03)'
  }

  const resultNumber = post.results.findIndex((r) => r.id === result.id) + 1

  const classes = useStyles()

  return (
    <Box className={classes.postResultForm}>
      <Typography
        variant="subtitle1"
        color="textPrimary"
        style={{ marginBottom: '1.25rem' }}
      >
        {`Result ${resultNumber}`}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <InputLabel className={classes.inputLabel}>Image</InputLabel>
          <MediaItemInput
            url={result.media?.imageUrl}
            imageLabel={'Result Image'}
            mediaItemId={result.media?.mediaItemId}
            options={{
              aspectRatio: AspectRatio.Square,
              orientation: MediaOrientation.Portrait,
            }}
            onComplete={(fileParams: MediaUploadModel): void => {
              uploadResultImage(post.id, result.id, fileParams)
            }}
            onCreate={(mediaItem: MediaItem): void => {
              const newResult = _.cloneDeep(result)
              newResult.media = {
                ...(newResult.media ?? {}),
                mediaItemId: mediaItem.id,
              }
              updatePostResult(newResult)
            }}
          />
        </Grid>
        <Grid item xs={9}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputLabel
                htmlFor={ResultField.TEXT}
                className={classes.inputLabel}
              >
                Title
              </InputLabel>
              <TextField
                id={ResultField.TEXT}
                name={ResultField.TEXT}
                onChange={handleChange as React.ChangeEventHandler<HTMLInputElement>}
                value={result[ResultField.TEXT]}
                variant="filled"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <LabelWithCharLimit
                htmlFor={`${ResultField.MEDIA}[${ResultField.DESCRIPTION}]`}
                className={classes.inputLabel}
                label="Description"
                limit={MAX_RESULT_DESCRIPTION}
                valueLength={(result?.media?.description || '').length}
              />

              <TextField
                id={`${ResultField.MEDIA}[${ResultField.DESCRIPTION}]`}
                name={`${ResultField.MEDIA}[${ResultField.DESCRIPTION}]`}
                value={getIn(
                  result,
                  `${ResultField.MEDIA}[${ResultField.DESCRIPTION}]`,
                )}
                onChange={(event): void => {
                  setFieldValue(
                    `${ResultField.MEDIA}[${ResultField.DESCRIPTION}]`,
                    event.target.value,
                  )
                }}
                multiline
                rows={5}
                variant="filled"
                fullWidth
                helperText={errors?.media?.description}
                error={!!errors?.media?.description}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Volume total={outcomeTotal} volume={result.totalOutcomes} />
        </Grid>
      </Grid>
    </Box>
  )
}
