import { Reducer } from 'react'
import { ISimpleAction } from 'types/redux/interfaces/IAction'
import ActionTypes from 'core/filtering/action-types'
import _ from 'core/utils/deepdash'
import { IFilter } from 'types/common/filtering/models/entities/IFilter'

const FiltersReducer: Reducer<IFilter[], ISimpleAction> = (
  state = [],
  action: ISimpleAction,
) => {
  const newState = _.clone(state)
  const payload = action.payload as IFilter

  const existingFilterIndex = newState.findIndex((filter) => {
    return filter.field === payload.field
  })

  const filterExists = existingFilterIndex >= 0

  switch (action.type) {
    case ActionTypes.APPLY_FILTER:
      if (filterExists) {
        newState[existingFilterIndex] = payload
      } else {
        newState.push(payload)
      }
      return newState
    case ActionTypes.REMOVE_FILTER:
      if (filterExists) {
        _.pullAt(newState, existingFilterIndex)
        return newState
      }
  }

  return state
}

export default FiltersReducer
