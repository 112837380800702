import React from 'react'
import { useTypedSelector } from 'core/redux/utils'
import { useEvergreenQuestsContext } from 'modules/quests/context/evergreen-quest-context'
import {
  EvergreenQuestCard,
  StyledTableCell,
} from 'modules/quests/view/evergreen/evergeen-quest-card'
import { EvergreenQuestModal } from 'modules/quests/view/evergreen/evergreen-quest-modal'
import { IEvergreenQuest } from 'modules/quests/models/evergreen-quest'
import { LocalListWrapper } from 'components/local-list-wrapper'
import { EvergreenQuestActions, Selectors } from '../..'

const sortOptions = [
  { name: 'Created at', value: 'createdAt', descending: true },
  { name: 'Name', value: 'name' },
]

export const EvergreenQuestList: React.FC<{}> = () => {
  const questGroups = useTypedSelector((state) =>
    Selectors.getEvergreenQuests(state),
  )
  const count = useTypedSelector((state) =>
    Selectors.getEvergreenQuestsCount(state),
  )
  const {
    currentEvergreenQuest,
    createEvergreenQuestDraft,
    setCurrentEvergreenQuest,
  } = useEvergreenQuestsContext()

  return (
    <LocalListWrapper<
      IEvergreenQuest,
      EvergreenQuestActions.IEvergreenQuestActionParams,
      null
    >
      list={questGroups}
      count={count}
      searchKey="name"
      currentItem={currentEvergreenQuest}
      createDraft={createEvergreenQuestDraft}
      setCurrentItem={setCurrentEvergreenQuest}
      CardComponent={EvergreenQuestCard}
      ModalComponent={EvergreenQuestModal}
      getItems={EvergreenQuestActions.getEvergreenQuests}
      searchLabel="Name"
      title="Evergreen Quests"
      sortOptions={sortOptions}
      panelXs={12}
    >
      <>
        <StyledTableCell>Name</StyledTableCell>
        <StyledTableCell>Description</StyledTableCell>
        <StyledTableCell>Task Code</StyledTableCell>
      </>
    </LocalListWrapper>
  )
}
