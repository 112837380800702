import ActionTypes from 'modules/campaigns/action-types/campaign-action-types'
import { Reducer } from 'redux'
import { actionTypeSuccess, cacheItem, cacheItems } from 'core/redux/utils'
import { ISimpleAction, ISuccessAction } from 'types/redux/interfaces/IAction'
import _ from 'core/utils/deepdash'
import { mergeArrays } from 'core/utils/mergeArrays'
import { ICampaignActionParams } from 'modules/campaigns/actions/campaign-actions'
import { NIL as NIL_UUID } from 'uuid'
import { ICampaignState } from 'modules/campaigns/models/state'
import { ICampaign } from 'modules/campaigns/models/campaign'

const pageSize = 20

const initialState: ICampaignState = {
  count: 0,
  page: 0,
  pageSize: pageSize,
  items: [],
  cache: {},
}

export type GetCampaignsApiResponse = {
  count: number
  page: number
  pageSize: number
  items: ICampaign[]
}

type ListResponse = { data: { items: ICampaign[] } }
type AllowedActionTypes = ISuccessAction | ISimpleAction

const handleUpdate = (state: ICampaignState,
    action: AllowedActionTypes): ICampaignState => {
  const successAction = action as ISuccessAction
    const payloadItem = successAction.data as ICampaign
    const itemIndex = state.items.findIndex((x) => x.id === payloadItem.id)

    return {
      ...state,
      items:
        itemIndex >= 0
          ? state.items.map((item, index) =>
              index === itemIndex ? payloadItem : item,
            )
          : [...state.items, payloadItem],
      cache: cacheItem(state.cache, payloadItem),
    }
}

const actionHandlers = {
  [ActionTypes.SET_CURRENT_CAMPAIGN_ID]: (
    state: ICampaignState,
    action: AllowedActionTypes,
  ): ICampaignState => {
    const simpleAction = action as ISimpleAction
    const { campaignId } =
      simpleAction.payload as ICampaignActionParams
    return {
      ...state,
      currentCampaignId: campaignId ? campaignId : undefined,
    }
  },
  [ActionTypes.CREATE_CAMPAIGN_DRAFT]: (
    state: ICampaignState,
    action: AllowedActionTypes,
  ): ICampaignState => {
    const simpleAction = action as ISimpleAction
    const { item } = simpleAction.payload as ICampaignActionParams

    return {
      ...state,
      currentCampaignId: NIL_UUID,
      items: [item as ICampaign, ...state.items],
    }
  },
  [actionTypeSuccess(ActionTypes.GET_CAMPAIGNS)]: (
    state: ICampaignState,
    action: AllowedActionTypes,
  ): ICampaignState => {
    const successAction = action as unknown as ListResponse
    const response = successAction.data.items as ICampaign[]
    return {
      ...state,
      items: mergeArrays(state.items, response),
      cache: cacheItems(state.cache, response),
    }
  },
  [actionTypeSuccess(ActionTypes.GET_CAMPAIGN_BY_ID)]: (
    state: ICampaignState,
    action: AllowedActionTypes,
  ): ICampaignState => {
    const successAction = action as ISuccessAction
    const response = successAction.data as ICampaign
    return {
      ...state,
      cache: cacheItem(state.cache, response),
    }
  },
  [actionTypeSuccess(ActionTypes.CREATE_CAMPAIGN)]: (
    state: ICampaignState,
    action: AllowedActionTypes,
  ): ICampaignState => {
    const successAction = action as ISuccessAction
    const responseItem = successAction.data as ICampaign

    const { items } = state

    return {
      ...state,
      items: [responseItem, ...items],
      cache: cacheItem(state.cache, responseItem),
    }
  },
  [actionTypeSuccess(ActionTypes.DELETE_CAMPAIGN)]: (
    state: ICampaignState,
    action: AllowedActionTypes,
  ): ICampaignState => {
    const successAction = action as ISuccessAction

    const currentCache = { ...state.cache }
    const { id } = successAction.data as ICampaign
    delete currentCache[id!]

    return {
      ...state,
      items: state.items.filter(item => item.id !== id),
      cache: { ...currentCache }
    }
  },
  [actionTypeSuccess(ActionTypes.UPDATE_CAMPAIGN)]: (
    state: ICampaignState,
    action: AllowedActionTypes,
  ): ICampaignState => handleUpdate(state, action),
  [actionTypeSuccess(ActionTypes.UPLOAD_CAMPAIGN_IMAGE)]: (
    state: ICampaignState,
    action: AllowedActionTypes,
  ): ICampaignState => handleUpdate(state, action)
}

const campaignsReducer: Reducer<ICampaignState, AllowedActionTypes> = (
  state = initialState,
  action: AllowedActionTypes,
) => {
  return actionHandlers[action.type]
    ? actionHandlers[action.type](state, action)
    : state
}

export default campaignsReducer
