import React, { createContext, PropsWithChildren, useContext } from 'react'
import { useRequestContext } from 'core/api/context'
import { Actions } from 'modules/prizes'
import { useDispatch } from 'react-redux'
import PrizeStatus from 'types/modules/prizes/enums/prize-status'
import { IPrize } from 'types/modules/prizes/models/entities/prize'
import _ from 'core/utils/deepdash'
import { MediaUploadModel } from 'types/common/images/models/entities/image-upload-model'
import { mapMimeType } from 'core/utils/mime-mapper'
import { usePrizeListContext } from 'modules/prizes/context/prize-list'

interface IPrizeManagerContext {
  createPrize: (prize: Partial<IPrize>) => void
  updatePrize: (prize: Partial<IPrize>) => void
  duplicatePrize: (prizeId: string) => void
  deletePrize: (prizeId: string) => void
  discardPrize: (prizeId: string) => void
  uploadPrizeImage: (itemId: string, fileParams: MediaUploadModel) => void
  uploadFeaturedImage: (itemId: string, fileParams: MediaUploadModel) => void
  uploadLogo: (itemId: string, fileParams: MediaUploadModel) => void
}

export const PrizeManagerContext = createContext<IPrizeManagerContext>(
  {} as IPrizeManagerContext,
)

export const PrizeManagerContextProvider: React.FC<PropsWithChildren<{}>> = (
  props: PropsWithChildren<{}>,
) => {
  const rc = useRequestContext()

  const { setCurrentPrize } = usePrizeListContext()

  const dispatch = useDispatch()

  const createPrize = (prize: Partial<IPrize>): void => {
    new Promise<IPrize>((onResolve, onReject) => {
      dispatch(
        Actions.createPrize({
          item: prize,
          promise: {
            onResolve,
            onReject,
          },
        }),
      )
    }).then((prize: IPrize) => {
      dispatch(Actions.getPrizeStatuses())
      setCurrentPrize(prize.id, prize.type)
    })
  }

  const updatePrize = (prize: Partial<IPrize>): void => {
    dispatch(
      Actions.updatePrize({
        prizeId: prize.id,
        item: prize,
      }),
    )
  }

  const updatePrizeStatus = (
    prizeId: string,
    prizeStatus: PrizeStatus,
  ): void => {
    new Promise((onResolve, onReject) => {
      dispatch(
        Actions.updatePrizeStatus({
          prizeId,
          prizeStatus,
          promise: {
            onResolve,
            onReject,
          },
        }),
      )
    }).then(() => {
      dispatch(Actions.getPrizeStatuses())
    })
  }

  const duplicatePrize = (prizeId: string): void => {
    dispatch(
      Actions.duplicatePrize({
        prizeId,
        callbackFn: (newPrize: IPrize) => {
          setCurrentPrize(newPrize.id, newPrize.type)
        },
      }),
    )
  }

  const deletePrize = (prizeId: string): void => {
    dispatch(Actions.deletePrize({ prizeId }))
  }

  const discardPrize = (prizeId: string): void => {
    updatePrizeStatus(prizeId, PrizeStatus.Trashed)
  }

  const uploadPrizeImage = (
    itemId: string,
    fileParams: MediaUploadModel,
  ): void => {
    dispatch(
      Actions.uploadPrizeImage({
        itemId,
        item: fileParams.binaryData,
        extension: `image.${fileParams.fileExtension}`,
        overrideHeaders: {
          'content-type': mapMimeType(fileParams.fileExtension),
          accept: '*/*',
        },
      }),
    )
  }

  const uploadFeaturedImage = (
    itemId: string,
    fileParams: MediaUploadModel,
  ): void => {
    dispatch(
      Actions.uploadFeaturedImage({
        itemId,
        item: fileParams.binaryData,
        extension: `image.${fileParams.fileExtension}`,
        overrideHeaders: {
          'content-type': mapMimeType(fileParams.fileExtension),
          accept: '*/*',
        },
      }),
    )
  }

  const uploadLogo = (itemId: string, fileParams: MediaUploadModel): void => {
    dispatch(
      Actions.uploadLogo({
        itemId,
        item: fileParams.binaryData,
        extension: `image.${fileParams.fileExtension}`,
        overrideHeaders: {
          'content-type': mapMimeType(fileParams.fileExtension),
          accept: '*/*',
        },
      }),
    )
  }

  const context: IPrizeManagerContext = {
    createPrize,
    updatePrize,
    duplicatePrize,
    deletePrize,
    discardPrize,
    uploadPrizeImage,
    uploadFeaturedImage,
    uploadLogo,
  }

  return (
    <PrizeManagerContext.Provider value={context}>
      {props.children}
    </PrizeManagerContext.Provider>
  )
}

export const usePrizeManagerContext = (): IPrizeManagerContext =>
  useContext(PrizeManagerContext)
