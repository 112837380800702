export enum MobileAppUserFieldName {
  ID = 'id',
  ACCOUNTS = 'accounts',
  USERNAME = 'username',
  PREFERRED_USERNAME = 'preferredUsername',
  BIRTHDAY = 'birthday',
  GIVEN_NAME = 'givenName',
  FAMILY_NAME = 'familyName',
  EMAIL = 'email',
  PHONE_NUMBER = 'phoneNumber',
  MEDIA = 'media',
  MEDIA_ITEM_ID = 'mediaItemId',
  UPDATED_AT = 'updatedAt',
  LOCALE = 'locale',
  BACKGROUND_IMAGE_URL = 'backgroundImageUrl',
  CURRENCY = 'currency',
  BALANCE = 'balance',
  COIN_ACCOUNT = 'coinAccount',
  STAR_ACCOUNT = 'starAccount',
  UNCUT_DIAMOND_ACCOUNT = 'uncutDiamondAccount',
  CHEST_ACCOUNT = 'chestAccount',
  XP_ACCOUNT = 'xpAccount',
  GOLD_BOX_ACCOUNT = 'goldBoxAccount',
  BROWN_BOX_ACCOUNT = 'brownBoxAccount',
}
