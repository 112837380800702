/* Types */
import { EndpointRoute } from 'types/common/api/models/entities/endpoint-route'
import { RequestMethod } from 'types/common/api/enums/request-method'

/* Module */
import filters from './filters'
import params from './params'
import paths from './paths'

const UserPrizeEndpoints: { [key: string]: EndpointRoute } = {
  getUserPrizeStatuses: {
    method: RequestMethod.GET,
    path: [paths.userPrizes, paths.statuses],
    filters,
  },
  getUserPrizes: {
    method: RequestMethod.GET,
    path: [paths.userPrizes],
    filters,
  },
  updateUserPrizeStatus: {
    method: RequestMethod.PUT,
    path: [
      paths.userPrizes,
      params.userPrizeId,
      paths.updateStatus,
      params.userPrizeStatus,
    ],
  },
  createUserPrizeNote: {
    method: RequestMethod.POST,
    path: [paths.userPrizes, params.userPrizeId, paths.notes],
  },
}

export default UserPrizeEndpoints
