import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react'
import { Regions } from 'types/common/enums/regions'
import { REGIONS_NAME, REGIONS_OPTIONS } from 'utils/constants'

interface IRegionsSelectProps {
  selectedRegions: any[]
  onSelect: (val: any[]) => void
}

const useStyles = makeStyles(() =>
  createStyles({
    select: {
      width: '100%',
    },
    formControl: {
      margin: 1,
      width: '100%',
      position: 'relative',
    },
    selectedWrapper: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: 0.5,
    },
    chip: {
      margin: 4,
    },
  }),
)

export const RegionsSelect: React.FC<IRegionsSelectProps> = ({
  selectedRegions,
  onSelect,
}) => {
  const handleChange = (event: any): void => {
    const {
      target: { value },
    } = event
    onSelect(value)
  }

  const classes = useStyles()

  return (
    <FormControl variant="standard" className={classes.formControl}>
      <Select
        variant="standard"
        labelId="regions-label"
        id="regions"
        multiple
        className={classes.select}
        value={selectedRegions}
        onChange={handleChange}
        input={<OutlinedInput />}
        renderValue={(): JSX.Element => {
          return (
            <Box className={classes.selectedWrapper}>
              {selectedRegions.map((value) => (
                <Chip
                  key={value}
                  label={REGIONS_NAME[value as Regions]}
                  className={classes.chip}
                />
              ))}
            </Box>
          )
        }}
        >
        {REGIONS_OPTIONS.map(({ name, value }) => (
          <MenuItem key={name} value={value}>
            <Checkbox
              checked={selectedRegions.indexOf(value) > -1}
              color="primary"
            />
            <ListItemText primary={name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
