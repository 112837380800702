import { default as Endpoints } from 'core/api/endpoints/info-cards'
import { createAction, createAsyncAction } from 'core/redux/utils'
import { ActionTypes } from 'modules/info-cards'
import { IAsyncActionParams } from 'types/common/api/models/entities/async-action-params'
import { InfoCardStatus } from 'types/modules/info-cards/enums/info-card-status'
import { IInfoCard } from 'types/modules/info-cards/models/entities/info-card'
import { IInfoCardDraft } from 'types/modules/info-cards/models/entities/info-card-draft'
import { IAsyncAction, ISimpleAction } from 'types/redux/interfaces/IAction'

export interface IInfoCardActionParams
  extends IAsyncActionParams<IInfoCard | IInfoCardDraft | string> {
  infoCardId?: string
  infoCardStatus?: InfoCardStatus
}

export const setCurrentInfoCardId: (
  params: IInfoCardActionParams,
) => ISimpleAction = (params) =>
  createAction(ActionTypes.SET_CURRENT_INFO_CARD_ID, params)

export const clearInfoCards: () => ISimpleAction = () =>
  createAction(ActionTypes.CLEAR_INFO_CARDS, null)

export const getInfoCardStatuses: () => IAsyncAction = () =>
  createAsyncAction({
    type: ActionTypes.GET_INFO_CARD_STATUSES,
    endpoint: Endpoints.getInfoCardStatuses,
    payload: null,
  })

export const getInfoCards: (params: IInfoCardActionParams) => IAsyncAction = (
  params,
) =>
  createAsyncAction({ type: ActionTypes.GET_INFO_CARDS, endpoint: Endpoints.getInfoCards, payload: params })

export const getInfoCardById: (
  params: IInfoCardActionParams,
) => IAsyncAction = (params) =>
  createAsyncAction({
    type: ActionTypes.GET_INFO_CARD_BY_ID,
    endpoint: Endpoints.getInfoCardById,
    payload: params,
  })

export const createInfoCard: (params: IInfoCardActionParams) => IAsyncAction = (
  params,
) =>
  createAsyncAction({
    type: ActionTypes.CREATE_INFO_CARD,
    endpoint: Endpoints.createInfoCard,
    payload: params,
  })

export const createInfoCardDraft: (
  params: IInfoCardActionParams,
) => ISimpleAction = (params) =>
  createAction(ActionTypes.CREATE_INFO_CARD_DRAFT, params)

export const updateInfoCard: (params: IInfoCardActionParams) => IAsyncAction = (
  params,
) =>
  createAsyncAction({
    type: ActionTypes.UPDATE_INFO_CARD,
    endpoint: Endpoints.updateInfoCard,
    payload: params,
  })

export const updateInfoCardStatus: (
  params: IInfoCardActionParams,
) => IAsyncAction = (params) =>
  createAsyncAction({
    type: ActionTypes.UPDATE_INFO_CARD_STATUS,
    endpoint: Endpoints.updateInfoCardStatus,
    payload: params,
  })

export const uploadInfoCardImage: (
  params: IInfoCardActionParams,
) => IAsyncAction = (params) =>
  createAsyncAction({
    type: ActionTypes.UPLOAD_INFO_CARD_IMAGE,
    endpoint: Endpoints.uploadInfoCardImage,
    payload: params,
  })
