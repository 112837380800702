import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import {
  Box,
  CircularProgress,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material'
import { TagGroup } from 'types/modules/tags/models/entities/tag-group'
import _ from 'core/utils/deepdash'
import { Actions, Selectors } from 'modules/tags'
import { AutocompleteSearchMultiselect } from 'components/controls/inputs/downshift/autocomplete/autocomplete-search-multiselect'
import { OverrideChipProps } from 'components/controls/inputs/downshift/multiselect/multiselect-props'
import { buildRequestUrl } from 'core/api/utils/build-request-url'
import { Version } from 'types/common/enums/versions'
import { request } from 'core/axios'
import { ApiRequestParams } from 'types/common/api/models/entities/api-request-params'
import { RequestMethod } from 'types/common/api/enums/request-method'
import { TagGroupFromContainer } from 'modules/tags/views/form-wrapper'
import { useTypedSelector } from 'core/redux/utils'
import Alert from '@mui/material/Alert';
import { useDispatch } from 'react-redux'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    selectField: {
      marginBottom: '20px',
      width: '100%',
    },
    editFormWrapper: {
      flexFlow: 'wrap',
      minHeight: 350,
    },
    tabContainer: {
      paddingLeft: 20,
      paddingTop: 10,
    },
    inputLabel: {
      display: 'flex',
      marginBottom: 8,
      marginTop: 8,
    },
    centralized: {
      display: 'flex',
      justifyContent: 'center',
    },
  }),
)

export interface IAttachTagGroupsProps {
  tagGroupId: string
  handleClose: () => void
}

export const AttachTagGroups: React.FC<IAttachTagGroupsProps> = ({
  tagGroupId,
  handleClose,
}) => {
  const classes = useStyles()
  const timeout = useRef<any>(null)
  const dispatch = useDispatch()
  const [childTagGroupIds, setChildTagGroupIds] = useState<string[]>([])
  const [initiallySelectedTagGroups, setInitiallySelectedTagGroups] = useState<
    TagGroup[]
  >([])
  const [loadingTagRelationship, setLoadingTagRelationship] = useState(true)
  const [savingTagRelationship, setSavingTagRelationship] = useState(false)
  const [showError, setShowError] = useState(false)
  const [showSuccess, setShowsuccess] = useState(false)


  const relationshipReqParams = useMemo(
    (): ApiRequestParams => ({
      url: buildRequestUrl({
        path: ['tag-groups', tagGroupId],
        version: Version.V2,
      }),
      method: RequestMethod.GET,
    }),
    [tagGroupId],
  )

  const cachedItems = useTypedSelector((state) =>
    Selectors.getTagGroups(state),
  ).cache

  const loadTagGroupRelationship = useCallback(() => {
    const loadRelationship = async (): Promise<any> => {
      try {
        const response = await request(relationshipReqParams)
        // @ts-expect-error decide later
        const { childTagGroupIds = [] } =
          response?.data || {}
        setChildTagGroupIds(childTagGroupIds)
        const unfetchedChildTagGroups: Promise<any>[] = []
        childTagGroupIds.forEach((groupId: string) => {
          if (cachedItems[groupId]) return
          unfetchedChildTagGroups.push(
            new Promise((onResolve, onReject) => {
              dispatch(
                Actions.getTagGroupById({
                  tagGroupId: groupId,
                  promise: {
                    onResolve,
                    onReject,
                  },
                }),
              )
            })
          )
        })
        const unfetchedChildTagGroupsResponse = await Promise.all(unfetchedChildTagGroups)
        setInitiallySelectedTagGroups(
          [
            ...(unfetchedChildTagGroupsResponse as TagGroup[]),
            ...(childTagGroupIds
              .filter((id: string) => !!cachedItems[id])
              .map((id: string) => cachedItems[id]))
          ]
        )
        setLoadingTagRelationship(false)
      } catch (e) {
        setLoadingTagRelationship(false)
      }
    }
    loadRelationship()
  }, [tagGroupId])

  const saveTagGroupRelationship = useCallback(() => {
    const saveRelationship = async (): Promise<void> => {
      setSavingTagRelationship(true)
      try {
        await request({
          url: buildRequestUrl({
            path: ['tag-groups', 'relationships'],
            version: Version.V2,
          }),
          method: RequestMethod.POST,
          body: {
            tagGroupId,
            childTagGroupIds,
          },
        })
        setSavingTagRelationship(false)
        setShowsuccess(true)
      } catch (e) {
        setShowError(true)
        setSavingTagRelationship(false)
      }
    }

    saveRelationship()
  }, [tagGroupId, childTagGroupIds.length])

  useEffect(() => {
    loadTagGroupRelationship()
  }, [tagGroupId])

  useEffect(() => {
    if (showSuccess || showError) {
      timeout.current = setTimeout(() => {
        setShowsuccess(false)
        setShowsuccess(false)
      }, 2000)
    }

    return (): void => {
      clearTimeout(timeout.current)
    }
  }, [showSuccess, showError])

  const handleSelectedTagGroupsChange = (
    selectedTagGroups: TagGroup[],
  ): void => {
    setChildTagGroupIds(selectedTagGroups.map((group) => group.id!))
  }

  return (
    <TagGroupFromContainer
      handleSubmit={saveTagGroupRelationship}
      handleClose={handleClose}
      buttonLabel="Save"
      processing={savingTagRelationship}
    >
      <>
      {showSuccess && <Alert severity="success">Successfully saved</Alert>}
      {showError && <Alert severity="error">Sorry an error occurred</Alert>}

      {loadingTagRelationship ? (
        <Box className={classes.centralized}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <AutocompleteSearchMultiselect
            key={`fuckoff-${tagGroupId ?? '-empty'}`}
            label="Tag groups"
            initialSelectedItems={
              (initiallySelectedTagGroups as TagGroup[]) ?? []
            }
            availableItems={[]}
            onSelectedItemsChange={handleSelectedTagGroupsChange}
            searchAsyncActionCreator={Actions.getAutocompleteSearchTagGroups}
            selectorMethod={Selectors.getAutocompleteSearchTagGroups}
            options={{
              selectedItemsChipProps: (item: TagGroup): OverrideChipProps => {
                const props: OverrideChipProps = {
                  label: item.name!,
                }
                return props
              },
              autocompleteSearchResultContent: (item: TagGroup): string =>
                item.name,
            }}
          />
        </>
      )}
      </>
    </TagGroupFromContainer>
  )
}
