import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { ActionTypes } from 'modules/prizes'
import { Reducer } from 'redux'
import { IPrizeState } from 'types/modules/prizes/models/state/prize-state'
import { ISimpleAction, ISuccessAction } from 'types/redux/interfaces/IAction'
import _ from 'core/utils/deepdash'
import { IPrizeActionParams } from 'modules/prizes/actions'
import { actionTypeSuccess, cacheItem, cacheItems } from 'core/redux/utils'
import { IPrize } from 'types/modules/prizes/models/entities/prize'
import IPaginatedItems from 'types/common/pagination/models/entities/paginated-items'
import { mergeArrays } from 'core/utils/mergeArrays'
import PrizeStatus from 'types/modules/prizes/enums/prize-status'
import { IPrizeItem } from 'types/modules/prizes/models/entities/prize-item'

const initialState = (): IPrizeState => {
  const state: IPrizeState = {
    items: [],
    statuses: {},
    cache: {},
  }

  Object.values(PrizeStatus).forEach((value) => {
    state.statuses[value] = 0
  })

  return state
}

type AllowedActionTypes = ISuccessAction | ISimpleAction

const PrizeReducer: Reducer<IPrizeState, AllowedActionTypes> = (
  state = initialState(),
  action: AllowedActionTypes,
) => {
  const simpleAction = action as ISimpleAction
  const successAction = action as ISuccessAction

  const currentPrize = !IsNullOrUndefined(state.currentPrizeId)
    ? state.items.find((prize) => prize.id === state.currentPrizeId)
    : null

  switch (action.type) {
    case ActionTypes.SET_CURRENT_PRIZE_ID: {
      const newState = _.cloneDeep(state)

      const { prizeId } = simpleAction.payload as IPrizeActionParams

      if (IsNullOrUndefined(prizeId)) {
        if (Object.hasOwnProperty.call(newState, 'currentPrizeId')) {
          const page = (newState as any).page ?? 0
          const pageSize = (newState as any).pageSize ?? 0
          if (page === 1 && newState.items.length > pageSize) {
            const currentPageIndex = newState.items.findIndex(
              (prize) => prize.id === currentPrize?.id,
            )

            _.pullAt(newState.items, currentPageIndex)
          }
          delete newState.currentPrizeId
        }
      } else {
        newState.currentPrizeId = prizeId
      }

      return newState
    }

    case ActionTypes.CLEAR_PRIZES: {
      const newState = _.cloneDeep(state)
      if (IsNullOrUndefined(newState.currentPrizeId)) {
        newState.items = []
      } else {
        newState.items = newState.items.filter(
          (prize) => prize.id !== newState.currentPrizeId,
        )
      }

      return newState
    }

    case actionTypeSuccess(ActionTypes.GET_PRIZE_STATUSES): {
      const newState = _.cloneDeep(state)
      const statuses = successAction.data as IPrizeState['statuses']

      _.forEach(state.statuses, (value, status) => {
        const key = `${status[0].toLowerCase()}${status.slice(1)}`
        const responseStatus = statuses[key]

        if (!IsNullOrUndefined(responseStatus)) {
          newState.statuses[status] = responseStatus
        } else {
          newState.statuses[status] = 0
        }
      })

      return newState
    }

    case actionTypeSuccess(ActionTypes.GET_PRIZES): {
      let newState = _.cloneDeep(state)
      const response = successAction.data as IPaginatedItems<IPrize>
      const page = successAction.payload!.page

      const pageIsFirstOrNull = IsNullOrUndefined(page) || page === 1

      const currentPrizeIsNullOrResponseContainsCurrentPrize =
        IsNullOrUndefined(currentPrize) ||
        response.items.some((prize) => prize.id === currentPrize!.id)

      if (
        pageIsFirstOrNull &&
        currentPrizeIsNullOrResponseContainsCurrentPrize
      ) {
        newState = {
          ...newState,
          ...response,
          pageSize: response.pageSize ?? response.count,
          cache: cacheItems(state.cache, response.items),
        }
      } else {
        newState = {
          ...newState,
          ...response,
          items: mergeArrays(state.items, response.items),
          cache: cacheItems(state.cache, response.items),
        }
      }
      return newState
    }

    case actionTypeSuccess(ActionTypes.GET_PRIZE_BY_ID): {
      const newState = _.cloneDeep(state)
      const response = successAction.data as IPrize
      const prizeIndex = state.items.findIndex((x) => x.id === response.id)

      if (prizeIndex < 0) {
        newState.items = [...newState.items, response]
      } else {
        newState.items[prizeIndex] = response
      }

      newState.cache = cacheItem(state.cache, response)

      return newState
    }

    case actionTypeSuccess(ActionTypes.UPDATE_PRIZE):
    case actionTypeSuccess(ActionTypes.UPDATE_PRIZE_STATUS):
    case actionTypeSuccess(ActionTypes.PROMOTE_PRIZE): {
      const newState = _.cloneDeep(state)
      const response = successAction.data as IPrize

      const prizeIndex = state.items.findIndex(
        (prize) => prize.id === response.id,
      )

      if (prizeIndex >= 0) {
        newState.items[prizeIndex] = response
      }

      newState.cache = cacheItem(state.cache, response)

      return newState
    }

    case actionTypeSuccess(ActionTypes.UPLOAD_PRIZE_IMAGE):
    case actionTypeSuccess(ActionTypes.UPLOAD_FEATURED_IMAGE):
    case actionTypeSuccess(ActionTypes.UPLOAD_LOGO): {
      const newState = _.cloneDeep(state)
      const response = successAction.data as IPrizeItem

      const prizeIndex = newState.items.findIndex(
        (prize) => prize.itemId === response.id,
      )

      if (prizeIndex >= 0) {
        const newPrize = newState.items[prizeIndex]

        newPrize.item = response

        newState.items[prizeIndex] = newPrize

        newState.cache = cacheItem(state.cache, newPrize)

        return newState
      }

      return state
    }

    case actionTypeSuccess(ActionTypes.AUTOCOMPLETE_PRIZE_SEARCH): {
      const newState = _.cloneDeep(state)
      const response = successAction.data as IPaginatedItems<IPrize>

      newState.cache = cacheItems(state.cache, response.items)

      return newState
    }

    default:
      return state
  }
}

export default PrizeReducer
