import { Box, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { useLeaderboardContext } from 'modules/leaderboards/context'
import { PositionRewardsModal } from 'modules/leaderboards/views/components/edit-position-reward-panel/modal'
import { PositionRewardListItem } from 'modules/leaderboards/views/components/position-reward-list/position-reward-list-item'
import React from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    positionRewardlistContainer: {
      display: 'flex',
      width: '100%',
      height: '100%',
      overflow: 'auto',
      flexDirection: 'column',
      padding: '0.625rem',
    },
  }),
)

export const PositionRewardList: React.FC<{}> = () => {
  const classes = useStyles()

  const { currentLeaderboard, currentPositionReward } = useLeaderboardContext()

  return (
    <React.Fragment>
      <Box className={classes.positionRewardlistContainer}>
        {currentLeaderboard?.rewards.map((positionReward) => {
          const key = `position-reward-${positionReward.id}`
          return <PositionRewardListItem key={key} {...positionReward} />
        })}
      </Box>
      {!IsNullOrUndefined(currentPositionReward) && (
        <PositionRewardsModal positionReward={currentPositionReward} open />
      )}
    </React.Fragment>
  )
}
