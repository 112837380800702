export enum ElementType {
  Paragraph = 'paragraph',
  HeadingOne = 'headingOne',
  HeadingTwo = 'headingTwo',
  BlockQuote = 'block-quote',
  CheckListItem = 'check-list-item',
  ListItem = 'list-item',
  BulletedList = 'bulleted-list',
  NumberedList = 'numbered-list',
  Link = 'link',
}
