import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react'

import {
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  Theme,
  Button,
  IconButton,
  ClickAwayListener,
  SelectChangeEvent,
} from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import SearchIcon from '@mui/icons-material/Search'
import SortIcon from '@mui/icons-material/SortOutlined'

import { IKeyValueStringCollection } from 'types/common/interfaces/IKeyValueStringCollection'
import { SortValue } from 'types/common/sorting/models/entities/sort-value'
import { SortDirection } from 'types/common/enums/sort-direction'

import _ from 'core/utils/deepdash'
import clsx from 'clsx'
import { IFilter } from 'types/common/filtering/models/entities/IFilter'
import { ISearchFilterConfig } from 'types/common/filtering/models/entities/ISearchFilterConfig'
import { useRequestContext } from 'core/api/context'
import {
  IsNullOrUndefined,
  IsNullUndefinedOrEmpty,
} from 'core/utils/isNullOrUndefined'
import { FilterType } from 'types/common/filtering/enums/FilterType'
import { FilterOperator } from 'types/common/filtering/enums/FilterOperator'
import { CrossIcon } from 'components/icons'

type SortOption = {
  label: string
  value: string
}

export interface IFilterToolbarProps {
  searchFilterConfig: ISearchFilterConfig
  sortOptions: SortOption[]
  initialSortValue: SortValue
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbarControlWrapper: {
      display: 'flex',
      flex: 1,
      marginRight: '0.625rem',
      '&:last-child': {
        margin: 0,
      },
    },
    toolbarSearchInput: {
      display: 'flex',
      flex: 1,
    },
    toolbarSortInput: {
      display: 'flex',
      flex: 1,
      height: '2.5rem',
      overflow: 'hidden',
    },
    toolbarSortInputText: {
      textOverflow: 'clip',
    },
    toolbarSortDescending: {
      transform: 'scaleY(-1)',
    },
  }),
)

const FilterToolbar: React.FC<IFilterToolbarProps> = (
  props: IFilterToolbarProps,
) => {
  const rc = useRequestContext()

  const searchFilter = rc?.getFilterByField(props.searchFilterConfig.field)

  const [searchValue, setSearchValue] = useState('')

  // useEffect(() => {
  //   setRequestSortValue(props.initialSortValue);
  // }, [props.initialSortValue]);

  // Handle Search
  const handleSearchChange = (
    event: React.ChangeEvent<{ value: unknown }>,
  ): void => {
    setSearchValue(event.target.value as string)
  }

  const handleClearSearch = (): void => {
    setSearchValue('')
    rc?.removeFilter(props.searchFilterConfig.field)
  }

  const handleSearchClickAway = (): void => {
    setSearchValue((searchFilter?.value as string) ?? '')
  }

  const handleSubmitSearchFilter = (): void => {
    if (searchValue === '') {
      if (!IsNullOrUndefined(searchFilter)) {
        rc?.removeFilter(props.searchFilterConfig.field)
      }
    } else {
      rc?.applyFilter({
        field: props.searchFilterConfig.field,
        type: FilterType.Search,
        operator: FilterOperator.ContainsCaseInsensitive,
        value: searchValue,
      })
    }
  }

  const handleSearchKeyDown = useCallback(
    (event: React.KeyboardEvent): void => {
      if (event.keyCode === 13) {
        // Enter Key
        handleSubmitSearchFilter()
      }
    },
    [searchValue],
  )

  // Handle Sorting
  const handleSortBy = (event: SelectChangeEvent<string>): void => {
    const newSortValue = _.cloneDeep(rc?.sortValue!)
    newSortValue.sortBy = event.target.value as string
    rc?.setSortValue(newSortValue)
  }

  const handleSortDirection = (): void => {
    const newSortValue = _.cloneDeep(rc?.sortValue!)
    newSortValue.sortDirection =
      rc?.sortValue.sortDirection === SortDirection.Ascending
        ? SortDirection.Descending
        : SortDirection.Ascending
    rc?.setSortValue(newSortValue)
  }

  // Render
  const classes = useStyles()

  const SortButton: React.FC<{}> = () => (
    <IconButton
      size="small"
      onClick={handleSortDirection}
      className={clsx(
        rc?.sortValue.sortDirection !== SortDirection.Ascending &&
          classes.toolbarSortDescending,
      )}
    >
      <SortIcon />
    </IconButton>
  )

  return (
    <React.Fragment>
      <div className={classes.toolbarControlWrapper}>
        <ClickAwayListener onClickAway={handleSearchClickAway}>
          <TextField
            size="small"
            className={classes.toolbarSearchInput}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {!IsNullOrUndefined(searchFilter) && (
                    <IconButton
                      size="small"
                      onClick={(): void => handleClearSearch()}
                      style={{
                        width: 30,
                        height: 30,
                      }}
                    >
                      <CrossIcon />
                    </IconButton>
                  )}
                  <IconButton
                    size="small"
                    onClick={(): void => handleSubmitSearchFilter()}
                    disabled={
                      !IsNullOrUndefined(searchFilter) &&
                      searchFilter?.value === searchValue
                    }
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            variant="outlined"
            value={searchValue}
            onChange={handleSearchChange}
            onKeyDown={handleSearchKeyDown}
          />
        </ClickAwayListener>
      </div>
      <div className={classes.toolbarControlWrapper}>
        <Select
          value={rc?.sortValue.sortBy}
          onChange={handleSortBy}
          displayEmpty
          className={classes.toolbarSortInput}
          inputProps={{
            'aria-label': 'Without label',
            className: classes.toolbarSortInputText,
          }}
          startAdornment={
            <InputAdornment position="start">Sort by: </InputAdornment>
          }
          endAdornment={<SortButton />}
          IconComponent={'object'}
          variant="outlined"
        >
          {props.sortOptions.map((option, index) => (
            <MenuItem key={`${option.value}-${index}`} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </div>
    </React.Fragment>
  )
}

export default FilterToolbar
