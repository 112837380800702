import { IKeyValueStringCollection } from 'types/common/interfaces/IKeyValueStringCollection'

const paths = {
  alerts: 'alerts',
  answers: 'answers',
  clearTrash: 'clear-trash',
  duplicate: 'duplicate',
  images: 'images',
  matches: 'matches',
  options: 'options',
  posts: 'posts',
  promote: 'promote',
  questions: 'questions',
  results: 'results',
  save: 'save',
  setCompromised: 'set-compromised',
  settle: 'settle',
  statuses: 'statuses',
  tags: 'tags',
  tickets: 'tickets',
  updateStake: 'update-stake',
  updateStatus: 'update-status',
  upload: 'upload',
}

export default paths
