import { Theme, Button, ButtonProps } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx'
import { ConfirmationDialog } from 'components/confirmation-dialog'
import { SubmitIcon } from 'components/icons'
import { useEventsContext } from 'modules/events/context'
import React from 'react'
import { EventStatus } from 'types/modules/events/enums/event-status'
import { IEventActionButtonProps } from 'types/modules/events/models/props/event-action-button-props'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.primary.contrastText,
      backgroundColor: '#02b3dc',
      '&:hover': {
        backgroundColor: '#14d1fd',
      },
    },
  }),
)

export const SubmitEventButton: React.FC<IEventActionButtonProps> = (
  props: IEventActionButtonProps,
) => {
  const { submitEvent } = useEventsContext()
  const classes = useStyles()

  const isDraft = props.event.status === EventStatus.Created

  const buttonProps: ButtonProps = {
    variant: 'contained',
    startIcon: <SubmitIcon />,
    className: clsx(classes.root),
    size: 'small',
    disabled: props.disabled,
  }

  const buttonLabel = 'submit'

  return isDraft ? (
    <ConfirmationDialog
      popoverId={`${buttonLabel}-event-${props.event.id}`}
      buttonProps={buttonProps}
      onConfirmation={(): void => submitEvent(props.event.id)}
      confirmationText={`Are you sure you want to ${buttonLabel} this event?`}
      buttonLabel={buttonLabel}
    />
  ) : null
}
