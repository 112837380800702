import { combineReducers } from 'redux'

import loginReducer from 'modules/account/reducer'
import tagsReducer from 'modules/tags/reducer'
import { Reducer as postsReducer } from 'modules/posts'
import { Reducers as apiReducers } from 'core/api'
import { Reducer as eventsReducer } from 'modules/events'
import { Reducer as watchlistReducer } from 'modules/watchlist'
import { Reducer as alertsReducer } from 'core/alerts'
import { Reducer as liveSchedulesReducer } from 'modules/live-schedules'
import { Reducer as prizeReducer } from 'modules/prizes'
import { Reducer as usersReducer } from 'modules/users'
import { Reducer as leaderboardsReducer } from 'modules/leaderboards'
import { Reducer as commentsReducer } from 'modules/comments'
import { Reducer as shippingReducer } from 'modules/shipping'
import { Reducer as infoCardReducer } from 'modules/info-cards'
import { Reducer as mediaItemsReducer } from 'modules/media-items'
import { Reducer as chestReducer } from 'modules/chests'
import mobileAppUsersReducer from 'modules/mobile-app-users/reducer'
import questsReducer from 'modules/quests/questReducer'
import questGroupsReducer from 'modules/quests/questGroupsReducer'
import AppSettingReducer from 'modules/app-settings/reducer'
import evergreenQuestsReducer from 'modules/quests/evergreenQuestsReducer'
import gameTaskReducer from 'modules/quests/gameTaskReducer'
import commonReducer from 'modules/common/commonReducer'
import newsCardsReducer from 'modules/news-cards/news-card-reducer'
import iapProductsReducer from 'modules/iap-products/iap-product-reducer'
import postGroupsReducer from 'modules/post-groups/reducer'
import milestoneReducer from 'modules/milestones/milestone-reducer'
import campaignsReducer from 'modules/campaigns/campaign-reducer'

const rootReducer = combineReducers({
  account: loginReducer,
  alerts: alertsReducer,
  comments: commentsReducer,
  events: eventsReducer,
  liveSchedules: liveSchedulesReducer,
  posts: postsReducer,
  prizes: prizeReducer,
  requests: apiReducers.RequestsReducer,
  tags: tagsReducer,
  watchlist: watchlistReducer,
  users: usersReducer,
  leaderboards: leaderboardsReducer,
  shipping: shippingReducer,
  infoCards: infoCardReducer,
  mediaItems: mediaItemsReducer,
  chests: chestReducer,
  mobileAppUsers: mobileAppUsersReducer,
  appSettings: AppSettingReducer,
  quests: questsReducer,
  questGroups: questGroupsReducer,
  evergreenQuests: evergreenQuestsReducer,
  common: commonReducer,
  newsCards: newsCardsReducer,
  iapProducts: iapProductsReducer,
  postGroups: postGroupsReducer,
  milestones: milestoneReducer,
  campaigns: campaignsReducer,
  gameTasks: gameTaskReducer
})

export default rootReducer
