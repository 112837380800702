import { Box, ButtonBase, Popover, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx'
import { usePopover } from 'components/popover/use-popover'
import { UserPrizeTimeGridContextMenu } from 'modules/shipping/views/components/dashboard/user-prize-calendar-time-grid-cell/context-menu'
import { TimeGridUserPrize } from 'modules/shipping/views/components/dashboard/user-prize-calendar-time-grid-cell/user-prize'
import moment from 'moment-timezone'
import React from 'react'
import { IUserPrize } from 'types/modules/shipping/models/entities/user-prize'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    postEvent: {
      display: 'flex',
      flex: 1,
      boxSizing: 'border-box',
      flexDirection: 'column',
      height: 79,
      '&.multiple': {
        background: '#02599D',
        border: '1px solid #02599D',
      },
    },
    eventHeader: {
      display: 'flex',
      height: 15,
      width: '100%',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingLeft: '0.3rem',
      paddingRight: '0.3rem',
    },
    headerText: {
      fontSize: '0.7rem',
      color: theme.palette.primary.contrastText,
      '&.textPrimary': {
        color: theme.palette.text.primary,
      },
    },
    eventContent: {
      display: 'flex',
      flex: 1,
      width: '100%',
      height: 'calc(100% - 15px)',
      background: 'rgba(255,255,255,0.60)',
      padding: '0.3rem',
      '&.darker': {
        background: 'rgba(255,255,255,0.30)',
      },
    },
    multiprizePopover: {
      display: 'flex',
      flexDirection: 'column',
      width: 230,
    },
    multiprizeCount: {
      color: theme.palette.primary.contrastText,
      textAlign: 'center',
      margin: 'auto',
    },
  }),
)

interface ITimeGridUserPrizeMultiviewProps {
  userPrizes: IUserPrize[]
  dateTime: string
  dateField: string
}

export const TimeGridUserPrizeMultiview: React.FC<
  ITimeGridUserPrizeMultiviewProps
> = (props: ITimeGridUserPrizeMultiviewProps) => {
  const { userPrizes, dateTime, dateField } = props
  const classes = useStyles()

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handleMultiprizePopoverClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleMultiprizePopoverClose = (): void => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  // eslint-disable-next-line id-blacklist
  const id = open ? `multiple-prizes-${dateTime}` : undefined

  const contextMenu = usePopover({
    id: `context-menu-${dateTime}`,
    anchorReference: 'anchorPosition',
  })

  return (
    <React.Fragment>
      <ButtonBase
        aria-describedby={id}
        className={clsx(classes.postEvent, 'multiple')}
        onClick={handleMultiprizePopoverClick}
        onContextMenu={(e: React.MouseEvent<HTMLButtonElement>): void => {
          e.preventDefault()
          contextMenu.onClick(e)
        }}
      >
        <Box className={classes.eventHeader}>
          <Typography
            component="span"
            variant="button"
            className={clsx(classes.headerText)}
          >
            {moment(dateTime).format('h:mm A')}
          </Typography>
          <Typography
            component="span"
            variant="button"
            className={clsx(classes.headerText)}
          >
            {`.`}
          </Typography>
        </Box>
        <Box className={clsx(classes.eventContent, 'darker')}>
          <Typography
            component="span"
            variant="body2"
            className={clsx(classes.multiprizeCount)}
          >
            {`${userPrizes.length} User Prizes`}
          </Typography>
        </Box>
      </ButtonBase>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleMultiprizePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box className={classes.multiprizePopover}>
          {userPrizes.map((userPrize, index) => (
            <TimeGridUserPrize
              key={`user-prize-${userPrize.id}`}
              index={index}
              userPrize={userPrize}
              dateField={dateField}
              onClick={(): void => handleMultiprizePopoverClose()}
            />
          ))}
        </Box>
      </Popover>
      <UserPrizeTimeGridContextMenu
        dateTime={dateTime}
        dateField={dateField}
        popoverProps={contextMenu.popoverProps}
      />
    </React.Fragment>
  )
}
