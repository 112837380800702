import PostType from 'types/modules/posts/enums/post-type'

export const getTypeLabel = (type: PostType | string): string => {
  switch (type) {
    case PostType.Game:
      return 'Retro'

    case PostType.Test:
      return 'Personality'

    default:
      return type
  }
}
