import { Button, ButtonProps, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx'
import { ConfirmationDialog } from 'components/confirmation-dialog'
import { FeaturedIcon } from 'components/icons'
import { usePrizeStatusManagerContext } from 'modules/prizes/context/prize-status-manager'
import React from 'react'
import PrizeStatus from 'types/modules/prizes/enums/prize-status'
import { IPrizeActionButtonProps } from 'types/modules/prizes/models/props/prize-action-button-props'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.text.primary,
      backgroundColor: '#ecf0f1',
      '&:hover': {
        backgroundColor: '#f2f5f5',
      },
      '&.featured': {
        color: theme.palette.primary.contrastText,
        backgroundColor: '#2cd06e',
        '&:hover': {
          backgroundColor: '#54db8a',
        },
      },
    },
  }),
)

export const FeaturePrizeButton: React.FC<IPrizeActionButtonProps> = (
  props: IPrizeActionButtonProps,
) => {
  const { toggleFeaturePrize } = usePrizeStatusManagerContext()

  const classes = useStyles()

  const isFeatured = props.prize.featured
  const isActive = props.prize.status === PrizeStatus.Open

  const buttonProps: ButtonProps = {
    variant: 'contained',
    startIcon: <FeaturedIcon />,
    className: clsx(classes.root, isFeatured && 'featured'),
    size: 'small',
    disabled: props.disabled,
  }

  const buttonLabel = isFeatured ? 'Featured' : 'Feature'
  const popoverLabel = isFeatured ? 'unfeature' : 'feature'

  return isActive ? (
    <ConfirmationDialog
      popoverId={`${buttonLabel}-prize-${props.prize.id}`}
      buttonProps={buttonProps}
      onConfirmation={(): void => toggleFeaturePrize(props.prize.id)}
      confirmationText={`Are you sure you want to ${popoverLabel} this post?`}
      buttonLabel={buttonLabel}
      onPopoverClose={props.onPopoverClose}
    />
  ) : null
}
