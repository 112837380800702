import { Box, InputAdornment, MenuItem, Select, SelectChangeEvent, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useRequestContext } from 'core/api/context'
import { CheckboxFilter } from 'core/filtering/components/checkbox-filter'
import { SelectFilter } from 'core/filtering/components/select-filter'
import React from 'react'
import { FilterOperator } from 'types/common/filtering/enums/FilterOperator'
import { FilterType } from 'types/common/filtering/enums/FilterType'
import { SelectFilterMode } from 'types/common/filtering/enums/SelectFilterMode'
import { ISelectOption } from 'types/common/filtering/models/entities/ISelectOption'
import { PrizeField } from 'types/modules/prizes/enums/prize-field'
import { PrizeType } from 'fe-shared-resources'
import {
  PrizeVisibility,
  PrizeVisibilityDisplay,
} from 'types/modules/prizes/enums/prize-visibility'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filters: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    prizeTypeFilter: {
      display: 'flex',
      flexDirection: 'column',
      flexShrink: 1,
    },

    writerFilter: {
      display: 'flex',
      flexDirection: 'column',
    },

    toolbarSortInputText: {
      textOverflow: 'clip',
    },

    visibilityFilter: {
      display: 'flex',
      minWidth: 225,
      height: '2.5rem',
      overflow: 'hidden',
    },
  }),
)

export const CustomPrizeFilters: React.FC<{}> = () => {
  const classes = useStyles()
  const rc = useRequestContext()
  const visibilityFilter = rc?.getFilterByField(PrizeField.VISIBILITY)

  const prizeTypeFilterOptions: ISelectOption[] = Object.values(PrizeType).map(
    (prizeType) => {
      return {
        label: prizeType,
        value: [prizeType],
      }
    },
  )

  const handleVisibiltyChange = (
    event: SelectChangeEvent<string>,
  ): void => {
    rc?.applyFilter({
      field: PrizeField.VISIBILITY,
      type: FilterType.Select,
      operator: FilterOperator.Equals,
      value: event.target.value as string,
    })
  }

  return (
    <React.Fragment>
      <Box className={classes.filters}>
        <Box className={classes.prizeTypeFilter}>
          <SelectFilter
            field={PrizeField.TYPE}
            mode={SelectFilterMode.Multiple}
            options={prizeTypeFilterOptions}
            filterOptionComponent={CheckboxFilter}
          />
        </Box>
        <Box className={classes.writerFilter}>
          <Select
            variant={'outlined'}
            value={`${visibilityFilter?.value ?? ''}`}
            onChange={handleVisibiltyChange}
            className={classes.visibilityFilter}
            inputProps={{
              'aria-label': 'Without label',
              className: classes.toolbarSortInputText,
            }}
            startAdornment={
              <InputAdornment position="start">Visibilty: </InputAdornment>
            }
          >
            <MenuItem key={`created-by-null`} value={''}>
              {'None'}
            </MenuItem>
            {Object.values(PrizeVisibility).map((visibility) => (
              <MenuItem key={visibility} value={visibility}>
                {PrizeVisibilityDisplay[visibility]}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>
    </React.Fragment>
  )
}
