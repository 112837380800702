import { Panel, PanelContainer } from 'components/panel'
import { RequestContextProvider } from 'core/api/context'
import { PostGroupsProvider } from 'modules/post-groups/context'
import React from 'react'
import { PostGroupsDashboard } from 'modules/post-groups/view/components/post-groups-dashboard'

export const PostGroupsPage: React.FC<{}> = () => {
  return (
    <RequestContextProvider>
      <PostGroupsProvider>
        <PanelContainer>
          <Panel container xs={12}>
            <PostGroupsDashboard />
          </Panel>
        </PanelContainer>
      </PostGroupsProvider>
    </RequestContextProvider>
  )
}
