import { FormikErrors } from 'formik'
import moment from 'moment-timezone'
import { IFormErrors } from 'types/common/interfaces/IFormErrors'

export const AHEAD_OF_END_DATE_MESSAGE = 'can not be ahead of end date'
export const BEFORE_START_DATE_MESSAGE = 'can not be before of before date'

export const testDateRange = (start?: string, end?: string | null): boolean => {
  if (!end || !start) {
    return true
  }
  return (
    !!start && !!end && moment(new Date(end)).isSameOrAfter(new Date(start))
  )
}

export const createErrors = (errors?: FormikErrors<any>): IFormErrors => {
  if (!errors) return {}
  return Object.keys(errors).reduce(
    (acc, key) => ({
      ...acc,
      [key]: {
        helperText: errors[key],
        error: !!errors[key],
      },
    }),
    {},
  )
}
