import { actionTypeSuccess } from 'core/redux/utils'
import { Reducer } from 'redux'
import { ILeaderboardState } from 'types/modules/leaderboards/models/state/leaderboard-state'
import { ISimpleAction, ISuccessAction } from 'types/redux/interfaces/IAction'
import _ from 'core/utils/deepdash'
import { ActionTypes } from 'modules/leaderboards'
import { ILeaderboardActionParams } from 'modules/leaderboards/actions'
import {
  IsNullOrUndefined,
  IsNullUndefinedOrEmpty,
} from 'core/utils/isNullOrUndefined'
import ILeaderboardPositionReward from 'types/modules/leaderboards/models/entities/leaderboard-position-reward'

const initialState = (): ILeaderboardState => ({
  rewards: {},
})

type AllowedActionTypes = ISuccessAction | ISimpleAction

const LeaderboardsReducer: Reducer<ILeaderboardState, AllowedActionTypes> = (
  state = initialState(),
  action: AllowedActionTypes,
) => {
  const simpleAction = action as ISimpleAction
  const successAction = action as ISuccessAction

  switch (action.type) {
    case ActionTypes.SET_CURRENT_LEADERBOARD_ID: {
      const newState = _.cloneDeep(state)
      const { leaderboardId } = simpleAction.payload as ILeaderboardActionParams

      if (IsNullUndefinedOrEmpty(leaderboardId)) {
        delete newState.currentLeaderboardId
      } else newState.currentLeaderboardId = leaderboardId

      return newState
    }

    case actionTypeSuccess(ActionTypes.CREATE_LEADERBOARD_POSITION_REWARD):
    case actionTypeSuccess(ActionTypes.UPDATE_LEADERBOARD_POSITION_REWARD):
    case actionTypeSuccess(ActionTypes.GET_LEADERBOARD_POSITION_REWARDS): {
      const newState = _.cloneDeep(state)
      const { leaderboardId } =
        successAction.payload as ILeaderboardActionParams
      const positionRewards = successAction.data as ILeaderboardPositionReward[]

      if (
        !IsNullUndefinedOrEmpty(leaderboardId) &&
        !IsNullUndefinedOrEmpty(positionRewards)
      ) {
        newState.rewards[leaderboardId!] = positionRewards
      }

      return newState
    }

    default:
      return state
  }
}

export default LeaderboardsReducer
