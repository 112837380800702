import { IMediaItemState } from 'types/modules/media-items/models/state/media-item-state'
import { AppState } from 'types/redux/types/app-state'
import _ from 'core/utils/deepdash'
import { MediaItem } from 'types/modules/media-items/models/entities/media-item'
import { IsNullUndefinedOrEmpty } from 'core/utils/isNullOrUndefined'
import { MODULE_NAME } from 'modules/media-items/constants'

const getState = (state: AppState): IMediaItemState =>
  _.cloneDeep(state[MODULE_NAME])

export const getMediaItemById = (
  state: AppState,
  mediaItemId?: string,
): MediaItem | null =>
  !IsNullUndefinedOrEmpty(mediaItemId)
    ? getState(state).items[mediaItemId!] ?? null
    : null
