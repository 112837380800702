import { useTypedSelector } from 'core/redux/utils'
import { useRouter } from 'core/routing/hooks/use-router'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { CampaignActions, Selectors } from 'modules/campaigns'
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
} from 'react'
import { useDispatch } from 'react-redux'
import { NIL as NIL_UUID } from 'uuid'
import { ICampaign } from 'modules/campaigns/models/campaign'

export interface ICampaignsContext {
  currentCampaign: ICampaign | null
  setCurrentCampaign: (campaignId: string) => void
  clearCurrentCampaign: () => void
  createCampaign: (
    campaign: ICampaign,
    callbackFn?: (campaign: ICampaign) => void,
  ) => void
  createCampaignDraft: (campaign?: ICampaign) => void
  updateCampaign: (campaign: ICampaign) => void
  deleteCampaign: () => void
}

const CampaignsContext = createContext<ICampaignsContext>(
  {} as ICampaignsContext,
)

export const CampaignsProvider: React.FC<PropsWithChildren<{}>> = (
  props: PropsWithChildren<{}>,
) => {
  const dispatch = useDispatch()

  const {
    routeParams: { campaignId },
    pushQueryParams,
    removeQueryParams,
  } = useRouter()

  const currentCampaign = useTypedSelector((state) =>
    Selectors.getCurrentCampaign(state),
  )

  const setCurrentCampaign = (campaignId: string): void => {
    pushQueryParams({
      campaignId,
    })
  }

  const clearCurrentCampaign = (): void => {
    removeQueryParams('campaignId')
  }

  const createCampaign = (campaign: ICampaign): void => {
    dispatch(
      CampaignActions.createCampaign({
        item: campaign,
        promise: {
          onResolve: (data: ICampaign): void => {
            setCurrentCampaign(data.id!)
          },
        },
      }),
    )
  }

  const createCampaignDraft = (): void => {
    setCurrentCampaign(NIL_UUID)
  }

  const updateCampaign = (campaign: ICampaign): void => {
    dispatch(
      CampaignActions.updateCampaign({
        item: campaign,
        campaignId: campaign.id,
      }),
    )
  }

  const deleteCampaign = (): void => {
    dispatch(
      CampaignActions.deleteCampaign({
        campaignId: currentCampaign?.id,
      }),
    )
    clearCurrentCampaign()
  }

  useEffect(() => {
    dispatch(CampaignActions.setCurrentCampaignId({ campaignId }))
    if (!IsNullOrUndefined(campaignId) && campaignId !== NIL_UUID) {
      dispatch(CampaignActions.getCampaignById({ campaignId }))
    }
  }, [campaignId])

  const context = {
    currentCampaign,
    setCurrentCampaign,
    clearCurrentCampaign,
    createCampaign,
    createCampaignDraft,
    updateCampaign,
    deleteCampaign,
  }

  return (
    <CampaignsContext.Provider value={context}>
      {props.children}
    </CampaignsContext.Provider>
  )
}

export const useCampaignsContext = (): ICampaignsContext =>
  useContext(CampaignsContext)
