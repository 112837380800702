import { actionTypeSuccess } from 'core/redux/utils'
import ActionTypes from 'modules/users/portal/action-types'
import { Reducer } from 'redux'
import { IPortalUsersState } from 'types/modules/users/portal/models/state/portal-users-state'
import { ISimpleAction, ISuccessAction } from 'types/redux/interfaces/IAction'
import _ from 'core/utils/deepdash'
import { AwsCognitoListUsersResponse } from 'types/aws/cognito/list-users-response'
import { PortalUser } from 'types/modules/users/portal/models/entities/portal-user'

type AllowedActionTypes = ISimpleAction | ISuccessAction

const buildInitialState = (): IPortalUsersState => {
  return {
    items: [],
  }
}

const PortalUsersReducer: Reducer<IPortalUsersState, AllowedActionTypes> = (
  state = buildInitialState(),
  action: AllowedActionTypes,
) => {
  const simpleAction = action as ISimpleAction
  const successAction = action as ISuccessAction

  switch (action.type) {
    case actionTypeSuccess(ActionTypes.GET_USERS): {
      const newState = _.cloneDeep(state)

      const response = successAction.data as AwsCognitoListUsersResponse[]

      newState.items = []

      response.forEach((u) => {
        const ignoreList = ['postman', 'test', 'watcher']

        const ignoredUsername = ignoreList.some(
          (str) => u.username.toLowerCase().indexOf(str) >= 0,
        )

        if (!u.enabled || ignoredUsername) return

        newState.items.push({
          id: '',
          username: u.username,
          email: u.attributes.find((a) => a.name === 'email')?.value ?? '',
        } as PortalUser)
      })

      return newState
    }

    default:
      return state
  }
}

export default PortalUsersReducer
