import { Box, Button, ButtonBase, Popover, Theme, Tooltip, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { usePostQuestionsManager } from 'modules/posts/context/post-questions-manager'
import React from 'react'
import { IPost } from 'types/modules/posts/models/entities/post'
import { IPostQuestion } from 'types/modules/posts/models/entities/post-question'
import { IPostQuestionOption } from 'types/modules/posts/models/entities/post-question-option'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    option: {
      display: 'flex',
      width: '100%',
      height: 40,
      background: '#FFFFFF',
      flexDirection: 'row',
      borderRadius: 9,
      marginBottom: '1rem',
      '&:last-ot-type': {
        marginBottom: 0,
      },
      [theme.breakpoints.down('sm')]: {
        height: '12.738853503184714VW',
        borderRadius: '2.8662420382165608VW',
      },
    },
    optionNumberTab: {
      display: 'flex',
      height: '100%',
      width: 40,
      borderRadius: '9px 0 0 9px',
      background: theme.palette.primary.main,
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        width: '12.738853503184714VW',
        borderRadius: '2.8662420382165608VW 0 0 2.8662420382165608VW',
      },
    },
    optionNumber: {
      color: theme.palette.primary.contrastText,
    },
    optionContentTab: {
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
    },
    optionTextContainer: {
      display: 'flex',
      width: 165,
      [theme.breakpoints.down('sm')]: {
        width: '52.547770700636946VW',
      },
    },
    optionText: {
      lineHeight: '0.8rem',
      fontSize: '0.75rem',
      fontWeight: 600,
      textAlign: 'center',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        lineHeight: '4.076433121019109VW',
        fontSize: '3.821656050955414VW',
      },
    },
    confirmationPopover: {
      padding: '1.25rem',
    },
    confirmationTextWrapper: {
      justifyContent: 'center',
      textAlign: 'center',
      marginBottom: '1.25rem',
    },
    confirmationText: {},
    confirmationButtons: {
      display: 'flex',
      justifyContent: 'center',
    },
    confirmButton: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
      marginRight: '0.625rem',
    },
    cancelButton: {
      color: theme.palette.text.primary,
      backgroundColor: '#ecf0f1',
      marginLeft: '0.625rem',
      '&:hover': {
        backgroundColor: '#f2f5f5',
      },
    },
    optionAnswers: {
      display: 'flex',
      position: 'absolute',
      right: 10,
      height: 30,
      width: 30,
      borderRadius: 90,
      background: 'rgba(0, 0, 0, 0.03)',
      color: theme.palette.text.primary,
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        width: '9.554140127388536VW',
        height: '9.554140127388536VW',
      },
    },
    answerCount: {
      display: 'flex',
      lineHeight: '0.8rem',
      fontSize: '0.75rem',
      fontWeight: 600,
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        lineHeight: '4.076433121019109VW',
        fontSize: '3.821656050955414VW',
      },
    },
  }),
)

interface IWatchlistOptionSelector {
  userPreviousAnswerId?: string
  option: IPostQuestionOption
  index: number
  question: IPostQuestion
  post: IPost
}

export const WatchlistOptionSelector: React.FC<IWatchlistOptionSelector> = (
  props: IWatchlistOptionSelector,
) => {
  const { userPreviousAnswerId, option, index, question, post } = props

  const { addQuestionAnswer, updateQuestionAnswer } = usePostQuestionsManager()

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handlePopoverClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ): void => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = (): void => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  // eslint-disable-next-line id-blacklist
  const id = open ? `option-selector-${option.id}` : undefined

  const portalAnswers = option.answers.filter(
    (answer) =>
      !IsNullOrUndefined(answer.updatedBy) && answer.updatedBy?.fromPortal,
  )

  const classes = useStyles()
  return (
    <React.Fragment>
      <ButtonBase className={classes.option} onClick={handlePopoverClick}>
        <Box className={classes.optionNumberTab}>
          <Typography variant="h5" className={classes.optionNumber}>
            {index + 1}
          </Typography>
        </Box>
        <Box className={classes.optionContentTab}>
          <Box className={classes.optionTextContainer}>
            <Typography variant="body2" className={classes.optionText}>
              {option.text}
            </Typography>
          </Box>
          {portalAnswers.length > 0 && (
            <Tooltip
              title={portalAnswers.map((x) => x.updatedBy?.name).join(', ')}
            >
              <Box className={classes.optionAnswers}>
                <Typography variant="body2" className={classes.answerCount}>
                  {portalAnswers.length}
                </Typography>
              </Box>
            </Tooltip>
          )}
        </Box>
      </ButtonBase>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box className={classes.confirmationPopover}>
          <Box className={classes.confirmationTextWrapper}>
            <Typography className={classes.confirmationText}>
              {'Are you sure you want to select this option?'}
            </Typography>
          </Box>
          <Box className={classes.confirmationButtons}>
            <Button
              variant="contained"
              className={classes.confirmButton}
              size="small"
              onClick={(): void => {
                if (IsNullOrUndefined(userPreviousAnswerId)) {
                  addQuestionAnswer(option, question.id, post.id)
                } else {
                  updateQuestionAnswer(
                    userPreviousAnswerId!,
                    option,
                    question.id,
                    post.id,
                  )
                }
                handlePopoverClose()
              }}
            >
              Confirm
            </Button>
            <Button
              variant="contained"
              className={classes.cancelButton}
              size="small"
              onClick={handlePopoverClose}
              color="secondary"
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Popover>
    </React.Fragment>
  )
}
