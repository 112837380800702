import { useTypedSelector } from 'core/redux/utils'
import { useRouter } from 'core/routing/hooks/use-router'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { NewsCardActions, Selectors } from 'modules/news-cards'
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
} from 'react'
import { useDispatch } from 'react-redux'
import { NIL as NIL_UUID } from 'uuid'
import { INewsCard } from 'modules/news-cards/models/news-card'
import { MediaUploadModel } from 'types/common/images/models/entities/image-upload-model'
import { convertNewsCardToRequestObject } from 'modules/news-cards/utils'

export interface INewsCardsContext {
  currentNewsCard: INewsCard | null
  setCurrentNewsCard: (newsCardId: string) => void
  clearCurrentNewsCard: () => void
  createNewsCard: (
    newsCard: INewsCard,
    callbackFn?: (newsCard: INewsCard) => void,
  ) => void
  createNewsCardDraft: (newsCard?: INewsCard) => void
  updateNewsCard: (newsCard: INewsCard) => void
  deleteNewsCard: () => void
  uploadNewsCardImage: (eventId: string, fileParams: MediaUploadModel) => void
}

const NewsCardsContext = createContext<INewsCardsContext>(
  {} as INewsCardsContext,
)

export const NewsCardsProvider: React.FC<PropsWithChildren<{}>> = (
  props: PropsWithChildren<{}>,
) => {
  const dispatch = useDispatch()

  const {
    routeParams: { newsCardId },
    pushQueryParams,
    removeQueryParams,
  } = useRouter()

  const currentNewsCard = useTypedSelector((state) =>
    Selectors.getCurrentNewsCard(state),
  )

  const setCurrentNewsCard = (newsCardId: string): void => {
    pushQueryParams({
      newsCardId,
    })
  }

  const clearCurrentNewsCard = (): void => {
    removeQueryParams('newsCardId')
  }

  const createNewsCard = (newsCard: INewsCard): void => {
    dispatch(
      NewsCardActions.createNewsCard({
        item: newsCard,
        promise: {
          onResolve: (data: INewsCard): void => {
            setCurrentNewsCard(data.id!)
          },
        },
      }),
    )
  }

  const createNewsCardDraft = (): void => {
    setCurrentNewsCard(NIL_UUID)
  }

  const updateNewsCard = (newsCard: INewsCard): void => {
    dispatch(
      NewsCardActions.updateNewsCard({
        item: newsCard,
        newsCardId: newsCard.id,
      }),
    )
  }

  const deleteNewsCard = (): void => {
    dispatch(
      NewsCardActions.deleteNewsCard({
        newsCardId: currentNewsCard?.id,
      }),
    )
    clearCurrentNewsCard()
  }

  const uploadNewsCardImage = (
    newsCardId: string,
    fileParams: MediaUploadModel,
  ): void => {
    if (!currentNewsCard) return
    const requestObject = convertNewsCardToRequestObject(currentNewsCard)
    dispatch(
      NewsCardActions.updateNewsCard({
        item: {
          ...requestObject,
          media: {
            ...currentNewsCard?.media,
            imageUrl: fileParams.imageUrl,
            mediaItemId: fileParams.mediaItemId,
          },
        },
        newsCardId,
      }),
    )
  }

  useEffect(() => {
    dispatch(NewsCardActions.setCurrentNewsCardId({ newsCardId }))
    if (!IsNullOrUndefined(newsCardId) && newsCardId !== NIL_UUID) {
      dispatch(NewsCardActions.getNewsCardById({ newsCardId }))
    }
  }, [newsCardId])

  const context = {
    uploadNewsCardImage,
    currentNewsCard,
    setCurrentNewsCard,
    clearCurrentNewsCard,
    createNewsCard,
    createNewsCardDraft,
    updateNewsCard,
    deleteNewsCard,
  }

  return (
    <NewsCardsContext.Provider value={context}>
      {props.children}
    </NewsCardsContext.Provider>
  )
}

export const useNewsCardsContext = (): INewsCardsContext =>
  useContext(NewsCardsContext)
