import { Box, Button, ButtonProps, Popover, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx'
import { screenLabel } from 'modules/posts/constants'
import { usePostListContext } from 'modules/posts/context/post-list'
import React from 'react'
import PostType from 'types/modules/posts/enums/post-type'
import { IPost } from 'types/modules/posts/models/entities/post'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popover: {
      padding: '0.625rem',
      flexDirection: 'column',
    },
    createButton: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    postTypeButton: {
      display: 'flex',
      width: '100%',
      marginBottom: '0.625rem',
      '&:last-of-type': {
        margin: 0,
      },
      '&.Bet': {
        color: theme.palette.primary.contrastText,
        backgroundColor: '#ff787d',
        '&:hover': {
          backgroundColor: '#ff999c',
        },
      },
      '&.Game': {
        backgroundColor: '#FDD219',
        '&:hover': {
          backgroundColor: '#fddd4e',
        },
      },
      '&.Poll': {
        color: theme.palette.primary.contrastText,
        backgroundColor: '#06B2BB',
        '&:hover': {
          backgroundColor: '#07d4df',
        },
      },
      '&.Test': {
        color: theme.palette.primary.contrastText,
        backgroundColor: '#F78828',
        '&:hover': {
          backgroundColor: '#f9a053',
        },
      },
    },
  }),
)

interface ICreatePostDialogButtonProps {
  initialData?: Partial<IPost>
  buttonProps: ButtonProps
  onPopoverClose?: () => void
  postTypes?: PostType[]
}

export const CreatePostDialogButton: React.FC<ICreatePostDialogButtonProps> = (
  props: ICreatePostDialogButtonProps,
) => {
  const classes = useStyles()

  const { createPostDraft } = usePostListContext()

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handlePopoverClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ): void => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = (): void => {
    setAnchorEl(null)
    props.onPopoverClose && props.onPopoverClose()
  }

  const open = Boolean(anchorEl)

  // eslint-disable-next-line id-blacklist
  const id = open ? 'create-post-button' : undefined

  const renderPostTypeButtons = (): JSX.Element[] => {
    return (props.postTypes as string[] || Object.values(PostType)).map((postType: string) => {
      const onClick = (): void => createPostDraft(postType as PostType)
      const label = screenLabel[postType as PostType]

      return (
        <Button
          key={`create-post-button-${postType}`}
          className={clsx(classes.postTypeButton, postType)}
          {...props.buttonProps}
          onClick={(): void => {
            onClick()
            handlePopoverClose()
          }}
        >
          {label}
        </Button>
      )
    })
  }

  return (
    <React.Fragment>
      <Button
        className={classes.createButton}
        {...props.buttonProps}
        onClick={handlePopoverClick}
      >
        Create Post
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box className={classes.popover}>{renderPostTypeButtons()}</Box>
      </Popover>
    </React.Fragment>
  )
}
