import ActionTypes from 'modules/iap-products/action-types/iap-products-action-types'
import { Reducer } from 'redux'
import { actionTypeSuccess, cacheItem, cacheItems } from 'core/redux/utils'
import { ISimpleAction, ISuccessAction } from 'types/redux/interfaces/IAction'
import _ from 'core/utils/deepdash'
import { mergeArrays } from 'core/utils/mergeArrays'
import { IIapProductActionParams } from 'modules/iap-products/actions/iap-product-actions'
import { NIL as NIL_UUID } from 'uuid'
import { IIapProductState } from 'modules/iap-products/models/state'
import { IIapProduct } from 'modules/iap-products/models/iap-product'


const initialState: IIapProductState = {
  items: [],
  cache: {},
}

export type GetIapProductsApiResponse = {
  items: IIapProduct[]
}

type AllowedActionTypes = ISuccessAction | ISimpleAction

const handleUpdate = (state: IIapProductState,
    action: AllowedActionTypes): IIapProductState => {
  const successAction = action as ISuccessAction
    const payloadItem = successAction.data as IIapProduct
    const itemIndex = state.items.findIndex((x) => x.id === payloadItem.id)

    return {
      ...state,
      items:
        itemIndex >= 0
          ? state.items.map((item, index) =>
              index === itemIndex ? payloadItem : item,
            )
          : [...state.items, payloadItem],
      cache: cacheItem(state.cache, payloadItem),
    }
}

const actionHandlers = {
  [ActionTypes.SET_CURRENT_IAP_PRODUCT_ID]: (
    state: IIapProductState,
    action: AllowedActionTypes,
  ): IIapProductState => {
    const simpleAction = action as ISimpleAction
    const { iapProductId } =
      simpleAction.payload as IIapProductActionParams
    return {
      ...state,
      currentIapProductId: iapProductId ? iapProductId : undefined,
    }
  },
  [ActionTypes.CREATE_IAP_PRODUCT_DRAFT]: (
    state: IIapProductState,
    action: AllowedActionTypes,
  ): IIapProductState => {
    const simpleAction = action as ISimpleAction
    const { item } = simpleAction.payload as IIapProductActionParams

    return {
      ...state,
      currentIapProductId: NIL_UUID,
      items: [item as IIapProduct, ...state.items],
    }
  },
  [actionTypeSuccess(ActionTypes.GET_IAP_PRODUCTS)]: (
    state: IIapProductState,
    action: AllowedActionTypes,
  ): IIapProductState => {
    const successAction = action as ISuccessAction
    const { items: response } = successAction.data as { items:  IIapProduct[] }
    return {
      ...state,
      items: response,
      cache: cacheItems(state.cache, response),
    }
  },
  [actionTypeSuccess(ActionTypes.GET_IAP_PRODUCT_BY_ID)]: (
    state: IIapProductState,
    action: AllowedActionTypes,
  ): IIapProductState => {
    const successAction = action as ISuccessAction
    const response = successAction.data as IIapProduct
    return {
      ...state,
      cache: cacheItem(state.cache, response),
    }
  },
  [actionTypeSuccess(ActionTypes.CREATE_IAP_PRODUCT)]: (
    state: IIapProductState,
    action: AllowedActionTypes,
  ): IIapProductState => {
    const successAction = action as ISuccessAction
    const responseItem = successAction.data as IIapProduct

    const { items } = state

    return {
      ...state,
      items: [responseItem, ...items],
      cache: cacheItem(state.cache, responseItem),
    }
  },
  [actionTypeSuccess(ActionTypes.DELETE_IAP_PRODUCT)]: (
    state: IIapProductState,
    action: AllowedActionTypes,
  ): IIapProductState => {
    const successAction = action as ISuccessAction

    const currentCache = { ...state.cache }
    const { id } = successAction.data as IIapProduct
    delete currentCache[id!]

    return {
      ...state,
      items: state.items.filter(item => item.id !== id),
      cache: { ...currentCache }
    }
  },
  [actionTypeSuccess(ActionTypes.UPDATE_IAP_PRODUCT)]: (
    state: IIapProductState,
    action: AllowedActionTypes,
  ): IIapProductState => handleUpdate(state, action),
}

const iapProductsReducer: Reducer<IIapProductState, AllowedActionTypes> = (
  state = initialState,
  action: AllowedActionTypes,
) => {
  return actionHandlers[action.type]
    ? actionHandlers[action.type](state, action)
    : state
}

export default iapProductsReducer
