export enum ResultField {
  ID = 'id',
  POST_ID = 'postId',
  TEXT = 'text',
  MEDIA = 'media',
  MEDIA_ITEM_ID = 'mediaItemId',
  DESCRIPTION = 'description',
  IMAGE_URL = 'imageUrl',
  UPDATED_AT = 'updatedAt',
  UPDATED_BY = 'updatedBy',
  TOTAL_OUTCOMES = 'totalOutcomes',
}
