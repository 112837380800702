import { Button, ButtonProps, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx'
import { ConfirmationDialog } from 'components/confirmation-dialog'
import { CloseIcon } from 'components/icons'
import { usePostListContext } from 'modules/posts/context/post-list'
import { usePostStatusManager } from 'modules/posts/context/post-status-manager'
import React from 'react'
import PostStatus from 'types/modules/posts/enums/post-status'
import PostType from 'types/modules/posts/enums/post-type'
import { IPostActionButtonProps } from 'types/modules/posts/models/props/post-action-button-props'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.text.primary,
      backgroundColor: '#ecf0f1',
      '&:hover': {
        backgroundColor: '#f2f5f5',
      },
    },
  }),
)

export const ClosePostButton: React.FC<IPostActionButtonProps> = (
  props: IPostActionButtonProps,
) => {
  const { currentPost, setCurrentPost } = usePostListContext()
  const { closePost } = usePostStatusManager()

  const classes = useStyles()

  const isActive = props.post.status === PostStatus.Active
  const isPaused = props.post.status === PostStatus.Paused

  const requiresValidation = ![PostType.Poll, PostType.Test].includes(
    props.post.type,
  )

  const canClosePost =
    !requiresValidation ||
    props.post.questions.every((question) =>
      question.options.some((option) => option.correct),
    )

  const buttonProps: ButtonProps = {
    variant: 'contained',
    startIcon: <CloseIcon />,
    className: clsx(classes.root),
    size: 'small',
    disabled: props.disabled,
  }

  const popoverButtonProps = {
    ...buttonProps,
    disabled: props.disabled || (requiresValidation && !canClosePost),
  }

  const buttonLabel = 'close'

  return isActive || isPaused ? (
    requiresValidation && !canClosePost && props.post.id !== currentPost?.id ? (
      <Button
        {...buttonProps}
        onClick={(e): void => {
          setCurrentPost(props.post.id, props.post.type)
        }}
      >
        {buttonLabel}
      </Button>
    ) : (
      <ConfirmationDialog
        popoverId={`${buttonLabel}-post-${props.post.id}`}
        buttonProps={popoverButtonProps}
        onConfirmation={(): void => closePost(props.post.id)}
        confirmationText={`Are you sure you want to ${buttonLabel} this post?`}
        buttonLabel={buttonLabel}
        onPopoverClose={props.onPopoverClose}
      />
    )
  ) : null
}
