import { OverrideChipProps } from 'components/controls/inputs/downshift/multiselect/multiselect-props'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { ITagActionParams } from 'modules/tags/actions'
import React from 'react'
import SortDirection from 'types/common/enums/sort-direction'
import { FilterOperator } from 'types/common/filtering/enums/FilterOperator'
import { FilterType } from 'types/common/filtering/enums/FilterType'
import { IFilter } from 'types/common/filtering/models/entities/IFilter'
import { IAsyncAction } from 'types/redux/interfaces/IAction'
import { NIL as NIL_UUID } from 'uuid'
import { AutocompleteSearchMultiselect } from 'components/controls/inputs/downshift/autocomplete/autocomplete-search-multiselect'
import { formatTagCode } from 'modules/tags/utils/format-tag-code'
import { useTagsContext } from 'modules/tags/context'
import { Tag } from '../types/modules/tags/models/entities/tag'
import { Selectors as TagSelectors } from '../modules/tags'
import * as TagActions from '../modules/tags/actions'

interface IParams {
  initiallySelectedTags: Tag[],
  onSelect: (val: Tag[]) => void,
  hasCreateOption?: boolean,
  onClickTag?: (val: string) => void,
}

export const useTagSelect = ({
  initiallySelectedTags,
  onSelect,
  hasCreateOption,
  onClickTag,
}: IParams): JSX.Element => {
  const { createTagFromName } = useTagsContext()
  const searchTags = (params: ITagActionParams): IAsyncAction => {
    const filters: IFilter[] = [
      {
        field: 'name',
        type: FilterType.Search,
        operator: FilterOperator.ContainsCaseInsensitive,
        value: params.search!.value,
      },
    ]

    return TagActions.getAutocompleteSearchTags({
      filters,
      sortyBy: 'name',
      sortDirection: SortDirection.Ascending,
    })
  }

  const additionalProps = hasCreateOption
    ? {
      createOptions: {
        onCreate: (
          draftTag: Tag,
          callbackFn: (newTag: Tag) => void,
        ): void => {
          createTagFromName(
            draftTag.name!,
            (newTag: Tag): void => {
              callbackFn(newTag)
            },
          )
        },
        newItemPlaceholder: (inputValue: string): Tag => {
          return {
            id: NIL_UUID,
            name: inputValue,
            code: formatTagCode(inputValue),
          }
        },
      },
    }
    : {}

  return <AutocompleteSearchMultiselect
    variant="filled"
    initialSelectedItems={initiallySelectedTags}
    availableItems={[]}
    onSelectedItemsChange={(selectedTags: Tag[]): void => {
      onSelect(selectedTags)
    }}
    searchAsyncActionCreator={searchTags}
    selectorMethod={TagSelectors.getAutocompleteSearchTags}
    options={{
      selectedItemsChipProps: (item: Tag): OverrideChipProps => {
        const props: OverrideChipProps = {
          label: item.name!,
          onClick: () => {
            if (onClickTag) {
              onClickTag(item.id!)
            }
          },
        }
        if (!IsNullOrUndefined(item.media?.icon)) {
          props.icon = <i>{item.media!.icon!}</i>
        }
        return props
      },
      autocompleteSearchResultContent: (item: Tag): string =>
        item.id === NIL_UUID
          ? `Create new tag '${item.name}'`
          : `${item.media?.icon ?? ''} ${item.name} - Level: ${
              item.level
            }`,
      ...additionalProps
    }}
  />
}