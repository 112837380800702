import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react'

import { TextField, InputAdornment, Select, MenuItem, Theme, Button, IconButton, SelectChangeEvent } from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import SearchIcon from '@mui/icons-material/Search'
import SortIcon from '@mui/icons-material/SortOutlined'

import { IKeyValueStringCollection } from 'types/common/interfaces/IKeyValueStringCollection'
import { SortValue } from 'types/common/sorting/models/entities/sort-value'
import { SortDirection } from 'types/common/enums/sort-direction'

import _ from 'core/utils/deepdash'
import clsx from 'clsx'

type SortOption = {
  label: string
  value: string
}

interface IPanelToolbarProps {
  onSearch: (searchValue: string) => void
  onSort: (sortValue: SortValue) => void
  sortingOptions: SortOption[]
  defaultSortValue: SortValue
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbarControlWrapper: {
      display: 'flex',
      flex: 1,
      marginRight: '0.625rem',
      '&:last-child': {
        margin: 0,
      },
    },
    toolbarSearchInput: {
      display: 'flex',
      flex: 1,
    },
    toolbarSortInput: {
      display: 'flex',
      flex: 1,
      height: '2.5rem',
      overflow: 'hidden',
    },
    toolbarSortInputText: {
      textOverflow: 'clip',
    },
    toolbarSortDescending: {
      transform: 'scaleY(-1)',
    },
  }),
)

const SearchAndSortToolbar: React.FC<PropsWithChildren<IPanelToolbarProps>> = (
  props: PropsWithChildren<IPanelToolbarProps>,
) => {
  const [searchValue, setSearchValue] = useState('')
  const [sortValue, setSortValue] = useState(props.defaultSortValue)

  // Handle Search
  const handleSearchChange = (
    event: React.ChangeEvent<{ value: unknown }>,
  ): void => {
    setSearchValue(event.target.value as string)
  }

  const handleSearchSubmit = useCallback(
    (event: React.KeyboardEvent): void => {
      if (event.keyCode === 13) {
        // 13: Enter Key
        props.onSearch(searchValue)
      }
    },
    [searchValue],
  )

  // Handle Sorting
  const handleSortBy = (event: SelectChangeEvent<string>): void => {
    const newSortValue = _.cloneDeep(sortValue)
    newSortValue.sortBy = event.target.value as string
    setSortValue(newSortValue)
  }

  const handleSortDirection = (): void => {
    const newSortValue = _.cloneDeep(sortValue)
    newSortValue.sortDirection =
      sortValue.sortDirection === SortDirection.Ascending
        ? SortDirection.Descending
        : SortDirection.Ascending
    setSortValue(newSortValue)
  }

  // Trigger props.onSort when sortValue changes
  useEffect(() => props.onSort(sortValue), [sortValue])

  // Render
  const classes = useStyles()

  const SortButton: React.FC<{}> = () => (
    <IconButton
      size="small"
      onClick={handleSortDirection}
      className={clsx(
        sortValue.sortDirection !== SortDirection.Ascending &&
          classes.toolbarSortDescending,
      )}
    >
      <SortIcon />
    </IconButton>
  )

  return (
    <React.Fragment>
      <div className={classes.toolbarControlWrapper}>
        <TextField
          size="small"
          className={classes.toolbarSearchInput}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          variant="outlined"
          value={searchValue}
          onChange={handleSearchChange}
          onKeyDown={handleSearchSubmit}
        />
      </div>
      <div className={classes.toolbarControlWrapper}>
        <Select
          value={sortValue.sortBy}
          onChange={handleSortBy}
          displayEmpty
          className={classes.toolbarSortInput}
          inputProps={{
            'aria-label': 'Without label',
            className: classes.toolbarSortInputText,
          }}
          startAdornment={
            <InputAdornment position="start">Sort by: </InputAdornment>
          }
          endAdornment={<SortButton />}
          IconComponent={'object'}
          variant="outlined"
        >
          {props.sortingOptions.map((option, index) => (
            <MenuItem key={`${option.value}-${index}`} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </div>
    </React.Fragment>
  )
}

export default SearchAndSortToolbar
