import React from 'react'
import { Box, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { ICalendarGridCellProps } from 'components/calendar/props/calendar-grid-cell-props'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { usePopover } from 'components/popover/use-popover'
import { IInfoCard } from 'types/modules/info-cards/models/entities/info-card'
import { TimeGridInfoCard } from 'modules/info-cards/views/components/dashboard/info-card-calendar-time-grid-cell/info-card'
import { TimeGridInfoCardMultiview } from 'modules/info-cards/views/components/dashboard/info-card-calendar-time-grid-cell/multiview'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    infoCardGridCellContainer: {
      display: 'flex',
      flex: 1,
      '&:hover': {
        background: 'rgba(0,0,0,0.03)',
        cursor: 'pointer',
      },
    },
    emptyCell: {
      display: 'flex',
      flex: 1,
    },
  }),
)

export const InfoCardCalendarTimeGridCell: React.FC<
  ICalendarGridCellProps<IInfoCard>
> = (props: ICalendarGridCellProps<IInfoCard>) => {
  const { items, dateTime, sortBy } = props

  const classes = useStyles()

  const contextMenu = usePopover({
    id: `context-menu-${dateTime}`,
    anchorReference: 'anchorPosition',
  })

  const renderViewSelector = (): JSX.Element | null => {
    if (IsNullOrUndefined(items) || items.length === 0) {
      return <Box className={classes.emptyCell} />
    }

    switch (items.length) {
      case 1:
        return (
          <TimeGridInfoCard infoCard={items[0]} index={0} dateField={sortBy} />
        )
      default:
        return (
          <TimeGridInfoCardMultiview
            infoCards={items}
            dateTime={dateTime}
            dateField={sortBy}
          />
        )
    }
  }

  return (
    <Box className={classes.infoCardGridCellContainer}>
      {renderViewSelector()}
    </Box>
  )
}
