import React from 'react'
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Box, Button, ButtonProps, Grid } from '@mui/material'
import { NIL as NIL_UUID } from 'uuid'
import { PanelContent, PanelFooter } from 'components/panel'
import _ from 'core/utils/deepdash'
import { useFormik } from 'formik'
import { colors } from 'utils/colors'
import { useIapProductsContext } from 'modules/iap-products/context/iap-product-context'
import {
  IIapProduct,
  IIapProductMetData,
  ProductStatus,
  ProductType,
} from 'modules/iap-products/models/iap-product'
import { IapProductForm } from 'modules/iap-products/view/iap-product-modal/iap-product-form'
import { IapProductSchema } from 'modules/iap-products/validation/iap-product-schema'
import { ModalWrapper } from 'components/modal-wrapper'
import { ConfirmationDialog } from 'components/confirmation-dialog'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
    },
    panelHeader: {
      display: 'flex',
      width: '100%',
      flexShrink: 0,
    },
    panelTitle: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      padding: '0 0 0 1.25rem',
    },
    navigationContainer: {
      display: 'flex',
      flex: 2,
      flexGrow: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    rightHeaderSection: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      padding: '0 1.25rem 0 0',
      flexDirection: 'row-reverse',
      '& .MuiButton-contained': {
        marginRight: 20,
      },
    },
    footerContent: {
      display: 'flex',
      flex: 1,
      justifyContent: 'flex-end',
    },
    cancelBtn: {
      marginRight: '1.25rem',
    },
    wrapper: {
      borderLeft: `1px solid ${colors.rgba_0_0_0_12}`,
    },
    form: {
      height: '600px',
      overflow: 'auto',
    },
  }),
)

const createRedefinedMetaData = (
  iapProduct: IIapProduct | null,
): IIapProductMetData => {
  const metaData = iapProduct?.metaData || { productConfig: { currencies: [] } }
  return {
    ...metaData,
    productConfig: {
      ...metaData.productConfig,
      currencies: (metaData?.productConfig?.currencies || []).map(
        (currencyItem, index) => ({
          ...currencyItem,
          id: Date.now() + index,
        }),
      ),
    },
  }
}

export const IapProductModal: React.FC = () => {
  const classes = useStyles()
  const {
    currentIapProduct,
    updateIapProduct,
    createIapProduct,
    clearCurrentIapProduct,
    deleteIapProduct,
  } = useIapProductsContext()
  const isUpdating = currentIapProduct?.id !== NIL_UUID

  const form = useFormik({
    enableReinitialize: !isUpdating,
    validationSchema: IapProductSchema,
    initialValues: {
      id:
        !currentIapProduct?.id || currentIapProduct?.id === NIL_UUID
          ? ''
          : currentIapProduct?.id,
      description: currentIapProduct?.description || '',
      secondsFromUserCreationToStart: currentIapProduct?.secondsFromUserCreationToStart || '',
      secondsFromUserCreationToEnd: currentIapProduct?.secondsFromUserCreationToEnd || '',
      metaData: createRedefinedMetaData(currentIapProduct),
      type: currentIapProduct?.type || ProductType.NotSet,
      status: currentIapProduct?.status || ProductStatus.Inactive,
    },
    onSubmit: (value: IIapProduct) => {
      const renewsCard = isUpdating ? updateIapProduct : createIapProduct
      renewsCard({
        ...value,
        secondsFromUserCreationToEnd: value.secondsFromUserCreationToEnd
          ? parseFloat(value.secondsFromUserCreationToEnd as string)
          : undefined,
        secondsFromUserCreationToStart: value.secondsFromUserCreationToStart
          ? parseFloat(value.secondsFromUserCreationToStart as string)
          : undefined,
      })
    },
  })

  return (
    <ModalWrapper
      panelSize={10}
      closeModal={clearCurrentIapProduct}
      title={
        isUpdating
          ? `Edit ${currentIapProduct?.id} (${currentIapProduct?.status})`
          : 'Create IAP product'
      }
      open={!!currentIapProduct}
    >
      <>
        <PanelContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <IapProductForm
                iapProduct={form.values}
                handleChange={form.handleChange}
                setFieldTouched={form.setFieldTouched}
                setFieldValue={form.setFieldValue}
                errors={form.errors}
                isUpdating={isUpdating}
              />
            </Grid>
          </Grid>
        </PanelContent>
        <PanelFooter>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box className={classes.footerContent}>
                <Box>
                  <Button
                    size="large"
                    className={classes.cancelBtn}
                    onClick={clearCurrentIapProduct}
                    variant="contained"
                    color="secondary"
                  >
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    size="large"
                    onClick={form.submitForm}
                    variant="contained"
                    disabled={form.dirty && !form.isValid}
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </PanelFooter>
      </>
    </ModalWrapper>
  )
}
