import React from 'react'
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Box, Button, Grid } from '@mui/material'
import { NIL as NIL_UUID } from 'uuid'
import { PanelContent, PanelFooter } from 'components/panel'
import _ from 'core/utils/deepdash'
import { useFormik } from 'formik'
import { colors } from 'utils/colors'
import { useQuestGroupsContext } from 'modules/quests/context/quest-group-context'
import { QuestGroupField } from 'modules/quests/enums/quest-group-field'
import { IQuestGroup } from 'modules/quests/models/quest-group'
import { QuestGroupForm } from 'modules/quests/view/quest-groups/quest-group-modal/quest-group-form'
import { QuestGroupSchema } from 'modules/quests/validation/quest-group-schema'
import { ModalWrapper } from 'components/modal-wrapper'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%'
    },
    footerContent: {
      display: 'flex',
      flex: 1,
      justifyContent: 'flex-end',
    },
    cancelBtn: {
      marginRight: '1.25rem',
    },
    wrapper: {
      borderLeft: `1px solid ${colors.rgba_0_0_0_12}`,
    },
    form: {
      height: '600px',
      overflow: 'auto'
    }
  }),
)


export const QuestGroupModal: React.FC = () => {
  const classes = useStyles()
  const { currentQuestGroup, updateQuestGroup, createQuestGroup, clearCurrentQuestGroup } = useQuestGroupsContext()
  const isUpdating = currentQuestGroup?.id !== NIL_UUID

  const form = useFormik({
    enableReinitialize: isUpdating,
    validationSchema: QuestGroupSchema,
    initialValues: {
      [QuestGroupField.ID]: currentQuestGroup?.[QuestGroupField.ID] ?? '',
      [QuestGroupField.NAME]: currentQuestGroup?.[QuestGroupField.NAME] || '',
      [QuestGroupField.TEST]: currentQuestGroup?.[QuestGroupField.TEST] || '',
      [QuestGroupField.VARIANT]: currentQuestGroup?.[QuestGroupField.VARIANT] ?? '',
      [QuestGroupField.ACTIVE]: !!currentQuestGroup?.[QuestGroupField.ACTIVE],
      [QuestGroupField.QUEST_GROUP_QUESTS]: currentQuestGroup?.[QuestGroupField.QUEST_GROUP_QUESTS] ?? [],
    },
    onSubmit: (value: IQuestGroup) => {
      const request = isUpdating ? updateQuestGroup : createQuestGroup
      request({
        ...value,
        questIds: value.questGroupQuests.map((group) => group.questId)
      })
    },
  })

  return (
    <ModalWrapper
      panelSize={6}
      closeModal={clearCurrentQuestGroup}
      title={isUpdating ? 'Edit quest group' : 'Create quest group'}
      open={!!currentQuestGroup}
    >
      <>
        <PanelContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <QuestGroupForm
                questGroup={form.values}
                handleChange={form.handleChange}
                setFieldTouched={form.setFieldTouched}
                setFieldValue={form.setFieldValue}
                errors={form.errors}
              />
            </Grid>
          </Grid>
        </PanelContent>
        <PanelFooter>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box className={classes.footerContent}>
                <Button
                  size="large"
                  className={classes.cancelBtn}
                  onClick={clearCurrentQuestGroup}
                  variant="contained"
                  color="secondary"
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  size="large"
                  onClick={form.submitForm}
                  variant="contained"
                  disabled={form.dirty && !form.isValid}
                >
                  Save
                </Button>
              </Box>
            </Grid>
          </Grid>
        </PanelFooter>
      </>
    </ModalWrapper>
  )
}
