import { Box, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { hoursInDay } from 'components/calendar/utils'
import moment from 'moment-timezone'
import React from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    timeColumn: {
      display: 'flex',
      flexDirection: 'column',
      height: 'max-content',
      width: 40,
      marginRight: '0.625rem',
    },
    columnRow: {
      display: 'flex',
      width: '100%',
      height: 80,
      justifyContent: 'center',
    },
    time: {
      fontSize: '0.7rem',
    },
  }),
)

export const TimeColumn: React.FC<{}> = () => {
  const classes = useStyles()

  return (
    <Box className={classes.timeColumn}>
      {hoursInDay().map((hour) => {
        return (
          <Box key={hour} className={classes.columnRow}>
            <Typography
              component="span"
              variant="button"
              color="textPrimary"
              className={classes.time}
            >
              {moment(hour).format('h A')}
            </Typography>
          </Box>
        )
      })}
    </Box>
  )
}
