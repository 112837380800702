import { AppState } from 'types/redux/types/app-state'
import {
  MODULE_NAME,
} from 'modules/milestones/constants'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import {
  IMilestoneState,
} from 'modules/milestones/models/state'
import { IMilestone } from 'modules/milestones/models/milestone'


const getMilestoneState = (state: AppState): IMilestoneState => state[MODULE_NAME]


export const getCurrentMilestone = (
  state: AppState,
): IMilestone | null => {
  const { currentMilestoneId, cache } = getMilestoneState(state)
  if (IsNullOrUndefined(currentMilestoneId)) return null
  const currentMilestone = cache[currentMilestoneId!]
  return currentMilestone ?? null
}

export const getMilestones = (state: AppState): IMilestone[] =>
  getMilestoneState(state).items

export const getMilestonesCount = (state: AppState): number =>
  getMilestoneState(state).count
