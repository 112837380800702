import { Box, Button, Divider, IconButton, SvgIcon, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useLiveScheduleContext } from 'modules/live-schedules/context'
import { NIL as NIL_UUID } from 'uuid'
import React from 'react'
import { LiveScheduleField as FieldName } from 'types/modules/live-schedules/enums/live-schedule-field'
import { useFormik } from 'formik'
import moment from 'moment-timezone'
import { ILiveScheduleDraft } from 'types/modules/live-schedules/models/entities/live-schedule-draft'
import { LiveScheduleForm } from 'modules/live-schedules/views/components/schedule-details-panel/live-schedule-form'
import { Panel, PanelContainer } from 'components/panel'
import { CrossIcon, UndoIcon, SaveIcon } from 'components/icons'
import { LiveScheduleSchema } from 'modules/live-schedules/validation/live-schedule-schema'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    panelHeader: {
      display: 'flex',
      width: '100%',
      flexShrink: 0,
      height: 72,
    },
    panelTitle: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      padding: '0 0 0 1.25rem',
    },
    rightHeaderSection: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      padding: '0 1.25rem 0 0',
      flexDirection: 'row-reverse',
      '& .MuiButton-contained': {
        marginRight: 20,
      },
    },
    contentWrapper: {
      display: 'flex',
      flexGrow: 1,
      overflow: 'hidden',
    },
    contentPanel: {
      position: 'relative',
      display: 'flex',
      flex: 1,
      padding: '1.25rem',
      overflow: 'auto',
      borderRight: '1px solid rgba(0,0,0,0.12)',
      '&:last-child': {
        border: 'none',
      },
    },
    footer: {
      display: 'flex',
      width: '100%',
      justifySelf: 'flex-end',
    },
    footerSection: {
      display: 'flex',
      padding: '1.25rem',
      flex: 1,
      justifyContent: 'space-between',
      borderRight: '1px solid rgba(0,0,0,0.12)',
      '&:last-child': {
        border: 'none',
      },
    },
    updateButton: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    resetButton: {
      color: theme.palette.text.primary,
      backgroundColor: '#ecf0f1',
      '&:hover': {
        backgroundColor: '#f2f5f5',
      },
    },
  }),
)

enum DisplayMode {
  View = 'View',
  Edit = 'Edit',
  Create = 'Create',
}

interface ILiveScheduleDetailsPanelProps {
  isModal?: boolean
  closeModal?: () => void
}

export const LiveScheduleDetailsPanel: React.FC<
  ILiveScheduleDetailsPanelProps
> = (props: ILiveScheduleDetailsPanelProps) => {
  const classes = useStyles()

  const {
    currentLiveSchedule: liveSchedule,
    clearCurrentLiveSchedule,
    updateLiveSchedule,
    createLiveSchedule,
  } = useLiveScheduleContext()

  const displayMode =
    liveSchedule?.id !== NIL_UUID
      ? DisplayMode.Edit
      : DisplayMode.Create ?? 'Loading'

  const form = useFormik({
    enableReinitialize: true,
    validationSchema: LiveScheduleSchema,
    initialValues: {
      [FieldName.ID]: liveSchedule?.[FieldName.ID] ?? NIL_UUID,
      [FieldName.NAME]: liveSchedule?.[FieldName.NAME] ?? '',
      [FieldName.DESCRIPTION]: liveSchedule?.[FieldName.DESCRIPTION] ?? '',
      [FieldName.START]:
        liveSchedule?.[FieldName.START] ?? moment().toISOString(),
      [FieldName.END]:
        liveSchedule?.[FieldName.END] ?? moment().add(1, 'day').toISOString(),
      // [FieldName.LIVE]: liveSchedule?.[FieldName.LIVE] ?? true,
      [FieldName.EVENT_ID]: liveSchedule?.[FieldName.EVENT_ID] ?? '',
      [FieldName.XP_MULTIPLIER]: liveSchedule?.[FieldName.XP_MULTIPLIER] ?? 1,
      [FieldName.CREATED_AT]: liveSchedule?.[FieldName.CREATED_AT] ?? '',
      [FieldName.UPDATED_AT]: liveSchedule?.[FieldName.UPDATED_AT] ?? '',
      [FieldName.TIMEZONE]: liveSchedule?.[FieldName.TIMEZONE] ?? '',
    },
    onSubmit: (value: ILiveScheduleDraft) => {
      displayMode === DisplayMode.Create
        ? createLiveSchedule(value)
        : updateLiveSchedule(value)
    },
  })

  return (
    <PanelContainer>
      <Panel container xs={12}>
        <Box className={classes.panelHeader}>
          <Box className={classes.panelTitle}>
            <Typography
              variant="h5"
              color="textPrimary"
              style={{
                paddingRight: 10,
              }}
            >
              {`${displayMode} Live Schedule`}
            </Typography>
          </Box>
          <Box className={classes.rightHeaderSection}>
            {props.isModal && (
              <IconButton onClick={(): void => clearCurrentLiveSchedule()} size="large">
                <SvgIcon>
                  <CrossIcon />
                </SvgIcon>
              </IconButton>
            )}
          </Box>
        </Box>
        <Divider />
        <Box className={classes.contentWrapper}>
          <Box className={classes.contentPanel}>
            <LiveScheduleForm
              {...{
                errors: form.errors,
                liveSchedule: form.values,
                handleChange: form.handleChange,
                setFieldTouched: form.setFieldTouched,
                setFieldValue: form.setFieldValue,
                setValues: form.setValues,
              }}
            />
          </Box>
        </Box>
        <Divider />
        <Box className={classes.footer}>
          <Box className={classes.footerSection}>
            <Button
              variant="contained"
              startIcon={<UndoIcon />}
              className={classes.resetButton}
              size="small"
              onClick={(): void => {
                form.resetForm()
              }}
              disabled={!form.dirty}
            >
              Undo
            </Button>
            <Button
              variant="contained"
              startIcon={<SaveIcon />}
              className={classes.updateButton}
              size="small"
              onClick={(): void => {
                form.submitForm()
              }}
              disabled={!form.dirty}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Panel>
    </PanelContainer>
  );
}
