import { Theme, Popover, Box, Button, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { IPopoverProps } from 'components/popover/use-popover'
import React from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    confirmationPopover: {
      padding: '1.25rem',
    },
    confirmationTextWrapper: {
      justifyContent: 'center',
      textAlign: 'center',
      marginBottom: '1.25rem',
    },
    confirmationText: {
      whiteSpace: 'pre-wrap',
    },
    confirmationButtons: {
      display: 'flex',
      justifyContent: 'center',
    },
    confirmButton: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
      marginRight: '0.625rem',
    },
    cancelButton: {
      color: theme.palette.text.primary,
      backgroundColor: '#ecf0f1',
      marginLeft: '0.625rem',
      '&:hover': {
        backgroundColor: '#f2f5f5',
      },
    },
  }),
)

interface IDisbaleMultibetConfirmationPopoverProps {
  confirmationText: string
  onConfirmation: () => void
  popoverProps: IPopoverProps
}

export const DisbaleMultibetConfirmationPopover: React.FC<
  IDisbaleMultibetConfirmationPopoverProps
> = (props: IDisbaleMultibetConfirmationPopoverProps) => {
  const { confirmationText, onConfirmation, popoverProps } = props

  const classes = useStyles()

  return (
    <Popover {...popoverProps}>
      <Box className={classes.confirmationPopover}>
        <Box className={classes.confirmationTextWrapper}>
          <Typography className={classes.confirmationText}>
            {confirmationText}
          </Typography>
        </Box>
        <Box className={classes.confirmationButtons}>
          <Button
            variant="contained"
            className={classes.confirmButton}
            size="small"
            onClick={(): void => {
              onConfirmation()
              popoverProps.onClose()
            }}
          >
            Confirm
          </Button>
          <Button
            variant="contained"
            className={classes.cancelButton}
            size="small"
            onClick={(): void => popoverProps.onClose()}
            color="secondary"
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Popover>
  )
}
