import _ from 'core/utils/deepdash'
import { ISelectOption } from 'types/common/filtering/models/entities/ISelectOption'
import PrizeStatus from 'types/modules/prizes/enums/prize-status'
import { IPrizeState } from 'types/modules/prizes/models/state/prize-state'


const closedGroup = [
  PrizeStatus.Closed,
  PrizeStatus.Settled,
]

export const getStatusFilterOptions = (
  statuses: IPrizeState['statuses'],
): ISelectOption[] => {

  const sumStatusCountForGroup = (statusGroup: PrizeStatus[]): number => {
    let total = 0
    statusGroup.forEach((status) => (total += statuses[status]))
    return total
  }

  return [
    {
      label: PrizeStatus.Closed,
      value: closedGroup,
      metadata: {
        count: sumStatusCountForGroup(closedGroup),
      },
    },
    {
      label: 'Active',
      value: [PrizeStatus.Open],
      metadata: {
        count: statuses[PrizeStatus.Open],
      },
    },
    {
      label: PrizeStatus.Scheduled,
      value: [PrizeStatus.Scheduled],
      metadata: {
        count: statuses[PrizeStatus.Scheduled],
      },
    },
    {
      label: PrizeStatus.Pending,
      value: [PrizeStatus.Pending],
      metadata: {
        count: statuses[PrizeStatus.Pending],
      },
    },
    {
      label: 'Drafts',
      value: [PrizeStatus.Created],
      metadata: {
        count: statuses[PrizeStatus.Created],
      },
    },
    {
      label: PrizeStatus.Trashed,
      value: [PrizeStatus.Trashed],
      metadata: {
        count: statuses[PrizeStatus.Trashed],
      },
    },

    {
      label: 'Warning',
      value: [PrizeStatus.OutOfStock],
      metadata: {
        count: statuses[PrizeStatus.OutOfStock],
      },
      labelColor: '#f78c00',
    },
  ]
}
