import { ButtonBase, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx'
import { IsNullUndefinedOrEmpty } from 'core/utils/isNullOrUndefined'
import React, { PropsWithChildren } from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbarButton: {
      width: 32,
      height: 32,
      marginRight: 1,
      '&:hover, &.active': {
        background: 'rgba(0,0,0,0.03)',
      },
    },
    primary: {
      color: theme.palette.primary.contrastText,
      backgroundColor: '#ff787d',
      '&:hover': {
        backgroundColor: '#ff999c',
      },
    },
  }),
)

export interface IToolbarButtonProps {
  classes?: string[]
  active?: boolean
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
}

export const ToolbarButton: React.FC<PropsWithChildren<IToolbarButtonProps>> = (
  props: PropsWithChildren<IToolbarButtonProps>,
) => {
  const classes = useStyles()
  const { active, onClick, children } = props
  return (
    <ButtonBase
      className={clsx(
        classes.toolbarButton,
        active && 'active',
        !IsNullUndefinedOrEmpty(props.classes) &&
          props.classes?.includes('primary') &&
          classes.primary,
      )}
      onClick={onClick}
    >
      {children}
    </ButtonBase>
  )
}
