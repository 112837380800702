import { Box, ButtonBase, Popover, SvgIcon, Theme, Typography, useEventCallback } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx'
import { ICalendarGridCellProps } from 'components/calendar/props/calendar-grid-cell-props'
import { ImageIcon } from 'components/icons'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { useEventsContext } from 'modules/events/context'
import moment from 'moment-timezone'
import React from 'react'
import { EventStatus } from 'types/modules/events/enums/event-status'
import { IEvent } from 'types/modules/events/models/entities/event'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    eventGridCellContainer: {
      display: 'flex',
      flex: 1,
      '&:hover': {
        background: 'rgba(0,0,0,0.03)',
        cursor: 'pointer',
      },
    },
    event: {
      display: 'flex',
      flex: 1,
      boxSizing: 'border-box',
      flexDirection: 'column',
      height: 79,
      background: '#ff787d',
      border: '1px solid #ff787d',
      '&.multiple': {
        background: '#02599D',
        border: '1px solid #02599D',
      },
    },
    eventHeader: {
      display: 'flex',
      height: 15,
      width: '100%',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingLeft: '0.3rem',
      paddingRight: '0.3rem',
    },
    headerText: {
      fontSize: '0.7rem',
      color: theme.palette.primary.contrastText,
      '&.textPrimary': {
        color: theme.palette.text.primary,
      },
    },
    eventContent: {
      display: 'flex',
      flex: 1,
      width: '100%',
      height: 'calc(100% - 15px)',
      background: 'rgba(255,255,255,0.60)',
      padding: '0.3rem',
      '&.darker': {
        background: 'rgba(255,255,255,0.30)',
      },
    },
    coverImageContainer: {
      display: 'flex',
      flex: 1,
      borderRadius: 4,
      background: '#f7f7f7',
      color: 'rgba(0,0,0,0.54)',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      justifyContent: 'center',
      alignItems: 'center',
    },
    coverImage: {
      display: 'flex',
      width: '100%',
      height: '100%',
      color: 'rgba(0,0,0,0.54)',
      justifyContent: 'center',
      alignItems: 'center',
    },
    eventDetailsContainer: {
      display: 'flex',
      flex: 2,
    },
    eventTitle: {
      fontSize: '0.7rem',
      color: theme.palette.text.primary,
      overflow: 'hidden',
      textAlign: 'left',
    },
    multieventPopover: {
      display: 'flex',
      flexDirection: 'column',
      width: 230,
    },
    multieventCount: {
      color: theme.palette.primary.contrastText,
      textAlign: 'center',
      margin: 'auto',
    },
  }),
)

export const EventsCalendarTimeGridCell: React.FC<
  ICalendarGridCellProps<IEvent>
> = (props: ICalendarGridCellProps<IEvent>) => {
  const { items, dateTime, sortBy } = props

  const { setCurrentEvent } = useEventsContext()

  const classes = useStyles()

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handleMultiEventPopoverClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleMultiEventPopoverClose = (): void => {
    setAnchorEl(null)
  }

  const renderEvent = (event: IEvent): JSX.Element => {
    const hasCoverImage = !IsNullOrUndefined(event.media?.imageUrl)
    const eventCoverContainerStyles: { [key: string]: string | number } = {}

    if (hasCoverImage) {
      eventCoverContainerStyles.backgroundImage = `url(${event.media!
        .imageUrl!})`
    } else {
      eventCoverContainerStyles.background = '#f7f7f7'
    }

    const eventStatus =
      event.status === EventStatus.Created ? 'Draft' : event.status

    return (
      <ButtonBase
        key={event.id}
        className={clsx(classes.event)}
        onClick={(): void => {
          handleMultiEventPopoverClose()
          setCurrentEvent(event.id)
        }}
      >
        <Box className={classes.eventHeader}>
          <Typography
            component="span"
            variant="button"
            className={clsx(classes.headerText)}
          >
            {moment((event as { [key: string]: any })[sortBy]).format('h:mm A')}
          </Typography>
          <Typography
            component="span"
            variant="button"
            className={clsx(classes.headerText)}
          >
            {eventStatus}
          </Typography>
        </Box>
        <Box className={classes.eventContent}>
          <Box
            className={classes.coverImageContainer}
            style={eventCoverContainerStyles}
          >
            {!hasCoverImage && (
              <SvgIcon>
                <ImageIcon />
              </SvgIcon>
            )}
          </Box>
          <Box className={classes.eventDetailsContainer}>
            <Typography
              component="span"
              variant="body2"
              className={clsx(classes.eventTitle)}
            >
              {event.name}
            </Typography>
          </Box>
        </Box>
      </ButtonBase>
    )
  }

  const renderMultipleEvents = (): JSX.Element => {
    const open = Boolean(anchorEl)
    // eslint-disable-next-line id-blacklist
    const id = open ? `multiple-events-${dateTime}` : undefined

    return (
      <React.Fragment>
        <ButtonBase
          aria-describedby={id}
          className={clsx(classes.event, 'multiple')}
          onClick={handleMultiEventPopoverClick}
        >
          <Box className={classes.eventHeader}>
            <Typography
              component="span"
              variant="button"
              className={clsx(classes.headerText)}
            >
              {moment(dateTime).format('h:mm A')}
            </Typography>
            <Typography
              component="span"
              variant="button"
              className={clsx(classes.headerText)}
            >
              {`.`}
            </Typography>
          </Box>
          <Box className={clsx(classes.eventContent, 'darker')}>
            <Typography
              component="span"
              variant="body2"
              className={clsx(classes.multieventCount)}
            >
              {`${items.length} Events`}
            </Typography>
          </Box>
        </ButtonBase>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleMultiEventPopoverClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Box className={classes.multieventPopover}>
            {items.map((item) => renderEvent(item))}
          </Box>
        </Popover>
      </React.Fragment>
    )
  }

  const renderEmptyView = (): JSX.Element | null => {
    return null
  }

  const renderViewSelector = (): JSX.Element | null => {
    if (IsNullOrUndefined(items) || items.length === 0) {
      return renderEmptyView()
    }

    switch (items.length) {
      case 1:
        return renderEvent(items[0])
      default:
        return renderMultipleEvents()
    }
  }

  return (
    <Box className={classes.eventGridCellContainer}>{renderViewSelector()}</Box>
  )
}
