import { MODULE_NAME } from 'modules/milestones/constants'

const ActionTypes = {
  SET_CURRENT_MILESTONE_ID: `${MODULE_NAME}/SET_CURRENT_MILESTONE_ID`,
  GET_MILESTONES: `${MODULE_NAME}/GET_MILESTONES`,
  GET_MILESTONE_BY_ID: `${MODULE_NAME}/GET_MILESTONE_BY_ID`,
  UPDATE_MILESTONE: `${MODULE_NAME}/UPDATE_MILESTONE`,
}

export default ActionTypes
