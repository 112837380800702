import { MODULE_NAME } from '../constants'

const ACTION_TYPES = {
  GET_MEDIA_ITEM_BY_ID: `${MODULE_NAME}/GET_MEDIA_ITEM_BY_ID`,
  UPLOAD_MEDIA_ITEM_IMAGE: `${MODULE_NAME}/UPLOAD_MEDIA_ITEM_IMAGE`,
  UPDATE_MEDIA_ITEM_IMAGE: `${MODULE_NAME}/UPDATE_MEDIA_ITEM_IMAGE`,
  UPDATE_MEDIA_ITEM_TRANSFORM_DATA: `${MODULE_NAME}/UPDATE_MEDIA_ITEM_TRANSFORM_DATA`,

  CREATE_VIDEO_PRESIGNED_URL: `${MODULE_NAME}/CREATE_VIDEO_PRESIGNED_URL`
}

export default ACTION_TYPES
