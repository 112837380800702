import { Button, ButtonProps, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx'
import { ConfirmationDialog } from 'components/confirmation-dialog'
import { CloseIcon } from 'components/icons'
import { useAuth } from 'core/authentication/hooks/use-auth'
import { PortalPermissions } from 'core/authorization/rules'
import { useEventsContext } from 'modules/events/context'
import React from 'react'
import { EventStatus } from 'types/modules/events/enums/event-status'
import { IEventActionButtonProps } from 'types/modules/events/models/props/event-action-button-props'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.text.primary,
      backgroundColor: '#ecf0f1',
      '&:hover': {
        backgroundColor: '#f2f5f5',
      },
    },
  }),
)

export const CloseEventButton: React.FC<IEventActionButtonProps> = (
  props: IEventActionButtonProps,
) => {
  const { currentUser } = useAuth()
  const { closeEvent } = useEventsContext()

  const classes = useStyles()

  const isActive = props.event.status === EventStatus.Active

  const buttonProps: ButtonProps = {
    variant: 'contained',
    startIcon: <CloseIcon />,
    className: clsx(classes.root),
    size: 'small',
    disabled: props.disabled,
  }

  const popoverButtonProps = {
    ...buttonProps,
    disabled: props.disabled,
  }

  const buttonLabel = 'close'

  return isActive && currentUser.can(PortalPermissions.EVENTS_CLOSE) ? (
    <ConfirmationDialog
      popoverId={`${buttonLabel}-event-${props.event.id}`}
      buttonProps={popoverButtonProps}
      onConfirmation={(): void => closeEvent(props.event.id)}
      confirmationText={`Are you sure you want to ${buttonLabel} this event?`}
      buttonLabel={buttonLabel}
    />
  ) : null
}
