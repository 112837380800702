/* Libs */
import {
  all,
  AllEffect,
  ForkEffect,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects'

/* Module */
import { ActionTypes } from '..'
import { GetPostGroupsSaga } from './get-post-groups-saga'

type RootSagaReturnType = Generator<
  ForkEffect<never> | AllEffect<any>,
  void,
  any
>

export const RootSaga = function* (): RootSagaReturnType {
  yield all([
    yield takeEvery(ActionTypes.GET_POST_GROUPS, GetPostGroupsSaga),
    yield takeEvery(ActionTypes.GET_POST_GROUP_BY_ID, GetPostGroupsSaga),
    yield takeEvery(ActionTypes.CREATE_POST_GROUP, GetPostGroupsSaga),
    yield takeEvery(ActionTypes.UPDATE_POST_GROUP, GetPostGroupsSaga),
    yield takeEvery(ActionTypes.SETTLE_POST_GROUP, GetPostGroupsSaga),
    yield takeEvery(ActionTypes.DELETE_POST_GROUP_BY_ID, GetPostGroupsSaga),
    yield takeEvery(ActionTypes.GET_POST_GROUP_POSTS, GetPostGroupsSaga),
  ])
}
