import React, { useEffect } from 'react'
import { PostGroupType } from 'fe-shared-resources'
import { usePostListContext } from 'modules/posts/context/post-list'
import { Box, Chip, CircularProgress, Typography } from '@mui/material'
import { useTypedSelector } from 'core/redux/utils'
import { getPostGroupPosts } from 'modules/post-groups/selectors'
import { SimpleImageDisplay } from 'components/simple-image-display'
import { createStyles, makeStyles } from '@mui/styles'
import { renderDates } from 'utils/date-range'
import { Actions } from 'modules/post-groups'
import { useDispatch } from 'react-redux'
import { CreatePostDialogButton } from 'modules/posts/views/components/create-post-dialog-button'
import StatusSelect from 'modules/post-groups/view/components/status-select'
import { usePostGroupsContext } from 'modules/post-groups/context'
import { useStatusFilter } from 'hooks/use-status-filter'
import { IPost } from 'types/modules/posts/models/entities/post'
import PostType from 'types/modules/posts/enums/post-type'

const useStyles = makeStyles(() =>
  createStyles({
    postGroupContent: {
      padding: '0.625rem',
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
    },
    postGroupDetails: {
      display: 'flex',
      flexGrow: 1,
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    headerContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      paddingRight: 16
    },
    chip: {
      marginRight: 8
    }
  }),
)

const allowedPostTypes = {
  [PostGroupType.DailyStack]: [PostType.Bet]
}


export const CustomPostList = () => {
  const classes = useStyles()
  const { setCurrentPost } = usePostListContext()
  const { fetchingPosts, currentPostGroup } = usePostGroupsContext()
  const dispatch = useDispatch()
  const posts = useTypedSelector((state) =>
    getPostGroupPosts(state),
  )

  useEffect(() => {
    return () => {
      dispatch(Actions.clearPostGroupPost({}))
    }
  }, [])

  const {
    filtered,
    statusOptions,
    onSelect,
    status,
    countMap
  } = useStatusFilter<IPost>({ items: posts as IPost[] })

  return (<Box>
    <Box className={classes.headerContainer}>
      <Box>
        <Typography variant="h5" color="textPrimary" component="span">
          Posts
        </Typography>
      </Box>
      <Box display="flex">
        <StatusSelect
          options={statusOptions}
          value={status}
          onSelect={onSelect}
          countMap={countMap}
        />
        <CreatePostDialogButton
          buttonProps={{
            variant: 'contained',
            size: 'small',
          }}
          // @ts-expect-error there wont be errors
          postTypes={currentPostGroup?.type ? allowedPostTypes[currentPostGroup?.type] : undefined}
        />
      </Box>
    </Box>

    {
      fetchingPosts
        ? <CircularProgress />
        : <>
          {filtered.length === 0 && <Box>
            <Typography variant="subtitle1" color="textPrimary" component="span">
              The are no posts in this group
            </Typography>
          </Box>}
        </>
    }
    {
      filtered.map((item) => <Box
        key={item.id}
        onClick={() => setCurrentPost(item.id!, item.type!)}
        className={classes.postGroupContent}
      >
        <SimpleImageDisplay imageUrl={item.media?.imageUrl} width={60} height={60}/>
        <Box className={classes.postGroupDetails}>
          <Typography variant="subtitle1" color="textPrimary" component="span">
            {item.title}
          </Typography>
          <Typography variant="body2" color="textPrimary" component="span">
            {renderDates(item.start, item.close)}
          </Typography>
          <Box>
            <Chip label={`Type: ${item.type}`} size="small" className={classes.chip} />
            <Chip label={`Status: ${item.status}`} size="small" className={classes.chip} />
          </Box>
        </Box>
      </Box>)
    }
  </Box>)
}