import { RequestMethod } from 'types/common/api/enums/request-method'
import paths from './paths'
import params from './params'
import filters from './filters'

const CampaignEndpoints = {
  getCampaigns: {
    method: RequestMethod.GET,
    path: [paths.campaigns],
    filters,
  },
  getCampaignById: {
    method: RequestMethod.GET,
    path: [paths.campaigns, params.campaignId],
  },
  createCampaign: {
    method: RequestMethod.POST,
    path: [paths.campaigns],
  },
  updateCampaign: {
    method: RequestMethod.PUT,
    path: [paths.campaigns, params.campaignId],
  },
  deleteCampaignById: {
    method: RequestMethod.DELETE,
    path: [paths.campaigns, params.campaignId],
  },

  uploadCampaignImage: {
    method: RequestMethod.PUT,
    path: [paths.campaigns, params.campaignId, paths.images, paths.upload],
  },
}

export default CampaignEndpoints
