import { EndpointRoute } from 'types/common/api/models/entities/endpoint-route'
import { RequestMethod } from 'types/common/api/enums/request-method'
import paths from './paths'
import params from './params'
import filters from './filters'

const TagEndpoints: { [key: string]: EndpointRoute } = {
  getTags: {
    method: RequestMethod.GET,
    path: [paths.tags],
    filters,
  },
  getTagById: {
    method: RequestMethod.GET,
    path: [paths.tags, params.tagId],
  },
  createTag: {
    method: RequestMethod.POST,
    path: [paths.tags],
  },
  updateTag: {
    method: RequestMethod.PUT,
    path: [paths.tags, params.tagId],
  },
  initialAllPostTags: {
    method: RequestMethod.PUT,
    path: [paths.tags, paths.initialPostTags],
  },
  deleteTagById: {
    method: RequestMethod.DELETE,
    path: [paths.tags, params.tagId],
  },
  getTagPromotion: {
    method: RequestMethod.GET,
    path: [paths.trendingTags],
  },
  updateTagPromotion: {
    method: RequestMethod.PUT,
    path: [paths.trendingTags],
  },
  getTagSuggestion: {
    method: RequestMethod.GET,
    path: [paths.suggestedTags],
  },
  updateTagSuggestion: {
    method: RequestMethod.PUT,
    path: [paths.suggestedTags],
  },
  uploadTagImage: {
    method: RequestMethod.PUT,
    path: [paths.tags, params.tagId, paths.images, paths.upload],
  },
}

export default TagEndpoints
