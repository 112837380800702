/* eslint-disable no-invalid-this */
import { QuestField, QuestMediaField, QuestRewardField, QuestRewardTypes, QuestTaskField } from 'modules/quests/enums/quest-field'
import * as yup from 'yup'

export const QuestSchema = yup.object({
  [QuestField.SCHEDULE]: yup
    .string()
    .required('required'),
  [QuestField.SET]: yup
    .number()
    .required('required'),
  [QuestField.MEDIA]: yup
    .object()
    .shape({
      [QuestMediaField.DESCRIPTION]: yup
        .string()
        .required('required'),
      [QuestMediaField.TITLE]: yup
        .string()
        .required('required'),
    }),
  [QuestField.QUEST_REWARDS]: yup.array().of(
    yup.object().shape({
      [QuestRewardField.TYPE]: yup
        .string()
        .required('required'),
      [QuestRewardField.AMOUNT]: yup
        .number()
        .test({
          message: 'required',
          test: function (value) {
            if (this.parent.type !== QuestRewardTypes.CURRENCY) return true
            return !!value
          },
        }),
      [QuestRewardField.PRIZE_ID]: yup
        .string()
        .test({
          message: 'required',
          test: function (value) {
            if (this.parent.type !== QuestRewardTypes.PRIZE) return true
            return !!value
          },
        }),
      [QuestRewardField.CURRENCY]: yup
        .string()
        .test({
          message: 'required',
          test: function (value) {
            if (this.parent.type !== QuestRewardTypes.CURRENCY) return true
            return !!value
          },
        }),
    }),
  ),
  [QuestField.QUEST_TASKS]: yup.array().of(
    yup.object().shape({
      [QuestTaskField.QUANTITY]: yup.number().required('required'),
      [QuestTaskField.CODE]: yup.string().required('required'),
    })
  )
})
