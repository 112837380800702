import { Box, IconButton, SvgIcon, Theme, useMediaQuery } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { ArrowLeftIcon, ArrowRightIcon } from 'components/icons'
import { usePostQuestionsManager } from 'modules/posts/context/post-questions-manager'
import { ManagePostView } from 'modules/posts/views/components/manage-post-panel/types/manage-post-view'
import { IPostNavigationConfigItem } from 'modules/posts/views/components/manage-post-panel/types/post-navigation-config-item'
import { WatchlistQuestionPreview } from 'modules/watchlist/views/components/post-modal/question-preview'
import React, { useEffect, useState } from 'react'
import { IPost } from 'types/modules/posts/models/entities/post'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    postPreviewContainer: {
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        flex: 1,
        width: '100%',
        height: '100%',
      },
    },
    previousQuestionButton: {
      background: 'white',
      width: 40,
      height: 40,
      marginRight: '1.25rem',
      '&:hover': {
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      },
    },
    nextQuestionButton: {
      background: 'white',
      width: 40,
      height: 40,
      marginLeft: '1.25rem',
      '&:hover': {
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      },
    },
  }),
)

interface IWatchlistPostPreviewProps {
  post: IPost
}

export const WatchlistPostPreview: React.FC<IWatchlistPostPreviewProps> = (
  props: IWatchlistPostPreviewProps,
) => {
  const classes = useStyles()

  const { post } = props

  const { currentQuestion, setCurrentQuestion } = usePostQuestionsManager()

  const currentQuestionIndex = post.questions.findIndex(
    (question) => question.id === currentQuestion?.id,
  )

  const currentPage = currentQuestionIndex + 1

  const buildNavigationConfig = (): IPostNavigationConfigItem[] => {
    const config: IPostNavigationConfigItem[] = []

    post.questions.forEach((question, index) => {
      config.push({
        label: `Question ${index + 1}`,
        view: ManagePostView.Question,
        params: {
          postId: question.postId,
          questionId: question.id,
        },
      })
    })

    return config
  }

  const [navigationConfig, setNavigationConfig] = useState<
    IPostNavigationConfigItem[]
  >(buildNavigationConfig())

  useEffect(() => {
    setNavigationConfig(buildNavigationConfig())
  }, [post, post.questions])

  const nextPage = (): void => {
    setCurrentQuestion(post.questions[currentQuestionIndex + 1])
  }

  const previousPage = (): void => {
    setCurrentQuestion(post.questions[currentQuestionIndex - 1])
  }

  const isMobile = useMediaQuery('(max-width: 600px)')

  return (
    <Box className={classes.postPreviewContainer}>
      {!isMobile && (
        <IconButton
          onClick={previousPage}
          className={classes.previousQuestionButton}
          disabled={currentPage === 1}
          size="large">
          <SvgIcon>
            <ArrowLeftIcon />
          </SvgIcon>
        </IconButton>
      )}
      {currentQuestion && (
        <WatchlistQuestionPreview
          question={currentQuestion!}
          post={post}
          isMobile={isMobile}
          currentPage={currentPage}
          nextPage={nextPage}
          previousPage={previousPage}
          navigationConfig={navigationConfig}
        />
      )}
      {!isMobile && (
        <IconButton
          onClick={nextPage}
          className={classes.nextQuestionButton}
          disabled={currentPage === navigationConfig.length}
          size="large">
          <SvgIcon>
            <ArrowRightIcon />
          </SvgIcon>
        </IconButton>
      )}
    </Box>
  );
}
