import { useTypedSelector } from 'core/redux/utils'
import { useRouter } from 'core/routing/hooks/use-router'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { EvergreenQuestActions, Selectors } from 'modules/quests'
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useDispatch } from 'react-redux'
import { NIL as NIL_UUID } from 'uuid'
import { IEvergreenQuest, IEvergreenQuestLevel } from 'modules/quests/models/evergreen-quest'

export interface IEvergreenQuestsContext {
  currentEvergreenQuest: IEvergreenQuest | null
  evergreenQuestLevels: IEvergreenQuestLevel[]
  sortedLevelsIds: string[]
  navigationDisabled: boolean
  selectedLevel?: string
  requesting: boolean
  setCurrentEvergreenQuest: (evergreenQuestId: string) => void
  clearCurrentEvergreenQuest: () => void
  createEvergreenQuest: (
    evergreenQuest: IEvergreenQuest,
    callbackFn?: (quest: IEvergreenQuest) => void,
  ) => void
  createEvergreenQuestDraft: (evergreenQuest?: IEvergreenQuest) => void
  updateEvergreenQuest: (evergreenQuest: IEvergreenQuest) => void
  createEvergreenQuestLevel: (
    evergreenQuestLevel: IEvergreenQuestLevel,
    callbackFn?: (quest: IEvergreenQuestLevel) => void,
  ) => void
  updateEvergreenQuestLevel: (evergreenQuestLevel: IEvergreenQuestLevel) => void,
  setSelectedLevel: (level?: string) => void
  setNavigationDisabled: (disabled: boolean) => void
  uploadEvergreenQuestImage: (evergreenQuestLevelId: string, imageUrl?: string) => void
}

const EvergreenQuestsContext = createContext<IEvergreenQuestsContext>(
  {} as IEvergreenQuestsContext,
)

export const EvergreenQuestsProvider: React.FC<PropsWithChildren<{}>> = (
  props: PropsWithChildren<{}>,
) => {
  const dispatch = useDispatch()

  const {
    routeParams: { evergreenQuestId },
    pushQueryParams,
    removeQueryParams,
  } = useRouter()

  const [requesting, setRequesting] = useState(false)
  const [navigationDisabled, setNavigationDisabled] = useState(false)
  const [selectedLevel, setSelectedLevel] = useState<string | undefined>()

  const currentEvergreenQuest = useTypedSelector((state) =>
    Selectors.getCurrentEvergreenQuest(state),
  )

  const setCurrentEvergreenQuest = (evergreenQuestId: string): void => {
    pushQueryParams({
      evergreenQuestId,
    })
  }

  const clearCurrentEvergreenQuest = (): void => {
    removeQueryParams('evergreenQuestId')
  }

  const createEvergreenQuestDraft = (): void => {
    setCurrentEvergreenQuest(NIL_UUID)
  }

  const createEvergreenQuest = (evergreenQuest: IEvergreenQuest): void => {
    setRequesting(true)
    dispatch(
      EvergreenQuestActions.createEvergreenQuest({
        item: evergreenQuest,
        promise: {
          onResolve: (data: IEvergreenQuest): void => {
            setCurrentEvergreenQuest(data.id!)
            setRequesting(false)
          },
          onReject: (): void => setRequesting(false),
        },
      }),
    )
  }

  const updateEvergreenQuest = (evergreenQuest: IEvergreenQuest): void => {
    setRequesting(true)
    dispatch(
      EvergreenQuestActions.updateEvergreenQuest({
        item: evergreenQuest,
        evergreenQuestId: evergreenQuest.id,
        promise: {
          onResolve: (): void => setRequesting(false),
          onReject: (): void => setRequesting(false),
        }
      }),
    )
  }

  const createEvergreenQuestLevel = (evergreenQuestLevel: IEvergreenQuestLevel): void => {
    setRequesting(true)
    dispatch(
      EvergreenQuestActions.createEvergreenQuestLevel({
        item: evergreenQuestLevel,
        evergreenQuestId: currentEvergreenQuest?.id,
        promise: {
          onResolve: (data: IEvergreenQuest): void => {
            setCurrentEvergreenQuest(data.id!)
            setRequesting(false)
            if (data.levels) {
              const level = data.levels.find(level => level.level === evergreenQuestLevel.level)
              setSelectedLevel(level?.id)
            }
          },
          onReject: (): void => setRequesting(false),
        },

      }),
    )
  }

  const updateEvergreenQuestLevel = (evergreenQuestLevel: IEvergreenQuestLevel): void => {
    setRequesting(true)
    dispatch(
      EvergreenQuestActions.updateEvergreenQuestLevel({
        item: evergreenQuestLevel,
        evergreenQuestId: currentEvergreenQuest?.id,
        evergreenQuestLevelId: evergreenQuestLevel?.id,
        promise: {
          onResolve: (): void => setRequesting(false),
          onReject: (): void => setRequesting(false),
        }
      }),
    )
  }

  const uploadEvergreenQuestImage = (
    evergreenQuestLevelId: string,
    imageUrl?: string,
  ): void => {
    setRequesting(true)
    dispatch(
      EvergreenQuestActions.uploadEvergreenQuestImage({
        evergreenQuestId: currentEvergreenQuest?.id,
        evergreenQuestLevelId,
        item: `"${imageUrl}"`,
        overrideHeaders: {
          accept: '*/*',
          'content-type': 'application/json'
        },
        promise: {
          onResolve: (): void => setRequesting(false),
          onReject: (): void => setRequesting(false),
        }
      }),
    )
  }

  useEffect(() => {
    dispatch(
      EvergreenQuestActions.setCurrentEvergreenQuestId({ evergreenQuestId }),
    )
    if (!IsNullOrUndefined(evergreenQuestId) && evergreenQuestId !== NIL_UUID) {
      dispatch(
        EvergreenQuestActions.getEvergreenQuestById({ evergreenQuestId }),
      )
    }
  }, [evergreenQuestId])

  const evergreenQuestLevels = currentEvergreenQuest?.levels || []
  const sortedLevelsIds: string[] = useMemo(() =>
    evergreenQuestLevels.sort((a, b) => a.level - b.level).map((questLevel) => questLevel.id || '')
  , [evergreenQuestLevels.length])

  const context = {
    currentEvergreenQuest,
    setCurrentEvergreenQuest,
    clearCurrentEvergreenQuest,
    createEvergreenQuest,
    createEvergreenQuestDraft,
    updateEvergreenQuest,
    createEvergreenQuestLevel,
    updateEvergreenQuestLevel,
    setNavigationDisabled: (disabled: boolean): void => setNavigationDisabled(disabled),
    setSelectedLevel: (level?: string): void => setSelectedLevel(level),
    uploadEvergreenQuestImage,
    evergreenQuestLevels,
    sortedLevelsIds,
    navigationDisabled,
    selectedLevel,
    requesting
  }

  return (
    <EvergreenQuestsContext.Provider value={context}>
      {props.children}
    </EvergreenQuestsContext.Provider>
  )
}

export const useEvergreenQuestsContext = (): IEvergreenQuestsContext =>
  useContext(EvergreenQuestsContext)
