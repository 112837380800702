import { useTypedSelector } from 'core/redux/utils'
import { useRouter } from 'core/routing/hooks/use-router'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import {
  EditableMobileAppUser,
  MobileAppUser,
} from 'modules/mobile-app-users/models/entities/mobile-app-user'
import { Actions, Selectors } from 'modules/mobile-app-users'
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useDispatch } from 'react-redux'
import { NIL as NIL_UUID } from 'uuid'
import { Currency } from 'fe-shared-resources'

interface IAccountSwitchParams {
  oldAccountId: string,
  newAccountId: string,
  callback: () => void
}

export interface IMobileAppUsersContext {
  currentMobileAppUser: MobileAppUser | null
  setCurrentMobileAppUser: (mobileAppUserId: string) => void
  clearCurrentMobileAppUser: () => void
  updateMobileAppUser: (value: EditableMobileAppUser) => void
  disableMobileAppUser: (mobileAppUserId?: string) => void
  updateMobileAppUserCurrency: (
    accountId: string,
    amount: number,
    currencyAmountKey: Currency,
  ) => void
  selectedTicketId?: string
  selectTicket: (ticketId: string) => void
  switchAccount: (val: IAccountSwitchParams) => void
  processing: boolean
}

const MobileAppUsersContext = createContext<IMobileAppUsersContext>(
  {} as IMobileAppUsersContext,
)

export const MobileAppUsersProvider: React.FC<PropsWithChildren<{}>> = (
  props: PropsWithChildren<{}>,
) => {
  const dispatch = useDispatch()

  const { routeParams, pushQueryParams, removeQueryParams } = useRouter()

  const [selectedTicketId, setSelectedTicketId] = useState<string | undefined>()

  const { mobileAppUserId } = routeParams

  const [processing, setProcessing] = useState(false)

  const currentMobileAppUser = useTypedSelector((state) =>
    Selectors.getCurrentMobileAppUser(state),
  )

  const switchAccount = (accountDetails: IAccountSwitchParams): void => {
    setProcessing(true)
    dispatch(
      Actions.switchUsers({
        queryParams: accountDetails,
        promise: {
          onResolve: (): void => {
            dispatch(Actions.getMobileAppUser({ mobileAppUserId: accountDetails.oldAccountId }))
            dispatch(Actions.getMobileAppUser({ mobileAppUserId: accountDetails.newAccountId }))
            setProcessing(false)
            accountDetails.callback()
          },
          onReject: (): void => {
            setProcessing(false)
          }
        }
      }),
    )
  }

  const setCurrentMobileAppUser = (id: string): void => {
    pushQueryParams({
      mobileAppUserId: id,
    })
  }

  const clearCurrentMobileAppUser = (): void => {
    removeQueryParams('mobileAppUserId')
    setSelectedTicketId(undefined)
  }

  const updateMobileAppUser = (value: EditableMobileAppUser): void => {
    dispatch(
      Actions.updateMobileAppUsers({
        mobileAppUserId: value.id,
        item: value,
      }),
    )
  }

  const disableMobileAppUser = (mobileAppUserId?: string): void => {
    dispatch(
      Actions.disableMobileAppUser({
        mobileAppUserId,
        item: { id: mobileAppUserId },
      }),
    )
  }

  const updateMobileAppUserCurrency = async (
    accountId: string,
    amount: number,
    currency: Currency,
  ): Promise<void> => {
    dispatch(
      await Actions.updateMobileAppUserCurrency({
        mobileAppUserId: currentMobileAppUser?.id,
        accountId,
        item: { amount, currency },
      }),
    )
  }

  useEffect(() => {
    dispatch(Actions.setCurrentMobileAppUserId({ mobileAppUserId }))

    if (!IsNullOrUndefined(mobileAppUserId) && mobileAppUserId !== NIL_UUID) {
      dispatch(Actions.getMobileAppUser({ mobileAppUserId }))
    }
  }, [mobileAppUserId])

  const context = {
    currentMobileAppUser,
    setCurrentMobileAppUser,
    clearCurrentMobileAppUser,
    updateMobileAppUser,
    updateMobileAppUserCurrency,
    disableMobileAppUser,
    selectedTicketId,
    selectTicket: (ticketId: string): void => {
      setSelectedTicketId(ticketId)
    },
    switchAccount,
    processing
  }

  return (
    <MobileAppUsersContext.Provider value={context}>
      {props.children}
    </MobileAppUsersContext.Provider>
  )
}

export const useMobileAppUsersContext = (): IMobileAppUsersContext =>
  useContext(MobileAppUsersContext)
