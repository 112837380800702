import { Box, Button, Grid } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import { makeStyles } from '@mui/styles'
import { PanelContent, PanelFooter } from 'components/panel'
import { useFormik } from 'formik'
import { useEvergreenQuestsContext } from 'modules/quests/context/evergreen-quest-context'
import { IEvergreenQuestLevel } from 'modules/quests/models/evergreen-quest'
import { EvergreenQuestLevelSchema } from 'modules/quests/validation/evergreen-quest-level-schema'
import { EvergreenQuestLevelForm } from 'modules/quests/view/evergreen/evergreen-quest-modal/evergreen-quest-level-form'
import React, { useEffect, useRef } from 'react'
import { Currency } from 'fe-shared-resources'

interface IEvergreenQuestLevelProps {
  questLevelId?: string,
  onCancel: () => void
}

const useStyles = makeStyles(() => createStyles({
    footerContent: {
      display: 'flex',
      flex: 1,
      justifyContent: 'space-between',
    },
    cancelBtn: {
      marginRight: '1.25rem',
    },
}))

export const EvergreenQuestLevel = ({ questLevelId, onCancel }:  IEvergreenQuestLevelProps): JSX.Element => {
  const classes = useStyles()
  const {
    evergreenQuestLevels,
    createEvergreenQuestLevel,
    updateEvergreenQuestLevel,
    setNavigationDisabled,
    navigationDisabled,
    selectedLevel,
    requesting
  } = useEvergreenQuestsContext()
  const initialRequestingValue = useRef(requesting)
  const questLevel = evergreenQuestLevels.find(level => level.id === questLevelId)

  const form = useFormik({
    enableReinitialize: !!questLevelId,
    validationSchema: EvergreenQuestLevelSchema,
    initialValues: {
      id: questLevel?.id ?? '',
      description: questLevel?.description || '',
      rewardCurrency: questLevel?.rewardCurrency ?? Currency.Coin,
      level: questLevel?.level || evergreenQuestLevels.length + 1 ,
      rewardAmount: questLevel?.rewardAmount || 1,
      requiredQuantity: questLevel?.requiredQuantity || 0,
    },
    onSubmit: (value: IEvergreenQuestLevel) => {
      const { id, ...rest } = value
      if (questLevel) {
        updateEvergreenQuestLevel(value)
      } else {
        createEvergreenQuestLevel(rest)
      }
    },
  })

  useEffect(() => {
    setNavigationDisabled(form.dirty && selectedLevel !== 'new')
  }, [form.dirty, selectedLevel])

  useEffect(() => {
    if (requesting === false && initialRequestingValue.current) {
      form.resetForm({ values: form.values })
    }
    initialRequestingValue.current = requesting
  }, [requesting])

  return <>
    <PanelContent>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <EvergreenQuestLevelForm
            evergreenQuestLevel={form.values}
            handleChange={form.handleChange}
            setFieldTouched={form.setFieldTouched}
            setFieldValue={form.setFieldValue}
            errors={form.errors}
          />
        </Grid>
      </Grid>
    </PanelContent>
    <PanelFooter>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box className={classes.footerContent}>
            <Button
              size="large"
              className={classes.cancelBtn}
              onClick={(): void => form.resetForm()}
              variant="contained"
              disabled={requesting}
            >
              Reset
            </Button>
            <Box>
              <Button
                size="large"
                className={classes.cancelBtn}
                onClick={onCancel}
                variant="contained"
                disabled={navigationDisabled || requesting}
                color="secondary"
              >
                Cancel
              </Button>
              <Button
                color="primary"
                size="large"
                onClick={form.submitForm}
                variant="contained"
                disabled={(form.dirty && !form.isValid) || requesting}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </PanelFooter>
  </>
}
