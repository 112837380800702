import { all, AllEffect, ForkEffect } from 'redux-saga/effects'

import { RootSaga as tagsRootSaga } from 'modules/tags'
import { RootSaga as postsRootSaga } from 'modules/posts'
import { RootSaga as eventsRootSaga } from 'modules/events/sagas'
import { RootSaga as watchlistRootSaga } from 'modules/watchlist'
import { RootSaga as liveSchedulesRootSaga } from 'modules/live-schedules'
import { RootSaga as alertsRootSaga } from 'core/alerts'
import { RootSaga as prizeRootSaga } from 'modules/prizes'
import { RootSaga as portalUsersSaga } from 'modules/users/portal/sagas'
import { RootSaga as leaderboardSaga } from 'modules/leaderboards'
import { RootSaga as commentsSaga } from 'modules/comments'
import { RootSaga as shippingSaga } from 'modules/shipping'
import { RootSaga as infoCardSaga } from 'modules/info-cards'
import { RootSaga as mediaItemsSaga } from 'modules/media-items'
import { RootSaga as chestsSagas } from 'modules/chests'
import { RootSaga as mobileAppUSersSagas } from 'modules/mobile-app-users'
import { RootSaga as questSagas } from 'modules/quests'
import { RootSaga as appSettingsSagas } from 'modules/app-settings'
import { RootSaga as newsCardsSagas } from 'modules/news-cards'
import { RootSaga as iapProductsSagas } from 'modules/iap-products'
import { RootSaga as postGroupsSagas } from 'modules/post-groups'
import { RootSaga as milestoneSagas } from 'modules/milestones'
import { RootSaga as campaignSagas } from 'modules/campaigns'

export type RootSagaReturnType = Generator<
  ForkEffect<never> | AllEffect<any>,
  void,
  any
>

export default function* (): Generator<
  AllEffect<RootSagaReturnType>,
  void,
  unknown
> {
  yield all([
    alertsRootSaga(),
    eventsRootSaga(),
    liveSchedulesRootSaga(),
    tagsRootSaga(),
    postsRootSaga(),
    prizeRootSaga(),
    watchlistRootSaga(),
    portalUsersSaga(),
    leaderboardSaga(),
    commentsSaga(),
    infoCardSaga(),
    shippingSaga(),
    mediaItemsSaga(),
    chestsSagas(),
    mobileAppUSersSagas(),
    questSagas(),
    appSettingsSagas(),
    newsCardsSagas(),
    iapProductsSagas(),
    postGroupsSagas(),
    milestoneSagas(),
    campaignSagas()
  ])
}
