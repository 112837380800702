import { IPrizeState } from 'types/modules/prizes/models/state/prize-state'
import { AppState } from 'types/redux/types/app-state'
import _ from 'core/utils/deepdash'
import { DEFAULT_PRIZE, DRAFTED_PRIZE, MODULE_NAME } from 'modules/prizes/constants'
import PrizeStatus from 'types/modules/prizes/enums/prize-status'
import {
  IsNullOrUndefined,
  IsNullUndefinedOrEmpty,
} from 'core/utils/isNullOrUndefined'
import { IPrize } from 'types/modules/prizes/models/entities/prize'
import { NIL as NIL_UUID } from 'uuid'
import { PrizeType } from 'fe-shared-resources'

const getState = (state: AppState): IPrizeState =>
  _.cloneDeep(state[MODULE_NAME])

export const getPrizes = (state: AppState): IPrizeState => getState(state)

export const getPrizesByStatus = (
  state: AppState,
  statuses: PrizeStatus[] | null,
): IPrizeState => {
  const prizes = getPrizes(state)

  if (!IsNullOrUndefined(statuses)) {
    prizes.items = prizes.items.filter((prize) =>
      statuses?.includes(prize.status),
    )
  }

  return prizes
}

export const getCurrentPrize = (
  state: AppState,
  prizeType?: PrizeType,
): Partial<IPrize> | null => {
  const prizes = getPrizes(state)

  if (IsNullOrUndefined(prizes.currentPrizeId)) return null

  if (prizes.currentPrizeId === NIL_UUID && prizeType) {
    return {
      id: NIL_UUID,
      // @ts-expect-error will write problem later
      ...(DRAFTED_PRIZE[prizeType] || { ...DEFAULT_PRIZE, type: prizeType }),
    }
  }

  const currentPrize = prizes.items.find(
    (prize) => prize.id === prizes.currentPrizeId,
  )

  return currentPrize ?? null
}

export const getPrizeById = (
  state: AppState,
  prizeId: string,
): IPrize | null => {
  return IsNullUndefinedOrEmpty(prizeId)
    ? getPrizes(state).items.find((prize) => prize.id === prizeId) ?? null
    : null
}

export const getStatuses = (state: AppState): IPrizeState['statuses'] =>
  getPrizes(state).statuses

export const getCachedPrizes = (state: AppState): IPrize[] =>
  Object.values(getPrizes(state).cache)

export const getCachedInstantPrizes = (state: AppState): IPrize[] =>
  getCachedPrizes(state).filter((prize) => prize.type === PrizeType.Instant)

export const getCachedPrizesOfType = (state: AppState, type: PrizeType): IPrize[] =>
  getCachedPrizes(state).filter((prize) => prize.type === type)

export const getCachedPrizesOfTypes = (state: AppState, types: PrizeType[]): IPrize[] => {
  const typesMap = types.reduce((acc, curr)=> ({ ...acc, [curr as PrizeType]: true }), {})
  // @ts-expect-error will write problem later
  return getCachedPrizes(state).filter((prize) => typesMap[prize.type])
}

export const getCachedPrizeById = (
  state: AppState,
  prizeId: string,
): IPrize | null => {
  const prizes = getCachedPrizes(state)
  return prizes.find((prize) => prize.id === prizeId) ?? null
}

export const getPrizesCache = (state: AppState): { [key: string]: IPrize } =>
  getPrizes(state).cache

export const getPrizeCacheChecker = (
  state: AppState,
): ((prizeId: string) => boolean) => {
  return (prizeId: string): boolean => !!getPrizes(state).cache[prizeId]
}

export const getPrizeGetter = (state: AppState): (prizeId: string) => IPrize => {
  return (prizeId: string): IPrize => getState(state).cache[prizeId]
}
