import {
  Box,
  Button,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { isInteger } from 'lodash-es'
import { currencyFieldNameMap } from 'modules/mobile-app-users/constants'
import { useMobileAppUsersContext } from 'modules/mobile-app-users/context'
import { MobileAppUserFieldName } from 'modules/mobile-app-users/enums/mobile-app-user-field'
import React, { useState } from 'react'
import { numberWithCommas } from 'utils/common'
import { Currency } from 'fe-shared-resources'

const useStyles = makeStyles(() =>
  createStyles({
    inputLabel: {
      display: 'flex',
      marginBottom: '0.625rem',
      marginTop: '0.625rem',
    },
    subtractButton: {
      marginLeft: 16,
    },
    wrapper: {
      paddingLeft: 12,
    },
  }),
)

const MenuProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  getContentAnchorEl: null,
}

const CURRENCY_OPTIONS = [
  {
    label: 'Coin',
    value: Currency.Coin,
  },
  {
    label: 'Star',
    value: Currency.Star,
  },
  {
    label: 'Uncut Diamond',
    value: Currency.UncutDiamond,
  },
  {
    label: 'Gold box',
    value: Currency.GoldBox,
  },
  {
    label: 'Brown box',
    value: Currency.BrownBox,
  },
  {
    label: 'XP',
    value: Currency.Xp,
  },
]

export const CurrencyForm: React.FC = () => {
  const classes = useStyles()
  const [value, setValue] = useState('0')
  const [currency, setCurrency] = useState(Currency.Coin)
  const [updating, setUpdating] = useState(false)
  const { updateMobileAppUserCurrency, currentMobileAppUser } =
    useMobileAppUsersContext()

  const update = async (amount: number): Promise<void> => {
    setUpdating(true)
    const accountId =
      // @ts-ignore
      currentMobileAppUser[currencyFieldNameMap[currency]]?.id || ''
    await updateMobileAppUserCurrency(accountId, amount, currency)
    setUpdating(false)
    setValue('0')
  }

  const handleClickSubtract = (): void => {
    const amount = parseFloat(value)
    update(-amount)
  }

  const handleClickAdd = (): void => {
    const amount = parseFloat(value)
    update(amount)
  }

  // @ts-ignore
  const balance = currentMobileAppUser[currencyFieldNameMap[currency]]?.balance
  const valueAsNumber = parseFloat(value)
  const disableSubtract =
    updating ||
    valueAsNumber > balance ||
    valueAsNumber <= 0 ||
    !isInteger(valueAsNumber)
  const disableAdd = updating || valueAsNumber <= 0 || !isInteger(valueAsNumber)

  return (
    <Box className={classes.wrapper}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <InputLabel htmlFor="amount" className={classes.inputLabel}>
            Currency
          </InputLabel>
          <Select
            value={currency}
            onChange={(event): void => {
              setCurrency(event.target.value as Currency)
            }}
            fullWidth
            variant="filled"
            // @ts-ignore
            MenuProps={MenuProps}
          >
            {CURRENCY_OPTIONS.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={6}>
          <InputLabel htmlFor="amount" className={classes.inputLabel}>
            Amount
          </InputLabel>
          <TextField
            value={value}
            name="amount"
            onChange={(event): void => {
              setValue(event.target.value)
            }}
            variant="filled"
            fullWidth
            helperText="Only integers allowed"
            type="number"
            InputProps={{
              inputProps: {
                step: 10,
              },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Box>
            <Button
              color="primary"
              onClick={handleClickAdd}
              variant="contained"
              disabled={disableAdd}
            >
              Add
            </Button>
            <Button
              color="secondary"
              onClick={handleClickSubtract}
              variant="contained"
              disabled={disableSubtract}
              className={classes.subtractButton}
            >
              Subtract
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          Current balance: {numberWithCommas(balance)}
        </Grid>
      </Grid>
    </Box>
  )
}
