import { NIL as NIL_UUID } from 'uuid'
import { Chest } from 'modules/chests/models/entities/chest'
import React, { PropsWithChildren, useContext, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { Actions, Selectors } from 'modules/chests'
import { Actions as PrizeActions } from 'modules/prizes'
import { useTypedSelector } from 'core/redux/utils'
import { MediaUploadModel } from 'types/common/images/models/entities/image-upload-model'
import { mapMimeType } from 'core/utils/mime-mapper'
import { usePrizeListContext } from 'modules/prizes/context/prize-list'
import { usePrizeManagerContext } from 'modules/prizes/context/prize-manager'

export interface IChestsContext {
  createChest: (chest: Chest, callbackFn?: (chest: Chest) => void) => void
  updateChest: (chest: Chest, callbackFn?: (chest: Chest) => void) => void
  removeChest: (callbackFn?: () => void) => void
  currentChest: Chest | null
  uploadChestImage: (eventId: string, fileParams: MediaUploadModel) => void
}

export const ChestsContext = React.createContext<IChestsContext>(
  {} as IChestsContext,
)

export const ChestsContextProvider: React.FC<React.ReactNode> = (
  props: PropsWithChildren<React.ReactNode>,
) => {
  const dispatch = useDispatch()
  const { currentPrize } = usePrizeListContext()
  const { updatePrize } = usePrizeManagerContext()
  const currentChest = useTypedSelector((state) =>
    Selectors.getCurrentChest(state),
  )

  const { chestId } = currentPrize || {}
  useEffect(() => {
    const fetchChest = async (): Promise<void> => {
      if (chestId === null) return
      dispatch(Actions.setCurrentChestId({ chestId }))
      if (!IsNullOrUndefined(chestId) && chestId !== NIL_UUID) {
        await dispatch(Actions.getChest({ chestId }))
      }
    }

    fetchChest()
  }, [chestId])

  useEffect(() => {
    if (IsNullOrUndefined(chestId) || chestId === NIL_UUID || !currentChest)
      return
    const chanceRewards = currentChest?.chestConfig?.chanceRewards || []
    chanceRewards.forEach((chanceReward) => {
      dispatch(PrizeActions.getPrizeById({ prizeId: chanceReward.prizeId }))
    })
  }, [(currentChest?.chestConfig?.chanceRewards || []).length])

  const createChest = (
    chest: Chest,
    callbackFn?: (chest: Chest) => void,
  ): void => {
    new Promise<Chest>((onResolve, onReject) => {
      updatePrize({ ...currentPrize, chest })
    }).then((chest) => {
      if (!IsNullOrUndefined(callbackFn)) {
        callbackFn!(chest)
      }
    })
  }

  const removeChest = async (callbackFn?: () => void): Promise<any> => {
    await updatePrize({ ...currentPrize, chest: null, chestId: null })
    callbackFn!()
  }

  const updateChest = (chest: Chest): void => {
    dispatch(
      Actions.updateChest({
        item: chest,
        chestId: chest.id,
      }),
    )
  }

  const uploadChestImage = (
    chestId: string,
    fileParams: MediaUploadModel,
  ): void => {
    dispatch(
      Actions.uploadChestImage({
        chestId,
        item: fileParams.binaryData,
        extension: `image.${fileParams.fileExtension}`,
        overrideHeaders: {
          'content-type': mapMimeType(fileParams.fileExtension),
          accept: '*/*',
        },
      }),
    )
  }

  const context: IChestsContext = {
    createChest,
    updateChest,
    currentChest,
    removeChest,
    uploadChestImage,
  }

  return (
    <ChestsContext.Provider value={context}>
      {props.children}
    </ChestsContext.Provider>
  )
}

export const useChestsContext = (): IChestsContext => useContext(ChestsContext)
