import React from 'react'
import { FormGen, FormTypes } from 'components/simple-form-gen'
import { CURRENCY_OPTIONS } from 'modules/quests/constants'
import { ParameterTypes, QuestTaskField } from 'modules/quests/enums/quest-field'
import { IQuestTask } from 'modules/quests/models/quest'
import { TagsSelect } from 'components/tags-select'
import { IKeyValueStringCollection } from 'types/common/interfaces/IKeyValueStringCollection'

interface IQuestTaskFormProps {
  item: IQuestTask,
  setValue: (value: any, fieldName: string, id: string) => void,
  errors: any
  paramterType: ParameterTypes
}

export const Parameters = ({
  setValue,
  item,
  errors,
  paramterType
}: IQuestTaskFormProps): JSX.Element => {

  let element = null

  switch (paramterType) {
    case ParameterTypes.CURRENCY:
      element = <FormGen
        handleChange={({ target: { value } }): void => {
          setValue({ currency: value }, QuestTaskField.PARAMETERS, item.id || '')
        }}
        value={item[QuestTaskField.PARAMETERS]?.currency}
        type={FormTypes.SELECT}
        name={QuestTaskField.PARAMETERS}
        options={CURRENCY_OPTIONS}
        label="Currency"
        hideInitialOption
        otherProps={{
          error: !!errors?.[QuestTaskField.PARAMETERS],
          helperText: errors?.[QuestTaskField.PARAMETERS]
        }}
      />
    break
    case ParameterTypes.TAGS:
      element = <TagsSelect
        initialSelectedIds={
          Object.values(item[QuestTaskField.PARAMETERS] as IKeyValueStringCollection)
        }
        handleChange={(selectedTags): void => {
          const params = selectedTags.reduce((acc, curr) => ({
            ...acc,
            [curr.id!]: curr.id,
          }), {})
          setValue(params, QuestTaskField.PARAMETERS, item.id || '')
        }}
      />
    break
  }

  return <>
    {element}
  </>
}