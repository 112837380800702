import TagActionTypes from 'modules/tags/action-types'
import EventActionTypes from 'modules/events/action-types'
import { Reducer } from 'redux'
import { actionTypeSuccess, cacheItem, cacheItems } from 'core/redux/utils'
import { ITagState } from 'types/modules/tags/models/state/ITagState'
import { ISimpleAction, ISuccessAction } from 'types/redux/interfaces/IAction'
import { Tag } from 'types/modules/tags/models/entities/tag'
import _ from 'lodash-es'
import { GetTagsApiResponse } from 'types/modules/tags/models/api/get-tags-api-response'
import { GetTagGroupsApiResponse } from 'types/modules/tags/models/api/get-tag-groups-api-response'
import { mergeArrays } from 'core/utils/mergeArrays'
import { TagGroup } from 'types/modules/tags/models/entities/tag-group'
import { IEvent } from 'types/modules/events/models/entities/event'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { ITagActionParams } from 'modules/tags/actions'
import { NIL as NIL_UUID } from 'uuid'
import { ITagPromotion } from 'types/modules/tags/models/entities/tag-promotion'
import IPaginatedItems from '../../types/common/pagination/models/entities/paginated-items'

const pageSize = 20

const initialState: ITagState = {
  tags: {
    count: 0,
    page: 0,
    pageSize: pageSize,
    items: [],
    cache: {},
  },
  tagGroups: {
    count: 0,
    page: 0,
    pageSize: pageSize,
    items: [],
    cache: {},
  },
  tagPromotion: {
    tagIds: [],
    promotionEndDate: '',
  },
  tagSuggestion: {
    tags: [],
  },
}

type AllowedActionTypes = ISuccessAction | ISimpleAction

const TagsReducer: Reducer<ITagState, AllowedActionTypes> = (
  state = initialState,
  action: AllowedActionTypes,
) => {
  const simpleAction = action as ISimpleAction
  const successAction = action as ISuccessAction

  const currentliveSchedule = !IsNullOrUndefined(state.tags.currentTagId)
    ? state.tags.items.find((tag) => tag.id === state.tags.currentTagId)
    : null

  switch (action.type) {
    case TagActionTypes.SET_CURRENT_TAG_ID: {
      const newState = _.cloneDeep(state)

      const { tagId } = simpleAction.payload as ITagActionParams

      if (IsNullOrUndefined(tagId)) {
        if (Object.hasOwnProperty.call(newState.tags, 'currentTagId')) {
          const page = (newState as any).page ?? 0
          const pageSize = (newState as any).pageSize ?? 0

          if (
            (page === 1 && newState.tags.items.length > pageSize) ||
            state.tags.currentTagId === NIL_UUID
          ) {
            const currentPageIndex = newState.tags.items.findIndex(
              (liveSchedule) => liveSchedule.id === currentliveSchedule?.id,
            )

            _.pullAt(newState.tags.items, currentPageIndex)
          }
          delete newState.tags.currentTagId
        }
      } else {
        newState.tags.currentTagId = tagId
      }

      return newState
    }

    case TagActionTypes.CREATE_TAG_DRAFT: {
      const newState = _.cloneDeep(state)
      const { item } = simpleAction.payload as ITagActionParams

      newState.tags.items = [item as Tag, ...state.tags.items]

      newState.tags.currentTagId = NIL_UUID

      return newState
    }

    case actionTypeSuccess(TagActionTypes.GET_TAGS): {
      const newState = _.cloneDeep(state)
      const response = successAction.data as GetTagsApiResponse
      const page = successAction.payload!.page
      if (page === 1) {
        newState.tags = {
          ...state.tags,
          ...response,
        }
      } else {
        newState.tags.items = mergeArrays(newState.tags.items!, response.items!)
      }
      newState.tags.cache = cacheItems(state.tags.cache, response.items)
      return newState
    }

    case actionTypeSuccess(TagActionTypes.GET_TAG_BY_ID): {
      const newState = _.cloneDeep(state)
      const response = successAction.data as Tag
      const tagIndex = state.tags.items.findIndex((x) => x.id === response.id)

      if (tagIndex < 0) {
        newState.tags.items = [...newState.tags.items, response]
      } else {
        newState.tags.items[tagIndex] = response
      }

      newState.tags.cache = cacheItem(state.tags.cache, response)

      return newState
    }

    case actionTypeSuccess(TagActionTypes.CREATE_TAG): {
      const newState = _.cloneDeep(state)
      const responseItem = successAction.data as Tag
      responseItem.tagGroups = []
      newState.tags.items = [responseItem, ...newState.tags.items!]

      newState.tags.cache = cacheItem(state.tags.cache, responseItem)

      return newState
    }

    case actionTypeSuccess(TagActionTypes.UPDATE_TAG): {
      const newState = _.cloneDeep(state)
      const payloadItem = successAction.payload!.item as Tag
      const index = newState.tags.items!.findIndex(
        (item) => item.id === payloadItem.id,
      )

      newState.tags.items![index] = payloadItem

      newState.tags.cache = cacheItem(state.tags.cache, payloadItem)

      return newState
    }

    case actionTypeSuccess(TagActionTypes.UPLOAD_TAG_IMAGE): {
      const newState = _.cloneDeep(state)
      const payloadItem = successAction.data as Tag
      const index = newState.tags.items!.findIndex(
        (item) => item.id === payloadItem.id,
      )

      newState.tags.items![index] = payloadItem

      newState.tags.cache = cacheItem(state.tags.cache, payloadItem)

      return newState
    }

    case actionTypeSuccess(TagActionTypes.GET_AUTOCOMPLETE_SEARCH_TAGS): {
      const newState = _.cloneDeep(state)

      const response = successAction.data as IPaginatedItems<Tag>

      newState.tags.cache = cacheItems(state.tags.cache, response.items)

      return newState
    }

    case actionTypeSuccess(TagActionTypes.GET_TAG_GROUPS): {
      const newState = _.cloneDeep(state)
      const response = successAction.data as GetTagGroupsApiResponse
      const page = successAction.payload!.page
      if (page === 1) {
        newState.tagGroups = {
          ...state.tagGroups,
          ...response,
        }
      } else {
        newState.tagGroups.items = mergeArrays(
          newState.tagGroups.items!,
          response.items!,
        )
      }

      newState.tagGroups.cache = cacheItems(
        state.tagGroups.cache,
        response.items!,
      )

      return newState
    }

    case actionTypeSuccess(TagActionTypes.GET_TAG_GROUP_BY_ID): {
      const newState = _.cloneDeep(state)
      const response = successAction.data as TagGroup
      const tagIndex = state.tagGroups.items.findIndex((x) => x.id === response.id)

      if (tagIndex < 0) {
        newState.tagGroups
        .items = [...newState.tagGroups.items, response]
      } else {
        newState.tagGroups.items[tagIndex] = response
      }

      newState.tagGroups.cache = cacheItem(state.tagGroups.cache, response)

      return newState
    }

    case actionTypeSuccess(TagActionTypes.CREATE_TAG_GROUP): {
      const newState = _.cloneDeep(state)
      const responseItem = successAction.data as TagGroup
      newState.tagGroups.items = [responseItem, ...newState.tagGroups.items!]

      newState.tagGroups.cache = cacheItem(state.tagGroups.cache, responseItem)

      return newState
    }

    case actionTypeSuccess(TagActionTypes.UPDATE_TAG_GROUP): {
      const newState = _.cloneDeep(state)
      const responseItem = successAction.data as TagGroup
      const index = newState.tagGroups.items!.findIndex(
        (item) => item.id === responseItem.id,
      )
      newState.tagGroups.items![index] = responseItem

      newState.tagGroups.cache = cacheItem(state.tagGroups.cache, responseItem)

      return newState
    }

    case actionTypeSuccess(TagActionTypes.GET_AUTOCOMPLETE_SEARCH_TAG_GROUPS): {
      const newState = _.cloneDeep(state)

      const response = successAction.data as IPaginatedItems<TagGroup>

      newState.tagGroups.cache = cacheItems(
        state.tagGroups.cache,
        response.items,
      )

      return newState
    }

    case actionTypeSuccess(EventActionTypes.GET_EVENT_BY_ID): {
      const newState = _.cloneDeep(state)

      const response = successAction.data as IEvent

      const tagGroup = response.tagGroup

      if (IsNullOrUndefined(tagGroup)) return state

      newState.tagGroups.cache = cacheItem(state.tagGroups.cache, tagGroup!)

      return newState
    }

    case actionTypeSuccess(TagActionTypes.UPDATE_TAG_PROMOTION):
    case actionTypeSuccess(TagActionTypes.GET_TAG_PROMOTION): {
      const newState = _.cloneDeep(state)

      const response = successAction.data as ITagPromotion

      newState.tagPromotion = response

      return newState
    }

    case actionTypeSuccess(TagActionTypes.UPDATE_TAG_SUGGESTION):
    case actionTypeSuccess(TagActionTypes.GET_TAG_SUGGESTION): {
      const newState = _.cloneDeep(state)

      const response = successAction.data as Tag[]

      newState.tagSuggestion.tags = response

      return newState
    }

    case actionTypeSuccess(TagActionTypes.DELETE_TAG_BY_ID): {
      const newState = _.cloneDeep(state)
      const successAction = action as ISuccessAction

      const currentCache = { ...state.tags.cache }
      const tagId = successAction.payload?.tagId as string
      delete currentCache[tagId!]

      return {
        ...newState,
        tags: {
          ...state.tags,
          items: state.tags.items.filter(item => item.id !== tagId),
          cache: { ...currentCache }
        }
      }
    }

    default:
      return state
  }
}

export default TagsReducer
