import { EndpointRoute } from 'types/common/api/models/entities/endpoint-route'
import { RequestMethod } from 'types/common/api/enums/request-method'
import paths from './paths'
import params from './params'
import filters from './filters'

const TagGroupEndpoints = {
  getTagGroups: {
    method: RequestMethod.GET,
    path: [paths.tagGroups],
    filters,
  },
  getTagGroupById: {
    method: RequestMethod.GET,
    path: [paths.tagGroups, params.tagGroupId],
  },
  createTagGroup: {
    method: RequestMethod.POST,
    path: [paths.tagGroups],
  },
  updateTagGroup: {
    method: RequestMethod.PUT,
    path: [paths.tagGroups, params.tagGroupId],
  },
}

export default TagGroupEndpoints
