import { Typography } from '@mui/material'
import { IsNullUndefinedOrEmpty } from 'core/utils/isNullOrUndefined'
import React from 'react'
import { IUserPrize } from 'types/modules/shipping/models/entities/user-prize'

interface IProps {
  userPrize: IUserPrize | null
}

export const ShippingInfo: React.FC<IProps> = ({ userPrize }) => {
  return (
    <>
      {!IsNullUndefinedOrEmpty(userPrize?.shippingDetails.address1) && (
        <Typography
          variant="subtitle2"
          color="textPrimary"
        >
          {userPrize?.shippingDetails.address1}
        </Typography>
      )}
      {!IsNullUndefinedOrEmpty(userPrize?.shippingDetails.address2) && (
        <Typography
          variant="subtitle2"
          color="textPrimary"
        >
          {userPrize?.shippingDetails.address2}
        </Typography>
      )}
      {!IsNullUndefinedOrEmpty(userPrize?.shippingDetails.city) && (
        <Typography
          variant="subtitle2"
          color="textPrimary"
        >
          {userPrize?.shippingDetails.city}
        </Typography>
      )}
      {!IsNullUndefinedOrEmpty(userPrize?.shippingDetails.postcode) && (
        <Typography
          variant="subtitle2"
          color="textPrimary"
        >
          {userPrize?.shippingDetails.postcode}
        </Typography>
      )}
      {!IsNullUndefinedOrEmpty(userPrize?.shippingDetails.country) && (
        <Typography
          variant="subtitle2"
          color="textPrimary"
        >
          {userPrize?.shippingDetails.country}
        </Typography>
      )}
    </>
  )
}
