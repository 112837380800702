import PrizeStatus from 'types/modules/prizes/enums/prize-status'

export const getStatusLabel = (status: PrizeStatus | string): string => {
  switch (status) {
    case PrizeStatus.Created:
      return 'Draft'

    case PrizeStatus.Open:
      return 'Active'

    default:
      return status
  }
}
