import { Button, ButtonProps, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx'
import { ConfirmationDialog } from 'components/confirmation-dialog'
import { AbandonIcon } from 'components/icons'
import { usePostStatusManager } from 'modules/posts/context/post-status-manager'
import React from 'react'
import PostStatus from 'types/modules/posts/enums/post-status'
import PostType from 'types/modules/posts/enums/post-type'
import { IPostActionButtonProps } from 'types/modules/posts/models/props/post-action-button-props'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.primary.contrastText,
      backgroundColor: '#ff7400',
      '&:hover': {
        backgroundColor: '#ff9033',
      },
    },
  }),
)

export const AbandonPostButton: React.FC<IPostActionButtonProps> = (
  props: IPostActionButtonProps,
) => {
  const { abandonPost } = usePostStatusManager()
  const classes = useStyles()

  const isPaused = props.post.status === PostStatus.Paused

  const buttonProps: ButtonProps = {
    variant: 'contained',
    startIcon: <AbandonIcon />,
    className: clsx(classes.root),
    size: 'small',
    disabled: props.disabled,
  }

  const buttonLabel = 'Abandon'

  return isPaused ? (
    <ConfirmationDialog
      popoverId={`pause-post-${props.post.id}`}
      buttonProps={buttonProps}
      onConfirmation={(): void => abandonPost(props.post.id)}
      confirmationText={'Are you sure you want to abandon this post?'}
      buttonLabel={buttonLabel}
      onPopoverClose={props.onPopoverClose}
    />
  ) : null
}
