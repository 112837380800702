export enum PrizeField {
  ID = 'id',
  ALLOW_FREE_ENTRY = 'allowFreeEntry',
  BRAND = 'brand',
  PROMOTION = 'promotion',
  PROMOTION_URL = 'shopUrl',
  PROMOTION_DESCRIPTION = 'promotionDescription',
  PROMOTION_CODE = 'code',
  COIN_COST = 'coinCost',
  CURRENT_ENTRIES = 'currentEntries',
  DESCRIPTION = 'description',
  DETAILS = 'details',
  END = 'end',
  FEATURED = 'featured',
  FEATURED_IMAGE_URL = 'featuredImageUrl',
  FEATURED_MEDIA_ITEM_ID = 'featuredMediaItemId',
  IMAGE_URL = 'imageUrl',
  ITEM = 'item',
  ITEM_ID = 'itemId',
  LOGO_IMAGE_URL = 'logoImageUrl',
  MEDIA = 'media',
  NAME = 'name',
  PROMOTED = 'promoted',
  PUBLIC = 'public',
  QUANTITY = 'quantity',
  REQUIRED_ENTRIES = 'requiredEntries',
  SHIPPED = 'shipped',
  START = 'start',
  STAR_COST = 'starCost',
  STATUS = 'status',
  TYPE = 'type',
  UNIT_COST = 'unitCost',
  UPDATED_AT = 'updatedAt',
  VISIBILITY = 'visibility',
  CHEST_ID = 'chestId',
  CHEST = 'chest',
  COUNTRIES = 'countries',
  PRIZE_COUNTRIES = 'prizeCountries',
}
