import { PostGroupType } from 'fe-shared-resources'
import * as yup from 'yup'

export const PostGroupSchema = yup.object({
  name: yup.string().required('required'),
  type: yup
    .string()
    .required('type is required')
    .test({
      message: 'type is required',
      test: function (value) {
        return !!value && value !== PostGroupType.NotSet
      },
    }),
})
