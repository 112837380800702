import { AppState } from 'types/redux/types/app-state'
import { MODULE_NAME } from 'modules/post-groups/constants'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { NIL as NIL_UUID } from 'uuid'
import { IPostGroupState } from 'modules/post-groups/models/state'
import { IPost } from 'types/modules/posts/models/entities/post'
import { PostGroup, PostGroupStatus, PostGroupType } from 'fe-shared-resources'

const getState = (state: AppState): IPostGroupState => state[MODULE_NAME]

export const getPostGroupPosts = (state: AppState): Partial<IPost>[] => getState(state).postGroupPosts

export const getCurrentPostGroup = (state: AppState): PostGroup | null => {
  const { currentPostGroupId, cache } = getState(state)

  if (IsNullOrUndefined(currentPostGroupId)) return null

  if (currentPostGroupId === NIL_UUID) {
    return {
      name: '',
      type: PostGroupType.DailyStack,
      postIds: [],
      status: PostGroupStatus.Pending,
    }
  }

  const currentPostGroup = cache[currentPostGroupId!]

  return currentPostGroup ?? null
}

export const getPostGroups = (state: AppState): IPostGroupState =>
  getState(state)

export const getPostGroupsByStatus = (
  state: AppState,
  statuses: PostGroupStatus[] | null,
): IPostGroupState => {
  const postGroups = getPostGroups(state)

  if (!IsNullOrUndefined(statuses)) {
    postGroups.items = postGroups.postGroups.filter((postGroup) =>
      statuses?.includes(postGroup.status!),
    )
  }

  return postGroups
}

export const getPostGroupGetter = (state: AppState): (id: string) => PostGroup => {
  return (id: string): PostGroup => getState(state).cache[id]
}

export const getAutocompleteSearchPostGroups = (state: AppState): PostGroup[] =>
  Object.values(getPostGroups(state).cache)
