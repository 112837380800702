import ContentDashboard from 'components/content-dashboard'
import { DashboardContextProvider } from 'components/content-dashboard/context'
import { IContentDashboardProps } from 'components/content-dashboard/props/content-dashboard-props'
import { useRequestContext } from 'core/api/context'
import { useLiveScheduleContext } from 'modules/live-schedules/context'
import React, { useCallback } from 'react'
import { ILiveSchedule } from 'types/modules/live-schedules/models/entities/live-schedule'
import _ from 'core/utils/deepdash'
import { LiveScheduleField } from 'types/modules/live-schedules/enums/live-schedule-field'
import { SortDirection } from 'types/common/enums/sort-direction'
import { LiveSchedulesToolbar } from 'modules/live-schedules/views/components/dashboard/toolbar'
import { LiveScheduleListItem } from 'modules/live-schedules/views/components/dashboard/list-item'
import { LiveScheduleListHeaderPanel } from 'modules/live-schedules/views/components/dashboard/list-header-panel'
import { LiveScheduleCalendarDateGridCell } from 'modules/live-schedules/views/components/dashboard/calendar-date-grid-cell'
import { LiveScheduleCalendarTimeGridCell } from 'modules/live-schedules/views/components/dashboard/calendar-time-grid-cell'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { LiveScheduleDetailsPanel } from 'modules/live-schedules/views/components/schedule-details-panel'
import { LiveScheduleDetailsModal } from 'modules/live-schedules/views/components/schedule-details-panel/modal'

export const LiveScheduleDashboard: React.FC<{}> = () => {
  const { currentLiveSchedule, liveSchedules, initialiseDashboard } =
    useLiveScheduleContext()

  const requestContext = useRequestContext()

  const onViewModeChange = (viewMode: string): void => {
    const newSortValue = _.clone(requestContext?.sortValue!)
    newSortValue.sortBy = LiveScheduleField.START

    switch (viewMode) {
      case 'list': {
        newSortValue.sortDirection = SortDirection.Descending
        requestContext?.setSortValue(newSortValue)
        break
      }

      case 'calendar': {
        newSortValue.sortDirection = SortDirection.Ascending
        requestContext?.setSortValue(newSortValue)
        break
      }

      default:
        return
    }
  }

  const dashboardProps: IContentDashboardProps<ILiveSchedule> = {
    onDashboardMount: initialiseDashboard,
    onViewModeChange,
    listOptions: {
      headerPanelComponent: <LiveScheduleListHeaderPanel />,
      listItemComponent: LiveScheduleListItem,
      toolbarComponent: <LiveSchedulesToolbar />,
    },
    calendarOptions: {
      toolbarComponent: <LiveSchedulesToolbar />,
      timeGridCellComponent: LiveScheduleCalendarTimeGridCell,
      dateGridCellComponent: LiveScheduleCalendarDateGridCell,
    },
    data: liveSchedules,
  }

  return (
    <React.Fragment>
      <DashboardContextProvider>
        <ContentDashboard {...dashboardProps} />
      </DashboardContextProvider>
      {!IsNullOrUndefined(currentLiveSchedule) && (
        <LiveScheduleDetailsModal currentSchedule={currentLiveSchedule} open />
      )}
    </React.Fragment>
  )
}
