export enum UserPrizeStatus {
  Created = 'Created',
  Entered = 'Entered',
  Won = 'Won',
  ReadyToShip = 'ReadyToShip',
  Cancelled = 'Cancelled',
  Dispatched = 'Dispatched',
  Delivered = 'Delivered',
  Returned = 'Returned',
}
