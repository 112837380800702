import { IKeyValueStringCollection } from 'types/common/interfaces/IKeyValueStringCollection'

const filters: IKeyValueStringCollection = {
  eventId: '{{eventid}}',
  start: '{{start}}',
  end: '{{end}}',
  name: '{{name}}',
}

export default filters
