import { useTypedSelector } from 'core/redux/utils'
import { useRouter } from 'core/routing/hooks/use-router'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { GameTask, GameTaskStep } from 'fe-shared-resources'
import { GameTaskActions, Selectors } from 'modules/quests'
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useDispatch } from 'react-redux'
import { NIL as NIL_UUID } from 'uuid'

export interface IGameTasksContext {
  currentGameTask: GameTask | null
  gameTaskSteps: GameTaskStep[]
  sortedStepsIds: string[]
  navigationDisabled: boolean
  selectedStep?: string
  requesting: boolean
  setCurrentGameTask: (gameTaskId: string) => void
  clearCurrentGameTask: () => void
  createGameTask: (
    gameTask: GameTask,
    callbackFn?: (quest: GameTask) => void,
  ) => void
  createGameTaskDraft: (gameTask?: GameTask) => void
  updateGameTask: (gameTask: GameTask) => void
  createGameTaskStep: (
    gameTaskStep: GameTaskStep,
    callbackFn?: (task: GameTaskStep) => void,
  ) => void
  updateGameTaskStep: (
    gameTaskStep: GameTaskStep,
    callbackFn?: (task: GameTaskStep) => void
  ) => void,
  setSelectedStep: (step?: string) => void
  setNavigationDisabled: (disabled: boolean) => void
}

const GameTasksContext = createContext<IGameTasksContext>(
  {} as IGameTasksContext,
)

export const GameTasksProvider: React.FC<PropsWithChildren<{}>> = (
  props: PropsWithChildren<{}>,
) => {
  const dispatch = useDispatch()

  const {
    routeParams: { gameTaskId },
    pushQueryParams,
    removeQueryParams,
  } = useRouter()

  const [requesting, setRequesting] = useState(false)
  const [navigationDisabled, setNavigationDisabled] = useState(false)
  const [selectedStep, setSelectedStep] = useState<string | undefined>()

  const currentGameTask = useTypedSelector((state) =>
    Selectors.getCurrentGameTask(state),
  )

  const setCurrentGameTask = (gameTaskId: string): void => {
    pushQueryParams({
      gameTaskId,
    })
  }

  const clearCurrentGameTask = (): void => {
    setNavigationDisabled(false)
    setSelectedStep(undefined)
    removeQueryParams('gameTaskId')
  }

  const createGameTaskDraft = (): void => {
    setCurrentGameTask(NIL_UUID)
  }

  const createGameTask = (gameTask: GameTask, callbackFn?: (task: GameTask) => void): void => {
    setRequesting(true)
    dispatch(
      GameTaskActions.createGameTask({
        item: gameTask,
        promise: {
          onResolve: (data: GameTask): void => {
            setCurrentGameTask(data.id!)
            setRequesting(false)
            callbackFn?.(data)
          },
          onReject: (): void => setRequesting(false),
        },
      }),
    )
  }

  const updateGameTask = (gameTask: GameTask, callbackFn?: (task: GameTask) => void): void => {
    setRequesting(true)
    dispatch(
      GameTaskActions.updateGameTask({
        item: gameTask,
        gameTaskId: gameTask.id,
        promise: {
          onResolve: (data: GameTask): void => {
            setRequesting(false)
            callbackFn?.(data)
          },
          onReject: (): void => setRequesting(false),
        }
      }),
    )
  }

  const createGameTaskStep = (
    gameTaskStep: GameTaskStep,
    callbackFn?: (gameTaskStep: GameTaskStep) => void
  ): void => {
    setRequesting(true)
    dispatch(
      GameTaskActions.createGameTaskStep({
        item: gameTaskStep,
        gameTaskId: currentGameTask?.id,
        promise: {
          onResolve: (data: GameTaskStep): void => {
            setRequesting(false)
            setSelectedStep(data.id)
            callbackFn?.(data)
          },
          onReject: (): void => setRequesting(false),
        },

      }),
    )
  }

  const updateGameTaskStep = (
    gameTaskStep: GameTaskStep,
    callbackFn?: (gameTaskStep: GameTaskStep) => void
    ): void => {
    setRequesting(true)
    dispatch(
      GameTaskActions.updateGameTaskStep({
        item: gameTaskStep,
        gameTaskId: currentGameTask?.id,
        gameTaskStepId: gameTaskStep?.id,
        promise: {
          onResolve: (gameTaskStep: GameTaskStep): void => {
            setRequesting(false)
            callbackFn?.(gameTaskStep)
          },
          onReject: (): void => setRequesting(false),
        }
      }),
    )
  }

  useEffect(() => {
    dispatch(
      GameTaskActions.setCurrentGameTaskId({ gameTaskId }),
    )
    if (!IsNullOrUndefined(gameTaskId) && gameTaskId !== NIL_UUID) {
      dispatch(
        GameTaskActions.getGameTaskById({ gameTaskId }),
      )
      dispatch(
        GameTaskActions.getGameTaskSteps({ gameTaskId })
      )
    }
  }, [gameTaskId])

  const gameTaskSteps = useTypedSelector((state) =>
    Selectors.getGameTaskSteps(state),
  )
  const sortedStepsIds: string[] = useMemo(() =>
    gameTaskSteps.sort((a, b) => a.stepNumber - b.stepNumber).map((questStep) => questStep.id || '')
  , [gameTaskSteps.length])

  const context = {
    currentGameTask,
    setCurrentGameTask,
    clearCurrentGameTask,
    createGameTask,
    createGameTaskDraft,
    updateGameTask,
    createGameTaskStep,
    updateGameTaskStep,
    setNavigationDisabled: (disabled: boolean): void => setNavigationDisabled(disabled),
    setSelectedStep: (step?: string): void => setSelectedStep(step),
    gameTaskSteps,
    sortedStepsIds,
    navigationDisabled,
    selectedStep,
    requesting
  }

  return (
    <GameTasksContext.Provider value={context}>
      {props.children}
    </GameTasksContext.Provider>
  )
}

export const useGameTasksContext = (): IGameTasksContext =>
  useContext(GameTasksContext)
