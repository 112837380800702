import { MODULE_NAME } from 'core/alerts/constants'

const ActionTypes: { [key: string]: string } = {
  ENQUEUE_ALERT: `${MODULE_NAME}/ENQUEUE_ALERT`,
  UPDATE_ALERT: `${MODULE_NAME}/UPDATE_ALERT`,
  DISMISS_ALERT: `${MODULE_NAME}/DISMISS_ALERT`,
  REMOVE_ALERT: `${MODULE_NAME}/REMOVE_ALERT`,
}

export default ActionTypes
