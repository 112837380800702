const paths: { [key: string]: string } = {
  clearTrash: 'clear-trash',
  duplicate: 'duplicate',
  prizes: 'prizes',
  promote: 'promote',
  statuses: 'statuses',
  images: 'images',
  items: 'items',
  upload: 'upload',
  image: 'image',
  featured: 'featured',
  logo: 'logo',
  updateStatus: 'update-status',
}

export default paths
