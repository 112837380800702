/* eslint-disable no-invalid-this */
import { QuestGroupField } from 'modules/quests/enums/quest-group-field'
import * as yup from 'yup'

export const QuestGroupSchema = yup.object({
  [QuestGroupField.NAME]: yup
    .string()
    .required('required'),
  [QuestGroupField.VARIANT]: yup
    .string()
    .required('required'),
  [QuestGroupField.TEST]: yup
    .string()
    .required('required'),
  [QuestGroupField.QUEST_GROUP_QUESTS]: yup
    .array()
    .test({
      message: 'required',
      test: function (value) {
        return this.parent.questGroupQuests.length > 0
      },
    }),
})
