import * as yup from 'yup'
import { QuestionField } from 'types/modules/posts/enums/question-field'
import { OptionField } from 'types/modules/posts/enums/option-field'
import { MAX_TITLE_LENGTH } from 'modules/posts/constants'

export const QuestionSchema = yup.object({
  [QuestionField.TEXT]: yup
    .string()
    .required('can not be empty')
    .max(MAX_TITLE_LENGTH, `can not be more than ${MAX_TITLE_LENGTH}`),
  [QuestionField.OPTIONS]: yup.array().of(
    yup.object().shape({
      [OptionField.TEXT]: yup
        .string()
        .required('can not be empty')
        .max(80, 'can not be more than 80'),
    }),
  ),
})
