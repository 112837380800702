import { Fade, Modal, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Panel } from 'components/panel'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { ManageTagPanel } from 'modules/tags/views/manage-tag-panel'
import React from 'react'
import { Tag } from 'types/modules/tags/models/entities/tag'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 40,
      zIndex: 1250,
    },
  }),
)

interface IManageTagModalProps {
  currentTag: Tag | null
  open: boolean
  closeModal?: () => void
}

export const ManageTagModal: React.FC<IManageTagModalProps> = (
  props: IManageTagModalProps,
) => {
  const classes = useStyles()
  return (
    <div>
      <Modal
        className={classes.modal}
        open={props.open}
      >
        <Fade in={!IsNullOrUndefined(props.currentTag)}>
          <Panel
            xs={12}
            style={{
              display: 'flex',
              maxWidth: 900,
              height: 650,
              zIndex: 1250,
            }}
          >
            <ManageTagPanel isModal closeModal={props.closeModal} />
          </Panel>
        </Fade>
      </Modal>
    </div>
  )
}
