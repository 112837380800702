import { Box, Button, Portal, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { ConfirmationDialog } from 'components/confirmation-dialog'
import { UndoIcon, SaveIcon, DiscardIcon } from 'components/icons'
import { titleCase } from 'core/utils/titleCase'
import { useFormik } from 'formik'
import { ResultView } from 'modules/posts/views/components/manage-post-panel/result-view'
import moment from 'moment-timezone'
import React, { useEffect } from 'react'
import PostStatus from 'types/modules/posts/enums/post-status'
import { IPostResult } from 'types/modules/posts/models/entities/post-result'
import _ from 'core/utils/deepdash'
import { useAuth } from 'core/authentication/hooks/use-auth'
import { IPostDetailsScreenProps } from 'types/modules/posts/models/props/post-details-screen-props'
import { ResultScreenPreview } from 'modules/app/views/components/previews/result-screen'
import { ResultSchema } from 'modules/posts/validation/result-schema'
import { usePostResultsManager } from 'modules/posts/context/post-results-manager'
import { usePostListContext } from 'modules/posts/context/post-list'
import { IPost } from 'types/modules/posts/models/entities/post'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentPanel: {
      position: 'relative',
      display: 'flex',
      flex: 1,
      padding: '1.25rem',
      overflow: 'auto',
      borderRight: '1px solid rgba(0,0,0,0.12)',
      '&:last-child': {
        border: 'none',
      },
    },
    updateButton: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
      marginRight: '0.625rem',
    },
    resetButton: {
      color: theme.palette.text.primary,
      backgroundColor: '#ecf0f1',
      marginLeft: '0.625rem',
      '&:hover': {
        backgroundColor: '#f2f5f5',
      },
    },
    metadataContainer: {
      position: 'absolute',
      display: 'flex',
      flexDirection: 'column',
      bottom: '1.25rem',
      right: '1.25rem',
    },
    metadata: {
      display: 'flex',
      fontSize: '0.7rem',
      justifyContent: 'flex-end',
    },
  }),
)

interface IResultScreenProps extends IPostDetailsScreenProps {
  result: IPostResult
}

export const ResultScreen: React.FC<IResultScreenProps> = (
  props: IResultScreenProps,
) => {
  const {
    result,
    mainContentPanelRef,
    dynamicContentPanelRef,
    formButtonFooterContainerRef,
    disableButtons,
    renderPreview,
  } = props
  const { deleteResult, updatePostResult } = usePostResultsManager()
  const { currentPost } = usePostListContext()
  const { currentUser } = useAuth()
  const post = currentPost as IPost

  const postResultForm = useFormik({
    validationSchema: ResultSchema,
    enableReinitialize: true,
    initialValues: result,
    onSubmit: (values: IPostResult): void => {
      const newValues = _.clone(values)
      newValues.updatedBy = currentUser.username
      newValues.text = newValues.text.trim().replace('  ', ' ')
      updatePostResult(newValues)
    },
  })

  useEffect(() => {
    disableButtons(postResultForm.dirty)
  }, [postResultForm.dirty])

  const classes = useStyles()

  const renderFormButtons = (): JSX.Element => {
    return (
      <Portal container={formButtonFooterContainerRef}>
        <Button
          variant="contained"
          startIcon={<UndoIcon />}
          className={classes.resetButton}
          size="small"
          onClick={(): void => {
            postResultForm.resetForm()
          }}
          disabled={!postResultForm.dirty}
        >
          Undo
        </Button>
        {[PostStatus.Created, PostStatus.Pending, PostStatus.Trashed].includes(
          post.status,
        ) && (
          <ConfirmationDialog
            popoverId={`delete-post-result-${result.id}`}
            buttonProps={{
              variant: 'contained',
              startIcon: <DiscardIcon />,
              className: classes.resetButton,
              size: 'small',
            }}
            onConfirmation={(): void => deleteResult(post.id, result.id)}
            confirmationText={`Are you sure you want to delete this result?`}
            buttonLabel={'Delete Result'}
          />
        )}
        <Button
          variant="contained"
          startIcon={<SaveIcon />}
          className={classes.updateButton}
          size="small"
          onClick={(): void => {
            postResultForm.submitForm()
          }}
          disabled={!postResultForm.dirty}
        >
          Save
        </Button>
      </Portal>
    )
  }

  return (
    <React.Fragment>
      {renderFormButtons()}
      <Portal container={mainContentPanelRef}>
        <ResultView
          {...{
            post,
            result: postResultForm.values,
            handleChange: postResultForm.handleChange,
            setFieldValue: postResultForm.setFieldValue,
            setFieldTouched: postResultForm.setFieldTouched,
            errors: postResultForm.errors,
          }}
        />
      </Portal>
      {renderPreview && (
        <Portal container={dynamicContentPanelRef}>
          <ResultScreenPreview {...postResultForm.values} />
          <Box className={classes.metadataContainer}>
            <Typography color="textPrimary" className={classes.metadata}>
              {`Created By: ${titleCase(post.createdBy ?? 'Unknown')}`}
            </Typography>
            <Typography color="textPrimary" className={classes.metadata}>
              {`Updated By: ${titleCase(result.updatedBy ?? 'Unknown')}`}
            </Typography>
            <Typography color="textPrimary" className={classes.metadata}>
              {`${moment(result.updatedAt).format('Do MMMM YYYY, h:mm A')}`}
            </Typography>
          </Box>
        </Portal>
      )}
    </React.Fragment>
  )
}
