import { call, CallEffect, fork, ForkEffect } from 'redux-saga/effects'
import { IAsyncAction } from 'types/redux/interfaces/IAction'
import Api from 'core/api'
import { ApiRequestActions } from 'types/common/api/models/entities/api-request-actions'

export type GetTagsSagaReturnType = Generator<
  CallEffect<ApiRequestActions> | ForkEffect<void>,
  void,
  ApiRequestActions
>

// Todo: Move this logic inside of the AsyncRequestSaga, delete this file and update references
export function* GetTagsSaga(action: IAsyncAction): GetTagsSagaReturnType {
  const actions: ApiRequestActions = yield call(
    Api.Utils.CreateApiRequestActions,
    action,
  )

  yield fork(Api.Sagas.AsyncRequestSaga, actions)
}
