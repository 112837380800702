import React, { useRef, useState } from 'react'
import {
  AppBar,
  Avatar,
  Box,
  ButtonBase,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Popover,
  SvgIcon,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

import clsx from 'clsx'
import { ENV } from 'core/environment'
import { useAuth } from 'core/authentication/hooks/use-auth'
import { HamburgerIcon } from 'components/icons'
import GossLogo from 'core/theme/logo'
import { PortalPermissions } from 'core/authorization/rules'
import { IPortalUser } from 'types/modules/users/portal/models/entities/portal-user'
import { PageSizeSelect } from 'components/page-size-select'
import { useLocation } from 'react-router-dom'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    '@global': {
      '@keyframes openMenu': {
        '0%': {
          maxHeight: 65,
        },
        '100%': {
          boxShadow: '1px 3px 5px rgba(0, 0, 0, 0.3)',
          maxHeight: 145,
        },
      },
    },
    appHeader: {
      minHeight: '65px',
      flexDirection: 'row',
      alignItems: 'center',
      zIndex: 1201,
    },
    leftSection: {
      display: 'flex',
      flex: '1',
      height: '65px',
      alignItems: 'center',
      [theme.breakpoints.up('sm')]: {
        padding: 20,
      },
    },
    middleSection: {
      display: 'flex',
      flexShrink: 0,
      alignItems: 'center',
      justifyContent: 'center',
    },
    rightSection: {
      display: 'flex',
      flex: '1',
      height: '65px',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    mobileNavButton: {
      color: theme.palette.primary.contrastText,
      marginLeft: 7,
    },
    logo: {
      display: 'flex',
      width: '100%',
      height: '30px',
      marginTop: '7px',
      '& svg': {
        fill: theme.palette.primary.contrastText,
        height: '100%',
        fontSize: 90,
      },
    },
    userAccountMenu: {
      top: 0,
      borderLeft: '1px solid rgba(51, 51, 51, 0.2)',
      flexDirection: 'column',
      maxHeight: 65,
      overflow: 'hidden',
      transition: theme.transitions.create(['box-shadow', 'max-height'], {
        duration: theme.transitions.duration.standard,
      }),
      '&.open': {
        border: 'none',
        boxShadow: '1px 5px 10px rgba(0, 0, 0, 0.3)',
        maxHeight: 210,
        transition: theme.transitions.create(['box-shadow', 'max-height'], {
          duration: theme.transitions.duration.standard,
        }),
      },
      [theme.breakpoints.down('sm')]: {
        border: 0,
      },
    },
    headerTab: {
      display: 'flex',
      height: '100%',
      padding: '10px 20px',
      alignItems: 'center',
      cursor: 'pointer',
      [theme.breakpoints.down('sm')]: {
        padding: 10,
      },
    },
    avatar: {
      display: 'flex',
      width: 45,
      height: 45,
      marginRight: 10,
      fontSize: '1.25rem',
      fontWeight: 500,
      backgroundColor: 'rgba(0,0,0,0.14)',
      [theme.breakpoints.down('sm')]: {
        margin: 0,
      },
    },
    username: {
      display: 'flex',
      flex: 1,
    },
    userDropdownLinks: {
      background: theme.palette.background.paper,
      width: '100%',
      padding: 0,
    },
    userDropdownLinkButton: {
      padding: '14px 20px',
    },
    userDropdownLinkText: {
      marginTop: 6,
      marginBottom: 2,
      color: theme.palette.text.primary,
    },
    dropdownArrow: {
      transform: 'rotate(0deg)',
      transition: theme.transitions.create(['transform'], {
        duration: theme.transitions.duration.standard,
      }),
      '&.open': {
        transform: 'rotate(180deg)',
        transition: theme.transitions.create(['transform'], {
          duration: theme.transitions.duration.standard,
        }),
      },
    },
  }),
)

const pagesWithResizableList = [
  'events/list',
  'posts/list',
  'prizes/list'
]

type UserAccountMenuProps = {
  user: IPortalUser
}

const titleCase = (value: string): string => {
  const sentence = value.toLowerCase().split('_')
  for (let i = 0; i < sentence.length; i++) {
    sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1)
  }

  return sentence.join(' ')
}

// Todo: cleanup
const UserAccountMenu: React.FC<UserAccountMenuProps> = ({
  user,
}: UserAccountMenuProps) => {
  const { logout } = useAuth()

  const classes = useStyles()

  const [menuOpen, setMenuOpen] = useState(false)

  const initials = user.username.substr(0, 1)

  const dropdownClasses = clsx(classes.userAccountMenu, menuOpen && 'open')

  const dropdownMenuClasses = clsx(
    classes.userDropdownLinks,
    menuOpen && 'open',
  )

  const dropdownArrowClasses = clsx(classes.dropdownArrow, menuOpen && 'open')

  const logoutHandler = (): void => {
    logout()
  }

  const closeMenu = (): void => setMenuOpen(false)

  const toggleMenu = (): void =>
    menuOpen ? setMenuOpen(false) : setMenuOpen(true)

  // Popover stuff
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handlePopoverClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ): void => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = (): void => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  // eslint-disable-next-line id-blacklist
  const id = open ? 'user-account-menu' : undefined

  const mobile = useMediaQuery('(max-width: 600px)')

  return (
    <Box className={dropdownClasses} onMouseLeave={closeMenu}>
      <ButtonBase onClick={handlePopoverClick}>
        <Box className={classes.headerTab}>
          <Avatar className={classes.avatar} variant="circular">
            {initials.toUpperCase()}
          </Avatar>
          {!mobile && (
            <React.Fragment>
              <Typography className={classes.username} variant="body1">
                {titleCase(user.username)}
              </Typography>
              <ArrowDropDownIcon className={dropdownArrowClasses} />
            </React.Fragment>
          )}
        </Box>
      </ButtonBase>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        marginThreshold={0}
      >
        <List className={dropdownMenuClasses}>
          <ListItem
            button
            onClick={logoutHandler}
            className={classes.userDropdownLinkButton}
          >
            <ListItemText
              primary={'Log Out'}
              className={classes.userDropdownLinkText}
            />
          </ListItem>
          <Divider />
          <ListItem className={classes.userDropdownLinkButton}>
            <ListItemText
              primary={`App Version: ${ENV.APP_VERSION}`}
              className={classes.userDropdownLinkText}
              style={{
                color: 'rgba(51, 51, 51, 0.54)',
              }}
            />
          </ListItem>
        </List>
      </Popover>
    </Box>
  )
}

interface IHeaderProps {
  toggleMobileNav?: () => void
}

const Header: React.FC<IHeaderProps> = (props: IHeaderProps) => {
  const { currentUser } = useAuth()

  const classes = useStyles()

  const mobile = useMediaQuery('(max-width: 600px)')

  const location = useLocation()

  const canSeeDashboard = currentUser.can(PortalPermissions.DASHBOARD_VIEW)

  const canSeePageSize = useRef(
    pagesWithResizableList.some(page => location.pathname.includes(page)) && currentUser !== null
  )

  return (
    <AppBar position="fixed" className={classes.appHeader}>
      <Box className={classes.leftSection}>
        {mobile && canSeeDashboard ? (
          <IconButton
            onClick={props.toggleMobileNav}
            className={classes.mobileNavButton}
            size="large">
            <SvgIcon>
              <HamburgerIcon />
            </SvgIcon>
          </IconButton>
        ) : (
          <Box
            className={classes.logo}
            style={{
              marginLeft: mobile ? '1.25rem' : 0,
            }}
          >
            <GossLogo />
          </Box>
        )}
      </Box>
      {mobile && canSeeDashboard && (
        <Box className={classes.middleSection}>
          <Box className={classes.logo}>
            <GossLogo />
          </Box>
        </Box>
      )}
      <Box className={classes.rightSection}>
        {canSeePageSize.current && <PageSizeSelect />}
        {currentUser !== null && <UserAccountMenu user={currentUser!} />}
      </Box>
    </AppBar>
  );
}

export default Header
