import React from 'react'
import { Link, NavLink, LinkProps } from 'react-router-dom'

import { RouteConfig } from 'types/common/routing/route-config'
import { RouteParam } from 'types/common/routing/route-param'

interface ILinkResolverProps extends Partial<LinkProps> {
  route: RouteConfig
  params?: { [key: string]: RouteParam }
  navLink: boolean
}

const LinkResolver: React.FC<ILinkResolverProps> = ({
  route,
  params,
  component,
  replace,
  innerRef,
}: ILinkResolverProps) => {
  let { path } = route

  // Todo: Figure out what to do with permissioning.
  const { requiredParams, requiredPermissions } = route

  if (requiredParams && params) {
    for (const param in requiredParams) {
      if (Object.hasOwnProperty.call(params, param)) {
        const value = params[param].replace('/', '') // For enum compatibility
        path = path.replace(param, `/${value}`)
      }
    }
  }

  const linkProps = { component, replace, innerRef }

  return <Link {...linkProps} to={path} />
}

export default LinkResolver
