import React from 'react'
import { Box, Grid, InputAdornment, InputLabel, TextField, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { CoinIcon, StarIcon } from 'components/icons'
import { IPositionRewardFormProps } from 'modules/leaderboards/views/components/edit-position-reward-panel/types/position-reward-form-props'
import { LeaderboardPositionRewardField as FieldName } from 'types/modules/leaderboards/enums/leaderboard-position-reward-field'
import {
  Actions as PrizeActions,
  Selectors as PrizeSelectors,
} from 'modules/prizes'
import { useTypedSelector } from 'core/redux/utils'
import { IPrize } from 'types/modules/prizes/models/entities/prize'
import { AutocompleteSearchSelect } from 'components/controls/inputs/downshift/autocomplete/autocomplete-search-select'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { IPrizeActionParams } from 'modules/prizes/actions'
import { FilterOperator } from 'types/common/filtering/enums/FilterOperator'
import { FilterType } from 'types/common/filtering/enums/FilterType'
import { PrizeField } from 'types/modules/prizes/enums/prize-field'
import PrizeStatus from 'types/modules/prizes/enums/prize-status'
import { PrizeType } from 'fe-shared-resources'
import { IAsyncAction } from 'types/redux/interfaces/IAction'
import { AppState } from 'types/redux/types/app-state'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    positionRewardsForm: {
      width: '100%',
      height: 'auto',
      '& .MuiGrid-item': {
        display: 'flex',
        flexDirection: 'column',
      },
    },
    inputLabel: {
      display: 'flex',
      marginBottom: '0.625rem',
    },
    adornmentIcon: {
      display: 'flex',
      width: 24,
      height: 24,
      marginRight: 12,
    },
  }),
)

export const PositionRewardForm: React.FC<IPositionRewardFormProps> = (
  props: IPositionRewardFormProps,
) => {
  const classes = useStyles()

  const { positionReward, handleChange, setFieldValue } = props

  const prizeReward = (positionReward.prizes[0] as IPrize) ?? null

  const selectedPrize = useTypedSelector((state) =>
    PrizeSelectors.getCachedPrizeById(state, prizeReward?.id),
  )

  const searchPrizes = (params: IPrizeActionParams): IAsyncAction => {
    const filters = [
      {
        field: PrizeField.STATUS,
        type: FilterType.Select,
        operator: FilterOperator.NotEquals,
        value: [
          ...Object.values(PrizeStatus).filter((status) =>
            [PrizeStatus.Created, PrizeStatus.Trashed].includes(status),
          ),
        ],
      },
      {
        field: PrizeField.NAME,
        type: FilterType.Search,
        operator: FilterOperator.ContainsCaseInsensitive,
        value: params.search!.value,
      },
      {
        field: PrizeField.TYPE,
        type: FilterType.Select,
        operator: FilterOperator.Equals,
        value: [PrizeType.Event, PrizeType.Instant],
      },
    ]

    return PrizeActions.autocompletePrizeSearch({
      filters,
    })
  }

  return (
    <React.Fragment>
      <Box className={classes.positionRewardsForm}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>Prize Reward</InputLabel>
            <AutocompleteSearchSelect
              variant="filled"
              initialSelectedItem={selectedPrize || prizeReward}
              availableItems={[]}
              onSelectedItemChange={(selectedPrize: IPrize | null): void => {
                if (!IsNullOrUndefined(selectedPrize)) {
                  setFieldValue(`${FieldName.PRIZES}[0]`, selectedPrize)
                } else {
                  setFieldValue(FieldName.PRIZES, [])
                }
              }}
              fieldName="item.name"
              searchAsyncActionCreator={searchPrizes}
              selectorMethod={(state: AppState): IPrize[] =>
                PrizeSelectors.getCachedPrizesOfTypes(state, [PrizeType.Event, PrizeType.Instant])}
              options={{
                autocompleteSearchResultContent: (item: unknown): string => {
                  const prize = item as IPrize
                  return `${prize?.item?.name} - ${prize?.item?.details?.brand}`
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel
              className={classes.inputLabel}
              htmlFor={FieldName.GOLD_BOX}
            >
              Gold Boxes
            </InputLabel>
            <TextField
              id={FieldName.GOLD_BOX}
              name={FieldName.GOLD_BOX}
              value={positionReward[FieldName.GOLD_BOX]}
              onChange={handleChange}
              variant="filled"
              type="number"
              fullWidth
              InputProps={{
                inputProps: {
                  min: 0,
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <Box className={classes.adornmentIcon}>
                      <i>🎁</i>
                    </Box>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel
              className={classes.inputLabel}
              htmlFor={FieldName.BROWN_BOX}
            >
              Brown Boxes
            </InputLabel>
            <TextField
              id={FieldName.BROWN_BOX}
              name={FieldName.BROWN_BOX}
              value={positionReward[FieldName.BROWN_BOX]}
              onChange={handleChange}
              variant="filled"
              type="number"
              fullWidth
              InputProps={{
                inputProps: {
                  min: 0,
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <Box className={classes.adornmentIcon}>
                      <i>📦</i>
                    </Box>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel
              className={classes.inputLabel}
              htmlFor={FieldName.STARS}
            >
              Stars
            </InputLabel>
            <TextField
              id={FieldName.STARS}
              name={FieldName.STARS}
              value={positionReward[FieldName.STARS]}
              onChange={handleChange}
              variant="filled"
              type="number"
              fullWidth
              InputProps={{
                inputProps: {
                  min: 0,
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <Box className={classes.adornmentIcon}>
                      <StarIcon />
                    </Box>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel
              className={classes.inputLabel}
              htmlFor={FieldName.COINS}
            >
              Coins
            </InputLabel>
            <TextField
              id={FieldName.COINS}
              name={FieldName.COINS}
              value={positionReward[FieldName.COINS]}
              onChange={handleChange}
              variant="filled"
              type="number"
              fullWidth
              InputProps={{
                inputProps: {
                  min: 0,
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <Box className={classes.adornmentIcon}>
                      <CoinIcon />
                    </Box>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  )
}
