/* eslint-disable no-console */
import { Reducer } from 'redux'
import { actionTypeSuccess } from 'core/redux/utils'
import { ISimpleAction, ISuccessAction } from 'types/redux/interfaces/IAction'
import _ from 'core/utils/deepdash'
import { mergeArrays } from 'core/utils/mergeArrays'
import { IPostsState } from 'types/modules/posts/models/state/IPostsState'
import PostStatus from 'types/modules/posts/enums/post-status'
import ActionTypes from 'modules/posts/action-types'
import IPaginatedItems from 'types/common/pagination/models/entities/paginated-items'
import { IPost } from 'types/modules/posts/models/entities/post'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { IPostActionParams } from 'modules/posts/actions'
import { IPostQuestion } from 'types/modules/posts/models/entities/post-question'
import { IPostResult } from 'types/modules/posts/models/entities/post-result'

const buildInitialState = (): IPostsState => {
  const state: IPostsState = {
    items: [],
    statuses: {},
  }

  Object.values(PostStatus).forEach((value) => {
    state.statuses[value] = {
      count: 0,
      hasNotifications: false,
    }
  })

  return state
}

type AllowedActionTypes = ISuccessAction | ISimpleAction

const PostsReducer: Reducer<IPostsState, AllowedActionTypes> = (
  state = buildInitialState(),
  action: AllowedActionTypes,
) => {
  const simpleAction = action as ISimpleAction
  const successAction = action as ISuccessAction

  const currentPost = !IsNullOrUndefined(state.currentPostId)
    ? state.items.find((post) => post.id === state.currentPostId) ?? null
    : null

  switch (action.type) {
    case ActionTypes.SET_CURRENT_POST_ID: {
      const newState = _.cloneDeep(state)
      const { postId } = simpleAction.payload as IPostActionParams

      if (IsNullOrUndefined(postId)) {
        if (Object.hasOwnProperty.call(newState, 'currentPostId')) {
          const page = (newState as any).page ?? 0
          const pageSize = (newState as any).pageSize ?? 0
          if (page === 1 && newState.items.length > pageSize) {
            const currentPageIndex = newState.items.findIndex(
              (post) => post.id === currentPost?.id,
            )

            _.pullAt(newState.items, currentPageIndex)
          }
          delete newState.currentPostId
        }
      } else {
        newState.currentPostId = postId
      }

      return newState
    }
    case ActionTypes.CLEAR_POSTS: {
      const newState = _.cloneDeep(state)
      if (IsNullOrUndefined(newState.currentPostId)) {
        newState.items = []
      } else {
        newState.items = newState.items.filter(
          (post) => post.id !== newState.currentPostId,
        )
      }

      return newState
    }

    case actionTypeSuccess(ActionTypes.GET_POST_STATUSES): {
      const newState = _.cloneDeep(state)
      const statuses = successAction.data as IPostsState['statuses']

      _.forEach(state.statuses, (value, status) => {
        const responseStatus = statuses[status.toLowerCase()]

        if (!IsNullOrUndefined(responseStatus)) {
          newState.statuses[status] = responseStatus
        } else {
          newState.statuses[status] = {
            count: 0,
            hasNotifications: false,
          }
        }
      })

      return newState
    }

    case actionTypeSuccess(ActionTypes.GET_POSTS): {
      let newState = _.cloneDeep(state)
      const response = successAction.data as IPaginatedItems<IPost>
      const page = successAction.payload!.page

      const pageIsFirstOrNull = IsNullOrUndefined(page) || page === 1

      const currentPostIsNullOrResponseContainsCurrentPost =
        IsNullOrUndefined(currentPost) ||
        response.items.some((post) => post.id === currentPost!.id)

      if (pageIsFirstOrNull && currentPostIsNullOrResponseContainsCurrentPost) {
        newState = {
          ...newState,
          ...response,
          pageSize: response.pageSize ?? response.count,
        }
      } else {
        newState = {
          ...newState,
          ...response,
          items: mergeArrays(newState.items, response.items),
        }
      }
      return newState
    }

    case actionTypeSuccess(ActionTypes.GET_POST_BY_ID): {
      const newState = _.cloneDeep(state)
      const response = successAction.data as IPost
      const postIndex = state.items.findIndex((x) => x.id === response.id)

      if (postIndex < 0) {
        newState.items = [...newState.items, response]
      } else {
        newState.items[postIndex] = response
      }

      return newState
    }

    case actionTypeSuccess(ActionTypes.GET_AUTOCOMPLETE_SEARCH_POSTS): {
      const newState = _.cloneDeep(state)

      const response = successAction.data as IPaginatedItems<IPost>

      newState.items = mergeArrays(state.items, response.items)

      return newState
    }


    // Post Updates
    case actionTypeSuccess(ActionTypes.UPDATE_POST):
    case actionTypeSuccess(ActionTypes.UPLOAD_POST_IMAGE):
    case actionTypeSuccess(ActionTypes.UPDATE_POST_STATUS):
    case actionTypeSuccess(ActionTypes.SETTLE_POST_TICKETS):
    case actionTypeSuccess(ActionTypes.PROMOTE_POST):
    case actionTypeSuccess(ActionTypes.SET_POST_COMPROMISED): {
      const newState = _.cloneDeep(state)
      const response = successAction.data as IPost
      const postIndex = state.items.findIndex((post) => post.id === response.id)

      if (postIndex >= 0) {
        newState.items[postIndex] = response
      }

      return newState
    }

    // Questions
    case actionTypeSuccess(ActionTypes.ADD_QUESTION): {
      const newState = _.cloneDeep(state)
      const response = successAction.data as IPostQuestion
      const postIndex = state.items.findIndex(
        (post) => post.id === response.postId,
      )

      if (postIndex >= 0) {
        const post = _.cloneDeep(state.items[postIndex])

        post.questions = [...post.questions, response]

        newState.items[postIndex] = post

        return newState
      }

      return state
    }

    case actionTypeSuccess(ActionTypes.UPDATE_QUESTION):
    case actionTypeSuccess(ActionTypes.UPLOAD_QUESTION_IMAGE):
    case actionTypeSuccess(ActionTypes.SAVE_QUESTION_AND_OPTIONS): {
      const newState = _.cloneDeep(state)
      const response = successAction.data as IPostQuestion
      const postIndex = state.items.findIndex(
        (post) => post.id === response.postId,
      )

      if (postIndex >= 0) {
        const post = _.cloneDeep(state.items[postIndex])
        const questionIndex = post.questions.findIndex(
          (question) => question.id === response.id,
        )

        if (questionIndex >= 0) {
          post.questions[questionIndex] = response

          newState.items[postIndex] = post

          return newState
        }
      }

      return state
    }

    case actionTypeSuccess(ActionTypes.DELETE_QUESTION): {
      const newState = _.cloneDeep(state)
      const response = successAction.data as IPostQuestion
      const postIndex = state.items.findIndex(
        (post) => post.id === response.postId,
      )

      if (postIndex >= 0) {
        const post = _.cloneDeep(state.items[postIndex])
        const questionIndex = post.questions.findIndex(
          (question) => question.id === response.id,
        )

        if (questionIndex >= 0) {
          _.pullAt(post.questions, questionIndex)

          newState.items[postIndex] = post

          return newState
        }
      }

      return state
    }

    // Results
    case actionTypeSuccess(ActionTypes.ADD_RESULT): {
      const newState = _.cloneDeep(state)
      const response = successAction.data as IPostResult
      const postIndex = state.items.findIndex(
        (post) => post.id === response.postId,
      )

      if (postIndex >= 0) {
        const post = _.cloneDeep(state.items[postIndex])

        post.results = [...post.results, response]

        newState.items[postIndex] = post

        return newState
      }

      return state
    }

    case actionTypeSuccess(ActionTypes.UPDATE_RESULT):
    case actionTypeSuccess(ActionTypes.UPLOAD_RESULT_IMAGE): {
      const newState = _.cloneDeep(state)
      const response = successAction.data as IPostResult
      const postIndex = state.items.findIndex(
        (post) => post.id === response.postId,
      )

      if (postIndex >= 0) {
        const post = _.cloneDeep(state.items[postIndex])
        const resultIndex = post.results.findIndex(
          (result) => result.id === response.id,
        )

        if (resultIndex >= 0) {
          post.results[resultIndex] = response

          newState.items[postIndex] = post

          return newState
        }
      }

      return state
    }

    case actionTypeSuccess(ActionTypes.DELETE_RESULT): {
      const newState = _.cloneDeep(state)
      const response = successAction.data as IPostQuestion
      const postIndex = state.items.findIndex(
        (post) => post.id === response.postId,
      )

      if (postIndex >= 0) {
        const post = _.cloneDeep(state.items[postIndex])
        const resultIndex = post.results.findIndex(
          (result) => result.id === response.id,
        )

        if (resultIndex >= 0) {
          _.pullAt(post.results, resultIndex)

          newState.items[postIndex] = post

          return newState
        }
      }

      return state
    }

    default:
      return state
  }
}

export default PostsReducer
