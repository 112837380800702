import { PostGroupStatus } from 'fe-shared-resources'
import { useMemo, useState } from 'react'
import PostStatus from 'types/modules/posts/enums/post-status'
import { IPost } from 'types/modules/posts/models/entities/post'

interface Status {
  status: PostStatus | PostGroupStatus,
}

interface Params<T extends Status> {
  items: T[]
  shouldIgnoreItem?: (item: T) => boolean
}

enum StatusFilters {
  All = 'All',
  Active = 'Active',
  Settled = 'Settled',
  Others = 'Others',
}

const settledEquivalents = [
  PostStatus.Settled,
  PostGroupStatus.Settled
]

const activeEquivalents = [
  PostStatus.Active,
  PostGroupStatus.Active,
]

const statusOptions = Object.values(StatusFilters)

export function useStatusFilter<T extends Status>  ({
  items,
  shouldIgnoreItem
}: Params<T>) {
  const [status, setStatus] = useState(StatusFilters.Active)

  const { itemMap, countMap } = useMemo(() => {
    const countMap = {
      [StatusFilters.All]: 0,
      [StatusFilters.Active]: 0,
      [StatusFilters.Settled]: 0,
      [StatusFilters.Others]: 0
    }
    const itemMap: {
      [StatusFilters.All]: T[],
      [StatusFilters.Active]: T[],
      [StatusFilters.Settled]: T[],
      [StatusFilters.Others]: T[]
    } = {
      [StatusFilters.All]: [],
      [StatusFilters.Active]: [],
      [StatusFilters.Settled]: [],
      [StatusFilters.Others]: []
    }
    items.forEach((item: T) => {
      if (shouldIgnoreItem && shouldIgnoreItem(item)) return
      countMap.All = countMap.All + 1
      itemMap.All.push(item)
      if (settledEquivalents.includes(item.status)) {
        countMap.Settled = countMap.Settled + 1
        itemMap.Settled.push(item)
      } else if (activeEquivalents.includes(item.status)) {
        countMap.Active = countMap.Active + 1
        itemMap.Active.push(item)
      } else {
        countMap.Others = countMap.Others + 1
        itemMap.Others.push(item)
      }
    })
    return { countMap, itemMap }
  }, [items, shouldIgnoreItem])

  return {
    filtered: itemMap[status],
    statusOptions,
    status,
    countMap,
    onSelect: (val: string) => setStatus(val as StatusFilters)
  }
}