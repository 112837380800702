import { Fade, Modal, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Panel } from 'components/panel'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { ManagePrizePanel } from 'modules/prizes/views/components/manage-prize-panel'
import React from 'react'
import { IPrize } from 'types/modules/prizes/models/entities/prize'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 40,
    },
  }),
)

interface IManagePrizeModalProps {
  currentPrize: Partial<IPrize> | null
}

export const ManagePrizeModal: React.FC<IManagePrizeModalProps> = (
  props: IManagePrizeModalProps,
) => {
  const classes = useStyles()

  return (
    <div>
      <Modal
        className={classes.modal}
        open={!IsNullOrUndefined(props.currentPrize)}
      >
        <Fade in={!IsNullOrUndefined(props.currentPrize)}>
          <Panel
            xs={12}
            style={{
              display: 'flex',
              flex: 1,
              height: '100%',
            }}
          >
            <ManagePrizePanel isModal />
          </Panel>
        </Fade>
      </Modal>
    </div>
  )
}
