import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { Reducer } from 'redux'
import { ISimpleAction, ISuccessAction } from 'types/redux/interfaces/IAction'
import _ from 'core/utils/deepdash'
import { actionTypeSuccess, cacheItem, cacheItems } from 'core/redux/utils'
import IPaginatedItems from 'types/common/pagination/models/entities/paginated-items'
import { mergeArrays } from 'core/utils/mergeArrays'
import { IUserPrizeState } from 'types/modules/shipping/models/state/user-prize-state'
import { UserPrizeStatus } from 'types/modules/shipping/enums/user-prize-status'
import { ActionTypes } from 'modules/shipping'
import { IShippingActionParams } from 'modules/shipping/actions'
import { IUserPrize } from 'types/modules/shipping/models/entities/user-prize'
import { IUserPrizeHistory } from 'types/modules/shipping/models/entities/user-prize-history'

const initialState = (): IUserPrizeState => {
  const state: IUserPrizeState = {
    items: [],
    statuses: {},
    cache: {},
  }

  Object.values(UserPrizeStatus).forEach((value) => {
    state.statuses[value] = 0
  })

  return state
}

type AllowedActionTypes = ISuccessAction | ISimpleAction

const ShippingReducer: Reducer<IUserPrizeState, AllowedActionTypes> = (
  state = initialState(),
  action: AllowedActionTypes,
) => {
  const simpleAction = action as ISimpleAction
  const successAction = action as ISuccessAction

  const currentUserPrize = !IsNullOrUndefined(state.currentUserPrizeId)
    ? state.items.find((userPrize) => userPrize.id === state.currentUserPrizeId)
    : null

  switch (action.type) {
    case ActionTypes.SET_CURRENT_USER_PRIZE_ID: {
      const newState = _.cloneDeep(state)

      const { userPrizeId } = simpleAction.payload as IShippingActionParams

      if (IsNullOrUndefined(userPrizeId)) {
        if (Object.hasOwnProperty.call(newState, 'currentUserPrizeId')) {
          const page = (newState as any).page ?? 0
          const pageSize = (newState as any).pageSize ?? 0
          if (page === 1 && newState.items.length > pageSize) {
            const currentPageIndex = newState.items.findIndex(
              (userPrize) => userPrize.id === currentUserPrize?.id,
            )

            _.pullAt(newState.items, currentPageIndex)
          }
          delete newState.currentUserPrizeId
        }
      } else {
        newState.currentUserPrizeId = userPrizeId
      }

      return newState
    }

    case ActionTypes.CLEAR_USER_PRIZES: {
      const newState = _.cloneDeep(state)

      if (IsNullOrUndefined(newState.currentUserPrizeId)) {
        newState.items = []
      } else {
        newState.items = newState.items.filter(
          (userPrize) => userPrize.id !== newState.currentUserPrizeId,
        )
      }

      return newState
    }

    case actionTypeSuccess(ActionTypes.GET_USER_PRIZE_STATUSES): {
      const newState = _.cloneDeep(state)
      const statuses = successAction.data as IUserPrizeState['statuses']

      _.forEach(state.statuses, (value, status) => {
        const responseStatus = statuses[status.toLowerCase()]

        if (!IsNullOrUndefined(responseStatus)) {
          newState.statuses[status] = responseStatus
        } else {
          newState.statuses[status] = 0
        }
      })

      newState.statuses[UserPrizeStatus.ReadyToShip] = statuses.readyToShip ?? 0

      return newState
    }

    case actionTypeSuccess(ActionTypes.GET_USER_PRIZES): {
      let newState = _.cloneDeep(state)
      const response = successAction.data as IPaginatedItems<IUserPrize>
      const page = successAction.payload!.page

      const pageIsFirstOrNull = IsNullOrUndefined(page) || page === 1

      const currentUserPrizeIsNullOrResponseContainsCurrentPrize =
        IsNullOrUndefined(currentUserPrize) ||
        response.items.some(
          (userPrize) => userPrize.id === currentUserPrize!.id,
        )

      if (
        pageIsFirstOrNull &&
        currentUserPrizeIsNullOrResponseContainsCurrentPrize
      ) {
        newState = {
          ...newState,
          ...response,
          pageSize: response.pageSize ?? response.count,
          cache: cacheItems(state.cache, response.items),
        }
      } else {
        newState = {
          ...newState,
          ...response,
          items: mergeArrays(state.items, response.items),
          cache: cacheItems(state.cache, response.items),
        }
      }
      return newState
    }

    // case actionTypeSuccess(ActionTypes.GET_USER_PRIZE_BY_ID): {
    //   const newState = _.cloneDeep(state);
    //   const response = successAction.data as IUserPrize;
    //   const userPrizeIndex = state.items.findIndex(x => x.id === response.id);

    //   if (userPrizeIndex < 0) {
    //     newState.items = [...newState.items, response];
    //   }
    //   else {
    //     newState.items[userPrizeIndex] = response;
    //   }

    //   newState.cache = cacheItem(state.cache, response);

    //   return newState;
    // }

    case actionTypeSuccess(ActionTypes.UPDATE_USER_PRIZE_STATUS): {
      const newState = _.cloneDeep(state)
      const response = successAction.data as IUserPrize

      const userPrizeIndex = state.items.findIndex(
        (userPrize) => userPrize.id === response.id,
      )

      if (userPrizeIndex >= 0) {
        newState.items[userPrizeIndex] = response
      }

      newState.cache = cacheItem(state.cache, response)

      return newState
    }

    case actionTypeSuccess(ActionTypes.CREATE_USER_PRIZE_NOTE): {
      const newState = _.cloneDeep(state)
      const response = successAction.data as IUserPrizeHistory

      const userPrizeIndex = state.items.findIndex(
        (userPrize) => userPrize.id === response.userPrizeId,
      )

      if (userPrizeIndex >= 0) {
        const newUserPrize = newState.items[userPrizeIndex]

        newUserPrize.userPrizeHistories.push(response)

        newState.items[userPrizeIndex] = newUserPrize

        newState.cache = cacheItem(state.cache, newUserPrize)

        return newState
      }

      return state
    }

    default:
      return state
  }
}

export default ShippingReducer
