import React, { ReactElement } from 'react'
import { IDashboardViewProps } from 'components/content-dashboard/props/dashboard-view-props'
import { Box, Button, ButtonGroup, Portal, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import SearchAndSortToolbar from 'components/panel/toolbars/search+sort-toolbar'
import { SortDirection } from 'types/common/enums/sort-direction'
import { SortValue } from 'types/common/sorting/models/entities/sort-value'
import Calendar from 'components/calendar'
import { useCalendarContext } from 'components/calendar/context/calendar-context'
import { CalendarViewMode } from 'components/calendar/types/enums/calendar-view-mode'
import { ICalendarViewProps } from 'components/content-dashboard/props/calendar-view-props'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    calendarNavigation: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
    },
    timeframeButtonGroup: {
      display: 'flex',
      width: 250,
      height: 40,
      marginBottom: '0.625rem',
    },
    timeframeButton: {
      display: 'flex',
      fontSize: '0.7rem',
      flex: 1,
    },
    dateSelectorButtonGroup: {
      display: 'flex',
      width: 250,
      height: 40,
    },
    dateSelectorNavButton: {
      display: 'flex',
      minWidth: 0,
      padding: 7,
      boxSizing: 'border-box',
      color: 'rgba(0,0,0,0.54)',
    },
    icon: {
      display: 'flex',
      width: 24,
      height: 24,
    },
    resultCountContainer: {
      display: 'flex',
      height: 40,
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
  }),
)

export function CalendarView<TModel>(
  props: ICalendarViewProps<TModel> & IDashboardViewProps,
): ReactElement<any, any> {
  const {
    viewMode,
    currentTimeFrameLabel,
    changeViewMode,
    goToNext,
    goToPrevious,
  } = useCalendarContext()

  const classes = useStyles()

  const renderToolbarComponent = (): JSX.Element | null => {
    const ToolbarComponent = props.toolbarComponent

    return !IsNullOrUndefined(ToolbarComponent) ? (
      <Portal container={props.dynamicToolbarRef}>{ToolbarComponent}</Portal>
    ) : null
  }

  return (
    <React.Fragment>
      {renderToolbarComponent()}
      <Portal container={props.dynamicHeaderPanelRef}>
        <Box className={classes.calendarNavigation}>
          <ButtonGroup
            disableElevation
            className={classes.timeframeButtonGroup}
          >
            {Object.values(CalendarViewMode).map((mode) => {
              const isActive = viewMode === mode

              return (
                <Button
                  key={`view-mode-btn-${mode}`}
                  variant={isActive ? 'contained' : 'outlined'}
                  color={isActive ? 'primary' : 'inherit'}
                  className={classes.timeframeButton}
                  onClick={(): void => changeViewMode(mode)}
                >
                  {mode}
                </Button>
              )
            })}
          </ButtonGroup>
          <ButtonGroup
            disableElevation
            className={classes.dateSelectorButtonGroup}
          >
            <Button
              variant="outlined"
              className={classes.dateSelectorNavButton}
              onClick={(): void => goToPrevious()}
            >
              <i className={classes.icon}>
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M15 19l-7-7 7-7"
                  ></path>
                </svg>
              </i>
            </Button>
            <Button variant="outlined" className={classes.timeframeButton}>
              {currentTimeFrameLabel()}
            </Button>
            <Button
              variant="outlined"
              className={classes.dateSelectorNavButton}
              onClick={(): void => goToNext()}
            >
              <i className={classes.icon}>
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 5l7 7-7 7"
                  ></path>
                </svg>
              </i>
            </Button>
          </ButtonGroup>
        </Box>
        {!IsNullOrUndefined(props.resultCount) && (
          <Box className={classes.resultCountContainer}>
            <Typography variant="body2" color="textPrimary" component="span">
              Total Results =
            </Typography>
            <Typography
              variant="h6"
              color="textPrimary"
              component="span"
              style={{
                marginLeft: 5,
              }}
            >
              {`${props.resultCount}`}
            </Typography>
          </Box>
        )}
      </Portal>
      <Calendar
        timeGridCellComponent={props.timeGridCellComponent}
        dateGridCellComponent={props.dateGridCellComponent}
        headerActionButton={props.headerActionButton}
        data={props.data!}
      />
    </React.Fragment>
  )
}
