const paths = {
  postGroups: 'post-groups',
  images: 'images',
  upload: 'upload',
  inactive: 'inactive',
  active: 'active',
  posts: 'posts',
  settle: 'settle'
}

export default paths
