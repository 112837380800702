/* Libs */
import {
  all,
  AllEffect,
  ForkEffect,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects'

/* Module */
import { ActionTypes } from '../action-types'
import { GetChestsSagas } from './get-chests-saga'

type RootSagaReturnType = Generator<
  ForkEffect<never> | AllEffect<any>,
  void,
  any
>

export const RootSaga = function* (): RootSagaReturnType {
  yield all([
    yield takeEvery(ActionTypes.GET_CHESTS, GetChestsSagas),
    yield takeEvery(ActionTypes.GET_CHEST, GetChestsSagas),
    yield takeEvery(ActionTypes.CREATE_CHEST, GetChestsSagas),
    yield takeEvery(ActionTypes.UPDATE_CHEST, GetChestsSagas),
    yield takeEvery(ActionTypes.GET_AUTOCOMPLETE_SEARCH_CHESTS, GetChestsSagas),
    yield takeEvery(ActionTypes.UPLOAD_CHEST_IMAGE, GetChestsSagas),
  ])
}
