import QuestEndpoints from 'core/api/endpoints/quests'
import QuestActionTypes from 'modules/quests/action-types/quest-action-types'
import { createAction, createAsyncAction } from 'core/redux/utils'
import { IAsyncAction, ISimpleAction } from 'types/redux/interfaces/IAction'
import { IAsyncActionParams } from 'types/common/api/models/entities/async-action-params'
import { IQuest } from 'modules/quests/models/quest'
import { IQuestGroup } from 'modules/quests/models/quest-group'

export interface IQuestActionParams extends IAsyncActionParams<IQuest> {
  questId?: string
}

export interface IQuestGroupActionParams extends IAsyncActionParams<IQuestGroup> {
  questGroupId?: string
}

export const setCurrentQuestId: (params: IQuestActionParams) => ISimpleAction = (
  params,
) => createAction(QuestActionTypes.SET_CURRENT_QUEST_ID, params)

export const getQuests: (params: IQuestActionParams) => IAsyncAction = (params) =>
  createAsyncAction({ type: QuestActionTypes.GET_QUESTS, endpoint: QuestEndpoints.getQuests, payload: params})

export const getQuestById: (params: IQuestActionParams) => IAsyncAction = (
  params,
) =>
  createAsyncAction({
    type: QuestActionTypes.GET_QUEST_BY_ID,
    endpoint: QuestEndpoints.getQuestById,
    payload: params,
  })

export const createQuest: (params: IQuestActionParams) => IAsyncAction = (params) =>
  createAsyncAction({ type: QuestActionTypes.CREATE_QUEST,  endpoint: QuestEndpoints.createQuest, payload: params})

export const createQuestDraft: (params: IQuestActionParams) => ISimpleAction = (
  params,
) => createAction(QuestActionTypes.CREATE_QUEST_DRAFT, params)

export const updateQuest: (params: IQuestActionParams) => IAsyncAction = (params) =>
  createAsyncAction({ type: QuestActionTypes.UPDATE_QUEST, endpoint: QuestEndpoints.updateQuest, payload: params})

export const uploadQuestImage: (params: IQuestActionParams) => IAsyncAction = (
  params,
) =>
  createAsyncAction({
    type: QuestActionTypes.UPLOAD_QUEST_IMAGE,
    endpoint: QuestEndpoints.uploadQuestImage,
    payload: params,
  })

export const activateQuest: (params: IQuestActionParams) => IAsyncAction = (params) =>
  createAsyncAction({ type: QuestActionTypes.ACTIVATE_QUEST, endpoint: QuestEndpoints.activateQuest, payload: params})


export const deactivateQuest: (params: IQuestActionParams) => IAsyncAction = (params) =>
  createAsyncAction({ type: QuestActionTypes.DEACTIVATE_QUEST, endpoint: QuestEndpoints.deactivateQuest, payload: params})
