import { Box, SvgIcon, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx'
import {
  ArrowRightIcon,
  CalendarStarIcon,
  CircleUserIcon,
  CoinIcon,
  HomeIcon,
  SearchIcon,
  SparklesIcon,
  StarIcon,
} from 'components/icons'
import { PreviewContainer } from 'components/preview'
import {
  IsNullOrUndefined,
  IsNullUndefinedOrEmpty,
} from 'core/utils/isNullOrUndefined'
import moment from 'moment-timezone'
import React from 'react'
import { IPrize } from 'types/modules/prizes/models/entities/prize'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loyaltyStoreContainer: {
      position: 'relative',
      display: 'flex',
      width: '100%',
      height: '100%',
      flexDirection: 'column',
      overflow: 'hidden',
    },
    loyaltyFeedHeader: {
      display: 'flex',
      width: '100%',
      height: 240,
      padding: 20,
      flexDirection: 'column',
      justifyContent: 'flex-end',
      alignItems: 'center',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
    },
    prizeCoverContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: '100%',
      height: 'auto',
      minHeight: 335,
      borderRadius: 12,
      padding: 20,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
    },
    headingAndLogo: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    featuredLogoBanner: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      justifyContent: 'flex-start',
      alignItems: 'center',
      height: 20,
    },
    featuredItemLogo: {
      display: 'flex',
      maxHeight: '100%',
      maxWidth: '40%',
      marginLeft: 6,
      objectFit: 'contain',
    },
    feedWrapper: {
      display: 'flex',
      overflow: 'hidden',
      bottom: 0,
      width: '100%',
      height: '100%',
      borderRadius: '15px 15px 0 0',
      background: 'white',
      padding: 20,
      flexDirection: 'column',
    },
    feedContentWrapper: {
      display: 'flex',
      flex: 1,
    },
    prizeCard: {
      display: 'flex',
      width: '100%',
      height: 450,
      borderRadius: 15,
      background: 'rgba(0,0,0,0.03)',
      flexDirection: 'column',
      padding: 12,
    },
    prizeCardTopSection: {
      position: 'relative',
      display: 'flex',
      width: '100%',
      height: '100%',
      justifyContent: 'center',
    },
    prizeType: {
      display: 'flex',
      background: '#FFFFFF',
      borderRadius: 3,
      padding: '0.2rem 0.5rem',
      height: 'max-content',
      position: 'absolute',
      top: 0,
      left: 0,
      justifyContent: 'center',
      alignItems: 'center',
    },
    prizeCardImageContainer: {
      display: 'flex',
      width: 180,
      height: '100%',
      backgroundSize: 'contain',
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      borderRadius: 15,
    },
    prizeCardLogoImageContainer: {
      display: 'flex',
      width: 70,
      height: 40,
      position: 'absolute',
      top: 0,
      right: 0,
      backgroundSize: 'contain',
      backgroundPosition: 'top center',
      backgroundRepeat: 'no-repeat',
    },
    prizeCardDetails: {
      display: 'flex',
      width: '100%',
      background: 'white',
      borderRadius: 10,
      marginTop: 10,
      flexDirection: 'column',
      justifyContent: 'space-between',
      padding: 20,
    },
    prizeCardMeta: {
      display: 'flex',
      width: '100%',
      height: 25,
    },
    prizeCountdown: {
      display: 'flex',
      width: 50,
      height: '100%',
      border: '1px solid rgba(0,0,0,0.12)',
      borderRadius: 7,
      marginRight: 5,
      justifyContent: 'center',
      alignItems: 'center',
    },
    prizeStarCost: {
      display: 'flex',
      width: 40,
      height: '100%',
      background: 'rgba(0,0,0,0.03)',
      borderRadius: 7,
      marginRight: 5,
      justifyContent: 'center',
      alignItems: 'center',
    },
    prizeCoinCost: {
      display: 'flex',
      width: 40,
      height: '100%',
      background: 'rgba(0,0,0,0.03)',
      borderRadius: 7,
      justifyContent: 'center',
      alignItems: 'center',
    },
    rightArrow: {
      marginLeft: 'auto',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 25,
      width: 25,
    },
    navigationBar: {
      width: 'calc(100% - 40px)',
      marginLeft: 20,
      marginRight: 20,
      position: 'absolute',
      bottom: 30,
      height: 90,
      borderRadius: 20,
      backgroundColor: '#ff787d',
      padding: 5,
      display: 'flex',
      flexDirection: 'row',
    },
    navigationItem: {
      display: 'flex',
      flex: 1,
      borderRadius: 20,
      color: '#ffffff',
      justifyContent: 'center',
      alignItems: 'center',
    },
    activeNavItem: {
      background: 'rgba(255,255,255,0.2)',
    },
  }),
)

interface IPrizeFeedPreviewProps {
  prize: Partial<IPrize>
}

export const PrizeFeedPreview: React.FC<IPrizeFeedPreviewProps> = ({
  prize,
}: IPrizeFeedPreviewProps) => {
  const classes = useStyles()

  const hasFeaturedImage = !IsNullUndefinedOrEmpty(
    prize.item?.details?.featuredImageUrl,
  )
  const featuredImageContainerStyles: { [key: string]: string | number } = {}

  if (hasFeaturedImage) {
    featuredImageContainerStyles.backgroundImage = `url(${prize.item?.details!
      .featuredImageUrl!})`
  } else {
    featuredImageContainerStyles.background = 'rgba(0,0,0,0.03)'
  }

  const hasLogoImage = !IsNullUndefinedOrEmpty(
    prize.item?.details?.logoImageUrl,
  )

  const remainingTime = !IsNullOrUndefined(prize.end)
    ? moment.duration(moment(prize.end!).diff(moment()))
    : null

  const formattedRemainingTime = !IsNullOrUndefined(remainingTime)
    ? remainingTime!.asMinutes() <= 60
      ? `${remainingTime!.asMinutes().toFixed()}m`
      : remainingTime!.asHours() <= 24
      ? `${remainingTime!.asHours().toFixed()}h`
      : `${remainingTime!.asDays().toFixed()}d`
    : null

  return (
    <PreviewContainer withDevice={true}>
      <Box className={classes.loyaltyStoreContainer}>
        <Box
          className={classes.loyaltyFeedHeader}
          style={featuredImageContainerStyles}
        >
          <Box className={classes.headingAndLogo}>
            <Typography
              variant={'h4'}
              color={'textPrimary'}
              style={{
                fontWeight: 600,
                color: '#ffffff',
              }}
            >
              Loyalty Store
            </Typography>
            {hasLogoImage && (
              <Box className={classes.featuredLogoBanner}>
                <Typography
                  variant={'body1'}
                  color={'textPrimary'}
                  style={{
                    fontSize: 14,
                    fontWeight: 600,
                    color: '#ffffff',
                  }}
                >
                  Featuring
                </Typography>
                <img
                  className={classes.featuredItemLogo}
                  src={prize.item?.details?.logoImageUrl}
                />
              </Box>
            )}
          </Box>
        </Box>
        <Box className={classes.feedWrapper}>
          <Box className={classes.feedContentWrapper}>
            <Box className={classes.prizeCard}>
              <Box className={classes.prizeCardTopSection}>
                <Box className={classes.prizeType}>
                  <Typography
                    variant={'body1'}
                    style={{
                      fontSize: '0.8rem',
                      fontWeight: 600,
                    }}
                  >
                    {prize.type}
                  </Typography>
                </Box>
                <Box
                  className={classes.prizeCardImageContainer}
                  style={{
                    backgroundImage: `url(${prize.item?.details!.imageUrl!})`,
                  }}
                />
                <Box
                  className={classes.prizeCardLogoImageContainer}
                  style={{
                    backgroundImage: `url(${prize.item?.details!
                      .logoImageUrl!})`,
                  }}
                />
              </Box>
              <Box className={classes.prizeCardDetails}>
                <Typography
                  variant={'body1'}
                  style={{
                    fontSize: 20,
                    color: 'black',
                    marginBottom: '-5px',
                    fontWeight: 600,
                  }}
                >
                  {prize.item?.name ?? ''}
                </Typography>
                <Typography
                  variant={'body1'}
                  style={{
                    fontSize: 16,
                    color: 'black',
                    marginBottom: '-3px',
                    fontWeight: 600,
                  }}
                >
                  {prize.item?.details?.description ?? ''}
                </Typography>
                <Typography
                  variant={'body1'}
                  style={{
                    fontSize: 16,
                    color: 'rgba(0,0,0,0.54)',
                    fontWeight: 600,
                  }}
                >
                  {prize.chestId
                    ? prize.item?.details?.brand ?? ''
                    : prize.chest?.description}
                </Typography>
                {!prize.chestId && (
                  <Box className={classes.prizeCardMeta}>
                    {formattedRemainingTime && (
                      <Box className={classes.prizeCountdown}>
                        <Typography
                          variant={'body1'}
                          style={{
                            color: 'blue',
                            fontSize: 8,
                            fontWeight: 400,
                          }}
                        >
                          {`⌛ ${formattedRemainingTime} left`}
                        </Typography>
                      </Box>
                    )}
                    {!IsNullOrUndefined(prize.starCost) &&
                      prize.starCost! > 0 && (
                        <Box className={classes.prizeStarCost}>
                          <Typography
                            variant={'body1'}
                            style={{
                              fontSize: 14,
                              fontWeight: 400,
                              marginRight: 2,
                            }}
                          >
                            {`${prize.starCost}`}
                          </Typography>
                          <i style={{ width: 15, height: 15 }}>
                            <StarIcon />
                          </i>
                        </Box>
                      )}
                    {!IsNullOrUndefined(prize.coinCost) &&
                      prize.coinCost! > 0 && (
                        <Box className={classes.prizeCoinCost}>
                          <Typography
                            variant={'body1'}
                            style={{
                              fontSize: 8,
                              fontWeight: 400,
                              marginRight: 2,
                            }}
                          >
                            {`${prize.coinCost}`}
                          </Typography>
                          <i style={{ width: 10, height: 10 }}>
                            <CoinIcon />
                          </i>
                        </Box>
                      )}
                    <Box className={classes.rightArrow}>
                      <SvgIcon style={{ fontSize: 15 }}>
                        <ArrowRightIcon />
                      </SvgIcon>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={classes.navigationBar}>
          <Box className={clsx(classes.navigationItem, classes.activeNavItem)}>
            <SvgIcon>
              <SparklesIcon />
            </SvgIcon>
          </Box>
          <Box className={classes.navigationItem}>
            <SvgIcon>
              <SearchIcon />
            </SvgIcon>
          </Box>
          <Box className={classes.navigationItem}>
            <SvgIcon>
              <HomeIcon />
            </SvgIcon>
          </Box>
          <Box className={classes.navigationItem}>
            <SvgIcon>
              <CalendarStarIcon />
            </SvgIcon>
          </Box>
          <Box className={classes.navigationItem}>
            <SvgIcon>
              <CircleUserIcon />
            </SvgIcon>
          </Box>
        </Box>
      </Box>
    </PreviewContainer>
  )
}
