import NewsCardEndpoints from 'core/api/endpoints/news-cards'
import NewsCardActionTypes from 'modules/news-cards/action-types/news-card-action-types'
import { createAction, createAsyncAction } from 'core/redux/utils'
import { IAsyncAction, ISimpleAction } from 'types/redux/interfaces/IAction'
import { IAsyncActionParams } from 'types/common/api/models/entities/async-action-params'
import { INewsCard } from 'modules/news-cards/models/news-card'

export interface INewsCardActionParams
  extends IAsyncActionParams<INewsCard> {
  newsCardId?: string
  newsCardLevelId?: string
}

export const setCurrentNewsCardId: (
  params: INewsCardActionParams,
) => ISimpleAction = (params) =>
  createAction(NewsCardActionTypes.SET_CURRENT_NEWS_CARD_ID, params)

export const getNewsCards: (
  params: INewsCardActionParams,
) => IAsyncAction = (params) =>
  createAsyncAction({
    type: NewsCardActionTypes.GET_NEWS_CARDS,
    endpoint: NewsCardEndpoints.getNewsCards,
    payload: params,
  })

export const getNewsCardById: (
  params: INewsCardActionParams,
) => IAsyncAction = (params) =>
  createAsyncAction({
    type: NewsCardActionTypes.GET_NEWS_CARD_BY_ID,
    endpoint: NewsCardEndpoints.getNewsCardById,
    payload: params,
  })

export const createNewsCardDraft: (
  params: INewsCardActionParams,
) => ISimpleAction = (params) =>
  createAction(NewsCardActionTypes.CREATE_NEWS_CARD_DRAFT, params)

export const createNewsCard: (
  params: INewsCardActionParams,
) => IAsyncAction = (params) =>
  createAsyncAction({
    type: NewsCardActionTypes.CREATE_NEWS_CARD,
    endpoint: NewsCardEndpoints.createNewsCard,
    payload: params,
  })

export const updateNewsCard: (
  params: INewsCardActionParams,
) => IAsyncAction = (params) =>
  createAsyncAction({
    type: NewsCardActionTypes.UPDATE_NEWS_CARD,
    endpoint: NewsCardEndpoints.updateNewsCard,
    payload: params,
  })

export const uploadNewsCardImage: (params: INewsCardActionParams) => IAsyncAction = (
  params,
) =>
  createAsyncAction({
    type: NewsCardActionTypes.UPLOAD_NEWS_CARD_IMAGE,
    endpoint: NewsCardEndpoints.uploadNewsCardImage,
    payload: params,
  })

export const deleteNewsCard: (
  params: INewsCardActionParams,
) => IAsyncAction = (params) =>
  createAsyncAction({
    type: NewsCardActionTypes.DELETE_NEWS_CARD,
    endpoint: NewsCardEndpoints.deleteNewsCardById,
    payload: params,
  })