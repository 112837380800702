import {
  Box,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Theme,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { IapCode, PostAcceptedCurrency, PrizeType } from 'fe-shared-resources'
import {
  IsNullOrUndefined,
  IsNullUndefinedOrEmpty,
} from 'core/utils/isNullOrUndefined'
import moment from 'moment-timezone'
import React from 'react'
import { Tag } from 'types/modules/tags/models/entities/tag'
import { CoinIcon, StarIcon } from 'components/icons'
import { IPostDetailsFormProps } from 'modules/posts/views/components/manage-post-panel/types/post-details-form-props'
import { PostField } from 'types/modules/posts/enums/post-field'
import { getIn } from 'formik'
import { PostDifficulty } from 'types/modules/posts/enums/post-difficulty'
import { MediaUploadModel } from 'types/common/images/models/entities/image-upload-model'
import { Selectors as EventSelectors } from 'modules/events'
import PostType from 'types/modules/posts/enums/post-type'
import { AutocompleteSearchSelect } from 'components/controls/inputs/downshift/autocomplete/autocomplete-search-select'
import { Selectors as PrizeSelectors } from 'modules/prizes'
import { IPrize } from 'types/modules/prizes/models/entities/prize'
import _ from 'core/utils/deepdash'
import { DisbaleMultibetConfirmationPopover } from 'modules/posts/views/components/disable-multibet-confirmation-popover'
import { IEvent } from 'types/modules/events/models/entities/event'
import { useTagsContext } from 'modules/tags/context'
import { ManageTagModal } from 'modules/tags/views/manage-tag-panel/modal'
import { MediaItemInput } from 'modules/media-items/media-item-input'
import { AspectRatio } from 'types/common/images/enum/aspect-ratio'
import { MediaOrientation } from 'types/common/images/enum/media-orientation'
import { MediaItem } from 'types/modules/media-items/models/entities/media-item'
import { createErrors } from 'utils/validation-helper'
import { Duration } from 'components/duration'
import { searchPrizes } from 'modules/prizes/utils/get-search-filter-option'
import { LabelWithCharLimit } from 'modules/posts/views/components/manage-post-panel/label-with-char-limit'
import { MAX_TITLE_LENGTH } from 'modules/posts/constants'
import { PostPricingModel, PostSettlePeriod } from 'types/modules/posts/models/entities/post-details'
import { usePostListContext } from 'modules/posts/context/post-list'
import { usePostManagerContext } from 'modules/posts/context/post-manager'
import { SaveBeforeWarning } from 'components/save-before-warning'
import { usePostFormHelper } from 'modules/posts/hooks/use-post-form-helper'
import { RegionsSelect } from 'components/regions-select'
import { AppState } from 'types/redux/types/app-state'
import { useTagSelect } from 'hooks/use-tag-select'
import PrizeStatus from 'types/modules/prizes/enums/prize-status'
import { DateTimePicker } from '@mui/x-date-pickers';
import { PostGroupsSelect } from 'components/post-groups-select';
import { FormGen, FormTypes } from 'components/simple-form-gen';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    postDetailsForm: {
      width: '100%',
      height: 'auto',
      '& .MuiGrid-item': {
        display: 'flex',
        flexDirection: 'column',
      },
    },
    inputLabel: {
      display: 'flex',
      marginBottom: '0.625rem',
    },
    switches: {
      display: 'flex',
      flex: 1,
      justifyContent: 'space-between',
    },
    switchLabel: {
      display: 'flex',
      marginBottom: '0.625rem',
    },
    coverImageContainer: {
      display: 'flex',
      flex: 1,
      width: '100%',
      height: '100%',
      background: 'rgba(0,0,0,0.03)',
      color: 'rgba(0,0,0,0.54)',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      justifyContent: 'center',
      alignItems: 'center',
    },
    adornmentIcon: {
      display: 'flex',
      width: 24,
      height: 24,
      marginRight: 12,
    },
  }),
)

const settlePeriodOptions = Object.values(PostSettlePeriod).map((settlePeriod) => ({
  value: settlePeriod,
  name: settlePeriod,
}))

const acceptedCurrencyOptions = Object.values(PostAcceptedCurrency).map((currency) => ({
  value: currency,
  name: currency,
}))

export const PostDetailsView: React.FC<IPostDetailsFormProps> = (
  props: IPostDetailsFormProps,
) => {
  const { post, postDetails, handleChange, setFieldValue, errors, setValues } = props
  const { currentPost, postGroupId } = usePostListContext()
  const { uploadPostImage, updatePostMedia } = usePostManagerContext()
  const { currentTag, setCurrentTag } = useTagsContext()
  const formErrors = createErrors(errors)
  const classes = useStyles()
  const {
    isCreateMode,
    setStartCloseDate,
    setDifficulty,
    disableMultibet,
    useDisableMultibetPopover,
    searchTags,
    selectedEvent,
    searchEvents,
    selectedPrize,
    getPostPrizeMaxQuantity,
    liveSchedules
  } = usePostFormHelper({ postDetails, post, setFieldValue, setValues })
  const tagSelect = useTagSelect({
    hasCreateOption: true,
    initiallySelectedTags: postDetails.tags as Tag[],
    onSelect: (selectedTags) => {
      const startDate = moment(postDetails[PostField.START])
      if (
        selectedTags.some((tag) => tag.code === 'we-asked-100') &&
        !(post.tags as Tag[]).some(
          (tag) => tag.code === 'we-asked-100',
        ) &&
        !selectedTags.some((tag) => tag.code === 'daily')
      ) {
        const endDate = startDate.add(1, 'week').toISOString()
        setStartCloseDate(PostField.CLOSE, endDate, false)
      }

      if (
        selectedTags.some((tag) => tag.code === 'daily') &&
        !(post.tags as Tag[]).some((tag) => tag.code === 'daily')
      ) {
        const endDate = startDate.add(1, 'day').toISOString()
        setStartCloseDate(PostField.CLOSE, endDate)
      }

      setFieldValue(PostField.TAGS, selectedTags)
    },
    onClickTag: (tagId: string) => setCurrentTag(tagId),
  })

  const settlePeriod = {
    name: 'settlePeriod',
    label: 'Settle Period',
    value: postDetails?.settlePeriod,
    type: FormTypes.SELECT,
    options: settlePeriodOptions,
    hideInitialOption: true,
    otherProps: {
      error: !!errors?.settlePeriod,
      helperText: errors?.settlePeriod,
    },
    handleChange: (val: any) => {
      if (val.target.value === PostSettlePeriod.DAILY) {
        setFieldValue('close', undefined)
      }
      handleChange(val)
    }
  }

  const acceptedCurrency = {
    name: 'acceptedCurrency',
    label: 'Accepted Currency',
    value: postDetails?.acceptedCurrency,
    type: FormTypes.SELECT,
    options: acceptedCurrencyOptions,
    hideInitialOption: true,
    otherProps: {
      error: !!errors?.acceptedCurrency,
      helperText: errors?.acceptedCurrency,
    },
    handleChange
  }

  return (
    <React.Fragment>
      <Box className={classes.postDetailsForm}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <InputLabel className={classes.inputLabel}>Cover Image</InputLabel>
            <MediaItemInput
              disabled={isCreateMode}
              url={currentPost?.media?.imageUrl || ''}
              imageLabel={'Cover Image'}
              mediaItemId={currentPost?.media?.mediaItemId}
              options={{
                aspectRatio:
                  post.type !== PostType.Poll
                    ? AspectRatio.Standard
                    : AspectRatio.Wide,
                orientation: MediaOrientation.Portrait,
              }}
              onComplete={(fileParams: MediaUploadModel): void => {
                uploadPostImage(post.id, fileParams)
              }}
              onCreate={(mediaItem: MediaItem): void => {
                updatePostMedia(mediaItem)
              }}
            />
            {isCreateMode && <SaveBeforeWarning />}
          </Grid>
          <Grid item xs={8}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <LabelWithCharLimit
                  htmlFor={PostField.TITLE}
                  className={classes.inputLabel}
                  label="Title"
                  valueLength={postDetails[PostField.TITLE].length}
                  limit={MAX_TITLE_LENGTH}
                />
                <TextField
                  id={PostField.TITLE}
                  name={PostField.TITLE}
                  onChange={handleChange as React.ChangeEventHandler<HTMLInputElement>}
                  value={postDetails[PostField.TITLE]}
                  multiline
                  rows={4}
                  variant="filled"
                  fullWidth
                  inputProps={{
                    maxLength: MAX_TITLE_LENGTH,
                  }}
                  {...formErrors[PostField.TITLE]}
                />
              </Grid>
              <Grid item xs={6}>
                <FormGen {...acceptedCurrency} />
              </Grid>
              <Grid item xs={6}>
                <FormGen {...settlePeriod} />
              </Grid>
              <Grid item xs={6}>
                <InputLabel
                  htmlFor={PostField.START}
                  className={classes.inputLabel}
                >
                  Start Date
                </InputLabel>
                <DateTimePicker
                  value={moment(postDetails[PostField.START])}
                  onChange={(date): void => {
                    setStartCloseDate(
                      PostField.START,
                      moment(date?.utc().toISOString()).toISOString(),
                    )
                  }}
                  format={'YYYY-MM-DD HH:mm'}
                  {...formErrors[PostField.START]}
                />
              </Grid>
              <Grid item xs={6}>
                <InputLabel
                  htmlFor={PostField.CLOSE}
                  className={classes.inputLabel}
                >
                  End Date
                </InputLabel>
                <DateTimePicker
                  value={
                    IsNullOrUndefined(postDetails.close)
                      ? null
                      : moment(postDetails[PostField.CLOSE])
                  }
                  onChange={(date): void => {
                    setStartCloseDate(
                      PostField.CLOSE,
                      moment(date?.utc().toISOString()).toISOString(),
                    )
                  }}
                  format={'YYYY-MM-DD HH:mm'}
                  minDate={moment(postDetails[PostField.START])}
                  disabled={postDetails.settlePeriod === PostSettlePeriod.DAILY}
                  {...formErrors[PostField.CLOSE]}
                />
              </Grid>
              <Duration
                start={postDetails[PostField.START]}
                end={postDetails[PostField.CLOSE]}
              />
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box>
                  <InputLabel className={classes.inputLabel}>
                    Regions
                  </InputLabel>
                  <RegionsSelect
                    selectedRegions={postDetails.countries || []}
                    onSelect={(val: any[]): void => {
                      setFieldValue('countries', val)
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <InputLabel className={classes.inputLabel}>
                  Difficulty
                </InputLabel>
                <Select
                  id={PostField.DIFFICULTY}
                  name={PostField.DIFFICULTY}
                  value={postDetails[PostField.DIFFICULTY]}
                  onChange={(event): void => {
                    setDifficulty(event.target.value as string)
                  }}
                  variant="filled"
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    // getContentAnchorEl: null,
                  }}
                  fullWidth
                  disabled={[PostType.Poll, PostType.Test].includes(post.type)}
                >
                  {Object.values(PostDifficulty).map((difficulty) => (
                    <MenuItem key={difficulty} value={difficulty}>
                      {difficulty}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12}>
                <InputLabel
                  className={classes.inputLabel}
                  htmlFor={PostField.MIN_BET}
                >
                  Minimum Coin Bet
                </InputLabel>
                <TextField
                  id={PostField.MIN_BET}
                  name={PostField.MIN_BET}
                  value={postDetails[PostField.MIN_BET]}
                  onChange={(event: React.ChangeEvent<any>): void => {
                    handleChange(event)
                    if (
                      [PostType.Bet, PostType.Game].includes(postDetails.type)
                    ) {
                      setFieldValue(PostField.MAX_BET, event.target.value * 10)
                    }
                  }}
                  variant="filled"
                  type="number"
                  fullWidth
                  InputProps={{
                    inputProps: {
                      min: [PostType.Poll, PostType.Test].includes(post.type)
                        ? 0
                        : 1,
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <Box className={classes.adornmentIcon}>
                          <CoinIcon />
                        </Box>
                      </InputAdornment>
                    ),
                  }}
                  disabled={[
                    PostType.Bet,
                    PostType.Poll,
                    PostType.Test,
                  ].includes(post.type)}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel
                  htmlFor={PostField.STARS_WIN}
                  className={classes.inputLabel}
                >
                  Star Reward
                </InputLabel>
                <TextField
                  id={PostField.STARS_WIN}
                  name={PostField.STARS_WIN}
                  value={postDetails[PostField.STARS_WIN]}
                  onChange={handleChange as React.ChangeEventHandler<HTMLInputElement>}
                  type="number"
                  variant="filled"
                  fullWidth
                  InputProps={{
                    inputProps: {
                      min: post.type === PostType.Bet ? 1 : 0,
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <Box className={classes.adornmentIcon}>
                          <StarIcon />
                        </Box>
                      </InputAdornment>
                    ),
                  }}
                  disabled={[PostType.Poll, PostType.Test].includes(post.type)}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel
                  className={classes.inputLabel}
                  htmlFor={PostField.COINS_WIN}
                >
                  Coin Reward
                </InputLabel>
                <TextField
                  id={PostField.COINS_WIN}
                  name={PostField.COINS_WIN}
                  value={postDetails[PostField.COINS_WIN]}
                  onChange={handleChange as React.ChangeEventHandler<HTMLInputElement>}
                  variant="filled"
                  type="number"
                  fullWidth
                  InputProps={{
                    inputProps: {
                      min: 0,
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <Box className={classes.adornmentIcon}>
                          <CoinIcon />
                        </Box>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel className={classes.inputLabel}>
                  Attributes
                </InputLabel>
                <FormGroup className={classes.switches}>
                  <FormControlLabel
                    className={classes.switchLabel}
                    control={
                      <Switch
                        color="primary"
                        id={PostField.BET_CARD}
                        name={PostField.BET_CARD}
                        checked={postDetails[PostField.BET_CARD]}
                        onChange={(event, checked): void => {
                          if (
                            checked ||
                            IsNullUndefinedOrEmpty(
                              postDetails[PostField.POST_PRIZES],
                            )
                          ) {
                            setFieldValue(PostField.BET_CARD, checked)
                          } else {
                            useDisableMultibetPopover.onChange(event)
                          }
                        }}
                        disabled={postDetails[PostField.TYPE] !== PostType.Bet || postDetails.isDailyStack}
                      />
                    }
                    label={<InputLabel>Multiplay</InputLabel>}
                  />
                  <DisbaleMultibetConfirmationPopover
                    popoverProps={useDisableMultibetPopover.popoverProps}
                    confirmationText={`Warning: Disabling multibet will remove the prize.\nRemember to reassign the prize if the switch is enabled again.`}
                    onConfirmation={disableMultibet}
                  />
                  {!IsNullUndefinedOrEmpty(postDetails[PostField.EVENT_ID]) && (
                    <React.Fragment>
                      <FormControlLabel
                        className={classes.switchLabel}
                        control={
                          <Switch
                            color="primary"
                            id={PostField.DISPLAY_IF_LOCKED}
                            name={PostField.DISPLAY_IF_LOCKED}
                            checked={postDetails[PostField.DISPLAY_IF_LOCKED]}
                            onChange={(event, checked): void => {
                              setFieldValue(
                                PostField.DISPLAY_IF_LOCKED,
                                checked,
                              )
                            }}
                            disabled={IsNullUndefinedOrEmpty(
                              postDetails[PostField.EVENT_ID],
                            )}
                          />
                        }
                        label={<InputLabel>Display if locked</InputLabel>}
                      />
                      <FormControlLabel
                        className={classes.switchLabel}
                        control={
                          <Switch
                            color="primary"
                            id={PostField.PUBLIC}
                            name={PostField.PUBLIC}
                            checked={postDetails[PostField.PUBLIC]}
                            onChange={(event, checked): void => {
                              setFieldValue(PostField.PUBLIC, checked)
                            }}
                            disabled={IsNullUndefinedOrEmpty(
                              postDetails[PostField.EVENT_ID],
                            )}
                          />
                        }
                        label={<InputLabel>Free to play</InputLabel>}
                      />
                    </React.Fragment>
                  )}
                  <FormControlLabel
                    className={classes.switchLabel}
                    control={
                      <Switch
                        color="primary"
                        id={PostField.WATCHLIST}
                        name={PostField.WATCHLIST}
                        checked={postDetails[PostField.WATCHLIST]}
                        onChange={(event, checked): void => {
                          setFieldValue(PostField.WATCHLIST, checked)
                        }}
                        disabled={postDetails[PostField.TYPE] !== PostType.Bet}
                      />
                    }
                    label={<InputLabel>Watchlist</InputLabel>}
                  />
                  <FormControlLabel
                    className={classes.switchLabel}
                    control={
                      <Switch
                        color="primary"
                        id={PostField.FEATURED}
                        name={PostField.FEATURED}
                        checked={postDetails[PostField.FEATURED]}
                        onChange={(event, checked): void => {
                          setFieldValue(PostField.FEATURED, checked)
                        }}
                      />
                    }
                    label={<InputLabel>Featured</InputLabel>}
                  />
                  <FormControlLabel
                    className={classes.switchLabel}
                    control={
                      <Switch
                        color="primary"
                        id={PostField.SKIP_MAIN_FEED}
                        name={PostField.SKIP_MAIN_FEED}
                        checked={postDetails[PostField.SKIP_MAIN_FEED]}
                        onChange={(event, checked): void => {
                          setFieldValue(PostField.SKIP_MAIN_FEED, checked)
                        }}
                      />
                    }
                    label={<InputLabel>Skip Main Feed</InputLabel>}
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={8}>
            <Grid container spacing={2}>
              <Grid item xs={post.type === PostType.Bet ? 6 : 12}>
                <InputLabel
                  htmlFor={PostField.CHECK_BY_DATE}
                  className={classes.inputLabel}
                >
                  Check on
                </InputLabel>
                <DateTimePicker
                  value={
                    IsNullOrUndefined(postDetails.checkByDate)
                      ? null
                      : moment(postDetails.checkByDate)
                  }
                  onChange={(date): void => {
                    const value = moment(date?.utc().toISOString()).toISOString()
                    setFieldValue(
                      PostField.CHECK_BY_DATE,
                      value,
                    )
                    if (!post.shouldBeSettledBy) {
                      setFieldValue('shouldBeSettledBy', value)
                    }
                  }}
                  format={'YYYY-MM-DD HH:mm'}
                  minDate={moment(postDetails[PostField.START])}
                  {...formErrors[PostField.CHECK_BY_DATE]}
                />
              </Grid>
              {post.type === PostType.Bet && <Grid item xs={6}>
                <InputLabel
                  htmlFor="Settle date"
                  className={classes.inputLabel}
                >
                  Settle date
                </InputLabel>
                <DateTimePicker
                  value={
                    IsNullOrUndefined(postDetails.shouldBeSettledBy)
                      ? null
                      : moment(postDetails.shouldBeSettledBy)
                  }
                  onChange={(date): void => {
                    setFieldValue(
                      'shouldBeSettledBy',
                      moment(date?.utc().toISOString()).toISOString(),
                    )
                  }}
                  format={'YYYY-MM-DD HH:mm'}
                  minDate={moment(postDetails.start)}
                  {...formErrors.shouldBeSettledBy}
                />
              </Grid>}
              <Grid item xs={6}>
                <InputLabel
                  htmlFor="Pricing"
                  className={classes.inputLabel}
                >
                  Pricing
                </InputLabel>
                <Select
                  id="postPricingModel"
                  name="postPricingModel"
                  onChange={(event): void => {
                    const value = event.target.value as string | undefined
                    if (value === PostPricingModel.Free) {
                      setFieldValue('pricingModelInAppPurchaseCode', '')
                    }
                    setFieldValue('postPricingModel', value)
                  }}
                  value={postDetails.postPricingModel}
                  variant="filled"
                  fullWidth
                  disabled={!isCreateMode}
                >
                  {Object.values(PostPricingModel).map((model) => (
                    <MenuItem
                      key={model}
                      value={model}
                    >
                      {model}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={6}>
                <InputLabel className={classes.inputLabel}>
                  App purchase code
                </InputLabel>
                <Select
                  id="pricingModelInAppPurchaseCode"
                  name="pricingModelInAppPurchaseCode"
                  onChange={(event): void => {
                    const value = event.target.value as string | undefined
                    setFieldValue('pricingModelInAppPurchaseCode', value)
                  }}
                  value={postDetails.pricingModelInAppPurchaseCode}
                  variant="filled"
                  fullWidth
                  disabled={
                    postDetails.postPricingModel === PostPricingModel.Free ||
                    !isCreateMode
                  }
                >
                  <MenuItem value="">Select a code</MenuItem>
                  {
                    Object.keys(IapCode).map((code) => {
                      // @ts-expect-error to be decided later
                      const value = IapCode[code]
                      return <MenuItem
                        key={code}
                        value={value}
                      >
                        {code}
                      </MenuItem>
                    })
                  }
                </Select>
              </Grid>
              {postDetails.type === PostType.Bet && <Grid item xs={12}>
                <PostGroupsSelect
                  initialSelectedIds={postDetails.groups}
                  handleChange={handleChange}
                  disabled={!!postGroupId}
                />
              </Grid>}
              <Grid item xs={6}>
                <InputLabel className={classes.inputLabel}>Event</InputLabel>
                <AutocompleteSearchSelect
                  variant="filled"
                  initialSelectedItem={selectedEvent}
                  availableItems={[]}
                  onSelectedItemChange={(
                    selectedEvent: IEvent | null,
                  ): void => {
                    setFieldValue(PostField.EVENT_ID, selectedEvent?.id)
                    if (IsNullOrUndefined(selectedEvent)) {
                      setFieldValue(PostField.DISPLAY_IF_LOCKED, false)
                      setFieldValue(PostField.PUBLIC, false)
                    }
                  }}
                  searchAsyncActionCreator={searchEvents}
                  selectorMethod={EventSelectors.getCachedEvents}
                  options={{
                    autocompleteSearchResultContent: (
                      item: unknown,
                    ): string => {
                      const event = item as IEvent
                      return `${event?.name} ${
                        event?.media?.description
                          ? '- ' + event.media.description
                          : ''
                      }`
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <InputLabel
                  htmlFor={PostField.LIVE_SCHEDULE_ID}
                  className={classes.inputLabel}
                >
                  Schedule
                </InputLabel>
                <Select
                  id={PostField.LIVE_SCHEDULE_ID}
                  name={PostField.LIVE_SCHEDULE_ID}
                  onChange={(event): void => {
                    const value = event.target.value as string | undefined
                    if (IsNullUndefinedOrEmpty(value)) {
                      setFieldValue(PostField.LIVE_SCHEDULE_ID, false)
                    }
                    setFieldValue(PostField.LIVE_SCHEDULE_ID, value)
                  }}
                  value={postDetails[PostField.LIVE_SCHEDULE_ID]}
                  variant="filled"
                  fullWidth
                  disabled={IsNullUndefinedOrEmpty(postDetails.eventId)}
                >
                  <MenuItem key={`no-schedule`} value={''}>
                    No Schedule
                  </MenuItem>
                  {liveSchedules.map((liveSchedule) => (
                    <MenuItem
                      key={`live-schedule-${liveSchedule.id}`}
                      value={liveSchedule.id}
                    >
                      {`${liveSchedule.name}
                          ${
                            !IsNullOrUndefined(liveSchedule.description)
                              ? `: ${liveSchedule.description}`
                              : ''
                          }`}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>

              <Grid item xs={12}>
                <InputLabel
                  htmlFor={`${PostField.MEDIA}[${PostField.NEWS_LINK}]`}
                  className={classes.inputLabel}
                >
                  News Link
                </InputLabel>
                <TextField
                  id={`${PostField.MEDIA}[${PostField.NEWS_LINK}]`}
                  name={`${PostField.MEDIA}[${PostField.NEWS_LINK}]`}
                  value={getIn(
                    postDetails,
                    `${PostField.MEDIA}[${PostField.NEWS_LINK}]`,
                  )}
                  onChange={(event): void => {
                    setFieldValue(
                      `${PostField.MEDIA}[${PostField.NEWS_LINK}]`,
                      event.target.value,
                    )
                  }}
                  variant="filled"
                  fullWidth
                  disabled={post.type !== PostType.Bet}
                />
              </Grid>
              <Grid item xs={6}>
                <InputLabel
                  className={classes.inputLabel}
                  htmlFor={PostField.MULTIPLIER_MID}
                >
                  Star Reward Mid Multiplier
                </InputLabel>
                <TextField
                  id={PostField.MULTIPLIER_MID}
                  name={PostField.MULTIPLIER_MID}
                  value={postDetails[PostField.MULTIPLIER_MID]}
                  onChange={handleChange as React.ChangeEventHandler<HTMLInputElement>}
                  variant="filled"
                  type="number"
                  fullWidth
                  disabled={post.type !== PostType.Bet}
                />
              </Grid>
              <Grid item xs={6}>
                <InputLabel
                  className={classes.inputLabel}
                  htmlFor={PostField.MULTIPLIER_MAX}
                >
                  Star Reward Max Multiplier
                </InputLabel>
                <TextField
                  id={PostField.MULTIPLIER_MAX}
                  name={PostField.MULTIPLIER_MAX}
                  value={postDetails[PostField.MULTIPLIER_MAX]}
                  onChange={handleChange as React.ChangeEventHandler<HTMLInputElement>}
                  variant="filled"
                  type="number"
                  fullWidth
                  disabled={post.type !== PostType.Bet}
                />
              </Grid>
              {postDetails.betCard && (
                <React.Fragment>
                  <Grid item xs={12}>
                    <InputLabel
                      htmlFor={`${PostField.MEDIA}[${PostField.DESCRIPTION}]`}
                      className={classes.inputLabel}
                    >
                      Description
                    </InputLabel>
                    <TextField
                      id={`${PostField.MEDIA}[${PostField.DESCRIPTION}]`}
                      name={`${PostField.MEDIA}[${PostField.DESCRIPTION}]`}
                      value={getIn(
                        postDetails,
                        `${PostField.MEDIA}[${PostField.DESCRIPTION}]`,
                      )}
                      onChange={(event): void => {
                        setFieldValue(
                          `${PostField.MEDIA}[${PostField.DESCRIPTION}]`,
                          event.target.value,
                        )
                      }}
                      variant="filled"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={9}>
                    <InputLabel className={classes.inputLabel}>
                      Prize Reward
                    </InputLabel>
                    <AutocompleteSearchSelect
                      variant="filled"
                      initialSelectedItem={selectedPrize}
                      availableItems={[]}
                      fieldName="item.name"
                      optionDisabled={(item: IPrize): boolean => item.status !== PrizeStatus.Open}
                      onSelectedItemChange={(
                        selectedPrize: IPrize | null,
                      ): void => {
                        if (!IsNullOrUndefined(selectedPrize)) {
                          const value = {
                            prizeId: selectedPrize?.id ?? '',
                            quantity: getPostPrizeMaxQuantity(selectedPrize),
                          }
                          setFieldValue(`${PostField.POST_PRIZES}[0]`, value)
                        } else {
                          setFieldValue(PostField.POST_PRIZES, [])
                        }
                      }}
                      searchAsyncActionCreator={searchPrizes}
                      selectorMethod={(state: AppState): IPrize[] =>
                        PrizeSelectors.getCachedPrizesOfTypes(state, [PrizeType.Post, PrizeType.Instant])}
                      options={{
                        autocompleteSearchResultContent: (
                          item: unknown,
                        ): string => {
                          const prize = item as IPrize
                          return `${prize?.item?.name} - ${prize?.item?.details?.brand}`
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <InputLabel
                      htmlFor={`${PostField.POST_PRIZES}[0][${PostField.QUANTITY}]`}
                      className={classes.inputLabel}
                    >
                      Max Quantity
                    </InputLabel>
                    <TextField
                      id={`${PostField.POST_PRIZES}[0][${PostField.QUANTITY}]`}
                      name={`${PostField.POST_PRIZES}[0][${PostField.QUANTITY}]`}
                      value={
                        postDetails[PostField.POST_PRIZES][0]?.[
                          PostField.QUANTITY
                        ] ?? 0
                      }
                      onChange={handleChange as React.ChangeEventHandler<HTMLInputElement>}
                      type="number"
                      variant="filled"
                      fullWidth
                      InputProps={{
                        inputProps: {
                          min: 0,
                        },
                      }}
                    />
                  </Grid>
                </React.Fragment>
              )}
              <Grid item xs={12}>
                <InputLabel className={classes.inputLabel}>Tags</InputLabel>
                {tagSelect}
                {!IsNullOrUndefined(currentTag) && (
                  <ManageTagModal
                    currentTag={currentTag}
                    open={!IsNullOrUndefined(currentTag)}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  )
}
