import { Fade, Modal, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Panel } from 'components/panel'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { ManagePostPanel } from 'modules/posts/views/components/manage-post-panel'
import React from 'react'
import { IPost } from 'types/modules/posts/models/entities/post'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 40,
    },
  }),
)

interface IManagePostModalProps {
  currentPost: IPost | null
}

export const ManagePostModal: React.FC<IManagePostModalProps> = (
  props: IManagePostModalProps,
) => {
  const classes = useStyles()

  return (
    <div>
      <Modal
        className={classes.modal}
        open={!IsNullOrUndefined(props.currentPost)}
      >
        <Fade in={!IsNullOrUndefined(props.currentPost)}>
          <Panel
            xs={12}
            style={{
              display: 'flex',
              flex: 1,
              height: '100%',
            }}
          >
            <ManagePostPanel isModal />
          </Panel>
        </Fade>
      </Modal>
    </div>
  )
}
