import CommentsEndpoints from 'core/api/endpoints/comments'
import { createAction, createAsyncAction } from 'core/redux/utils'
import { ActionTypes } from 'modules/comments'
import { IAsyncActionParams } from 'types/common/api/models/entities/async-action-params'
import { IComment } from 'types/modules/comments/models/entities/comment'
import { ICommentDraft } from 'types/modules/comments/models/entities/comment-draft'
import { IAsyncAction } from 'types/redux/interfaces/IAction'

export interface ICommentActionParams
  extends IAsyncActionParams<IComment | ICommentDraft> {
  commentId?: string
  postId?: string
}

export const getPostComments: (params: ICommentActionParams) => IAsyncAction = (
  params: ICommentActionParams,
) =>
  createAsyncAction({
    type: ActionTypes.GET_POST_COMMENTS,
    endpoint: CommentsEndpoints.getPostComments,
    payload: params,
  })

export const createPostComment: (
  params: ICommentActionParams,
) => IAsyncAction = (params: ICommentActionParams) =>
  createAsyncAction({
    type: ActionTypes.CREATE_POST_COMMENT,
    endpoint: CommentsEndpoints.createPostComment,
    payload: params,
  })

export const updatePostComment: (
  params: ICommentActionParams,
) => IAsyncAction = (params: ICommentActionParams) =>
  createAsyncAction({
    type: ActionTypes.UPDATE_POST_COMMENT,
    endpoint: CommentsEndpoints.updatePostComment,
    payload: params,
  })

export const deletePostComment: (
  params: ICommentActionParams,
) => IAsyncAction = (params: ICommentActionParams) =>
  createAsyncAction({
    type: ActionTypes.DELETE_POST_COMMENT,
    endpoint: CommentsEndpoints.deletePostComment,
    payload: params,
  })
