import { RootSagaReturnType } from 'core/redux/rootSaga'
import { actionTypeSuccess } from 'core/redux/utils'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { GetTagsSaga } from 'modules/tags/sagas/get-tags-saga'
import { ActionTypes } from 'modules/watchlist'
import { getOnboardings } from 'modules/watchlist/actions'
import {
  ForkEffect,
  all,
  takeEvery,
  take,
  put,
  fork,
  PutEffect,
  TakeEffect,
} from 'redux-saga/effects'
import { IAsyncActionParams } from 'types/common/api/models/entities/async-action-params'
import { FilterOperator } from 'types/common/filtering/enums/FilterOperator'
import { FilterType } from 'types/common/filtering/enums/FilterType'
import { IFilter } from 'types/common/filtering/models/entities/IFilter'
import { IOnboarding } from 'types/modules/onboarding/models/entities/onboarding'
import { TagGroup } from 'types/modules/tags/models/entities/tag-group'
import { IAsyncAction, ISuccessAction } from 'types/redux/interfaces/IAction'

const getTagGroupsSaga = function* (
  action: IAsyncAction,
): Generator<
  PutEffect<IAsyncAction<boolean>> | TakeEffect | ForkEffect<void>,
  void,
  unknown
> {
  yield put(getOnboardings())

  const response = yield take(actionTypeSuccess(ActionTypes.GET_ONBOARDINGS))

  const onboardings = (response as ISuccessAction).data as IOnboarding[]

  const tagGroupIds = onboardings.map((onboarding) => onboarding.tagGroupId)

  const filters: IFilter[] = [
    {
      field: 'id',
      type: FilterType.Select,
      operator: FilterOperator.Equals,
      value: tagGroupIds,
    },
  ]

  action.payload = {
    filters,
  } as IAsyncActionParams<TagGroup>

  yield fork(GetTagsSaga, action)
}

export const RootSaga = function* (): RootSagaReturnType {
  yield all([
    yield takeEvery(ActionTypes.GET_ONBOARDINGS, GetTagsSaga),
    yield takeEvery(ActionTypes.GET_TAG_GROUPS, getTagGroupsSaga),
  ])
}
