import { useTypedSelector } from 'core/redux/utils'
import { useRouter } from 'core/routing/hooks/use-router'
import {
  IsNullOrUndefined,
} from 'core/utils/isNullOrUndefined'
import { QuestActions, Selectors } from 'modules/quests'
import { Actions as PrizeActions, Selectors as PrizeSelectors } from 'modules/prizes'
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
} from 'react'
import { useDispatch } from 'react-redux'
import { NIL as NIL_UUID } from 'uuid'
import { IQuest } from 'modules/quests/models/quest'
import { MediaUploadModel } from 'types/common/images/models/entities/image-upload-model'
import { mapMimeType } from 'core/utils/mime-mapper'
import { compact } from 'lodash-es'

export interface IQuestsContext {
  currentQuest: IQuest | null
  setCurrentQuest: (questId: string) => void
  activateQuest: (questId?: string) => void
  deactivateQuest: (questId?: string) => void
  clearCurrentQuest: () => void
  createQuest: (quest: IQuest, callbackFn?: (quest: IQuest) => void) => void
  createQuestDraft: (quest?: IQuest) => void
  updateQuest: (quest: IQuest) => void
  uploadQuestImage: (eventId: string, fileParams: MediaUploadModel) => void
}

const QuestsContext = createContext<IQuestsContext>({} as IQuestsContext)

export const QuestsProvider: React.FC<PropsWithChildren<{}>> = (
  props: PropsWithChildren<{}>,
) => {
  const dispatch = useDispatch()

  const { routeParams: { questId }, pushQueryParams, removeQueryParams } = useRouter()

  const currentQuest = useTypedSelector((state) => Selectors.getCurrentQuest(state))

  const isPrizesCached = useTypedSelector((state) => PrizeSelectors.getPrizeCacheChecker(state))

  const setCurrentQuest = (questId: string): void => {
    pushQueryParams({
      questId,
    })
  }

  const clearCurrentQuest = (): void => {
    removeQueryParams('questId')
  }

  const createQuest = (quest: IQuest): void => {
    dispatch(
      QuestActions.createQuest({
        item: quest,
        promise: {
          onResolve: (data: IQuest): void => {
            setCurrentQuest(data.id!)
          }
        }
      }),
    )
  }

  const createQuestDraft = (): void => {
    setCurrentQuest(NIL_UUID)
  }

  const updateQuest = (quest: IQuest): void => {
    dispatch(
      QuestActions.updateQuest({
        item: quest,
        questId: quest.id
      }),
    )
  }

  const activateQuest = (questId?: string): void => {
    dispatch(
      QuestActions.activateQuest({
        questId: questId
      }),
    )
  }

  const deactivateQuest = (questId?: string): void => {
    dispatch(
      QuestActions.deactivateQuest({
        questId: questId
      }),
    )
  }

  const uploadQuestImage = (
    questId: string,
    fileParams: MediaUploadModel,
  ): void => {
    dispatch(
      QuestActions.uploadQuestImage({
        questId,
        item: fileParams.binaryData,
        extension: `image.${fileParams.fileExtension}`,
        overrideHeaders: {
          'content-type': mapMimeType(fileParams.fileExtension),
          accept: '*/*',
        },
      }),
    )
  }

  useEffect(() => {
    dispatch(QuestActions.setCurrentQuestId({ questId }))
    if (!IsNullOrUndefined(questId) && questId !== NIL_UUID) {
      dispatch(QuestActions.getQuestById({ questId }))
    }
  }, [questId])

  useEffect(() => {
    const prizeIds = currentQuest
      ? currentQuest.questRewards.map((quest) => quest.prizeId)
      : []
    compact(prizeIds).forEach((prizeId) => {
      if (!isPrizesCached(prizeId)) {
        dispatch(PrizeActions.getPrizeById({ prizeId }))
      }
    });
  }, [questId, currentQuest?.questRewards.length])

  const context = {
    uploadQuestImage,
    currentQuest,
    setCurrentQuest,
    clearCurrentQuest,
    createQuest,
    createQuestDraft,
    updateQuest,
    deactivateQuest,
    activateQuest
  }

  return (
    <QuestsContext.Provider value={context}>
      {props.children}
    </QuestsContext.Provider>
  )
}

export const useQuestsContext = (): IQuestsContext => useContext(QuestsContext)
