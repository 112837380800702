export enum PostField {
  BET_CARD = 'betCard',
  CATEGORY = 'category',
  CLOSE = 'close',
  CHECK_BY_DATE = 'checkByDate',
  COINS_WIN = 'coinsWin',
  CREATED_BY = 'createdBy',
  DESCRIPTION = 'description',
  DIFFICULTY = 'difficulty',
  DISPLAY_IF_LOCKED = 'displayIfLocked',
  EVENT_ID = 'eventId',
  FEATURED = 'featured',
  HAS_ALERTS = 'hasAlerts',
  HAS_DIFFERENT_ANSWERS = 'hasDifferentAnswers',
  ID = 'id',
  IMAGE_URL = 'imageUrl',
  LIVE_SCHEDULE_ID = 'liveScheduleId',
  MAX_BET = 'maxBet',
  MEDIA = 'media',
  MEDIA_ITEM_ID = 'mediaItemId',
  MIN_BET = 'minBet',
  MULTIPLIER_MAX = 'multiplierMax',
  MULTIPLIER_MID = 'multiplierMid',
  NEWS_LINK = 'newsLink',
  PAYOUT_CAP = 'payoutCap',
  POST_COMMENT_COUNT = 'postCommentCount',
  POST_PRIZES = 'postPrizes',
  PRIZE_ID = 'prizeId',
  PROMOTED = 'promoted',
  PUBLIC = 'public',
  QUANTITY = 'quantity',
  QUESTIONS = 'questions',
  RESULTS = 'results',
  SKIP_MAIN_FEED = 'skipMainFeed',
  STARS_WIN = 'starsWin',
  START = 'start',
  STATUS = 'status',
  SUB_CATEGORY_ID = 'subCategoryId',
  TAGS = 'tags',
  TAG_IDS = 'tagIds',
  TIMED = 'timed',
  TITLE = 'title',
  TYPE = 'type',
  UPDATED_AT = 'updatedAt',
  UPDATED_BY = 'updatedBy',
  VOLUME = 'volume',
  WATCHLIST = 'watchlist',
  WINNING_COUNT = 'winningCount',
  VIDEO_URL = 'videoUrl',
  IS_VIDEO = 'isVideo',
}
