import { Box, Chip, Grid, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import _ from 'core/utils/deepdash'
import { FormGen, FormTypes } from 'components/simple-form-gen'
import { useQuestsContext } from 'modules/quests/context/quest-context'
import { QuestField, QuestMediaField } from 'modules/quests/enums/quest-field'
import { IQuest } from 'modules/quests/models/quest'
import React from 'react'
import { IForm } from 'types/common/interfaces/IForm'
import { useEnums } from 'hooks/use-enums'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      width: '100%',
      height: 'auto',
      '& .MuiGrid-item': {
        display: 'flex',
        flexDirection: 'column',
      },
    },
    imageWrapper: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: 250,
    },
    activeChip: {
      width: 100
    }
  }),
)

interface IQuestFormProps extends IForm {
  quest: IQuest,
  isUpdating: boolean
}

export const QuestForm: React.FC<IQuestFormProps> = ({
  quest,
  handleChange,
  errors,
  isUpdating
}): JSX.Element => {
  const classes = useStyles()
  const { schedules, questMediaLocations } = useEnums()
  const { currentQuest } = useQuestsContext()
  const questMediaLocationsOptions = questMediaLocations.length > 0
    ? questMediaLocations.map((location) => ({ value: location, name: location }))
    : [{ value: quest.media?.location, name: quest.media?.location }]

  const elements = [
    {
      name: `${QuestField.MEDIA}.${QuestMediaField.TITLE}`,
      label: 'Title',
      value: quest?.[QuestField.MEDIA]?.[QuestMediaField.TITLE] || '',
      type: FormTypes.TEXT_INPUT,
      otherProps: {
        error: !!errors[QuestField.MEDIA]?.[QuestMediaField.TITLE],
        helperText: errors[QuestField.MEDIA]?.[QuestMediaField.TITLE]
      }
    },
    {
      name: `${QuestField.MEDIA}.${QuestMediaField.DESCRIPTION}`,
      label: 'Description',
      value: quest?.[QuestField.MEDIA]?.[QuestMediaField.DESCRIPTION] || '',
      type: FormTypes.TEXT_INPUT,
      otherProps: {
        error: !!errors[QuestField.MEDIA]?.[QuestMediaField.DESCRIPTION],
        helperText: errors[QuestField.MEDIA]?.[QuestMediaField.DESCRIPTION]
      }
    },
    {
      name: 'media.location',
      label: 'Location',
      value: quest?.media?.location || '',
      type: FormTypes.SELECT,
      options: questMediaLocationsOptions,
      otherProps: {
        error: !!errors?.actionCode,
        helperText: errors?.actionCode
      }
    },
  ]

  const elements2 = [
    {
      name: QuestField.SCHEDULE,
      label: 'Schedule',
      value: quest[QuestField.SCHEDULE],
      type: FormTypes.SELECT,
      options: schedules.map((schedule) => ({
        value: schedule,
        name: schedule
      })),
      otherProps: {
        error: !!errors[QuestField.SCHEDULE],
        helperText: errors[QuestField.SCHEDULE]
      },
      hideInitialOption: true
    },
    {
      name: QuestField.SET,
      label: 'Set',
      value: quest[QuestField.SET],
      type: FormTypes.TEXT_INPUT,
      otherProps: {
        error: !!errors[QuestField.SET],
        helperText: errors[QuestField.SET],
        type: 'number'
      }
    }
  ]

  return (
    <Box className={classes.form}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            {elements.map((element) => (
              <Grid
                item xs={12}
                key={element.name}
                className={element.type === FormTypes.IMAGE ?  classes.imageWrapper : ''}
              >
                <FormGen
                  handleChange={handleChange}
                  {...element}
                />
              </Grid>
            ))}
            {elements2.map((element) => (
              <Grid item xs={6} key={element.name}>
                <FormGen
                  handleChange={handleChange}
                  {...element}
                />
              </Grid>
            ))}
             {isUpdating && <Grid item xs={12}>
               <Chip
                className={classes.activeChip}
                label={currentQuest?.active ? 'Active' : 'Inactive'}
                color={currentQuest?.active ? 'primary' : 'secondary'}
              />
             </Grid>}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}
