/* Lib */
import React from 'react'
import classNames from 'classnames'
import { Theme, Box, Card, CardActionArea, CardContent, Chip, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

/* Core */
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'

/* Types */
import { TagGroup } from 'types/modules/tags/models/entities/tag-group'

/* App */
import { Tag } from 'types/modules/tags/models/entities/tag'
import { useIconStyles } from 'hooks/use-icon-styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tagGroupCard: {
      width: '100%',
      marginBottom: '1.25rem',
      overflow: 'initial',
      boxShadow: 'none',
      border: '1px solid rgba(0, 0, 0, 0.12)',
    },
    tagGroupHeader: {
      display: 'flex',
      width: '100%',
    },
    tagGroupName: {
      display: 'flex',
      flexGrow: 1,
      alignItems: 'center',
    },
    tagGroupMeta: {
      display: 'flex',
      flexGrow: 1,
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    metaField: {
      display: 'flex',
      marginLeft: '1.25rem',
    },
    metaIcon: {
      width: 16,
      height: 16,
    },
    metaText: {
      marginLeft: '0.40rem',
    },
    tagGroupCardContent: {
      marginTop: '1.25rem',
      display: 'flex',
      overflow: 'hidden',
      minHeight: 36,
    },
    cardChip: {
      marginRight: '0.5rem',
      cursor: 'pointer',
    },
  }),
)

interface ITagGroupCardProps {
  tagGroup: TagGroup
  onClick: () => void
}

export const TagGroupCard: React.FC<ITagGroupCardProps> = (
  props: ITagGroupCardProps,
) => {
  const classes = useStyles()
  const iconClasses = useIconStyles()

  const renderTagsCount = (count: number | null): string => {
    if (IsNullOrUndefined(count)) count = 0

    let result = `${count} Tag`

    return count !== 1 ? (result += 's') : result
  }

  return (
    <Card className={classes.tagGroupCard}>
      <CardActionArea onClick={props.onClick}>
        <CardContent>
          <Box className={classes.tagGroupHeader}>
            <Box className={classes.tagGroupName}>
              <Typography variant="h6" color="textPrimary">
                {`${props.tagGroup.name}`}
              </Typography>
            </Box>
            <Box className={classes.tagGroupMeta}>
              {props.tagGroup.tags && (
                <Box className={classes.metaField}>
                  <i
                    className={classNames(
                      classes.metaIcon,
                      iconClasses.tagsIcon
                    )}
                  />
                  <Typography variant={'caption'} className={classes.metaText}>
                    {renderTagsCount(props.tagGroup.tags!.length)}
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
          <Box className={classes.tagGroupCardContent}>
            {(props.tagGroup.tags as Tag[])?.map((tag) => (
              <Chip
                key={`tagGroup-${props.tagGroup.id}-tag-${tag.id}`}
                label={tag.name}
                icon={<i>{tag.media?.icon ?? null}</i>}
                className={classes.cardChip}
              />
            ))}
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}
