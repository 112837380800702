import { MODULE_NAME } from 'modules/events/constants'

const ActionTypes = {
  SET_CURRENT_EVENT_ID: `${MODULE_NAME}/SET_CURRENT_EVENT_ID`,
  GET_EVENT_STATUSES: `${MODULE_NAME}/GET_EVENT_STATUSES`,
  GET_EVENTS: `${MODULE_NAME}/GET_EVENTS`,
  CLEAR_EVENTS: `${MODULE_NAME}/CLEAR_EVENTS`,
  GET_EVENT_BY_ID: `${MODULE_NAME}/GET_EVENT_BY_ID`,
  CREATE_EVENT: `${MODULE_NAME}/CREATE_EVENT`,
  UPDATE_EVENT: `${MODULE_NAME}/UPDATE_EVENT`,
  UPDATE_EVENT_STATUS: `${MODULE_NAME}/UPDATE_EVENT_STATUS`,
  UPLOAD_EVENT_IMAGE: `${MODULE_NAME}/UPLOAD_EVENT_IMAGE`,
  UPLOAD_EVENT_PASS_IMAGE: `${MODULE_NAME}/UPLOAD_EVENT_PASS_IMAGE`,
  UPLOAD_DEFAULT_LIVE_SCHEDULE_IMAGE: `${MODULE_NAME}/UPLOAD_DEFAULT_LIVE_SCHEDULE_IMAGE`,
  AUTOCOMPLETE_EVENT_SEARCH: `${MODULE_NAME}/AUTOCOMPLETE_EVENT_SEARCH`,
  PROMOTE_EVENT: `${MODULE_NAME}/PROMOTE_EVENT`,
}

export default ActionTypes
