import { RequestContextProvider } from 'core/api/context'
import { PostContext } from 'modules/posts/context'
import { PostsDashboard } from 'modules/posts/views/components/dashboard'
import React from 'react'
import { IEvent } from 'types/modules/events/models/entities/event'

interface IEventPostsProps {
  event: IEvent
}

export const EventPosts: React.FC<IEventPostsProps> = (
  props: IEventPostsProps,
) => {
  return (
    <RequestContextProvider>
      <PostContext event={props.event}>
        <PostsDashboard />
      </PostContext>
    </RequestContextProvider>
  )
}
