import { PostDifficulty } from 'types/modules/posts/enums/post-difficulty'
import moment from 'moment-timezone'
import { IsNullUndefinedOrEmpty } from 'core/utils/isNullOrUndefined'

export const calculateTimeBasedDifficulty = (
  startTime: string,
  endTime?: string,
): PostDifficulty => {
  if (IsNullUndefinedOrEmpty(endTime)) {
    return PostDifficulty.Easy
  }

  const start = moment(startTime)
  const end = moment(endTime!)

  const weeks = end.diff(start, 'weeks', true)
  const months = end.diff(start, 'months', true)

  if (weeks < 1) {
    return PostDifficulty.Easy
  } else if (months <= 1) {
    return PostDifficulty.Medium
  } else if (months <= 3) {
    return PostDifficulty.Hard
  } else {
    return PostDifficulty.Impossible
  }
}
