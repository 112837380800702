/* eslint-disable react/display-name */
import React from 'react'
import { Button } from '@mui/material'
import { dismissAlert, enqueueAlert } from 'core/alerts/actions'
import { createAlert } from 'core/alerts/utils/create-alert'
import store from 'core/redux/store'
import { SnackbarKey } from 'notistack'
import { put, PutEffect } from 'redux-saga/effects'
import { IFailedAction, ISimpleAction } from 'types/redux/interfaces/IAction'
import { IsNullUndefinedOrEmpty } from 'core/utils/isNullOrUndefined'
import { isArray, isObject, isString } from 'lodash-es'

type SagaReturnType = Generator<PutEffect<ISimpleAction>, void, unknown>

export function* FailedAlertSaga(action: IFailedAction): SagaReturnType {
  const actionType = action.type.split('/')[1]

  let message = actionType.split('_').join(' ')

  const serverErrors = action?.error?.message?.errors?.['server error']
  const dataErrors = action?.error?.data?.errors

  if (serverErrors && isArray(serverErrors)) {
    message += `\n${serverErrors.join('\n')}`
  } else if (dataErrors && isObject(dataErrors)) {
    message += `\n${Object.values(dataErrors).join('\n')}`
  } else if (isString(action?.error?.message)) {
    message += `: ${action.error.message}`
  }

  const alert = createAlert(message, {
    variant: 'error',
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'center',
    },
    action: (key: SnackbarKey) => (
      <Button
        onClick={(): void => {
          store.dispatch(dismissAlert(key))
        }}
      >
        Dismiss
      </Button>
    ),
  })

  yield put(enqueueAlert(alert))
}
