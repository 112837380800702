import { IKeyValueStringCollection } from 'types/common/interfaces/IKeyValueStringCollection'

const paths: IKeyValueStringCollection = {
  tags: 'tags',
  initialPostTags: 'intial-post-tags',
  trendingTags: 'v1/trending/promoted-tags',
  suggestedTags: 'v1/trending/suggested-tags',
  images: 'images',
  upload: 'upload',
}

export default paths
