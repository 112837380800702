import { RequestMethod } from 'types/common/api/enums/request-method'
import paths from './paths'
import params from './params'
import filters from './filters'

const MilestoneEndpoints = {
  getMilestones: {
    method: RequestMethod.GET,
    path: [paths.milestones],
    filters,
  },
  getMilestoneById: {
    method: RequestMethod.GET,
    path: [paths.milestones, params.milestoneId],
  },
  updateMilestone: {
    method: RequestMethod.PUT,
    path: [paths.milestones, params.milestoneId],
  },
}

export default MilestoneEndpoints
