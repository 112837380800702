import { Button, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx'
import { ViewIcon } from 'components/icons'
import { useShippingContext } from 'modules/shipping/context'
import React from 'react'
import { IUserPrizeActionButtonProps } from 'types/modules/shipping/models/props/user-prize-action-button-props'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.text.primary,
      backgroundColor: '#ecf0f1',
      '&:hover': {
        backgroundColor: '#f2f5f5',
      },
    },
  }),
)

export const ViewUserPrizeButton: React.FC<IUserPrizeActionButtonProps> = (
  props: IUserPrizeActionButtonProps,
) => {
  const classes = useStyles()

  const { setCurrentUserPrize } = useShippingContext()

  return (
    <Button
      variant="contained"
      startIcon={<ViewIcon />}
      className={clsx(classes.root)}
      size="small"
      onClick={(): void => {
        setCurrentUserPrize(props.userPrize.id)
        props.onPopoverClose && props.onPopoverClose()
      }}
    >
      View
    </Button>
  )
}
