import { Fade, Modal, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { usePostListContext } from 'modules/posts/context/post-list'
import { WatchlistPostPreview } from 'modules/watchlist/views/components/post-modal/post-preview'
import React from 'react'
import { IPost } from 'types/modules/posts/models/entities/post'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),
)

interface IWatchlistPostModalProps {
  post: IPost
}

export const WatchlistPostModal: React.FC<IWatchlistPostModalProps> = (
  props: IWatchlistPostModalProps,
) => {
  const { clearCurrentPost } = usePostListContext()
  const classes = useStyles()
  return (
    <div>
      <Modal
        className={classes.modal}
        open={!IsNullOrUndefined(props.post)}
        onClose={(): void => clearCurrentPost()}
      >
        <Fade in={!IsNullOrUndefined(props.post)}>
          <WatchlistPostPreview post={props.post} />
        </Fade>
      </Modal>
    </div>
  )
}
