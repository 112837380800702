import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  IconButton,
  InputLabel,
  SvgIcon,
  Switch,
  Theme,
  Typography,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useInfoCardContext } from 'modules/info-cards/context'
import { NIL as NIL_UUID } from 'uuid'
import React, { useState } from 'react'
import { InfoCardField as FieldName } from 'types/modules/info-cards/enums/info-card-field'
import { useFormik } from 'formik'
import moment from 'moment-timezone'
import { IInfoCardDraft } from 'types/modules/info-cards/models/entities/info-card-draft'
import { InfoCardForm } from 'modules/info-cards/views/components/info-card-details-panel/info-card-form'
import { Panel, PanelContainer } from 'components/panel'
import { CrossIcon, UndoIcon, SaveIcon } from 'components/icons'
import { IsNullUndefinedOrEmpty } from 'core/utils/isNullOrUndefined'
import { InfoCardStatus } from 'types/modules/info-cards/enums/info-card-status'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    panelHeader: {
      display: 'flex',
      width: '100%',
      flexShrink: 0,
      height: 72,
    },
    panelTitle: {
      display: 'flex',
      alignItems: 'center',
      padding: '0 0 0 1.25rem',
    },
    rightHeaderSection: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      padding: '0 1.25rem 0 0',
      flexDirection: 'row-reverse',
      '& .MuiButton-contained': {
        marginRight: 20,
      },
    },
    infoCardStatus: {
      marginRight: 20,
    },
    contentWrapper: {
      display: 'flex',
      flexGrow: 1,
      overflow: 'hidden',
    },
    contentPanel: {
      position: 'relative',
      display: 'flex',
      flex: 1,
      padding: '1.25rem',
      overflow: 'auto',
      borderRight: '1px solid rgba(0,0,0,0.12)',
      '&:last-child': {
        border: 'none',
      },
    },
    footer: {
      display: 'flex',
      width: '100%',
      justifySelf: 'flex-end',
    },
    footerSection: {
      display: 'flex',
      padding: '1.25rem',
      flex: 1,
      justifyContent: 'space-between',
      borderRight: '1px solid rgba(0,0,0,0.12)',
      '&:last-child': {
        border: 'none',
      },
    },
    updateButton: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    resetButton: {
      color: theme.palette.text.primary,
      backgroundColor: '#ecf0f1',
      '&:hover': {
        backgroundColor: '#f2f5f5',
      },
    },
  }),
)

enum DisplayMode {
  View = 'View',
  Edit = 'Edit',
  Create = 'Create',
}

interface IInfoCardDetailsPanelProps {
  isModal?: boolean
  closeModal?: () => void
}

export const InfoCardDetailsPanel: React.FC<IInfoCardDetailsPanelProps> = (
  props: IInfoCardDetailsPanelProps,
) => {
  const classes = useStyles()

  const {
    currentInfoCard: infoCard,
    clearCurrentInfoCard,
    updateInfoCard,
    createInfoCard,
  } = useInfoCardContext()

  const displayMode =
    infoCard?.id !== NIL_UUID
      ? DisplayMode.Edit
      : DisplayMode.Create ?? 'Loading'

  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      [FieldName.ID]: infoCard?.[FieldName.ID] ?? NIL_UUID,
      [FieldName.TITLE]: infoCard?.[FieldName.TITLE] ?? '',
      [FieldName.BODY]: infoCard?.[FieldName.BODY] ?? '',
      [FieldName.STATUS]:
        infoCard?.[FieldName.STATUS] ?? InfoCardStatus.Inactive,
      [FieldName.MEDIA]: infoCard?.[FieldName.MEDIA] ?? {
        [FieldName.IMAGE_URL]:
          infoCard?.[FieldName.MEDIA]?.[FieldName.IMAGE_URL] ?? '',
      },
      [FieldName.CREATED_AT]: infoCard?.[FieldName.CREATED_AT] ?? '',
      [FieldName.UPDATED_AT]: infoCard?.[FieldName.UPDATED_AT] ?? '',
    },
    onSubmit: (value: IInfoCardDraft) => {
      displayMode === DisplayMode.Create
        ? createInfoCard(value)
        : updateInfoCard(value)
    },
  })

  return (
    <PanelContainer>
      <Panel container xs={12}>
        <Box className={classes.panelHeader}>
          <Box className={classes.panelTitle}>
            <Typography
              variant="h5"
              color="textPrimary"
              style={{
                paddingRight: 10,
              }}
            >
              {`${displayMode} Info Card`}
            </Typography>
            <Typography
              variant="h5"
              color="primary"
              className={classes.infoCardStatus}
            >
              {infoCard!.status.toString()}
            </Typography>
          </Box>
          <Box className={classes.rightHeaderSection}>
            {props.isModal && (
              <IconButton onClick={(): void => clearCurrentInfoCard()} size="large">
                <SvgIcon>
                  <CrossIcon />
                </SvgIcon>
              </IconButton>
            )}
          </Box>
        </Box>
        <Divider />
        <Box className={classes.contentWrapper}>
          <Box className={classes.contentPanel}>
            <InfoCardForm
              {...{
                infoCard: form.values,
                handleChange: form.handleChange,
                setFieldTouched: form.setFieldTouched,
                setFieldValue: form.setFieldValue,
                errors: form.errors
              }}
            />
          </Box>
        </Box>
        <Divider />
        <Box className={classes.footer}>
          <Box className={classes.footerSection}>
            <Button
              variant="contained"
              startIcon={<UndoIcon />}
              className={classes.resetButton}
              size="small"
              onClick={(): void => {
                form.resetForm()
              }}
              disabled={!form.dirty}
            >
              Undo
            </Button>
            <Button
              variant="contained"
              startIcon={<SaveIcon />}
              className={classes.updateButton}
              size="small"
              onClick={(): void => {
                form.submitForm()
              }}
              disabled={!form.dirty}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Panel>
    </PanelContainer>
  );
}
