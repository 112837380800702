/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useMemo } from 'react'
import {
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
  match,
} from 'react-router-dom'
import { History, Location } from 'history'
import queryString from 'query-string'
import { IKeyValueStringCollection } from 'types/common/interfaces/IKeyValueStringCollection'
import _ from 'core/utils/deepdash'

interface IUseRouterHook {
  (): {
    pathname: string
    routeParams: IKeyValueStringCollection
    match: match<{}>
    location: Location
    history: History
    pushQueryParams: (newParams: { [key: string]: string }) => void
    removeQueryParams: (...args: string[]) => void
  }
}

export const useRouter: IUseRouterHook = () => {
  const params = useParams()
  const location = useLocation()
  const history = useHistory()
  const match = useRouteMatch()

  const getQueryParams = (): { [key: string]: any } => {
    const routeParams = params as { [key: string]: any }
    return _.filter(routeParams, (value, key) => {
      !match.path.includes(key)
    })
  }

  const pushQueryParams = (newParams: { [key: string]: string }): void => {
    const search = {
      ...queryString.parse(location.search),
      ...newParams,
    }

    history.push({
      pathname: location.pathname,
      search: queryString.stringify(search),
    })
  }

  const removeQueryParams = (...args: string[]): void => {
    let search: { [key: string]: any } = {}

    if (args.length > 0) {
      search = {
        ...queryString.parse(location.search),
      }
      args.forEach((value: string) => {
        if (Object.hasOwnProperty.call(search, value)) {
          delete search[value]
        }
      })
    }

    const next: {
      pathname: string
      search?: string
    } = {
      pathname: location.pathname,
    }

    if (Object.keys(search).length > 0) {
      next.search = queryString.stringify(search)
    }

    history.push(next)
  }

  return useMemo(() => {
    return {
      pathname: location.pathname,
      routeParams: {
        ...queryString.parse(location.search),
        ...params,
      },
      match,
      location,
      history,
      pushQueryParams,
      removeQueryParams,
    }
  }, [params, match, location, history])
}
