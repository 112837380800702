import { ENV } from 'core/environment'
import { API_BASE_URL, Version } from 'types/common/enums/versions'
import { IPayload } from 'types/redux/interfaces/IPayload'

interface IParams {
  payload?: IPayload,
  path: string[],
  host?: string,
  version?: Version
}

export const buildRequestUrl = ({
  payload = {},
  path,
  host,
  version = Version.V1
}: IParams): string => {
  const newPath: string[] = []

  path.forEach((part, index) => {
    const paramPattern = /({{[a-zA-Z]+}})/g
    const partHasParamPattern = paramPattern.test(part)

    if (partHasParamPattern) {
      // Removes curly brances from parameter placeholders
      const paramPart = part.substr(2, part.length - 4)

      // Get value where param part matches payload key
      const paramValue = payload[paramPart]

      newPath.push(paramValue)
    } else {
      newPath.push(part)
    }
  })

  const baseUrl = API_BASE_URL[version] || ENV.API_BASE_URL

  return `${host ?? baseUrl}/${newPath.join('/')}`
}
