import { RootSagaReturnType } from 'core/redux/rootSaga'
import { ActionTypes } from 'modules/shipping'
import { GetTagsSaga } from 'modules/tags/sagas/get-tags-saga'
import { all, takeEvery, takeLatest } from 'redux-saga/effects'

const enabledAsyncActions = [
  ActionTypes.GET_USER_PRIZE_STATUSES,
  ActionTypes.UPDATE_USER_PRIZE_STATUS,
  ActionTypes.CREATE_USER_PRIZE_NOTE,
]

export const RootSaga = function* (): RootSagaReturnType {
  yield all([
    yield takeEvery(enabledAsyncActions, GetTagsSaga),
    yield takeLatest(ActionTypes.GET_USER_PRIZES, GetTagsSaga),
  ])
}
