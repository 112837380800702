import { Box, Card, CardContent, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { ImageUploader } from 'components/image-uploader'
import { SimpleImageDisplay } from 'components/simple-image-display'
import { usePostResultsManager } from 'modules/posts/context/post-results-manager'
import React from 'react'
import { MediaUploadModel } from 'types/common/images/models/entities/image-upload-model'

interface IResultsProps {
  results: any[]
  postId: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    postImageCard: {
      flex: '0 0 auto',
      width: 300,
      marginRight: '1.25rem',
      position: 'relative',
      '&:hover': {
        '& $cardInfoPopup': {
          opacity: 1,
          transition: theme.transitions.create(['opacity'], {
            duration: theme.transitions.duration.standard,
          }),
        },
      },
    },
    cardContent: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      flex: 1,
    },
    cardInfoPopup: {
      position: 'absolute',
      bottom: 0,
      width: 300,
      height: 400,
      pointerEvents: 'none',
      display: 'flex',
      padding: '1.25rem',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'rgba(0,0,0,0.54)',
      opacity: 0,
      transition: theme.transitions.create(['opacity'], {
        duration: theme.transitions.duration.standard,
      }),
    },
  }),
)

export const Results = ({ results, postId }: IResultsProps): JSX.Element => {
  const classes = useStyles()

  const { uploadResultImage } = usePostResultsManager()

  return (
    <>
      {results.map((result, index) => (
        <Card key={result.id} className={classes.postImageCard} elevation={4}>
          <CardContent className={classes.cardContent}>
            <Typography
              variant="subtitle2"
              style={{
                fontWeight: 600,
              }}
              color="textPrimary"
            >
              {`Result ${index + 1}`}jj
            </Typography>
          </CardContent>
          <ImageUploader
            handleUpload={(fileParams: MediaUploadModel): void => {
              uploadResultImage(postId, result.id, fileParams)
            }}
          >
            <SimpleImageDisplay imageUrl={result.media?.imageUrl} />
          </ImageUploader>
          <Box className={classes.cardInfoPopup}>
            <Typography
              variant="subtitle2"
              style={{
                fontWeight: 600,
                color: 'white',
              }}
            >
              {result.text}
            </Typography>
            {result.media?.description && (
              <Typography
                variant="body2"
                style={{
                  fontWeight: 600,
                  color: 'white',
                }}
              >
                {result.media?.description}
              </Typography>
            )}
          </Box>
        </Card>
      ))}
    </>
  )
}
