import { Box, ButtonBase } from '@mui/material'
import clsx from 'clsx'
import { useStylesForModalsWithTabs } from 'hooks/use-styles-for-modals-with-tabs'
import React from 'react'

interface IProps {
  onSelectTab: () => void
  icon: JSX.Element
  selected: Boolean
}

export const TabSwitch = ({
  onSelectTab,
  icon,
  selected
}: IProps): JSX.Element => {
  const otherClasses = useStylesForModalsWithTabs()
  return <ButtonBase
    className={otherClasses.navItemButton}
    onClick={onSelectTab}
  >
    <Box
      className={clsx(
        otherClasses.postNavigationItem,
        selected ? otherClasses.selectedNavItem : '',
      )}
    >
      <Box className={otherClasses.navItemIcon}>
        {icon}
      </Box>
    </Box>
  </ButtonBase>
}