import { Theme, Popover, PopoverProps, Box } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { IPopoverProps } from 'components/popover/use-popover'
import { UserPrizeActionButtons } from 'modules/shipping/views/components/action-buttons'
import React from 'react'
import { IUserPrize } from 'types/modules/shipping/models/entities/user-prize'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contextMenu: {
      display: 'flex',
      flexDirection: 'column',
      padding: '0.625rem',
      '& .MuiButton-contained': {
        marginBottom: '0.625rem',
        '&:last-child': {
          marginBottom: 0,
        },
      },
    },
  }),
)

interface IUserPrizeTimeGridContextMenuProps {
  userPrize?: IUserPrize
  dateTime: string
  dateField: string
  popoverProps: IPopoverProps
}

export const UserPrizeTimeGridContextMenu: React.FC<
  IUserPrizeTimeGridContextMenuProps
> = (props: IUserPrizeTimeGridContextMenuProps) => {
  const { userPrize, dateTime, dateField, popoverProps } = props

  const classes = useStyles()

  return (
    <Popover {...popoverProps}>
      <Box className={classes.contextMenu}>
        {userPrize && (
          <UserPrizeActionButtons
            userPrize={userPrize}
            onPopoverClose={(): void => {
              popoverProps.onClose()
            }}
          />
        )}
      </Box>
    </Popover>
  )
}
