import ActionTypes from 'modules/news-cards/action-types/news-card-action-types'
import { Reducer } from 'redux'
import { actionTypeSuccess, cacheItem, cacheItems } from 'core/redux/utils'
import { ISimpleAction, ISuccessAction } from 'types/redux/interfaces/IAction'
import _ from 'core/utils/deepdash'
import { mergeArrays } from 'core/utils/mergeArrays'
import { INewsCardActionParams } from 'modules/news-cards/actions/news-card-actions'
import { NIL as NIL_UUID } from 'uuid'
import { INewsCardState } from 'modules/news-cards/models/state'
import { INewsCard } from 'modules/news-cards/models/news-card'

const pageSize = 20

const initialState: INewsCardState = {
  count: 0,
  page: 0,
  pageSize: pageSize,
  items: [],
  cache: {},
}

export type GetNewsCardsApiResponse = {
  count: number
  page: number
  pageSize: number
  items: INewsCard[]
}

type AllowedActionTypes = ISuccessAction | ISimpleAction

const handleUpdate = (state: INewsCardState,
    action: AllowedActionTypes): INewsCardState => {
  const successAction = action as ISuccessAction
    const payloadItem = successAction.data as INewsCard
    const itemIndex = state.items.findIndex((x) => x.id === payloadItem.id)

    return {
      ...state,
      items:
        itemIndex >= 0
          ? state.items.map((item, index) =>
              index === itemIndex ? payloadItem : item,
            )
          : [...state.items, payloadItem],
      cache: cacheItem(state.cache, payloadItem),
    }
}

const actionHandlers = {
  [ActionTypes.SET_CURRENT_NEWS_CARD_ID]: (
    state: INewsCardState,
    action: AllowedActionTypes,
  ): INewsCardState => {
    const simpleAction = action as ISimpleAction
    const { newsCardId } =
      simpleAction.payload as INewsCardActionParams
    return {
      ...state,
      currentNewsCardId: newsCardId ? newsCardId : undefined,
    }
  },
  [ActionTypes.CREATE_NEWS_CARD_DRAFT]: (
    state: INewsCardState,
    action: AllowedActionTypes,
  ): INewsCardState => {
    const simpleAction = action as ISimpleAction
    const { item } = simpleAction.payload as INewsCardActionParams

    return {
      ...state,
      currentNewsCardId: NIL_UUID,
      items: [item as INewsCard, ...state.items],
    }
  },
  [actionTypeSuccess(ActionTypes.GET_NEWS_CARDS)]: (
    state: INewsCardState,
    action: AllowedActionTypes,
  ): INewsCardState => {
    const successAction = action as ISuccessAction
    const response = successAction.data as INewsCard[]
    return {
      ...state,
      items: mergeArrays(state.items, response),
      cache: cacheItems(state.cache, response),
    }
  },
  [actionTypeSuccess(ActionTypes.GET_NEWS_CARD_BY_ID)]: (
    state: INewsCardState,
    action: AllowedActionTypes,
  ): INewsCardState => {
    const successAction = action as ISuccessAction
    const response = successAction.data as INewsCard
    return {
      ...state,
      cache: cacheItem(state.cache, response),
    }
  },
  [actionTypeSuccess(ActionTypes.CREATE_NEWS_CARD)]: (
    state: INewsCardState,
    action: AllowedActionTypes,
  ): INewsCardState => {
    const successAction = action as ISuccessAction
    const responseItem = successAction.data as INewsCard

    const { items } = state

    return {
      ...state,
      items: [responseItem, ...items],
      cache: cacheItem(state.cache, responseItem),
    }
  },
  [actionTypeSuccess(ActionTypes.DELETE_NEWS_CARD)]: (
    state: INewsCardState,
    action: AllowedActionTypes,
  ): INewsCardState => {
    const successAction = action as ISuccessAction

    const currentCache = { ...state.cache }
    const { id } = successAction.data as INewsCard
    delete currentCache[id!]

    return {
      ...state,
      items: state.items.filter(item => item.id !== id),
      cache: { ...currentCache }
    }
  },
  [actionTypeSuccess(ActionTypes.UPDATE_NEWS_CARD)]: (
    state: INewsCardState,
    action: AllowedActionTypes,
  ): INewsCardState => handleUpdate(state, action),
  [actionTypeSuccess(ActionTypes.UPLOAD_NEWS_CARD_IMAGE)]: (
    state: INewsCardState,
    action: AllowedActionTypes,
  ): INewsCardState => handleUpdate(state, action)
}

const newsCardsReducer: Reducer<INewsCardState, AllowedActionTypes> = (
  state = initialState,
  action: AllowedActionTypes,
) => {
  return actionHandlers[action.type]
    ? actionHandlers[action.type](state, action)
    : state
}

export default newsCardsReducer
