import { Box, Chip, SvgIcon, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { ImageIcon } from 'components/icons'
import { SimpleImageDisplay } from 'components/simple-image-display'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import { buildPostStatusConfig } from 'modules/events/utils/build-post-status-config'
import { EventActionButtons } from 'modules/events/views/components/action-buttons'
import { EventStatusses } from 'modules/events/views/components/events-dashboard/event-statusses'
import moment from 'moment-timezone'
import React from 'react'
import { EventActionButtonType } from 'types/modules/events/enums/event-action-button-type'
import { IEvent } from 'types/modules/events/models/entities/event'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    eventListCard: {
      display: 'flex',
      width: '100%',
      marginBottom: '0.625rem',
      flexDirection: 'row',
      boxShadow: 'none',
      border: '1px solid rgba(0,0,0,0.12)',
      borderRadius: 4,
      '&:last-child': {
        marginBottom: 'calc(48px + 0.625rem)',
      },
    },
    eventContent: {
      padding: '0.625rem',
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
    },
    eventDetails: {
      display: 'flex',
      flexGrow: 1,
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    eventPostsData: {
      display: 'flex',
      flexDirection: 'row',
    },
    postStatus: {
      display: 'flex',
      flexDirection: 'column',
      width: 70,
      height: 70,
      marginRight: '0.625rem',
      background: 'rgba(0,0,0,0.03)',
      justifyContent: 'center',
      alignItems: 'center',
      '&:last-child': {
        margin: 0,
      },
    },
    postStatusCount: {
      color: 'rgba(0,0,0,0.54)',
      fontSize: '1rem',
    },
    postStatusLabel: {
      color: 'rgba(0,0,0,0.54)',
      fontSize: '0.55rem',
    },
    actionsAndMetadata: {
      display: 'flex',
      flexShrink: 1,
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    actions: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      '& .MuiButton-root': {
        marginRight: '0.625rem',
        '&:last-child': {
          marginRight: 0,
        },
      },
    },
    metadata: {
      display: 'flex',
      flexDirection: 'row-reverse',
    },
    metadataItem: {
      marginLeft: '1.25rem',
    },
    promotedChip: {
      marginLeft: 8,
    }
  }),
)

export const EventsListItem: React.FC<IEvent> = (event: IEvent) => {
  const classes = useStyles()

  const renderDates = (): string => {
    const dateFormat = 'D MMMM YYYY HH:mm'
    let result = moment(event.start).format(dateFormat)
    if (!IsNullOrUndefined(event.end)) {
      result += ` - ${moment(event.end).format(dateFormat)}`
    }
    return result
  }

  const renderMetadata = (): JSX.Element => {
    return (
      <React.Fragment>
        <Box className={classes.metadataItem}>
          <Typography variant="body2" color="textPrimary" component="span">
            Followers =
          </Typography>
          <Typography variant="h6" color="textPrimary" component="span">
            {' ' + event.users}
          </Typography>
        </Box>
        <Box className={classes.metadataItem}>
          <Typography variant="body2" color="textPrimary" component="span">
            Total Posts =
          </Typography>
          <Typography variant="h6" color="textPrimary" component="span">
            {' ' +
              event.postStatus.reduce(
                (a: number, b: { count: number }) => a + b.count,
                0,
              )}
          </Typography>
        </Box>
      </React.Fragment>
    )
  }

  return (
    <Box className={classes.eventListCard}>
      <Box className={classes.eventContent}>
        <SimpleImageDisplay imageUrl={event.media?.imageUrl} width={130} height={130}/>
        <Box className={classes.eventDetails}>
          <Typography variant="subtitle1" color="textPrimary" component="span">
            {`${event.name}${
              event.media?.description ? ': ' + event.media.description : ''
            }`}
            {
              event.promoted
              && <Chip label="Promoted" size="small" color="primary" variant="outlined" className={classes.promotedChip} />
            }
          </Typography>
          <Typography variant="body2" color="textPrimary" component="span">
            {renderDates()}
          </Typography>
          <EventStatusses postStatus={event.postStatus} eventId={event.id} />
        </Box>
        <Box className={classes.actionsAndMetadata}>
          <Box className={classes.actions}>
            <EventActionButtons
              event={event}
              exclude={[EventActionButtonType.Submit]}
            />
          </Box>
          <Box className={classes.metadata}>{renderMetadata()}</Box>
        </Box>
      </Box>
    </Box>
  )
}
