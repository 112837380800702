import { ISelectOption } from 'types/common/filtering/models/entities/ISelectOption'
import { UserPrizeStatus } from 'types/modules/shipping/enums/user-prize-status'
import { IUserPrizeState } from 'types/modules/shipping/models/state/user-prize-state'

export const getStatusFilterOptions = (
  statuses: IUserPrizeState['statuses'],
): ISelectOption[] => {
  const unclaimedGroup = [
    UserPrizeStatus.Created,
    UserPrizeStatus.Won,
    UserPrizeStatus.Entered,
  ]

  const sumStatusCountForGroup = (statusGroup: UserPrizeStatus[]): number => {
    let total = 0
    statusGroup.forEach((status) => (total += statuses[status]))
    return total
  }

  return [
    {
      label: UserPrizeStatus.Cancelled,
      value: [UserPrizeStatus.Cancelled],
      metadata: {
        count: statuses[UserPrizeStatus.Cancelled],
      },
    },
    {
      label: 'Ready to ship',
      value: [UserPrizeStatus.ReadyToShip],
      metadata: {
        count: statuses[UserPrizeStatus.ReadyToShip],
      },
    },
    {
      label: UserPrizeStatus.Dispatched,
      value: [UserPrizeStatus.Dispatched],
      metadata: {
        count: statuses[UserPrizeStatus.Dispatched],
      },
    },
    {
      label: UserPrizeStatus.Delivered,
      value: [UserPrizeStatus.Delivered],
      metadata: {
        count: statuses[UserPrizeStatus.Delivered],
      },
    },
    {
      label: UserPrizeStatus.Returned,
      value: [UserPrizeStatus.Returned],
      metadata: {
        count: statuses[UserPrizeStatus.Returned],
      },
    },
  ]
}
