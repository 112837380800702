/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import { useTypedSelector } from 'core/redux/utils'
import { useRouter } from 'core/routing/hooks/use-router'
import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react'
import { Selectors, Actions } from 'modules/posts'
import {
  IsNullOrUndefined,
  IsNullUndefinedOrEmpty,
} from 'core/utils/isNullOrUndefined'
import { IPost } from 'types/modules/posts/models/entities/post'
import { IPostsState } from 'types/modules/posts/models/state/IPostsState'
import { IAsyncActionParams } from 'types/common/api/models/entities/async-action-params'
import { useDispatch } from 'react-redux'
import { IPostActionParams } from 'modules/posts/actions'
import PostStatus from 'types/modules/posts/enums/post-status'
import _ from 'core/utils/deepdash'
import { ISelectFilterConfig } from 'types/common/filtering/models/entities/ISelectFilterConfig'
import { getStatusFilterOptions } from 'modules/posts/utils/get-status-filter-options'
import { useRequestContext } from 'core/api/context'
import { FilterOperator } from 'types/common/filtering/enums/FilterOperator'
import { FilterType } from 'types/common/filtering/enums/FilterType'
import SortDirection from 'types/common/enums/sort-direction'
import { PostField } from 'types/modules/posts/enums/post-field'
import PostType from 'types/modules/posts/enums/post-type'
import { getEventById } from 'modules/events/actions'
import { getTagGroupById } from 'modules/tags/actions'
import { getLiveSchedules } from 'modules/live-schedules/actions'
import { IEvent } from 'types/modules/events/models/entities/event'
import { buildPostStatusConfig } from 'modules/events/utils/build-post-status-config'
import { LiveScheduleField } from 'types/modules/live-schedules/enums/live-schedule-field'
import { getPrizeById } from 'modules/prizes/actions'
import { usePageSizeMonitor } from 'modules/common/usePageSizeMonitor'
import { NIL } from 'uuid'
import { getCachedLiveSchedulesByEvent } from 'modules/live-schedules/selectors'
import { getCachedTagGroupById } from 'modules/tags/selectors'
import { Tag } from 'types/modules/tags/models/entities/tag'
import { IPostQuestion } from 'types/modules/posts/models/entities/post-question'
import { getPostGroupById } from 'modules/post-groups/actions'
import { PostGroup } from 'fe-shared-resources'

export interface IPostListContext {
  posts: IPostsState
  statusFilterConfig: ISelectFilterConfig
  currentPost: Partial<IPost> | null
  initialiseDashboard: (dashboardView: string) => void
  setCurrentPost: (id: string, type: PostType) => void
  clearCurrentPost: () => void
  loadPosts: (params: IAsyncActionParams<IPost>) => void
  hasTrash: boolean
  clearTrash: () => void
  createPostDraft: (type: PostType) => void
  eventId?: string
  postGroupId?: string
  currentPostQuestions: IPostQuestion[]
  fetching: boolean
  fetchingPosts: boolean
  initialTags?: Tag[]
}

export const PostListContext = React.createContext<IPostListContext>(
  {} as IPostListContext,
)

export interface IPostContextOverrides {
  loadPosts: () => void
}

interface IPostContextProps {
  event?: IEvent
  postGroup?: PostGroup
  overrides?: IPostContextOverrides
}

export const PostContextProvider: React.FC<IPostContextProps> = (
  props: PropsWithChildren<IPostContextProps>,
) => {
  const { event, overrides } = props

  const [fetchingPosts, setFetchingPosts] = useState(false)
  const [fetchingEvent, setFetchingEvent] = useState(true)
  const [fetchingPostGroup, setFetchingPostGroup] = useState(true)
  const [fetchingPost, setFetchingPost] = useState(true)
  const [fetchingLiveSchedules, setFetchingLiveSchedules] = useState(false)
  const [fetchingPrizes, setFetchingPrizes] = useState(true)
  const [fetchingEventTagGroupTags, setFetchingEventTagGroupTags] = useState(true)
  const [initialTags, setInitialTags] = useState()
  const fetching = fetchingEvent || fetchingPost || fetchingPrizes || fetchingEventTagGroupTags || fetchingPostGroup

  const rc = useRequestContext()
  const eventTagGroupId = event?.tagGroupId

  const pageSize = usePageSizeMonitor({ fetchItems: Actions.getPosts })

  const statusFilter = rc?.getFilterByField(PostField.STATUS)

  const { routeParams, pushQueryParams, removeQueryParams } = useRouter()

  const { postId, postType: postTypeFromParams, eventId, postGroupId } = routeParams

  const posts = useTypedSelector((state) =>
    Selectors.getPostsByStatus(state, statusFilter?.value as PostStatus[]),
  )

  const liveSchedules = useTypedSelector((state) =>
    getCachedLiveSchedulesByEvent(state, event?.id ?? ''),
  )

  const eventTagGroupTags = useTypedSelector(
    (state) => getCachedTagGroupById(state, event?.tagGroupId)?.tags as Tag[],
  )

  const currentPost = useTypedSelector((state) =>
    Selectors.getCurrentPost(state, {
      postType: postTypeFromParams as PostType,
      eventTagGroupTags,
      event,
      liveSchedules,
    }))

  const currentPostQuestions = currentPost?.questions || []

  const prizeId = (currentPost?.postPrizes || [])[0]?.prizeId

  const hasTrash = posts.statuses[PostStatus.Trashed].count > 0

  const setCurrentPost = (id: string, type: PostType): void => {
    pushQueryParams({
      postId: id,
      postType: type,
    })
  }

  const clearCurrentPost = (): void => {
    removeQueryParams()
  }

  const statusFilterConfig = {
    field: 'status',
    options: IsNullOrUndefined(event)
      ? getStatusFilterOptions(posts.statuses)
      : buildPostStatusConfig(event?.postStatus!),
  }

  const dispatch = useDispatch()

  useEffect(() => {
    if (!IsNullUndefinedOrEmpty(postGroupId)) {
      setFetchingPostGroup(true)
      dispatch(
        getPostGroupById({
          postGroupId: postGroupId,
          promise: {
            onResolve: (): void => {
              setFetchingPostGroup(false)
            },
            onReject: (): void => {
              setFetchingPostGroup(false)
            },
          },
        }),
      )
    } else {
      setFetchingPostGroup(false)
    }
  }, [])

  useEffect(() => {
    dispatch(Actions.setCurrentPostId({ postId }))

    if (!IsNullOrUndefined(postId) && postId !== NIL) {
      setFetchingPost(true)
      new Promise<IPost>((onResolve, onReject) => {
        dispatch(
          Actions.getPostById({
            postId,
            promise: {
              onResolve: (): void => {
                setFetchingPost(false)
                onResolve
              },
              onReject: (): void => {
                setFetchingPost(false)
                onReject()
              },
            },
          }),
        )
      })
    } else {
      setFetchingPost(false)
      setFetchingPrizes(false)
    }
    if (!IsNullUndefinedOrEmpty(eventId)) {
      setFetchingEvent(true)
      dispatch(
        getEventById({
          eventId: eventId,
          promise: {
            onResolve: (): void => {
              setFetchingEvent(false)
            },
            onReject: (): void => {
              setFetchingEvent(false)
            },
          },
        }),
      )
    } else {
      setFetchingEvent(false)
    }
    if (!IsNullUndefinedOrEmpty(eventId)) {
      setFetchingLiveSchedules(true)
      dispatch(
        getLiveSchedules({
          filters: [
            {
              field: LiveScheduleField.EVENT_ID,
              type: FilterType.Select,
              operator: FilterOperator.Equals,
              value: eventId!,
            },
          ],
          promise: {
            onResolve: (): void => {
              setFetchingLiveSchedules(false)
            },
            onReject: (): void => {
              setFetchingLiveSchedules(false)
            },
          },
        }),
      )
    } else {
      setFetchingLiveSchedules(false)
    }

  }, [postId, eventId])

  useEffect(() => {
    if (!IsNullUndefinedOrEmpty(eventId)) {
      if (!eventTagGroupId) {
        setFetchingEventTagGroupTags(false)
        return
      }
      setFetchingEventTagGroupTags(true)
      dispatch(
        getTagGroupById({
          tagGroupId: eventTagGroupId,
          promise: {
            onResolve: (data): void => {
              setInitialTags(data?.tags || [])
              setFetchingEventTagGroupTags(false)
            },
            onReject: (): void => {
              setFetchingEventTagGroupTags(false)
            },
          },
        }),
      )
    } else {
      setFetchingEventTagGroupTags(false)
    }
  }, [eventTagGroupId, eventId])

  useEffect(() => {
    if (!IsNullOrUndefined(prizeId)) {
        setFetchingPrizes(true)
        dispatch(
          getPrizeById({
            prizeId,
            promise: {
              onResolve: (): void => setFetchingPrizes(false),
              onReject: (): void => setFetchingPrizes(false),
            }
          }),
        )
      } else {
      setFetchingPrizes(false)
    }
  }, [prizeId])

  const initialiseDashboard = (dashboardView: string): void => {
    if (!IsNullOrUndefined(event)) {
      rc?.applyFilter({
        field: PostField.EVENT_ID,
        type: FilterType.Select,
        operator: FilterOperator.Equals,
        value: [event?.id!],
      })
    } else if (!IsNullOrUndefined(postGroupId)) {
      rc?.applyFilter({
        field: 'postGroupId',
        type: FilterType.Select,
        operator: FilterOperator.Equals,
        value: [postGroupId!],
      })
    } else {
      dispatch(Actions.getPostStatuses())
    }

    rc?.applyFilter({
      field: statusFilterConfig.field,
      type: FilterType.Select,
      operator: FilterOperator.Equals,
      value:
        dashboardView === 'list'
          ? [PostStatus.Active]
          : [
              PostStatus.Paused,
              PostStatus.Active,
              PostStatus.Scheduled,
              PostStatus.Pending,
            ],
    })
    rc?.setSortValue({
      sortBy: 'start',
      sortDirection: SortDirection.Descending,
    })
  }

  const loadPosts = useCallback((): void => {
    setFetchingPosts(true)
    dispatch(
      Actions.getPosts({
        filters: rc?.filters,
        page: rc?.page,
        pageSize,
        ...rc?.sortValue,
        promise: {
          onResolve: () => setFetchingPosts(false),
          onReject: () => setFetchingPosts(false),
        },
      } as IPostActionParams),
    )
  }, [rc?.active, rc?.filters, rc?.page, pageSize, rc?.sortValue])

  useEffect(() => {
    dispatch(Actions.clearPosts())
  }, [rc?.filters, rc?.sortValue])

  useEffect(() => {
    if (overrides?.loadPosts) {
      overrides.loadPosts()
      return
    }
    if (!rc?.active) return
    loadPosts()
  }, [rc?.active, rc?.filters, rc?.page, rc?.sortValue])

  const createPostDraft = (type: PostType): void => {
    setCurrentPost(NIL, type)
  }

  const clearTrash = (): void => {
    dispatch(Actions.clearTrash())
    dispatch(Actions.clearPosts())
  }

  const context: IPostListContext = {
    posts,
    statusFilterConfig,
    currentPost,
    hasTrash,
    initialiseDashboard,
    setCurrentPost,
    clearCurrentPost,
    loadPosts,
    clearTrash,
    createPostDraft,
    eventId: event?.id,
    currentPostQuestions,
    fetching,
    initialTags,
    postGroupId,
    fetchingPosts
  }

  return (
    <PostListContext.Provider value={context}>
      {props.children}
    </PostListContext.Provider>
  )
}

export const usePostListContext = (): IPostListContext =>
  React.useContext(PostListContext)
