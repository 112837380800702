

export enum QuestGroupField {
  ID = 'id',
  NAME = 'name',
  TEST = 'test',
  VARIANT = 'variant',
  ACTIVE = 'active',
  QUEST_IDS = 'questIds',
  QUESTS = 'quests',
  QUEST_GROUP_QUESTS = 'questGroupQuests',
}

export enum QuestGroupQuestField {
  ID = 'id',
  QUEST_ID = 'questId',
  QUEST_GROUP_ID = 'questGroupId',
}