/* Core */
import { ENV } from 'core/environment'
import { IsNullOrUndefined } from 'core/utils/isNullOrUndefined'
import {
  createRequestAction,
  createSuccessAction,
  createFailedAction,
} from 'core/redux/utils'

/* Types */
import { ApiRequestActions } from 'types/common/api/models/entities/api-request-actions'
import { ApiResponseData } from 'types/common/api/models/entities/api-response-data'
import { ApiResponseError } from 'types/common/api/models/entities/api-response-error'
import { IApiRequestActionsCreator } from 'types/common/api/models/entities/IApiRequestActionsCreator'
import {
  IAsyncAction,
  IRequestAction,
  ISuccessAction,
  IFailedAction,
} from 'types/redux/interfaces/IAction'
import { BuildRequestParams } from 'core/api/utils/build-request-params'

/**
 * Generates api request, success and failed actions.
 * @param asyncAction - An asynchronous action
 */
export const CreateApiRequestActions: IApiRequestActionsCreator = (
  asyncAction: IAsyncAction,
) => {
  const { type, payload, endpoint, version } = asyncAction

  const requestParams = BuildRequestParams({ endpoint, payload: payload!, version })

  const requestActionCreator = (): IRequestAction =>
    createRequestAction(asyncAction, requestParams)

  const successActionCreator = (data: ApiResponseData): ISuccessAction =>
    createSuccessAction(requestActionCreator(), data)

  const failedActionCreator = (error: ApiResponseError): IFailedAction =>
    createFailedAction(requestActionCreator(), error)

  return {
    REQUEST: requestActionCreator,
    SUCCESS: successActionCreator,
    FAILED: failedActionCreator,
  } as ApiRequestActions
}
