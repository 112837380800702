import { Theme, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { useCalendarContext } from 'components/calendar/context/calendar-context'
import { hoursInDay } from 'components/calendar/utils'
import { TimeColumn } from 'components/calendar/views/time-column'
import { TimeGridHeader } from 'components/calendar/views/time-grid-header'
import moment from 'moment-timezone'
import React, { ReactElement } from 'react'
import _ from 'core/utils/deepdash'
import { useRequestContext } from 'core/api/context'
import { ITimeGridProps } from 'components/calendar/props/time-grid-props'
import {
  DragDropContext,
  Droppable,
  DropResult,
  ResponderProvided,
} from 'react-beautiful-dnd'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    timeGrid: {
      position: 'relative',
      display: 'flex',
      alignSelf: 'flex-end',
      height: 'calc(100% - 80px)',
      flex: 1,
      overflowY: 'scroll',
      zIndex: 1,
    },
    dateColumn: {
      display: 'flex',
      flex: 1,
      height: 'max-content',
      flexDirection: 'column',
      borderLeft: '1px solid rgba(0,0,0,0.12)',
      boxSizing: 'border-box',
      '&:last-child': {
        borderRight: '1px solid rgba(0,0,0,0.12)',
      },
    },
    eventRow: {
      display: 'flex',
      height: 80,
      borderBottom: '1px solid rgba(0,0,0,0.12)',
    },
  }),
)

export function TimeGrid<TModel>(
  props: ITimeGridProps<TModel>,
): ReactElement<any, any> {
  const { headerActionButton } = props
  const classes = useStyles()

  const rc = useRequestContext()

  const { visibleDates } = useCalendarContext()

  const itemsGroupedByDay = _.groupBy(
    props.data?.items ?? [],
    (val: { [key: string]: any }) => {
      return moment(val[rc?.sortValue.sortBy!]).startOf('day').toISOString()
    },
  )

  const renderDateColumn = (date: string, items: TModel[] | any[]): JSX.Element => {
    const itemsGroupedByHour = _.groupBy(
      props.data?.items ?? [],
      (val: { [key: string]: any }) => {
        return moment(val[rc?.sortValue.sortBy!]).startOf('hour').toISOString()
      },
    )

    const TimeGridCellComponent = props.timeGridCellComponent

    return (
      <Box key={`date-column-${date}`} className={classes.dateColumn}>
        {hoursInDay().map((hour) => {
          const datePart = moment(date).format('YYYY-MM-DD')
          const timePart = moment(hour).format('HH:mm:ss')
          const dateTimeIso = `${datePart}T${timePart}.000Z`
          const dateTime = moment(dateTimeIso)
          const items = itemsGroupedByHour[dateTime.toISOString()] ?? null
          const itemKey = `event-row-${dateTime.toISOString()}`

          return (
            <Droppable key={itemKey} droppableId={dateTime.toISOString()}>
              {(provided): JSX.Element => (
                <div
                  className={classes.eventRow}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  <TimeGridCellComponent
                    sortBy={rc?.sortValue.sortBy!}
                    items={items}
                    dateTime={dateTime.toISOString()}
                  />
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          )
        })}
      </Box>
    )
  }

  const handleDragEnd = (
    result: DropResult,
    provided: ResponderProvided,
  ): void => {
    // eslint-disable-next-line no-console
    // console.log({
    //   result,
    //   provided
    // });
  }

  return (
    <React.Fragment>
      <TimeGridHeader actionButton={headerActionButton} />
      <Box className={classes.timeGrid}>
        <TimeColumn />
        <DragDropContext onDragEnd={handleDragEnd}>
          {visibleDates.map((date) =>
            renderDateColumn(date, itemsGroupedByDay[date]),
          )}
        </DragDropContext>
      </Box>
    </React.Fragment>
  )
}
