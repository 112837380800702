import PostStatus from 'types/modules/posts/enums/post-status'

export const getStatusLabel = (status: PostStatus | string): string => {
  switch (status) {
    case PostStatus.Created:
      return 'Draft'

    default:
      return status
  }
}
