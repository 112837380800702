import { ENV } from 'core/environment';

export enum Version {
  V1,
  V2
}

export const API_BASE_URL = {
  [Version.V1]: ENV.API_BASE_URL,
  [Version.V2]: ENV.API_BASE_URL_V2
}