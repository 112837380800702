import { ENV } from 'core/environment'
import { ICognitoAuthConfiguration } from 'types/common/authentication/ICognitoAuthConfiguration'

const CognitoAuthConfiguration: ICognitoAuthConfiguration = {
  Auth: {
    region: ENV.COGNITO_AUTH_REGION,
    userPoolId: ENV.COGNITO_AUTH_USER_POOL_ID,
    userPoolWebClientId: ENV.COGNITO_AUTH_WEB_CLIENT_ID,
    authenticationFlowType: ENV.COGNITO_AUTH_FLOWTYPE,
  },
}

export default CognitoAuthConfiguration
